import { ISelectOption, Select } from '../../../web/components/Select';
import { CalendarDateIcon } from '../../../web/components/svgs/icons/CalendarDateIcon';
import { LogoutIcon } from '../../../web/components/svgs/icons/LogoutIcon';
import { MenuIcon } from '../../../web/components/svgs/icons/MenuIcon';
import { PhoneIcon } from '../../../web/components/svgs/icons/PhoneIcon';
import { QuestionMarkIcon } from '../../../web/components/svgs/icons/QuestionMarkIcon';
import { brandPrimary, white } from '../../styles/colors';
import { TrophyIcon } from '../svgs/icons/TrophyIcon';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { useCallback, useRef } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

enum UserOption {
	CallLogs = 'CallLogs',
	Help = 'Help',
	Leaderboard = 'Leaderboard',
	Demos = 'Demos',
	Logout = 'logout',
	ReportingDashboard = 'ReportingDashboard',
}

interface IProps {
	className?: string;
}

export const MobileMenu: React.FC<IProps> = ({ className = '' }) => {
	const history = useHistory();
	const options = useRef<ISelectOption<UserOption>[]>([
		{
			dataContext: UserOption.CallLogs,
			icon: <PhoneIcon fill={brandPrimary} className={css(styleSheet.iconSize)} />,
			id: 'call-logs-page',
			styles: [styleSheet.optionStyles],
			text: 'Call Logs',
			type: 'icon',
		},
		{
			dataContext: UserOption.Leaderboard,
			icon: <TrophyIcon fillColor={brandPrimary} className={css(styleSheet.iconSize)} />,
			id: 'leaderboard-page',
			styles: [styleSheet.optionStyles],
			text: 'Leaderboard',
			type: 'icon',
		},
		{
			dataContext: UserOption.Demos,
			icon: <CalendarDateIcon fillColor={brandPrimary} className={css(styleSheet.iconSize)} />,
			id: 'demos-page',
			styles: [styleSheet.optionStyles],
			text: 'Demos',
			type: 'icon',
		},
		{
			dataContext: UserOption.Help,
			icon: <QuestionMarkIcon fillColor={brandPrimary} className={css(styleSheet.iconSize)} />,
			id: 'user-option-help',
			styles: [styleSheet.optionStyles],
			text: 'Help',
			type: 'icon',
		},
		{
			dataContext: UserOption.Logout,
			icon: <LogoutIcon fill={brandPrimary} />,
			id: 'user-option-logout',
			styles: [styleSheet.optionStyles],
			text: 'Logout',
			type: 'icon',
		},
	]).current;

	const onOptionClick = (option: ISelectOption<UserOption>) => {
		switch (option.dataContext) {
			case UserOption.CallLogs:
				history.push('/call-log');
				break;
			case UserOption.Leaderboard:
				history.push('/leaderboard?view=leaderboard');
				break;
			case UserOption.Demos:
				history.push('/leaderboard?view=demos');
				break;
			case UserOption.Logout:
				history.push('/logout');
				break;
			case UserOption.Help:
				window.open('https://aida.help.levitate.ai');
				break;
			default:
				break;
		}
	};

	const renderAnchor = useCallback(() => {
		return (
			<div className={css(styleSheet.mobileMenuWrapper)}>
				<MenuIcon className={css(styleSheet.mobileMenu)} fillColor={white} />
			</div>
		);
	}, []);

	return (
		<div className={`${css(styleSheet.userOptionsContainer)} ${className}`}>
			<Select
				onOptionClick={onOptionClick}
				options={options}
				selectedOptionTitle={renderAnchor}
				showCaret={false}
				styles={[styleSheet.select]}
			/>
		</div>
	);
};
