import { IAccountTag, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AutoCompleteSearchField } from '../../../../../../web/components/autocomplete/AutoCompleteSearchField';

import { useTagsStorage } from '../../../../../../web/components/entities/tags/TagsEditor/hooks';
import { FeaturedAndRecentTags } from '../../../../../../web/components/entities/tags/TagsEditor/presentation';
import { XMarkIcon } from '../../../../../../web/components/svgs/icons/XMarkIcon';
import { SearchIcon } from '../../../../../components/svgs/icons/SearchIcon';
import { styleSheet } from './styles';

interface IProps<T> {
	className?: string;
	defaultSelected?: T;
	getSelected?: (selected: T) => string;
	model: ResourceAutoCompleteViewModelType;
	onChange?: (selected: T) => void;
	placeholder?: string;
}
interface IState<T> {
	isOpen: boolean;
	value: string;
	selected: T | null;
}
export function ViewModelTypeAutocomplete<T>({
	className,
	defaultSelected,
	getSelected,
	model,
	onChange,
	placeholder,
}: IProps<T>) {
	const [state, setState] = useState<IState<T>>({
		isOpen: true,
		value: '',
		selected: defaultSelected,
	});

	const isTags = useMemo(() => model === ResourceAutoCompleteViewModelType.AccountTag, [model]);

	const handleStateChange = useCallback((partialState: Partial<IState<T>>) => {
		setState(prevState => ({ ...prevState, ...partialState }));
	}, []);

	const { updateTagsRecentSearches } = useTagsStorage();

	useEffect(() => {
		handleStateChange({ selected: defaultSelected });
	}, [defaultSelected, handleStateChange]);

	const onSelected = (item: T) => {
		onChange(item);
		handleStateChange({ selected: defaultSelected });
		if (isTags) {
			const tag = item as IAccountTag;
			updateTagsRecentSearches(tag.tag);
			handleStateChange({
				isOpen: false,
				value: '',
			});
		}
	};

	const onClear = () => {
		onChange(null);
		handleStateChange({ selected: null });
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleStateChange({
			value: e.target.value,
		});
	};

	return (
		<div className={`${css(styleSheet.autocompleteWrapper)} common-autocomplete-wrapper ${className}`}>
			<AutoCompleteSearchField
				anchorClassName='common-autocomplete-anchor'
				sectionTitleElement={
					<>
						<div className={css(styleSheet.sectionWrap)}>
							<p className={css(styleSheet.sectionTitle)}>Top Results</p>
						</div>
					</>
				}
				className={`${css(styleSheet.autocomplete)} common-autocomplete`}
				clearSearchFieldAfterSelectingItem={true}
				dropdownClassName={`common-autocomplete-dropdown ${isTags ? css(styleSheet.autoCompleteMenu) : ''}`}
				dropdownItemClassName={css(styleSheet.dropdownItem)}
				leftAccessory={<SearchIcon className={`${css(styleSheet.searchIcon)} common-autocomplete-search-icon`} />}
				emptyResults={
					isTags ? (
						<>
							<FeaturedAndRecentTags
								onSelected={(tag: IAccountTag) => {
									onSelected(tag as T);
								}}
								showNoResults={Boolean(state.value)}
							/>
						</>
					) : null
				}
				onItemSelected={onSelected}
				inputProps={{
					placeholder: 'Search',
					onChange: handleValueChange,
					onFocus: () => setState(prevState => ({ ...prevState, isOpen: true })),
					onBlur: () => setState(prevState => ({ ...prevState, isOpen: false })),
				}}
				placeholder={
					state.selected ? getSelected?.(state.selected) || placeholder : placeholder || 'Start typing to select.'
				}
				preventImpersonation={true}
				resultsLimit={25}
				type={model}
				hideResultsFooter={false}
				inputId='common-autocomplete-input-id'
				openOnFocus={isTags}
				isOpen={isTags ? state.isOpen : undefined}
			/>
			{state.selected && (
				<div className={`${css(styleSheet.clearButton)} common-autocomplete-clear-button`} onClick={onClear}>
					<XMarkIcon />
				</div>
			)}
		</div>
	);
}
