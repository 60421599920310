import { useInterval } from '../../../hooks/useInterval';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import { useEffect, useState } from 'react';
import * as React from 'react';

interface IProps {
	isConnected: boolean;
	onTimeChange?: (time: number) => void;
}

export const CallTimer: React.FC<IProps> = ({ isConnected }) => {
	const [count, setCount] = useState(0);
	const [isRunning, setIsRunning] = useState(isConnected);

	useEffect(() => {
		setIsRunning(isConnected);
	}, [isConnected]);

	useInterval(
		() => {
			const time = count + 1;
			setCount(time);
		},
		// @ts-ignore
		isRunning ? 1000 : null
	);

	const timer = new Date(count * 1000).toISOString().substr(14, 5);

	if (!isConnected) {
		return null;
	}

	return <div className={css(styleSheet.dialerTimer)}>{isConnected ? timer : '00:00'}</div>;
};
