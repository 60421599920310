import { Howl } from 'howler';
import { useEffect, useRef, useState } from 'react';

export interface ISimpleAudioPlayer {
	pause(): void;
	play(): void;
	setLoop(value: boolean): void;
	setVolume(value: number): void;
	stop(): void;
}

export const useAudioPlayer = (source: string) => {
	// @ts-ignore
	const [instance, setInstance] = useState<Howl>(null);
	const instanceRef = useRef(instance);
	useEffect(() => {
		setInstance(
			new Howl({
				src: [source],
				volume: 0.5,
			}).load()
		);
	}, [source]);
	instanceRef.current = instance;

	const player = useRef<ISimpleAudioPlayer>({
		pause: () => instanceRef.current?.pause(),
		play: () => instanceRef.current?.play(),
		setLoop: (value: boolean) => instanceRef.current?.loop(value),
		setVolume: (value: number) => instanceRef.current?.volume(value),
		stop: () => instanceRef.current?.stop(),
	}).current;

	return player;
};
