import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { isAdmin } from '../../../extViewmodels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { useSearch } from '../../contexts/search';
import { INavLink, NavLink, NavLinkName } from '../NavLink';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

interface IAidaNavLink extends INavLink {
	adminPermissionsRequired?: boolean;
}

const NavBase: React.FC<IProps> = ({ className = '' }) => {
	const userSession = useUserSession();
	const { setShowSearchLambda } = useSearch();
	const allLinks = useRef<IAidaNavLink[]>([
		{
			path: '/queue',
			text: NavLinkName.Queue,
		},
		{
			path: '/call-log',
			text: NavLinkName.CallLog,
		},
		{
			path: '/demos',
			text: NavLinkName.Demos,
		},
		{
			path: '/follow-ups',
			text: NavLinkName.FollowUps,
		},
		{
			path: '/leaderboard',
			text: NavLinkName.Leaderboard,
		},
		{
			adminPermissionsRequired: true,
			path: '/settings',
			text: NavLinkName.Settings,
		},
		{
			onClick: setShowSearchLambda(true),
			text: NavLinkName.Search,
		},
	]).current;
	const [links, setLinks] = useState<IAidaNavLink[]>([]);

	useEffect(() => {
		setLinks(
			allLinks.filter(link => {
				if (link.adminPermissionsRequired) {
					// @ts-ignore
					if (isAdmin(userSession.user)) {
						return link;
					}
				} else {
					return link;
				}
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userSession.userRole]);

	const renderLinks = () => {
		return links.map(link => {
			return <NavLink key={link.text} link={link} />;
		});
	};

	return (
		<div className={`${css(styleSheet.navContainer)} ${className}`}>
			{userSession.isBusy ? <LoadingSpinner type='small' /> : renderLinks()}
		</div>
	);
};

export const Nav = observer(NavBase);
