import { BreakPoints, CssSelectors } from '../../../web/styles/styles';
import { borderColor, brandPrimary, brandSecondary, white } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	centeredContent: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		minHeight: '100vh',
		position: 'relative',
		zIndex: 1,
		[BreakPoints.phoneCompactWidth]: {
			alignItems: 'flex-start',
			boxSizing: 'border-box',
			paddingTop: 40,
		},
	},
	container: {
		background: brandPrimary,
		minHeight: '100%',
		overflowX: 'hidden',
		overflowY: 'auto',
		position: 'relative',
	},
	footer: {
		color: 'white',
		display: 'flex',
		fontSize: 14,
		fontWeight: 600,
		justifyContent: 'space-between',
		marginTop: 8,
	},
	forgotPasswordLink: {
		':active': {
			color: 'white',
		},
		':hover': {
			textDecoration: 'underline',
		},
		':link': {
			color: 'white',
		},
		':visited': {
			color: 'white',
		},
		color: brandSecondary,
		cursor: 'pointer',
		padding: '8px 0',
		textDecoration: 'none',
	},
	formFieldLabel: {
		color: '#858585',
		fontSize: '16px',
	},
	lightningGraphic1: {
		left: 0,
		position: 'absolute',
		top: 0,
		[BreakPoints.phoneCompactWidth]: {
			display: 'none',
		},
	},
	lightningGraphic2: {
		position: 'absolute',
		right: '-60px',
		top: '45vh',
		[BreakPoints.phoneCompactWidth]: {
			display: 'none',
		},
	},
	login: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	loginForm: {
		background: white,
		borderRadius: 10,
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '90vw',
		padding: '50px 70px 60px',
		position: 'relative',
		width: 500,
		[BreakPoints.tabletCompactWidth]: {
			padding: '40px',
		},
		[BreakPoints.phoneCompactWidth]: {
			padding: '30px',
		},
		...CssSelectors.allDescendants(
			{
				':focus-within': {
					background: '#fff',
					border: `4px solid ${brandSecondary} `,
					padding: '15px 20px',
				},
				background: '#f9f9f9',
				border: `1px solid ${borderColor}`,
				marginBottom: 20,
				padding: '18px 23px',
			},
			'.coffee-login-input-field'
		),
	},
	logo: {
		display: 'block',
		height: 'auto',
		margin: '0 auto 25px auto',
		width: 250,
	},
	submitButton: {
		alignItems: 'center',
		background: brandSecondary,
		borderRadius: 3,
		boxShadow: '0 1px 2px 0 rgba(140,140,140,0.50)',
		color: '#fff',
		display: 'flex',
		flexGrow: 1,
		fontSize: 16,
		fontWeight: 600,
		justifyContent: 'center',
		marginTop: 30,
		padding: '15px 0',
		width: '100%',
	},
});
