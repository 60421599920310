import { ViewportContext } from '../../../hooks/useViewport';
import { white } from '../../../styles/colors';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { useContext } from 'react';
import * as React from 'react';

export interface ILeadViewTitleProps {
	color?: string;
	size?: 'regular' | 'small';
	title: string;
}

export const LeadViewTitle: React.FC<ILeadViewTitleProps> = ({ color, size, title }) => {
	// @ts-ignore
	const { isBelowSmall } = useContext(ViewportContext);
	const isSmall = size === 'small' ? styleSheet.titleSmall : null;
	const titleMobile = isBelowSmall ? styleSheet.titleMobile : null;
	return (
		<div className={`${css(styleSheet.container)} lead-view-title`}>
			<div className={css(styleSheet.header)}>
				<div style={{ color: color || white }} className={css(styleSheet.title, isSmall, titleMobile)}>
					{title}
				</div>
			</div>
		</div>
	);
};
