import { CssSelectors, FontFamilies } from '../../../../web/styles/styles';
import { brandPrimaryHover } from '../../../styles/colors';
import { aidaBaseStyles } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	backButton: {
		color: brandPrimaryHover,
		fontSize: '14px',
		margin: '10px 0 0 10px',
		paddingLeft: 4,
		position: 'relative',
		...CssSelectors.allChildren(
			{
				position: 'absolute',
				right: '100%',
				top: 4,
			},
			'.back-button-arrow'
		),
	},
	bold: {
		font: FontFamilies.bold,
	},
	header: {
		color: '#aaa',
		fontSize: '11px',
		padding: '10px 0 20px',
		textAlign: 'center',
		textTransform: 'uppercase',
	},
	label: {
		color: '#696969',
	},
	skipOptionContainer: {
		padding: '4px 0',
	},
	skipOptions: {
		...aidaBaseStyles.flexCol,
	},
	skipOptionsContainer: {
		...aidaBaseStyles.flexCol,
		padding: '0 20px',
	},
});
