import { brandPrimaryLight } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	copyButton: {
		color: brandPrimaryLight,
	},
	copyIcon: {
		marginBottom: -2,
		marginLeft: 5,
	},
});
