import { CssSelectors } from '../../../web/styles/styles';
import { grayIconFill } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	anchor: {
		':hover': {
			cursor: 'pointer',
		},
		...aidaBaseStyles.flexHorizontalCenter,
		padding: '20px 0',
	},
	avatar: {
		height: 35,
		width: 35,
	},
	chevron: {
		margin: '5px 0 0 10px',
	},
	helpOption: {
		color: grayIconFill,
	},
	logoutOption: {
		color: grayIconFill,
	},
	open: {
		transform: 'rotateZ(180deg)',
	},
	question: {
		height: 20,
		width: 20,
	},
	reporting: {
		height: 20,
		width: 20,
	},
	select: {
		background: 'none',
		border: 'none',
		padding: '20px 0',
	},
	userOptionsContainer: {
		...CssSelectors.allDescendants(
			{
				padding: '10px 0',
			},
			'.tinyPopover-anchor'
		),
	},
});
