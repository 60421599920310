import { GhostsGraphic } from '../svgs/GhostsGraphic';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const WhoYaGonnaCall: React.FC<IProps> = ({ className = '' }) => {
	return (
		<div className={`${css(styleSheet.whoYaGonnaCallContainer)} ${className}`}>
			<div className={css(styleSheet.ghostsContainer)}>
				<GhostsGraphic />
				<div className={css(styleSheet.text)}>Who you gonna call when you run out of leads?</div>
			</div>
		</div>
	);
};
