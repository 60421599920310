import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import { grayIconFill } from '../../../../styles/colors';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	strokeWidth?: number;
	viewBox?: string;
}

export const SearchIcon: React.FC<IProps> = props => {
	const { className, fillColor, strokeWidth = 0, viewBox } = props;
	return (
		<SvgIcon className={`search-icon ${className || ''}`} height={16} viewBox={viewBox} width={16}>
			<path
				d='M6.4,11.2 C5.1184,11.2 3.9128,10.7008 3.0064,9.7944 C2.0992,8.888 1.6,7.6824 1.6,6.4 C1.6,5.1184 2.0992,3.9128 3.0064,3.0064 C3.9128,2.0992 5.1184,1.6 6.4,1.6 C7.6816,1.6 8.8872,2.0992 9.7936,3.0064 C10.7008,3.9128 11.2,5.1184 11.2,6.4 C11.2,7.6824 10.7008,8.888 9.7936,9.7944 C8.8872,10.7008 7.6816,11.2 6.4,11.2 Z M15.7656,14.6344 L11.452,10.32 C12.3224,9.2032 12.8,7.8392 12.8,6.4 C12.8,4.6904 12.1336,3.0832 10.9248,1.8752 C9.7168,0.6656 8.1096,0 6.4,0 C4.6904,0 3.0832,0.6656 1.8752,1.8752 C0.6664,3.0832 0,4.6904 0,6.4 C0,8.1096 0.6664,9.7168 1.8752,10.9248 C3.0832,12.1344 4.6904,12.8 6.4,12.8 C7.8392,12.8 9.2032,12.3232 10.32,11.4512 L14.6344,15.7656 L15.7656,14.6344 Z'
				fill={fillColor || grayIconFill}
				stroke={fillColor || grayIconFill}
				strokeWidth={strokeWidth}
			/>
		</SvgIcon>
	);
};
