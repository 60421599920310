import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	viewBox?: string;
}

export const ChatUserIcon: React.FC<IProps> = props => {
	const { className, fillColor, viewBox } = props;
	return (
		<SvgIcon className={`chat-user-icon ${className || ''}`} height={24} viewBox={viewBox} width={24}>
			<g>
				<path
					d='M5.5 15.5a2.834 2.834 0 1 0-2.833-2.833A2.836 2.836 0 0 0 5.5 15.5zm0-4.667a1.834 1.834 0 1 1-1.833 1.834A1.836 1.836 0 0 1 5.5 10.833zM7.41 16.833H3.59a2.093 2.093 0 0 0-2.09 2.09V22a.5.5 0 0 0 1 0v-3.077a1.091 1.091 0 0 1 1.09-1.09h3.82a1.091 1.091 0 0 1 1.09 1.09V22a.5.5 0 0 0 1 0v-3.077a2.092 2.092 0 0 0-2.09-2.09zM20.3 1.5h-8.6a2.2 2.2 0 0 0-2.2 2.2v5.6a2.2 2.2 0 0 0 2 2.191V14a.5.5 0 0 0 .288.453.507.507 0 0 0 .212.047.5.5 0 0 0 .32-.116l3.461-2.884H20.3a2.2 2.2 0 0 0 2.2-2.2V3.7a2.2 2.2 0 0 0-2.2-2.2zm1.2 7.8a1.2 1.2 0 0 1-1.2 1.2h-4.7a.5.5 0 0 0-.32.116l-2.78 2.317V11a.5.5 0 0 0-.5-.5h-.3a1.2 1.2 0 0 1-1.2-1.2V3.7a1.2 1.2 0 0 1 1.2-1.2h8.6a1.2 1.2 0 0 1 1.2 1.2z'
					fill={fillColor}
				/>
				<path
					d='M19 4.5h-6a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zM16 7.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z'
					fill={fillColor}
				/>
			</g>
		</SvgIcon>
	);
};
