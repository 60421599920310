import { TextInput } from '../../../web/components/TextInput';
import { ChangeEventHandler } from 'react';
import * as React from 'react';

interface IProps {
	className?: string;
	handler: ChangeEventHandler;
	id: string;
	label?: JSX.Element;
	value: string;
}

export const InputField: React.FC<IProps> = ({ className = '', handler, id, label, value }) => {
	return (
		<div className={className}>
			<label htmlFor={id}>{label}</label>
			<TextInput inputId={id} onChange={handler} type='text' value={`${value || ''}`} />
		</div>
	);
};
