import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { PrizesScore } from '../../../components/LeaderboardComponents/PrizesScore';
import { useLeaderboardPrizes } from '../../../entities/Leaderboard/usePrizes';
import { FetchStatus } from '../../../entities/utils/useStatesOnFetch';
import { useInterval } from '../../../hooks/useInterval';
import { IPrizeLogFilter } from '../../../models/slotMachines';
import { styleSheet } from '../styles';
import { DailySlider } from './DailySlider';
import { css } from 'aphrodite';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';

let isFirstLoading = true;

export const Prizes: React.FC = () => {
	const [filter, setFilter] = useState<IPrizeLogFilter>({
		date: {
			end: moment().add(1, 'day').startOf('day').toDate(),
			start: moment().startOf('day').toDate(),
		},
	});

	const { get, data, empty, status } = useLeaderboardPrizes();

	useEffect(() => {
		get({ filter });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		if (status === FetchStatus.Done) {
			isFirstLoading = false;
		}
	}, [status]);

	useInterval(() => {
		get({ filter });
	}, 30000);

	const renderEmptyState = (
		<div className={css(styleSheet.noLeaders)}>
			<div className='no-leaders-header'>No data yet.</div>
		</div>
	);

	const handleFilterChange = (value: string) => {
		setFilter({
			date: {
				end: moment(value).add(1, 'day').startOf('day').toDate(),
				start: moment(value).startOf('day').toDate(),
			},
		});
	};

	const renderFilter = useMemo(() => <DailySlider onChange={handleFilterChange} />, []);

	const renderList = () => {
		// @ts-ignore
		const emptyState = empty || data?.length <= 0 ? renderEmptyState : null;
		// @ts-ignore
		// @ts-ignore
		return <PrizesScore renderEmptyState={emptyState} renderFilter={renderFilter} data={data} />;
	};

	const isLoading = status !== FetchStatus.Done;

	return (
		<div className={css(styleSheet.leaderboardContainer)}>
			<div className={css(styleSheet.boardsContainer)}>
				{isLoading && isFirstLoading ? <LoadingSpinner /> : renderList()}
			</div>
		</div>
	);
};
