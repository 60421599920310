import { CssSelectors } from '../../../web/styles/styles';
import { background, borderColorLight, grayDark } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	box: {
		maxWidth: 500,
		...CssSelectors.allDescendants(
			{
				background,
				borderRadius: 10,
				height: 200,
				padding: 20,
			},
			'textarea'
		),
	},
	container: {
		':first-of-type': {
			maxWidth: 'none',
		},
		background: borderColorLight,
		borderCollapse: 'collapse',
		borderRadius: 10,
		display: 'flex',
		justifyContent: 'center',
		margin: '0 40px 0 40px',
		minHeight: 100,
		padding: 20,
		paddingBottom: 80,
		paddingTop: 80,
		width: 'calc(100% - 80px)',
	},
	description: {
		color: grayDark,
		textAlign: 'center',
	},
	header: {
		fontSize: 20,
		fontWeight: 800,
		textAlign: 'center',
	},
});
