import { aidaBaseStyleSheet } from '../../../../styles/styles';
import { ILeadInteraction } from '../../../../viewModels/leads/interfaces';
import { styleSheet } from './../styles';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';

export const Interaction: React.FC<ILeadInteraction> = ({ date, outcome }) => {
	return (
		<div>
			{outcome ? (
				<>
					<div className={css(styleSheet.lastInteraction)}>
						<span>{moment(date).format('ddd, MM/DD/YY, h:mmA')}</span>
					</div>
					<div className={css(aidaBaseStyleSheet.flexHorizontalCenter)}>
						<div className={css(styleSheet.lastInteraction)}>
							<div className={css(styleSheet.dot)} style={{ background: outcome?.hexColor }} />
							<span>{outcome.name}</span>
						</div>
					</div>
				</>
			) : (
				<span className={css(styleSheet.label)}>No interactions yet</span>
			)}
		</div>
	);
};
