import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import { brandPrimaryHover } from '../../../../styles/colors';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const VolumeMuteIcon: React.FC<IProps> = ({ className = '', fillColor = brandPrimaryHover }) => {
	return (
		<SvgIcon className={`volume-mute-icon ${className}`} height={18} width={20}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.521 10.997H2v-4h3.521L9 2.024l.001 13.949-3.48-4.976ZM9.764.149a2.019 2.019 0 0 0-2.178.434L4.479 4.997H2c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h2.479l2.995 4.28c.416.496.959.72 1.526.72a1.995 1.995 0 0 0 2-2v-14c0-.813-.485-1.538-1.236-1.848Z'
				fill={fillColor}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10 2.024v13.973l-1 .736-3.5-3.751-2.582-.985-1.79-.644V5.997H5.5l3.5-5 1 1.027Z'
				fill={fillColor}
			/>
			<rect width='2' height='9' rx='.8' transform='scale(-1 1) rotate(-45 -2.15 27.08)' fill={fillColor} />
			<rect width='2' height='9' rx='.8' transform='scale(1 -1) rotate(45 24.603 15.78)' fill={fillColor} />
		</SvgIcon>
	);
};
