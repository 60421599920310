import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	actions: {
		alignItems: 'center',
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-start',
		marginTop: 4,
		paddingLeft: 8,
	},
	disabled: {
		opacity: 0.5,
	},
	item: {
		flex: 1,
	},
	itemContainer: {
		display: 'flex',
		width: '100%',
	},
});
