import { brandSecondary } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	leadCard: {
		marginBottom: 30,
		marginTop: 20,
	},
	leadRow: {
		alignItems: 'center',
		borderBottom: `1px solid ${brandSecondary}`,
		display: 'flex',
	},
	leadRowSelect: {
		width: 30,
	},
	loading: {
		marginTop: 20,
	},
	noResults: {
		color: '#fff',
		marginTop: 20,
		textAlign: 'center',
	},
});
