import * as Api from '@ViewModels';

export interface ISlotMachineReelSymbol {
	id: string;
	index: number;
	value: Api.SlotMachineSymbol;
}

export interface ISlotMachineReel {
	id: string;
	symbols: ISlotMachineReelSymbol[];
}

export interface ISlotMachineComponent {
	PreloadImageUrls: string[];
}

export const getSlotMachineName = (machineType: Api.SlotMachineType) => {
	switch (machineType) {
		case Api.SlotMachineType.BoozeCruise: {
			return "Daisy's Booze Cruise";
		}
		case Api.SlotMachineType.GoldRush: {
			return 'Gold Rush';
		}
		default: {
			return 'High Score';
		}
	}
};

export interface IPrizeLogFilter {
	date?: Api.IRange<Date>;
	prizePaid?: boolean;
	user?: Api.IUser;
}
