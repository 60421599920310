import { StyleSheet } from 'aphrodite';
import { brandPrimaryHover } from '../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		backgroundColor: brandPrimaryHover,
		backgroundPosition: `center 20px`,
		backgroundSize: '1300px auto',
		height: '100vh',
		left: 0,
		overflow: 'hidden',
		position: 'fixed',
		top: 0,
		width: '100vw',
	},
});
