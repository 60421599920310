import { CssSelectors, FontFamilies } from '../../../web/styles/styles';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	close: {
		position: 'absolute',
		right: 0,
		top: 0,
	},
	header: {
		...aidaBaseStyles.flexCenter,
		paddingBottom: 25,
		position: 'relative',
	},
	leadCard: {
		marginBottom: 20,
	},
	noResults: {
		color: '#fff',
		textAlign: 'center',
	},
	placeholder: {
		textAlign: 'center',
	},
	placeholderImage: {
		marginTop: 50,
		width: 600,
	},
	resultsContainer: {
		minHeight: 120,
		paddingTop: 20,
		position: 'relative',
	},
	searchInput: {
		borderRadius: 10,
		padding: 15,
		...CssSelectors.allChildren(
			{
				fontSize: '16px',
				paddingLeft: 15,
			},
			'input'
		),
		...CssSelectors.allChildren(
			{
				height: 20,
				width: 20,
			},
			'svg'
		),
	},
	searchModal: {
		alignItems: 'flex-start',
		...CssSelectors.allChildren(
			{
				background: 'none',
				border: 'none',
				boxShadow: 'none',
				marginTop: 20,
				maxWidth: '1040px',
				width: '80vw',
			},
			'.modal-overlay-content'
		),
	},
	searchModalContainer: {},
	smallTitle: {
		fontSize: '35px',
		textAlign: 'center',
	},
	title: {
		color: '#fff',
		fontFamily: FontFamilies.conqueror,
		fontSize: '54px',
		textTransform: 'uppercase',
	},
});
