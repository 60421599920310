import { CssSelectors } from '../../../web/styles/styles';
import { brandPrimary, brandPrimaryLight } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	anchor: {
		boxShadow: '0 2px 4px 0 rgba(0,0,0,0.50);',
		opacity: 1,
	},
	anchorContainer: {
		height: 55,
		position: 'absolute',
		right: 20,
		top: 20,
		width: 55,
	},
	fabMenuItem: {
		':hover': {
			cursor: 'pointer',
		},
		background: brandPrimaryLight,
		borderRadius: '50%',
		boxShadow: '0 2px 4px 0 rgba(0,0,0,0.50)',
		height: 55,
		position: 'relative',
		width: 55,
	},
	item: {
		':hover': {
			...CssSelectors.allChildren(
				{
					opacity: 1,
					pointerEvents: 'all',
				},
				'.fab-tooltip'
			),
		},
		...CssSelectors.allChildren(
			{
				pointerEvents: 'none',
			},
			'.fab-tooltip'
		),
	},
	itemIcon: {
		height: 55,
		...aidaBaseStyles.flexCenter,
	},
	itemTooltip: {
		background: brandPrimary,
		borderRadius: 2,
		color: '#fff',
		fontSize: '14px',
		opacity: 0,
		padding: '4px 12px',
		position: 'absolute',
		right: 'calc(100% + 10px)',
		top: '50%',
		transform: 'translateY(-50%)',
		transition: '.2s',
		whiteSpace: 'nowrap',
	},
	menuContainer: {
		border: '1px solid transparent',
	},
	menuItem: {
		marginTop: 10,
	},
});
