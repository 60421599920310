import { RadioButton } from '../../../../web/components/RadioButton';
import { useQueue } from '../../../hooks/queue';
import { FieldKey } from '../../../viewModels/form';
import { ISkipReason } from '../../../viewModels/queue';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	className?: string;
	options: ISkipReason[];
	onReasonSelect(reason: ISkipReason): void;
}

export const SkipOptionsBase: React.FC<IProps> = ({ className = '', options, onReasonSelect }) => {
	const queue = useQueue();

	const onOptionChange = (reason: ISkipReason) => () => {
		onReasonSelect(reason);
		queue.lead.clearFollowUpOptions();
	};

	const renderSkipOptions = () => {
		return (options || []).map((option, i) => (
			<div className={css(styleSheet.skipOptionContainer)} key={`skip-option-${i}`}>
				<RadioButton
					checked={option.id === queue.skipReason?.id}
					id={`skip-option-radio-${option.id}`}
					onChange={onOptionChange(option)}
				>
					<span className={css(styleSheet.label, option.name === FieldKey.Other && styleSheet.bold)}>
						{option.label}
					</span>
				</RadioButton>
			</div>
		));
	};

	return (
		<div className={`${css(styleSheet.skipOptionsContainer)} ${className}`}>
			<div className={css(styleSheet.skipOptions)}>{renderSkipOptions()}</div>
		</div>
	);
};

export const SkipOptions = observer(SkipOptionsBase);
