import { HeaderSize } from '..';
import { ViewportContext } from '../../../hooks/useViewport';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import { useContext } from 'react';
import * as React from 'react';

interface IProps {
	headerHeight: HeaderSize;
}

export const BodyContainer: React.FC<IProps> = ({ children, headerHeight }) => {
	// @ts-ignore
	const { isBelowSmall } = useContext(ViewportContext);
	return (
		<div
			className={css(
				styleSheet.bodyContainer,
				isBelowSmall
					? styleSheet.mobileHeader
					: headerHeight === 'tall'
						? styleSheet.tallHeader
						: styleSheet.shortHeader
			)}
		>
			{children}
		</div>
	);
};
