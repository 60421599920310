import { IContact } from '../../../extViewmodels';
import { useSearch } from '../../contexts/search';
import { ILeadSearchView } from '../../viewModels/leads/interfaces';
import { LeadServedSource } from '../../viewModels/queue';
import { CardView } from '../CardView';
import { CardableContact } from '../CardableContact';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

interface IProps {
	lead?: ILeadSearchView;
	source: LeadServedSource;
}

export const LeadCard: React.FC<IProps> = ({ lead, source }) => {
	const history = useHistory();
	const searchModal = useSearch();
	const renderContactInfo = () => {
		// order of precidence
		// 1. contact spoke with if one was connected with in last outcome
		// 2. first dm listed
		// 3. first contact listed

		// @ts-ignore
		let contact: IContact = null;

		// TODO: check for last engagment and get listed contact if present
		// TODO: if no contact already, get the first instance of a dm if present

		// @ts-ignore
		if (!contact && lead.contactReferences?.length) {
			// @ts-ignore
			contact = lead.contactReferences[0];
		}

		return <CardableContact contact={contact} />;
	};

	const onClick = () => {
		history.push({
			pathname: '/queue',
			// @ts-ignore
			// @ts-ignore
			search: `?from=${source}&companyId=${lead.company.id}`,
		});
		searchModal.setShowSearch(false);
	};

	if (!lead) {
		return null;
	}

	return (
		<CardView
			followUp={lead.followUp}
			lastDeal={lead.lastDeal}
			nextMeeting={lead.nextMeeting}
			name={lead.company?.companyName}
			// @ts-ignore
			leadId={lead.company?.id}
			// @ts-ignore
			phone={lead.company?.phoneNumbers[0]?.metadata?.standard}
			website={lead.company?.webSite}
			onClick={onClick}
			contactInfo={renderContactInfo}
			interaction={{
				date: lead.lastInteraction?.date,
				dateLabel: 'Last Called:',
				outcome: lead.lastInteraction?.outcome?.name,
				outcomeColor: lead.lastInteraction?.outcome?.hexColor,
				outcomeLabel: 'Outcome:',
			}}
		/>
	);
};
