import { GroupProps } from './';
import { StyleSheet } from 'aphrodite';

export const styleSheet = ({ fullWidth, hCentered, noWrap, spacingSize, vCentered, vertical }: Partial<GroupProps>) => {
	return StyleSheet.create({
		base: {
			alignItems: vertical && hCentered ? 'center' : !vertical && vCentered ? 'center' : 'initial',
			display: 'flex',
			flexDirection: vertical ? 'column' : 'initial',
			flexWrap: noWrap ? 'nowrap' : 'wrap',
			height: 'auto',
			justifyContent: vertical && vCentered ? 'center' : !vertical && hCentered ? 'center' : 'initial',
			width: fullWidth ? '100%' : 'fit-content',
		},
		spacing: {
			flexGrow: 0,
			flexShrink: 0,
			height: vertical ? spacingSize : 0,
			width: vertical ? 0 : spacingSize,
		},
	});
};
