import * as React from 'react';
import { useEventLogging } from '../../../../../models/Logging';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { useErrorMessages } from '../../../../hooks';
import { GameTemplatesViewModel } from '../../../../viewModels/games';
import { SlotMachineAchievementsViewModel } from '../../../../viewModels/slotMachines';

export const useSlotMachineAchievements = () => {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('AchievementsConfigurationTable');
	const achievements = React.useRef<SlotMachineAchievementsViewModel>(
		new SlotMachineAchievementsViewModel(userSession)
	).current;
	const gameTemplates = React.useRef<GameTemplatesViewModel>(new GameTemplatesViewModel(userSession)).current;

	React.useEffect(() => {
		(async () => {
			try {
				await Promise.all([achievements.load(), gameTemplates.provider.getNext()]);
			} catch (error) {
				// @ts-ignore
				logApiError(error);
				// @ts-ignore
				// @ts-ignore
				errorMessages.pushApiError(error);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		achievements,
		gameTemplates,
	} as const;
};
