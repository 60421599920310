import { backgroundGreen, backgroundGreenDark, brandPrimary } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		':hover': {
			background: backgroundGreenDark,
		},
		background: backgroundGreen,
		borderRadius: 6,
		cursor: 'pointer',
		marginBottom: 10,
		padding: 14,
	},
	prompt: {
		color: '#4F7726',
		fontSize: 11,
		letterSpacing: 1.1,
		textTransform: 'uppercase',
	},
	time: {
		color: brandPrimary,
	},
});
