import { StyleDeclaration, StyleSheet } from 'aphrodite';
import { Style } from '../../web/styles/styles';
import { brandPrimary, brandSecondary, brandSecondaryHover } from './colors';

export const headerHeight = 65;

const flexCenter: Style = {
	alignItems: 'center',
	display: 'flex',
};

export const aidaBaseStyles: StyleDeclaration<{
	absoluteCenter: Style;
	brandLink: Style;
	brandSecondaryLink: Style;
	cardShadow: Style;
	disabled: Style;
	flexCenter: Style;
	flexCol: Style;
	flexHorizontalCenter: Style;
	textField: Style;
	truncateText: Style;
}> = {
	absoluteCenter: {
		left: '50%',
		position: 'absolute',
		top: '50%',
		transform: 'translate(-50%, -50%)',
	},
	brandLink: {
		color: brandPrimary,
	},
	brandSecondaryLink: {
		':hover': {
			color: brandSecondaryHover,
			textDecoration: 'underline',
		},
		color: brandSecondary,
		textDecoration: 'none',
	},
	cardShadow: {
		boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
	},
	disabled: {
		cursor: 'not-allowed',
		opacity: 0.5,
	},
	flexCenter: {
		...flexCenter,
		justifyContent: 'center',
	},
	flexCol: {
		display: 'flex',
		flexDirection: 'column',
	},
	flexHorizontalCenter: {
		...flexCenter,
	},
	textField: {},
	truncateText: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
};

export const aidaBaseStyleSheet = StyleSheet.create(aidaBaseStyles);
