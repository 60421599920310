import { IPhoneCallCategory } from '../../../../extViewmodels';
import { Button } from '../../../../web/components/Button';
import { usePhoneCallCategory } from '../../../entities/PhoneCall/usePhoneCallCategory';
import { BasicPage } from '../../BasicPage';
import { CategoryCard } from './components/CategoryCard';
import { CategoryNewModal } from './components/CategoryNewModal';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { useEffect, useState } from 'react';
import * as React from 'react';

export const Categories: React.FC = () => {
	const [newCatModal, setNewCatModal] = useState(false);

	const list = usePhoneCallCategory<IPhoneCallCategory[]>();

	useEffect(() => {
		list.get();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleNewCategory = () => {
		setNewCatModal(true);
	};

	const onCloseNewCategory = (success: boolean) => {
		if (success) {
			list.get();
		}
		setNewCatModal(false);
	};

	return (
		<BasicPage title='Recording Categories'>
			<div className={css(styleSheet.container)}>
				<div className={css(styleSheet.actionsBar)}>
					<Button label='New Category' onClick={handleNewCategory} kind='secondary' />
				</div>

				{newCatModal && <CategoryNewModal onRequestClose={onCloseNewCategory} />}

				{(list.data || [])?.map(item => {
					return <CategoryCard key={item.name} category={item} onRequestToUpdateList={list.get} />;
				})}
			</div>
		</BasicPage>
	);
};
