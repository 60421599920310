import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	checkboxLabel: {
		color: 'inherit',
		fontSize: 14,
		marginLeft: 5,
	},
	checkboxLabelSmall: {
		fontSize: 12,
		marginLeft: 5,
	},
	checkboxWrapper: {
		color: 'inherit',
		display: 'inline-block',
	},
});
