import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	description: {
		fontSize: 12,
		padding: 10,
		paddingBottom: 12,
	},
	icon: {
		marginLeft: 5,
		marginTop: 5,
	},
	leadStatusesContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		flexWrap: 'wrap',
		minWidth: '100%',
	},
	status: {
		alignItems: 'center',
		color: '#fff',
		display: 'flex',
		fontSize: '12px',
		height: 28,
		margin: '0 8px 4px 0',
		padding: '5px 20px',
	},
});
