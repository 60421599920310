import * as Api from '@ViewModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useErrorMessages } from '../../hooks';
import { ApiClient } from '../../services/api';
import { IPhoneCallFilterCriteria, PhoneCallFilterCriteriaProperty } from '../../viewModels/phonecall';
import { formatOptional, formatUser } from '../utils/criteriaBuilder';
import { useStatesOnFetch } from '../utils/useStatesOnFetch';

export interface IPhoneCallFilter {
	direction?: 'Inbound';
	user?: Api.IUser;
}

function filterToCriteria(filter: IPhoneCallFilter): IPhoneCallFilterCriteria[] {
	const criteria: IPhoneCallFilterCriteria[] = [];

	// @ts-ignore
	criteria.push(formatUser<PhoneCallFilterCriteriaProperty>(PhoneCallFilterCriteriaProperty.User, filter?.user));
	criteria.push(
		// @ts-ignore
		formatOptional<PhoneCallFilterCriteriaProperty>(PhoneCallFilterCriteriaProperty.Direction, filter?.direction)
	);

	return criteria.filter(item => !!item);
}

export const usePhoneCall = <T>() => {
	const { state, handleWorking, handleResponse, reset } = useStatesOnFetch<T>();

	const userSession = useUserSession();
	const errorMessages = useErrorMessages();

	// @ts-ignore
	const client = new ApiClient(userSession);

	const get = async (filter: IPhoneCallFilter) => {
		handleWorking();
		try {
			const response = await client.getPhoneCall(filterToCriteria(filter));
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response);
			return response;
		} catch (error) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
	};

	const getById = async (recentCallId: string) => {
		handleWorking();
		try {
			const response = await client.getPhoneCallById(recentCallId);
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response);
			return response;
		} catch (error) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
	};

	return {
		...state,
		empty: !state?.data,
		get,
		getById,
		reset,
		status: state.status,
	};
};
