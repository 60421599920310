import { createContext, useEffect, useState } from 'react';
import * as React from 'react';

export interface IViewportContextInterface {
	width: number;
	height: number;
	isXSmall?: boolean;
	isSmall?: boolean;
	isMedium?: boolean;
	isLarge?: boolean;
	isXLarge?: boolean;
	isAboveXSmall?: boolean;
	isAboveSmall?: boolean;
	isAboveMedium?: boolean;
	isAboveLarge?: boolean;
	isBelowXSmall?: boolean;
	isBelowSmall?: boolean;
	isBelowMedium?: boolean;
	isBelowLarge?: boolean;
}

export enum breakpoints {
	xs = 415,
	sm = 768,
	md = 1024,
	lg = 1280,
	xl = 2048,
}

export const useViewport = (customHandler?: () => void) => {
	const [sizes, setSizes] = useState<IViewportContextInterface>({
		height: window.innerHeight,
		width: window.innerWidth,
	});
	const handler = () => {
		setSizes({
			height: window.innerHeight,
			width: window.innerWidth,
		});
		customHandler?.();
	};
	useEffect(() => {
		window.addEventListener('resize', handler);
		return () => window.removeEventListener('resize', handler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return {
		isAboveLarge: sizes.width >= breakpoints.lg,
		isAboveMedium: sizes.width >= breakpoints.md,
		isAboveSmall: sizes.width >= breakpoints.sm,
		isAboveXSmall: sizes.width >= breakpoints.xs,
		isBelowLarge: sizes.width <= breakpoints.lg,
		isBelowMedium: sizes.width <= breakpoints.md,
		isBelowSmall: sizes.width <= breakpoints.sm,
		isBelowXSmall: sizes.width <= breakpoints.xs,
		isLarge: sizes.width >= breakpoints.lg && sizes.width <= breakpoints.xl,
		isMedium: sizes.width >= breakpoints.md && sizes.width <= breakpoints.lg,
		isSmall: sizes.width >= breakpoints.sm && sizes.width <= breakpoints.md,
		isXLarge: sizes.width >= breakpoints.xl,
		isXSmall: sizes.width >= breakpoints.xs && sizes.width <= breakpoints.sm,
		...sizes,
	};
};

export const ViewportContext = createContext<IViewportContextInterface | null>(null);

export const ViewportProvider: React.FC<React.ReactNode> = ({ children }) => {
	const sizes: IViewportContextInterface = useViewport();
	return <ViewportContext.Provider value={sizes}>{children}</ViewportContext.Provider>;
};
