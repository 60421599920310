import { ICompany, IContact, IPhoneCall } from '../../../../extViewmodels';
import { ILeadInteraction } from '../../../viewModels/leads/interfaces';
import { ILeadFollowUp, ILeadNextMeeting } from '../../../viewModels/queue';
import { CardableContact } from '../../CardableContact';
import { CompanyInfo } from './components/CompanyInfo';
import { FollowUp } from './components/FollowUp';
import { FullDateIndicator } from './components/FullDateIndicator';
import { Interaction } from './components/Interaction';
import { Recording } from './components/Recording';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

type TDisplayType =
	| 'DemoDate'
	| 'FollowUp'
	| 'NextMeeting'
	| 'Interaction'
	| 'CompanyInfo'
	| 'ContactInfo'
	| 'MultilineText'
	| 'Text'
	| 'CallInfo'
	| 'Recording';

type TLeadViewItem = ILeadNextMeeting | ILeadFollowUp | ICompany | ILeadInteraction | IPhoneCall | IContact | string;

export interface ILeadViewRowElementProps {
	displayType: TDisplayType;
	value: TLeadViewItem;
	width: number;
}

export const LeadViewRowElement: React.FC<ILeadViewRowElementProps> = props => {
	const { value, width, displayType } = props;

	const renderType = (type: TDisplayType) => {
		switch (type) {
			case 'DemoDate': {
				const valueToUse = value as ILeadNextMeeting;
				return <FullDateIndicator dateValue={valueToUse.startDate} />;
			}
			case 'FollowUp': {
				return <FollowUp {...(value as ILeadFollowUp)} />;
			}
			case 'NextMeeting': {
				return <div>not imp</div>;
			}
			case 'CompanyInfo': {
				return <CompanyInfo {...(value as ICompany)} />;
			}
			case 'MultilineText':
			case 'Text': {
				const text = value as string;
				return <div className={css(styleSheet.fontSizeSmall)}>{text}</div>;
			}
			case 'CallInfo': {
				return <div>not imp</div>;
			}
			case 'Recording': {
				return <Recording {...(value as IPhoneCall)} />;
			}
			case 'Interaction': {
				return <Interaction {...(value as ILeadInteraction)} />;
			}
			case 'ContactInfo': {
				const contact = value as IContact;
				return <CardableContact contact={contact} />;
			}
			default: {
				return null;
			}
		}
	};

	return (
		<div className='lead-view-row-element' style={{ width }}>
			{renderType(displayType)}
		</div>
	);
};
