import * as React from 'react';

export interface IColumnWrapperProps {
	width: number;
}

export const ColumnWrapper: React.FC<IColumnWrapperProps> = ({ children, width }) => {
	return (
		<div className='column-wrapper' style={{ width }}>
			{children}
		</div>
	);
};
