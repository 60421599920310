import { success, successTint } from '../../../web/styles/colors';
import { CssSelectors } from '../../../web/styles/styles';
import { brandPrimaryHover, gray, titles } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

const pulseBorderKeyframes = {
	'0%': {
		boxShadow: `0 0 0 0 rgba(137, 201, 71, 0.7)`,
		transform: 'scale(0.95)',
	},
	'100%': {
		boxShadow: `0 0 0 0 rgba(137, 201, 71, 0)`,
		transform: 'scale(0.95)',
	},
	'70%': {
		boxShadow: `0 0 0 6px rgba(137, 201, 71, 0)`,
		transform: 'scale(1)',
	},
};

export const styleSheet = StyleSheet.create({
	bubble: {
		...aidaBaseStyles.flexCenter,
		animationDuration: '2s',
		animationIterationCount: 'infinite',
		animationName: [pulseBorderKeyframes],
		backgroundColor: success,
		borderRadius: '50%',
		bottom: -10,
		boxShadow: '0 0 0 0 rgba(137, 201, 71, 0)',
		color: 'white',
		fontSize: 10,
		height: 20,
		padding: 4,
		pointerEvents: 'none',
		position: 'absolute',
		right: -10,
		transform: 'scale(1)',
		transition: 'outline',
		width: 20,
	},
	clientsContainer: {
		height: '100%',
		marginRight: 20,
	},
	clientsList: {
		'::-webkit-scrollbar': {
			background: successTint,
			width: 5,
		},
		'::-webkit-scrollbar-thumb': {
			backgroundColor: gray,
			borderRadius: 6,
			padding: '0',
		},
		marginTop: 14,
		...CssSelectors.allChildren(
			{
				color: titles,
				fontSize: 14,
			},
			'div'
		),
		maxHeight: 200,
		overflowY: 'auto',
	},
	clientsNearMeContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		maxWidth: 90,
		...CssSelectors.allChildren(
			{
				color: brandPrimaryHover,
				flexBasis: 50,
				flexGrow: 0,
				flexShrink: 0,
				fontSize: '12px',
				marginRight: 10,
			},
			':first-child'
		),
	},
	globe: {
		...aidaBaseStyles.flexCenter,
		position: 'relative',
	},
	popoverContainer: {
		...aidaBaseStyles.flexCenter,
		alignItems: 'flex-start',
		background: successTint,
		borderRadius: 10,
		marginLeft: 20,
		padding: 20,
		transform: 'translate3d(0, -50%, 0)',
	},
	totalClients: {
		color: brandPrimaryHover,
		fontSize: 14,
		letterSpacing: 1.1,
		textTransform: 'uppercase',
	},
});
