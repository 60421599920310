import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';
import Draggable, { DraggableProps } from 'react-draggable';

interface IProps extends Partial<DraggableProps> {
	isDraggable: boolean;
}

export const AidaDraggable: React.FC<IProps> = props => {
	const { children, isDraggable, ...restProps } = props;
	if (!isDraggable) {
		return <>{children}</>;
	}

	return (
		<Draggable
			handle='.drag-handle'
			defaultClassName={css(styleSheet.draggable, isDraggable && styleSheet.draggableHandle)}
			defaultClassNameDragging={css(styleSheet.dragging)}
			bounds='body'
			{...restProps}
		>
			{children}
		</Draggable>
	);
};
