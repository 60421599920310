import { ViewportContext } from '../../../hooks/useViewport';
import { SlotMachineSymbol } from '../../slotMachines/SlotMachineSymbol';
import { styleSheet } from '../styles';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import { useContext } from 'react';
import * as React from 'react';

export interface IDemosListRowItem {
	name?: string;
	label?: string;
	goal: number;
	demos: number;
}

interface IProps {
	date: string;
	name: string;
	prize: string;
}

export const PrizesList: React.FC<IProps> = ({ date, name, prize }) => {
	// @ts-ignore
	const { isBelowSmall } = useContext(ViewportContext);

	const formattedDate = moment(date).format('MM/DD/YYYY');

	const renderRows = (
		<div className={css(styleSheet.prizeRow)}>
			<div className={css(styleSheet.colSymbol)} title={name}>
				<SlotMachineSymbol type={Api.SlotMachineSymbol.LevitateCoin} style={{ height: 26, width: 'auto' }} />
			</div>
			<div className={css(styleSheet.col, styleSheet.colName, isBelowSmall ? styleSheet.colMobile : null)} title={name}>
				{name}
			</div>
			{isBelowSmall ? (
				<div className={css(styleSheet.col, styleSheet.colMobile)} title=''>
					{formattedDate} / <span className={css(styleSheet.colPrize)}>{prize}</span>
				</div>
			) : (
				<>
					<div className={css(styleSheet.col, styleSheet.colDate)} title={formattedDate}>
						{formattedDate}
					</div>
					<div className={css(styleSheet.col, styleSheet.colPrize)} title={formattedDate}>
						{prize}
					</div>
				</>
			)}
		</div>
	);

	return <div className={css(styleSheet.prizesContainer)}>{renderRows}</div>;
};
