import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import shallowEqual from 'shallowequal';
import { Noop } from '../../../../extViewmodels/Utils';
import { IImpersonationContextComponentProps } from '../../../../models';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { TinyPopover } from '../../../../web/components/TinyPopover';
import { ResourceAutoCompleteViewModel } from '../autocomplete';
import { styleSheet } from './styles';

export type AutocompleteDropdownAnchorProvider = (inputElement: JSX.Element) => React.ReactElement<any>;

export interface IAutoCompleteDropdownComponentProps<TItem = any, TTransformedItem = any>
	extends IImpersonationContextComponentProps {
	anchor?: AutocompleteDropdownAnchorProvider;
	autoCompleteViewModel: ResourceAutoCompleteViewModel<TItem>;
	className?: string;
	contentPositionY?: 'top' | 'bottom';
	disableAutocomplete?: boolean;
	dropdownContentClassName?: string;
	dropdownContentItemsStyles?: StyleDeclarationValue[];
	getDefaultItems?(): TTransformedItem[];
	initialSearchQuery?: string;
	inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
	isOpen?: boolean;
	loaderType?: 'login' | 'large' | 'small';
	onInputRef?: React.Ref<HTMLInputElement>;
	onItemSelected?(item: TTransformedItem): void;
	onOpenChanged?(isOpen?: boolean): void;
	onRenderEmptyResults?(searchQuery: string): React.ReactNode;
	onRenderItem(
		item: TTransformedItem,
		index: number,
		highlighted: boolean,
		onClick: (e?: React.MouseEvent<HTMLElement>) => void
	): React.ReactElement<any>;
	onRenderLimitedResults?(
		renderedItems: TTransformedItem[],
		overflowItems: TTransformedItem[],
		totalCount: number
	): React.ReactNode;
	onRenderResultsFooter?(
		searchQuery: string,
		renderedItems: TTransformedItem[],
		totalCount: number,
		overflowItems?: TTransformedItem[]
	): React.ReactNode;
	onRequestDeleteBackwards?(): void;
	resultsLimit?: number;
	searchQuery?: string;
	searchResultsFilter?(searchResults?: TItem[]): TItem[];
	searchResultsTransformer?(searchResults?: TItem[]): TTransformedItem[];
}

interface IState {
	disableAutocomplete?: boolean;
	highlightedItemIndex?: number;
	inputHasFocus?: boolean;
	inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
	isOpen?: boolean;
	searchQuery?: string;
}

class _AutoCompleteDropdown<TItem = any, TTransformedItem = any> extends React.Component<
	IAutoCompleteDropdownComponentProps<TItem, TTransformedItem>,
	IState
> {
	private fieldInput: HTMLInputElement;
	public state: IState = {
		highlightedItemIndex: -1,
	};

	public UNSAFE_componentWillMount() {
		const nextState = this.getNextStateWithProps(this.props);
		if (!!nextState || !!this.props.initialSearchQuery) {
			this.setState({
				...nextState,
				searchQuery: this.props.initialSearchQuery,
			});

			// kick off the initial search
			if (!!this.props.initialSearchQuery && !!this.props.autoCompleteViewModel) {
				this.props.autoCompleteViewModel.setSearchQuery(this.props.initialSearchQuery);
			}
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IAutoCompleteDropdownComponentProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public componentWillUnmount() {
		// IE11 needs this... I assume because we remove the active element
		if (!!this.fieldInput && this.fieldInput === document.activeElement) {
			(document.activeElement as HTMLElement).blur();
		}
	}

	// private fieldInput: HTMLInputElement;
	public render() {
		const {
			anchor,
			autoCompleteViewModel,
			disableAutocomplete,
			dropdownContentItemsStyles,
			className,
			loaderType,
			onRenderEmptyResults,
			onRenderItem,
			onRenderLimitedResults,
			onRenderResultsFooter,
			resultsLimit,
		} = this.props;

		const { highlightedItemIndex, inputHasFocus, isOpen, searchQuery } = this.state;

		const { onBlur, onChange, onFocus, onKeyDown, ref, value, ...restInputProps } = (this.state.inputProps ||
			{}) as React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

		let defaultItems: TTransformedItem[] = [];
		if (!this.searchResults || !searchQuery) {
			defaultItems = (!!this.props.getDefaultItems && this.props.getDefaultItems()) || [];
		}

		let itemsToRender = (
			!!this.searchResults && !!searchQuery ? this.searchResults : defaultItems
		) as TTransformedItem[];
		let overflowItems: TTransformedItem[];
		if (!!resultsLimit && resultsLimit > 0 && !!itemsToRender && itemsToRender.length > resultsLimit) {
			overflowItems = itemsToRender.slice(resultsLimit);
			itemsToRender = itemsToRender.slice(0, resultsLimit);
		}

		const input = (
			<input
				{...restInputProps}
				onBlur={disableAutocomplete ? onBlur : this.toggleIsOpen(onBlur, false)}
				onChange={this.onInputTextChanged(onChange)}
				onFocus={disableAutocomplete ? onFocus : this.toggleIsOpen(onFocus, true)}
				onKeyDown={disableAutocomplete ? onKeyDown : this.onKeyDown(onKeyDown)}
				ref={this.onInputRef(ref)}
				value={value || searchQuery || ''}
			/>
		);

		const renderedAnchor = anchor
			? typeof anchor === 'function'
				? (anchor as AutocompleteDropdownAnchorProvider)(input)
				: anchor
			: null;

		const footer = onRenderResultsFooter
			? onRenderResultsFooter(searchQuery, itemsToRender || [], autoCompleteViewModel.totalCount, overflowItems)
			: null;

		const styles = [styleSheet.dropdownContent];

		return (
			<TinyPopover
				className={className}
				contentStyles={styles}
				anchor={renderedAnchor}
				dismissOnOutsideAction={true}
				isOpen={((!!searchQuery && !!this.searchResults) || autoCompleteViewModel.isBusy) && (inputHasFocus || isOpen)}
				onRequestClose={Noop}
				placement={['bottom', 'top']}
			>
				<React.Fragment key='content'>
					<div className={`${css(...(dropdownContentItemsStyles || []))}`}>
						{!!itemsToRender && !autoCompleteViewModel.isBusy
							? itemsToRender.length > 0
								? itemsToRender.map((item, i) => {
										return onRenderItem(item, i, i === highlightedItemIndex, this.onItemClicked(item, i));
									})
								: onRenderEmptyResults
									? onRenderEmptyResults(searchQuery)
									: null
							: null}
					</div>
					{!autoCompleteViewModel.isBusy &&
						!!onRenderLimitedResults &&
						this.searchResults.length < autoCompleteViewModel.totalCount &&
						onRenderLimitedResults(itemsToRender || [], overflowItems, this.searchResults.length)}
					{!autoCompleteViewModel.isBusy && footer}
					{autoCompleteViewModel.isBusy && (
						<LoadingSpinner type={loaderType} className={`autocomplete-list-loading ${css(styleSheet.loading)}`} />
					)}
				</React.Fragment>
			</TinyPopover>
		);
	}

	public focus = () => {
		if (this.fieldInput) {
			this.setState(
				{
					inputHasFocus: true,
				},
				() => {
					this.fieldInput.focus();
				}
			);
		}
	};

	public blur = () => {
		if (this.fieldInput) {
			this.setState(
				{
					highlightedItemIndex: -1,
					inputHasFocus: false,
				},
				() => {
					this.fieldInput.blur();
				}
			);
		}
	};

	public reset = () => {
		this.setState({
			inputHasFocus: false,
			searchQuery: null,
		});

		this.props.autoCompleteViewModel.reset();

		if (this.fieldInput) {
			this.fieldInput.blur();
		}
	};

	private getNextStateWithProps = (nextProps: IAutoCompleteDropdownComponentProps) => {
		const nextState: IState = {};
		if (this.state.isOpen !== nextProps.isOpen) {
			nextState.isOpen = nextProps.isOpen;
		}

		if (this.state.disableAutocomplete !== nextProps.disableAutocomplete) {
			nextState.disableAutocomplete = nextProps.disableAutocomplete;
		}

		if (!shallowEqual(this.state.inputProps, nextProps.inputProps)) {
			nextState.inputProps = nextProps.inputProps;
		}

		if (
			this.state.searchQuery !== nextProps.searchQuery ||
			this.props.initialSearchQuery !== nextProps.initialSearchQuery
		) {
			nextState.searchQuery =
				this.state.searchQuery !== nextProps.searchQuery ? nextProps.searchQuery : this.props.initialSearchQuery;

			if (!!nextProps.autoCompleteViewModel && !nextProps.disableAutocomplete) {
				nextProps.autoCompleteViewModel.setSearchQuery(nextState.searchQuery);
			}
		}

		if (Object.keys(nextState).length > 0) {
			return nextState;
		}
		return nextState;
	};

	private onInputRef = (onRef?: React.LegacyRef<HTMLInputElement>) => (ref: HTMLInputElement) => {
		this.fieldInput = ref;
		if (this.props.onInputRef) {
			if (typeof this.props.onInputRef === 'function') {
				this.props.onInputRef(ref);
			} else {
				(this.props.onInputRef as React.MutableRefObject<HTMLInputElement>).current = ref;
			}
		}

		if (!!onRef && typeof onRef === 'function') {
			// only function refs are supported... no one should be using string refs
			onRef(ref);
		}
	};

	@computed
	private get searchResults() {
		if (this.props.autoCompleteViewModel.searchResults) {
			let results: TItem[] | TTransformedItem[] = this.props.autoCompleteViewModel.searchResults;
			if (this.props.searchResultsFilter) {
				results = this.props.searchResultsFilter(results) || [];
			}

			if (this.props.searchResultsTransformer) {
				results = this.props.searchResultsTransformer(results) || [];
			}
			return results;
		}

		return null;
	}

	private toggleIsOpen =
		(onFocusOrBlur: React.FocusEventHandler<HTMLInputElement>, inputHasFocus: boolean) =>
		(e: React.FocusEvent<HTMLInputElement>) => {
			this.setState({
				inputHasFocus,
			});

			if (onFocusOrBlur) {
				// pass this on to this.props.inputProps.onBlur or onFocus
				onFocusOrBlur(e);
			}
		};

	private onItemClicked = (_: TTransformedItem, index: number) => (e?: React.MouseEvent<HTMLElement>) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		const searchResults = this.searchResults || [];
		this.onItemSelected(searchResults[index] as TTransformedItem)();
	};

	private onKeyDown =
		(onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void) => (e: React.KeyboardEvent<HTMLInputElement>) => {
			const searchResults = this.searchResults || [];
			const { resultsLimit } = this.props;
			switch (e.keyCode) {
				case 38: {
					// up
					const index = this.state.highlightedItemIndex - 1;
					if (index >= -1) {
						this.setState({
							highlightedItemIndex: index,
						});
					}
					break;
				}
				case 40: {
					// down
					const index = this.state.highlightedItemIndex + 1;
					const limit =
						!!resultsLimit && resultsLimit > 0 ? Math.min(searchResults.length, resultsLimit) : searchResults.length;
					if (index < limit) {
						this.setState({
							highlightedItemIndex: index,
						});
					}
					break;
				}
				case 13: {
					// enter
					if (this.state.highlightedItemIndex >= 0 && this.state.highlightedItemIndex < searchResults.length) {
						e.preventDefault();
						e.stopPropagation();
						this.onItemSelected(searchResults[this.state.highlightedItemIndex] as TTransformedItem)();
					}
					break;
				}
				case 8:
				case 46: {
					// delete or backspace
					if (!this.state.searchQuery && this.props.onRequestDeleteBackwards) {
						this.props.onRequestDeleteBackwards();
					}
					break;
				}
				default: {
					break;
				}
			}

			if (!!onKeyDown && !e.defaultPrevented) {
				// pass this on to this.props.inputProps.onKeyDown
				onKeyDown(e);
			}
		};

	private onItemSelected = (item: TTransformedItem) => () => {
		if (this.props.onItemSelected) {
			this.props.onItemSelected(item);
		}
	};

	private onInputTextChanged =
		(onChange: (e: React.ChangeEvent<HTMLInputElement>) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const searchQuery = e.target.value || '';
			this.setState({
				highlightedItemIndex: -1,
				searchQuery,
			});

			if (!this.state.disableAutocomplete) {
				this.props.autoCompleteViewModel.setSearchQuery(searchQuery);
			}

			if (onChange) {
				// pass this on to this.props.inputProps.onChange
				onChange(e);
			}
		};
}

export const AutoCompleteDropdown = observer(_AutoCompleteDropdown);
