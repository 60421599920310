import { IUser, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import React, { useState } from 'react';
import { getDisplayName } from '../../../../../models/UiUtils';
import { ViewModelTypeAutocomplete } from '../../../../containers/Settings/PhoneNumbers/components/ViewModelTypeAutocomplete';
import { styleSheet } from '../styles';

interface IProps {
	onChange: (user: IUser) => void;
}

export const ManagerSelect: React.FC<IProps> = ({ onChange }) => {
	const [selectedItem, selectItem] = useState<IUser>(null);
	const handleSelection = (value: IUser) => {
		onChange(value);
		selectItem(value);
	};
	return (
		<ViewModelTypeAutocomplete<IUser>
			className={css(styleSheet.autocomplete)}
			placeholder={selectedItem ? 'Manager: ' + getDisplayName(selectedItem) : 'Filter by Manager'}
			model={ResourceAutoCompleteViewModelType.Manager}
			onChange={handleSelection}
		/>
	);
};
