import { animated } from '@react-spring/web';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

export interface ISlotMachineSymbolProps extends React.SVGProps<SVGSVGElement> {
	index?: number;
	styles?: StyleDeclarationValue[];
}

export const SlotMachineSymbol = React.forwardRef<SVGSVGElement, ISlotMachineSymbolProps>(
	function SlotMachineSymbol(props, ref) {
		const { index, className = '', styles = [], children, width, height, viewBox, ...restProps } = props;
		return (
			<animated.svg
				{...restProps}
				className={`svg-icon slot-machine-symbol ${className || ''} ${css(...styles)}`}
				height={height}
				ref={ref}
				viewBox={viewBox ? viewBox : `0 0 ${width} ${height}`}
				width={width}
				xmlns='http://www.w3.org/2000/svg'
			>
				{children}
			</animated.svg>
		);
	}
);
