import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { ISelectedEmailRecipient } from '..';
import { replaceTokenWithActualFirstNameString } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { EmailCampaignBrowserViewModel } from '../../../../viewmodels/AppViewModels';
import { AutomationPreviewStep } from '../../../../web/components/automation/AutomationPreview/presentation';
import { styleSheet } from '../styles';

export interface IProps {
	selectedRecipient: ISelectedEmailRecipient;
	template: Api.AutomationTemplateViewModel;
}

export const ChildAutomationViewer: React.FC<IProps> = ({ selectedRecipient, template }) => {
	const userSession = useUserSession();
	const browser = useRef(new EmailCampaignBrowserViewModel(userSession)).current;
	const [automationTemplate, setTemplate] = useState(null);

	useEffect(() => {
		// @ts-ignore
		browser.loadAutomationTemplateById(template.id).then(response => {
			// @ts-ignore
			// @ts-ignore
			setTemplate(response.value);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!automationTemplate) {
		return null;
	}

	// @ts-ignore
	return automationTemplate?.published?.steps?.map((step: Api.IAutomationStep) => {
		if (step?.content) {
			// @ts-ignore
			step.content = replaceTokenWithActualFirstNameString(step.content, selectedRecipient?.contact?.firstName);
		}
		return (
			<div className={css(styleSheet.automationPreviewContainer)} key={step.id}>
				<AutomationPreviewStep
					automationTemplate={automationTemplate}
					showPublished={true}
					step={step}
					// @ts-ignore
					steps={automationTemplate.published.steps}
				/>
			</div>
		);
	});
};
