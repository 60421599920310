import { brandPrimaryHover } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	active: {
		background: brandPrimaryHover,
	},
	// commandIcon: {
	// 	height: 30,
	// 	marginLeft: -4,
	// 	marginTop: 6,
	// 	width: 30,
	// },
	// commandIconContainer: {
	// 	alignItems: 'center',
	// 	border: `1px solid ${ brandPrimaryLight }`,
	// 	borderRadius: 5,
	// 	display: 'flex',
	// 	height: 24,
	// 	marginLeft: 10,
	// 	position: 'relative',
	// 	width: 37,
	// },
	navLinkContainer: {
		':hover': {
			cursor: 'pointer',
		},
		':not(:first-child)': {
			marginLeft: 10,
		},
		...aidaBaseStyles.flexCenter,
		borderRadius: '50%',
		height: 40,
		width: 40,
	},
	// navLinkSearchContainer: {
	// 	justifyContent: 'flex-start',
	// 	width: 200,
	// },
	// plusK: {
	// 	color: brandPrimaryLight,
	// 	fontFamily: FontFamilies.semiBold,
	// 	fontSize: 16,
	// 	left: 19,
	// 	position: 'absolute',
	// 	top: 0,
	// },
	// searchIcon: {
	// 	height: 20,
	// 	marginLeft: 8,
	// 	width: 20,
	// },
	// searchIconContainer: {
	// 	alignItems: 'center',
	// 	display: 'flex',
	// },
});
