import {
	DialerClientType,
	ICompany,
	IContact,
	IOperationResult,
	IUserSessionContext,
	ImpersonationBroker,
} from '../../extViewmodels';
import { IRequestOptions } from '../entities/PhoneCall/usePhoneCallNotes';
import { ILeaderboardDemos, ILeaderboardDemosFilterCriteria, IMeme } from '../viewModels/gamification';
import { IIntegrationPipelineItem, IIntegrationPropertyConfig, ILeadView } from '../viewModels/leads/interfaces';
import { ILeadServedFilterCriteria } from '../viewModels/leadserved';
import { IPhoneCallFilterCriteria } from './../viewModels/phonecall';
import {
	IGameTokenFilter,
	INote,
	IPagedCollection,
	IPhoneCall,
	IPhoneCallCategory,
	IPrizeLog,
	ITranscription,
} from '@ViewModels';

interface IPhoneCallRequest {
	phoneNumber: string;
	companyId: string;
	contactIds?: string[];
	preferredTelephonyConfigurationId?: string;
	clientType?: DialerClientType;
}

interface ISaveEmailOutcomeRequest {
	bulkEmailMessageId: string;
	leadServedSource: string;
}

interface ISaveOutcomeResponse {
	meme: IMeme;
}

export class ApiClient extends ImpersonationBroker {
	private userSession: IUserSessionContext;

	constructor(userSession: IUserSessionContext) {
		super();

		this.userSession = userSession;
	}

	public async getUserById<T>(userId: string): Promise<IOperationResult<T>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<T>(
			this.composeApiUrl({ urlPath: `user/${userId}` }),
			'GET'
		);
	}

	public async getTwilioAccessToken(): Promise<IOperationResult<string>> {
		const clientName = 'Web Browser';
		const path = `twilio/token?clientName=${clientName}`;
		return this.userSession.webServiceHelper.callWebServiceAsync<string>(path, 'GET');
	}

	public async createPhoneCall(request: IPhoneCallRequest): Promise<IOperationResult<IPhoneCall>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IPhoneCall>(
			this.composeApiUrl({ urlPath: 'phonecall' }),
			'POST',
			request
		);
	}

	public async getPhoneCallById(id: string): Promise<IOperationResult<IPhoneCall>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IPhoneCall>(
			this.composeApiUrl({ urlPath: `phonecall/${id}` }),
			'GET'
		);
	}

	public async getPhoneCall(criteria: IPhoneCallFilterCriteria[]): Promise<IOperationResult<IPhoneCall>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IPhoneCall>(
			this.composeApiUrl({ urlPath: `phonecall/filter` }),
			'POST',
			{ criteria }
		);
	}

	public async addPhoneCallCategory(phoneCallId: string, categoryName: string): Promise<IOperationResult<IPhoneCall>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IPhoneCall>(
			this.composeApiUrl({ urlPath: `phonecall/${phoneCallId}/category?category=${categoryName}` }),
			'POST'
		);
	}

	public async removePhoneCallCategory(
		phoneCallId: string,
		categoryName: string
	): Promise<IOperationResult<IPhoneCall>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IPhoneCall>(
			this.composeApiUrl({ urlPath: `phonecall/${phoneCallId}/category?category=${categoryName}` }),
			'DELETE'
		);
	}

	public async createPhoneCallCategory(request: IPhoneCallCategory): Promise<IOperationResult<IPhoneCallCategory>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IPhoneCallCategory>(
			this.composeApiUrl({ urlPath: `phonecall/category` }),
			'POST',
			request
		);
	}

	public async updatePhoneCallCategory(
		categoryId: string,
		request: IPhoneCallCategory
	): Promise<IOperationResult<IPhoneCallCategory>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IPhoneCallCategory>(
			this.composeApiUrl({ urlPath: `phonecall/category/${categoryId}` }),
			'PUT',
			request
		);
	}

	public async getPhoneCallCategory(): Promise<IOperationResult<IPhoneCallCategory>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IPhoneCallCategory>(
			this.composeApiUrl({ urlPath: `phonecall/category` }),
			'GET'
		);
	}

	public async getPhoneCallNotes(
		phoneCallId: string,
		options?: IRequestOptions
	): Promise<IOperationResult<IPagedCollection<INote>>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IPagedCollection<INote>>(
			this.composeApiUrl({
				queryParams: { pageSize: options?.pageSize, pageToken: options?.pageToken, sort: options?.sort },
				urlPath: `note/byPhoneCall/${phoneCallId}`,
			}),
			'GET'
		);
	}

	public async createPhoneCallNote(phoneCallId: string, request: FormData): Promise<IOperationResult<INote>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<INote>(
			this.composeApiUrl({ urlPath: `phonecall/${phoneCallId}/Note` }),
			'POST',
			request
		);
	}

	public async getTranscription<T>(transcriptionId: string, viewSpeakerLine?: boolean): Promise<IOperationResult<T>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<T>(
			this.composeApiUrl({
				queryParams: { type: viewSpeakerLine ? 'SpeakerLine' : undefined },
				urlPath: `transcription/${transcriptionId}${viewSpeakerLine ? '/view' : ''}`,
			}),
			'GET'
		);
	}

	public async markAsRead(transcriptionId: string): Promise<IOperationResult<ITranscription>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<ITranscription>(
			this.composeApiUrl({ urlPath: `transcription/${transcriptionId}/markRead` }),
			'POST',
			{}
		);
	}

	public async createTranscription(phoneCallId: string): Promise<IOperationResult<ITranscription>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<ITranscription>(
			this.composeApiUrl({ urlPath: `transcription` }),
			'POST',
			{
				phoneCallId,
			}
		);
	}

	public async exportCallTranscripts(criteria: ILeadServedFilterCriteria[]): Promise<IOperationResult<undefined>> {
		return this.userSession.webServiceHelper.callWebServiceAsync(
			this.composeApiUrl({ urlPath: `leadServed/export` }),
			'POST',
			{
				filter: { criteria },
				includeTranscription: true,
			}
		);
	}

	public async getLead(companyId: string): Promise<IOperationResult<ILeadView>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<ILeadView>(
			this.composeApiUrl({ urlPath: `lead/${companyId}` }),
			'GET'
		);
	}

	public async getDealPipeline(): Promise<IOperationResult<IIntegrationPipelineItem>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IIntegrationPipelineItem>(
			this.composeApiUrl({ urlPath: `aida/property/deal/pipeline` }),
			'GET'
		);
	}

	public async getLeadConfig(): Promise<IOperationResult<IIntegrationPropertyConfig>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IIntegrationPropertyConfig>(
			this.composeApiUrl({ urlPath: `aida/property/lead/config` }),
			'GET'
		);
	}

	public async getDealConfig(): Promise<IOperationResult<IIntegrationPropertyConfig>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IIntegrationPropertyConfig>(
			this.composeApiUrl({ urlPath: `aida/property/deal/config` }),
			'GET'
		);
	}

	public async updateLeadConfig(request: any): Promise<IOperationResult<IIntegrationPropertyConfig>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IIntegrationPropertyConfig>(
			this.composeApiUrl({ urlPath: `aida/property/lead/config` }),
			'PATCH',
			request
		);
	}

	public async updateDealConfig(request: any): Promise<IOperationResult<IIntegrationPropertyConfig>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IIntegrationPropertyConfig>(
			this.composeApiUrl({ urlPath: `aida/property/deal/config` }),
			'PATCH',
			request
		);
	}

	public async getCompany(companyId: string): Promise<IOperationResult<ICompany>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<ICompany>(
			this.composeApiUrl({ urlPath: `company/${companyId}` }),
			'GET'
		);
	}

	public async getContact(contactId: string): Promise<IOperationResult<IContact>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IContact>(
			this.composeApiUrl({ urlPath: `contact/${contactId}` }),
			'GET'
		);
	}

	public async getAvailableLeadsNumber(ruleId: string): Promise<IOperationResult<number>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<number>(
			this.composeApiUrl({ urlPath: `aida/rule/${ruleId}/count` }),
			'GET'
		);
	}

	public async saveEmailOutcome(
		companyId: string,
		request: ISaveEmailOutcomeRequest
	): Promise<IOperationResult<ISaveOutcomeResponse>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<ISaveOutcomeResponse>(
			this.composeApiUrl({ urlPath: `lead/${companyId}/EmailOutcome` }),
			'POST',
			request
		);
	}

	public async getVoicemailText(): Promise<IOperationResult<string>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<string>(
			this.composeApiUrl({ urlPath: `twilio/voicemailtext` }),
			'GET'
		);
	}

	public async updateVoicemailText(message: string): Promise<IOperationResult<string>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<string>(
			this.composeApiUrl({ urlPath: `twilio/voicemailtext` }),
			'POST',
			{
				text: message,
			}
		);
	}

	public async getLeaderboardDemos(
		criteria: ILeaderboardDemosFilterCriteria[]
	): Promise<IOperationResult<ILeaderboardDemos>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<ILeaderboardDemos>(
			this.composeApiUrl({ urlPath: `userDailyActivity/leaderboard/demos/filter` }),
			'POST',
			{ criteria }
		);
	}

	public async getLeaderboardPrizes(filter: IGameTokenFilter): Promise<IOperationResult<IPrizeLog[]>> {
		return this.userSession.webServiceHelper.callWebServiceAsync<IPrizeLog[]>(
			this.composeApiUrl({ urlPath: `game/prize-log/filter` }),
			'POST',
			filter
		);
	}
}
