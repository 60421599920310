import { Checkbox } from '../../../../web/components/Checkbox';
import { styleSheet } from './../styles';
import { css } from 'aphrodite';
import { useEffect, useState } from 'react';
import * as React from 'react';

interface IProps {
	defaultChecked?: boolean;
	onChange: (isDM: boolean) => void;
}

export const MarkAsDM: React.FC<IProps> = ({ defaultChecked, onChange }) => {
	const [isDM, markAsDM] = useState(defaultChecked);

	useEffect(() => {
		markAsDM(defaultChecked);
	}, [defaultChecked]);

	const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		markAsDM(target.checked);
		onChange(target.checked);
	};

	return (
		<div className={css(styleSheet.markAsDMContainer)}>
			<Checkbox checked={isDM} id='contact-modal-mark-as-dm-checkbox' onChange={handleChange} />
			<span>Mark as DM</span>
		</div>
	);
};
