import { IModalProps, Modal } from '../../../web/components/Modal';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

export interface IBlueModalProps<TResult = any> extends IModalProps<TResult> {
	className?: string;
	isOpen: boolean;
}

export const BlueModal: React.FC<IBlueModalProps> = ({
	children,
	className = '',
	isOpen,
	useDefaultHeader = true,
	...restProps
}) => {
	return (
		<Modal
			{...restProps}
			className={className}
			isOpen={isOpen}
			overlayPresentationStyle={{
				afterOpenClassName: css(styleSheet.blueModalAfterOpen),
				beforeCloseClassName: css(styleSheet.blueModalBeforeClose),
				className: `modal-overlay ${css(styleSheet.blueModal)} ${className}`,
				transitionInDuration: 500,
				transitionOutDuration: 500,
			}}
			useDefaultHeader={useDefaultHeader}
		>
			{children}
		</Modal>
	);
};
