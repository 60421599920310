import { Layouts } from '../../../web/styles/styles';
import { brandSecondary, gray, grayIconFill, titles } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: { margin: '20px auto' },
	center: {
		...aidaBaseStyles.flexCenter,
		height: 'calc(100vh - 80px)',
	},
	container: {
		background: 'white',
		borderRadius: 3,
		padding: 20,
		textAlign: 'center',
		width: 500,
		...Layouts.verticalStack(10),
	},
	description: {
		color: titles,
		fontSize: 14,
		margin: '10px auto',
		width: 220,
	},
	message: {
		fontFamily: 'monospace',
		fontWeight: 'bold',
		textAlign: 'left',
	},
	stack: {
		background: gray,
		border: `1px solid ${grayIconFill}`,
		borderRadius: 3,
		fontFamily: 'monospace',
		maxHeight: 300,
		overflow: 'scroll',
		padding: 10,
		textAlign: 'left',
	},
	title: {
		color: brandSecondary,
		fontSize: 18,
		margin: '10px 0',
	},
	wrong: {
		color: 'red',
	},
});
