import '../../../web/styles/colors';
import { CssSelectors, FontFamilies, Layouts, truncateTextStyle } from '../../../web/styles/styles';
import {
	backgroundGreen,
	brandPrimary,
	brandPrimaryHover,
	brandSecondary,
	gold,
	titles,
	white,
} from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { CSSProperties, StyleSheet } from 'aphrodite';

const avatarSize = 60;

export const styleSheet = StyleSheet.create({
	avatarContainer: {
		...aidaBaseStyles.flexCenter,
		...CssSelectors.allDescendants(
			{
				minHeight: avatarSize,
				minWidth: avatarSize,
			},
			'.avatar-content'
		),
	},
	companyContainer: {
		...CssSelectors.allChildren(aidaBaseStyles.truncateText as CSSProperties),
		...CssSelectors.allDescendants(
			{
				color: brandPrimaryHover,
				fontSize: '18px',
				marginBottom: 10,
				textDecoration: 'none',
				width: '100%',
				...truncateTextStyle,
			},
			'.company-name'
		),
		...CssSelectors.allChildren(
			{
				color: titles,
				fontSize: '16px',
			},
			'.company-phone'
		),
		...CssSelectors.allChildren(
			{
				color: brandPrimaryHover,
				fontSize: '12px',
			},
			'.company-website'
		),
	},
	companyContainerExtraMargin: {
		marginTop: 16,
	},
	contactContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
	},
	contactInfoContainer: {
		...aidaBaseStyles.flexCol,
		maxWidth: `calc(100% - ${avatarSize}px)`,
		paddingLeft: 15,
		...CssSelectors.allChildren(aidaBaseStyles.truncateText as CSSProperties),
		...CssSelectors.allChildren(
			{
				color: titles,
				fontSize: '16px',
			},
			'.contact-name'
		),
		...CssSelectors.allChildren(
			{
				color: '#858585',
				fontSize: '12px',
			},
			'.contact-job-title'
		),
		...CssSelectors.allChildren(
			{
				color: brandPrimaryHover,
				fontSize: '12px',
			},
			'.contact-email'
		),
	},
	dot: {
		borderRadius: '50%',
		height: 10,
		marginLeft: 5,
		width: 10,
	},
	followUpClockIcon: {
		marginLeft: 10,
		...CssSelectors.allChildren(
			{
				height: 14,
				width: 14,
			},
			'svg'
		),
	},
	followUpContainer: {
		background: brandSecondary,
		borderRadius: 14,
		boxShadow: '0 0 3px 3px rgba(0,0,0,0.1)',
		color: white,
		height: '50',
		padding: '10px 14px',
		width: '100',
		...CssSelectors.allDescendants({
			fontFamily: FontFamilies.semiBold,
		}),
	},
	followUpName: {
		':nth-child(1n)': {
			fontFamily: FontFamilies.regular,
		},
		marginLeft: 10,
	},
	followUpOverdue: {
		color: gold,
		marginLeft: 10,
	},
	label: {
		color: '#aaa',
		display: 'inline-block',
		fontSize: '11px',
		minWidth: 90,
		textAlign: 'right',
		textTransform: 'uppercase',
	},
	lastDealContainer: {
		background: backgroundGreen,
		borderRadius: 14,
		boxShadow: '0 0 3px 3px rgba(0,0,0,0.1)',
		color: brandPrimary,
		height: '50',
		padding: '10px 14px',
		width: '100',
		...CssSelectors.allDescendants({
			fontFamily: FontFamilies.semiBold,
		}),
	},
	lastInteraction: {
		...aidaBaseStyles.flexHorizontalCenter,
		':first-child': {
			marginBottom: 4,
		},
		...CssSelectors.allChildren(
			{
				fontSize: '12px',
				paddingLeft: 5,
			},
			'span:last-child'
		),
	},
	lastInteractionContainer: {
		background: '#f9f9f9',
		padding: 10,
	},
	leadCardContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		background: '#fff',
		borderRadius: 10,
		color: titles,
		cursor: 'pointer',
		justifyContent: 'space-between',
		padding: '25px 30px',
		position: 'relative',
	},
	locationIcon: {
		marginLeft: 6,
	},
	pepper: {
		height: 16,
		marginLeft: 10,
		width: 16,
	},
	playButton: {
		':hover': {
			color: brandPrimaryHover,
			textDecoration: 'underline',
		},
		color: brandPrimary,
		cursor: 'pointer',
		fontSize: 12,
		marginLeft: 5,
	},
	recording: {
		marginTop: 4,
	},
	section: {
		maxWidth: '30%',
		minWidth: '30%',
		...CssSelectors.allDescendants(
			{
				color: brandPrimaryHover,
				fontSize: '11px',
				position: 'absolute',
				right: 10,
				top: 10,
			},
			'.company-link'
		),
	},
	tagContainer: {
		...Layouts.horizontalStack(16),
		position: 'absolute',
		top: -16,
	},
});
