import { ISlotMachineSymbolProps, SlotMachineSymbol } from '../SlotMachineSymbol';
import * as React from 'react';

export const LuckySevenSymbol = React.forwardRef<SVGSVGElement, ISlotMachineSymbolProps>(
	function LuckySevenSymbol(props, ref) {
		const { className = '', index, ...restProps } = props;
		return (
			<SlotMachineSymbol
				height={107}
				width={89}
				{...restProps}
				className={`${className} luck-seven-symbol`}
				fill='none'
				ref={ref}
			>
				<defs>
					<radialGradient
						id='luckySevenGradient'
						cx='0'
						cy='0'
						r='1'
						gradientUnits='userSpaceOnUse'
						gradientTransform='matrix(0 106.497 -74.326 0 44.32 4.681)'
					>
						<stop stopColor='#D7EBF7' />
						<stop offset='.413' stopColor='#00AAE8' />
						<stop offset='.55' stopColor='#046CB6' />
						<stop offset='.783' stopColor='#00528C' />
						<stop offset='.85' stopColor='#002F51' />
						<stop offset='1' stopColor='#046CB6' />
					</radialGradient>
				</defs>
				<path
					d='M20.64 8.29c-.28-.937-.64-1.759-1.103-2.44-1.51-2.431-4.104-3.43-7.137-3.43-2.946 0-5.479 1.046-7.424 3.169C3.048 7.692 2.14 10.345 2.14 13.38c0 1.41.264 3.406.735 5.9.462 2.453.665 4.143.665 5.16 0 .266-.072 1.062-.264 2.544-.184 1.426-.461 3.37-.834 5.837v.002A40.647 40.647 0 0 0 2 38.86c0 3.604.958 6.667 3.216 8.803 2.236 2.116 5.41 2.997 9.144 2.997 3.961 0 7.297-1.019 9.622-3.404 2.316-2.375 3.298-5.767 3.298-9.796 0-1.699-.265-3.506-.766-5.409v-.003l-1.257-4.746a33.986 33.986 0 0 1-.216-.908c.022-1.175.302-1.724.529-1.974.203-.223.63-.5 1.67-.5.877 0 1.99.283 3.38 1.015l6.416 3.626C42.95 31.9 48.207 33.72 52.72 33.72c1 0 2.035-.088 3.101-.26-2.117 1.925-4.597 4.048-7.445 6.37-5.083 4.142-9.436 8.067-13.048 11.774-7.663 7.86-11.488 17.562-11.488 28.976 0 7.073 1.5 12.892 4.738 17.227 3.289 4.403 7.863 6.613 13.502 6.613 4.008 0 7.427-1.088 10.029-3.463 2.615-2.388 3.871-5.565 3.871-9.317 0-1.738-.251-4.259-.72-7.497-.465-3.202-.68-5.487-.68-6.923 0-4.51 1.102-8.777 3.324-12.828 2.26-4.123 6.676-9.835 13.374-17.185l.005-.005.005-.006c3.27-3.643 5.667-6.369 7.182-8.168l.013-.015.012-.015c5.357-6.624 8.145-13.588 8.145-20.858 0-4.376-1.152-8.158-3.591-11.193C80.608 3.91 77.465 2.28 73.72 2.28c-3.22 0-5.904 1.146-7.659 3.653l-.007.01-.007.01-2.091 3.069-.004.005c-.557.802-1.022.953-1.432.953-.58 0-1.516-.224-2.886-.909l-6.44-3.22C48.074 3.291 42.73 2 37.18 2c-6.911 0-12.496 2.07-16.54 6.29Z'
					fill='url(#luckySevenGradient)'
				/>
				<path
					d='M20.64 8.29c-.28-.937-.64-1.759-1.103-2.44-1.51-2.431-4.104-3.43-7.137-3.43-2.946 0-5.479 1.046-7.424 3.169C3.048 7.692 2.14 10.345 2.14 13.38c0 1.41.264 3.406.735 5.9.462 2.453.665 4.143.665 5.16 0 .266-.072 1.062-.264 2.544-.184 1.426-.461 3.37-.834 5.837v.002A40.647 40.647 0 0 0 2 38.86c0 3.604.958 6.667 3.216 8.803 2.236 2.116 5.41 2.997 9.144 2.997 3.961 0 7.297-1.019 9.622-3.404 2.316-2.375 3.298-5.767 3.298-9.796 0-1.699-.265-3.506-.766-5.409v-.003l-1.257-4.746a33.986 33.986 0 0 1-.216-.908c.022-1.175.302-1.724.529-1.974.203-.223.63-.5 1.67-.5.877 0 1.99.283 3.38 1.015l6.416 3.626C42.95 31.9 48.207 33.72 52.72 33.72c1 0 2.035-.088 3.101-.26-2.117 1.925-4.597 4.048-7.445 6.37-5.083 4.142-9.436 8.067-13.048 11.774-7.663 7.86-11.488 17.562-11.488 28.976 0 7.073 1.5 12.892 4.738 17.227 3.289 4.403 7.863 6.613 13.502 6.613 4.008 0 7.427-1.088 10.029-3.463 2.615-2.388 3.871-5.565 3.871-9.317 0-1.738-.251-4.259-.72-7.497-.465-3.202-.68-5.487-.68-6.923 0-4.51 1.102-8.777 3.324-12.828 2.26-4.123 6.676-9.835 13.374-17.185l.005-.005.005-.006c3.27-3.643 5.667-6.369 7.182-8.168l.013-.015.012-.015c5.357-6.624 8.145-13.588 8.145-20.858 0-4.376-1.152-8.158-3.591-11.193C80.608 3.91 77.465 2.28 73.72 2.28c-3.22 0-5.904 1.146-7.659 3.653l-.007.01-.007.01-2.091 3.069-.004.005c-.557.802-1.022.953-1.432.953-.58 0-1.516-.224-2.886-.909l-6.44-3.22C48.074 3.291 42.73 2 37.18 2c-6.911 0-12.496 2.07-16.54 6.29Z'
					stroke='#6D0609'
					strokeWidth='4'
				/>
				<path
					d='M20.64 8.29c-.28-.937-.64-1.759-1.103-2.44-1.51-2.431-4.104-3.43-7.137-3.43-2.946 0-5.479 1.046-7.424 3.169C3.048 7.692 2.14 10.345 2.14 13.38c0 1.41.264 3.406.735 5.9.462 2.453.665 4.143.665 5.16 0 .266-.072 1.062-.264 2.544-.184 1.426-.461 3.37-.834 5.837v.002A40.647 40.647 0 0 0 2 38.86c0 3.604.958 6.667 3.216 8.803 2.236 2.116 5.41 2.997 9.144 2.997 3.961 0 7.297-1.019 9.622-3.404 2.316-2.375 3.298-5.767 3.298-9.796 0-1.699-.265-3.506-.766-5.409v-.003l-1.257-4.746a33.986 33.986 0 0 1-.216-.908c.022-1.175.302-1.724.529-1.974.203-.223.63-.5 1.67-.5.877 0 1.99.283 3.38 1.015l6.416 3.626C42.95 31.9 48.207 33.72 52.72 33.72c1 0 2.035-.088 3.101-.26-2.117 1.925-4.597 4.048-7.445 6.37-5.083 4.142-9.436 8.067-13.048 11.774-7.663 7.86-11.488 17.562-11.488 28.976 0 7.073 1.5 12.892 4.738 17.227 3.289 4.403 7.863 6.613 13.502 6.613 4.008 0 7.427-1.088 10.029-3.463 2.615-2.388 3.871-5.565 3.871-9.317 0-1.738-.251-4.259-.72-7.497-.465-3.202-.68-5.487-.68-6.923 0-4.51 1.102-8.777 3.324-12.828 2.26-4.123 6.676-9.835 13.374-17.185l.005-.005.005-.006c3.27-3.643 5.667-6.369 7.182-8.168l.013-.015.012-.015c5.357-6.624 8.145-13.588 8.145-20.858 0-4.376-1.152-8.158-3.591-11.193C80.608 3.91 77.465 2.28 73.72 2.28c-3.22 0-5.904 1.146-7.659 3.653l-.007.01-.007.01-2.091 3.069-.004.005c-.557.802-1.022.953-1.432.953-.58 0-1.516-.224-2.886-.909l-6.44-3.22C48.074 3.291 42.73 2 37.18 2c-6.911 0-12.496 2.07-16.54 6.29Z'
					stroke='#fff'
					strokeWidth='4'
				/>
			</SlotMachineSymbol>
		);
	}
);
