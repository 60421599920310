import { useRef } from 'react';
import { IRange, IUser, TranscriptionStatusFilter } from '../../../extViewmodels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { ISelectOption } from '../../../web/components/Select';
import { FieldKey } from '../../viewModels/form';
import { ILeadServedOutcomeView } from '../../viewModels/leads/interfaces';
import {
	ILeadServedFilterCriteria,
	LeadServedFilterCriteriaProperty,
	RecentCallsViewModel,
} from '../../viewModels/leadserved';
import { formatDate, formatOptional, formatOutcomes, formatSelect, formatUser } from '../utils/criteriaBuilder';
import { useStatesOnFetch } from '../utils/useStatesOnFetch';

export interface ILeadViewFilter {
	category?: string;
	companyTag?: string;
	date?: IRange<Date>;
	manager?: IUser;
	outcome?: string;
	outcomes?: ISelectOption<FieldKey>[];
	recordingChannels?: ISelectOption<string>;
	team?: string;
	transcriptionStatus?: ISelectOption<TranscriptionStatusFilter>;
	user?: IUser;
	userRole?: string;
}
interface IParams {
	filter: ILeadViewFilter;
	pageSize?: number;
	shouldReset?: boolean;
	shouldNotTriggerLoading?: boolean;
}

export function filterToCriteria(filter: ILeadViewFilter): ILeadServedFilterCriteria[] {
	const criteria: ILeadServedFilterCriteria[] = [];

	// @ts-ignore
	criteria.push(formatDate<LeadServedFilterCriteriaProperty>(LeadServedFilterCriteriaProperty.Date, filter?.date));
	criteria.push(
		// @ts-ignore
		formatOptional<LeadServedFilterCriteriaProperty>(LeadServedFilterCriteriaProperty.Category, filter?.category)
	);
	criteria.push(
		// @ts-ignore
		formatOptional<LeadServedFilterCriteriaProperty>(LeadServedFilterCriteriaProperty.CompanyTag, filter?.companyTag)
	);
	criteria.push(
		// @ts-ignore
		formatOptional<LeadServedFilterCriteriaProperty>(LeadServedFilterCriteriaProperty.Outcome, filter?.outcome)
	);
	criteria.push(
		// @ts-ignore
		formatSelect<LeadServedFilterCriteriaProperty>(
			LeadServedFilterCriteriaProperty.RecordingChannels,
			filter?.recordingChannels
		)
	);
	// @ts-ignore
	criteria.push(formatOptional<LeadServedFilterCriteriaProperty>(LeadServedFilterCriteriaProperty.Team, filter?.team));
	criteria.push(
		// @ts-ignore
		formatSelect<LeadServedFilterCriteriaProperty>(
			LeadServedFilterCriteriaProperty.TranscriptionStatus,
			filter?.transcriptionStatus
		)
	);
	criteria.push(
		// @ts-ignore
		formatOptional<LeadServedFilterCriteriaProperty>(LeadServedFilterCriteriaProperty.UserRole, filter?.userRole)
	);
	criteria.push(
		// @ts-ignore
		formatOutcomes<LeadServedFilterCriteriaProperty>(LeadServedFilterCriteriaProperty.Outcome, filter?.outcomes)
	);
	criteria.push(
		// @ts-ignore
		formatUser<LeadServedFilterCriteriaProperty>(LeadServedFilterCriteriaProperty.Manager, filter?.manager)
	);
	// @ts-ignore
	criteria.push(formatUser<LeadServedFilterCriteriaProperty>(LeadServedFilterCriteriaProperty.User, filter?.user));

	return criteria.filter(item => !!item);
}

export const useLeadView = () => {
	const userSession = useUserSession();

	const apiCall = useRef(new RecentCallsViewModel(userSession)).current;

	const { state, handleWorking, handleResponse, reset } = useStatesOnFetch<ILeadServedOutcomeView[]>();

	const get = async ({ filter, pageSize = 25, shouldReset, shouldNotTriggerLoading }: IParams) => {
		handleWorking(shouldNotTriggerLoading);
		if (shouldReset) {
			apiCall.controller.reset();
		}

		const criteria = filterToCriteria(filter);

		try {
			await apiCall.controller.getNext({ criteria }, pageSize);
			return handleResponse({ values: apiCall.controller.fetchResults });
		} catch (error) {
			// @ts-ignore
			throw new Error(error);
		}
	};

	return {
		...state,
		empty: !state?.data || state?.data?.length <= 0,
		get,
		reset,
		status: state?.data ? 'done' : state.status,
	};
};
