import { CssSelectors, FontFamilies } from '../../../web/styles/styles';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	basicPage: {
		...CssSelectors.allChildren(
			{
				marginTop: 30,
			},
			':last-child'
		),
	},

	// Cards
	card: {
		':hover': {
			boxShadow: `0 5px 14px 4px rgba(0, 0, 0, 0.2)`,
			transform: 'scale(1.05)',
		},
		...aidaBaseStyles.flexCenter,
		borderRadius: 10,
		color: '#fff',
		fontFamily: FontFamilies.conqueror,
		fontSize: '54px',
		height: '100%',
		padding: '50px 40px',
		textAlign: 'center',
		transition: '.2s ease-in-out',
	},
	cardContainer: {
		height: 250,
		margin: '22.5px 12px',
		textDecoration: 'none',
		width: 400,
	},
	cardsWrapper: {
		...aidaBaseStyles.flexHorizontalCenter,
		alignItems: 'stretch',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
});
