import { animated } from '@react-spring/web';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useRef } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { copyToClipboard } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { DeprecatedXIcon } from '../../../web/components/svgs/icons/DeprecatedXIcon';
import { useAudio } from '../../contexts/audioPlayer';
import { calculateDraggableWindowZIndex, useDraggableWindowContext } from '../../contexts/draggableWindowContext';
import { useToaster } from '../../hooks';
import { white } from '../../styles/colors';
import { AidaDraggable } from '../AidaDraggable';
import { IBlueModalProps } from '../BlueModal';
import { TransparentButton } from '../TransparentButton';
import { CopyIcon } from '../svgs/icons/CopyIcon';
import { DraggableIcon } from '../svgs/icons/audio/DraggableIcon';
import { ForwardIcon } from '../svgs/icons/audio/ForwardIcon';
import { PauseIcon } from '../svgs/icons/audio/PauseIcon';
import { PlayIcon } from '../svgs/icons/audio/PlayIcon';
import { RewindIcon } from '../svgs/icons/audio/RewindIcon';
import { VolumeIcon } from '../svgs/icons/audio/VolumeIcon';
import { VolumeMuteIcon } from '../svgs/icons/audio/VolumeMute';
import './audioPlayer.less';
import { styleSheet } from './styles';

export interface IAidaAudioPlayerProps extends IBlueModalProps {
	className?: string;
}

const audioPlayerWindowId = 'emailEditor';

export const AidaAudioPlayer: React.FC<IAidaAudioPlayerProps> = observer(props => {
	const { className = '', isOpen } = props;
	const audio = useAudio();
	const windowContext = useDraggableWindowContext();
	const toaster = useToaster();
	const userSession = useUserSession();

	const onCloseClick = () => {
		audio.setAudioSrc('');
		audio.setIsOpen(false)();
	};

	const copyAudioLink = (evt: React.MouseEvent<HTMLSpanElement>) => {
		evt.stopPropagation();
		const url = `${userSession.webServiceHelper.baseUrl}/PhoneCall/${audio.phoneCallId}/Download`;
		if (copyToClipboard(url)) {
			// @ts-ignore
			toaster.push({
				message: `Link copied to clipboard`,
				type: 'successMessage',
			});
		} else {
			// @ts-ignore
			toaster.push({
				message: `Email failed to copy to clipboard`,
				type: 'errorMessage',
			});
		}
	};

	const renderHeader = () => {
		return (
			<div className={`${css(styleSheet.header)} drag-handle`}>
				<div className={css(styleSheet.flexBetween)}>
					<div className={css(styleSheet.titleBar)}>
						<DraggableIcon className={css(styleSheet.draggableIcon)} />
						<div className={css(styleSheet.titleContainer)}>
							<div className={css(styleSheet.title)}>
								{audio.title}
								{userSession.account?.features?.aida?.allowAnonymousAccessToCallRecording && audio.phoneCallId && (
									<TransparentButton onClick={copyAudioLink} className={css(styleSheet.copyButton)}>
										<CopyIcon fillColor={white} className={css(styleSheet.copyIcon)} />
									</TransparentButton>
								)}
							</div>
							<div className={css(styleSheet.subtitle)}>{audio.subtitle}</div>
						</div>
					</div>
					<TransparentButton className={css(styleSheet.close)} onClick={onCloseClick}>
						<DeprecatedXIcon fillColor={white} className={css(styleSheet.closeIcon)} />
					</TransparentButton>
				</div>
			</div>
		);
	};

	const bringToFront = () => windowContext.bringToFront(audioPlayerWindowId);
	const iconSet = useRef({
		forward: <ForwardIcon />,
		pause: <PauseIcon />,
		play: <PlayIcon />,
		rewind: <RewindIcon />,
		volume: <VolumeIcon />,
		volumeMute: <VolumeMuteIcon />,
	}).current;

	const audioPlayerContents = (
		<animated.div
			className={`${css(styleSheet.container)} ${className}`}
			style={{
				zIndex: calculateDraggableWindowZIndex(windowContext.windowIds, audioPlayerWindowId),
			}}
			onClick={bringToFront}
		>
			{renderHeader()}
			<AudioPlayer
				className={css(styleSheet.playerWrapper)}
				customAdditionalControls={[]}
				customControlsSection={[RHAP_UI.MAIN_CONTROLS, RHAP_UI.VOLUME_CONTROLS]}
				src={audio.audioSrc}
				customIcons={iconSet}
				layout='horizontal'
				autoPlay={true}
			/>
		</animated.div>
	);

	if (!isOpen) {
		return null;
	}

	return <AidaDraggable isDraggable={true}>{audioPlayerContents}</AidaDraggable>;
});
