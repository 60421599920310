import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const DuplicateIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`duplicate-icon ${className || ''}`} height={20} width={20}>
			<g fill={fillColor || '#89C947'} fillRule='evenodd'>
				<path d='M6,14 L18,14 L18,2 L6,2 L6,14 Z M19,16 L5,16 C4.448,16 4,15.552 4,15 L4,1 C4,0.448 4.448,0 5,0 L19,0 C19.552,0 20,0.448 20,1 L20,15 C20,15.552 19.552,16 19,16 L19,16 Z' />
				<path d='M16,20 L1,20 C0.448,20 0,19.552 0,19 L0,4 L2,4 L2,18 L16,18 L16,20 Z' />
			</g>
		</SvgIcon>
	);
};
