import { TextInput } from '../../../../web/components/TextInput';
import { useFuzzySearch } from '../../../hooks/useFuzzySearch';
import { grayIconFill } from '../../../styles/colors';
import { IIntegrationPropertyItem } from '../../../viewModels/leads/interfaces';
import { Group } from '../../leadView/components/Group';
import { SearchIcon } from '../../svgs/icons/SearchIcon';
import { styleSheet } from '../styles';
import { CheckboxList, ICheckboxListItem } from './CheckboxList';
import { css } from 'aphrodite';
import { useState } from 'react';
import * as React from 'react';

interface IProps {
	blockList: boolean;
	getChecked: (item: IIntegrationPropertyItem) => boolean;
	isLoading: boolean;
	onChange: (items: ICheckboxListItem[]) => void;
	values: IIntegrationPropertyItem[];
}

export const PropertyList: React.FC<IProps> = ({ blockList, getChecked, isLoading, onChange, values }) => {
	const [selectedItems, setSelectedItems] = useState<ICheckboxListItem[]>([]);

	const [search, setSearch] = useState('');

	const fuzzySearch = useFuzzySearch<IIntegrationPropertyItem>(values, ['label']);

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	};

	const updateItems = (updatedItems: ICheckboxListItem[]) => {
		setSelectedItems(updatedItems);
		onChange(updatedItems);
	};

	const handleAdd = (item: ICheckboxListItem) => {
		const newValues = [...selectedItems];
		newValues.push(item);
		updateItems(newValues);
	};

	const handleRemove = (item: ICheckboxListItem) => {
		const newValues = [...selectedItems].filter(i => i.id !== item.id);
		updateItems(newValues);
	};

	const handleSelection = (item: ICheckboxListItem, isChecked: boolean) => {
		if (isChecked) {
			handleAdd(item);
			return;
		}
		handleRemove(item);
	};

	const list = search ? fuzzySearch.search(search).map(item => item.item) : values || [];

	const formattedValues = list.map(item => {
		const getMarked = selectedItems.filter(i => i.id === item.name)?.[0];
		return {
			content: item,
			id: item.name,
			isAdded: getMarked ? true : false,
			label: item.label,
		};
	});

	return (
		<Group vertical={true}>
			<TextInput
				className={css(styleSheet.searchInputFullWidth)}
				disabled={blockList}
				inputId='global-search-input'
				leftAccessory={<SearchIcon fillColor={grayIconFill} />}
				onChange={handleSearch}
				placeholder='Search property name'
				type='text'
				value={search}
			/>
			<CheckboxList
				disabled={blockList}
				getChecked={getChecked}
				isLoading={isLoading}
				items={formattedValues}
				onCheckboxChange={handleSelection}
			/>
		</Group>
	);
};
