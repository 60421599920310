import { IIntegrationPropertyItem } from '../../../viewModels/leads/interfaces';
import { ICheckboxListItem } from './CheckboxList';
import { sortPropertyItemsByKey as sort } from './LeadGeneralSettings';
import { PropertyList } from './PropertyList';
import * as React from 'react';

interface IProps {
	defaultValues: IIntegrationPropertyItem[];
	isAdding: boolean;
	isLoading: boolean;
	onSelect: (items: ICheckboxListItem[]) => void;
}

export const LeadAddPropertyList: React.FC<IProps> = ({ defaultValues, isAdding, isLoading, onSelect }) => {
	const handleChange = (items: ICheckboxListItem[]) => {
		onSelect(items);
	};

	const getChecked = (i: IIntegrationPropertyItem) => i.addAsTag;

	const values = sort(sort(defaultValues, 'label'), 'addAsTag', true);

	return (
		<PropertyList
			blockList={isAdding}
			getChecked={getChecked}
			isLoading={isLoading}
			onChange={handleChange}
			values={values}
		/>
	);
};
