import { CssSelectors } from '../../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		display: 'flex',
		position: 'absolute',
		right: 0,
		top: 20,
		...CssSelectors.allChildren(
			{
				cursor: 'pointer',
				marginLeft: 16,
			},
			'span'
		),
	},
});
