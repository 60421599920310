import { FilterOperator, IRange, IUser } from '../../../extViewmodels';
import { ISelectOption } from '../../../web/components/Select';
import { filterByChecked, reduceChildren } from '../../components/leadView/components/OutcomeSelect/utils';
import { FieldKey } from '../../viewModels/form';
import moment from 'moment';

export function formatDate<T>(property: T, value?: IRange<Date>) {
	if (!value) {
		return null;
	}

	const f = 'YYYY-MM-DD';
	const criteria = {
		criteria: [
			{
				op: FilterOperator.Gt,
				property,
				value: moment(value?.start).format(f),
			},
			{
				op: FilterOperator.Lt,
				property,
				value: moment(value?.end).add(1, 'day').format(f),
			},
		],
		op: FilterOperator.And,
	};

	return criteria;
}

export function formatUser<T>(property: T, value?: IUser) {
	if (!value?.id) {
		return null;
	}

	return {
		property,
		value: value?.id,
	};
}

export function formatOutcomes<T>(property: T, value?: ISelectOption<FieldKey>[]) {
	if (!value || value.length < 1) {
		return null;
	}

	const criteriaObject = (item: { name: string }) => ({
		property,
		value: item.name,
	});
	const filteredOutcome = value?.reduce(reduceChildren, [])?.filter(filterByChecked)?.map(criteriaObject);
	if (!filteredOutcome?.length) {
		return null;
	}
	return {
		criteria: filteredOutcome,
		op: FilterOperator.Or,
	};
}

export function formatOptional<T>(property: T, value?: string) {
	if (!value) {
		return null;
	}

	return {
		property,
		value,
	};
}

export function formatSelect<T>(property: T, value?: ISelectOption<any>) {
	if (!value?.dataContext) {
		return null;
	}

	return {
		property,
		value: value.dataContext,
	};
}
