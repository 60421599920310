import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { DeprecatedXIcon } from '../../../web/components/svgs/icons/DeprecatedXIcon';
import { TagIcon } from '../../../web/components/svgs/icons/TagIcon';
import { useAvailableLeadsNumber } from '../../entities/Blitz/useAvailableLeadsNumber';
import { FetchStatus } from '../../entities/utils/useStatesOnFetch';
import { white } from '../../styles/colors';
import { LeadRuleViewModel, LeadRulesViewModel, TurnItOnForOptions } from '../../viewModels/leadrules';
import { BlueModal } from '../BlueModal';
import { MrryDirection, MrryMessageType, MrryModal } from '../MrryModal';
import { AdditionalSettings } from './components/AdditionalSettings';
import { EditFilters } from './components/EditFilters';
import { RuleContainerHeader } from './components/RuleContainerHeader';
import { TurnItOnFor } from './components/TurnItOnFor';
import { styleSheet } from './styles';

interface IProps {
	isNewRule: boolean;
	leadRule: LeadRuleViewModel;
	leadRules: LeadRulesViewModel;
	onRequestClose(result?: LeadRuleViewModel, canceled?: boolean): void;
}

interface IMrryState {
	message: string;
	open: boolean;
}

const mrryInitialState: IMrryState = {
	// @ts-ignore
	message: null,
	open: false,
};

export const LeadRuleModal: React.FC<IProps> = observer(({ isNewRule, leadRule, leadRules, onRequestClose }) => {
	const userSession = useUserSession();

	// @ts-ignore
	const [selectedTurnItOn, setTurnItOn] = useState<TurnItOnForOptions>(null);
	// @ts-ignore
	const [currentLeadRule, setLeadRule] = useState<LeadRuleViewModel>(null);

	const [showMrry, setMrryState] = useState<IMrryState>(mrryInitialState);

	const availableLeads = useAvailableLeadsNumber();

	useEffect(() => {
		if (isNewRule) {
			const newRule = new LeadRuleViewModel(userSession, {
				...(leadRule?.getLeadRule || {}),
				name: `New rule from: ${leadRule?.name} (${Date.now()})`,
			});
			setLeadRule(newRule);
			return;
		}
		setLeadRule(leadRule);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNewRule, leadRule]);

	useEffect(() => {
		if (currentLeadRule && !isNewRule) {
			// @ts-ignore
			availableLeads.get(currentLeadRule?.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentLeadRule]);

	const handleSave = () => {
		if (selectedTurnItOn === TurnItOnForOptions.Everyone) {
			// @ts-ignore
			currentLeadRule?.setUsers(null);
			// @ts-ignore
			currentLeadRule?.setVerticals(null);
		}
		currentLeadRule[isNewRule ? 'save' : 'update']()
			.then(() => {
				if (isNewRule) {
					leadRules.rules.fetchResults.add(currentLeadRule);
				}
				if (currentLeadRule.id) {
					availableLeads.get(currentLeadRule.id);
				}
			})
			.catch(error => {
				setMrryState({
					message: error?.systemMessage || 'An error ocurred, please try again.',
					open: true,
				});
			})
			.finally(() => leadRules.sort());
	};

	const onClickToClose = () => {
		availableLeads.reset();
		onRequestClose?.(currentLeadRule, true);
	};

	const handleTurnItOn = (option: TurnItOnForOptions) => setTurnItOn(option);

	const handleCloseMrry = () => setMrryState(mrryInitialState);

	return (
		<div className={`lead-rule-modal ${css(styleSheet.container)}`}>
			<BlueModal
				isOpen={!!currentLeadRule}
				onRequestClose={onRequestClose}
				useDefaultHeader={false}
				className={css(styleSheet.leadRuleModal)}
			>
				<div className={css(styleSheet.header)}>
					<TagIcon fillColor={white} />
					<button onClick={onClickToClose}>
						<DeprecatedXIcon fillColor={white} />
					</button>
				</div>

				<RuleContainerHeader leadRule={currentLeadRule} />

				<div className={css(styleSheet.ruleContainer)}>
					<EditFilters leadRule={currentLeadRule} />
					<div className={css(styleSheet.dividerVertical)} />
					<TurnItOnFor leadRule={currentLeadRule} onSelect={handleTurnItOn} />
				</div>

				<div className={css(styleSheet.divider)} />

				<AdditionalSettings
					// @ts-ignore
					availableLeadsNumber={availableLeads.data}
					availableLeadsLoading={availableLeads.status === FetchStatus.Working}
					leadRule={currentLeadRule}
					onClickToSave={handleSave}
				/>

				{showMrry.open && (
					<MrryModal
						fromDirection={MrryDirection.FromTop}
						message={{
							content: <p>{showMrry.message}</p>,
							duration: 5000,
							type: MrryMessageType.Error,
						}}
						onRequestClose={handleCloseMrry}
					/>
				)}
			</BlueModal>
		</div>
	);
});
