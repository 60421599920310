import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		height: 'auto',
		justifyContent: 'center',
		minHeight: '95%',
		paddingTop: 30,
	},
});
