import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	value: number;
}

export const RulePriority: React.FC<IProps> = ({ value }) => {
	return (
		<div className={css(styleSheet.priority)}>
			<div className={css(styleSheet.priorityLabel)}>{value}</div>
		</div>
	);
};
