import { CssSelectors } from '../../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	coinMachine: {
		position: 'relative',
		zIndex: 0,
	},
	container: {
		alignItems: 'center',
		background: '#002138',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		overflow: 'auto',
		width: '100%',
	},
	genie: {
		bottom: 20,
		position: 'fixed',
		right: 20,
		zIndex: 1,
	},
	handle: {
		zIndex: 1,
	},
	machineContainer: {
		margin: '0 auto',
		position: 'relative',
	},
	machinePart: {
		position: 'absolute',
	},
	messageBox: {
		alignItems: 'center',
		color: '#fff',
		display: 'flex',
		flexDirection: 'column',
		fontSize: 16,
		justifyContent: 'center',
	},
	modal: {
		...CssSelectors.allDescendants(
			{
				background: 'transparent',
				border: 'none',
				boxShadow: 'none',
			},
			'.modal-overlay-content'
		),
		...CssSelectors.allDescendants(
			{
				background: 'rgba(0,0,0,0.50)',
			},
			'.modal-overlay'
		),
	},
	reel: {
		left: 0,
		perspective: 1100,
		top: 0,
		transformStyle: 'preserve-3d',
	},
	reelItem: {
		alignItems: 'center',
		backfaceVisibility: 'hidden',
		display: 'inline-flex',
		justifyContent: 'center',
		left: 0,
		overflow: 'hidden',
	},
	reels: {
		display: 'flex',
		overflow: 'hidden',
		perspective: 1100,
		transform: 'rotateY(0deg)',
		transformStyle: 'preserve-3d',
	},
	symbol: {},
});
