import { useLambda } from '../../models/hooks/useLambda';
import { INote } from '@ViewModels';
import { FC, createContext, useState } from 'react';
import * as React from 'react';

export interface IDealModalContext {
	note: INote;
	setNote: React.Dispatch<React.SetStateAction<INote>>;
	interactionNote: INote;
	setInteractionNote: React.Dispatch<React.SetStateAction<INote>>;
	showDealModal: boolean;
	setShowDealModal: React.Dispatch<React.SetStateAction<boolean>>;
	setShowDealModalLambda: (show: boolean) => () => void;
}

// @ts-ignore
export const DealModalContext = createContext<IDealModalContext>(null);

export const DealModalProvider: FC = ({ children }) => {
	const [showDealModal, setShowDealModal, setShowDealModalLambda] = useLambda(false);
	// @ts-ignore
	const [note, setNote] = useState<INote>(null);
	// @ts-ignore
	const [interactionNote, setInteractionNote] = useState<INote>(null);

	return (
		<DealModalContext.Provider
			value={{
				interactionNote,
				note,
				setInteractionNote,
				setNote,
				setShowDealModal,
				setShowDealModalLambda,
				showDealModal,
			}}
		>
			{children}
		</DealModalContext.Provider>
	);
};

export const useDealModal = () => {
	return React.useContext<IDealModalContext>(DealModalContext);
};
