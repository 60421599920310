import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const TagAddIcon: React.FC<IProps> = props => {
	const { className, fill } = props;
	return (
		<SvgIcon height={24} width={24} viewBox='4 4 24 24' className={`${className || ''}`}>
			<g fill='none' fillRule='evenodd' id='Page-1' stroke='none' strokeWidth='1'>
				<g fill={fill || '#fff'}>
					<path d='M9,21 L9,18 L10,18 L10,21 L13,21 L13,22 L10,22 L10,25 L9,25 L9,22 L6,22 L6,21 L9,21 L9,21 Z M6.7410124,16.7410124 L4,14 L4,5.99961498 C4,4.88743329 4.89525812,4 5.99961498,4 L14,4 L27.3809027,17.3809027 C28.1640508,18.1640508 28.168017,19.4319586 27.3884415,20.2115341 L20.2115341,27.3884415 C19.433119,28.1668566 18.1646418,28.1646418 17.3809027,27.3809027 L14.2589876,24.2589876 C13.3067391,25.8979838 11.5320645,27 9.5,27 C6.46243373,27 4,24.5375663 4,21.5 C4,19.4679355 5.10201625,17.6932609 6.7410124,16.7410124 L6.7410124,16.7410124 L6.7410124,16.7410124 Z M7.76440504,16.2794522 L5,13.5 L5,6.00844055 C5,5.45699692 5.45149422,5 6.00844055,5 L13.5,5 L26.6620109,18.0535183 C27.0531075,18.4413912 27.0550094,19.0704849 26.6678854,19.4602516 L19.5042948,26.672744 C19.1183535,27.0613199 18.488383,27.0618028 18.0998579,26.671163 L14.7110523,23.2639115 C14.8984126,22.710211 15,22.116969 15,21.5 C15,18.4624337 12.5375663,16 9.5,16 C8.89353564,16 8.3099972,16.0981575 7.76440504,16.2794522 L7.76440504,16.2794522 L7.76440504,16.2794522 Z M9.5,12 C10.8807119,12 12,10.8807119 12,9.5 C12,8.11928806 10.8807119,7 9.5,7 C8.11928806,7 7,8.11928806 7,9.5 C7,10.8807119 8.11928806,12 9.5,12 L9.5,12 Z M9.5,11 C10.3284272,11 11,10.3284272 11,9.5 C11,8.67157283 10.3284272,8 9.5,8 C8.67157283,8 8,8.67157283 8,9.5 C8,10.3284272 8.67157283,11 9.5,11 L9.5,11 Z M9.5,26 C11.9852815,26 14,23.9852815 14,21.5 C14,19.0147185 11.9852815,17 9.5,17 C7.0147185,17 5,19.0147185 5,21.5 C5,23.9852815 7.0147185,26 9.5,26 L9.5,26 Z' />
				</g>
			</g>
		</SvgIcon>
	);
};
