import { CssSelectors } from '../../../web/styles/styles';
import { brandPrimary, white } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	draggableIcon: {
		height: 15,
		marginLeft: -3,
	},
	header: {
		...aidaBaseStyles.flexHorizontalCenter,
		background: brandPrimary,
		height: 30,
		justifyContent: 'space-between',
		padding: '0 14px',
		...CssSelectors.allChildren(
			{
				paddingTop: 5,
			},
			':first-child'
		),
	},
	headerButton: {
		display: 'block',
		width: '100%',
	},
	icon: {
		height: 17,
		width: 17,
	},
	iconContainer: {
		'::before': {
			content: '" "',
			height: 40,
			left: '50%',
			marginLeft: -20,
			marginTop: -20,
			position: 'absolute',
			top: '50%',
			width: 40,
		},
		cursor: 'pointer',
		marginRight: 0,
		marginTop: 2,
		position: 'relative',
	},
	queueLeadNoteSliderContainer: {
		...aidaBaseStyles.cardShadow,
		background: white,
		borderRadius: 4,
		bottom: 0,
		height: 560,
		overflow: 'hidden',
		position: 'fixed',
		right: 0,
	},
});
