import { StyleSheet } from 'aphrodite';
import { brandPrimary } from '../../../aida/styles/colors';
import { white } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	ghostsContainer: {
		...aidaBaseStyles.flexCol,
	},
	text: {
		color: white,
		marginTop: 30,
	},
	whoYaGonnaCallContainer: {
		...aidaBaseStyles.flexCenter,
		background: brandPrimary,
		borderRadius: 10,
		height: '100%',
		width: '100%',
	},
});
