import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import { brandPrimaryHover } from '../../../../styles/colors';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const ForwardIcon: React.FC<IProps> = ({ className = '', fillColor = brandPrimaryHover }) => {
	return (
		<SvgIcon className={`forward-icon ${className}`} height={18} width={20}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.485 14.985V5.007l6.758 4.989-6.758 4.989Zm8.06-6.578-6.759-4.99c-.623-.462-1.526-.548-2.233-.219C.83 3.533.363 4.243.363 5.007v9.978c0 .763.467 1.473 1.19 1.81a2.21 2.21 0 0 0 2.234-.22l6.759-4.99c.52-.385.818-.964.818-1.59 0-.624-.298-1.203-.82-1.588ZM15.364 16V2h2l.002 14h-2.002Zm2-16h-2c-1.103 0-2 .896-2 2v14c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2V2c0-1.104-.897-2-2-2Z'
				fill={fillColor}
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M1.628 4.027V16l8.236-5.132V9L1.628 4.027Z' fill={fillColor} />
			<rect x='14.364' y='1' width='4' height='16' rx='2' fill={fillColor} />
		</SvgIcon>
	);
};
