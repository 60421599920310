import { getDisplayName } from '../../../../models/UiUtils';
import { useContact } from '../../../entities/Contact/useContact';
import { getBoolFromStatus } from '../../../entities/utils/getBoolFromStatus';
import { CustomAudioPlayer } from '../../CustomAudioPlayer';
import moment from 'moment';
import { useEffect } from 'react';
import * as React from 'react';

interface IProps {
	autoplay: boolean;
	contactId: string;
	creationDate: string;
	creatorName: string;
	phoneCallId: string;
	phoneNumber: string;
	recordingSource: string;
}

export const CallDetailsAudioPlayer: React.FC<IProps> = ({
	autoplay = false,
	contactId,
	creationDate,
	creatorName,
	phoneCallId,
	phoneNumber,
	recordingSource,
}) => {
	const { data, empty, get, status } = useContact();
	const { isEmpty, isWaiting, isWorking } = getBoolFromStatus({
		empty,
		status,
	});

	useEffect(() => {
		if (contactId) {
			get(contactId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contactId]);

	const defaultSubtitle = `${creatorName} called ${phoneNumber}`;
	// @ts-ignore
	const contactSubtitle = `${creatorName} called ${getDisplayName(data)}`;

	const title = `Call Recording (${moment(creationDate).format('M/DD/YYYY [at] h:mma')})`;
	const subtitle = isWorking ? '...' : isEmpty || isWaiting ? defaultSubtitle : contactSubtitle;

	return (
		<CustomAudioPlayer
			autoplay={autoplay}
			phoneCallId={phoneCallId}
			source={recordingSource}
			subtitle={subtitle}
			title={title}
		/>
	);
};
