import { white } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		margin: 'auto',
		marginBottom: 30,
		width: '100%',
	},
	containerSmallDistance: {
		marginBottom: 5,
	},
	content: {
		background: white,
		borderCollapse: 'collapse',
		borderRadius: 10,
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		padding: 20,
		position: 'relative',
	},
	header: {
		color: white,
		fontSize: 16,
		marginBottom: 15,
	},
	splashScreen: {
		alignItems: 'center',
		background: 'rgba(255, 255, 255, .8)',
		borderRadius: 10,
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		left: 0,
		position: 'absolute',
		top: 0,
		width: '100%',
		zIndex: 10,
	},
});
