import { FetchStatus } from '../../entities/utils/useStatesOnFetch';
import { ContentBox } from '../generics/ContentBox';
import { Group } from '../leadView/components/Group';
import { useIntegrationProperties } from './IntegrationPropertiesContext';
import { AddPropertiesButton } from './components/AddPropertiesButton';
import { ICheckboxListItem } from './components/CheckboxList';
import { DealAddPropertyList } from './components/DealAddPropertyList';
import { DealPropertyCard } from './components/DealPropertyCard';
import { EmptyState, EmptyStateVariations } from './components/EmptyState';
import { PropertyItemRow } from './components/PropertyItemRow';
import { SelectedTagsContainer } from './components/SelectedTagsContainer';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router';

// @ts-ignore
let savedItems: ICheckboxListItem[] = null;

export const AddDealProperties: React.FC = () => {
	const [isAdding, setAdding] = useState(false);

	// @ts-ignore
	const [selectedItems, setSelected] = useState<ICheckboxListItem[]>(null);

	const history = useHistory();

	const { config, status, update } = useIntegrationProperties();

	const moveToSaveOrClear = () => {
		setAdding(!isAdding);
		savedItems = selectedItems;
	};

	const cancelAndGoBack = () => {
		history.push('/integration/deal-properties');
	};

	const handleSelectedProperties = (items: ICheckboxListItem[]) => {
		setSelected(items);
	};

	const handleSave = () => {
		if (!config || !config.properties) {
			return;
		}

		selectedItems?.forEach(x => {
			// @ts-ignore
			// @ts-ignore
			// @ts-ignore
			config.properties.find(prop => prop.name === x.content.name).addToForm = true;
		});

		update({ ...config, properties: config.properties }, cancelAndGoBack);
	};

	const handleClear = () => {
		setAdding(false);
		setSelected(savedItems);
	};

	const handleRemoveFromSelected = (item: ICheckboxListItem) => () => {
		const newValues = [...selectedItems].filter(i => i.id !== item.id);
		setSelected(newValues);
	};

	return (
		<Group>
			<ContentBox
				className={css(styleSheet.addPropertiesContentBox)}
				maxWidth={430}
				title="Select the Hubspot properties you'd like to add below:"
				titleAlignment='center'
			>
				<DealAddPropertyList
					// @ts-ignore
					defaultValues={config?.properties}
					isAdding={isAdding}
					isLoading={status !== FetchStatus.Done}
					onSelect={handleSelectedProperties}
				/>
			</ContentBox>

			<AddPropertiesButton
				disabled={!selectedItems || selectedItems?.length <= 0}
				isAdding={isAdding}
				onClickToAdd={moveToSaveOrClear}
				onClickToCancel={cancelAndGoBack}
			/>

			<ContentBox
				className={css(styleSheet.addPropertiesContentBox)}
				isBlocked={status === FetchStatus.Working}
				maxWidth={430}
				title='The follow fields will appear when adding a deal:'
				titleAlignment='center'
			>
				{isAdding ? (
					<SelectedTagsContainer onClickToSave={handleSave} onClickToClear={handleClear}>
						{selectedItems?.map(item => {
							return (
								<PropertyItemRow
									key={item.id}
									onClickToRemove={handleRemoveFromSelected(item)}
									renderLeft={<DealPropertyCard label={item.label} />}
								/>
							);
						})}
					</SelectedTagsContainer>
				) : (
					<EmptyState variation={EmptyStateVariations.Flag} />
				)}
			</ContentBox>
		</Group>
	);
};
