import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useContext, useMemo } from 'react';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { ViewType } from '../../components/LeaderboardComponents/types';
import { ITab, Tabs } from '../../components/Tabs';
import { LeadView } from '../../components/leadView/LeadView';
import { useQueryParameter } from '../../hooks/query';
import { ViewportContext } from '../../hooks/useViewport';
import { brandSecondary, white } from '../../styles/colors';
import { Demos } from './components/Demos';
import { Leaderboard } from './components/Leaderboard';
import { Prizes } from './components/Prizes';
import { styleSheet } from './styles';

const LeaderboardContainerBase: React.FC = () => {
	const [, view, setView] = useQueryParameter<ViewType>('view', ViewType.Leaderboard);
	// @ts-ignore
	const { isBelowSmall } = useContext(ViewportContext);
	const userSession = useUserSession();

	const handleTabsChange = (selectedTab: ViewType) => {
		setView(selectedTab);
	};

	// @ts-ignore
	const tabs = useMemo<ITab[]>(() => {
		const achievementsEnabled = userSession.account?.features?.aida?.achievementsEnabled;
		return [
			{
				content: <Leaderboard />,
				name: 'leaderboard',
				renderLabel: (selectedTab: string) => (
					<LeadView.Title
						color={selectedTab !== 'leaderboard' ? brandSecondary : white}
						size='small'
						title={achievementsEnabled ? 'Leaders' : 'Leaderboard'}
					/>
				),
			},
			{
				content: <Demos />,
				name: 'demos',
				renderLabel: (selectedTab: string) => (
					<LeadView.Title color={selectedTab !== 'demos' ? brandSecondary : white} size='small' title='Demos' />
				),
			},
			achievementsEnabled
				? {
						content: <Prizes />,
						name: 'prizes',
						renderLabel: (selectedTab: string) => (
							<LeadView.Title color={selectedTab !== 'prizes' ? brandSecondary : white} size='small' title='Prizes' />
						),
					}
				: null,
		].filter(x => !!x);
	}, [userSession.account?.features?.aida?.achievementsEnabled]);

	return (
		<Tabs
			className={css(!isBelowSmall ? styleSheet.leaderboardTabs : null)}
			defaultTab={view}
			selectedTab={view}
			// @ts-ignore
			onChange={handleTabsChange}
			tabs={tabs}
		/>
	);
};

export const LeaderboardContainer = observer(LeaderboardContainerBase);
