import * as Api from '@ViewModels';
import { animated } from '@react-spring/web';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { useAudioPlayer } from '../../../hooks/useSound';
import { ISlotMachineComponent } from '../../../models/slotMachines';
import { SlotMachineViewModel } from '../../../viewModels/slotMachines';
import { SlotMachineEndGameModal } from '../SlotMachineEndGameModal';
import HandleAudioFileUrl from '../coins.mp3';
import { useSlotMachineEndGameModal, useSlots } from '../hooks';
import HandleImageUrl from './highScoreSlotMachineHandle.svg';
import MachineBodyImageUrl from './highScoreSlotMachineMachineBody.svg';
import PlayNowImageUrl from './highScoreSlotMachinePlayNow.svg';
import ReelShadowsImageUrl from './highScoreSlotMachineReelShadows.svg';
import ReelsImageUrl from './highScoreSlotMachineReels.svg';
import WinAudioFileUrl from './highScoreWin.mp3';
import { Background, HighScoreSymbol, Title } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	slotMachine: SlotMachineViewModel;
	scale?: number;
}

export const MachineDefaultSize = 3238;

/**
 * Note: audio files are from: https://mixkit.co/free-sound-effects/slot-machine/ License states free to use in
 * comercial projects: https://mixkit.co/license/#sfxFree
 */
export const HighScoreSlotMachine: React.FC<IProps> & Partial<ISlotMachineComponent> = observer(props => {
	const { className, slotMachine, styles = [], scale = 0.33 } = props;
	const { handleProps, loadingSpin, onHandleClick, reelItemProps } = useSlots(slotMachine, 'HighScoreSlotMachine');
	const { showEndGameModalWithTimeout, ...endGameModalProps } = useSlotMachineEndGameModal(slotMachine);
	const winAudio = useAudioPlayer(WinAudioFileUrl);
	const handleAudio = useAudioPlayer(HandleAudioFileUrl);
	const getScaledValue = (value: number) => {
		return scale * value;
	};

	const spin = React.useCallback(async () => {
		handleAudio.play();
		await onHandleClick();
		handleAudio.stop();
		if (slotMachine.spinOutcome?.gameOutcomeResultType === Api.GameOutcomeResultType.Win) {
			winAudio.play();
		}
		showEndGameModalWithTimeout(3000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onHandleClick, showEndGameModalWithTimeout, handleAudio, winAudio]);

	return (
		<div className={`${css(styleSheet.container, ...styles)} invader-slot-machine ${className || ''}`}>
			{loadingSpin ? <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} /> : null}
			<SlotMachineEndGameModal {...endGameModalProps} />
			<animated.div
				className={css(styleSheet.machineContainer)}
				style={{
					height: getScaledValue(MachineDefaultSize),
					width: getScaledValue(MachineDefaultSize),
				}}
			>
				<Background
					className={css(styleSheet.machinePart)}
					style={{
						height: getScaledValue(2966),
						left: getScaledValue(26),
						top: getScaledValue(319),
						width: getScaledValue(3186),
					}}
				/>
				<img
					className={css(styleSheet.machinePart)}
					src={MachineBodyImageUrl}
					style={{
						height: getScaledValue(1458),
						left: getScaledValue(974),
						top: getScaledValue(1378),
						width: getScaledValue(1340.6),
					}}
				/>
				<img
					className={css(styleSheet.machinePart)}
					onClick={!loadingSpin && !slotMachine?.spinOutcome ? spin : undefined}
					src={PlayNowImageUrl}
					style={{
						height: getScaledValue(105),
						left: getScaledValue(1231),
						top: getScaledValue(2339),
						width: getScaledValue(750),
					}}
				/>
				<img
					className={css(styleSheet.machinePart)}
					src={ReelsImageUrl}
					style={{
						height: getScaledValue(435.69),
						left: getScaledValue(1175),
						top: getScaledValue(1479),
						width: getScaledValue(888.36),
					}}
				/>
				<div
					className={css(styleSheet.machinePart, styleSheet.reels)}
					style={{
						height: getScaledValue(435.69),
						left: getScaledValue(1175),
						top: getScaledValue(1479),
						width: getScaledValue(888.36),
					}}
				>
					{slotMachine.reels.map((reel, i) => {
						const reelWidth = getScaledValue(251.36);
						const reelHeight = getScaledValue(435.69);
						const spacing = getScaledValue(68);
						return (
							<animated.div
								className={css(styleSheet.machinePart, styleSheet.reel)}
								key={reel.id}
								style={{
									height: reelHeight,
									marginLeft: (reelWidth + spacing) * i,
									perspective: `${getScaledValue(500)}px`,
									width: reelWidth,
								}}
							>
								{reelItemProps[i]?.map((animatedStyles, index) => {
									const { rotation, blur, ...restProps } = animatedStyles;
									return (
										<animated.div
											key={index}
											className={css(styleSheet.machinePart, styleSheet.reelItem)}
											style={{
												...restProps,
												filter: blur
													.to({
														output: [0, 1, 0.5, 0],
														range: [0, 0.5, 0.75, 1],
													})
													.to(x => {
														return `blur(${x}px)`;
													}),
												height: reelHeight,
												transform: rotation.to(x => {
													return `rotateX(${x}deg)`;
												}),
												transformOrigin: `50% 50% -${reelHeight * 1.2}px`,
												width: reelWidth,
											}}
										>
											<HighScoreSymbol
												height={getScaledValue(230)}
												symbolType={slotMachine.reels[i].symbols[index].value}
											/>
										</animated.div>
									);
								})}
							</animated.div>
						);
					})}
				</div>
				<img
					className={css(styleSheet.machinePart)}
					src={ReelShadowsImageUrl}
					style={{
						height: getScaledValue(435.69),
						left: getScaledValue(1175),
						top: getScaledValue(1479),
						width: getScaledValue(888.36),
					}}
				/>
				<animated.img
					className={css(styleSheet.machinePart)}
					onClick={!loadingSpin && !slotMachine?.spinOutcome ? spin : undefined}
					src={HandleImageUrl}
					style={{
						height: getScaledValue(578.13),
						left: getScaledValue(2289.46),
						perspective: 1100,
						top: getScaledValue(1294.31),
						transform: handleProps.rotation.to(x => {
							return `rotateX(${x}deg)`;
						}),
						transformOrigin: `0% 105% ${getScaledValue(-15)}px`,
						transformStyle: 'preserve-3d',
						width: getScaledValue(117.3),
					}}
				/>
				<Title
					className={css(styleSheet.machinePart)}
					style={{
						height: getScaledValue(1294),
						left: getScaledValue(730),
						top: getScaledValue(0.6),
						width: getScaledValue(1767.03),
					}}
				/>
			</animated.div>
		</div>
	);
});

HighScoreSlotMachine.PreloadImageUrls = [
	HandleImageUrl,
	MachineBodyImageUrl,
	PlayNowImageUrl,
	ReelShadowsImageUrl,
	ReelsImageUrl,
];
