import { StyleDeclarationValue, css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { useLambda } from '../../../models/hooks/useLambda';
import { TinyPopover } from '../../../web/components/TinyPopover';
import { useDealModal } from '../../contexts/dealModal';
import { useQueue } from '../../hooks/queue';
import { brandSecondary } from '../../styles/colors';
import { IDeal, IPreviousDeal } from '../../viewModels/leads/interfaces';
import { DollarSignIcon } from '../svgs/icons/DollarSignIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	deal?: IDeal;
	previousDeals?: IPreviousDeal[];
}

export const LastDeal: React.FC<IProps> = props => {
	const queue = useQueue();
	const dealModal = useDealModal();
	const { deal, previousDeals, className, styles = [] } = props;
	const [isOpen, , setIsOpen] = useLambda(false);
	if (!deal && !previousDeals?.length) {
		return null;
	}

	const onClick = () => {
		// @ts-ignore
		queue.lead?.loadDealForm(queue.lead?.lastDeal?.id);
		dealModal.setShowDealModal(true);
	};

	return (
		<div className={`${css(styleSheet.container, ...styles)} last-deal ${className || ''}`}>
			{deal ? (
				<div className={css(styleSheet.container)}>
					<DollarSignIcon fill={brandSecondary} className={css(styleSheet.dollarIcon)} />
					<span className={css(styleSheet.dealText)} onClick={onClick}>
						Deal: {deal.name}
						<span className={css(styleSheet.dealDate)}>
							&nbsp;(created {moment(deal.creationDate).format('M/D/YY h:mm a')})
						</span>
					</span>
				</div>
			) : (
				<div />
			)}
			{/* @ts-ignore */}
			{previousDeals?.length > 0 && (
				<TinyPopover
					anchor={
						<span className={css(styleSheet.prevDealsAnchor)} onMouseEnter={setIsOpen(true)}>
							Last Deal Notes
						</span>
					}
					isOpen={isOpen}
					dismissOnOutsideAction={true}
					onRequestClose={setIsOpen(false)}
					placement={['left']}
				>
					<div className={css(styleSheet.prevDeals)}>
						<div className={css(styleSheet.prevDealsTitle)}>Previous Deals</div>
						{/* @ts-ignore */}
						{previousDeals.map((prevDeal, i) => {
							return (
								<div key={i} className={css(styleSheet.prevDeal)}>
									<div className={css(styleSheet.prevDealName)}>
										{prevDeal.name}
										{prevDeal.date && (
											<span className={css(styleSheet.prevDealDate)}>
												&nbsp;({moment(prevDeal.date).format('M/D/YYYY h:mm a')})
											</span>
										)}
									</div>
									<div className={css(styleSheet.prevDealLostReason)}>Lost Reason: {prevDeal.lostReason}</div>
									<div className={css(styleSheet.prevDealDescription)}>
										{prevDeal.description?.split('\n')?.map((x, j) => {
											return (
												<div key={j}>
													{x}
													<br />
												</div>
											);
										})}
									</div>
								</div>
							);
						})}
					</div>
				</TinyPopover>
			)}
		</div>
	);
};
