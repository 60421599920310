import { css } from 'aphrodite';
import { useState } from 'react';
import * as React from 'react';
import { Button } from '../../../../web/components/Button';
import { TextInput } from '../../../../web/components/TextInput';
import { BlueModal } from '../../BlueModal';
import { Fill } from '../../leadView/components/Fill';
import { Group } from '../../leadView/components/Group';
import { styleSheet } from '../styles';

interface IProps {
	onClickToClose: () => void;
	onClickToSave: (value: string) => void;
}

export const SetCustomValueModal: React.FC<IProps> = ({ onClickToClose, onClickToSave }) => {
	const [value, setValue] = useState('1');
	return (
		<BlueModal isOpen={true} onRequestClose={onClickToClose} className={css(styleSheet.customValueModal)}>
			<div className={css(styleSheet.title)}>Set Custom Value</div>
			<TextInput
				className={css(styleSheet.customValueInput)}
				inputId='custom-value'
				min={1}
				onChange={({ target }) => setValue(target.value)}
				type='number'
				value={value}
			/>
			<Group>
				<Fill />
				<Button label='Save' onClick={() => onClickToSave(value)} />
			</Group>
		</BlueModal>
	);
};
