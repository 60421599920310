import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { useRef, useState } from 'react';
import { Button } from '../../../../web/components/Button';
import { DeprecatedXIcon } from '../../../../web/components/svgs/icons/DeprecatedXIcon';
import { white } from '../../../styles/colors';
import { MemeViewModel } from '../../../viewModels/memes';
import { TransparentButton } from '../../TransparentButton';
import { styleSheet } from './styles';

interface IProps {
	meme: MemeViewModel;
	label: string;
	url: string;
	onFileSelected(file: File): void;
	className?: string;
	styles?: StyleDeclarationValue[];
	/** Comma separated list of file extensions */
	accept?: string;
}

const parseFileName = (url: string) => {
	const slashIndex = url.lastIndexOf('/') + 1;
	const stringAfterSlash = url.substring(slashIndex);
	const queryIndex = stringAfterSlash.indexOf('?');
	const filename = stringAfterSlash.substring(0, queryIndex);
	return filename.substring(0, 20);
};

export const MemeFile: React.FC<IProps> = props => {
	const { label, url, styles = [], className, accept, onFileSelected } = props;
	const inputRef = useRef<HTMLInputElement>(null);
	const [isReuploading, setReuploading] = useState(false);
	const [inputValue, setInputValue] = useState('');

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		// @ts-ignore
		onFileSelected?.(e.currentTarget.files[0]);
		setInputValue(e.currentTarget.value);
		setReuploading(false);
	};

	const onUploadClick = () => {
		inputRef.current?.click();
	};

	const onXClick = () => {
		setInputValue('');
		setReuploading(true);
	};

	return (
		<div className={`${css(styleSheet.container, ...styles)} meme ${className || ''}`}>
			<div className={css(styleSheet.file)}>
				<div>{label}</div>
				<div>
					{url && !isReuploading ? (
						<div className={css(styleSheet.uploadedFile)}>
							<div className={css(styleSheet.fileName)}>{parseFileName(url)}</div>
							<span className={css(styleSheet.removeIcon)}>
								<TransparentButton onClick={onXClick}>
									<DeprecatedXIcon fillColor={white} />
								</TransparentButton>
							</span>
						</div>
					) : (
						<Button label='Upload' size='small' onClick={onUploadClick} />
					)}
				</div>
				<input
					className={css(styleSheet.fileInput)}
					type='file'
					onChange={onFileChange}
					ref={inputRef}
					accept={accept}
					multiple={false}
					value={inputValue}
				/>
			</div>
		</div>
	);
};
