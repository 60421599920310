import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { Button } from '../../../../web/components/Button';
import { ISelectOption, Select } from '../../../../web/components/Select';
import { TextInput } from '../../../../web/components/TextInput';
import { LeadRuleViewModel } from '../../../viewModels/leadrules';
import { Fill } from '../../generics/Fill';
import { Group } from '../../leadView/components/Group';
import { styleSheet } from '../styles';
import { minDaysOptions, ownershipEnforcementOptions } from '../utils';
import { AvailableLeads } from './AvailableLeads';
import { generateCustomValueOption, getSelectedOption } from './RuleContainerHeader';
import { SetCustomValueModal } from './SetCustomValueModal';

interface IProps {
	availableLeadsLoading: boolean;
	availableLeadsNumber: number;
	leadRule: LeadRuleViewModel;
	onClickToSave: (values: { minDays: number; ownership: string; priority: number }) => void;
}

export const AdditionalSettings: React.FC<IProps> = observer(
	({ availableLeadsLoading, availableLeadsNumber, leadRule, onClickToSave }) => {
		const defaultMinDays = minDaysOptions[0];
		const defaultOwnership = ownershipEnforcementOptions[1];

		const [ownership, setOwnership] = useState(defaultOwnership);
		const [priority, setPriority] = useState(10);

		const [minDays, setMinDays] = useState(defaultMinDays);

		useEffect(() => {
			if (leadRule) {
				const getOwnership = ownershipEnforcementOptions.filter(
					item => item.dataContext === leadRule?.ownershipEnforcement
				)?.[0];
				setOwnership(getOwnership || defaultOwnership);
				// @ts-ignore
				setPriority(leadRule?.priority);
				// @ts-ignore
				setMinDays(getSelectedOption(leadRule?.minDaysSinceLastInteraction, minDaysOptions));
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [leadRule]);

		const handlePriorityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			const parsed = parseInt(event.target.value, 10);
			if (parsed && !isNaN(parsed)) {
				setPriority(parsed);
			} else if (!event.target.value) {
				// @ts-ignore
				setPriority(null);
			}
		};

		const handleOwnershipChange = (option: ISelectOption) => {
			setOwnership(option);
			leadRule?.setOwnershipEnforcement(option.dataContext);
		};

		const handleMinDaysChange = (option: ISelectOption) => {
			if (option.id !== 'custom') {
				leadRule?.setMinDaysSinceLastInteraction(option.dataContext);
				setMinDays(option);
				return;
			}
			setMinDays(option);
		};

		const handleSave = () => {
			leadRule?.setPriority(priority);
			onClickToSave({
				minDays: minDays?.dataContext,
				ownership: ownership?.dataContext,
				priority,
			});
		};

		return (
			<>
				<div className={css(styleSheet.ctaContainer)}>
					<Group vCentered={true} spacingSize={20}>
						<div className={css(styleSheet.sectionTitle)}>Additional Settings:</div>
						<>
							<div className={css(styleSheet.regularText)}>Priority:</div>
							<TextInput
								className={css(styleSheet.priorityTextInput)}
								inputId='option-priority-input'
								onChange={handlePriorityChange}
								type='text'
								value={priority}
							/>
						</>
						<>
							<div className={css(styleSheet.regularText)}>Ownership:</div>
							<Select
								styles={[styleSheet.regularSelect]}
								onOptionClick={handleOwnershipChange}
								options={ownershipEnforcementOptions}
								selectedOption={ownership}
							/>
						</>
						<>
							<div className={css(styleSheet.regularText)}>Min days:</div>
							<Select
								styles={[styleSheet.timeSelect]}
								onOptionClick={handleMinDaysChange}
								options={minDaysOptions}
								selectedOption={minDays}
							/>
							{minDays?.id === 'custom' ? (
								<SetCustomValueModal
									onClickToClose={() =>
										// @ts-ignore
										setMinDays(getSelectedOption(leadRule?.minDaysSinceLastInteraction, minDaysOptions))
									}
									onClickToSave={value => {
										setMinDays(generateCustomValueOption(Number(value)));
										leadRule.setMinDaysSinceLastInteraction(Number(value));
									}}
								/>
							) : null}
						</>
					</Group>
				</div>
				<div className={css(styleSheet.divider)} />
				<div className={css(styleSheet.ctaContainer)}>
					<Group vCentered={true} spacingSize={20}>
						<Button label='Save' onClick={handleSave} />
						<AvailableLeads availableLeadsLoading={availableLeadsLoading} availableLeadsNumber={availableLeadsNumber} />
						<Fill />
					</Group>
				</div>
			</>
		);
	}
);
