import { Button } from '../../../../../web/components/Button';
import { LoadingSpinner } from '../../../../../web/components/LoadingSpinner';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	buttonLabel?: string;
	imageSrc: string;
	isLoading?: boolean;
	onClick: () => void;
}

export const UtilityCard: React.FC<IProps> = ({ buttonLabel, imageSrc, isLoading, onClick }) => {
	return (
		<div className={css(styleSheet.utilityCard)}>
			<div className={css(styleSheet.utilityCardImage)}>
				<img src={imageSrc} />
			</div>
			<div className={css(styleSheet.utilityActions)}>
				{isLoading ? (
					<LoadingSpinner type='small' />
				) : (
					// @ts-ignore
					<Button label={buttonLabel} onClick={onClick} kind='secondary' />
				)}
			</div>
		</div>
	);
};
