import { css } from 'aphrodite';
import * as React from 'react';
import { DisclosureIcon } from '../../../../web/components/svgs/icons/DisclosureIcon';
import { brandPrimary, white } from '../../../styles/colors';
import { TransparentButton } from '../../TransparentButton';
import { styleSheet } from '../styles';

export enum SortOptions {
	Demos = 'demos',
	Dials = 'dials',
	Connections = 'connections',
	Points = 'points',
}

interface IProps {
	columnKey: string;
	isAsc?: boolean;
	onClickToSort: (key: string) => void;
	sortBy?: SortOptions;
	width?: number;
}

export const ListHeader: React.FC<IProps> = ({ columnKey, isAsc, onClickToSort, sortBy, width }) => {
	const handleClick = (key: string) => () => onClickToSort(key);
	return (
		<div className={css(styleSheet.leaderColumn)} style={{ width }}>
			<TransparentButton onClick={handleClick(columnKey)}>
				<DisclosureIcon
					className={css(styleSheet.arrowIcon, isAsc && styleSheet.arrowAsc)}
					fillColor={sortBy === columnKey ? white : brandPrimary}
				/>
			</TransparentButton>
		</div>
	);
};
