import { FetchStatus } from './useStatesOnFetch';

export const getBoolFromStatus = ({ empty, status }: { empty: boolean; status: FetchStatus }) => {
	return {
		isEmpty: empty === true,
		isLoading: status === FetchStatus.Waiting || status === FetchStatus.Working,
		isWaiting: status === FetchStatus.Waiting,
		isWorking: status === FetchStatus.Working,
	};
};
