import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

export enum SpeechBubbleType {
	Error = 'bubbleTypeError',
	Normal = 'bubbleTypeNormal',
	Success = 'bubbleTypeSuccess',
}

interface IProps {
	className?: string;
	onCloseClick?: () => void;
	tailSide?: 'left' | 'right';
	type?: SpeechBubbleType;
}

export const SpeechBubble: React.FC<IProps> = ({
	children,
	className = '',
	tailSide = 'right',
	type = SpeechBubbleType.Normal,
}) => {
	const getTypeSide = () => {
		if (tailSide === 'left') {
			switch (type) {
				case SpeechBubbleType.Error:
					return styleSheet.bubbleTypeErrorLeft;
				case SpeechBubbleType.Success:
					return styleSheet.bubbleTypeSuccessLeft;
				default:
					return styleSheet.bubbleTypeNormalLeft;
			}
		} else {
			switch (type) {
				case SpeechBubbleType.Error:
					return styleSheet.bubbleTypeErrorRight;
				case SpeechBubbleType.Success:
					return styleSheet.bubbleTypeSuccessRight;
				default:
					return styleSheet.bubbleTypeNormalRight;
			}
		}
	};

	return (
		<div
			className={`${css(
				styleSheet.speechBubbleContainer,
				styleSheet[type],
				getTypeSide(),
				styleSheet[tailSide]
			)} ${tailSide} ${className}`}
		>
			<div className={css(styleSheet.contentWrapper)}>{children}</div>
		</div>
	);
};
