import { useImageLoader } from '../../../../models/hooks/useImageLoader';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { SlotMachineViewModel } from '../../../viewModels/slotMachines';
import { DaisyBoozeCruiseSlotMachine } from '../DaisyBoozeCruiseSlotMachine';
import { GoldRushSlotMachine } from '../GoldRushSlotMachine';
import { HighScoreSlotMachine } from '../HighScoreSlotMachine';
import { Title as HighScoreTitle } from '../HighScoreSlotMachine/presentation';
import { BackgroundImageUrls as PrizeLegendBackgroundImageUrls } from '../SlotMachinePrizeLegend/styles';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

export interface ISlotMachineLoaderProps {
	machineType: Api.SlotMachineType;
}

export const SlotMachineLoader: React.FC<ISlotMachineLoaderProps> = props => {
	const { children, machineType } = props;
	// @ts-ignore
	const [preloadImageUrls, setPreloadImageUrls] = React.useState<string[]>(null);

	useImageLoader(
		preloadImageUrls,
		// @ts-ignore
		React.useCallback(() => setPreloadImageUrls(null), [])
	);

	React.useEffect(() => {
		switch (machineType) {
			case Api.SlotMachineType.BoozeCruise: {
				setPreloadImageUrls([
					// @ts-ignore
					...DaisyBoozeCruiseSlotMachine.PreloadImageUrls,
					PrizeLegendBackgroundImageUrls[machineType],
				]);
				break;
			}
			case Api.SlotMachineType.GoldRush: {
				setPreloadImageUrls([...GoldRushSlotMachine.PreloadImageUrls, PrizeLegendBackgroundImageUrls[machineType]]);
				break;
			}
			case Api.SlotMachineType.Default: {
				// @ts-ignore
				setPreloadImageUrls([...HighScoreSlotMachine.PreloadImageUrls, PrizeLegendBackgroundImageUrls[machineType]]);
				break;
			}
			default: {
				// @ts-ignore
				setPreloadImageUrls(null);
				break;
			}
		}
	}, [machineType]);

	const renderTitle = React.useCallback((type: Api.SlotMachineType) => {
		switch (type) {
			case Api.SlotMachineType.Default: {
				return <HighScoreTitle className={css(styleSheet.loadingScreenTitle)} />;
			}
			default: {
				return null;
			}
		}
	}, []);

	if (!preloadImageUrls) {
		return <>{children}</>;
	}

	return (
		<div className={css(styleSheet.loadingScreenContainer)}>
			<div className={css(baseStyleSheet.absoluteCenter, styleSheet.loadingScreenContent)}>
				{renderTitle(machineType)}
				<LoadingSpinner type='large' />
			</div>
		</div>
	);
};

export const SlotMachine: React.FC<{ machine?: SlotMachineViewModel }> = observer(({ machine }) => {
	switch (machine?.type) {
		case Api.SlotMachineType.BoozeCruise: {
			return <DaisyBoozeCruiseSlotMachine slotMachine={machine} />;
		}
		case Api.SlotMachineType.GoldRush: {
			return <GoldRushSlotMachine slotMachine={machine} />;
		}
		case Api.SlotMachineType.Default: {
			return <HighScoreSlotMachine slotMachine={machine} />;
		}
		default: {
			return null;
		}
	}
});
