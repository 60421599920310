import { Group } from '../../../../components/leadView/components/Group';
import { grayDark, green, hotPink, kitTintColor, lightBlue, yellow } from '../../../../styles/colors';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import { useEffect, useState } from 'react';
import * as React from 'react';

interface IProps {
	onChange: (color: string) => void;
	defaultColor: string;
}

export const CategoryCardColors: React.FC<IProps> = ({ onChange, defaultColor }) => {
	const [currentColor, setColor] = useState(defaultColor);
	const defaultColors = [grayDark, green, hotPink, kitTintColor, lightBlue, yellow];

	useEffect(() => {
		if (defaultColor) {
			setColor(defaultColor);
		}
	}, [defaultColor]);

	const handleColorClick = (color: string) => () => {
		setColor(color);
		onChange?.(color);
	};

	return (
		<Group spacingSize={2} fullWidth={false}>
			{defaultColors.map(color => {
				const markAsSelected = color === currentColor ? styleSheet.colorOptionSelected : null;
				return (
					<div
						key={color}
						className={css(styleSheet.colorOption, markAsSelected)}
						onClick={handleColorClick(color)}
						style={{ backgroundColor: color }}
						role='button'
						tabIndex={0}
					/>
				);
			})}
		</Group>
	);
};
