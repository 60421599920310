import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import { brandSecondary } from '../../../styles/colors';
import * as React from 'react';

export enum LightningType {
	Lightning = 'Lightning',
	LightningEmpty = 'LightningEmpty',
	LightningDouble = 'LightningDouble',
}

interface IBaseLightning {
	className?: string;
	primaryColor?: string;
	secondaryColor?: string;
	backgroundColor?: string;
}

interface ILightning1Props extends IBaseLightning {
	type?: LightningType;
}

interface ILightning2Props extends IBaseLightning {
	type?: Omit<LightningType, LightningType.LightningEmpty>;
}

export const LightningGraphic1: React.FC<ILightning1Props> = ({
	className = '',
	type,
	primaryColor = brandSecondary,
	secondaryColor = brandSecondary,
	backgroundColor = '#0061A6',
}) => {
	return type === LightningType.LightningEmpty ? (
		<SvgIcon className={`lightning-empty-graphic-1 ${className}`} height={356} width={284}>
			<g filter='url(#filter0_d_0:5599)'>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M10 3L154.509 243.406L201.196 234.984L276.786 346L223.429 126.266L175.259 122.438L148.58 3H10Z'
					stroke={primaryColor}
					strokeWidth='6'
					shapeRendering='crispEdges'
					fill='transparent'
				/>
			</g>
			<defs>
				<filter
					id='filter0_d_0:5599'
					x='0.696533'
					y='0'
					width='283.004'
					height='355.688'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='4' />
					<feGaussianBlur stdDeviation='2' />
					<feComposite in2='hardAlpha' operator='out' />
					<feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
					<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_0:5599' />
					<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_0:5599' result='shape' />
				</filter>
			</defs>
		</SvgIcon>
	) : (
		<SvgIcon
			className={`lightning-${type === LightningType.LightningDouble ? 'double-' : ''}graphic-1 ${className}`}
			height={332}
			width={267}
		>
			{type === LightningType.LightningDouble && (
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M0 0L144.509 232.696L191.196 224.545L266.786 332L233 109L184 103L156 0H0Z'
					fill={backgroundColor}
				/>
			)}
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0 0L144.509 232.696L191.196 224.545L266.786 332L213.429 119.312L165.259 115.607L138.58 0H0Z'
				fill='url(#paint0_linear_0:2358)'
			/>
			<defs>
				<linearGradient id='paint0_linear_0:2358' x1='0' y1='0' x2='0' y2='332' gradientUnits='userSpaceOnUse'>
					<stop stopColor={primaryColor} />
					<stop offset='1' stopColor={secondaryColor} />
				</linearGradient>
			</defs>
		</SvgIcon>
	);
};

export const LightningGraphic2: React.FC<ILightning2Props> = ({
	className = '',
	type,
	primaryColor = brandSecondary,
	secondaryColor = brandSecondary,
	backgroundColor = '#0061A6',
}) => {
	if (type === LightningType.LightningEmpty) {
		// this type was not added in design
		return null;
	}

	return (
		<SvgIcon
			className={`lightning-${type === LightningType.LightningDouble ? 'double-' : ''}graphic-2 ${className}`}
			height={145}
			width={419}
		>
			{type === LightningType.LightningDouble && (
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M427.879 9L196.419 51.3077L182.248 13.2308L0 63L154.078 79L181.99 145L460 14.0769L427.879 9Z'
					fill={backgroundColor}
				/>
			)}
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M427.879 0L196.419 42.3077L182.248 4.23077L0 63L152.961 63.4615L182.248 121L460 5.07692L427.879 0Z'
				fill='url(#paint0_linear_31:418)'
			/>
			<defs>
				<linearGradient
					id='paint0_linear_31:418'
					x1='102.373'
					y1='-89.0917'
					x2='67.3046'
					y2='99.4625'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor={primaryColor} />
					<stop offset='1' stopColor={secondaryColor} />
				</linearGradient>
			</defs>
		</SvgIcon>
	);
};
