import {
	BaseObservablePageCollectionController,
	ICompany,
	IContact,
	IPhoneCall,
	IScheduledMeeting,
	UserSessionContext,
	ViewModel,
} from '@ViewModels';
import { computed, observable } from 'mobx';
import { ILeadFollowUp } from '../queue';
import { IDeal, ILeadInteraction, ILeadSearchView } from './interfaces';

class LeadSearchView extends ViewModel {
	@observable public company?: ICompany;
	@observable public decisionMakersContactIds?: string[];
	@observable public contactReferences?: IContact[];
	@observable public lastInteraction?: ILeadInteraction;
	@observable public followUp?: ILeadFollowUp;
	@observable public lastDeal?: IDeal;
	@observable public nextMeeting?: IScheduledMeeting;
	@observable public interactionPhoneCall?: IPhoneCall;

	@computed
	public get id() {
		return this.company?.id;
	}

	constructor(userSession: UserSessionContext, view: ILeadSearchView) {
		super(userSession);
		this.company = view.company;
		this.decisionMakersContactIds = view.decisionMakersContactIds;
		this.contactReferences = view.contactReferences;
		this.lastInteraction = view.lastInteraction;
		this.followUp = view.followUp;
		this.lastDeal = view.lastDeal;
		this.nextMeeting = view.nextMeeting;
		this.interactionPhoneCall = view.interactionPhoneCall;
	}
}

export class DemosToConfirmViewModel extends ViewModel {
	@observable public demosToConfirm: BaseObservablePageCollectionController<ILeadSearchView, LeadSearchView>;
	constructor(userSession: UserSessionContext) {
		super(userSession);
		this.demosToConfirm = new BaseObservablePageCollectionController<ILeadSearchView, LeadSearchView>({
			apiPath: this.composeApiUrl({ urlPath: 'lead/demosToConfirm' }),
			client: this.userSession.webServiceHelper,
			httpMethod: 'GET',
			transformer: x => new LeadSearchView(this.userSession, x),
		});
	}

	@computed
	get controller() {
		return this.demosToConfirm;
	}
}

export class DemosViewModel extends ViewModel {
	@observable public demos: BaseObservablePageCollectionController<ILeadSearchView, LeadSearchView>;
	constructor(userSession: UserSessionContext) {
		super(userSession);
		this.demos = new BaseObservablePageCollectionController<ILeadSearchView, LeadSearchView>({
			apiPath: this.composeApiUrl({ urlPath: 'lead/demos' }),
			client: this.userSession.webServiceHelper,
			httpMethod: 'GET',
			transformer: x => new LeadSearchView(this.userSession, x),
		});
	}

	@computed
	get controller() {
		return this.demos;
	}
}
