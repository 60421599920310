import { css } from 'aphrodite';
import * as React from 'react';
import { useState } from 'react';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { ContactViewModel, EmailAddress, IContact, IPhoneNumber } from '../../../viewmodels/AppViewModels';
import { Button } from '../../../web/components/Button';
import { DefaultConfirmationOptions } from '../../../web/components/ConfirmationDialog';
import { DeprecatedXIcon } from '../../../web/components/svgs/icons/DeprecatedXIcon';
import { WarningIcon } from '../../../web/components/svgs/icons/WarningIcon';
import { BlueConfirmationDialog } from '../BlueConfirmationDialog';
import { TransparentButton } from '../TransparentButton';
import { Group } from '../leadView/components/Group';
import { EmailsRepeater } from './components/EmailsRepeater';
import { MarkAsDM } from './components/MarkAsDM';
import { PhoneNumbersRepeater } from './components/PhoneNumbersRepeater';
import { TextFieldType } from './components/TextFieldType';
import { styleSheet } from './styles';

interface IProps {
	bare?: boolean;
	contactViewModel?: ContactViewModel;
	isDecisionMaker?: boolean;
	onCancel?: () => void;
	onSave: (updatedContact: IContact) => void;
}

export const ContactInfo: React.FC<IProps> = ({ bare, contactViewModel, isDecisionMaker, onCancel, onSave }) => {
	const contact = contactViewModel?.toJs();
	if (contact) {
		contact.isDecisionMaker = isDecisionMaker;
	}
	// @ts-ignore
	const [contactInfo, setContactInfo] = useState<IContact>(contact);

	const [phoneNumbers, setPhoneNumbers] = useState(contact?.phoneNumbers);
	const [emailAddresses, setEmailsAddresses] = useState(contact?.emailAddresses);

	const [confirmModalIsOpen, setConfirmModal] = useState(false);
	const [isEditing, setEditing] = useState(false);
	const userSession = useUserSession();

	const handleChange = ({ name, value }: { name: string; value: string }) => {
		setEditing(true);
		setContactInfo({ ...contactInfo, [name]: value });
	};

	const handlePhoneNumbers = (values: IPhoneNumber[]) => {
		setEditing(true);
		setPhoneNumbers(values);
	};

	const handleEmailsAddresses = (values: EmailAddress[]) => {
		setEditing(true);
		setEmailsAddresses(values?.filter(item => !!item?.value));
	};

	const handleDMCheckbox = (value: boolean) => {
		setEditing(true);
		setContactInfo({ ...contactInfo, isDecisionMaker: value });
	};

	const onClickToSave = () => {
		onSave({ ...contactInfo, emailAddresses, phoneNumbers });
	};

	const onClickToCancel = () => {
		if (!contactInfo || !isEditing) {
			// @ts-ignore
			onCancel();
		} else {
			setConfirmModal(true);
		}
	};

	const renderFields = () => (
		<Group vertical={true} spacingSize={20} className={css(styleSheet.contactInfoContainer)}>
			<Group>
				<TextFieldType
					value={contact?.firstName}
					isRequired={true}
					isHalf={true}
					label='First Name'
					name='firstName'
					onChange={handleChange}
				/>
				<TextFieldType
					value={contact?.lastName}
					isRequired={true}
					isHalf={true}
					label='Last Name'
					name='lastName'
					onChange={handleChange}
				/>
			</Group>
			<Group>
				<TextFieldType value={contact?.jobTitle} label='Job Title' name='jobTitle' onChange={handleChange} />
			</Group>
			<Group>
				{/* @ts-ignore */}
				<PhoneNumbersRepeater values={contact?.phoneNumbers} onChange={handlePhoneNumbers} />
			</Group>
			<Group>
				{/* @ts-ignore */}
				<EmailsRepeater values={contact?.emailAddresses} onChange={handleEmailsAddresses} />
			</Group>
			{!userSession.account.isLevitateSalesCoffeeAccount && (
				<Group>
					<TextFieldType value={contact?.bio} label='Note' name='bio' onChange={handleChange} />
				</Group>
			)}
			<div className={css(styleSheet.footer)}>
				<Button className={css(styleSheet.saveButton)} disabled={!contactInfo} label='Save' onClick={onClickToSave} />
				<MarkAsDM defaultChecked={isDecisionMaker} onChange={handleDMCheckbox} />
			</div>
		</Group>
	);

	const renderWithContainer = () => (
		<div className={css(styleSheet.contactInfoCard)}>
			<div className={`${css(styleSheet.contactInfoModal)}`}>
				<div className={css(styleSheet.header)}>
					<div className={css(styleSheet.title)}>{contact ? `Edit Contact` : 'New Contact'}</div>
					<TransparentButton onClick={onClickToCancel}>
						<DeprecatedXIcon />
					</TransparentButton>
				</div>
				<BlueConfirmationDialog
					icon={<WarningIcon />}
					modalProps={{
						isOpen: confirmModalIsOpen,
						// @ts-ignore
						onRequestClose: onCancel,
					}}
					options={DefaultConfirmationOptions}
					title='Discard unsaved changes?'
				/>
				{renderFields()}
			</div>
		</div>
	);

	return bare ? renderFields() : renderWithContainer();
};
