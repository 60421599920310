import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import { white } from '../../../../styles/colors';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const RowViewIcon: React.FC<IProps> = ({ className = '', fillColor = white }) => {
	return (
		<SvgIcon className={`row-view-icon ${className}`} height={18} width={16}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16 6H0V4h16v2Zm0-4H0V0h16v2Zm0 7.999H0v-2h16v2ZM16 14H0v-2h16v2Zm0 4H0v-2h16v2Z'
				fill={fillColor}
			/>
		</SvgIcon>
	);
};
