import { OpenNewWindowIcon } from '../../../../../web/components/svgs/icons/OpenNewWindowIcon';
import { brandPrimaryLight } from '../../../../styles/colors';
import { TransparentButton } from '../../../TransparentButton';
import { Group } from '../Group';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { ReactNode } from 'react';
import * as React from 'react';

interface IProps {
	hrefLink?: string;
	isSmall?: boolean;
	onClick?: () => void;
	subtext?: string;
	text: string;
	renderRightAccessory?: ReactNode;
}

export const TextColumn: React.FC<IProps> = ({ hrefLink, isSmall, onClick, renderRightAccessory, subtext, text }) => {
	const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
		event.preventDefault();
		event.stopPropagation();
		if (!(event.ctrlKey || event.metaKey)) {
			onClick?.();
			return;
		}
		window.open(hrefLink, '_blank');
	};
	const renderClickIcon = onClick && (
		<TransparentButton onClick={handleClick} title='Open in new window'>
			<OpenNewWindowIcon fill={brandPrimaryLight} className={css(styleSheet.icon)} />
		</TransparentButton>
	);
	return (
		<div className='text-column'>
			<Group>
				{isSmall ? <small>{text}</small> : <div>{text}</div>}
				{renderClickIcon}
				{renderRightAccessory}
			</Group>
			<div>{subtext && <small>{subtext}</small>}</div>
		</div>
	);
};
