import { CssSelectors } from '../../../web/styles/styles';
import { borderColor, brandPrimary, brandPrimaryHover, error } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addCcButton: {
		':hover': {
			color: brandPrimary,
		},
		color: brandPrimaryHover,
	},
	attachments: {
		padding: '0 10px',
	},
	ccContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '0 15px 10px',
		...CssSelectors.allDescendants(
			{
				paddingLeft: 0,
			},
			'.cc-field'
		),
	},
	ccField: {
		background: 'none',
		border: 'none',
		flexGrow: 1,
		...CssSelectors.allDescendants(
			{
				marginLeft: 0,
			},
			'.dropdown'
		),
	},
	decisionMakerLabel: {
		fontSize: 12,
	},
	doNotMarketContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
	},
	doNotMarketLabel: {
		':hover': {
			cursor: 'pointer',
		},
		color: error,
		fontSize: '11px',
		marginLeft: 8,
		textTransform: 'uppercase',
	},
	editorContainer: {
		flexGrow: 1,
		minHeight: 50,
		position: 'relative',
	},
	noteEditorContainer: {
		...aidaBaseStyles.flexCol,
		flexGrow: 1,
		...CssSelectors.allDescendants(
			{
				borderBottom: `1px solid ${borderColor}`,
				borderTop: `1px solid ${borderColor}`,
			},
			'.tox-editor-header'
		),
	},
});
