import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	name: string;
}

export const RuleName: React.FC<IProps> = ({ name }) => {
	return (
		<div className={css(styleSheet.name)} title={name}>
			{name}
		</div>
	);
};
