import { EmailAddress, IContact } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { EmailCampaignBrowserViewModel, ITemplateCard } from '../../../../viewmodels/AppViewModels';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { ISelectOption, Select } from '../../../../web/components/Select';
import IndicatorImage from '../../../assets/template-automation-indicator.png';
import { Group } from '../../generics/Group';
import { styleSheet } from '../styles';

export interface ISelectedEmailRecipient {
	contact: IContact;
	selectedEmailAddress?: EmailAddress;
}

export interface IProps {
	onTemplateChange: (selected: ISelectOption<ITemplateCard>) => void;
	options: ISelectOption<ITemplateCard>[];
	selected: ISelectOption<ITemplateCard>;
}

export const TemplateSelector: React.FC<IProps> = ({ onTemplateChange, options, selected }) => {
	const userSession = useUserSession();
	const [selectedOption, setSelectedOption] = useState<ISelectOption<ITemplateCard>>(selected);
	const browser = useRef(new EmailCampaignBrowserViewModel(userSession)).current;

	useEffect(() => {
		setSelectedOption(selected);
	}, [selected]);

	const handleSelectionChange = (option: ISelectOption<ITemplateCard>) => {
		setSelectedOption(option);
		onTemplateChange(option);
	};

	const renderTrigger = () => (
		<div className={css(styleSheet.trigger)}>
			{selectedOption?.icon ? selectedOption.icon : null}
			<span>{selectedOption?.text}</span>
		</div>
	);

	const renderSpinner = <LoadingSpinner type='tiny' />;

	return (
		<div className={css(styleSheet.emailTemplatesSelectContainer)}>
			{!browser || browser.isBusy ? (
				renderSpinner
			) : options.length ? (
				<Group>
					<Select
						options={options}
						onOptionClick={handleSelectionChange}
						selectedOptionTitle={renderTrigger()}
						selectedOption={selectedOption}
						styles={[styleSheet.emailTemplatesSelect]}
					/>
					<img src={IndicatorImage} height={54} />
				</Group>
			) : (
				<div>No templates found</div>
			)}
		</div>
	);
};
