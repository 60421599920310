import { ISelectOption, Select } from '../../../../../web/components/Select';
import { MoreIcon } from '../../../../../web/components/svgs/icons/MoreIcon';
import { styleSheet } from '../styles';
import * as React from 'react';

interface IProps {
	onClickToArchive?: () => void;
	onClickToBrowse?: () => void;
	onClickToRename?: () => void;
}

enum MoreOptions {
	Archive = 'archive',
	Browse = 'browse',
	Rename = 'rename',
}

export const CategoryCardOptions: React.FC<IProps> = ({ onClickToArchive, onClickToBrowse, onClickToRename }) => {
	const options: ISelectOption<MoreOptions>[] = [
		// {
		// 	dataContext: MoreOptions.Rename,
		// 	id: 'category-card-options-rename',
		// 	text: 'Rename',
		// 	type: 'blue',
		// },
		{
			dataContext: MoreOptions.Browse,
			id: 'category-card-options-browse',
			text: 'Browse Calls',
			type: 'blue',
		},
		{
			dataContext: MoreOptions.Archive,
			id: 'category-card-options-archive',
			text: 'Archive',
			type: 'blue',
		},
	];

	const handleSelectChange = (option: ISelectOption<MoreOptions>) => {
		switch (option.dataContext) {
			case MoreOptions.Archive:
				// @ts-ignore
				onClickToArchive();
				break;
			case MoreOptions.Browse:
				// @ts-ignore
				onClickToBrowse();
				break;
			case MoreOptions.Rename:
				// @ts-ignore
				onClickToRename();
				break;
			default:
				break;
		}
	};

	return (
		<Select
			onOptionClick={handleSelectChange}
			options={options}
			selectedOptionTitle={<MoreIcon fillColor='' />}
			showCaret={false}
			styles={[styleSheet.moreMenu]}
			triggerStyles={[styleSheet.moreMenuTrigger]}
		/>
	);
};
