import { brandSecondary } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	tabsContainer: {
		marginLeft: 'auto',
		marginRight: 'auto',
		maxWidth: 1100,
		position: 'relative',
		width: '100%',
	},
	tabsContainerMobile: {
		paddingLeft: 0,
		paddingRight: 0,
		width: '100vw',
	},
	tabsContent: {},
	tabsTab: {
		borderBottomColor: 'transparent',
		borderBottomStyle: 'solid',
		borderBottomWidth: 3,
		cursor: 'pointer',
		flex: 1,
	},
	tabsTabActive: {
		borderBottomColor: brandSecondary,
	},
	tabsTabs: {
		alignItems: 'center',
		borderBottomColor: brandSecondary,
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 30,
	},
	tabsTabsMobile: {
		marginBottom: 10,
	},
});
