import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { Button } from '../../../web/components/Button';
import { TextArea } from '../../../web/components/TextArea';
import { Fill } from '../../components/leadView/components/Fill';
import { Group } from '../../components/leadView/components/Group';
import { useErrorMessages, useToaster } from '../../hooks';
import { ApiClient } from '../../services/api';
import { BasicPage } from '../BasicPage';
import { styleSheet } from './styles';

export const VoicemailContainer: React.FC = () => {
	const userSession = useUserSession();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();

	const [message, setMessage] = useState<string>('');

	// @ts-ignore
	const client = new ApiClient(userSession);

	const getCurrentVoicemail = async () => {
		try {
			const result = await client.getVoicemailText();
			if (!result.success) {
				throw Api.asApiError(result);
			}
			// @ts-ignore
			setMessage(result.value);
			return result;
		} catch (error) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
	};

	const updateCurrentVoicemail = async (newMessage: string) => {
		try {
			const result = await client.updateVoicemailText(newMessage);
			if (!result.success) {
				throw Api.asApiError(result);
			}

			// @ts-ignore
			toaster.push({
				message: 'Voicemail message updated.',
				type: 'successMessage',
			});
			return result;
		} catch (error) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
	};

	useEffect(() => {
		getCurrentVoicemail();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const { target } = event;
		setMessage(target.value);
	};

	// const handlePreview = () => {};

	const handleSave = () => updateCurrentVoicemail(message);

	return (
		<BasicPage title='voicemail'>
			<div className={css(styleSheet.container)}>
				<div className={css(styleSheet.box)}>
					<Group vertical={true} spacingSize={25}>
						<div className={css(styleSheet.header)}>
							<strong>Customize Greeting</strong>
						</div>
						<div className={css(styleSheet.description)}>
							Type in your greeting below, and our robot will read it out loud when people leave a voicemail.
						</div>
						<div>
							<TextArea
								inputId='take-survey-feedback-input'
								onChange={handleInputChange}
								placeholder='Write your message here...'
								value={message}
							/>
						</div>
						<Group>
							<Fill />
							{/* <Button
								kind="reverse"
								label={ 'Play Greeting' }
								onClick={ handlePreview }
							/> */}
							<Button label='Save' onClick={handleSave} />
						</Group>
					</Group>
				</div>
			</div>
		</BasicPage>
	);
};
