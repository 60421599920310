import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../../../web/styles/styles';
import { brandPrimary, brandPrimaryDark, brandSecondary, white } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	checkbox: {
		color: '#FFFFFF',
		marginBottom: 5,
	},
	checkboxText: {
		fontSize: 14,
		letterSpacing: 1.1,
		paddingRight: 20,
		width: 250,
	},
	childCheckbox: {
		color: '#FFFFFF',
		margin: '5px 0 5px 20px',
	},
	multiSelect: {
		background: brandPrimary,
		border: `1px solid ${brandSecondary}`,
		borderRadius: 3,
		fontSize: 14,
		padding: 10,
		width: 266,
		...CssSelectors.allDescendants(
			{
				...CssSelectors.allDescendants(
					{
						fill: brandSecondary,
					},
					'polygon'
				),
			},
			'.disclosure-icon'
		),
		...CssSelectors.allDescendants(
			{
				color: white,
			},
			'.select-option-text'
		),
	},
	multiSelectDropdown: {
		background: brandPrimary,
		borderRadius: 3,
		fontSize: 14,
		marginLeft: -1,
		marginTop: 20,
		overflowX: 'hidden',
		padding: 10,
		width: 300,
		...CssSelectors.allDescendants(
			{
				backgroundColor: '#FFFFFF',
			},
			'.checkbox.checkbox-large'
		),
		...CssSelectors.allDescendants(
			{
				marginLeft: 1,
			},
			'.checkbox.checkbox-large img'
		),
	},
	optionsContainer: {
		height: 'auto',
		overflowY: 'auto',
		width: '100%',
	},
	select: {
		background: brandPrimary,
		border: `1px solid ${brandSecondary}`,
		...CssSelectors.allDescendants(
			{
				...CssSelectors.allDescendants(
					{
						fill: brandSecondary,
					},
					'polygon'
				),
			},
			'.disclosure-icon'
		),
		...CssSelectors.allDescendants(
			{
				color: white,
			},
			'.select-option-text'
		),
	},
	selectAnchor: {
		color: 'rgb(167 171 173)',
		display: 'block',
		maxWidth: 260,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	selectAnchorIcon: {
		position: 'absolute',
		right: 15,
		top: 15,
		transform: 'rotate(90deg)',
	},
	selectDisabled: {
		opacity: 0.5,
	},
	selectDropdown: {
		border: `1px solid ${brandSecondary}`,
		...CssSelectors.allDescendants(
			{
				background: brandPrimary,
			},
			'.options-container'
		),
		...CssSelectors.allDescendants(
			{
				background: brandPrimary,
				...CssSelectors.allDescendants({
					':hover': {
						background: brandPrimaryDark,
					},
					color: white,
				}),
			},
			'.option-container'
		),
		...CssSelectors.allDescendants(
			{
				background: brandPrimaryDark,
			},
			'.selected'
		),
	},
});
