import { StyleSheet } from 'aphrodite';
import { CssSelectors, Layouts } from '../../../../web/styles/styles';
import { brandSecondary, brandSecondaryHover, white } from '../../../styles/colors';
import { aidaBaseStyles } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		margin: '0 40px 20px 40px',
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'space-between',
	},
	fileInput: {
		display: 'none',
	},
	goalsContainer: {
		':first-of-type': {
			maxWidth: 'none',
		},
		paddingBottom: 40,
	},
	header: {
		...Layouts.horizontalStack(20),
	},
	infoRow: {
		...aidaBaseStyles.flexHorizontalCenter,
		margin: '0 40px 20px 40px',
	},
	rightContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		flexAlign: 'flex-end',
	},
	searchIcon: {
		marginRight: 6,
	},
	selectedUser: {
		color: white,
		fontSize: 18,
		marginRight: 5,
	},
	selectedUserClear: {
		':hover': {
			color: brandSecondaryHover,
			textDecoration: 'underline',
		},
		color: brandSecondary,
		cursor: 'pointer',
		fontSize: 18,
		marginLeft: 10,
	},
	userAutocomplete: {
		...CssSelectors.allDescendants(
			{
				minWidth: '100%',
			},
			'.person-auto-complete-anchor'
		),
		...CssSelectors.allDescendants(
			{
				maxWidth: 425,
			},
			'.person-auto-complete-dropdown'
		),
		...CssSelectors.allDescendants(
			{
				background: white,
				border: 'none',
				borderRadius: 5,
				minWidth: 300,
			},
			'.autocomplete-search-field-input'
		),
	},
});
