import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	viewBox?: string;
}

export const ExpandIcon: React.FC<IProps> = props => {
	const { className, fillColor, viewBox } = props;
	return (
		<SvgIcon className={`expand-icon ${className || ''}`} height={14} viewBox={viewBox} width={14}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.875 1.75H11.0127L1.75 11.0127V7.875H0V14H6.125V12.25H2.98725L12.25 2.98725V6.125H14V0H7.875V1.75Z'
				fill={fillColor}
			/>
		</SvgIcon>
	);
};
