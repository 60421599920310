import { css } from 'aphrodite';
import { Fragment } from 'react';
import * as React from 'react';
import { Tag } from '../../../../web/components/tags/Tag';
import { LeadRuleViewModel } from '../../../viewModels/leadrules';
import { styleSheet } from '../styles';

interface IProps {
	leadRule: LeadRuleViewModel;
}

export const RuleTags: React.FC<IProps> = ({ leadRule }) => {
	return (
		<div className={css(styleSheet.flexContainer)}>
			<div className={css(styleSheet.tagContainer)}>
				{leadRule.filterToArrays.and.map((andTag, index, array) => {
					const showAnd = !!(
						index < array.length - 1 ||
						leadRule.filterToArrays.or.length ||
						leadRule.filterToArrays.not.length
					);
					return (
						<Fragment key={`${andTag}-${index}`}>
							<Tag tagValue={andTag} className={css(styleSheet.tag)} />
							{showAnd && <span className={css(styleSheet.separator)}>and</span>}
						</Fragment>
					);
				})}
				{leadRule.filterToArrays.or.map((orTag, index, array) => {
					const showOr = !!(index < array.length - 1);
					return (
						<Fragment key={`${orTag}-${index}`}>
							<Tag tagValue={orTag} className={css(styleSheet.tag)} />
							{showOr && <span className={css(styleSheet.separator)}>or</span>}
						</Fragment>
					);
				})}
				{leadRule.filterToArrays.not.map((notTag, index) => {
					return (
						<Fragment key={`${notTag}-${index}`}>
							<span className={css(styleSheet.separator)}>but not</span>
							<Tag tagValue={notTag} className={css(styleSheet.tag, styleSheet.tagNot)} />
						</Fragment>
					);
				})}
				{leadRule.filterToArrays.outcomes?.length >= 1 && (
					<div className={css(styleSheet.outcomeTypes)}>with certain outcome types</div>
				)}
			</div>
		</div>
	);
};
