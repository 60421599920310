import { CssSelectors } from '../../../web/styles/styles';
import { brandPrimaryHover, titles } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { CSSProperties, StyleSheet } from 'aphrodite';

const avatarSize = 60;

export const styleSheet = StyleSheet.create({
	avatarContainer: {
		...aidaBaseStyles.flexCenter,
		...CssSelectors.allDescendants(
			{
				minHeight: avatarSize,
				minWidth: avatarSize,
			},
			'.avatar-content'
		),
	},
	contactContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
	},
	contactInfoContainer: {
		...aidaBaseStyles.flexCol,
		maxWidth: `calc(100% - ${avatarSize}px)`,
		paddingLeft: 15,
		...CssSelectors.allChildren(aidaBaseStyles.truncateText as CSSProperties),
		...CssSelectors.allChildren(
			{
				color: titles,
				fontSize: '16px',
			},
			'.contact-name'
		),
		...CssSelectors.allChildren(
			{
				color: '#858585',
				fontSize: '12px',
			},
			'.contact-job-title'
		),
		...CssSelectors.allChildren(
			{
				color: brandPrimaryHover,
				fontSize: '12px',
			},
			'.contact-email'
		),
	},
	section: {
		maxWidth: '30%',
		minWidth: '30%',
	},
});
