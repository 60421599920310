import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { Button } from '../../../../web/components/Button';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { TextInput } from '../../../../web/components/TextInput';
import { DeprecatedXIcon } from '../../../../web/components/svgs/icons/DeprecatedXIcon';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { useGamification, useToaster } from '../../../hooks';
import { useResponseHandler } from '../../../hooks/error';
import { useInput } from '../../../hooks/input';
import { white } from '../../../styles/colors';
import { MemeViewModel } from '../../../viewModels/memes';
import { TransparentButton } from '../../TransparentButton';
import { EyeIcon } from '../../svgs/icons/EyeIcon';
import { MemeFile } from '../MemeFile';
import { styleSheet } from './styles';

interface IProps {
	meme: MemeViewModel;
	onDeleteClick?(id: string): () => void;
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const Meme: React.FC<IProps> = props => {
	const { className, meme, onDeleteClick, styles = [] } = props;
	const [phrase, , setPhrase] = useInput(meme.phrase);
	const toaster = useToaster();
	const gamification = useGamification();
	const [updatePhraseResponseHandler] = useResponseHandler('UpdatePhrase-Error', "Unable to update meme's text");
	const [updateImageResponseHandler] = useResponseHandler('UpdateImage-Error', "Unable to update meme's image");
	const [updateSoundResponseHandler] = useResponseHandler('UpdateSound-Error', "Unable to update meme's sound");

	const savePhrase = () => {
		meme
			.updatePhrase(phrase)
			// @ts-ignore
			.then(() => toaster.push({ message: 'Text updated', type: 'successMessage' }))
			.catch(updatePhraseResponseHandler);
	};

	const onPreview = () => {
		// @ts-ignore
		gamification.setMeme(meme);
	};

	const onImageSelected = (file: File) => {
		meme
			.updateImage(file)
			// @ts-ignore
			.then(() => toaster.push({ message: 'Image updated', type: 'successMessage' }))
			.catch(updateImageResponseHandler);
	};

	const onSoundSelected = (file: File) => {
		meme
			.updateSound(file)
			// @ts-ignore
			.then(() => toaster.push({ message: 'Sound updated', type: 'successMessage' }))
			.catch(updateSoundResponseHandler);
	};

	if (meme.isBusy) {
		return (
			<div className={`${css(styleSheet.container, ...styles)} meme ${className || ''}`}>
				<div className={css(baseStyleSheet.flexCenter, styleSheet.loader)}>
					<LoadingSpinner type='small' />
				</div>
			</div>
		);
	}

	return (
		<div className={`${css(styleSheet.container, ...styles)} meme ${className || ''}`}>
			<div className={css(styleSheet.xIconContainer)}>
				{/* @ts-ignore */}
				<TransparentButton onClick={onDeleteClick(meme.id)}>
					<DeprecatedXIcon fillColor={white} />
				</TransparentButton>
			</div>
			<div className={css(styleSheet.fileContainer)}>
				<MemeFile meme={meme} label='image:' url={meme.image?.url} onFileSelected={onImageSelected} accept='.png' />
				<MemeFile meme={meme} label='sound:' url={meme.sound?.url} onFileSelected={onSoundSelected} accept='.mp3' />
			</div>
			<div className={css(styleSheet.textContainer)}>
				<div className={css(styleSheet.phraseLabel)}>Text:</div>
				<div>
					<TextInput
						className={css(styleSheet.input)}
						inputId={meme.id}
						type='text'
						value={phrase}
						onChange={setPhrase}
					/>
				</div>
				<div>
					<Button label='Save' size='small' onClick={savePhrase} />
				</div>
				<div>
					<TransparentButton onClick={onPreview}>
						<EyeIcon />
					</TransparentButton>
				</div>
			</div>
		</div>
	);
};
