import { useQueue } from '../../hooks/queue';
import { LeadAttribute } from './LeadAttribute';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	className?: string;
}

const LeadAttributesBase: React.FC<IProps> = ({ className = '' }) => {
	const queue = useQueue();

	const renderAttributes = () => {
		return queue.lead?.attributesForm?.fields?.map(attribute => {
			return <LeadAttribute key={attribute.id} attribute={attribute} />;
		});
	};

	return <div className={`${css(styleSheet.attributesContainer)} ${className}`}>{renderAttributes()}</div>;
};

export const LeadAttributes = observer(LeadAttributesBase);
