import { CssSelectors } from '../../../../web/styles/styles';
import { background, brandPrimaryHover, brandPrimaryLight, error, titles, white } from '../../../styles/colors';
import { aidaBaseStyles } from '../../../styles/styles';
import { CSSProperties, StyleDeclaration, StyleSheet } from 'aphrodite';

const field: CSSProperties | StyleDeclaration = {
	...aidaBaseStyles.flexHorizontalCenter,
	background: '#f9f9f9',
	border: '1px solid #d8d8d8',
	borderRadius: 3,
	minWidth: '100%',
};

export const styleSheet = StyleSheet.create({
	addUserButton: {
		alignItems: 'center',
		color: brandPrimaryHover,
		display: 'flex',
		fontSize: 14,
		justifyContent: 'center',
		width: '100%',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	addUserDropdownFooter: {
		borderTop: '1px #D8D8D8 solid',
		boxSizing: 'border-box',
		padding: '8px 20px',
	},
	calendarIconContainer: {
		...aidaBaseStyles.flexCenter,
		maxWidth: 35,
		minWidth: 35,
	},
	dateFieldContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
	},
	datePickerContainer: {
		border: '1px solid #d8d8d8',
		borderRadius: 3,
		marginBottom: -27,
		padding: 10,
	},
	dateTimePickerContainer: {
		background: white,
	},
	dealFieldContainer: {
		...CssSelectors.allDescendants(
			{
				background,
				border: `1px solid #d8d8d8`,
				borderRadius: 3,
				maxHeight: 200,
				overflowY: 'auto',
				...CssSelectors.allDescendants(
					{
						borderBottom: `1px solid #d8d8d8`,
					},
					'.tox-editor-header'
				),
			},
			'.note-editor-deal-editor'
		),
	},
	dropdown: {
		minWidth: 441,
	},
	dropdownItem: {
		minWidth: '100%',
	},
	error: {
		color: error,
		fontSize: '12px',
	},
	fieldContainer: {
		...field,
		minHeight: 40,
	},
	focus: {
		border: `1px solid ${brandPrimaryLight}`,
	},
	hide: {
		display: 'none',
	},
	label: {
		color: '#858585',
		display: 'flex',
		fontSize: '14px',
		paddingBottom: 5,
		...CssSelectors.allChildren(
			{
				fontSize: '11px',
			},
			'span'
		),
	},
	loading: {
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'flex-start',
		paddingLeft: 20,
	},
	personAutoComplete: {
		...CssSelectors.allDescendants(
			{
				minWidth: '100%',
			},
			'.person-auto-complete-anchor'
		),
		...CssSelectors.allDescendants(
			{
				maxWidth: 325,
			},
			'.person-auto-complete-dropdown'
		),
		...CssSelectors.allDescendants(
			{
				...field,
				minWidth: '100%',
			},
			'.autocomplete-search-field-input'
		),
	},
	select: {
		...field,
		...CssSelectors.allChildren({
			minWidth: '100%',
		}),
	},
	selectedDate: {
		fontSize: '14px',
	},
	selectTriggerContent: {
		color: titles,
		...CssSelectors.allChildren(
			{
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},
			'div'
		),
	},
	selectTriggerNoneSelected: {
		color: '#858585',
	},
	textArea: {
		...field,
		height: 120,
		resize: 'none',
	},
	textInput: {
		...field,
	},
	useManualSchedulerLink: {
		...aidaBaseStyles.brandLink,
		':hover': {
			textDecoration: 'underline',
		},
		cursor: 'pointer',
		fontSize: 14,
	},
});
