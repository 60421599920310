import { TagFontSize, TagHeight } from '../../../../web/components/tags/Tag/styles';
import { tagBackgroundColor, titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	simpleTag: {
		alignItems: 'center',
		background: tagBackgroundColor,
		borderRadius: 30,
		color: titles,
		display: 'flex',
		fontSize: TagFontSize,
		height: TagHeight,
		justifyContent: 'center',
		letterSpacing: '0',
		padding: '0px 14px',
		width: 'fit-content',
	},
});
