import { MrryDirection, MrryMessageType, MrryModal } from '../../components/MrryModal';
import * as SharedAppState from '../../models/AppState';
import { IWebsocketSubscription } from '../../viewModels/websockets';
import { styleSheet } from './styles';
import { IRemoteEvent, TranscriptionStatus } from '@ViewModels';
import { css } from 'aphrodite';
import { createContext, useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router';

export const TranscriptionEventsContext = createContext<{
	eventPayload: IRemoteEvent<ITranscriptionWsEvent>[];
	// @ts-ignore
}>(null);

export interface ITranscriptionWsEvent {
	status: TranscriptionStatus;
	creationContext?: {
		resources?: {
			phoneCall?: string;
		};
	};
}

interface IMrryState {
	content: {
		phoneCallId: string;
		status: string;
	}[];
	open: boolean;
	payload: IRemoteEvent<ITranscriptionWsEvent>[];
}

const mrryInitialState: IMrryState = {
	// @ts-ignore
	content: null,
	open: false,
	// @ts-ignore
	payload: null,
};

export const useTranscriptionWsEvent = (callback: (payload: IRemoteEvent<ITranscriptionWsEvent>[]) => void) => {
	const websockets = SharedAppState.AppState[SharedAppState.WebsocketsViewModelKey];

	useEffect(() => {
		const subscription: IWebsocketSubscription<ITranscriptionWsEvent> = {
			callback,
			events: ['TranscriptionEvent'],
			name: 'TranscriptionEventsProvider',
		};
		// @ts-ignore
		websockets.subscribe(subscription);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return () => {
		// @ts-ignore
		websockets.unsubscribe('TranscriptionEventsProvider');
	};
};

export const TranscriptionEventsProvider: React.FC = ({ children }) => {
	const [showMrry, setMrryState] = useState<IMrryState>(mrryInitialState);

	const history = useHistory();

	const unsubscribe = useTranscriptionWsEvent(payload => {
		setMrryState({
			// @ts-ignore
			content: [...payload].map(item => ({
				// @ts-ignore
				// @ts-ignore
				// @ts-ignore
				phoneCallId: item.value.creationContext.resources.phoneCall,
				// @ts-ignore
				status: item.value.status,
			})),
			open: true,
			payload,
		});
	});
	useEffect(() => {
		return () => {
			unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCloseMrry = () => setMrryState(mrryInitialState);

	const goto = (phoneCallId: string) => () => {
		history.push(`/call-log/${phoneCallId || ''}`);
	};

	return (
		<TranscriptionEventsContext.Provider
			value={{
				eventPayload: showMrry.payload,
			}}
		>
			{showMrry.open && (
				<MrryModal
					fromDirection={MrryDirection.FromRightTop}
					internalCloseButton={true}
					message={{
						content: (
							<small>
								<p className={css(styleSheet.title)}>
									You have {showMrry?.content?.length || 0} transcription(s) finished!
								</p>
								{showMrry?.content?.map((item, index) => {
									const className =
										item.status === TranscriptionStatus.Completed
											? styleSheet.statusCompleted
											: styleSheet.statusFailed;

									return (
										<p
											key={item.phoneCallId}
											className={css(styleSheet.item)}
											onClick={goto(item.phoneCallId)}
											role='button'
										>
											<strong>{index + 1} - </strong>
											<i>
												Status: <span className={css(className)}>{item.status}</span> - Click for more.
											</i>
										</p>
									);
								})}
							</small>
						),
						type: MrryMessageType.Success,
					}}
					onRequestClose={handleCloseMrry}
				/>
			)}

			{children}
		</TranscriptionEventsContext.Provider>
	);
};
