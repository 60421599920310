import { ViewModelTypeAutocomplete } from '../../../../containers/Settings/PhoneNumbers/components/ViewModelTypeAutocomplete';
import { styleSheet } from '../styles';
import { ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import { useState } from 'react';
import * as React from 'react';

interface IProps {
	onChange: (item: string) => void;
}

export const RoleSelect: React.FC<IProps> = ({ onChange }) => {
	const [selectedItem, selectItem] = useState(null);
	const handleSelection = (value: string) => {
		onChange(value);
		// @ts-ignore
		selectItem(value);
	};
	return (
		<ViewModelTypeAutocomplete<string>
			className={css(styleSheet.autocomplete)}
			placeholder={selectedItem ? 'Role: ' + selectedItem : 'Filter by Role'}
			model={ResourceAutoCompleteViewModelType.Role}
			onChange={handleSelection}
		/>
	);
};
