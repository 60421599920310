import { EmailMessageViewModel } from '@ViewModels';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { EmailComposerOptions } from '../../../../web/components/email/EmailComposerOptions';

export interface IProps {
	emailMessage: EmailMessageViewModel<File>;
}

export const ComposerOptions: React.FC<IProps> = observer(({ emailMessage }) => {
	const userSession = useUserSession();

	const handleNotePrivacyToggleChange = (checked: boolean) => {
		if (emailMessage) {
			emailMessage.options.noteVisibility = checked ? 'all' : 'admin';
		}
	};

	const handleSaveAsNoteCheckedChange = (checked: boolean) => {
		if (emailMessage) {
			emailMessage.options.saveAsNote = checked;
		}
	};

	// @ts-ignore
	// @ts-ignore
	const crmEmail = userSession.user.userPreferences.bccEmail;
	return (
		<EmailComposerOptions
			bccToCrm={!!crmEmail && !!emailMessage?.bcc?.find(x => x.email === crmEmail)}
			createFollowUpReminder={false}
			followUpReminderDays={emailMessage?.options?.followUpIfNoResponseInDays || 7}
			hideCcEmployee={true}
			noteVisibility={emailMessage?.options?.noteVisibility}
			hideFollowUpReminderOptions={true}
			onNotePrivacyToggleChange={handleNotePrivacyToggleChange}
			onSaveAsNoteCheckedChange={handleSaveAsNoteCheckedChange}
			saveAsNote={emailMessage?.options?.saveAsNote}
			showBccToCrmOption={!!crmEmail}
		/>
	);
});
