import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const CircleAddIcon: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`circle-add-icon ${className}`} height={16} width={16}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8 14.4A6.407 6.407 0 011.6 8c0-3.529 2.871-6.4 6.4-6.4 3.529 0 6.4 2.871 6.4 6.4 0 3.529-2.871 6.4-6.4 6.4zM8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8z'
				fill='#00AAE8'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.8 4H7.2v3.2H4v1.6h3.2V12h1.6V8.8H12V7.2H8.8V4z'
				fill='#00AAE8'
			/>
		</SvgIcon>
	);
};
