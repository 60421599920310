import { CssSelectors, FontFamilies } from '../../../../web/styles/styles';
import { brandPrimary } from '../../../styles/colors';
import { aidaBaseStyles } from '../../../styles/styles';
import { CSSProperties, StyleSheet } from 'aphrodite';

const eightOfClubs: CSSProperties = {
	left: 0,
	position: 'absolute',
	top: 0,
};

export const styleSheet = StyleSheet.create({
	eightOfClubs: {
		...eightOfClubs,
		backfaceVisibility: 'hidden',
		zIndex: 1,
	},
	eightOfClubsBack: {
		...eightOfClubs,
		...aidaBaseStyles.flexCenter,
		background: brandPrimary,
		borderRadius: 10,
		height: '100%',
		transform: `rotate3d(0, 0, 180deg)`,
		width: '100%',
		...CssSelectors.allChildren(
			{
				...(aidaBaseStyles.flexCenter as CSSProperties),
				color: '#fff',
				fontFamily: FontFamilies.conqueror,
				fontSize: '60px',
				paddingTop: 12,
			},
			'span'
		),
	},
	eightOfClubsContainer: {
		height: 70,
		position: 'relative',
		transformStyle: 'preserve-3d',
		width: 54,
	},
	quickPitchContainer: {},
});
