import { css } from 'aphrodite';
import * as React from 'react';
import { useState } from 'react';
import { IPhoneCallCategory } from '../../../../../extViewmodels';
import { Button } from '../../../../../web/components/Button';
import { TextInput } from '../../../../../web/components/TextInput';
import { DeprecatedXIcon } from '../../../../../web/components/svgs/icons/DeprecatedXIcon';
import { TagIcon } from '../../../../../web/components/svgs/icons/TagIcon';
import { BlueModal } from '../../../../components/BlueModal';
import { Group } from '../../../../components/leadView/components/Group';
import { usePhoneCallCategory } from '../../../../entities/PhoneCall/usePhoneCallCategory';
import { useResponseHandler } from '../../../../hooks/error';
import { hotPink, white } from '../../../../styles/colors';
import { styleSheet } from '../styles';

interface IProps {
	onRequestClose(success: boolean): void;
}

export const CategoryNewModal: React.FC<IProps> = ({ onRequestClose }) => {
	const [saveError] = useResponseHandler('SaveError', "Oh no! I couldn't create this Category.");

	const [categoryName, setCategoryName] = useState('');

	const { create } = usePhoneCallCategory<IPhoneCallCategory>();

	const handleSave = () => {
		create({ hexColor: hotPink, name: categoryName })
			.then(() => {
				onRequestClose(true);
			})
			.catch(saveError);
	};

	const onClickToClose = () => onRequestClose?.(false);

	const handleCategoryName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCategoryName(event?.target?.value);
	};

	return (
		<BlueModal isOpen={true} onRequestClose={onRequestClose} useDefaultHeader={false} className={css(styleSheet.modal)}>
			<div className={css(styleSheet.modalHeader)}>
				<TagIcon fillColor={white} />
				<button onClick={onClickToClose}>
					<DeprecatedXIcon fillColor={white} />
				</button>
			</div>
			<div className={css(styleSheet.modalContent)}>
				<Group>
					<TextInput
						inputId='filter-name-input'
						placeholder='Category Name'
						onChange={handleCategoryName}
						type='text'
						value={categoryName}
					/>
					<Button label='Save' onClick={handleSave} />
				</Group>
			</div>
		</BlueModal>
	);
};
