/**
 * The company filter is similar to what is available for regular Levitate companies. Here, some extra criteria are
 * added, but the same recursive pattern is used to define the filter object
 */

export type ICompanyFilter = IFilterRequest<CompanyFilterCriteriaProperty>;

export enum CompanyFilterCriteriaProperty {
	All = 'All',
	DealOwner = 'DealOwner',
	DoNotCall = 'DoNotCall',
	FollowUpDate = 'FollowUpDate',
	FollowUpOwner = 'FollowUpOwner',
	HasDeals = 'HasDeals',
	LastConfirmationAttemptDate = 'LastConfirmationAttemptDate',
	LastConnectInteractionDate = 'LastConnectInteractionDate',
	LastDealStage = 'LastDealStage',
	LastInteractionDate = 'LastInteractionDate',
	LastInteractionType = 'LastInteractionType',
	LeadIndustry = 'LeadIndustry',
	LeadOwner = 'LeadOwner',
	Name = 'Name',
	Nested = 'Nested',
	NextMeetingDate = 'NextMeetingDate',
	NextMeetingIsActive = 'NextMeetingIsActive',
	NumberOfConfirmationAttempts = 'NumberOfConfirmationAttempts',
	ScheduledFollowUpDateTime = 'ScheduledFollowUpDateTime',
	Tag = 'Tag',
	TimeZone = 'TimeZone',
	WithoutTags = 'WithoutTags',
}

export type IFilterRequest<TProperty> = IFilterRequestBase<TProperty, FilterOperator>;

export interface IFilterRequestBase<TProperty, TFilterOperator> {
	criteria?: IFilterRequestBase<TProperty, TFilterOperator>[];
	op?: TFilterOperator;
	property?: TProperty;
	value?: string;
}

export enum FilterOperator {
	And = 'And',
	Or = 'Or',
	Not = 'Not',
}
