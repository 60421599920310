import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const GhostsGraphic: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`ghosts-graphic ${className}`} height={222} width={367}>
			<ellipse rx='3' ry='8' transform='matrix(-1 0 0 1 32 36)' fill='#00AAE8' stroke='white' strokeWidth='2' />
			<path fillRule='evenodd' clipRule='evenodd' d='M61 21L32 28V44L61 50V21Z' fill='#00AAE8' />
			<ellipse
				rx='3.5'
				ry='14.5'
				transform='matrix(-1 0 0 1 61.5 35.5)'
				fill='#046CB6'
				stroke='white'
				strokeWidth='2'
			/>
			<ellipse cx='297.5' cy='74.5' rx='3.5' ry='14.5' fill='#00AAE8' stroke='white' strokeWidth='2' />
			<path fillRule='evenodd' clipRule='evenodd' d='M298 60L327 67V83L298 89V60Z' fill='#00AAE8' />
			<ellipse cx='327' cy='75' rx='3' ry='8' fill='#00528C' stroke='white' strokeWidth='2' />
			<circle cx='243' cy='65' r='44' fill='white' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M198.755 65H287C287 65 282.998 113.656 282.125 127.704C279.687 166.894 259.21 185.02 246.046 181.591C232.883 178.161 233.37 165.915 233.37 165.915C233.37 165.915 228.007 181.101 215.331 178.651C202.655 176.202 203.143 162.975 203.143 162.975C203.143 162.975 198.755 175.222 186.079 175.222C173.402 175.222 173.402 164.935 174.377 158.566C175.353 152.198 187.541 144.85 191.929 122.316C196.317 99.7812 198.755 65 198.755 65Z'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M252.997 80.0002H234.003C234.001 80.0833 234 80.1666 234 80.2502C234 85.6349 238.253 90 243.5 90C248.747 90 253 85.6349 253 80.2502C253 80.1666 252.999 80.0833 252.997 80.0002Z'
				fill='#00528C'
			/>
			<ellipse cx='229' cy='63' rx='5' ry='8' fill='#00528C' />
			<ellipse cx='256' cy='63' rx='5' ry='8' fill='#00528C' />
			<circle cx='109.5' cy='36.5' r='36.5' fill='white' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M73.3733 36H146C146 36 146.719 73.4735 146 85C143.994 117.156 128.834 132.814 118 130C107.166 127.186 102 116 102 116C102 116 97.4484 131.262 87.0159 129.252C76.5833 127.243 78 113 78 113C78 113 65 128 55 123C45 118 64.1445 101.518 67.7558 83.0281C71.367 64.5384 73.3733 36 73.3733 36Z'
				fill='white'
			/>
			<ellipse cx='98' cy='34.5' rx='4' ry='6.5' fill='#00528C' />
			<ellipse cx='121' cy='34.5' rx='4' ry='6.5' fill='#00528C' />
			<circle cx='111' cy='55' r='5' fill='#00528C' />
			<path
				d='M29 36C29 36 -36.1225 153.43 46 164C75.0113 167.734 104.543 145.624 125 159C151 176 119.94 226.782 261 220C311.544 217.57 366 147 366 112C366 77 327 75 327 75'
				stroke='white'
				fill='transparent'
			/>
		</SvgIcon>
	);
};
