import * as Api from '@ViewModels';
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { orderBy, orderByDescending } from '../../models/UiUtils';
import { CompanyFilterCriteriaProperty, FilterOperator, ICompanyFilter, IFilterRequestBase } from './companyfilter';

export interface IValueRange<T> {
	min?: T;
	max?: T;
}

export enum OwnershipEnforcement {
	LeadOwner = 'LeadOwner',
	LeadVertical = 'LeadVertical',
}

export enum TurnItOnForOptions {
	Everyone = 'Everyone',
	Individual = 'Individual',
	Vertical = 'Vertical',
}

export enum TurnThisOnOptions {
	RightAway = 'RightAway',
	Scheduled = 'Scheduled',
}

export enum RecurrenceType {
	AlwaysOn = 'AlwaysOn',
	SingleInterval = 'SingleInterval',
	Weekly = 'Weekly',
}

export interface IInterval {
	startMinutes?: number;
	endMinutes?: number;
}

export interface IWeeklySchedule {
	intervals: IInterval[];
}

export interface ILeadRule extends Api.IBaseResourceModel {
	_type?: Api.LeadRuleType;
	dailyLimit?: number;
	extendedConstraintsEnabled?: boolean;
	filter?: ICompanyFilter;
	goldenHour?: boolean;
	hourlyLimit?: number;
	identifier?: string;
	isEnabled?: boolean;
	minDaysSinceLastInteraction?: number;
	name?: string;
	ownershipEnforcement?: OwnershipEnforcement;
	priority?: number;
	recurrence?: RecurrenceType;
	schedule?: IValueRange<string>;
	users?: Api.IUserReference[];
	verticals?: string[];
	weeklySchedule?: IWeeklySchedule[];
	weight?: number;
}

export const getPropertyLevelFilters = <TProperty extends CompanyFilterCriteriaProperty, TFilterOperator>(
	filter: IFilterRequestBase<TProperty, TFilterOperator>
) => {
	let properties: IFilterRequestBase<TProperty, TFilterOperator>[] = [];
	if (!filter?.criteria?.length) {
		return properties;
	}

	filter.criteria.forEach(criteria => {
		if (criteria.property === CompanyFilterCriteriaProperty.Nested) {
			// @ts-ignore
			criteria.criteria.forEach(nestedCriteria => {
				const nested = getPropertyLevelFilters(nestedCriteria);
				properties = properties.concat(nested);
			});
		} else {
			properties.push(criteria);
		}
	});
	return properties;
};

export class LeadRuleViewModel extends Api.ViewModel implements ILeadRule {
	@observable private leadRule: ILeadRule;

	constructor(userSession: Api.UserSessionContext, rule: ILeadRule) {
		super(userSession);
		this.userSession = userSession;
		this.leadRule = rule;
	}

	@computed public get getLeadRule() {
		return this.leadRule;
	}
	@computed public get id() {
		return this.leadRule?.id;
	}
	@computed public get name() {
		return this.leadRule?.name;
	}
	@computed public get lastModifiedDate() {
		return this.leadRule?.lastModifiedDate;
	}
	@computed public get identifier() {
		return this.leadRule?.identifier;
	}
	@computed public get isEnabled() {
		return this.leadRule?.isEnabled;
	}
	@action public setEnabled(enabled: boolean) {
		this.leadRule.isEnabled = enabled;
	}
	@computed public get filter() {
		return this.leadRule?.filter;
	}
	@computed public get users() {
		return this.leadRule?.users ?? [];
	}
	@action public setUsers(users: Api.IUserReference[]) {
		this.leadRule = { ...this.leadRule, users, verticals: [] };
	}

	@computed public get verticals() {
		return this.leadRule?.verticals ?? [];
	}
	@action public setVerticals(verticals: string[]) {
		this.leadRule = { ...this.leadRule, users: [], verticals };
	}
	@computed public get priority() {
		return this.leadRule?.priority;
	}
	@computed public get weight() {
		return this.leadRule?.weight;
	}
	@computed public get tag() {
		return this.filter?.criteria?.find(x => x.property === CompanyFilterCriteriaProperty.Tag)?.value;
	}
	@computed public get minDaysSinceLastInteraction() {
		return this.leadRule?.minDaysSinceLastInteraction;
	}
	@action public setMinDaysSinceLastInteraction(days: number) {
		this.leadRule = { ...this.leadRule, minDaysSinceLastInteraction: days };
	}

	@computed public get goldenHour() {
		return this.leadRule?.goldenHour;
	}
	@action public setGoldenHour(enabled: boolean) {
		this.leadRule = { ...this.leadRule, goldenHour: enabled };
	}

	@computed public get recurrence() {
		return this.leadRule?.recurrence;
	}
	@computed public get weeklySchedule() {
		return this.leadRule?.weeklySchedule;
	}

	@computed public get schedule() {
		return this.leadRule?.schedule;
	}

	@computed public get showSchedule() {
		return this.leadRule?.schedule && this.leadRule?.schedule?.max;
	}
	@computed public get isExpired() {
		return this.leadRule?.schedule && moment() > moment(this.leadRule?.schedule?.max);
	}
	@computed public get scheduleMin() {
		return moment(this.leadRule?.schedule?.min).format('h:mm a');
	}
	@computed public get scheduleMax() {
		return moment(this.leadRule?.schedule?.max).format('h:mm a');
	}
	@action public setSchedule(hrs: number | null) {
		if (!hrs) {
			// @ts-ignore
			this.leadRule.schedule = null;
			return;
		}
		this.leadRule.schedule = {
			max: moment().add(hrs, 'hours').toISOString(),
			min: moment().toISOString(),
		};
	}

	@action public setRecurrence(recurrence: RecurrenceType) {
		this.leadRule.recurrence = recurrence;
	}

	@action public setWeeklySchedule(weeklySchedule: IWeeklySchedule[]) {
		this.leadRule.weeklySchedule = weeklySchedule;
	}

	@action public setDailyLimit(value: number) {
		this.leadRule.dailyLimit = value;
	}

	@action public setHourlyLimit(value: number) {
		this.leadRule.hourlyLimit = value;
	}

	@computed public get dailyLimit() {
		return this.leadRule?.dailyLimit;
	}

	@computed public get hourlyLimit() {
		return this.leadRule?.hourlyLimit;
	}

	@computed public get isActive() {
		if (!this.isEnabled) {
			return false;
		}

		if (this.schedule == null) {
			return true;
		}

		const now = new Date();
		// @ts-ignore
		// @ts-ignore
		return new Date(this.schedule.min) < now && now < new Date(this.schedule.max);
	}

	@computed public get ownershipEnforcement() {
		return this.leadRule?.ownershipEnforcement;
	}
	@action public setOwnershipEnforcement(enforcement: OwnershipEnforcement) {
		this.leadRule.ownershipEnforcement = enforcement;
	}

	@action
	public setName = (name: string) => {
		this.leadRule.name = name;
	};

	@action
	public setIdentifier = (value: string) => {
		this.leadRule.identifier = value;
	};

	@action
	public setPriority = (priority: number) => {
		this.leadRule.priority = priority;
	};

	@computed
	public get type() {
		return this.leadRule?._type;
	}

	@computed
	public get isCustomRule() {
		// @ts-ignore
		const nested = getPropertyLevelFilters(this.filter);
		if (nested.some(x => x.property !== CompanyFilterCriteriaProperty.Tag)) {
			return true;
		}
		return false;
	}

	@computed
	public get extendedConstraintsEnabled() {
		return this.leadRule?.extendedConstraintsEnabled;
	}

	@computed public get filterToArrays(): {
		and: string[];
		or: string[];
		not: string[];
		outcomes: string[];
	} {
		const filter = this.filter?.criteria;

		const and =
			filter
				?.find(x => {
					return (
						x.op === FilterOperator.And &&
						x.criteria?.[0].property !== CompanyFilterCriteriaProperty.LastInteractionType
					);
				})
				?.criteria?.map(x => x.value) ?? [];

		const or =
			filter
				?.find(x => {
					return (
						x.op === FilterOperator.Or && x.criteria?.[0].property !== CompanyFilterCriteriaProperty.LastInteractionType
					);
				})
				?.criteria?.map(x => x.value) ?? [];

		// The NOTs must be nested inside an OR array to behave intuitively
		const not =
			filter
				?.find(x => {
					return (
						x.op === FilterOperator.Not &&
						x.criteria?.[0].property !== CompanyFilterCriteriaProperty.LastInteractionType
					);
				})
				?.criteria?.[0]?.criteria?.map(x => x.value) ?? [];

		const outcomesList = filter?.find(
			x => x.criteria?.[0].property === CompanyFilterCriteriaProperty.LastInteractionType
		);
		const outcomes = outcomesList?.criteria?.map(x => x.value) || [];

		// @ts-ignore
		// @ts-ignore
		// @ts-ignore
		// @ts-ignore
		return { and, not, or, outcomes };
	}

	@action
	public setFilterFromArrays = (and: string[], or: string[], not: string[], outcomes: string[]) => {
		const filter: ICompanyFilter = {
			// @ts-ignore
			criteria: [
				{
					criteria: or.map(x => ({
						property: CompanyFilterCriteriaProperty.Tag,
						value: x,
					})),
					op: FilterOperator.Or,
					property: CompanyFilterCriteriaProperty.Nested,
				},
				{
					criteria: and.map(x => ({
						property: CompanyFilterCriteriaProperty.Tag,
						value: x,
					})),
					op: FilterOperator.And,
					property: CompanyFilterCriteriaProperty.Nested,
				},

				not.length
					? {
							criteria: [
								{
									criteria: not.map(x => ({
										property: CompanyFilterCriteriaProperty.Tag,
										value: x,
									})),
									op: FilterOperator.Or,
									property: CompanyFilterCriteriaProperty.Nested,
								},
							],
							op: FilterOperator.Not,
							property: CompanyFilterCriteriaProperty.Nested,
						}
					: undefined,
				{
					criteria: outcomes.map(x => ({
						property: CompanyFilterCriteriaProperty.LastInteractionType,
						value: x,
					})),
					op: FilterOperator.Or,
					property: CompanyFilterCriteriaProperty.Nested,
				},
			]
				.filter(x => !!x)
				// @ts-ignore
				.filter(x => x.criteria.length),
			op: FilterOperator.And,
			property: CompanyFilterCriteriaProperty.Nested,
		};
		this.leadRule = { ...this.leadRule, filter };
		return filter;
	};

	@computed public get saved() {
		return !!this.leadRule?.id;
	}
	@computed public get currentType(): TurnItOnForOptions {
		return this.users.length
			? TurnItOnForOptions.Individual
			: this.verticals.length
				? TurnItOnForOptions.Vertical
				: TurnItOnForOptions.Everyone;
	}

	@action
	public load = async () => {
		if (!this.id || this.isBusy) {
			return false;
		}
		this.loading = true;
		const rule = await this.userSession.webServiceHelper.callWebServiceAsync<ILeadRule>(
			this.composeApiUrl({ urlPath: `aida/rule/${this.id}` }),
			'GET'
		);
		if (rule.success) {
			// @ts-ignore
			this.leadRule = rule.value;
		}
		this.loading = false;
		this.loaded = true;
		if (!rule.success) {
			throw Api.asApiError(rule);
		}

		return rule;
	};

	@action
	public save = async () => {
		if (this.isBusy) {
			return;
		}
		this.busy = true;
		const savedRule = await this.userSession.webServiceHelper.callWebServiceAsync<ILeadRule>(
			this.composeApiUrl({ urlPath: 'aida/rule/v2' }),
			'POST',
			this.leadRule
		);
		if (savedRule.success) {
			// @ts-ignore
			this.leadRule = savedRule.value;
		}
		this.busy = false;
		if (!savedRule.success) {
			throw Api.asApiError(savedRule);
		}

		return savedRule;
	};

	@action
	public delete = async () => {
		if (!this.id || this.isBusy) {
			return false;
		}
		this.busy = true;
		const deletedRule = await this.userSession.webServiceHelper.callWebServiceAsync(
			this.composeApiUrl({ urlPath: `aida/rule/${this.id}` }),
			'DELETE'
		);
		if (deletedRule.success) {
			// @ts-ignore
			this.leadRule = null;
		}
		this.busy = false;

		if (!deletedRule.success) {
			throw Api.asApiError(deletedRule);
		}

		return deletedRule;
	};

	@action
	public update = async () => {
		if (!this.id || this.isBusy) {
			return false;
		}
		this.busy = true;
		const updatedRule = await this.userSession.webServiceHelper.callWebServiceAsync<ILeadRule>(
			this.composeApiUrl({ urlPath: `aida/rule/${this.id}/v2` }),
			'PUT',
			this.leadRule
		);
		if (updatedRule.success) {
			// @ts-ignore
			this.leadRule = updatedRule.value;
		}
		this.busy = false;

		if (!updatedRule.success) {
			throw Api.asApiError(updatedRule);
		}

		return updatedRule;
	};

	@action
	public updateSchedule = async () => {
		if (!this.id || this.isBusy) {
			return false;
		}
		this.busy = true;
		const updatedRule = await this.userSession.webServiceHelper.callWebServiceAsync<ILeadRule>(
			this.composeApiUrl({ urlPath: `aida/rule/${this.id}/schedule` }),
			'PUT',
			{
				recurrence: this.leadRule.recurrence,
				schedule: this.leadRule.schedule,
				weeklySchedule: this.leadRule.weeklySchedule,
			}
		);
		if (updatedRule.success) {
			// @ts-ignore
			this.leadRule = updatedRule.value;
		}
		this.busy = false;

		if (!updatedRule.success) {
			throw Api.asApiError(updatedRule);
		}

		return updatedRule;
	};

	@action
	public setLeadRule = (leadRule: ILeadRule) => {
		this.leadRule = leadRule;
	};
}

/**
 * The lead rule view models represent the rules (aka blitzes, filters) which define the order in which lead searches
 * are executed. These view models are used to manage the properties, but the evaluation of rules is performed on the
 * server.
 */
export class LeadRulesViewModel extends Api.ViewModel {
	@observable public rules: Api.BaseObservablePageCollectionController<ILeadRule, LeadRuleViewModel>;
	constructor(userSession: Api.UserSessionContext) {
		super(userSession);
		this.rules = new Api.BaseObservablePageCollectionController<ILeadRule, LeadRuleViewModel>({
			apiPath: this.composeApiUrl({ urlPath: 'aida/rule' }),
			client: this.userSession.webServiceHelper,
			httpMethod: 'GET',
			transformer: x => new LeadRuleViewModel(this.userSession, x),
		});
	}

	@action
	public reset = () => {
		this.rules.reset();
	};

	/**
	 * The sort logic is copied _exactly_ from the server. The paging is not normal because the sort has to be performed
	 * on properties that are not indexed on the server. In the case of removing or editing a rule, this sort can be
	 * performed rather than reloading the entire list from the server
	 */
	@action
	public sort = () => {
		this.rules.fetchResults.sort(
			(a, b) =>
				orderByDescending(a.isActive, b.isActive) ||
				// @ts-ignore
				orderByDescending(a.isEnabled, b.isEnabled) ||
				// @ts-ignore
				orderBy(a.priority, b.priority) ||
				// @ts-ignore
				orderBy(a.name, b.name)
		);
	};

	@action
	public load = async () => {
		this.loading = true;
		try {
			await this.rules.getNext(null, 500);
		} catch (error) {
			throw Api.asApiError(error);
		}
		this.loading = false;
		this.loaded = true;
		return this.rules;
	};
}
