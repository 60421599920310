import { OutcomeInfo } from '../../leadView/components/OutcomeInfo';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	callDate: string;
	callStatus: string;
	sdrName: string;
	sdrPhoneNumber: string;
}

export const CallDetailsCard: React.FC<IProps> = ({ callDate, callStatus, sdrName, sdrPhoneNumber }) => {
	return (
		<div className={css(styleSheet.callActionContainer)}>
			<div className={css(styleSheet.left)}>
				<div className={css(styleSheet.triggerContainer)}>
					<span className={css(styleSheet.label)}>Last call by {sdrName}</span>
					<span>{sdrPhoneNumber}</span>
				</div>
			</div>
			<div className={css(styleSheet.right)}>
				<OutcomeInfo name={callStatus} date={callDate} />
			</div>
		</div>
	);
};
