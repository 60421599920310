import { Group } from '../../leadView/components/Group';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	label: string;
	title: string;
}

export const RuleRegularLabel: React.FC<IProps> = ({ label, title }) => {
	return (
		<Group fullWidth={false} spacingSize={5}>
			<p className={css(styleSheet.infoTitle)}>{title}</p>
			<span>{label}</span>
		</Group>
	);
};
