import { StyleSheet } from 'aphrodite';
import { CssSelectors, FontFamilies } from '../../../web/styles/styles';
import { brandPrimaryDark, brandPrimaryLight, success, white } from '../../styles/colors';

export const styleSheet = StyleSheet.create({
	barGreen: {
		background: 'linear-gradient(to right, #4A8A96, #89C947)',
	},
	barRed: {
		background: 'linear-gradient(to right, #C30DAF, #FF7892)',
	},
	barYellow: {
		background: 'linear-gradient(to right, #BC743D, #FFE94F)',
	},
	marker: {
		background: success,
		height: 24,
		left: 6,
		position: 'absolute',
		top: -3,
		transition: 'left 0.3s',
		width: 1,
	},
	markerSmall: {
		background: success,
		height: 20,
		left: 6,
		position: 'absolute',
		top: -3,
		transition: 'left 0.3s',
		width: 1,
	},
	progress: {
		borderRadius: 10,
		height: 18,
		minWidth: 18,
		transition: 'width 0.3s',
		width: 0,
	},
	progressBar: {
		background: brandPrimaryDark,
		borderRadius: 10,
		height: 18,
		position: 'relative',
		width: '100%',
	},
	progressBarContainer: {
		alignItems: 'center',
		display: 'flex',
		flex: 1,
		maxWidth: 310,
		position: 'relative',
	},
	progressBarDescription: {
		fontFamily: FontFamilies.conqueror,
		fontSize: 20,
		marginRight: 10,
		marginTop: 2,
		whiteSpace: 'nowrap',
		...CssSelectors.allChildren(
			{
				display: 'inline-block',
				paddingRight: 5,
			},
			'span:first-child'
		),
	},
	progressBarDescriptionSmall: {
		fontSize: 16,
		marginRight: 6,
	},
	progressBarGoals: {
		color: brandPrimaryLight,
	},
	progressBarPoints: {
		color: white,
	},
	progressBarSmall: {
		height: 14,
	},
	progressSmall: {
		height: 14,
		minWidth: 14,
	},
});
