import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const SailboatWaterGraphic: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`sailboat-water-graphic ${className}`} height={53} width={101}>
			<path fillRule='evenodd' clipRule='evenodd' d='M0 24L50 53L101 24V0L0 1L0 24Z' fill='#00528C' />
		</SvgIcon>
	);
};
