import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	amount: number;
	goal: number;
	hideValues?: boolean;
	isSmall?: boolean;
	projected?: number;
}

export const ProgressBar: React.FC<IProps> = ({ amount = 0, goal = 0, hideValues, isSmall, projected = 0 }) => {
	const goalPercentage = Math.min((amount * 100) / goal, 100);
	const projectedPercentage = Math.min((projected * 100) / goal, 100);

	const isGreen = goalPercentage >= 90;
	const isYellow = goalPercentage >= 70;

	const barColor = isGreen ? styleSheet.barGreen : isYellow ? styleSheet.barYellow : styleSheet.barRed;

	const progressBarDescriptionSmall = isSmall ? styleSheet.progressBarDescription : null;

	const progressBarSmall = isSmall ? styleSheet.progressBarSmall : null;

	const progressSmall = isSmall ? styleSheet.progressSmall : null;

	const markerSmall = isSmall ? styleSheet.markerSmall : null;

	return (
		<div className={css(styleSheet.progressBarContainer)}>
			{!hideValues && (
				<div className={css(styleSheet.progressBarDescription, progressBarDescriptionSmall)}>
					<span className={css(styleSheet.progressBarPoints)}>{amount || '0'}</span>
					<span className={css(styleSheet.progressBarGoals)}>/&nbsp;{goal || '0'}</span>
				</div>
			)}
			<div className={css(styleSheet.progressBar, progressBarSmall)}>
				<div className={css(styleSheet.progress, progressSmall, barColor)} style={{ width: `${goalPercentage}%` }} />
				{projected > 0 && (
					<div className={css(styleSheet.marker, markerSmall)} style={{ left: `${projectedPercentage}%` }} />
				)}
			</div>
		</div>
	);
};
