import { getDisplayName } from '../../../extViewmodels/Utils';
import { useModal } from '../../../models/hooks/useModal';
import { DefaultDeleteConfirmationOptions } from '../../../web/components/ConfirmationDialog';
import { Toggle } from '../../../web/components/Toggle';
import { WarningIcon } from '../../../web/components/svgs/icons/WarningIcon';
import blitzPlay from '../../assets/blitzPlay.png';
import { useResponseHandler } from '../../hooks/error';
import { grayIconFill } from '../../styles/colors';
import { LeadRuleViewModel, LeadRulesViewModel } from '../../viewModels/leadrules';
import { BlueConfirmationDialog } from '../BlueConfirmationDialog';
import { ownershipLabels } from '../LeadRuleModal/utils';
import { TransparentButton } from '../TransparentButton';
import { Fill } from '../leadView/components/Fill';
import { Group } from '../leadView/components/Group';
import { RuleName } from './components/RuleName';
import { RuleOptions } from './components/RuleOptions';
import { RulePriority } from './components/RulePriority';
import { RuleRegularLabel } from './components/RuleRegularLabel';
import { RuleSchedule } from './components/RuleSchedule';
import { RuleTags } from './components/RuleTags';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	isPlayable?: boolean;
	isReadonly?: boolean;
	leadRule: LeadRuleViewModel;
	leadRules?: LeadRulesViewModel | LeadRuleViewModel[];
	onClickToClone?(): void;
	onClickToDelete?(): void;
	onClickToEdit?(): void;
	onClickToEditSchedule?(): void;
	onClickToPlayBlitz?(): void;
}

export const LeadRule: React.FC<IProps> = observer(
	({
		isPlayable,
		isReadonly,
		leadRule,
		leadRules,
		onClickToClone,
		onClickToDelete,
		onClickToEdit,
		onClickToEditSchedule,
		onClickToPlayBlitz,
	}) => {
		const [leadUpdateError] = useResponseHandler('LeadUpdateEnablement', "I couldn't update the lead filter!");
		const deleteConfirmationModal = useModal(
			false,
			() => {
				onClickToDelete?.();
			},
			[onClickToDelete]
		);

		const toggle = () => {
			leadRule.setEnabled(!leadRule.isEnabled);
			leadRule
				.update()
				.catch(leadUpdateError)
				// @ts-ignore
				.finally(() => leadRules.sort());
		};

		if (!leadRule) {
			return null;
		}

		const onForText = () => {
			if (leadRule?.users?.length > 2) {
				return (
					leadRule.users
						.slice(0, 1)
						.map(x => getDisplayName(x))
						.join(', ') + ` and ${leadRule.users.length - 1} others`
				);
			} else if (leadRule?.users?.length) {
				return leadRule.users.map(x => getDisplayName(x)).join(', ');
			} else if (leadRule?.verticals?.length) {
				return leadRule.verticals.join(', ');
			} else {
				return 'Everyone';
			}
		};

		// @ts-ignore
		const ownership = ownershipLabels[leadRule?.ownershipEnforcement] || 'None';

		const handleDelete = () => {
			deleteConfirmationModal.setIsOpen(true)();
		};

		const handleEdit = () => {
			onClickToEdit?.();
		};

		const handleEditSchedule = () => {
			onClickToEditSchedule?.();
		};

		const handleClone = () => {
			onClickToClone?.();
		};

		const isAlwaysOn = leadRule?.recurrence === 'AlwaysOn';

		const andTags = leadRule?.filterToArrays?.and;
		const orTags = leadRule?.filterToArrays?.or;
		const notTags = leadRule?.filterToArrays?.not;

		const outcomes = leadRule?.filterToArrays?.outcomes;

		const hasTags = andTags?.length > 0 || orTags?.length > 0 || notTags?.length > 0 || outcomes?.length > 0;

		const renderCustomRuleDisable = <div className={css(styleSheet.customRule)}>Custom Rule - Editing Disabled</div>;

		return (
			<div className={`${css(styleSheet.container, styleSheet.flexContainer)} lead-rule`}>
				{leadRule.isCustomRule && renderCustomRuleDisable}
				{/* @ts-ignore */}
				<RulePriority value={leadRule.priority} />
				<Group spacingSize={20}>
					<Group fullWidth={false}>
						{!isReadonly && !leadRule.isCustomRule && !isPlayable ? (
							<Toggle
								disabled={isReadonly}
								// @ts-ignore
								id={leadRule.id}
								isOn={leadRule.isEnabled}
								onToggleCheckChanged={toggle}
								uncheckedColor={grayIconFill}
							/>
						) : null}
					</Group>
					<Group vertical={true} spacingSize={15}>
						<Group spacingSize={20}>
							{/* @ts-ignore */}
							<RuleName name={leadRule.name} />
							<Fill />
							<RuleRegularLabel title='For:' label={onForText()} />
							<RuleRegularLabel title='Ownership:' label={ownership} />
							<div />
							{!leadRule.isCustomRule && !isPlayable ? (
								<RuleOptions
									// @ts-ignore
									onClickToClone={!isReadonly ? handleClone : null}
									// @ts-ignore
									onClickToDelete={!isReadonly ? handleDelete : null}
									// @ts-ignore
									onClickToEdit={!isReadonly ? handleEdit : null}
									onClickToEditSchedule={handleEditSchedule}
								/>
							) : null}
						</Group>
						{!isReadonly && hasTags ? <RuleTags leadRule={leadRule} /> : null}
						{!isAlwaysOn && (
							<Group>
								<RuleSchedule leadRule={leadRule} />
							</Group>
						)}
					</Group>
					{isPlayable && (
						<TransparentButton className={css(styleSheet.blitzPlayButton)} onClick={onClickToPlayBlitz}>
							<img src={blitzPlay} alt='Play Blitz' />
						</TransparentButton>
					)}
				</Group>
				<BlueConfirmationDialog
					icon={<WarningIcon />}
					modalProps={deleteConfirmationModal}
					options={DefaultDeleteConfirmationOptions}
					title='Delete Blitz?'
				/>
			</div>
		);
	}
);
