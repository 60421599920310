import { useRef } from 'react';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { IPhoneCallLogView } from '../../viewModels/leads/interfaces';
import { DemosToConfirmViewModel, DemosViewModel } from '../../viewModels/leads/views';
import { useStatesOnFetch } from '../utils/useStatesOnFetch';

export interface IDemosFilterParams {
	dealStage?: string | string[];
	demoPerformerUserId?: string;
	end?: string;
	outcomeName?: string;
	start?: string;
	userId?: string;
}

interface IParams {
	filter: IDemosFilterParams;
	pageSize?: number;
	shouldReset?: boolean;
	shouldNotTriggerLoading?: boolean;
}

export const useDemos = () => {
	const userSession = useUserSession();

	const demosToConfirmApiCall = useRef(new DemosToConfirmViewModel(userSession)).current;
	const demosApiCall = useRef(new DemosViewModel(userSession)).current;

	const { state, handleWorking, handleResponse, reset } = useStatesOnFetch<IPhoneCallLogView[]>();

	const get = async ({ filter, pageSize = 25, shouldReset, shouldNotTriggerLoading }: IParams) => {
		let apiToUse: DemosViewModel | DemosToConfirmViewModel = demosToConfirmApiCall;
		if (filter.dealStage) {
			apiToUse = demosApiCall;
		}
		if (shouldReset) {
			apiToUse.controller.reset();
		}
		handleWorking(shouldNotTriggerLoading);
		try {
			await apiToUse.controller.getNext(null, pageSize, filter);
			return handleResponse({ values: apiToUse.controller.fetchResults });
		} catch (error) {
			// @ts-ignore
			throw new Error(error);
		}
	};

	return {
		...state,
		empty: !state?.data || state?.data?.length <= 0,
		get,
		reset,
		status: state?.data ? 'done' : state.status,
	};
};
