import { CssSelectors } from '../../../web/styles/styles';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	attributesContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		flexWrap: 'wrap',
		padding: '10px 0',
		...CssSelectors.allChildren({
			marginRight: 8,
		}),
	},
	triggerContainer: {
		height: 'unset',
		padding: 0,
	},
});
