import { CssSelectors } from '../../../web/styles/styles';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	ctaContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'flex-end',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':last-child:not(:first-child)'
		),
	},
	iconContainer: {
		textAlign: 'center',
		width: 30,
	},
	inputsContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		paddingBottom: 10,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
				maxWidth: 130,
				minWidth: 130,
			},
			':last-child'
		),
		...CssSelectors.allChildren(
			{
				flexGrow: 1,
			},
			':first-child'
		),
	},
	phoneNumberEditorContainer: {
		...aidaBaseStyles.flexCol,
	},
	save: {
		':disabled': {
			opacity: 0.5,
		},
	},
	select: {
		background: '#F9F9F9',
		border: '1px solid #ccc',
	},
});
