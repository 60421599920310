import { StyleSheet } from 'aphrodite';
import { brandPrimaryHover, brandSecondary } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	column: {
		color: '#67D0F6',
		fontSize: 11,
		letterSpacing: 1.1,
		textTransform: 'uppercase',
	},
	container: {
		alignItems: 'center',
		background: brandPrimaryHover,
		borderBottom: `1px solid ${brandSecondary}`,
		boxShadow: '0 20px 20px -20px rgba(0, 0, 0, 0.1)',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '22px 0',
		position: 'sticky',
		top: 0,
		width: '100%',
		zIndex: 1,
	},
	leadTableHeaderSelect: {
		width: 30,
	},
});
