import {
	BaseObservablePageCollectionController,
	IBaseResourceModel,
	IDictionary,
	IUser,
	PagedViewModel,
	UserSessionContext,
	asApiError,
} from '@ViewModels';
import { action, computed, observable } from 'mobx';

interface IUserDailyActivitySummaryRequest {
	userId?: string;
	startDate?: string;
	endDate?: string;
	team?: string;
}

interface IScore {
	dials?: number;
	connections?: number;
	demos?: number;
	points?: number;
}

export interface IGoals extends IScore {
	maxColdDemos?: number;
	maxFollowUps?: number;
	maxResearch?: number;
	maxOldies?: number;
	adjustedDials?: number;
	adjustedDemos?: number;
}

interface IVertical {
	industry?: string;
	subverticals?: string[];
}

interface IUserDailyActivity {
	/** Formatted like "yyyy-MM-dd" Example: 2021-09-10 */
	day?: string;
	score?: IScore;
	projectedScore?: IScore;
	goals?: IGoals;
	lastLeadServedId?: string;
	points?: number;
	activities?: IDictionary<number>;
	vertical?: IVertical;
	role?: string;
	teams?: string[];
	managerUserId?: string;
}

/**
 * All outcomes and skips are tracked per user per day and are available in the database. Other information about the
 * user, like what vertical they are on and who is their manager are also available. Score for the user is tracked here,
 * and how much progress they have made towards their goal in a given day.
 */
export class UserDailyActivity implements IUserDailyActivity, IBaseResourceModel {
	@computed public get id() {
		return `${this.day}`;
	}
	@observable public day?: string;
	@observable public score?: IScore;
	@observable public projectedScore?: IScore;
	@observable public goals?: IGoals;
	@observable public lastLeadServedId?: string;
	@observable public points?: number;
	@observable public activities?: IDictionary<number>;
	@observable public vertical?: IVertical;
	@observable public role?: string;
	@observable public teams?: string[];
	@observable public managerUserId?: string;
	@observable public creationDate?: string;
	@observable public creator?: Partial<IUser>;
	@observable public lastModifiedDate?: string;
}

export class GoalsViewModel extends PagedViewModel<IUserDailyActivity, UserDailyActivity> {
	protected mPagedCollectionController: BaseObservablePageCollectionController<IUserDailyActivity, UserDailyActivity>;
	@observable public startDate: Date = new Date();
	@observable public endDate: Date = new Date();
	// @ts-ignore
	@observable public user: Partial<IUser>;
	// @ts-ignore
	@observable public pageSize: number;

	constructor(userSession: UserSessionContext) {
		super(userSession);
		this.mPagedCollectionController = new BaseObservablePageCollectionController<IUserDailyActivity, UserDailyActivity>(
			{
				apiPath: this.composeApiUrl({ urlPath: 'userDailyActivity/summary' }),
				client: this.userSession.webServiceHelper,
				httpMethod: 'GET',
				transformer: x => x,
			}
		);
	}

	@computed
	public get request(): IUserDailyActivitySummaryRequest {
		const request = {
			endDate: this.endDate.toISOString(),
			startDate: this.startDate.toISOString(),
			userId: this.user?.id,
		};

		return request;
	}

	@action
	public upload = async (file: File) => {
		const formData = new FormData();
		formData.append('files', file);

		const result = await this.userSession.webServiceHelper.callWebServiceAsync('aida/goals', 'PUT', formData);
		if (!result.success) {
			throw asApiError(result);
		}

		return result;
	};
}
