import { FetchStatus } from '../../../entities/utils/useStatesOnFetch';
import { ContentBox } from '../../generics/ContentBox';
import { SimpleCheckbox } from '../../generics/SimpleCheckbox';
import { Group } from '../../leadView/components/Group';
import { useIntegrationProperties } from '../IntegrationPropertiesContext';
import { styleSheet } from '../styles';
import { PropertyMappingSelect } from './PropertyMappingSelect';
import { css } from 'aphrodite';
import { useEffect } from 'react';
import * as React from 'react';

interface IProps {
	onFetchStatusChange: (value: FetchStatus) => void;
}

export const DealGeneralSettings: React.FC<IProps> = ({ onFetchStatusChange }) => {
	const { config, status, update } = useIntegrationProperties();

	const enableFindDemoSpot = config?.enableFindDemoSpot;

	useEffect(() => {
		onFetchStatusChange(status);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const handleSettingCheckbox = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		update({ enableFindDemoSpot: target.checked });
	};

	return (
		<ContentBox maxWidth={1024} smallBottomMargin={true} title='General settings:'>
			<Group vertical={true}>
				<label>
					<Group spacingSize={2} vCentered={true}>
						<div className={css(styleSheet.regularText)}>Enable &quot;Find Demo Spot&quot; feature</div>
						<SimpleCheckbox checked={enableFindDemoSpot} onChange={handleSettingCheckbox} id='general-settings' />
					</Group>
				</label>
				<PropertyMappingSelect
					description='Please find the property that indicates the "person performing the demo"'
					filterBy='User'
					onFetchStatusChange={onFetchStatusChange}
					propertyKey='demoPerformerPropertyName'
				/>
				<PropertyMappingSelect
					description='Please find the property that indicates the "Date of the demo"'
					filterBy='DateTime'
					onFetchStatusChange={onFetchStatusChange}
					propertyKey='demoDatePropertyName'
				/>
				<PropertyMappingSelect
					description='Please choose the default deal pipeline for new deals'
					filterBy='DealPipeline'
					onFetchStatusChange={onFetchStatusChange}
					propertyKey='dealPipelineId'
				/>
			</Group>
		</ContentBox>
	);
};
