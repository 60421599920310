import { CssSelectors } from '../../../web/styles/styles';
import { brandPrimary, brandSecondary, mention, mentionDark } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	ctaContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'flex-end',
		paddingTop: 20,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':last-child'
		),
	},
	dayPicker: {},
	dayPickerContainer: {},
	dayPickerDateNotSelected: {
		background: mention,
		borderRadius: '50%',
	},
	dayPickerDateSelected: {
		background: brandPrimary,
		borderRadius: '50%',
		color: '#fff',
	},
	dayPickerDateToday: {
		':hover': {
			...CssSelectors.allChildren({
				display: 'block',
			}),
		},
		border: `1px solid ${brandSecondary}`,
		borderRadius: '50%',
		overflow: 'visible',
		pointerEvents: 'auto',
		position: 'relative',
	},
	timeContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		paddingTop: 20,
		...CssSelectors.allDescendants(
			{
				width: 189,
			},
			'#timezone-picker-input'
		),
	},
	timezone: {
		...aidaBaseStyles.truncateText,
		flexGrow: 1,
		fontSize: '12px',
		padding: '0 10px',
		textAlign: 'center',
	},
	timezoneSelect: {
		'::before': {
			bottom: 'calc(100% + 3px)',
			content: '"Time Zone"',
			fontSize: '12px',
			position: 'absolute',
		},
		marginLeft: 10,
		position: 'relative',
	},
	tooltip: {
		background: mention,
		border: `1px solid ${mentionDark}`,
		borderRadius: 4,
		bottom: 'calc(100% + 5px)',
		color: '#000',
		display: 'none',
		padding: 10,
		position: 'absolute',
	},
});
