import { useCopyToClipboard } from '.';
import { TransparentButton } from '../../components/TransparentButton';
import { CopyIcon } from '../../components/svgs/icons/CopyIcon';
import { brandPrimaryLight } from '../../styles/colors';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	label?: string;
	message?: {
		error?: string;
		success?: string;
	};
	value?: string;
}

export const CopyButton: React.FC<IProps> = ({ label, message, value }) => {
	const copyToClipboard = useCopyToClipboard();

	return (
		<TransparentButton className={css(styleSheet.copyButton)} onClick={copyToClipboard(value, message)}>
			{label && <small>{label}</small>}
			<CopyIcon fillColor={brandPrimaryLight} className={css(styleSheet.copyIcon)} />
		</TransparentButton>
	);
};
