import { css } from 'aphrodite';
import * as React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { SettingsCards } from '../../components/SettingsCards';
import { SettingsCardType } from '../../models/setting';
import { BasicPage } from '../BasicPage';
import { ReportingContainer } from '../Reporting';
import { Blitzes } from './Blitzes';
import { Categories } from './Categories';
import { Gamification } from './Gamification';
import { Goals } from './Goals';
import { PhoneNumbers } from './PhoneNumbers';
import { PrizesLog } from './PrizesLog';
import { SlotMachinePrizes } from './SlotMachinePrizes';
import { SlotMachines } from './SlotMachines';
import { StartABlitz } from './StartABlitz';
import { Utilities } from './Utilities';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

interface IRouteMatch {
	setting:
		| SettingsCardType.Blitzes
		| SettingsCardType.Categories
		| SettingsCardType.DashAndReports
		| SettingsCardType.Gamification
		| SettingsCardType.Goals
		| SettingsCardType.PhoneNumbers
		| SettingsCardType.PrizesLog
		| SettingsCardType.SlotMachinePrizes
		| SettingsCardType.SlotMachines
		| SettingsCardType.StartABlitz
		| SettingsCardType.Utilities;
}

export const SettingsContainer: React.FC<IProps> = ({ className = '' }) => {
	const match = useRouteMatch<IRouteMatch>('/settings/:setting');
	const userSession = useUserSession();

	switch (match?.params?.setting) {
		case SettingsCardType.Blitzes:
			return <Blitzes />;
		case SettingsCardType.Categories:
			return <Categories />;
		case SettingsCardType.DashAndReports:
			return <ReportingContainer />;
		case SettingsCardType.Gamification:
			return <Gamification />;
		case SettingsCardType.Goals:
			return <Goals />;
		case SettingsCardType.PhoneNumbers:
			return <PhoneNumbers />;
		case SettingsCardType.PrizesLog:
			// @ts-ignore
			// @ts-ignore
			return userSession.account.features.aida.achievementsEnabled ? <PrizesLog /> : <Redirect to='/queue' />;
		case SettingsCardType.SlotMachinePrizes:
			// @ts-ignore
			// @ts-ignore
			return userSession.account.features.aida.achievementsEnabled ? <SlotMachinePrizes /> : <Redirect to='/queue' />;
		case SettingsCardType.SlotMachines:
			// @ts-ignore
			// @ts-ignore
			return userSession.account.features.aida.achievementsEnabled ? <SlotMachines /> : <Redirect to='/queue' />;
		case SettingsCardType.StartABlitz:
			return <StartABlitz />;
		case SettingsCardType.Utilities:
			return <Utilities />;
		default:
			return (
				<BasicPage
					className={`${css(styleSheet.settingsContainer)} ${className}`}
					title={userSession.account.isAdmin ? 'Admins Only' : 'Settings'}
				>
					<div className={css(styleSheet.settings)}>
						<SettingsCards />
					</div>
				</BasicPage>
			);
	}
};
