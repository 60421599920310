import { ISlotMachineSymbolProps, SlotMachineSymbol } from '../SlotMachineSymbol';
import * as React from 'react';

export const CherriesSymbol = React.forwardRef<SVGSVGElement, ISlotMachineSymbolProps>(
	function CherriesSymbol(props, ref) {
		const { className = '', index, ...restProps } = props;
		return (
			<SlotMachineSymbol
				height={119}
				width={117}
				{...restProps}
				className={`${className} cherries-symbol`}
				fill='none'
				ref={ref}
			>
				<defs>
					<linearGradient
						id='cherriesSymbolGradienta'
						x1='112.527'
						y1='34.119'
						x2='72.944'
						y2='3.567'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#6CE800' />
						<stop offset='.23' stopColor='#329A00' />
						<stop offset='.593' stopColor='#005F33' />
						<stop offset='1' stopColor='#3ACD00' />
					</linearGradient>
					<linearGradient
						id='cherriesSymbolGradientb'
						x1='134.936'
						y1='10.95'
						x2='68.268'
						y2='14.771'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#6CE800' />
						<stop offset='.23' stopColor='#329A00' />
						<stop offset='.593' stopColor='#005F33' />
						<stop offset='1' stopColor='#3ACD00' />
					</linearGradient>
					<linearGradient
						id='cherriesSymbolGradientd'
						x1='23.471'
						y1='42.75'
						x2='23.471'
						y2='57.126'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#fff' />
						<stop offset='1' stopColor='#D8D8D8' stopOpacity='.01' />
					</linearGradient>
					<linearGradient
						id='cherriesSymbolGradiente'
						x1='120.497'
						y1='21.351'
						x2='73.083'
						y2='10.707'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#6CE800' />
						<stop offset='.23' stopColor='#329A00' />
						<stop offset='.593' stopColor='#005F33' />
						<stop offset='1' stopColor='#3ACD00' />
					</linearGradient>
					<linearGradient
						id='cherriesSymbolGradientg'
						x1='41.862'
						y1='60.169'
						x2='41.862'
						y2='76.142'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#fff' />
						<stop offset='1' stopColor='#D8D8D8' stopOpacity='.01' />
					</linearGradient>
					<linearGradient
						id='cherriesSymbolGradienth'
						x1='119.226'
						y1='34.73'
						x2='97.305'
						y2='18.529'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#6CE800' />
						<stop offset='.23' stopColor='#329A00' />
						<stop offset='.593' stopColor='#005F33' />
						<stop offset='1' stopColor='#3ACD00' />
					</linearGradient>
					<linearGradient
						id='cherriesSymbolGradienti'
						x1='119.226'
						y1='25.679'
						x2='97.305'
						y2='9.478'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#6CE800' />
						<stop offset='.23' stopColor='#329A00' />
						<stop offset='.593' stopColor='#005F33' />
						<stop offset='1' stopColor='#3ACD00' />
					</linearGradient>
					<linearGradient
						id='cherriesSymbolGradientj'
						x1='120.818'
						y1='45.379'
						x2='98.896'
						y2='29.177'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#6CE800' />
						<stop offset='.23' stopColor='#329A00' />
						<stop offset='.593' stopColor='#005F33' />
						<stop offset='1' stopColor='#3ACD00' />
					</linearGradient>
					<linearGradient
						id='cherriesSymbolGradientk'
						x1='115.504'
						y1='57.078'
						x2='104.042'
						y2='45.353'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#6CE800' />
						<stop offset='.23' stopColor='#329A00' />
						<stop offset='.593' stopColor='#005F33' />
						<stop offset='1' stopColor='#3ACD00' />
					</linearGradient>
					<linearGradient
						id='cherriesSymbolGradientl'
						x1='122.54'
						y1='26.41'
						x2='85.08'
						y2='21.347'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#6CE800' />
						<stop offset='.23' stopColor='#329A00' />
						<stop offset='.593' stopColor='#005F33' />
						<stop offset='1' stopColor='#3ACD00' />
					</linearGradient>
					<radialGradient
						id='cherriesSymbolGradientc'
						cx='0'
						cy='0'
						r='1'
						gradientUnits='userSpaceOnUse'
						gradientTransform='matrix(0 53.3418 -45.2789 0 32.47 37.958)'
					>
						<stop stopColor='red' />
						<stop offset='.413' stopColor='#E00000' />
						<stop offset='.55' stopColor='#9F0000' />
						<stop offset='.779' stopColor='#720303' />
						<stop offset='1' stopColor='#EC0000' />
					</radialGradient>
					<radialGradient
						id='cherriesSymbolGradientf'
						cx='0'
						cy='0'
						r='1'
						gradientUnits='userSpaceOnUse'
						gradientTransform='matrix(0 60.2994 -51.1849 0 64.835 54.463)'
					>
						<stop stopColor='red' />
						<stop offset='.413' stopColor='#E00000' />
						<stop offset='.55' stopColor='#9F0000' />
						<stop offset='.779' stopColor='#720303' />
						<stop offset='1' stopColor='#EC0000' />
					</radialGradient>
				</defs>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M44.632 41.85s18.492-31.033 43.66-30.59l1.546 4.65S57.076 27.691 51.484 45.614c-5.593 17.923-6.852-3.765-6.852-3.765Z'
					fill='url(#cherriesSymbolGradienta)'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M83.663 15.442s-8.974 23.259 9.26 52.612l13.14 14.25 2.277-7.287 1.659 1.415-.202-5.363 1.903.48-1.056-5.126 1.875.567-2.063-5.55 4.053-.252-1.161-4.74 2.35.203-2.547-6.82 2.885.426-3.288-4.205h2.916l-1.75-5.183-1.786-3.994 2.505.133-2.68-4.462s-13.784-20.01-21.318-20.648c-7.533-.639-6.972 3.544-6.972 3.544Z'
					fill='url(#cherriesSymbolGradientb)'
				/>
				<ellipse
					cx='32.47'
					cy='62.449'
					rx='24.407'
					ry='24.491'
					transform='rotate(25 32.47 62.45)'
					fill='url(#cherriesSymbolGradientc)'
				/>
				<ellipse
					cx='38.062'
					cy='49.938'
					rx='14.591'
					ry='7.188'
					transform='rotate(25 38.062 49.938)'
					fill='url(#cherriesSymbolGradientd)'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M63.873 55.838s.847-29.597 19.32-42.084c2.864-1.936 6.483-2.146 9.842-1.327 0 0-23.846 25.302-21.366 43.917 2.48 18.615-7.796-.506-7.796-.506Z'
					fill='url(#cherriesSymbolGradiente)'
				/>
				<ellipse
					cx='64.835'
					cy='82.149'
					rx='27.59'
					ry='27.686'
					transform='rotate(-25 64.835 82.15)'
					fill='url(#cherriesSymbolGradientf)'
				/>
				<ellipse
					cx='58.31'
					cy='68.156'
					rx='16.448'
					ry='7.986'
					transform='rotate(-25 58.31 68.156)'
					fill='url(#cherriesSymbolGradientg)'
				/>
				<path
					d='M91.641 32.572s5.56 8.38 12.058 10.583'
					stroke='url(#cherriesSymbolGradienth)'
					strokeWidth='3'
					strokeLinecap='round'
				/>
				<path
					d='M91.641 23.52s5.56 8.381 12.058 10.583'
					stroke='url(#cherriesSymbolGradienti)'
					strokeWidth='3'
					strokeLinecap='round'
				/>
				<path
					d='M93.233 43.22s5.56 8.381 12.057 10.583'
					stroke='url(#cherriesSymbolGradientj)'
					strokeWidth='3'
					strokeLinecap='round'
				/>
				<path
					d='M96.416 55.998s1.846 3.09 8.344 5.292'
					stroke='url(#cherriesSymbolGradientk)'
					strokeWidth='3'
					strokeLinecap='round'
				/>
				<path
					d='M92.25 13.129s-5.423 25.757 12.816 65.117'
					stroke='url(#cherriesSymbolGradientl)'
					strokeWidth='4'
					strokeLinecap='round'
				/>
			</SlotMachineSymbol>
		);
	}
);
