import { Layouts } from '../../../../web/styles/styles';
import { white } from './../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		justifyContent: 'center',
		margin: '0 auto',
		width: '80%',
		...Layouts.horizontalStack(20),
	},
	fileInput: {
		display: 'none',
	},
	grid: {
		columnGap: '1rem',
		display: 'grid',
		gridTemplateColumns: '340px 340px 340px',
		justifyContent: 'center',
		padding: 20,
		rowGap: '1rem',
	},
	utilities: {},
	utilityActions: {
		flexGrow: 0,
		flexShrink: 1,
		height: 40,
	},
	utilityCard: {
		alignItems: 'center',
		background: white,
		borderRadius: 10,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		padding: 20,
		width: 340,
	},
	utilityCardImage: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'center',
		marginBottom: 20,
	},
});
