import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ResourceAutoCompleteViewModelType } from '../../../../../extViewmodels';
import { RadioButton } from '../../../../../web/components/RadioButton';
import {
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../../../../../web/components/autocomplete/SimpleAutoCompleteSearchField';
import { DeprecatedXIcon } from '../../../../../web/components/svgs/icons/DeprecatedXIcon';
import { baseStyleSheet } from '../../../../../web/styles/styles';
import { LeadRuleViewModel, TurnItOnForOptions } from '../../../../viewModels/leadrules';
import { styleSheet } from '../../styles';

interface IProps {
	enabled: boolean;
	leadRule: LeadRuleViewModel;
	onChangeToEnable: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Vertical: React.FC<IProps> = observer(({ enabled, leadRule, onChangeToEnable }) => {
	const onSelected = (event: ISimpleAutoCompleteSearchFieldItemSelectionEvent<string>) => {
		leadRule?.setVerticals(leadRule?.verticals?.concat(event.selection));
	};
	const onRemoved = (option: string) => () => {
		leadRule?.setVerticals(leadRule?.verticals.filter((item: string) => item !== option));
	};
	return (
		<div>
			<RadioButton checked={enabled} id={TurnItOnForOptions.Vertical} onChange={onChangeToEnable}>
				<span className={css(styleSheet.radioLabel)}>Lead Pool(s)</span>
			</RadioButton>
			<div
				className={css(
					styleSheet.radioContent,
					!enabled && baseStyleSheet.disabled,
					!enabled && baseStyleSheet.disableInteraction
				)}
			>
				{enabled && (
					<>
						<div className={css(styleSheet.userSearchField)}>
							<SimpleAutoCompleteSearchField
								initialSearchQuery=''
								// @ts-ignore
								onItemSelected={onSelected}
								pageSize={5}
								placeholder='Search Lead Pool'
								resultsLimit={5}
								type={ResourceAutoCompleteViewModelType.Vertical}
							/>
						</div>
						{leadRule?.verticals?.map((item: string) => {
							return (
								<div key={item} className={css(styleSheet.selectedUser)}>
									<button onClick={onRemoved(item)}>
										<span>
											<DeprecatedXIcon />
										</span>
									</button>
									<span className={css(styleSheet.userName)}>{item}</span>
								</div>
							);
						})}
					</>
				)}
			</div>
		</div>
	);
});
