import { TextInput } from '../../../web/components/TextInput';
import { TinyPopover } from '../../../web/components/TinyPopover';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import moment from 'moment';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import * as React from 'react';

interface IProps {
	className?: string;
	defaultTime?: moment.Moment;
	/**
	 * The increments to be displayed in the TimePicker dropdown must be a number greater than 0 and less than or equal to
	 * 60
	 */
	increment?: number;
	onChange: (time: moment.Moment) => void;
}

const optionFormat = 'ddd, DD MMM YYYY HH:mm:ss ZZ';
const checkIsValidIncrement = (increment: number) => {
	if (increment <= 0 || increment > 60) {
		throw new Error('invalid increment found');
	}

	return true;
};

const roundToNearestIncrement = (day: moment.Moment, increment: number) => {
	checkIsValidIncrement(increment);
	let dateTime = day.clone();

	const diff = dateTime.minute() % increment;

	if (diff) {
		const remainder = increment - diff;
		dateTime = dateTime.add(remainder, 'minutes');
	}

	return dateTime;
};

const getIncrements = (increment: number) => {
	checkIsValidIncrement(increment);
	const increments: number[] = [];
	for (let i = 0; i < 60; i++) {
		if (i % increment === 0) {
			increments.push(i);
		}
	}
	return increments;
};

const getOption = (day: moment.Moment) => {
	return day.format(optionFormat);
};

// will not provide times in the past
const getTimeOptions = (d: moment.Moment, increment: number) => {
	const date = d.clone();
	checkIsValidIncrement(increment);
	let options: string[] = [];
	const increments = getIncrements(increment);
	const day = date.set('minutes', 0);
	for (let i = 0; i < 24; i++) {
		day.set('hour', i);

		if (increment !== 60) {
			options = [
				...options,
				...increments.map(inc => {
					day.set('minutes', inc);
					return getOption(day);
				}),
			];
		}
	}

	return options.filter(o => !!o);
};

// const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
const parseTimeInput = (time: string): moment.Moment => {
	let parsedHours = parseInt(time, 10);
	let parsedMins = 0;

	if (isNaN(parsedHours) || parsedHours > 24) {
		// @ts-ignore
		return null;
	}

	const segment = time.split(`${time?.[0] === '0' ? '0' : ''}${parsedHours}`)[1]?.trim();

	if (segment?.[0] === ':') {
		const mins = time.split(':')[1]?.trim();
		if (mins) {
			parsedMins = parseInt(mins, 10);
			if (isNaN(parsedMins) || parsedMins > 59) {
				// @ts-ignore
				return null;
			}

			const ampm = mins.split(`${mins?.[0] === '0' ? '0' : ''}${parsedMins}`)[1]?.trim();
			if (ampm?.[0]?.toLowerCase() === 'p' && parsedHours < 12) {
				parsedHours += 12;
			}
		}
	} else if (segment?.[0]?.toLowerCase() === 'p' && parsedHours < 12) {
		parsedHours += 12;
	}

	const now = moment();
	now.set('hours', parsedHours);
	now.set('minutes', parsedMins);
	now.set('seconds', 0);
	now.set('milliseconds', 0);
	return now;
};

export const TimePicker: React.FC<IProps> = ({ className = '', defaultTime, increment = 15, onChange }) => {
	const [timeOptions, setTimeOptions] = useState(getTimeOptions(defaultTime || moment(), increment));
	const [selectedTime, setSelectedTime] = useState(roundToNearestIncrement(defaultTime || moment(), increment));
	const [isOpen, setIsOpen] = useState(false);
	const [inputValue, setInputValue] = useState(moment(selectedTime || timeOptions[0]).format('h:mmA'));
	const [isValid, setIsValid] = useState(true);
	const [highlight, setHighlight] = useState(-1);
	const optionsRef = useRef<HTMLDivElement>(null);

	const scrollOptionIntoView = () => {
		if (isOpen) {
			setTimeout(() => {
				const el = document.getElementById(`time-option-${selectedTime.hour()}-${selectedTime.minute()}`);
				if (el) {
					el.scrollIntoView();
				}
			}, 10);
		}
	};

	useEffect(() => {
		setTimeOptions(getTimeOptions(defaultTime || moment(), increment));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultTime]);

	useEffect(() => {
		onChange(selectedTime);
		setHighlight(timeOptions.indexOf(selectedTime.format(optionFormat)));
		scrollOptionIntoView();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTime]);

	useEffect(() => {
		setIsValid(true);
		if (inputValue) {
			const parsedTime = parseTimeInput(inputValue);
			if (parsedTime) {
				setSelectedTime(roundToNearestIncrement(parsedTime, increment));
			} else {
				setIsValid(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue]);

	useEffect(() => {
		if (isOpen) {
			scrollOptionIntoView();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, highlight]);

	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			if (highlight > -1) {
				setSelectedTime(moment(timeOptions[highlight]));
			}

			if (inputValue) {
				const parsedTime = parseTimeInput(inputValue);
				if (parsedTime) {
					setSelectedTime(parsedTime);
					setInputValue(parsedTime.format('h:mm A'));
					setIsValid(true);
					setIsOpen(false);
				} else {
					setIsValid(false);
				}
			}
		} else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
			let newHighlight = -1;
			let time = '';
			if (e.key === 'ArrowDown' && highlight < timeOptions.length - 1) {
				newHighlight = highlight + 1;
			} else if (e.key === 'ArrowUp' && highlight > -1) {
				newHighlight = highlight - 1;
			}

			time = timeOptions[newHighlight];

			if (time) {
				const parsedTime = parseTimeInput(moment(time).format('h:mm A'));

				if (parsedTime) {
					setInputValue(parsedTime.format('h:mm A'));
					setHighlight(newHighlight);
					setIsValid(true);
				}
			}
		}
	};

	const onTimeInputBlur = () => {
		setTimeout(() => {
			setHighlight(-1);
			setIsOpen(false);
		}, 400);
	};

	const onTimeInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value);
		setIsOpen(true);
		setIsValid(true);
	};

	const onTimeInputFocus = () => {
		const newHightlight = timeOptions.indexOf(selectedTime.format(optionFormat));
		setHighlight(newHightlight);
		setIsOpen(true);
	};

	const onTimeOptionClick = (selectedOption: string) => () => {
		const time = moment(selectedOption);
		setInputValue(time.format('h:mm A'));
		setHighlight(timeOptions.indexOf(selectedOption));
		setIsValid(true);
		setIsOpen(false);
	};

	const renderOptions = () => {
		return timeOptions.map((o, i) => {
			const day = moment(o);
			return (
				<div
					className={css(styleSheet.option, i === highlight && styleSheet.highlight)}
					id={`time-option-${day.hour()}-${day.minute()}`}
					onClick={onTimeOptionClick(o)}
					key={`time-option-${i}`}
				>
					{day.format('h:mm A')}
				</div>
			);
		});
	};

	return (
		<div className={`${css(styleSheet.timePickerContainer)} ${className}`}>
			<TinyPopover
				anchor={
					<div className={css(styleSheet.textInputContainer)}>
						{!isValid && <div className={css(styleSheet.invalid)}>invalid</div>}
						<TextInput
							className={css(styleSheet.textInput, !isValid && styleSheet.invalidTextInput)}
							inputId='time-input'
							onChange={onTimeInputChange}
							onFocus={onTimeInputFocus}
							onBlur={onTimeInputBlur}
							onKeyDown={onKeyDown}
							type='text'
							value={inputValue}
						/>
					</div>
				}
				isOpen={isOpen}
			>
				<div className={css(styleSheet.optionsContainer)} ref={optionsRef}>
					<div className={css(styleSheet.optionsContainerInner)}>{renderOptions()}</div>
				</div>
			</TinyPopover>
		</div>
	);
};
