import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../../web/styles/styles';
import { brandPrimary, brandSecondary, white } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	autocomplete: {
		...CssSelectors.allDescendants(
			{
				background: brandPrimary,
				border: `1px solid ${brandSecondary}`,
				borderRadius: 3,
				...CssSelectors.allDescendants(
					{
						color: white,
						width: 240,
					},
					'input'
				),
			},
			'.common-autocomplete .autocomplete-search-field-input'
		),
		...CssSelectors.allDescendants(
			{
				fill: white,
				opacity: 0,
			},
			'.common-autocomplete-clear-button circle'
		),
		...CssSelectors.allDescendants(
			{
				fill: white,
			},
			'.common-autocomplete-search-icon path'
		),
	},
});
