import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { Children, Fragment, JSXElementConstructor, ReactNode } from 'react';
import * as React from 'react';

export type GroupProps = React.HTMLAttributes<HTMLElement> & {
	children: ReactNode;
	className?: string;
	fullWidth?: boolean;
	hCentered?: boolean;
	noWrap?: boolean;
	spacingSize?: number;
	vCentered?: boolean;
	vertical?: boolean;
};

export const Group: JSXElementConstructor<GroupProps> = ({
	children,
	className,
	fullWidth = true,
	hCentered,
	noWrap = true,
	spacingSize = 10,
	vCentered,
	vertical = false,
}) => {
	const styles = styleSheet({
		fullWidth,
		hCentered,
		noWrap,
		spacingSize,
		vCentered,
		vertical,
	});

	return (
		<div className={`${css(styles.base)} ${className || ''}`}>
			{Children.toArray(children).map((element, index) => (
				<Fragment key={index}>
					{index !== 0 && <div className={css(styles.spacing)} />}
					{element}
				</Fragment>
			))}
		</div>
	);
};
