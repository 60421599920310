import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const CrownGraphic: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`crown-graphic ${className}`} height={27} width={39}>
			<path fillRule='evenodd' clipRule='evenodd' d='M2 7L18 20H2L2 7Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M2 20L9 27H2L2 20Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M10 20L17 27H10L10 20Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M29 20L22 27H29L29 20Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M37 20L30 27H37L37 20Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M38 7L22 20H38L38 7Z' fill='#E7C309' />
			<circle cx='19.5' cy='2.5' r='2.5' fill='#F3CE0D' />
			<circle cx='36.5' cy='2.5' r='2.5' fill='#F3CE0D' />
			<circle cx='2.5' cy='2.5' r='2.5' fill='#F3CE0D' />
			<path fillRule='evenodd' clipRule='evenodd' d='M20 7L28 20H12L20 7Z' fill='#F9D519' />
			<path fillRule='evenodd' clipRule='evenodd' d='M19.5 20L22 27H17L19.5 20Z' fill='#F9D519' />
		</SvgIcon>
	);
};
