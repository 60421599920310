import { CssSelectors } from '../../../../web/styles/styles';
import { brandPrimary, brandPrimaryDark, brandSecondary, tagBackgroundColor, white } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	blitzPlayLightning1: {
		height: 40,
		left: -15,
		position: 'absolute',
		top: -12,
	},
	blitzPlayLightning2: {
		height: 55,
		position: 'absolute',
		right: -40,
		top: -10,
	},
	input: {
		':nth-child(1n)': {
			background: 'none',
			border: 'none',
			fontSize: 16,
			marginLeft: 5,
			outline: 'none',
			width: 530,
		},
	},
	inputContainer: {
		alignItems: 'center',
		background: white,
		borderRadius: 10,
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .1)',
		display: 'flex',
		fontSize: 16,
		height: 50,
		margin: '0 auto',
		maxWidth: 600,
	},
	inputLoader: {
		marginRight: 14,
		width: 40,
	},
	leadRulesContainer: {
		marginTop: 30,
	},
	listHeader: {
		margin: '0 auto',
		marginBottom: 20,
		maxWidth: 1000,
	},
	resultsContainer: {
		':nth-child(1n)': {
			background: white,
			borderRadius: 10,
			boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .1)',
			marginLeft: -39,
			marginTop: 5,
			padding: '10px 0',
			width: 600,
		},
	},
	resultTag: {
		':hover': {
			background: tagBackgroundColor,
		},
		cursor: 'pointer',
		margin: 0,
		padding: '8px 14px',
	},
	searchIcon: {
		height: 20,
		marginLeft: 14,
		marginRight: 5,
		width: 20,
	},
	select: {
		background: brandPrimary,
		border: `1px solid ${brandSecondary}`,
		width: 200,
		...CssSelectors.allDescendants(
			{
				...CssSelectors.allDescendants(
					{
						fill: brandSecondary,
					},
					'polygon'
				),
			},
			'.disclosure-icon'
		),
		...CssSelectors.allDescendants(
			{
				color: white,
			},
			'.select-option-text'
		),
	},
	selectDropdown: {
		border: `1px solid ${brandSecondary}`,
		...CssSelectors.allDescendants(
			{
				background: brandPrimary,
			},
			'.options-container'
		),
		...CssSelectors.allDescendants(
			{
				background: brandPrimary,
				...CssSelectors.allDescendants({
					':hover': {
						background: brandPrimaryDark,
					},
					color: white,
				}),
			},
			'.option-container'
		),
		...CssSelectors.allDescendants(
			{
				background: brandPrimaryDark,
			},
			'.selected'
		),
	},
	startABlitzButton: {
		backgroundColor: brandPrimary,
		borderRadius: 20,
		color: white,
		height: 36,
		textAlign: 'center',
		width: 114,
	},
	startABlitzContainer: {
		position: 'absolute',
		right: 40,
		top: 77,
	},
	toggle: {
		...CssSelectors.allChildren(
			{
				color: white,
				fontSize: 14,
			},
			'.toggle-text'
		),
	},
});
