import { css } from 'aphrodite';
import * as React from 'react';
import { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { Avatar } from '../../../web/components/Avatar';
import { ISelectOption, Select } from '../../../web/components/Select';
import { DisclosureIcon } from '../../../web/components/svgs/icons/DisclosureIcon';
import { IntegrationIcon } from '../../../web/components/svgs/icons/IntegrationIcon';
import { LogoutIcon } from '../../../web/components/svgs/icons/LogoutIcon';
import { QuestionMarkIcon } from '../../../web/components/svgs/icons/QuestionMarkIcon';
import { ReportingIcon } from '../../../web/components/svgs/icons/ReportingIcon';
import { SettingsIcon } from '../../../web/components/svgs/icons/SettingsIcon';
import { brandPrimaryLight, grayIconFill } from '../../styles/colors';
import { styleSheet } from './styles';

enum UserOption {
	Help = 'Help',
	Integration = 'Integration',
	Logout = 'logout',
	ReportingDashboard = 'ReportingDashboard',
	Settings = 'Settings',
	Voicemail = 'Voicemail',
}

interface IProps {
	className?: string;
}

export const UserOptions: React.FC<IProps> = ({ className = '' }) => {
	const userSession = useUserSession();
	const history = useHistory();

	const isPropertyMappingEnabled = userSession?.account?.features?.aida?.propertyMappingEnabled;

	const options = useRef<ISelectOption<UserOption>[]>([
		{
			dataContext: UserOption.Settings,
			icon: <SettingsIcon stroke={grayIconFill} className={css(styleSheet.question)} />,
			id: 'user-option-settings',
			styles: [styleSheet.logoutOption],
			text: 'Settings',
			type: 'icon',
		},
		{
			dataContext: UserOption.Help,
			icon: <QuestionMarkIcon fillColor={grayIconFill} className={css(styleSheet.question)} />,
			id: 'user-option-help',
			styles: [styleSheet.logoutOption],
			text: 'Help',
			type: 'icon',
		},
		{
			dataContext: UserOption.ReportingDashboard,
			icon: <ReportingIcon className={css(styleSheet.reporting)} />,
			id: 'user-option-aida-reporting-dashboard',
			styles: [styleSheet.logoutOption],
			text: 'Reporting',
			type: 'icon',
		},
		// @ts-ignore
		isPropertyMappingEnabled
			? {
					dataContext: UserOption.Integration,
					icon: <IntegrationIcon fill={grayIconFill} />,
					id: 'user-option-aida-integration',
					styles: [styleSheet.logoutOption],
					text: 'Integration',
					type: 'icon',
				}
			: null,
		{
			dataContext: UserOption.Logout,
			icon: <LogoutIcon fill={grayIconFill} />,
			id: 'user-option-logout',
			styles: [styleSheet.logoutOption],
			text: 'Logout',
			type: 'icon',
		},
	]).current;

	const onOptionClick = (option: ISelectOption<UserOption>) => {
		switch (option.dataContext) {
			case UserOption.Logout:
				history.push('/logout');
				break;
			case UserOption.Settings:
				history.push('/settings');
				break;
			case UserOption.Help:
				window.open('https://help.coffee.inc');
				break;
			case UserOption.ReportingDashboard:
				history.push('/reporting');
				break;
			case UserOption.Integration:
				history.push('/integration');
				break;
			default:
				break;
		}
	};

	const renderAnchor = useCallback(() => {
		return (
			<div className={css(styleSheet.anchor)}>
				{/* @ts-ignore */}
				<Avatar className={css(styleSheet.avatar)} user={userSession.user} />
				<DisclosureIcon type='chevron' fillColor={brandPrimaryLight} className={css(styleSheet.chevron)} />
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={`${css(styleSheet.userOptionsContainer)} ${className}`}>
			<Select
				onOptionClick={onOptionClick}
				options={options}
				selectedOptionTitle={renderAnchor}
				showCaret={false}
				styles={[styleSheet.select]}
			/>
		</div>
	);
};
