import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { Provider, observer } from 'mobx-react';
import { Component } from 'react';
import { CredentialStore } from '../../../api/CredentialStore';
import { ImpersonationContextKey, ModalChildComponentContextKey } from '../../../models';
import * as MainAppState from '../../../models/AppState';
import { IUserSessionContextConfig, UserSessionContext } from '../../../viewmodels/AppViewModels';
import { BrowserPushNotificationsViewModel } from '../../../viewmodels/PushNotifications';
import { ReactQueryProvider } from '../../../web/ReactQueryProvider';
import * as SharedAppState from '../../models/AppState';
import { GamificationViewModel } from '../../viewModels/gamification';
import { PhoneCallsViewModel } from '../../viewModels/phonecall';
import { WebsocketsListener } from '../../viewModels/websockets';
import { styleSheet } from './styles';

interface IProps {
	userSessionConfig?: IUserSessionContextConfig;
}

interface IState {
	isLoading?: boolean;
}

class AidaAppBase extends Component<IProps, IState> {
	private userSession: UserSessionContext;
	// @ts-ignore
	private mMounted: boolean;
	public readonly state: IState = {
		isLoading: true,
	};

	constructor(props: IProps) {
		super(props);

		/* const env = process.env.BUGSNAG_ENV as BUGSNAG_ENV; */
		const userSessionConfig: IUserSessionContextConfig = {
			...props.userSessionConfig,
			apiConfig: {
				...(
					props.userSessionConfig || {
						apiConfig: {
							baseUrl: process.env.API_URL,
						},
					}
				).apiConfig,
				credentialStore: new CredentialStore(),
			},
		};

		this.userSession = new UserSessionContext(userSessionConfig);
		MainAppState.AppState[MainAppState.UserSessionViewModelKey] = this.userSession;
		/*
		const highlightEventLogger = new HighlightEventLogger(env, process.env.HIGHLIGHT_APPID, SharedAppState.AppState);
		Api.EventLogger.addLogger(highlightEventLogger);
		*/

		SharedAppState.AppState[SharedAppState.WebsocketsViewModelKey] = new WebsocketsListener(
			this.userSession,
			Api.EventLogger
		);
		// @ts-ignore
		SharedAppState.AppState[SharedAppState.ActionItemComposerViewModelKey].setUserSession(this.userSession);
		SharedAppState.AppState[SharedAppState.GamificationViewModelKey] = new GamificationViewModel(
			this.userSession,
			SharedAppState.AppState[SharedAppState.WebsocketsViewModelKey]
		);
		// @ts-ignore
		SharedAppState.AppState[SharedAppState.NoteComposerViewModelKey].setUserSession(this.userSession);
		// @ts-ignore
		SharedAppState.AppState[SharedAppState.QuickAddEntityViewModelKey].setUserSession(this.userSession);
		SharedAppState.AppState[SharedAppState.PhoneCallsViewModelKey] = new PhoneCallsViewModel(this.userSession);
		SharedAppState.AppState[SharedAppState.PushNotificationsViewModelKey] = new BrowserPushNotificationsViewModel(
			this.userSession,
			{
				addLogger: Api.VmUtils.Noop,
				logEvent: Api.VmUtils.Noop,
				removeLogger: Api.VmUtils.Noop,
			}
		);
		// @ts-ignore
		SharedAppState.AppState[SharedAppState.SingleEmailComposerKey].setUserSession(this.userSession);
	}

	public componentDidMount() {
		this.mMounted = true;
		const promise = this.userSession.load();
		const onFinish = () => {
			if (this.mMounted) {
				// @ts-ignore
				SharedAppState.AppState[SharedAppState.WebsocketsViewModelKey].init({});
				// @ts-ignore
				SharedAppState.AppState[SharedAppState.GamificationViewModelKey].connect();
				this.setState({
					isLoading: false,
				});
			}
		};
		if (promise) {
			promise.then(onFinish).catch(onFinish);
		} else {
			onFinish();
		}
	}

	public componentWillUnmount() {
		this.mMounted = false;
		// @ts-ignore
		SharedAppState.AppState[SharedAppState.PhoneCallsViewModelKey].reset();
	}

	public render() {
		return (
			<ReactQueryProvider>
				<Provider
					{...SharedAppState.AppState}
					{...{
						[MainAppState.UserSessionViewModelKey]: this.userSession,
						[ImpersonationContextKey]: null,
						[ModalChildComponentContextKey]: null,
					}}
				>
					<div className={css(styleSheet.container)}>
						{this.state.isLoading ? <span style={{ display: 'none' }} /> : this.props.children}
					</div>
				</Provider>
			</ReactQueryProvider>
		);
	}
}

export const AidaApp = observer(AidaAppBase);
