import * as Api from '@ViewModels';

/**
 * Keep the contact logic for COFFEE separate from the base contact view model. This class overrides the base route
 * where contacts are managed. Here it is done per lead rather than on the account as a whole.
 */
export class AidaContactViewModel extends Api.ContactViewModel {
	public static Create = (userSession: Api.UserSessionContext, contactModel: Api.IContact) => {
		const promise = new Promise<AidaContactViewModel>((resolve, reject) => {
			const onFinish = (opResult: Api.IOperationResult<Api.IContact>) => {
				if (opResult.success) {
					// @ts-ignore
					const contact = new AidaContactViewModel(userSession, opResult.value);
					resolve(contact);
				} else {
					reject(opResult);
				}
			};
			userSession.webServiceHelper.callWebServiceWithOperationResults<Api.IContact>(
				`lead/${contactModel.companyId}/contact`,
				'POST',
				contactModel,
				onFinish,
				onFinish
			);
		});
		return promise;
	};

	protected getEntityApiPath = () => {
		return `lead/${this.companyId}/contact`;
	};
}
