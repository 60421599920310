import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const OpenNewWindowIcon: React.FC<IProps> = ({ className, fill = '#fff' }) => {
	const height = 20;
	const width = 24;
	return (
		<SvgIcon
			className={`open-new-window-icon ${className}`}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			width={width}
		>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g transform='translate(-248.000000, -428.000000)' fill={fill}>
					<g transform='translate(248.000100, 428.000500)'>
						<polygon points='17.9999 0 17.9999 2 20.5859 2 16.2929 6.293 17.7069 7.708 21.9999 3.414 21.9999 6 23.9999 6 23.9999 0' />
						<path d='M2,17.9995 L2,9.9995 L14,9.9995 L14,17.9995 L2,17.9995 Z M1,7.9995 C0.448,7.9995 0,8.4475 0,8.9995 L0,18.9995 C0,19.5515 0.448,19.9995 1,19.9995 L15,19.9995 C15.552,19.9995 16,19.5515 16,18.9995 L16,8.9995 C16,8.4475 15.552,7.9995 15,7.9995 L1,7.9995 Z' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
