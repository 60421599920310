import { CssSelectors } from '../../../web/styles/styles';
import { borderColor, brandPrimary, brandPrimaryHover, gray, grayIconFill } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

const executiveSummaryHeight = 100;

export const styleSheet = StyleSheet.create({
	body: {
		fontSize: '14px',
		maxHeight: executiveSummaryHeight,
		overflow: 'auto',
		whiteSpace: 'pre-wrap',
	},
	button: {
		...aidaBaseStyles.flexHorizontalCenter,
		fontSize: '12px',
	},
	buttonsContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
	},
	buttonText: {
		color: brandPrimary,
	},
	executiveSummaryContainer: {
		marginBottom: 15,
		position: 'relative',
		...CssSelectors.allDescendants(
			{
				minHeight: 0,
			},
			'.note-editor-body-editor'
		),
	},
	header: {
		...aidaBaseStyles.flexHorizontalCenter,
		height: 25,
		justifyContent: 'space-between',
	},
	headerEditing: {
		marginBottom: 10,
	},
	noteEditorContainer: {
		maxHeight: 200,
		overflowY: 'auto',
		...CssSelectors.allDescendants(
			{
				padding: '10px 0',
			},
			'.rich-content-document-editor-readonly'
		),
	},
	saveButton: {
		':hover': {
			background: brandPrimaryHover,
		},
		background: brandPrimary,
		border: 'none',
		borderRadius: 3,
		color: '#fff',
		marginRight: 10,
		padding: '2px 10px 3px',
	},
	summaryPlaceholder: {
		color: gray,
		fontSize: 12,
	},
	textarea: {
		':focus': {
			border: `1px solid ${brandPrimary}`,
		},
		border: `1px solid ${borderColor}`,
		borderRadius: 3,
		height: executiveSummaryHeight,
		outline: 'none',
		padding: 10,
		resize: 'none',
		width: '100%',
	},
	title: {
		color: grayIconFill,
		fontSize: '12px',
		letterSpacing: '1.2px',
		textTransform: 'uppercase',
	},
});
