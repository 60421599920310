import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { combinationsDependencies, create, simplifyDependencies } from 'mathjs';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useEventLogging } from '../../../../../models/Logging';
import { useErrorMessages } from '../../../../hooks';
import { SlotMachineAchievementViewModel } from '../../../../viewModels/slotMachines';
import { PrizeConfigurationTableRow } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	achievement: SlotMachineAchievementViewModel;
	className?: string;
	styles?: StyleDeclarationValue[];
}

const { combinations, simplify } = create({
	combinationsDependencies,
	simplifyDependencies,
});

/**
 * This is incomplete and doesn't really tell the story for specific reel configs or symbol combinations. Also doesn't
 * factor in, for instance, how a payline of n symbols would match before one of n-1 symbols but only if that payline is
 * active, otherwise n symbols would match n-1 symbols and the probability of a win for n-1 would increase slightly.
 */
const getProbabilityForMatchCount = (
	reelConfiguration: Api.ISlotMachineReelConfig[],
	count: number,
	asString = false
) => {
	const reelCount = reelConfiguration.length;
	// assumes each reel is the same
	const reelSymbols = reelConfiguration[0].reelSymbols;
	const sampleSymbol: Api.SlotMachineSymbol = reelSymbols[0];
	const sampleSymbolCountPerReel = reelSymbols.reduce(
		(symbolCount, x) => symbolCount + (x === sampleSymbol ? 1 : 0),
		0
	);
	const probOfSampleSymbol = sampleSymbolCountPerReel / reelSymbols.length;

	if (asString) {
		const numerator =
			combinations(reelCount, count) *
			Math.pow(sampleSymbolCountPerReel, count) *
			Math.pow(reelSymbols.length - sampleSymbolCountPerReel, reelCount - count);
		return simplify(`${numerator}/${Math.pow(reelSymbols.length, reelCount)}`);
	}

	// NOTE: this does not account for the fact that, if count < reelCount a spin result of count + 1 symbols would also match.
	// You'd need to add the probabilities of these additional cases.
	return (
		combinations(reelCount, count) *
		Math.pow(probOfSampleSymbol, count) *
		Math.pow(1 - probOfSampleSymbol, reelCount - count)
	);
};

export const PrizeConfigurationTable: React.FC<IProps> = observer(props => {
	const { className, styles = [], achievement } = props;
	const { logApiError } = useEventLogging('PrizeConfigurationTable');
	const errorMessages = useErrorMessages();

	const rows = React.useMemo(() => {
		const keys = new Set<number>();
		const map: Record<number, Api.ISlotMachinePayLine[]> = {};
		if (achievement?.game?.config?.payTable) {
			achievement.game.config.payTable.forEach(payline => {
				keys.add(payline.symbolCount);
				const collection = map[payline.symbolCount] || [];
				collection.push(payline);
				map[payline.symbolCount] = collection;
			});
		}
		return [
			Array.from(keys).sort((a, b) => {
				if (a > b) {
					return -1;
				} else if (b > a) {
					return 1;
				}
				return 0;
			}),
			map,
		] as const;
	}, [achievement?.game?.config?.payTable]);

	const onPayLineChanged = React.useCallback(
		async (payline: Api.ISlotMachinePayLine) => {
			try {
				await achievement.game.updatePayLine(payline);
			} catch (error) {
				// @ts-ignore
				logApiError('UpdatePayLine-Error', error);
				// @ts-ignore
				// @ts-ignore
				errorMessages.pushApiError(error);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[achievement?.game]
	);
	return (
		<div
			className={`${css(styleSheet.container, ...styles)} slot-machines-prize-configuration-table ${className || ''}`}
		>
			<div className={css(styleSheet.header)}>{achievement.game?.description}</div>
			{rows[0].map(symbolCount => {
				const payLines = rows[1][symbolCount];
				return (
					<React.Fragment key={`symbols-${symbolCount}`}>
						<div className={css(styleSheet.prizeColHeader)}>
							<div className={css(styleSheet.symbolCol)}>{`${symbolCount} Matching`}</div>
							<div className={css(styleSheet.prizeCol)}>{`PRIZE (probability is ${getProbabilityForMatchCount(
								achievement.game.config.reelConfiguration,
								symbolCount,
								true
							)}):`}</div>
						</div>
						<div>
							{payLines.map(payLine => {
								return (
									<PrizeConfigurationTableRow
										count={symbolCount}
										key={`${payLine.symbol}-${symbolCount}`}
										onPayLineChanged={onPayLineChanged}
										payLine={payLine}
									/>
								);
							})}
						</div>
					</React.Fragment>
				);
			})}
		</div>
	);
});
