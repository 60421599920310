import { CssSelectors, FontFamilies } from '../../../web/styles/styles';
import {
	brandPrimary,
	brandSecondary,
	destructive,
	grayIconFill,
	green,
	mentionDark,
	titles,
	white,
} from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	active: {
		color: green,
	},
	blitzPlayButton: {
		...CssSelectors.allDescendants(
			{
				height: 56,
			},
			'img'
		),
	},
	container: {
		background: white,
		borderRadius: 10,
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .1)',
		fontSize: 14,
		justifyContent: 'center',
		margin: '0 auto',
		marginBottom: 30,
		maxWidth: 1000,
		minHeight: 90,
		padding: 20,
		position: 'relative',
	},
	customRule: {
		alignItems: 'center',
		background: 'gray',
		borderRadius: 10,
		color: titles,
		cursor: 'not-allowed',
		display: 'flex',
		fontFamily: FontFamilies.conqueror,
		fontSize: 20,
		height: '100%',
		justifyContent: 'center',
		left: 0,
		opacity: 0.9,
		position: 'absolute',
		top: 0,
		width: '100%',
		zIndex: 2,
	},
	expired: {
		color: destructive,
	},
	flexContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	infoTitle: {
		color: grayIconFill,
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
	},
	moreMenu: {
		border: 'none',
		position: 'absolute',
		right: 15,
		top: 15,
		width: 'auto',
	},
	moreMenuContainer: {
		position: 'absolute',
		right: 5,
		top: 5,
	},
	moreMenuTrigger: {
		':hover': {
			...CssSelectors.allChildren(
				{
					fill: brandPrimary,
				},
				'svg path'
			),
		},
		height: 10,
		marginLeft: 10,
		padding: '0 5px',
		...CssSelectors.allChildren(
			{
				fill: brandSecondary,
			},
			'svg path'
		),
	},
	name: {
		flexGrow: 1,
		flexShrink: 0,
		fontFamily: FontFamilies.bold,
		fontWeight: 800,
		maxWidth: 390,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	outcomeTypes: {
		fontSize: 12,
		marginLeft: 6,
	},
	priority: {
		'::before': {
			borderRight: 'solid 15px transparent',
			borderTop: `solid 24px ${mentionDark}`,
			content: "' '",
			height: 0,
			position: 'absolute',
			right: -15,
			top: 0,
			width: 0,
			zIndex: 0,
		},
		backgroundColor: mentionDark,
		borderTopLeftRadius: 10,
		height: 24,
		left: 0,
		position: 'absolute',
		top: 0,
		width: 'fit-content',
	},
	priorityLabel: {
		color: brandPrimary,
		fontFamily: FontFamilies.bold,
		fontSize: 13,
		fontWeight: 800,
		padding: '3px 6px 3px 9px',
		position: 'relative',
		zIndex: 1,
	},
	readonly: {
		opacity: 0.5,
	},
	schedule: {
		fontSize: 14,
	},
	separator: {
		fontSize: 12,
		margin: '0 4px',
	},
	tag: {
		display: 'inline-block',
		margin: 4,
		...CssSelectors.allDescendants(
			{
				height: 30,
			},
			'button'
		),
	},
	tagContainer: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		marginLeft: -5,
	},
	tagNot: {
		':nth-of-type(1n)': {
			background: '#FEF3F5',
		},
	},
	trashButton: {
		cursor: 'pointer',
	},
	turnThisOnSection: {
		marginRight: 15,
	},
});
