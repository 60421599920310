import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import { brandPrimaryHover } from '../../../../styles/colors';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const VolumeIcon: React.FC<IProps> = ({ className = '', fillColor = brandPrimaryHover }) => {
	return (
		<SvgIcon className={`volume-icon ${className}`} height={18} width={20}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.521 11H2V7h3.521L9 2.027l.001 13.949L5.521 11ZM9.764.152a2.019 2.019 0 0 0-2.178.434L4.479 5H2C.897 5 0 5.897 0 7v4c0 1.103.897 2 2 2h2.479l2.995 4.28c.416.496.959.72 1.526.72a1.995 1.995 0 0 0 2-2V2c0-.813-.485-1.538-1.236-1.848ZM13 2v2c2.757 0 5 2.243 5 5s-2.243 5-5 5v2c3.86 0 7-3.14 7-7 0-3.859-3.14-7-7-7'
				fill={fillColor}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16 9c0-1.654-1.346-3-3-3v2a1.001 1.001 0 0 1 0 2v2c1.654 0 3-1.346 3-3m-6-6.973V16l-1 .737-3.5-3.752L2.918 12l-1.79-.644V6H5.5L9 1l1 1.027Z'
				fill={fillColor}
			/>
		</SvgIcon>
	);
};
