import { CssSelectors } from '../../../web/styles/styles';
import { brandPrimaryHover, brandPrimaryLight, error } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleDeclaration, StyleSheet } from 'aphrodite';

const fieldStyles: StyleDeclaration = {
	...aidaBaseStyles.flexHorizontalCenter,
	background: '#f9f9f9',
	border: '1px solid #e2e2e2',
	borderRadius: 3,
	minHeight: 40,
	padding: '0 10px',
};

export const styleSheet = StyleSheet.create({
	addCcButton: {
		color: brandPrimaryHover,
	},
	addCcIcon: {
		marginLeft: 10,
	},
	addCcIconDown: {
		transform: 'rotateZ(90deg)',
	},
	addCcIconUp: {
		transform: 'rotateZ(-90deg)',
	},
	ccField: {
		background: 'none',
		border: 'none',
		flexGrow: 1,
	},
	email: {
		border: `1px solid ${brandPrimaryLight}`,
		borderRadius: 3,
		padding: '4px 10px',
	},
	fieldContainer: fieldStyles,
	recipientsList: {
		justifyContent: 'space-between',
	},
	recipientsListContainer: {
		...aidaBaseStyles.flexCol,
		minWidth: '100%',
		// needed for over-writing the default Lev styles
		...CssSelectors.allDescendants(
			{
				...fieldStyles,
				marginTop: 10,
				padding: '0 10px',
				...CssSelectors.allDescendants(
					{
						background: 'none',
						border: 'none',
						fontSize: '12px',
						outline: 'none',
					},
					'input'
				),
			},
			'.email-cc-field-dropdown'
		),
		...CssSelectors.allDescendants(
			{
				flexGrow: 1,
			},
			'.dropdown-anchor'
		),
		...CssSelectors.allDescendants(
			{
				border: 'none',
				height: 'auto',
				padding: 0,
			},
			'.email-cc-field'
		),
		...CssSelectors.allDescendants(
			{
				marginLeft: 0,
				marginTop: 0,
				minHeight: 'unset',
			},
			'.email-cc-field-content'
		),
		...CssSelectors.allDescendants(
			{
				marginRight: 10,
				position: 'static',
			},
			'.email-cc-field-label'
		),
		...CssSelectors.allDescendants(
			{
				border: 'none',
				fontSize: '12px',
				...CssSelectors.allChildren(
					{
						border: 'none',
						fontSize: '12px',
					},
					'span'
				),
			},
			'.email-cc-field-tokens'
		),
		...CssSelectors.allDescendants(
			{
				':hover': {
					...CssSelectors.allChildren(
						{
							background: 'none',
							border: 'none',
							outline: 'none',
						},
						'.email-cc-field-token-remove-button'
					),
				},
				margin: '2px 10px 2px 0',
			},
			'.email-cc-field-token'
		),
		...CssSelectors.allDescendants(
			{
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
			'.email-cc-field-token-text'
		),
		...CssSelectors.allDescendants(
			{
				marginTop: 20,
			},
			'.dropdown-content'
		),
	},
	text: {
		color: '#767676',
		fontSize: '12px',
	},
	toContainer: {
		marginRight: 10,
		...CssSelectors.allChildren(
			{
				color: error,
			},
			'span'
		),
	},
});
