import { CssSelectors, FontFamilies } from '../../../web/styles/styles';
import {
	black,
	borderColor,
	brandPrimaryDark,
	brandSecondary,
	grayDark,
	grayIconFill,
	white,
} from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addPlusButton: {
		'::after': {
			borderLeft: '24px solid transparent',
			borderTop: `24px solid ${brandSecondary}`,
			content: "' '",
			height: 0,
			marginTop: -12,
			position: 'absolute',
			right: -5,
			top: '50%',
			transform: 'rotate(45deg)',
			transformOrigin: 'center',
			width: 0,
			zIndex: 0,
		},
		'::before': {
			color: white,
			content: "'Click to add:'",
			fontSize: 14,
			left: 0,
			position: 'absolute',
			textAlign: 'center',
			top: -30,
			width: 120,
		},
		alignItems: 'center',
		backgroundColor: brandSecondary,
		borderRadius: 10,
		boxShadow: `0 5px 14px 4px rgba(0, 0, 0, 0.2)`,
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		height: 120,
		justifyContent: 'center',
		marginTop: -40,
		position: 'relative',
		width: 120,
		...CssSelectors.allChildren(
			{
				height: 48,
				width: 48,
			},
			'svg'
		),
	},
	addPlusButtonAdding: {
		cursor: 'not-allowed',
	},
	// AddProperties
	addPropertiesContentBox: {
		height: 520,
	},
	addPropertiesEmptyState: {
		alignItems: 'center',
		color: black,
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		fontSize: 14,
		justifyContent: 'center',
		textAlign: 'center',
	},
	addPropertiesEmptyStateImg: {
		marginBottom: 30,
	},
	addPropertiesRightBody: {
		flex: 1,
		marginBottom: 20,
		...CssSelectors.allChildren(
			{
				maxHeight: 306,
				overflow: 'scroll',
			},
			':first-child'
		),
	},
	addPropertiesRightFooter: {
		'::before': {
			borderTop: `1px solid ${borderColor}`,
			content: "' '",
			left: -20,
			position: 'absolute',
			top: -20,
			width: 'calc(100% + 40px)',
		},
		marginTop: 20,
		position: 'relative',
	},
	addPropertiesRightHeader: {
		'::after': {
			borderBottom: `1px solid ${borderColor}`,
			bottom: -20,
			content: "' '",
			left: -20,
			position: 'absolute',
			width: 'calc(100% + 40px)',
		},
		color: black,
		fontSize: 12,
		marginBottom: 40,
		position: 'relative',
		textAlign: 'center',
		textTransform: 'uppercase',
	},
	basicPage: {
		...CssSelectors.allChildren(
			{
				marginTop: 30,
			},
			':last-child'
		),
	},
	cancelButton: {
		color: brandPrimaryDark,
		marginTop: 10,
		textAlign: 'center',
	},
	// Cards
	card: {
		':hover': {
			boxShadow: `0 5px 14px 4px rgba(0, 0, 0, 0.2)`,
			transform: 'scale(1.05)',
		},
		...aidaBaseStyles.flexCenter,
		borderRadius: 10,
		color: '#fff',
		fontFamily: FontFamilies.conqueror,
		fontSize: '54px',
		height: '100%',
		padding: '50px 40px',
		textAlign: 'center',
		transition: '.2s ease-in-out',
	},
	cardContainer: {
		height: 250,
		margin: '22.5px 12px',
		textDecoration: 'none',
		width: 400,
	},
	cardsWrapper: {
		...aidaBaseStyles.flexHorizontalCenter,
		alignItems: 'stretch',
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
	contentBox: {
		...CssSelectors.allChildren(
			{
				paddingBottom: 0,
			},
			':last-child'
		),
	},
	dealPropertyLabel: {
		fontSize: 14,
	},
	description: {
		color: grayDark,
		textAlign: 'center',
	},
	// DraggableList
	draggableIcon: {
		fill: borderColor,
	},
	draggableItem: {},
	draggableItemIsDragging: {
		opacity: 0.6,
	},
	draggableItemRow: {
		background: white,
		borderBottom: `1px solid ${borderColor}`,
		borderRadius: 4,
		borderTop: `1px solid ${borderColor}`,
		marginBottom: -1,
		marginLeft: -20,
		padding: '20px 30px',
		width: 'calc(100% + 40px)',
	},
	emptyState: {
		padding: '20px 40px 40px 40px',
	},
	// List states
	loadingState: {
		padding: '20px 40px 40px 40px',
	},
	// PropertiesHeader
	propertiesHeader: {
		paddingBottom: 10,
	},
	propertiesHeaderTitle: {
		color: grayIconFill,
		fontSize: 12,
		textTransform: 'uppercase',
	},
	// PropertyItemRow
	propertyItemRow: {
		position: 'relative',
	},
	// Select
	regularText: {
		fontSize: 14,
		marginRight: 5,
		whiteSpace: 'nowrap',
	},
	removeButton: {
		...CssSelectors.allChildren(
			{
				position: 'relative',
				top: 1,
			},
			'svg'
		),
	},
	searchInput: {
		minWidth: 400,
		...CssSelectors.allChildren(
			{
				paddingLeft: 15,
			},
			'input'
		),
	},
	searchInputFullWidth: {
		minWidth: '100%',
		...CssSelectors.allChildren(
			{
				paddingLeft: 15,
			},
			'input'
		),
	},
	trashIcon: {},
});
