import Fuse from 'fuse.js';
import { useEffect, useRef } from 'react';

export const useFuzzySearch = <T>(list: T[], keys: string[]) => {
	const options: any = {
		keys,
		threshold: 0,
	};

	const fuse = useRef(new Fuse<T>(Array.isArray(list) ? list : [], options));

	useEffect(() => {
		if (Array.isArray(list) && list?.length > 0) {
			fuse.current.setCollection(list);
		}
	}, [list]);

	return fuse.current;
};
