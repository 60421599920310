import { FontFamilies } from '../../../../web/styles/styles';
import { white } from '../../../styles/colors';
import { aidaBaseStyles } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	header: {
		...aidaBaseStyles.flexCenter,
		marginBottom: 20,
		marginTop: 30,
		minWidth: '100%',
	},
	title: {
		color: white,
		fontFamily: FontFamilies.conqueror,
		fontSize: 54,
		textTransform: 'uppercase',
	},
	titleMobile: {
		fontSize: 20,
	},
	titleSmall: {
		fontSize: 40,
	},
});
