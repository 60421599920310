import { CssSelectors } from '../../../../web/styles/styles';
import { brandPrimaryHover, mention, mentionDark, titles } from '../../../styles/colors';
import { aidaBaseStyles } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	dropdown: {
		border: 'none',
		boxShadow: `0 1px 4px 1px rgba(0, 0, 0, 0.2)`,
		marginTop: 5,
	},
	followUpSelectContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
	},
	label: {
		color: titles,
		fontSize: '11px',
		minWidth: 70,
		textTransform: 'uppercase',
	},
	optionContainer: {
		':hover': {
			...CssSelectors.allChildren(
				{
					background: mentionDark,
				},
				'.select-option'
			),
		},
		background: mention,
		color: brandPrimaryHover,
		fontSize: '12px',
	},
	selectContainer: {
		border: 'none',
	},
	selectedOptionText: {
		color: brandPrimaryHover,
	},
	title: {
		minWidth: 130,
		...CssSelectors.allChildren(
			{
				fontSize: '12px',
			},
			'span'
		),
	},
	trigger: {
		background: mention,
		border: 'none',
		borderRadius: 30,
	},
});
