import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const QueenOfHearsGraphic: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`queen-of-hearts-graphic ${className}`} height={62} width={48}>
			<rect x='3' y='3' width='42' height='56' rx='9' fill='white' stroke='#00528C' strokeWidth='5' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24.005 29.4734C24.0517 29.3728 24.0968 29.2781 24.14 29.1825C24.7547 27.8245 25.6771 26.7834 27.0602 26.2811C28.7549 25.6657 30.9874 26.0567 32.2297 27.8018C32.9849 28.8627 33.1455 30.0399 32.8817 31.3013C32.6033 32.6319 32.0142 33.821 31.2373 34.8967C30.4401 36.0004 29.5854 37.0601 28.7371 38.1227C27.5732 39.5808 26.4297 41.0524 25.507 42.6943C24.9651 43.6589 24.5227 44.6689 24.1839 45.7293C24.1507 45.8334 24.0709 45.9874 23.9968 45.998C23.8337 46.0214 23.8393 45.8317 23.8083 45.7197C23.245 43.6738 22.2084 41.8869 20.9725 40.2227C19.7847 38.6234 18.544 37.0666 17.329 35.4891C16.7095 34.6848 16.0968 33.8737 15.662 32.9397C15.2673 32.0921 14.9971 31.2151 15 30.2522C15.0044 28.8751 15.5497 27.7877 16.5731 26.9578C18.4512 25.4348 21.2801 25.7671 22.897 27.6773C23.3077 28.1625 23.6161 28.7126 23.8743 29.2965C23.8947 29.3428 23.9169 29.3886 23.942 29.4321C23.95 29.4458 23.9706 29.4516 24.005 29.4734Z'
				fill='#D0021B'
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M17 16L23 24H17L17 16Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M31 16L25 24H31L31 16Z' fill='#E7C309' />
			<circle cx='24' cy='14' r='1' fill='#F3CE0D' />
			<circle cx='31' cy='14' r='1' fill='#F3CE0D' />
			<circle cx='17' cy='14' r='1' fill='#F3CE0D' />
			<path fillRule='evenodd' clipRule='evenodd' d='M24 16L27 24H21L24 16Z' fill='#F9D519' />
		</SvgIcon>
	);
};
