import * as Api from '@ViewModels';
import { animated } from '@react-spring/web';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { useAudioPlayer } from '../../../hooks/useSound';
import { ISlotMachineComponent } from '../../../models/slotMachines';
import { SlotMachineViewModel } from '../../../viewModels/slotMachines';
import { SlotMachineEndGameModal } from '../SlotMachineEndGameModal';
import HandleAudioFileUrl from '../coins.mp3';
import { useSlotMachineEndGameModal, useSlots } from '../hooks';
import BackgoundImageUrl from './background.svg';
import PlayNowImageUrl from './goldRushPlayNow.svg';
import TitleImageUrl from './goldRushTitle.png';
import WinAudioFileUrl from './goldRushWin.mp3';
import HandleImageUrl from './handle.svg';
import MachineImageUrl from './machine.svg';
import { CoinMachine, GoldRushSymbol } from './presentation';
import ReelOverlayImageUrl from './reelOverlay.svg';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	scale?: number;
	slotMachine: SlotMachineViewModel;
	styles?: StyleDeclarationValue[];
}

export const MachineDefaultSize = 3200;

/**
 * Note: audio files are from: https://mixkit.co/free-sound-effects/slot-machine/ License states free to use in
 * comercial projects: https://mixkit.co/license/#sfxFree
 */
export const GoldRushSlotMachine: React.FC<IProps> & ISlotMachineComponent = props => {
	const { className, slotMachine, styles = [], scale = 0.33 } = props;
	const { handleProps, loadingSpin, onHandleClick, reelItemProps } = useSlots(slotMachine, 'GoldRushSlotMachine');
	const { showEndGameModalWithTimeout, ...endGameModalProps } = useSlotMachineEndGameModal(slotMachine);
	const winAudio = useAudioPlayer(WinAudioFileUrl);
	const handleAudio = useAudioPlayer(HandleAudioFileUrl);
	const getScaledValue = (value: number) => {
		return scale * value;
	};

	const spin = React.useCallback(async () => {
		handleAudio.play();
		await onHandleClick();
		handleAudio.stop();
		if (slotMachine.spinOutcome?.gameOutcomeResultType === Api.GameOutcomeResultType.Win) {
			winAudio.play();
		}
		showEndGameModalWithTimeout(3000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onHandleClick, showEndGameModalWithTimeout, winAudio, handleAudio]);

	return (
		<div className={`${css(styleSheet.container, ...styles)} gold-rush-slot-machine ${className || ''}`}>
			{loadingSpin ? <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} /> : null}
			<SlotMachineEndGameModal {...endGameModalProps} />
			<animated.div
				className={css(styleSheet.machineContainer)}
				style={{
					height: getScaledValue(MachineDefaultSize),
					width: getScaledValue(MachineDefaultSize),
				}}
			>
				{slotMachine?.token?.gameOutcome?.gameOutcomeResultType === Api.GameOutcomeResultType.Win && !loadingSpin ? (
					<CoinMachine />
				) : null}
				<img
					className={css(styleSheet.machinePart)}
					height={getScaledValue(MachineDefaultSize)}
					src={BackgoundImageUrl}
					width={getScaledValue(MachineDefaultSize)}
				/>
				<animated.img
					className={css(styleSheet.machinePart, styleSheet.handle)}
					height={getScaledValue(290.53)}
					onClick={!loadingSpin && !slotMachine?.spinOutcome ? spin : undefined}
					src={HandleImageUrl}
					style={{
						left: getScaledValue(1971),
						perspective: 1100,
						top: getScaledValue(1492),
						transform: handleProps.rotation.to(x => {
							return `rotateX(${x}deg)`;
						}),
						transformOrigin: `0% 100% 0px`,
						transformStyle: 'preserve-3d',
					}}
					width={getScaledValue(250.91)}
				/>
				<img
					className={css(styleSheet.machinePart)}
					height={getScaledValue(1251.5)}
					src={MachineImageUrl}
					width={getScaledValue(1770.98)}
					style={{
						left: getScaledValue(696.22),
						top: getScaledValue(1578),
					}}
				/>
				<img
					className={css(styleSheet.machinePart)}
					height={getScaledValue(220.32)}
					onClick={!loadingSpin && !slotMachine?.spinOutcome ? spin : undefined}
					src={PlayNowImageUrl}
					width={getScaledValue(353.76)}
					style={{
						left: getScaledValue(1386.78),
						top: getScaledValue(2120.34),
					}}
				/>
				<div
					className={css(styleSheet.machinePart, styleSheet.reels)}
					style={{
						height: getScaledValue(251),
						left: getScaledValue(1330),
						top: getScaledValue(1647.5),
						width: getScaledValue(494.14),
					}}
				>
					{slotMachine.reels.map((reel, i) => {
						const w = getScaledValue(470) / slotMachine.reels.length;
						const h = getScaledValue(231);
						return (
							<animated.div
								className={css(styleSheet.machinePart, styleSheet.reel)}
								key={reel.id}
								style={{
									height: h,
									marginLeft: w * i,
									perspective: `${getScaledValue(100)}px`,
									width: w,
								}}
							>
								{reelItemProps[i]?.map((animatedStyles, index) => {
									const { rotation, blur, ...restProps } = animatedStyles;
									return (
										<animated.div
											key={index}
											className={css(styleSheet.machinePart, styleSheet.reelItem)}
											style={{
												...restProps,
												filter: blur
													.to({
														output: [0, 1, 0.5, 0],
														range: [0, 0.5, 0.75, 1],
													})
													.to(x => {
														return `blur(${x}px)`;
													}),
												height: h,
												transform: rotation.to(x => {
													return `rotateX(${x}deg)`;
												}),
												transformOrigin: `50% 50% -${h}px`,
												width: w,
											}}
										>
											<GoldRushSymbol
												symbolType={slotMachine.reels[i].symbols[index].value}
												width={getScaledValue(80)}
											/>
										</animated.div>
									);
								})}
							</animated.div>
						);
					})}
				</div>
				<img
					className={css(styleSheet.machinePart)}
					height={getScaledValue(251)}
					src={ReelOverlayImageUrl}
					width={getScaledValue(524.14)}
					style={{
						left: getScaledValue(1309.9),
						top: getScaledValue(1647.5),
					}}
				/>
				<img
					className={css(styleSheet.machinePart)}
					height={getScaledValue(708)}
					src={TitleImageUrl}
					width={getScaledValue(1194)}
					style={{
						left: getScaledValue(978),
						top: getScaledValue(749.5),
					}}
				/>
				<div
					className={css(styleSheet.machinePart, styleSheet.messageBox)}
					style={{
						height: getScaledValue(215),
						left: getScaledValue(1203),
						top: getScaledValue(2584.5),
						width: getScaledValue(764),
					}}
				>
					<div>Gongrats! You&apos;ve earned</div>
					<div>
						a round of &nbsp;
						<span className={css(baseStyleSheet.fontBold)}>Gold Rush</span>!
					</div>
				</div>
			</animated.div>
		</div>
	);
};

GoldRushSlotMachine.PreloadImageUrls = [
	BackgoundImageUrl,
	HandleImageUrl,
	MachineImageUrl,
	ReelOverlayImageUrl,
	TitleImageUrl,
];
