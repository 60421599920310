import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../web/styles/styles';
import {
	borderColor,
	brandPrimary,
	brandPrimaryHover,
	brandSecondary,
	error,
	grayIconFill,
	orange,
	orangeHover,
	titles,
	white,
} from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';

const avatarSize = 60;

export const styleSheet = StyleSheet.create({
	avatar: {
		height: avatarSize,
		width: avatarSize,
	},
	avatarContainer: {
		...aidaBaseStyles.flexCol,
		alignItems: 'center',
	},
	bio: {
		fontSize: '12px',
	},
	callButton: {
		':hover': {
			background: orangeHover,
			transition: '.25s ease-in-out',
		},
		background: orange,
		borderRadius: 3,
		color: white,
		fontSize: '12px',
		padding: '1px 8px',
		textAlign: 'center',
	},
	clickableItem: {
		...aidaBaseStyles.flexHorizontalCenter,
		fontSize: '12px',
		justifyContent: 'space-between',
		maxWidth: '100%',
		minWidth: '100%',
		overflow: 'hidden',
		padding: '2px 0',
		...CssSelectors.allChildren(
			{
				...CssSelectors.allChildren(
					{
						':hover': {
							color: brandPrimaryHover,
							textDecoration: 'underline',
						},
						color: brandPrimary,
					},
					'div'
				),
			},
			'div'
		),
		...CssSelectors.allChildren(
			{
				':hover': {
					color: brandPrimaryHover,
					textDecoration: 'underline',
				},
				color: brandPrimary,
			},
			'span'
		),
		...CssSelectors.allChildren(
			{
				color: '#000',
				paddingLeft: 10,
			},
			':last-child:not(div):not(:first-child)'
		),
	},
	clickableItemsContainer: {
		...aidaBaseStyles.flexCol,
		alignItems: 'flex-start',
	},
	companyContactsContainer: {},
	contactCard: {
		'::before': {
			background: orange,
			bottom: 0,
			content: '" "',
			left: 0,
			position: 'absolute',
			top: 0,
			width: 4,
		},
		':not(:last-child)': {
			marginBottom: 10,
		},
		...aidaBaseStyles.flexHorizontalCenter,
		border: `1px solid ${borderColor}`,
		borderLeft: '1px solid transparent',
		borderRadius: 4,
		minHeight: 120,
		overflow: 'hidden',
		padding: '20px 15px 15px',
		position: 'relative',
	},
	copyIcon: {
		marginTop: 3,
		width: 26,
	},
	copyPhoneIcon: {
		alignItems: 'center',
		display: 'flex',
		marginLeft: 10,
	},
	disabled: {
		opacity: 0.5,
	},
	dmFlag: {
		color: grayIconFill,
		fontSize: '11px',
		minHeight: 15,
		minWidth: 18,
		position: 'relative',
	},
	emailUnsubcribed: {
		...aidaBaseStyles.flexHorizontalCenter,
		color: error,
		fontSize: '12px',
		padding: '2px 0',
	},
	googleLink: {
		paddingBottom: 4,
	},
	infoContainer: {
		...aidaBaseStyles.flexCol,
		flexGrow: 1,
		maxWidth: `calc(100% - ${avatarSize}px)`,
		paddingLeft: 15,
	},
	jobTitle: {
		color: '#858585',
		fontSize: '12px',
	},
	label: {
		':nth-child(1n)': {
			':hover': {
				textDecoration: 'none',
			},
		},
		color: titles,
	},
	moreMenu: {
		border: 'none',
	},
	moreMenuContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		position: 'absolute',
		right: 5,
		top: 5,
	},
	moreMenuTrigger: {
		':hover': {
			...CssSelectors.allChildren(
				{
					fill: brandPrimary,
				},
				'svg path'
			),
		},
		height: 10,
		marginLeft: 10,
		padding: '0 5px',
		...CssSelectors.allChildren(
			{
				fill: brandSecondary,
			},
			'svg path'
		),
	},
	name: {
		alignItems: 'center',
		display: 'block',
		width: '100%',
	},
	phoneNumberItem: {
		...aidaBaseStyles.flexHorizontalCenter,
		fontSize: '12px',
		justifyContent: 'space-between',
		maxWidth: '100%',
		minWidth: '100%',
		overflow: 'hidden',
		padding: '2px 0',
		...CssSelectors.allChildren(
			{
				paddingLeft: 10,
			},
			':last-child:not(div):not(:first-child)'
		),
	},
});
