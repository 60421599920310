import { FontFamilies } from '../../../web/styles/styles';
import { brandPrimaryDark, brandPrimaryHover, green, white } from '../../styles/colors';
import { aidaBaseStyles, headerHeight } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const statusBannerHeight = 60;

export const styleSheet = StyleSheet.create({
	blitzPlayLightning1: {
		height: 40,
		left: -15,
		position: 'absolute',
		top: -12,
	},
	blitzPlayLightning2: {
		height: 55,
		position: 'absolute',
		right: -40,
		top: -10,
	},
	demosContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
	},
	green: {
		color: green,
	},
	label: {
		color: brandPrimaryHover,
		font: FontFamilies.semiBold,
		fontSize: '12px',
		paddingLeft: 10,
	},
	lion: {
		':not(:last-child)': {
			marginRight: 5,
		},
		height: 25,
		width: 25,
	},
	red: {
		color: '#FF7888',
	},
	startABlitzButton: {
		background: brandPrimaryHover,
		borderRadius: 20,
		color: white,
		height: 36,
		textAlign: 'center',
		width: 114,
	},
	startABlitzContainer: {
		position: 'absolute',
		right: 40,
	},
	stat: {
		font: FontFamilies.bold,
		fontSize: '20px',
		transition: 'color .3s ease-in-out',
	},
	statContainer: {
		':not(:last-child)': {
			borderRight: `1px solid ${brandPrimaryHover}`,
		},
		...aidaBaseStyles.flexHorizontalCenter,
		padding: '0 20px',
	},
	statsContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
	},
	statusBannerContainer: {
		...aidaBaseStyles.flexCenter,
		background: brandPrimaryDark,
		height: statusBannerHeight,
		left: 0,
		position: 'fixed',
		right: 0,
		top: headerHeight,
	},
	statusBannerContent: {
		...aidaBaseStyles.flexCenter,
		justifyContent: 'space-evenly',
		maxWidth: 1400,
		width: '95%',
	},
	yellow: {
		color: '#F3CE0D',
	},
});
