import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

export interface ILeadViewRowViewProps {
	onClick?: () => void;
	source?: string;
}

export const LeadViewRowView: React.FC<ILeadViewRowViewProps> = ({ children, onClick, source }) => {
	const TagName = source ? 'a' : ('div' as keyof JSX.IntrinsicElements);
	return (
		<TagName className={`${css(styleSheet.container)} lead-view-row-view`} href={source} onClick={onClick}>
			{children}
		</TagName>
	);
};
