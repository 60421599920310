import { StyleSheet } from 'aphrodite';
import { FontFamilies } from '../../../web/styles/styles';
import { brandPrimaryDark, brandSecondary } from '../../styles/colors';

export const styleSheet = StyleSheet.create({
	answer: {
		fontFamily: FontFamilies.light,
		marginBottom: 12,
	},
	container: {
		':first-of-type': {
			maxWidth: 800,
		},
		color: 'white',
		margin: '0 auto',
		maxWidth: 800,
		width: '80%',
	},
	lightning: {
		height: 20,
		width: 20,
	},
	question: {
		fontFamily: FontFamilies.bold,
		marginBottom: 20,
	},
	questionTitle: {
		marginBottom: 10,
	},
	tipContainer: {
		background: brandSecondary,
		border: brandPrimaryDark,
		borderRadius: 5,
		padding: 12,
	},
	tipContent: {
		fontFamily: FontFamilies.light,
	},
	tipTitle: {
		alignItems: 'center',
		display: 'flex',
		fontFamily: FontFamilies.bold,
		marginBottom: 6,
	},
});
