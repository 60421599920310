import { LeadRuleViewModel } from '../../../viewModels/leadrules';
import { friendlyWeeklySchedule } from '../../LeadRuleModal/utils';
import { Group } from '../../leadView/components/Group';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	leadRule: LeadRuleViewModel;
}

export const RuleSchedule: React.FC<IProps> = ({ leadRule }) => {
	const isAlwaysOn = leadRule?.recurrence === 'AlwaysOn';
	const isSingleInterval = leadRule?.recurrence === 'SingleInterval';
	const isWeekly = leadRule?.recurrence === 'Weekly';

	const weeklySchedule = leadRule?.weeklySchedule;

	const intervals =
		isWeekly &&
		weeklySchedule
			?.map(friendlyWeeklySchedule)
			?.filter(i => !!i)
			.join(', ');

	if (isAlwaysOn) {
		return null;
	}

	return (
		<Group fullWidth={false} className={css(styleSheet.turnThisOnSection)}>
			{!isAlwaysOn && !leadRule.isExpired && <p className={css(styleSheet.infoTitle)}>Schedule:</p>}
			{isSingleInterval && (
				<div className={css(styleSheet.schedule)}>
					{leadRule.isExpired ? (
						<span className={css(styleSheet.expired)}>EXPIRED</span>
					) : (
						<>
							<span>{leadRule?.scheduleMin}</span>
							&nbsp;to&nbsp;
							<span>{leadRule?.scheduleMax}</span>
						</>
					)}
				</div>
			)}
			{isWeekly && (
				<span>
					<span>Weekly: {intervals}</span>
				</span>
			)}
		</Group>
	);
};
