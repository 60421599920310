import * as Api from '@ViewModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useErrorMessages } from '../../hooks';
import { ApiClient } from '../../services/api';
import { formatOptional } from '../utils/criteriaBuilder';
import { useStatesOnFetch } from '../utils/useStatesOnFetch';
import {
	ILeaderboardDemos,
	ILeaderboardDemosFilterCriteria,
	LeaderboardDemosFilterCriteriaProperty,
} from './../../viewModels/gamification';

export interface ILeaderboardDemosFilter {
	day?: string;
}

function filterToCriteria(filter: ILeaderboardDemosFilter): ILeaderboardDemosFilterCriteria[] {
	const criteria: ILeaderboardDemosFilterCriteria[] = [];

	criteria.push(
		// @ts-ignore
		formatOptional<LeaderboardDemosFilterCriteriaProperty>(LeaderboardDemosFilterCriteriaProperty.Day, filter?.day)
	);

	return criteria.filter(item => !!item);
}

interface IParams {
	filter: ILeaderboardDemosFilter;
}

export const useLeaderboardDemos = () => {
	const { state, handleWorking, handleResponse, reset } = useStatesOnFetch<ILeaderboardDemos>();

	const userSession = useUserSession();
	const errorMessages = useErrorMessages();

	// @ts-ignore
	const client = new ApiClient(userSession);

	const get = async ({ filter }: IParams) => {
		handleWorking();
		try {
			const response = await client.getLeaderboardDemos(filterToCriteria(filter));
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response);
			return response;
		} catch (error) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
	};

	return {
		...state,
		empty: !state?.data,
		get,
		reset,
		status: state.status,
	};
};
