import { css } from 'aphrodite';
import { useEffect } from 'react';
import * as React from 'react';
import { EmailAddress, IRecipient } from '../../../extViewmodels';
import { EmailCCField } from '../../../web/components/email/EmailCCFIeld';
import { DisclosureIcon } from '../../../web/components/svgs/icons/DisclosureIcon';
import { aidaBaseStyleSheet } from '../../styles/styles';
import { TransparentButton } from '../TransparentButton';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	emails: EmailAddress[];
	onCcRecipientsChange: (ccRecipients?: IRecipient[]) => void;
	isAddingCC: boolean;
	setAddCc: React.Dispatch<React.SetStateAction<boolean>>;
	ccRecipients: IRecipient[];
	setCcRecipients: React.Dispatch<React.SetStateAction<IRecipient[]>>;
}

export const RecipientsList: React.FC<IProps> = ({
	className = '',
	emails = [],
	onCcRecipientsChange,
	isAddingCC,
	setAddCc,
	ccRecipients,
	setCcRecipients,
}) => {
	useEffect(() => {
		onCcRecipientsChange?.(ccRecipients);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ccRecipients]);

	const renderRecipients = () => {
		return emails.map((email, i) => (
			<div key={`email-${i}`} className={css(styleSheet.text, styleSheet.email)}>
				{email.value}
			</div>
		));
	};

	const onAddCcClick = () => {
		if (!isAddingCC) {
			setAddCc(true);
		} else {
			setAddCc(false);
			setCcRecipients([]);
		}
	};

	return (
		<div className={`${css(styleSheet.recipientsListContainer)} ${className}`}>
			<div className={css(styleSheet.fieldContainer, styleSheet.recipientsList)}>
				<div className={css(aidaBaseStyleSheet.flexHorizontalCenter)}>
					<div className={css(styleSheet.text, styleSheet.toContainer)}>
						To:<span>*</span>
					</div>
					<div>{renderRecipients()}</div>
				</div>
				{!!onCcRecipientsChange && (
					<TransparentButton className={css(styleSheet.addCcButton)} onClick={onAddCcClick}>
						<span>Add Cc</span>
						<DisclosureIcon
							className={css(styleSheet.addCcIcon, isAddingCC ? styleSheet.addCcIconUp : styleSheet.addCcIconDown)}
						/>
					</TransparentButton>
				)}
			</div>
			{isAddingCC && !!onCcRecipientsChange && (
				<EmailCCField
					className={css(styleSheet.ccField)}
					onRecipientsChange={setCcRecipients}
					recipients={ccRecipients}
				/>
			)}
		</div>
	);
};
