import { brandPrimary } from '../../styles/colors';
import { aidaBaseStyles, headerHeight } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	headerContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		background: brandPrimary,
		height: headerHeight,
		left: 0,
		padding: '0 30px',
		position: 'fixed',
		right: 0,
		top: 0,
		zIndex: 1,
	},
	logo: {
		height: 50,
		width: 'auto',
	},
	mobileHeaderContainer: {
		height: 60,
		padding: '0 20px',
	},
	nav: {
		flexGrow: 1,
		paddingLeft: 100,
	},
	pointsProgressBar: {
		marginRight: 10,
	},
});
