import { IUser, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { getDisplayName } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { Button } from '../../../../web/components/Button';
import { ISelectOption, Select } from '../../../../web/components/Select';
import { AutoCompleteSearchField } from '../../../../web/components/autocomplete/AutoCompleteSearchField';
import { useToaster } from '../../../hooks';
import { white } from '../../../styles/colors';
import { aidaBaseStyleSheet } from '../../../styles/styles';
import { FieldKey } from '../../../viewModels/form';
import { IPhoneCallOutcome } from '../../../viewModels/phonecall';
import { SearchIcon } from '../../svgs/icons/SearchIcon';
import { useLeadView } from '../context';
import { styleSheet } from './styles';

export const LeadViewFilters: React.FC = () => {
	const leadView = useLeadView();
	const { setSelectedOutcome } = leadView;
	const userSession = useUserSession();
	const toaster = useToaster();
	const { logApiError } = useEventLogging('LeadOutcomeTypeFilter');
	const [outcomeOptions, setOutcomeOptions] = useState<ISelectOption<FieldKey>[]>([]);

	useEffect(() => {
		const loadOutcomes = async () => {
			try {
				const value = await userSession.webServiceHelper.callAsync<IPhoneCallOutcome[]>(
					`lead/PhoneCallOutcomes`,
					'GET'
				);
				// transform into select options
				// @ts-ignore
				const options = value.map(x => {
					const opt: ISelectOption<FieldKey> = {
						dataContext: x.name,
						id: x.name,
						text: x.label,
					};
					return opt;
				});
				options.unshift({
					dataContext: FieldKey.Unknown,
					id: 'all',
					text: 'All outcome types...',
				});
				setOutcomeOptions(options);
				setSelectedOutcome(FieldKey.Unknown);
			} catch (error) {
				// @ts-ignore
				logApiError('LoadOutcomes-Error', error);
				// @ts-ignore
				toaster.push({
					// @ts-ignore
					message: error.systemMessage ?? 'Unable to load outcomes',
				});
			}
		};
		loadOutcomes();
	}, [setSelectedOutcome, logApiError, toaster, userSession]);

	const onUserSelected = (item: IUser) => {
		leadView.setSelectedUser(item);
	};

	const onClearUser = () => {
		// @ts-ignore
		leadView.setSelectedUser(null);
	};

	const onOutcomeClick = (option: ISelectOption<FieldKey>) => {
		leadView.setSelectedOutcome(option.dataContext);
	};

	return (
		<div className={`${css(styleSheet.container)} lead-view-filters`}>
			<AutoCompleteSearchField
				anchorClassName='user-auto-complete-anchor'
				className={css(styleSheet.userAutocomplete)}
				dropdownClassName='user-auto-complete-dropdown'
				leftAccessory={<SearchIcon className={css(styleSheet.searchIcon)} fillColor={white} />}
				onItemSelected={onUserSelected}
				placeholder='Filter by SDR'
				preventImpersonation={true}
				clearSearchFieldAfterSelectingItem={true}
				resultsLimit={5}
				type={ResourceAutoCompleteViewModelType.User}
			/>
			{leadView.selectedUser && (
				<div className={css(aidaBaseStyleSheet.flexHorizontalCenter)}>
					<div className={css(styleSheet.selectedUser)}>{getDisplayName(leadView.selectedUser)}</div>
					<Button label='Clear' onClick={onClearUser} kind='secondary' />
				</div>
			)}
			<Select
				options={outcomeOptions}
				onOptionClick={onOutcomeClick}
				selectedOption={outcomeOptions.find(x => x.dataContext === leadView.selectedOutcome)}
				styles={[styleSheet.outcomeSelect]}
				dropdownStyles={[styleSheet.outcomeSelectDropdown]}
			/>
		</div>
	);
};
