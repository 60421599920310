import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	aidaActionItemContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		alignItems: 'stretch',
		border: '1px solid #f2f2f2',
		borderRadius: 3,
		boxShadow: 'rgb(139 139 139 / 10%) 0px 0px 6px 0px',
		position: 'relative',
	},
	checkboxContainer: {
		...aidaBaseStyles.flexCenter,
		background: '#f9f9f9',
		width: 46,
	},
	indicator: {
		bottom: 0,
		left: 0,
		position: 'absolute',
		top: 0,
		width: 4,
	},
});
