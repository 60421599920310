import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useOnFirstLoad } from '../../../../../models/hooks/mobxHooks';
import { SlotMachineAchievementsViewModel } from '../../../../viewModels/slotMachines';
import { AchievementConfigurationTableRow } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	achievements: SlotMachineAchievementsViewModel;
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const AchievementConfigurationTable: React.FC<IProps> = observer(props => {
	const { achievements, className, styles = [] } = props;
	// const userSession = useUserSession();

	useOnFirstLoad(achievements, () => {
		if (!achievements.selectedAchievement && achievements.fetchResults?.length) {
			achievements.selectedAchievement = achievements.fetchResults?.filter(x => x.configuration?.enabled)[0];
		}
	});

	return (
		<div
			className={`${css(styleSheet.container, ...styles)} slot-machine-achievement-configuration-table ${
				className || ''
			}`}
		>
			{achievements.isLoaded
				? achievements.fetchResults?.map(x => (
						<AchievementConfigurationTableRow achievement={x} achievements={achievements} key={x.type} />
					))
				: null}
		</div>
	);
});
