import * as Api from '@ViewModels';
import { IFormFieldOption } from '../form';
import { ILeadFollowUp } from '../queue';

export interface IInteractionOutcome {
	description: string;
	hexColor: string;
	name: string;
}

export interface ILeadInteraction {
	contactIds: string[];
	date: string;
	outcome: IInteractionOutcome;
	customProperties?: Record<string, object>;
}

export interface ILeadStatusFlag {
	description?: string;
	hexColor: string;
	name: string;
}

export interface IBaseLeadView {
	company?: Api.ICompany;
	decisionMakersContactIds?: string[];
}

export interface IPendingOutcome {
	phoneCall?: Partial<Api.IPhoneCall>;
}

export interface IDeal extends Api.IBaseResourceModel {
	amount?: number;
	companyId?: string;
	contactIds?: string[];
	description?: string;
	isOpen?: boolean;
	name?: string;
	ownerId?: string;
	stage?: string;
}

export interface IPreviousDeal {
	description?: string;
	lostReason?: string;
	date?: string;
	name?: string;
}

export interface IIntegrationPropertyItem extends Api.IBaseApiModel {
	addAsTag: boolean;
	addToForm: boolean;
	fieldType: string;
	isRequired: boolean;
	label: string;
	name: string;
}

export interface IIntegrationPipelineItem extends Api.IBaseApiModel {
	label: string;
}

export interface IIntegrationPropertyConfig {
	dealPipelineId?: string;
	demoDatePropertyName?: string;
	demoPerformerPropertyName?: string;
	enableFindDemoSpot?: boolean;
	enableLeadPools?: boolean;
	id?: string;
	lastModifiedDate?: string;
	leadPoolPropertyName?: string;
	pipelines?: IIntegrationPipelineItem[];
	properties?: IIntegrationPropertyItem[];
	provider?: 'HubSpot';
}

export interface ILeadView extends IBaseLeadView {
	abbreviatedTimeZone?: string;
	attributesForm?: Api.IFormResponse;
	customProperties?: { decisionMaker: string };
	executiveSummary?: Api.INote;
	followUp?: ILeadFollowUp;
	lastDeal?: IDeal;
	nextMeeting?: Api.IScheduledMeeting;
	nextMeetingFormattedString?: string;
	pendingOutcome?: IPendingOutcome;
	previousDeals?: IPreviousDeal[];
	providerName?: string;
	providerUrl?: string;
	requiredActivity?: SalesActivity;
	statusFlags?: ILeadStatusFlag[];
	suggestedTelephonyConfiguration?: Api.ITelephonyConfiguration;
}

export enum SalesActivity {
	Unknown = 'Unknown',
	Prospecting = 'Prospecting',
	MeetingConfirmation = 'MeetingConfirmation',
	MeetingReschedule = 'MeetingReschedule',
}

export interface ILeadSearchView extends IBaseLeadView {
	contactReferences?: Api.IContact[];
	lastInteraction?: ILeadInteraction;
	followUp?: ILeadFollowUp;
	lastDeal?: IDeal;
	nextMeeting?: Api.IScheduledMeeting;
	interactionPhoneCall?: Api.IPhoneCall;
}

export interface IPhoneCallLogView extends ILeadSearchView {
	phoneCall: Api.IPhoneCall;
}

export interface ILeadServedOutcomeView extends IBaseLeadView {
	id?: string;
	creator: Api.IUserReference;
	contact?: Api.IContact;
	phoneCall?: Api.IPhoneCall;
	interaction?: ILeadInteraction;
	followUp?: ILeadFollowUp;
	lastDeal?: IDeal;
	nextMeeting?: Api.IScheduledMeeting;
}

export type ILeadsSearchFilter = Api.IFilterCriteria<Api.CompanySearchRequestProperty>;

export interface ILeadsSearchRequest {
	/** Search parameters that will be and'd together */
	searches?: Api.ICompaniesSearchFilter[];
}

export interface IFollowUp {
	id?: string;
	defaultValueId: string;
	options?: Api.IFormField<IFormFieldOption>[];
	isDisabled?: boolean;
	selectedDate?: string;
}

export enum LeadAction {
	AddNote = 'addNote',
	Next = 'next',
	Skip = 'skip',
}

export interface INearbyClients {
	total?: number;
	groupings?: INearbyClientGrouping[];
}

export interface INearbyClientGrouping {
	label?: string;
	total?: number;
}
