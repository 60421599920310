import { observer } from 'mobx-react';
import * as React from 'react';
import { FC, createContext, useEffect, useState } from 'react';
import { EmailMessageViewModel, ISendEmailResponse } from '../../extViewmodels';
import { Noop } from '../../extViewmodels/Utils';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { useLambda } from '../../models/hooks/useLambda';
import { useModal } from '../../models/hooks/useModal';
import { EmailEditor, ISelectedEmailRecipient } from '../components/EmailEditor';
import { useQueue } from '../hooks/queue';
import { ApiClient } from '../services/api';

export interface IEmailEditorContext {
	selectedEmailRecipient: ISelectedEmailRecipient;
	setIsOpen: (isOpen: boolean) => () => void;
	setSelectedEmailRecipient: React.Dispatch<React.SetStateAction<ISelectedEmailRecipient>>;
	setSelectedEmailRecipientLambda: (newValue: ISelectedEmailRecipient) => () => void;
}

// @ts-ignore
export const EmailEditorContext = createContext<IEmailEditorContext>(null);

export const EmailEditorProvider: FC = observer(({ children }) => {
	const userSession = useUserSession();
	const queue = useQueue();
	const [selectedEmailRecipient, setSelectedEmailRecipient, setSelectedEmailRecipientLambda] =
		// @ts-ignore
		useLambda<ISelectedEmailRecipient>(null);
	const [emailMessage, setEmailMessage] = useState(null);
	const emailModal = useModal(false, Noop, [emailMessage, selectedEmailRecipient], false, true);

	const onEmailSent = async (response: ISendEmailResponse) => {
		// @ts-ignore
		const api = new ApiClient(userSession);
		// @ts-ignore
		await api.saveEmailOutcome(queue.companyId, {
			bulkEmailMessageId: response.id,
			leadServedSource: queue.leadServedSource,
		});

		queue.hasEmailed = true;
	};

	useEffect(() => {
		// @ts-ignore
		setSelectedEmailRecipient(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue?.companyId]);

	useEffect(() => {
		if (selectedEmailRecipient?.contact?.id) {
			const email = new EmailMessageViewModel<File>(userSession, false);
			// @ts-ignore
			setEmailMessage(email);
		} else {
			setEmailMessage(null);
		}
	}, [selectedEmailRecipient, userSession]);

	useEffect(() => {
		if (emailMessage) {
			emailModal.setIsOpen(true)();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailMessage]);

	if (!userSession.isAuthenticated) {
		return <div>{children}</div>;
	}

	const onAutomationStartSuccess = () => {
		queue.reloadLeadWithoutReset();
		setEmailMessage(null);
	};

	return (
		<EmailEditorContext.Provider
			value={{
				selectedEmailRecipient,
				setIsOpen: emailModal.setIsOpen,
				setSelectedEmailRecipient,
				setSelectedEmailRecipientLambda,
			}}
		>
			{children}
			<EmailEditor
				// @ts-ignore
				emailMessage={emailMessage}
				onAutomationStartSuccess={onAutomationStartSuccess}
				onSend={onEmailSent}
				selectedRecipient={selectedEmailRecipient}
				{...emailModal}
			/>
		</EmailEditorContext.Provider>
	);
});

export const useEmail = () => React.useContext<IEmailEditorContext>(EmailEditorContext);
