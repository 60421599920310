import { ViewportContext } from '../../hooks/useViewport';
import { ILeaderboard, ILeaderboardUser } from '../../viewModels/gamification';
import { LeaderboardList } from './components/LeaderboardList';
import { ListColumnNav } from './components/ListColumnNav';
import { SortOptions } from './components/ListHeader';
import { Top3 } from './components/Top3';
import { TotalScores } from './components/TotalScores';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';

export interface ILeaderBoardProps {
	asc?: boolean;
	renderEmptyState?: string | JSX.Element;
	renderFilter: JSX.Element;
	setAsc?(asc: boolean): void;
	setSortBy?(sort: SortOptions): void;
	sortBy?: SortOptions;
	top10: ILeaderboardUser[];
	totalScores: ILeaderboard;
}

export const LeaderboardScore: React.FC<ILeaderBoardProps> = ({
	asc,
	renderEmptyState,
	renderFilter,
	setAsc,
	setSortBy,
	sortBy,
	top10,
	totalScores,
}) => {
	const [selected, setSelected] = useState<SortOptions>(SortOptions.Demos);
	const [data, setData] = useState<{
		top10: ILeaderboardUser[];
		totalScores: ILeaderboard;
	}>({
		top10: [],
		// @ts-ignore
		totalScores: null,
	});

	useEffect(() => {
		if (top10 && totalScores) {
			setData({
				top10,
				totalScores,
			});
		}
	}, [top10, totalScores]);

	// @ts-ignore
	const { isBelowSmall } = useContext(ViewportContext);

	return (
		<div className={css(styleSheet.leaderBoardContainer)}>
			{renderFilter}
			{isBelowSmall && <ListColumnNav onSelect={setSelected} />}
			{renderEmptyState ? (
				renderEmptyState
			) : (
				<>
					<TotalScores selected={selected} totals={data.totalScores} />
					<Top3 asc={asc} top10={data.top10} />
					<LeaderboardList
						selected={selected}
						top10={data.top10}
						sortBy={sortBy}
						setSortBy={setSortBy}
						asc={asc}
						setAsc={setAsc}
					/>
				</>
			)}
		</div>
	);
};
