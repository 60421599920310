import { IPhoneCall, IPhoneCallCategory } from '../../../../extViewmodels';
import { usePhoneCallCategory } from '../../../entities/PhoneCall/usePhoneCallCategory';
import { CategoryListBuilder } from '../../CategoryListBuilder';
import { useTagList } from '../../LeadRuleModal/utils';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import { useEffect, useState } from 'react';
import * as React from 'react';

interface IProps {
	phoneCallCategories: IPhoneCallCategory[];
	phoneCallId: string;
}

export const CallDetailsCategory: React.FC<IProps> = ({ phoneCallCategories, phoneCallId }) => {
	const [categories, addCategory, removeCategory, setCategories] = useTagList([]);
	const [categoryObjects, setCategoryObjects] = useState(null);

	const phoneCallCategory = usePhoneCallCategory<IPhoneCall>();

	useEffect(() => {
		if (phoneCallCategories) {
			// @ts-ignore
			setCategoryObjects(phoneCallCategories);
			setCategories(phoneCallCategories?.map(c => c.name));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneCallCategories]);

	useEffect(() => {
		if (phoneCallCategory?.data?.categories) {
			// @ts-ignore
			setCategoryObjects(phoneCallCategory?.data?.categories);
		}
	}, [phoneCallCategory.data]);

	const handleAddCategory = (category: string) => {
		addCategory(category);
		phoneCallCategory.addToPhoneCall(phoneCallId, category);
	};

	const handleRemoveCategory = (category: string) => {
		removeCategory(category);
		phoneCallCategory.removeFromPhoneCall(phoneCallId, category);
	};

	return (
		<div>
			<div className={css(styleSheet.sectionTitle)}>Call Categories</div>
			<CategoryListBuilder
				// @ts-ignore
				categoryObjects={categoryObjects}
				categories={categories}
				onAdd={handleAddCategory}
				onRemove={handleRemoveCategory}
			/>
		</div>
	);
};
