import { TranscriptionStatusFilter } from '../../../../../extViewmodels';
import { ISelectOption, Select } from '../../../../../web/components/Select';
import { styleSheet } from './styles';
import * as React from 'react';

interface IProps {
	disabled?: boolean;
	onChange?: (item: ISelectOption<TranscriptionStatusFilter>) => void;
	selected?: ISelectOption<string>;
}

const options: ISelectOption<TranscriptionStatusFilter>[] = [
	{
		// @ts-ignore
		dataContext: null,
		id: 'all',
		text: 'All Statuses',
	},
	{
		dataContext: TranscriptionStatusFilter.TranscriptionComplete,
		id: TranscriptionStatusFilter.TranscriptionComplete,
		text: 'Transcribed',
	},
];

export const TranscriptionStatusSelect: React.FC<IProps> = ({ disabled, onChange, selected }) => {
	const onClick = (option: ISelectOption<TranscriptionStatusFilter>) => {
		// @ts-ignore
		onChange(option);
	};

	const selectedOption = options.find(x => x.dataContext === selected?.dataContext ?? null) || options[0];

	return (
		<Select
			dropdownStyles={[styleSheet.selectDropdown]}
			onOptionClick={onClick}
			options={options}
			disabled={disabled}
			selectedOption={selectedOption}
			styles={[styleSheet.select]}
		/>
	);
};
