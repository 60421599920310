import { CssSelectors } from '../../../../web/styles/styles';
import { brandPrimaryHover } from '../../../styles/colors';
import { CSSProperties, StyleSheet } from 'aphrodite';

const statusOption: CSSProperties = {
	transition: 'all .2s ease-in-out',
};

export const styleSheet = StyleSheet.create({
	backButton: {
		// '::before': {
		// 	borderBottom: `6px solid transparent`,
		// 	borderRight: `12px solid ${ brandSecondary }`,
		// 	borderTop: `6px solid transparent`,
		// 	content: '" "',
		// 	position: 'absolute',
		// 	right: '100%',
		// 	top: 4,
		// },
		color: brandPrimaryHover,
		margin: '10px 0 0 30px',
		paddingLeft: 4,
		position: 'relative',
		...CssSelectors.allChildren(
			{
				position: 'absolute',
				right: '100%',
				top: 4,
			},
			'.back-button-arrow'
		),
	},
	hexButton: {
		margin: '0 8px',
		...CssSelectors.allDescendants(
			{
				fontSize: '16px',
				lineHeight: '1.1em',
			},
			'.text'
		),
		...CssSelectors.allDescendants(
			{
				minHeight: 50,
			},
			'.no-wrap'
		),
	},
	label: {
		color: '#aaa',
		fontSize: '11px',
		textAlign: 'center',
		textTransform: 'uppercase',
	},
	statusOption: {
		...CssSelectors.allDescendants(statusOption, '*'),
	},
	statusOptions: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		paddingTop: 20,
	},
	statusOptionsContainer: {
		paddingBottom: 20,
	},
});
