import { UserSessionContext } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { AdminSettingsCards, ISettingsCard, SettingsCardType, UserSettingsCards } from '../../models/setting';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
}

const SettingsCardBase: React.FC<{ card: ISettingsCard } & RouteComponentProps> = ({ card }) => {
	if (card.href) {
		return (
			<a
				className={css(styleSheet.settingsCard, styleSheet[card.settingType])}
				href={card.href}
				key={card.settingType}
				target='_blank'
				rel='noreferrer'
			>
				{card.text}
			</a>
		);
	}

	return (
		<Link
			className={css(styleSheet.settingsCard, styleSheet[card.settingType])}
			key={card.settingType}
			to={card.to || `/settings/${card.settingType}`}
		>
			{card.text}
		</Link>
	);
};

const SettingsCard = withRouter(SettingsCardBase);

const getCards = (userSession: UserSessionContext) => {
	let cards = userSession.account.isAdmin ? AdminSettingsCards : UserSettingsCards;
	if (!userSession?.account?.features?.aida?.achievementsEnabled) {
		cards = cards.filter(x => {
			return (
				x.settingType !== SettingsCardType.SlotMachines &&
				x.settingType !== SettingsCardType.SlotMachinePrizes &&
				x.settingType !== SettingsCardType.PrizesLog
			);
		});
	}

	return cards;
};

export const SettingsCards: React.FC<IProps> = ({ className = '' }) => {
	const userSession = useUserSession();
	const cards = React.useRef<ISettingsCard[]>(getCards(userSession)).current;
	return (
		<div className={`${css(styleSheet.settingsCardsContainer)} ${className}`}>
			{cards.map(card => (
				<SettingsCard card={card} key={card.settingType} />
			))}
		</div>
	);
};
