import { IRange, IUser } from '../../../../../extViewmodels';
import { LoadingSpinner } from '../../../../../web/components/LoadingSpinner';
import { Toggle } from '../../../../../web/components/Toggle';
import { ExportIcon } from '../../../../../web/components/svgs/icons/ExportIcon';
import { TransparentButton } from '../../../../components/TransparentButton';
import { Fill } from '../../../../components/generics/Fill';
import { Group } from '../../../../components/generics/Group';
import { DatePicker } from '../../../../components/leadView/components/DatePicker';
import { UserSelect } from '../../../../components/leadView/components/UserSelect';
import { IPrizeLogFilter } from '../../../../models/slotMachines';
import { grayIconFill } from '../../../../styles/colors';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';
import { useState } from 'react';

interface IProps {
	exportingCsv?: boolean;
	isLoading?: boolean;
	onChange?: (filter: IPrizeLogFilter) => void;
	onExport?: () => void;
}

export const ListHeader: React.FC<IProps> = ({ exportingCsv, onChange, onExport }) => {
	const [currentFilter, setCurrentFilter] = useState<IPrizeLogFilter>({});

	function handleChange<T>(key: keyof IPrizeLogFilter) {
		return (value: T) => {
			setCurrentFilter({ ...currentFilter, [key]: value });
			onChange?.({ ...currentFilter, [key]: value });
		};
	}

	const handleTogglePrizePaid = (checked: boolean) => {
		setCurrentFilter({ ...currentFilter, prizePaid: checked });
		onChange?.({ ...currentFilter, prizePaid: checked });
	};

	return (
		<Group className={css(styleSheet.filter)} vCentered={true} hCentered={true} spacingSize={20}>
			<UserSelect onChange={handleChange<IUser>('user')} />
			{/* @ts-ignore */}
			<DatePicker onChange={handleChange<IRange<Date>>('date')} />
			<label className={css(styleSheet.toggleLabel)}>
				<Toggle
					id='toggle'
					isOn={currentFilter?.prizePaid}
					onToggleCheckChanged={handleTogglePrizePaid}
					uncheckedColor={grayIconFill}
				/>
				&nbsp;<span className={css(styleSheet.regularText)}>Show unpaid only</span>
			</label>
			<Fill />
			<TransparentButton onClick={onExport} disabled={exportingCsv}>
				<Group fullWidth={false}>
					{exportingCsv ? <LoadingSpinner type='extra-small' /> : <ExportIcon />}
					<span className={css(styleSheet.regularText)}>Export to CSV</span>
				</Group>
			</TransparentButton>
		</Group>
	);
};
