import { Layouts } from '../../../../web/styles/styles';
import { white } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		background: white,
		borderRadius: 5,
		display: 'flex',
		height: 39,
		justifyContent: 'space-between',
		padding: '0 6px',
		width: 160,
	},
	datePickerContainer: {
		...Layouts.horizontalStack(20),
	},
});
