import { useLambda } from '../../../models/hooks/useLambda';
import { TinyPopover } from '../../../web/components/TinyPopover';
import { TransparentButton } from '../TransparentButton';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { useEffect, useState } from 'react';
import * as React from 'react';

export interface IFabMenuItem {
	dataContext: string;
	icon: JSX.Element;
	tooltip: string;
}

interface IProps {
	className?: string;
	menuItems: IFabMenuItem[];
	onMenuItemClick: (menuItem: IFabMenuItem) => void;
}

interface IFabMenuItemProps {
	className?: string;
	item: IFabMenuItem;
	onClick: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
}

const FabMenuItem: React.FC<IFabMenuItemProps> = ({ className, item, onClick, onMouseEnter, onMouseLeave }) => (
	<div
		className={`${css(styleSheet.fabMenuItem)} ${className}`}
		onClick={onClick}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
	>
		<div className={css(styleSheet.itemIcon)}>{item.icon}</div>
		<div className={`fab-tooltip ${css(styleSheet.itemTooltip)}`}>{item.tooltip}</div>
	</div>
);

export const Fab: React.FC<IProps> = ({ className = '', menuItems, onMenuItemClick }) => {
	const [isHoveringTrigger, setIsHoveringTrigger, setIsHoveringTriggerLambda] = useLambda(false);
	const [isHoveringMenu, setIsHoveringMenu, setIsHoveringMenuLambda] = useLambda(false);
	const [anchor, setAnchor] = useState(menuItems[0]);
	const [items, setItems] = useState(menuItems.slice(1));

	useEffect(() => {
		setAnchor(menuItems[0]);
		setItems(menuItems.slice(1));
	}, [menuItems]);

	const onItemClick = (menuItem: IFabMenuItem) => () => {
		setIsHoveringTrigger(false);
		setIsHoveringMenu(false);
		onMenuItemClick(menuItem);
	};

	const renderAnchor = () => {
		return (
			<FabMenuItem
				className={css(styleSheet.item, styleSheet.anchor)}
				item={anchor}
				onClick={onItemClick(anchor)}
				onMouseEnter={setIsHoveringTriggerLambda(true)}
				onMouseLeave={setIsHoveringTriggerLambda(false)}
			/>
		);
	};

	const renderMenuItems = () => {
		return items.map(item => {
			return (
				<FabMenuItem
					className={`menu-item ${css(styleSheet.item, styleSheet.menuItem)}`}
					item={item}
					key={item.dataContext}
					onClick={onItemClick(item)}
				/>
			);
		});
	};

	return (
		<TransparentButton className={`${css(styleSheet.anchorContainer)} ${className}`}>
			<TinyPopover align='center' anchor={renderAnchor()} isOpen={isHoveringTrigger || isHoveringMenu}>
				<div
					className={css(styleSheet.menuContainer)}
					onMouseLeave={setIsHoveringMenuLambda(false)}
					onMouseEnter={setIsHoveringMenuLambda(true)}
				>
					{renderMenuItems()}
				</div>
			</TinyPopover>
		</TransparentButton>
	);
};
