import { grayIconFill } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const ReportingIcon: React.FC<IProps> = ({ className = '', fill }) => {
	return (
		<SvgIcon className={`reporting-icon ${className || ''}`} height={24} width={19}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.068 18.96 7.01 5.816 4.692 12H0v-2h3.307L6.988.183l4.944 12.856 2.883-8.646L17.619 10H24v2h-7.619l-1.196-2.394-3.117 9.354Z'
				fill={fill || grayIconFill}
			/>
		</SvgIcon>
	);
};
