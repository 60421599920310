import { CssSelectors, FontFamilies } from '../../../../web/styles/styles';
import { brandPrimary, brandSecondary, grayDark, grayIconFill, mentionDark, white } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	actionsBar: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: 30,
		marginTop: 40,
	},
	callingContainer: {
		width: 140,
	},
	cardContainer: {
		background: white,
		borderRadius: 10,
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .1)',
		fontSize: 14,
		justifyContent: 'center',
		margin: '0 auto',
		marginBottom: 30,
		minHeight: 90,
		padding: 20,
		position: 'relative',
		width: '100%',
	},
	cardIsBlocked: {
		background: 'rgba(255, 255, 255, .6)',
		borderRadius: 10,
		height: '100%',
		left: 0,
		position: 'absolute',
		top: 0,
		width: '100%',
	},
	colorOption: {
		border: 'solid 2px transparent',
		borderRadius: 16,
		cursor: 'pointer',
		height: 16,
		width: 16,
	},
	colorOptionSelected: {
		borderColor: 'rgba(0, 0, 0, .4)',
	},
	colorOptionsWrapper: {
		display: 'flex',
	},
	connectionContainer: {
		width: 90,
	},
	container: {
		margin: '0 auto',
		width: '80%',
	},
	copyIcon: {
		cursor: 'pointer',
		marginLeft: 6,
	},
	dateContainer: {
		color: grayIconFill,
		fontSize: 12,
		textTransform: 'uppercase',
		width: 180,
	},
	editButton: {
		...CssSelectors.allDescendants(
			{
				marginTop: 5,
			},
			'svg'
		),
	},
	fixedSize: {
		width: 170,
	},
	flexContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	loadingContainer: {
		height: 24.5,
		width: '100%',
	},
	modal: {
		...CssSelectors.allDescendants(
			{
				border: 'none',
				minHeight: 'auto',
				padding: 0,
			},
			'.modal-overlay-content'
		),
	},
	modalContent: {
		padding: 15,
		...CssSelectors.allDescendants(
			{
				width: 232,
			},
			'.text-input'
		),
	},
	modalHeader: {
		alignItems: 'center',
		background: brandPrimary,
		borderTopLeftRadius: 3,
		borderTopRightRadius: 3,
		display: 'flex',
		justifyContent: 'space-between',
		padding: '6px 14px',
	},
	moreMenu: {
		border: 'none',
		position: 'absolute',
		right: 15,
		top: 15,
		width: 'auto',
	},
	moreMenuTrigger: {
		':hover': {
			...CssSelectors.allChildren(
				{
					fill: brandPrimary,
				},
				'svg path'
			),
		},
		height: 10,
		marginLeft: 10,
		padding: '0 5px',
		...CssSelectors.allChildren(
			{
				fill: brandSecondary,
			},
			'svg path'
		),
	},
	mrryContent: {
		...CssSelectors.allDescendants(
			{
				fontSize: 13,
				marginBottom: 10,
			},
			'p'
		),
	},
	numbersContainer: {
		padding: '20px 0',
	},
	phoneNumberContainer: {
		fontFamily: FontFamilies.bold,
		fontWeight: 800,
		width: 190,
	},
	placeholder: {
		textAlign: 'center',
	},
	placeholderImage: {
		marginTop: 50,
		width: 400,
	},
	priority: {
		'::before': {
			borderRight: 'solid 15px transparent',
			borderTop: `solid 24px ${mentionDark}`,
			content: "' '",
			height: 0,
			position: 'absolute',
			right: -15,
			top: 0,
			width: 0,
			zIndex: 0,
		},
		backgroundColor: mentionDark,
		borderTopLeftRadius: 10,
		height: 24,
		left: 0,
		position: 'absolute',
		top: 0,
		width: 'fit-content',
	},
	priorityLabel: {
		color: brandPrimary,
		fontFamily: FontFamilies.bold,
		fontSize: 13,
		fontWeight: 800,
		padding: '3px 6px 3px 9px',
		position: 'relative',
		zIndex: 1,
	},
	providerContainer: {
		color: grayIconFill,
		fontSize: 12,
		textTransform: 'uppercase',
	},
	searchBar: {
		margin: '40px auto',
		maxWidth: 640,
		...CssSelectors.allDescendants(
			{
				height: 47,
			},
			'.common-autocomplete-anchor'
		),
	},
	textInput: {
		padding: '6px 10px',
		width: 220,
		...CssSelectors.allDescendants(
			{
				fontSize: 12,
				width: '100%',
			},
			'input'
		),
	},
	title: {
		color: grayDark,
		fontSize: 12,
		textTransform: 'uppercase',
	},
	usage: {
		color: brandPrimary,
		fontFamily: FontFamilies.bold,
	},
});
