import { parse, stringify } from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

/**
 * Make the component reactive to a specific key in query string Manages the value in state automatically
 *
 * @param name Key in the query string
 * @param initialValue Initial value used ONLY if not already included in query string
 * @returns Name: key, parameter: value, updateParameter: update f(x)
 */
export const useQueryParameter = <T>(name: string, initialValue: T) => {
	const location = useLocation();
	const history = useHistory();
	const match = useRouteMatch();

	const getParameter = () => {
		const updatedSearch = parse(location.search);
		const updated = updatedSearch[name];
		return updated;
	};

	const [parameter, setParameter] = useState(getParameter() ?? initialValue);

	useEffect(() => {
		updateParameter(getParameter() ?? initialValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateParameter = (newValue: T) => {
		const currentSearch = parse(location.search);
		if (newValue !== null) {
			currentSearch[name] = newValue;
		}
		if (newValue === null) {
			delete currentSearch[name];
			setParameter(null);
		}
		history.push(`${match.path}?${stringify(currentSearch)}`);
	};

	// if router search changes, update value
	useEffect(() => {
		const updated = getParameter();
		if (updated) {
			setParameter(updated);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.search, setParameter]);

	return [name, parameter, updateParameter] as const;
};
