import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	viewBox?: string;
}

export const MinimizeIcon: React.FC<IProps> = props => {
	const { className, fillColor, viewBox } = props;
	return (
		<SvgIcon className={`minimize-icon ${className || ''}`} height={2} viewBox={viewBox} width={14}>
			<rect width='14' height='2' fill={fillColor} />
		</SvgIcon>
	);
};
