import { RadioButton } from '../../../../../web/components/RadioButton';
import { TurnItOnForOptions } from '../../../../viewModels/leadrules';
import { styleSheet } from './../../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	enabled: boolean;
	onChangeToEnable: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Everyone: React.FC<IProps> = ({ enabled, onChangeToEnable }) => {
	return (
		<div>
			<RadioButton checked={enabled} id={TurnItOnForOptions.Everyone} onChange={onChangeToEnable}>
				<span className={css(styleSheet.radioLabel)}>Everyone</span>
			</RadioButton>
		</div>
	);
};
