import { Noop } from '../../../../extViewmodels/Utils';
import { useLambda } from '../../../../models/hooks/useLambda';
import { BackArrowIcon } from '../../../../web/components/svgs/icons/BackArrowIcon';
import { useQueueLeadNote } from '../../../contexts/queueLeadNote';
import { useTelephony } from '../../../contexts/telephony';
import { useErrorMessages } from '../../../hooks';
import { useQueue } from '../../../hooks/queue';
import { FieldKey } from '../../../viewModels/form';
import { LeadAction } from '../../../viewModels/leads/interfaces';
import { IPhoneCallOutcome } from '../../../viewModels/phonecall';
import { HexButton } from '../../HexButton';
import { TransparentButton } from '../../TransparentButton';
import { BookedGraphic } from '../../svgs/BookedGraphic';
import { HookRejectedGraphic } from '../../svgs/HookRejectedGraphic';
import { QualifiedPitchGraphic } from '../../svgs/QualifiedPitchGraphic';
import { QuickPitchGraphic } from '../../svgs/QuickPitchGraphic';
import { StumpedGraphic } from '../../svgs/StumpedGraphic';
import { styleSheet } from './styles';
import { animated, config, useSpring } from '@react-spring/web';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	className?: string;
	onStatusOptionClicked?(outcome: IPhoneCallOutcome): void;
	outcomesToRender: IPhoneCallOutcome[];
}

const from = {
	opacity: 0,
};

const to = {
	opacity: 1,
};

const ConnectedOptionsBase: React.FC<IProps> = ({ className = '', onStatusOptionClicked, outcomesToRender }) => {
	const queue = useQueue();
	const { phoneCalls } = useTelephony();
	const errorMessages = useErrorMessages();
	const { setShowQueueLeadNote } = useQueueLeadNote();
	const [animateLion, , setAnimateLion] = useLambda(false);
	const [animateSailboat, , setAnimateSailboat] = useLambda(false);
	const [animateEightOfClubs, , setAnimateEightOfClubs] = useLambda(false);
	const [animateKingAndQueen, , setAnimateKingAndQueen] = useLambda(false);
	const [animateQuestion, , setAnimateQuestion] = useLambda(false);
	const spring = useSpring({ config: config.gentle, delay: 150, from, to });

	const getOutcomeGraphic = (outcome: IPhoneCallOutcome) => {
		switch (outcome.name) {
			case FieldKey.Booked:
			case FieldKey.NewDemo:
				return <BookedGraphic animate={animateLion} />;
			case FieldKey.HookRejected:
				return <HookRejectedGraphic animate={animateSailboat} />;
			case FieldKey.QuickPitch:
				return <QuickPitchGraphic animate={animateEightOfClubs} />;
			case FieldKey.QualityPitch:
			case FieldKey.QualifiedPitch:
				return <QualifiedPitchGraphic animate={animateKingAndQueen} />;
			case FieldKey.Stumped:
				return <StumpedGraphic animate={animateQuestion} />;
			default:
				return null;
		}
	};

	const onBackClick = () => {
		// @ts-ignore
		queue.setAction(null);
	};

	const onStatusOptionClick = (option: IPhoneCallOutcome) => () => {
		if (option.id === queue.badLead?.id) {
			queue.setAction(LeadAction.Skip);
			// @ts-ignore
			phoneCalls.currentCall?.setOutcome(null);
			queue.lead.clearFollowUpOptions();
			setShowQueueLeadNote(true);
		} else {
			onStatusOptionClicked?.(option);
			phoneCalls.currentCall?.setOutcome(option);
			// @ts-ignore
			queue.lead.getFollowUp(option).catch(err => errorMessages.pushApiError(err));
		}
	};

	const renderOptions = () => {
		return outcomesToRender.map(o => {
			let onMouseEnter = Noop;
			let onMouseLeave = Noop;

			switch (o.name) {
				case FieldKey.Booked:
				case FieldKey.NewDemo:
					onMouseEnter = setAnimateLion(true);
					onMouseLeave = setAnimateLion(false);
					break;
				case FieldKey.HookRejected:
					onMouseEnter = setAnimateSailboat(true);
					onMouseLeave = setAnimateSailboat(false);
					break;
				case FieldKey.QuickPitch:
					onMouseEnter = setAnimateEightOfClubs(true);
					onMouseLeave = setAnimateEightOfClubs(false);
					break;
				case FieldKey.QualifiedPitch:
				case FieldKey.QualityPitch:
					onMouseEnter = setAnimateKingAndQueen(true);
					onMouseLeave = setAnimateKingAndQueen(false);
					break;
				case FieldKey.Stumped:
					onMouseEnter = setAnimateQuestion(true);
					onMouseLeave = setAnimateQuestion(false);
					break;
				default:
					break;
			}

			return (
				<HexButton
					className={css(styleSheet.hexButton)}
					disabled={!phoneCalls.currentCall}
					key={o.id}
					// @ts-ignore
					graphic={getOutcomeGraphic(o)}
					onClick={onStatusOptionClick(o)}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					points={o.points}
					selected={o.id === phoneCalls.currentCall?.outcome?.id}
					text={o.label}
				/>
			);
		});
	};

	return (
		<animated.div className={className} style={spring}>
			<div className={css(styleSheet.statusOptionsContainer)}>
				<TransparentButton className={css(styleSheet.backButton)} onClick={onBackClick}>
					Back
					<BackArrowIcon className='back-button-arrow' />
				</TransparentButton>
				<div className={css(styleSheet.label)}>Select a Status:</div>
				<div className={css(styleSheet.statusOptions)}>{renderOptions()}</div>
			</div>
		</animated.div>
	);
};

export const ConnectedOptions = observer(ConnectedOptionsBase);
