import { useEffect, useState } from 'react';

export const useTimeout = () => {
	const [config, setConfig] = useState({ callback: null, delay: null });
	useEffect(() => {
		if (config.callback) {
			const timeout = setTimeout(config.callback, config.delay || 0);
			return () => clearTimeout(timeout);
		}
	}, [config.callback, config.delay]);
	const handleSetTimeout = (callback: () => void, delay?: number) => {
		// @ts-ignore
		// @ts-ignore
		setConfig({ callback, delay });
	};
	return handleSetTimeout;
};
