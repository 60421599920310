import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import Waypoint from 'react-waypoint';
import { useEventLogging } from '../../../../models/Logging';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { prizeLogFilterToGameTokenFilter } from '../../../entities/Leaderboard/usePrizes';
import { useErrorMessages, useToaster } from '../../../hooks';
import { IPrizeLogFilter } from '../../../models/slotMachines';
import { BasicPage } from '../../BasicPage';
import { ListHeader } from './components/ListHeader';
import { ListItemsContainer } from './components/ListItemsContainer';
import { usePrizeLog } from './hooks';
import { styleSheet } from './styles';

// TODO: switch to using a page collection controller vm
export const PrizesLog: React.FC = observer(() => {
	const userSession = useUserSession();
	const toaster = useToaster();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('PrizesLog');

	const [loading, setLoading] = React.useState<boolean>(true);
	// @ts-ignore
	const [filterState, setFilter] = useState<IPrizeLogFilter>(null);

	const { exporting, exportToCsv, getItems, items, updatePaidStatus } = usePrizeLog(userSession);

	const getMoreItems = React.useCallback(async () => {
		try {
			await getItems(
				{
					pageSize: 25,
					sort: 'desc',
				},
				prizeLogFilterToGameTokenFilter(filterState)
			);
		} catch (error) {
			// @ts-ignore
			logApiError('LoadPrizes-Error', error);
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterState]);

	React.useEffect(() => {
		getMoreItems()?.then(() => {
			setLoading(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterState]);

	const handleFilterChange = (filter: IPrizeLogFilter) => {
		setFilter(filter);
	};

	const handleExportRequest = () => {
		exportToCsv(prizeLogFilterToGameTokenFilter(filterState))
			.then(() => {
				// @ts-ignore
				toaster.push({
					message: 'CSV exported successfully, you should receive an email with the file.',
					type: 'successMessage',
				});
			})
			.catch(error => {
				logApiError('CsvExport-Error', error);
				// @ts-ignore
				toaster.push({
					message: 'Error when trying to export CSV, please try again.',
					type: 'errorMessage',
				});
			});
	};

	const setGameTokenAsPaid = (gameTokenId: string) => {
		updatePaidStatus(gameTokenId)
			.then(() => {
				// @ts-ignore
				toaster.push({
					message: 'Paid status successfully updated.',
					type: 'successMessage',
				});
			})
			.catch(error => {
				logApiError('UpdatePaid-Error', error);
				// @ts-ignore
				toaster.push({
					message: 'Error when trying to update paid status.',
					type: 'errorMessage',
				});
			});
	};

	return (
		<BasicPage className={`${css(styleSheet.container)} slot-machine-prizes-log`} title='Prizes Log'>
			<ListHeader
				exportingCsv={exporting}
				isLoading={loading}
				onChange={handleFilterChange}
				onExport={handleExportRequest}
			/>
			<ListItemsContainer isLoading={loading} items={items} setGameTokenAsPaid={setGameTokenAsPaid} />
			<Waypoint bottomOffset='-200px' onEnter={getMoreItems} />
		</BasicPage>
	);
});
