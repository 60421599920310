import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import { grayIconFill } from '../../../../styles/colors';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const TrophyIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`trophy-icon ${className || ''}`} height={20} width={21}>
			<path
				fill={fillColor || grayIconFill}
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.9987 5.586L17.9987 6.586V3H18.9987V5.586ZM15.9987 8C15.9987 10.154 13.2487 12 10.9987 12C8.74869 12 5.99869 10.154 5.99869 8V2H15.9987V8ZM12.9987 18H8.99869V17H12.9987V18ZM2.99869 3H3.99869V6.586L2.99869 5.586V3ZM19.9987 1H17.7207C17.3737 0.405 16.7357 0 15.9987 0H5.99869C5.26169 0 4.62369 0.405 4.27669 1H1.99869C1.44669 1 0.998688 1.448 0.998688 2V6C0.998688 6.265 1.10469 6.52 1.29169 6.707L4.29169 9.707L4.32169 9.677C5.17369 11.896 7.60669 13.542 9.99869 13.914V15H7.99869C7.44669 15 6.99869 15.448 6.99869 16V18H5.99869V20H15.9987V18H14.9987V16C14.9987 15.448 14.5507 15 13.9987 15H11.9987V13.914C14.3907 13.542 16.8237 11.896 17.6757 9.677L17.7057 9.707L20.7057 6.707C20.8937 6.52 20.9987 6.265 20.9987 6V2C20.9987 1.448 20.5507 1 19.9987 1V1Z'
			/>
		</SvgIcon>
	);
};
