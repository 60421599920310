import { getDisplayName } from '../../../models/UiUtils';
import { LeadCard } from '../../components/LeadCard';
import { FullDateIndicator } from '../../components/leadView/LeadViewRowElement/components/FullDateIndicator';
import { LeadViewWrapper } from '../../components/leadView/LeadViewWrapper';
import { OutcomeInfo } from '../../components/leadView/components/OutcomeInfo';
import { RecordingIndicator } from '../../components/leadView/components/RecordingIndicator';
import { TextColumn } from '../../components/leadView/components/TextColumn';
import { IDemosFilterParams, useDemos } from '../../entities/Demos/useDemos';
import { IPhoneCallLogView } from '../../viewModels/leads/interfaces';
import { LeadServedSource } from '../../viewModels/queue';
import { Filters } from './Filters';
import { useState } from 'react';
import * as React from 'react';

export const DemosContainer: React.FC = () => {
	const { data, empty, get, status } = useDemos();

	// @ts-ignore
	const [filter, setFilter] = useState<IDemosFilterParams>(null);

	const handleGet = (shouldNotTriggerLoading: boolean) => get({ filter, shouldNotTriggerLoading });

	const handleFilterChange = (output: IDemosFilterParams) => {
		setFilter(output);
		get({ filter: output, shouldReset: true });
	};

	const renderFilter = <Filters filter={filter} onChange={handleFilterChange} />;

	const handleGetSource = (item: IPhoneCallLogView) => {
		const companyId = item?.company?.id;
		const { origin, pathname } = location;
		if (companyId) {
			return `${origin + pathname}#/queue?from=${LeadServedSource.AppointmentConfirmation}&companyId=${companyId}`;
		}
		return null;
	};

	const cardViewComponent = (item: IPhoneCallLogView) => (
		<LeadCard source={LeadServedSource.AppointmentConfirmation} lead={item} />
	);

	return (
		<LeadViewWrapper<IPhoneCallLogView>
			title='Demos'
			// @ts-ignore
			data={data}
			isLoading={status === 'working'}
			isEmpty={empty}
			filterComponent={renderFilter}
			// @ts-ignore
			getSource={handleGetSource}
			onLoadMore={handleGet}
			cardViewComponent={cardViewComponent}
			columns={[
				{
					Component: ({ nextMeeting }) => {
						// @ts-ignore
						return <FullDateIndicator dateValue={nextMeeting?.startDate} />;
					},
					label: 'demo perform date',
					width: 340,
				},
				{
					Component: ({ company }) => {
						const companyName = company?.companyName || 'Unknown';
						// @ts-ignore
						const phoneNumber = company?.phoneNumbers[0]?.metadata?.standard || 'Unknown';
						return <TextColumn text={companyName} subtext={phoneNumber} />;
					},
					label: 'company info',
					width: 300,
				},
				{
					Component: ({ lastDeal }) => {
						// @ts-ignore
						const creator = getDisplayName(lastDeal?.creator);
						return <TextColumn text={creator} isSmall={true} />;
					},
					label: 'sdr',
					width: 200,
				},
				{
					Component: ({ lastInteraction }) => {
						return (
							<OutcomeInfo
								// @ts-ignore
								name={lastInteraction?.outcome?.name}
								color={lastInteraction?.outcome?.hexColor}
								// @ts-ignore
								date={lastInteraction?.date}
							/>
						);
					},
					label: 'last call',
					width: 200,
				},
				{
					Component: ({ phoneCall }) => {
						return (
							<RecordingIndicator
								// @ts-ignore
								creationDate={phoneCall?.creationDate}
								// @ts-ignore
								creatorName={getDisplayName(phoneCall?.creator)}
								// @ts-ignore
								phoneNumber={phoneCall?.phoneNumber?.standard}
								// @ts-ignore
								id={phoneCall?.id}
								// @ts-ignore
								publicUrl={phoneCall?.recording?.publicUrl}
							/>
						);
					},
					label: 'recording',
					width: 100,
				},
			]}
		/>
	);
};
