import { StyleSheet } from 'aphrodite';
import { CssSelectors, FontFamilies, Layouts } from '../../../../web/styles/styles';
import { brandPrimary, brandSecondary, success } from '../../../styles/colors';
import BoozeCruiseImageUrl from './boozeCruiseSpinRowBackground.png';
import GoldRushImageUrl from './goldRushSpinRowBackground.png';
import HighScoreImageUrl from './highScoreSpinRowBackground.png';

const rowHeight = 80;
export const styleSheet = StyleSheet.create({
	boozeCruiseRow: {
		backgroundImage: `url(${BoozeCruiseImageUrl})`,
		backgroundPosition: 'left',
		backgroundRepeat: 'no-repeat',
	},
	container: {
		boxSizing: 'border-box',
		padding: '0 12%',
		position: 'relative',
	},
	contentColumn: {
		...Layouts.verticalStack(20),
		marginTop: 30,
	},
	goldRushRow: {
		backgroundImage: `url(${GoldRushImageUrl})`,
		backgroundPosition: 'left',
		backgroundRepeat: 'no-repeat',
	},
	highScoreRow: {
		backgroundImage: `url(${HighScoreImageUrl})`,
		backgroundPosition: 'left',
		backgroundRepeat: 'no-repeat',
	},
	historyRow: {
		background: brandPrimary,
		boxSizing: 'border-box',
		padding: '0 30px',
		...CssSelectors.allChildren(
			{
				marginLeft: 40,
			},
			':not(:first-child)'
		),
	},
	historyRowFieldWithLabel: {
		alignItems: 'center',
		display: 'flex',
	},
	historyRowLabel: {
		color: brandSecondary,
		fontSize: 11,
		letterSpacing: '1.1px',
		marginRight: 10,
		textTransform: 'uppercase',
	},
	historyRowPrize: {
		color: success,
		fontFamily: FontFamilies.bold,
		fontSize: 18,
		width: 150,
	},
	historyRowSymbol: {
		height: 20,
		width: 'auto',
	},
	historyRowSymbols: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-evenly',
		width: 70,
	},
	prizeRow: {
		background: brandPrimary,
	},
	row: {
		alignItems: 'center',
		borderRadius: 10,
		color: '#fff',
		display: 'flex',
		fontSize: 16,
		height: rowHeight,
		width: '100%',
	},
	spinRow: {
		background: brandSecondary,
		boxSizing: 'border-box',
		cursor: 'auto',
		justifyContent: 'flex-end',
		padding: '0 13%',
		transition: 'transform .2s ease-in-out',
	},
	spinRowHover: {
		':hover': {
			boxShadow: `0 5px 14px 4px rgba(0, 0, 0, 0.2)`,
			transform: 'scale(1.02)',
		},
		cursor: 'pointer',
	},
});
