import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Modal } from '../../../../web/components/Modal';
import { SlotMachineViewModel } from '../../../viewModels/slotMachines';
import { SlotMachineSymbolGroup } from '../../svgs/slotMachines/SlotMachineSymbolGroup';
import {
	SlotMachineEndGameModalBackground,
	SlotMachineEndGameModalMessage,
	SlotMachineEndGameModalTitle,
} from './presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	isOpen: boolean;
	onRenderBackground?(outcome: Api.ISlotMachineSpin, style?: StyleDeclarationValue): React.ReactNode;
	onRenderTitle?(style?: StyleDeclarationValue): React.ReactNode;
	onRequestClose(): void;
	slotMachine: SlotMachineViewModel;
	styles?: StyleDeclarationValue[];
}

export const SlotMachineEndGameModal: React.FC<IProps> = observer(props => {
	const { className, isOpen, onRenderBackground, onRenderTitle, onRequestClose, slotMachine, styles = [] } = props;
	const outcome = slotMachine.spinOutcome;
	const win = outcome?.gameOutcomeResultType === Api.GameOutcomeResultType.Win || false;

	const onCloseClicked = React.useCallback(() => {
		slotMachine.nextToken();
		onRequestClose();
	}, [slotMachine, onRequestClose]);

	return (
		<Modal
			className={css(styleSheet.modal)}
			isOpen={isOpen}
			onRequestClose={onCloseClicked}
			shouldCloseOnOverlayClick={false}
		>
			<div
				className={`${css(
					styleSheet.container,
					win && slotMachine.type === Api.SlotMachineType.BoozeCruise ? styleSheet.boozeCruiseContainer : null,
					...styles
				)} slot-machine-end-game-modal ${className || ''}`}
			>
				{onRenderBackground ? (
					onRenderBackground(outcome, styleSheet.background)
				) : (
					<SlotMachineEndGameModalBackground slotMachine={slotMachine} />
				)}
				{win ? (
					onRenderTitle ? (
						onRenderTitle(styleSheet.title)
					) : (
						<SlotMachineEndGameModalTitle slotMachine={slotMachine} />
					)
				) : null}
				<div className={css(styleSheet.content)}>
					{outcome?.spinResult ? (
						<SlotMachineSymbolGroup symbols={outcome.spinResult} symbolStyles={[styleSheet.symbol]} />
					) : null}
					<SlotMachineEndGameModalMessage slotMachine={slotMachine} />
				</div>
				<button className={css(styleSheet.continueButton)} onClick={onCloseClicked}>
					<span>Continue</span>
				</button>
			</div>
		</Modal>
	);
});
