import { ICompany } from '../../../../../extViewmodels';
import { styleSheet } from './../styles';
import { css } from 'aphrodite';
import * as React from 'react';

export const CompanyInfo: React.FC<ICompany> = ({ companyName, phoneNumbers }) => {
	return (
		<div>
			<div>{companyName ?? 'Unknown'}</div>
			{!!phoneNumbers && (
				<div className={css(styleSheet.fontSizeSmall)}>{phoneNumbers[0]?.metadata?.standard ?? 'Unknown'}</div>
			)}
		</div>
	);
};
