import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const DraggableIcon: React.FC<IProps> = ({ className = '', fill = '#D7EBF7' }) => {
	return (
		<SvgIcon className={`draggable-icon ${className}`} height={20} width={12}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g transform='translate(-16.000000, -16.000000)' fill={fill} fillRule='nonzero'>
					<g id='Group-7' transform='translate(16.000000, 16.000000)'>
						<ellipse id='Oval' cx='2.13793103' cy='2' rx='2.13793103' ry='2' />
						<ellipse id='Oval' cx='9.62068966' cy='2' rx='2.13793103' ry='2' />
						<ellipse id='Oval' cx='2.13793103' cy='10' rx='2.13793103' ry='2' />
						<ellipse id='Oval' cx='9.62068966' cy='10' rx='2.13793103' ry='2' />
						<ellipse id='Oval' cx='2.13793103' cy='18' rx='2.13793103' ry='2' />
						<ellipse id='Oval' cx='9.62068966' cy='18' rx='2.13793103' ry='2' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
