import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import * as React from 'react';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FormFieldType, IContact, IEntity, IRichContentEditorState, IUser, VmUtils } from '../../../../extViewmodels';
import { Noop, getDisplayName, timezoneMap } from '../../../../extViewmodels/Utils';
import { ToolbarLight, convertRawRichTextContentStateToRichContentEditorState } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useLambda } from '../../../../models/hooks/useLambda';
import { Button } from '../../../../web/components/Button';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { ISelectOption, MultiSelect, Select } from '../../../../web/components/Select';
import { TextArea } from '../../../../web/components/TextArea';
import { TextInput } from '../../../../web/components/TextInput';
import { TinyPopover } from '../../../../web/components/TinyPopover';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '../../../../web/components/richContent/RichContentDocumentEditor';
import { AddUserIcon } from '../../../../web/components/svgs/icons/AddUserIcon';
import { CalendarDateIcon } from '../../../../web/components/svgs/icons/CalendarDateIcon';
import { useErrorMessages } from '../../../hooks';
import { useResponseHandler } from '../../../hooks/error';
import { useQueue } from '../../../hooks/queue';
import { useEventListener } from '../../../hooks/useEventListener';
import { brandSecondary } from '../../../styles/colors';
import { FormFieldResolvers } from '../../../viewModels/FormFieldResolvers';
import {
	CustomFormViewModel,
	FieldKey,
	FormFieldViewModel,
	IFormFieldOption,
	IInitiateMeetingRequest as IInitiateSchedulerRequest,
} from '../../../viewModels/form';
import { AidaDayPicker } from '../../AidaDayPicker';
import { DefaultAIDANoteContentCSS } from '../../AidaNoteEditor';
import { IContactModalProps } from '../../CallingCard';
import { useSaveContact } from '../../ContactInfo/useSaveContact';
import { ContactInfoModal } from '../../ContactInfoModal';
import { TransparentButton } from '../../TransparentButton';
import { AutoCompleteSearchField, IAutoCompleteSearchFieldComponent } from '../../autocomplete/AutoCompleteSearchField';
import { ResourceAutoCompleteViewModelType } from '../../autocomplete/autocomplete';
import { styleSheet } from './styles';

interface IProps<T> {
	className?: string;
	form: CustomFormViewModel;
	field: FormFieldViewModel<T>;
}

enum SchedulingType {
	Automatic = 'automatic',
	Manual = 'manual',
}

interface IMeetingSchedulerState {
	schedulingType: SchedulingType;
}

const noResult = [
	{
		dataContext: {
			fieldId: 'no-results',
			label: 'No Results',
			name: FieldKey.None,
		},
		id: 'no-results',
		text: 'No Results',
	},
];

const DefaultEditorConfig: IRichContentDocumentEditorConfig = {
	autoresizeToFitContent: true,
	contentHorizontalPadding: 20,
	contentRawCss: DefaultAIDANoteContentCSS,
	maxHeight: 200,
	minHeight: 100,
	plugins: 'emoticons',
	toolbar: `${ToolbarLight} | emoticons`,
};

export const DealField = observer(<T extends object>({ className = '', field, form }: IProps<T>) => {
	const queue = useQueue();
	const errorMessages = useErrorMessages();
	const userSession = useUserSession();
	const [value, setValue] = useState<T>(field.tempValue || field.value);
	const [options, setOptions] = useState<ISelectOption<IFormFieldOption>[]>([]);
	// @ts-ignore
	const [filteredOptions, setFilteredOptions] = useState<ISelectOption<IFormFieldOption>[]>(null);
	const [filterValue, setFilterValue] = useState<string>('');
	// @ts-ignore
	const [selectedOption, setSelectedOption] = useState<ISelectOption<IFormFieldOption>>(null);
	// @ts-ignore
	const [selectedOptions, setSelectedOptions] = useState<ISelectOption<IFormFieldOption>[]>(null);
	const [showDayPicker, setShowDayPicker, setShowDayPickerLambda] = useLambda(false);
	// @ts-ignore
	const [selectedDate, setSelectedDate] = useState<moment.Moment>(null);
	// @ts-ignore
	const [selectedPerson, setSelectedPerson] = useState<IUser | IContact>(null);
	const [isFocused, , setIsFocused] = useLambda(false);
	const [meetingSchedulerState, setMeetingSchedulerState] = useState<IMeetingSchedulerState>({
		schedulingType: SchedulingType.Manual,
	});
	const [contactModalProps, setContactModalProps, setContactModalPropsLambda] = useLambda<IContactModalProps>({
		// @ts-ignore
		contact: null,
		show: false,
	});
	const [schedulerResponse] = useResponseHandler('LoadMeetingLink-Error', 'Unable to load meeting booking link');

	const saveContact = useSaveContact();

	const personAutocompleteSearchFieldRef = React.useRef<IAutoCompleteSearchFieldComponent>(null);
	const onPersonAutocompleteSearchFieldRef = React.useCallback((ref?: IAutoCompleteSearchFieldComponent) => {
		// @ts-ignore
		personAutocompleteSearchFieldRef.current = ref;
	}, []);
	const personAutocompleteSearchFieldInputProps = React.useMemo<
		React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
	>(() => {
		return {
			onBlur: () => {
				if (!selectedPerson) {
					return;
				}
				personAutocompleteSearchFieldRef.current?.setSearchQuery(VmUtils.getDisplayName(selectedPerson));
			},
		};
	}, [selectedPerson]);

	const onClickToSave = (contact: IContact, original?: IContact) => {
		// @ts-ignore
		setContactModalProps({ contact: null, show: false });
		saveContact(contact, original).then(contactVm => {
			if (contactVm) {
				// @ts-ignore
				setContactModalPropsLambda({ contact: null, show: false })();
				const person: string | IUser | IEntity = contactVm.toJs();
				onSelectedPersonChange(person);
				personAutocompleteSearchFieldRef.current?.setSearchQuery(VmUtils.getDisplayName(person));
			}
		});
	};

	useEffect(() => {
		field
			.load()
			.then(() => {
				if (field.fieldType === FormFieldType.User || field.fieldType === FormFieldType.Contact) {
					// @ts-ignore
					setSelectedPerson(field.value);
				} else if (field.fieldType === FormFieldType.Option || field.fieldType === FormFieldType.MultipleOptions) {
					const updatedOptions = field.options?.map((option: IFormFieldOption) => ({
						dataContext: option,
						id: option.id,
						text: option.label,
						type: field.fieldType === FormFieldType.MultipleOptions ? 'checkbox' : null,
					})) as ISelectOption<IFormFieldOption>[];

					setOptions(updatedOptions);

					if (field.fieldType === FormFieldType.Option) {
						setSelectedOption(
							// @ts-ignore
							updatedOptions.find(
								o =>
									o.dataContext?.id === (field.tempValue as IFormFieldOption)?.id ||
									o.dataContext?.id === (field.value as IFormFieldOption)?.id
							)
						);
					} else {
						setSelectedOptions(
							updatedOptions.filter(
								o => !!(field.value as IFormFieldOption[])?.map(v => v?.id)?.includes(o.dataContext?.id)
							)
						);
					}
				} else if (field.fieldType === FormFieldType.Note) {
					if (field.note.id) {
						field.note.load();
					}
				} else if (field.fieldType === FormFieldType.MeetingScheduler) {
					if (field.value) {
						setMeetingSchedulerState({
							schedulingType: SchedulingType.Automatic,
						});
						field.setSchedulerLink(field.value as unknown as string);
					}
				}
			})
			.catch((err: any) => {
				// @ts-ignore
				errorMessages.pushApiError(err);
			});

		if (
			field.fieldType === FormFieldType.Date ||
			field.fieldType === FormFieldType.DateTime ||
			field.fieldType === FormFieldType.Time
		) {
			if (field.tempValue ?? field.value) {
				setSelectedDate(moment(field.tempValue || field.value));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEventListener<KeyboardEvent>('keydown', (keyboardEvent: KeyboardEvent) => {
		if (keyboardEvent.key === 'Escape') {
			setFilterValue('');
			return;
		}

		if (keyboardEvent.key === 'Backspace' || keyboardEvent.key === 'Delete') {
			setFilterValue(`${filterValue.slice(0, filterValue.length - 1)}`);
			return;
		}

		if (keyboardEvent.key === 'Shift') {
			return;
		}
		setFilterValue(filterValue + keyboardEvent.key);
	});

	useEffect(() => {
		if (filterValue && options.length > 5) {
			// @ts-ignore
			const filtered = options.filter(x => x.text.trim().toLowerCase().includes(filterValue.trim().toLowerCase()));
			if (!filtered.length) {
				setFilteredOptions(noResult);
			} else {
				setFilteredOptions(filtered);
			}
		} else {
			// @ts-ignore
			setFilteredOptions(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterValue]);

	const onTriggerClick = () => {
		// @ts-ignore
		setFilteredOptions(null);
	};

	// text input or textarea change
	useEffect(() => {
		field.set(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	// select option change
	useEffect(() => {
		if (selectedOption) {
			field.set(selectedOption.dataContext.id);
		}
		setFilterValue('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOption]);

	useEffect(() => {
		field.set(selectedOptions?.map(o => o.dataContext.id).join(','));
		setFilterValue('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptions]);

	useEffect(() => {
		setFilterValue('');
	}, [isFocused]);

	// date change
	useEffect(() => {
		if (selectedDate && (field.fieldType === FormFieldType.Date || field.fieldType === FormFieldType.DateTime)) {
			field.set(selectedDate.format());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate]);

	useEffect(() => {
		if (selectedPerson && (field.fieldType === FormFieldType.User || field.fieldType === FormFieldType.Contact)) {
			field.setPerson(selectedPerson as T);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedPerson]);

	const onDayPickerCancel = useCallback(() => {
		setSelectedDate(moment(field.value));
		setShowDayPicker(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [field]);

	const onDayPickerSave = useCallback((day: moment.Moment) => {
		setSelectedDate(day);
		setShowDayPicker(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onDayPickerChange = useCallback((day: moment.Moment) => {
		setSelectedDate(day);
	}, []);

	const onMultiSelectOptionClick = (selectedOpt: ISelectOption<IFormFieldOption>, wasSelected: boolean) => {
		setSelectedOptions(
			wasSelected
				? [...selectedOptions, selectedOpt]
				: selectedOptions.filter(o => o.dataContext.id !== selectedOpt.dataContext.id)
		);
	};

	const onOptionClick = (option: ISelectOption<IFormFieldOption>) => {
		if (option?.dataContext?.id !== (field.value as IFormFieldOption)?.id) {
			setSelectedOption(option);
		}
	};

	const onSelectedPersonChange = (person: string | IUser | IEntity) => {
		setSelectedPerson(person as IUser);
		field.setPerson(person as T);
	};

	const onTextChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setValue(e.target.value as unknown as T);
	};

	const onNumberChanged = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setValue(e.target.value as unknown as T);
	};

	const onContentStateChanged = (content: IRichContentEditorState) => {
		setValue(content as unknown as T);
		field.note.setContent({ content: content.getRawRichTextContent() });
	};

	// @ts-ignore
	const userTimezone = userSession.user.userPreferences?.timeZone || momentTz.tz.guess();

	const timezoneLabel = timezoneMap[userTimezone] || userTimezone || '';

	const displaySelectedDate = (withTime: boolean) =>
		selectedDate?.format(`MM/DD/YYYY ${withTime ? '@ hh:mm A' : ''}`) + ' ' + timezoneLabel;

	const renderDatePicker = useCallback(
		(withTime = false) => {
			const anchor = (
				<div className={css(styleSheet.fieldContainer)}>
					<TransparentButton className={css(styleSheet.dateFieldContainer)} onClick={setShowDayPickerLambda(true)}>
						<div className={css(styleSheet.calendarIconContainer)}>
							<CalendarDateIcon fillColor={brandSecondary} />
						</div>
						<div className={css(styleSheet.selectedDate)}>
							{!selectedDate ? 'Select time' : displaySelectedDate(withTime)}
						</div>
					</TransparentButton>
				</div>
			);

			return (
				<TinyPopover anchor={anchor} isOpen={showDayPicker}>
					<div className={css(styleSheet.dateTimePickerContainer)}>
						<AidaDayPicker
							canAdjustTime={true}
							className={css(styleSheet.datePickerContainer)}
							day={selectedDate}
							hideTime={!withTime}
							onCancel={onDayPickerCancel}
							onSave={onDayPickerSave}
							onChange={onDayPickerChange}
						/>
					</div>
				</TinyPopover>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedDate, showDayPicker, onDayPickerCancel, onDayPickerSave]
	);

	const renderMultiSelectTrigger = () => {
		let content = '--';

		if (selectedOptions?.length) {
			content = selectedOptions.map(o => o.text).join(', ');
		}

		return (
			<div
				className={css(
					styleSheet.selectTriggerContent,
					!selectedOptions?.length && styleSheet.selectTriggerNoneSelected
				)}
			>
				<div>
					{content}
					{filterValue && isFocused && options?.length > 5 ? ' (filtering to "' + filterValue + '")' : ''}
				</div>
			</div>
		);
	};

	const onRenderNoopFooter = () => {
		return <div className={css(styleSheet.hide)} />;
	};

	const onRenderResultsFooter = () => {
		return (
			<div className={css(styleSheet.addUserDropdownFooter)}>
				<button
					className={css(styleSheet.addUserButton)}
					onMouseDown={setContactModalPropsLambda({
						// @ts-ignore
						contact: null,
						show: true,
					})}
				>
					<AddUserIcon />
					<span>Add contact</span>
				</button>
			</div>
		);
	};

	const onSelectOpenChanged = (isOpen: boolean) => {
		setIsFocused(isOpen)();
	};

	const renderSelectTrigger = (option: ISelectOption<IFormFieldOption>) => {
		return (
			<div className={css(styleSheet.selectTriggerContent, !option && styleSheet.selectTriggerNoneSelected)}>
				{option?.text ?? '--'}
				{filterValue && isFocused && options?.length > 5 ? ' (filtering to "' + filterValue + '")' : ''}
			</div>
		);
	};

	useEffect(() => {
		const demoPerformDate = FormFieldResolvers.getDemoPerformDateField(userSession);
		const demoPerformer = FormFieldResolvers.getDemoPerformerField(userSession);

		if (meetingSchedulerState.schedulingType === SchedulingType.Automatic) {
			form.fields.find(x => x.name === demoPerformDate)?.setHidden(true);
			form.fields.find(x => x.name === demoPerformer)?.setHidden(true);
		} else {
			form.fields.find(x => x.name === demoPerformDate)?.setHidden(false);
			form.fields.find(x => x.name === demoPerformer)?.setHidden(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [meetingSchedulerState.schedulingType]);

	const onUseAutoSchedulerClick = () => {
		const contactId = (
			form.fields?.find(x => x?.name === FormFieldResolvers.getAssociatedContactField(userSession))?.person as IContact
		)?.id;
		if (!contactId) {
			// @ts-ignore
			errorMessages.push({
				messages: ['You must select a contact to use the demo scheduler.'],
			});
			return;
		}

		const linkRequest: IInitiateSchedulerRequest = {
			// @ts-ignore
			companyId: form.companyId,
		};

		if (contactId) {
			linkRequest.contactId = contactId;
		}

		if (form.lead?.company?.timeZone) {
			linkRequest.timeZone = form.lead.company.timeZone;
		}

		field
			.loadMeetingLink(linkRequest)
			.then(res => {
				setMeetingSchedulerState({
					schedulingType: SchedulingType.Automatic,
				});
				window.open(res.url, '_blank');
			})
			.catch(schedulerResponse);
	};

	const onUseManualSchedulerClick = () => {
		setMeetingSchedulerState({ schedulingType: SchedulingType.Manual });
		field.clearSchedulerLink();
	};

	const renderFieldByType = () => {
		if (field.isHidden) {
			return null;
		}

		switch (field.fieldType) {
			case FormFieldType.Date:
			case FormFieldType.DateTime:
				return renderDatePicker(field.fieldType === FormFieldType.DateTime);
			case FormFieldType.Option:
				return field.isBusy ? (
					<div className={css(styleSheet.loading)}>
						<LoadingSpinner type='small' />
					</div>
				) : (
					<Select
						onOptionClick={onOptionClick}
						options={filteredOptions ?? options}
						selectedOption={selectedOption}
						onOpenChanged={onSelectOpenChanged}
						styles={[styleSheet.select]}
						selectedOptionTitle={renderSelectTrigger}
						onTriggerClick={onTriggerClick}
					/>
				);
			case FormFieldType.MultipleOptions:
				return field.isBusy ? (
					<div className={css(styleSheet.loading)}>
						<LoadingSpinner type='small' />
					</div>
				) : (
					<MultiSelect
						onClose={Noop}
						onOptionClick={onMultiSelectOptionClick}
						options={filteredOptions ?? options}
						onOpenChanged={onSelectOpenChanged}
						selectedOptions={selectedOptions}
						styles={[styleSheet.select]}
						selectedOptionsTitle={renderMultiSelectTrigger}
					/>
				);
			case FormFieldType.FullAddress:
			case FormFieldType.String:
				return (
					<TextInput
						className={css(styleSheet.textInput, isFocused && styleSheet.focus)}
						// @ts-ignore
						inputId={field.id}
						onBlur={setIsFocused(false)}
						onChange={onTextChange}
						onFocus={setIsFocused(true)}
						type='text'
						value={(value as unknown as string) || ''}
					/>
				);
			case FormFieldType.Text:
				return (
					<TextArea
						inputClassName={css(styleSheet.textArea)}
						// @ts-ignore
						inputId={field.id}
						onBlur={setIsFocused(false)}
						onChange={onTextChange}
						onFocus={setIsFocused(true)}
						value={(value as unknown as string) || ''}
					/>
				);
			case FormFieldType.Note:
				return (
					<RichContentDocumentEditor
						autoFocus={true}
						className='note-editor-deal-editor'
						config={{ ...DefaultEditorConfig }}
						contentState={convertRawRichTextContentStateToRichContentEditorState(field.note?.rawContentState)}
						onContentStateChanged={onContentStateChanged}
					/>
				);
			case FormFieldType.Contact:
			case FormFieldType.User:
				return (!!field.value || !!field.tempValue) && !selectedPerson ? (
					<div className={css(styleSheet.loading)}>
						<LoadingSpinner type='small' />
					</div>
				) : (
					<AutoCompleteSearchField
						anchorClassName='person-auto-complete-anchor'
						className={css(styleSheet.personAutoComplete)}
						dropdownClassName='person-auto-complete-dropdown'
						fieldId={field.id}
						hideResultsFooter={false}
						// @ts-ignore
						initialSearchQuery={selectedPerson ? getDisplayName(selectedPerson) : null}
						inputId={field.id}
						inputProps={personAutocompleteSearchFieldInputProps}
						leadId={queue.lead?.company?.id}
						onInnerRef={onPersonAutocompleteSearchFieldRef}
						onItemSelected={onSelectedPersonChange}
						onRenderResultsFooter={
							field.fieldType === FormFieldType.Contact ? onRenderResultsFooter : onRenderNoopFooter
						}
						preventImpersonation={true}
						resultsLimit={5}
						dealType={queue.lead?.lastDeal?.id ? 'UpdateDeal' : 'CreateDeal'}
						type={
							field.fieldType === FormFieldType.Contact
								? ResourceAutoCompleteViewModelType.Contact
								: ResourceAutoCompleteViewModelType.User
						}
					/>
				);
			case FormFieldType.MeetingScheduler:
				return meetingSchedulerState.schedulingType === SchedulingType.Manual ? (
					<Button label='Find Demo Spot' kind='primary' size='small' onClick={onUseAutoSchedulerClick} />
				) : (
					<Button label='Enter Demo Time Manually' kind='primary' size='small' onClick={onUseManualSchedulerClick} />
				);
			case FormFieldType.Decimal:
				return (
					<TextInput
						className={css(styleSheet.textInput, isFocused && styleSheet.focus)}
						// @ts-ignore
						inputId={field.id}
						onBlur={setIsFocused(false)}
						onChange={onNumberChanged}
						onFocus={setIsFocused(true)}
						type='number'
						value={(value as unknown as number) || ''}
					/>
				);
			default:
				return <div key={field.id}>field type not set up yet...{field.fieldType}</div>;
		}
	};

	const renderLabelByType = () => {
		if (field.isHidden) {
			return null;
		}

		switch (field.fieldType) {
			case FormFieldType.MeetingScheduler:
				return null;
			default:
				return (
					<label className={css(styleSheet.label)} htmlFor={field.id}>
						{field.label}
						{!field.isOptional && <span>*</span>}
					</label>
				);
		}
	};

	return (
		<div id={field.id + field.label + field.fieldType} className={`${css(styleSheet.dealFieldContainer)} ${className}`}>
			{renderLabelByType()}
			{renderFieldByType()}
			{!!field.validationError && <div className={css(styleSheet.error)}>{field.validationError}</div>}
			{field.fieldType === FormFieldType.Contact && (
				<ContactInfoModal
					contact={contactModalProps.contact}
					isOpen={contactModalProps.show}
					onCancel={setContactModalPropsLambda({
						// @ts-ignore
						contact: null,
						show: false,
					})}
					onSave={onClickToSave}
				/>
			)}
		</div>
	);
});
