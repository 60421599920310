import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import { brandPrimaryHover } from '../../../../styles/colors';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const RewindIcon: React.FC<IProps> = ({ className = '', fillColor = brandPrimaryHover }) => {
	return (
		<SvgIcon className={`rewind-icon ${className}`} height={19} width={19}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.879 14.986 10.12 9.997l6.758-4.99v9.979Zm.933-11.787a2.261 2.261 0 0 0-2.236.218l-6.758 4.99A1.97 1.97 0 0 0 8 9.997c0 .624.3 1.203.819 1.588l6.758 4.99a2.212 2.212 0 0 0 2.235.22c.722-.337 1.188-1.047 1.188-1.81V5.007c0-.763-.466-1.473-1.188-1.808ZM2 16V2h2l.002 14H2ZM4 0H2C.897 0 0 .896 0 2v14c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2V2c0-1.104-.897-2-2-2Z'
				fill={fillColor}
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M18.5 4.027V16l-8.236-5.132V9L18.5 4.027Z' fill={fillColor} />
			<rect width='4' height='16' rx='2' transform='matrix(-1 0 0 1 4 1)' fill={fillColor} />
		</SvgIcon>
	);
};
