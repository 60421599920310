import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import { brandPrimaryHover } from '../../../../styles/colors';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const PauseIcon: React.FC<IProps> = ({ className = '', fillColor = brandPrimaryHover }) => {
	return (
		<SvgIcon className={`pause-icon ${className}`} height={23} width={18}>
			<rect width='6' height='23' rx='2' transform='matrix(-1 0 0 1 6 0)' fill={fillColor} />
			<rect width='6' height='23' rx='2' transform='matrix(-1 0 0 1 18 0)' fill={fillColor} />
		</SvgIcon>
	);
};
