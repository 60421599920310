import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { ExportIcon } from '../../../../web/components/svgs/icons/ExportIcon';
import { TransparentButton } from '../../../components/TransparentButton';
import { ILeadViewFilter } from '../../../entities/LeadView/useLeadView';
import { useExportTranscripts } from '../../../entities/PhoneCall/useExportTranscripts';
import { useToaster } from '../../../hooks';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface ILeadViewFilterProps {
	filter?: ILeadViewFilter;
}

export const ExportReport: React.FC<ILeadViewFilterProps> = ({ filter }) => {
	const toaster = useToaster();

	const { bulkExport } = useExportTranscripts();

	const [showSpinner, setSpinner] = React.useState(false);

	return (
		<TransparentButton
			className={css(styleSheet.exportReportButton)}
			onClick={() => {
				setSpinner(true);
				// @ts-ignore
				bulkExport(filter).then(result => {
					setSpinner(false);
					// @ts-ignore
					if (result.success) {
						// @ts-ignore
						toaster.push({
							message: 'You will receive an email when your export is ready to be downloaded.',
							type: 'successMessage',
						});
					} else {
						// @ts-ignore
						// @ts-ignore
						toaster.push({ message: result.systemMessage, type: 'errorMessage' });
					}
				});
			}}
		>
			{showSpinner ? <LoadingSpinner type='extra-small' /> : <ExportIcon />} Export Report
		</TransparentButton>
	);
};
