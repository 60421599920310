import { FontFamilies } from '../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	callLogContainer: {
		width: '80vw',
	},
	leadCard: {
		marginBottom: 26,
		marginTop: 26,
	},
	noResults: {
		color: '#fff',
		textAlign: 'center',
	},
	subtitle: {
		color: 'white',
		fontFamily: FontFamilies.semiBold,
		margin: '10px auto 30px',
		textAlign: 'center',
	},
});
