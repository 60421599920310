import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import { Spring, config } from '@react-spring/web';
import * as React from 'react';

interface IProps {
	animate?: boolean;
	className?: string;
}

export const AnimatedLionGraphic: React.FC<IProps> = ({ animate, className = '' }) => {
	const renderEars = () => {
		if (animate) {
			return (
				<>
					<Spring config={{ ...config.wobbly, friction: 10 }} delay={250} from={{ degrees: 40 }} to={{ degrees: 0 }}>
						{({ degrees }) => {
							return (
								<g
									id='content-tiger-king-right-ear'
									transform={`translate(62) rotate(${degrees?.animation?.to || 0} 4 8)`}
								>
									<path
										d='M1,5 C4.95788401,5.36037341 7.95788401,5.36037341 10,5 C12.042116,4.63962659 14.7087827,3.63962659 18,2 C19.3333333,10.6666667 17,15.3333333 11,16 C5,16.6666667 1.66666667,13 1,5 Z'
										fill='#063E66'
										transform='rotate(-8 9.682 9.038)'
									/>
									<path
										d='M1,4.55737705 C4.41911446,4.86458061 7.0107375,4.86458061 8.77486911,4.55737705 C10.5390007,4.25017349 12.8426656,3.39771447 15.6858639,2 C16.8376963,9.38797814 14.8219895,13.3661202 9.63874346,13.9344262 C4.45549738,14.5027322 1.57591623,11.3770492 1,4.55737705 Z'
										fill='#F89143'
										transform='rotate(-8 8.5 8)'
									/>
								</g>
							);
						}}
					</Spring>
					<Spring config={{ ...config.wobbly, friction: 10 }} delay={150} from={{ degrees: 40 }} to={{ degrees: 0 }}>
						{({ degrees }) => {
							return (
								<g
									id='content-tiger-king-left-ear'
									transform={`matrix(-1 0 0 1 25 0) rotate(${degrees?.animation?.to || 0} 4 8)`}
								>
									<path
										d='M1,5 C4.95788401,5.36037341 7.95788401,5.36037341 10,5 C12.042116,4.63962659 14.7087827,3.63962659 18,2 C19.3333333,10.6666667 17,15.3333333 11,16 C5,16.6666667 1.66666667,13 1,5 Z'
										fill='#063E66'
										transform='rotate(-8 9.682 9.038)'
									/>
									<path
										d='M1,4.55737705 C4.41911446,4.86458061 7.0107375,4.86458061 8.77486911,4.55737705 C10.5390007,4.25017349 12.8426656,3.39771447 15.6858639,2 C16.8376963,9.38797814 14.8219895,13.3661202 9.63874346,13.9344262 C4.45549738,14.5027322 1.57591623,11.3770492 1,4.55737705 Z'
										fill='#F89143'
										transform='rotate(-8 8.5 8)'
									/>
								</g>
							);
						}}
					</Spring>
				</>
			);
		}

		return (
			<>
				<g id='content-tiger-king-right-ear' transform='translate(62) rotate(40 4 8)'>
					<path
						fill='#063E66'
						d='M1,5 C4.95788401,5.36037341 7.95788401,5.36037341 10,5 C12.042116,4.63962659 14.7087827,3.63962659 18,2 C19.3333333,10.6666667 17,15.3333333 11,16 C5,16.6666667 1.66666667,13 1,5 Z'
						transform='rotate(-8 9.682 9.038)'
					/>
					<path
						fill='#F89143'
						d='M1,4.55737705 C4.41911446,4.86458061 7.0107375,4.86458061 8.77486911,4.55737705 C10.5390007,4.25017349 12.8426656,3.39771447 15.6858639,2 C16.8376963,9.38797814 14.8219895,13.3661202 9.63874346,13.9344262 C4.45549738,14.5027322 1.57591623,11.3770492 1,4.55737705 Z'
						transform='rotate(-8 8.5 8)'
					/>
				</g>
				<g id='content-tiger-king-left-ear' transform='matrix(-1 0 0 1 25 0) rotate(40 4 8)'>
					<path
						fill='#063E66'
						d='M1,5 C4.95788401,5.36037341 7.95788401,5.36037341 10,5 C12.042116,4.63962659 14.7087827,3.63962659 18,2 C19.3333333,10.6666667 17,15.3333333 11,16 C5,16.6666667 1.66666667,13 1,5 Z'
						transform='rotate(-8 9.682 9.038)'
					/>
					<path
						fill='#F89143'
						d='M1,4.55737705 C4.41911446,4.86458061 7.0107375,4.86458061 8.77486911,4.55737705 C10.5390007,4.25017349 12.8426656,3.39771447 15.6858639,2 C16.8376963,9.38797814 14.8219895,13.3661202 9.63874346,13.9344262 C4.45549738,14.5027322 1.57591623,11.3770492 1,4.55737705 Z'
						transform='rotate(-8 8.5 8)'
					/>
				</g>
			</>
		);
	};

	return (
		<SvgIcon className={`animated-lion-graphic ${className}`} height={91} width={87}>
			<g fill='none' fillRule='evenodd'>
				<g transform='translate(0 19)'>
					<path
						fill='#FFF'
						d='M8,33 C8,33 5.33333333,35.3333333 0,40 L8,40 L2,46 L10,46 C12.3882526,52.5805678 15.0549193,56.9139012 18,59 C20.9450807,61.0860988 26.278414,62.4194322 34,63 L41,55 L13,30 C9.66666667,32 8,33 8,33 Z'
					/>
					<path
						fill='#FFF'
						d='M54,33 C54,33 51.3333333,35.3333333 46,40 L54,40 L48,46 L56,46 C58.3882526,52.5805678 61.0549193,56.9139012 64,59 C66.9450807,61.0860988 72.278414,62.4194322 80,63 L87,55 L59,30 C55.6666667,32 54,33 54,33 Z'
						transform='matrix(-1 0 0 1 133 0)'
					/>
					<circle cx='44' cy='57' r='14' fill='#063E66' />
					<path
						fill='#F89143'
						d='M35.9991914,66.9986024 L52.3234862,66.9986024 C50.6942496,69.8150666 47.6490838,71.7099737 44.1613388,71.7099737 C40.6735937,71.7099737 37.6284279,69.8150666 35.9991914,66.9986024 Z'
					/>
					<rect width='68' height='44' x='10' y='6' fill='#E78236' rx='19' />
					<g fill='#FFA25C' transform='translate(14.2 38)'>
						<path d='M8.91875,0 L20.8,0 C25.7705627,-9.13077564e-16 29.8,4.02943725 29.8,9 L29.8,9 C29.8,13.4335185 26.2059232,17.0275953 21.7724047,17.0275953 C21.4670291,17.0275953 21.1619023,17.0101702 20.8585121,16.9754051 L13.98226,16.1874632 C9.24219638,15.6443047 5.22472396,12.4502578 3.62701749,7.95464231 L3.51875,7.65 C2.45885059,4.66766235 4.0172947,1.39078176 6.99963235,0.330882353 C7.61577659,0.111909525 8.26485183,-1.65623769e-15 8.91875,-1.77635684e-15 Z' />
						<path
							d='M37.91875,0 L49.8,0 C54.7705627,-9.13077564e-16 58.8,4.02943725 58.8,9 L58.8,9 C58.8,13.4335185 55.2059232,17.0275953 50.7724047,17.0275953 C50.4670291,17.0275953 50.1619023,17.0101702 49.8585121,16.9754051 L42.98226,16.1874632 C38.2421964,15.6443047 34.224724,12.4502578 32.6270175,7.95464231 L32.51875,7.65 C31.4588506,4.66766235 33.0172947,1.39078176 35.9996324,0.330882353 C36.6157766,0.111909525 37.2648518,-1.65623769e-15 37.91875,-1.77635684e-15 Z'
							transform='matrix(-1 0 0 1 88.6 0)'
						/>
					</g>
					<polygon fill='#063E66' points='36 38 51 38 47.053 44.857 43.895 46 40.737 44.857' />
					<g fill='#063E66' transform='translate(31 10)'>
						<rect width='24' height='5' rx='2' />
						<rect width='19' height='5' x='3' y='7' rx='2' />
					</g>
					<rect width='9' height='3' x='69' y='32' fill='#063E66' rx='1.5' transform='rotate(-30 73.5 33.5)' />
					<rect width='9' height='3' x='69' y='37' fill='#063E66' rx='1.5' transform='rotate(-30 73.5 38.5)' />
					<rect
						width='9'
						height='3'
						x='10.147'
						y='31'
						fill='#063E66'
						rx='1.5'
						transform='scale(-1 1) rotate(-30 0 87.164)'
					/>
					<rect
						width='9'
						height='3'
						x='10.147'
						y='36'
						fill='#063E66'
						rx='1.5'
						transform='scale(-1 1) rotate(-30 0 92.164)'
					/>
					<rect width='28' height='2' x='56' y='46' fill='#063E66' rx='1' />
					<rect width='24' height='2' x='56' y='51' fill='#063E66' rx='1' transform='rotate(10 68 52)' />
					<rect width='19' height='2' x='56' y='56' fill='#063E66' rx='1' transform='rotate(21 65.5 57)' />
					<g fill='#063E66' transform='translate(56 46)' id='content-tiger-king-right-wiskers'>
						<rect width='28' height='2' rx='1' />
						<rect width='24' height='2' y='5' rx='1' transform='rotate(10 12 6)' />
						<rect width='19' height='2' y='10' rx='1' transform='rotate(21 9.5 11)' />
					</g>
					<g fill='#063E66' transform='matrix(-1 0 0 1 32 46)' id='content-tiger-king-left-wiskers'>
						<rect width='28' height='2' rx='1' />
						<rect width='24' height='2' y='5' rx='1' transform='rotate(10 12 6)' />
						<rect width='19' height='2' y='10' rx='1' transform='rotate(21 9.5 11)' />
					</g>
					<polygon fill='#FFF' points='36 59 38 66 40 59' transform='matrix(1 0 0 -1 0 125)' />
					<polygon fill='#FFF' points='48 59 50 66 52 59' transform='matrix(1 0 0 -1 0 125)' />
					<path
						fill='#FFF'
						d='M53,36.2857143 L75,20.2857143 C66.3333333,18.2857143 60,18.6190476 56,21.2857143 C52,23.952381 51,28.952381 53,36.2857143 Z'
					/>
					<g transform='translate(52.98 21.628)'>
						<path
							fill='#063E66'
							d='M8.21728311,16.657388 C15.2038684,16.3716737 16.2038684,18.3716737 16.2172831,16.657388 C16.2306978,14.9431023 16.2172831,10.8130242 16.2172831,8.65738801 C16.2172831,4.23911001 12.6355611,0.657388006 8.21728311,0.657388006 C5.98105754,0.657388006 1.66898744,-0.821735007 0.217283109,0.657388006 C-0.701475019,1.59349893 1.5715703,3.76276271 1.87692283,5.76711576 C2.04236121,6.85306364 1.21728311,7.89060338 1.21728311,8.65738801 C1.21728311,13.075666 3.80243823,16.8379317 8.21728311,16.657388 Z'
							transform='matrix(-1 0 0 1 16.223 0)'
						/>
						<circle cx='7.019' cy='9.372' r='4' fill='#F84343' transform='matrix(-1 0 0 1 14.039 0)' />
					</g>
					<path
						fill='#FFF'
						d='M1,17.2857143 L23,1.28571429 C14.3333333,-0.714285714 8,-0.380952381 4,2.28571429 C-2.17603713e-13,4.95238095 -1,9.95238095 1,17.2857143 Z'
						transform='matrix(-1 0 0 1 35 19)'
					/>
					{renderEars()}
					<circle cx='39' cy='48' r='1' fill='#063E66' />
					<circle cx='35' cy='50' r='1' fill='#063E66' />
					<g fill='#063E66' transform='matrix(-1 0 0 1 54 47)'>
						<circle cx='5' cy='1' r='1' />
						<circle cx='1' cy='3' r='1' />
					</g>
					<g transform='matrix(-1 0 0 1 34 21.628)'>
						<path
							fill='#063E66'
							d='M8.21728311,16.657388 C15.2038684,16.3716737 16.2038684,18.3716737 16.2172831,16.657388 C16.2306978,14.9431023 16.2172831,10.8130242 16.2172831,8.65738801 C16.2172831,4.23911001 12.6355611,0.657388006 8.21728311,0.657388006 C5.98105754,0.657388006 1.66898744,-0.821735007 0.217283109,0.657388006 C-0.701475019,1.59349893 1.5715703,3.76276271 1.87692283,5.76711576 C2.04236121,6.85306364 1.21728311,7.89060338 1.21728311,8.65738801 C1.21728311,13.075666 3.80243823,16.8379317 8.21728311,16.657388 Z'
							transform='matrix(-1 0 0 1 16.223 0)'
						/>
						<circle cx='7.019' cy='9.372' r='4' fill='#F84343' transform='matrix(-1 0 0 1 14.039 0)' />
					</g>
				</g>
				<g transform='translate(28)'>
					<polygon fill='#E7C309' points='2 6 14 16 2 16' />
					<polygon fill='#E7C309' points='2 16 8 22 2 22' />
					<polygon fill='#E7C309' points='8 16 14 22 8 22' />
					<polygon fill='#E7C309' points='18 16 24 22 18 22' transform='matrix(-1 0 0 1 42 0)' />
					<polygon fill='#E7C309' points='24 16 30 22 24 22' transform='matrix(-1 0 0 1 54 0)' />
					<polygon fill='#E7C309' points='18 6 30 16 18 16' transform='matrix(-1 0 0 1 48 0)' />
					<circle cx='16' cy='2' r='2' fill='#F3CE0D' />
					<circle cx='30' cy='2' r='2' fill='#F3CE0D' />
					<circle cx='2' cy='2' r='2' fill='#F3CE0D' />
					<polygon fill='#F9D519' points='16 6 22 16 10 16' />
					<polygon fill='#F9D519' points='16 16 18 22 14 22' />
				</g>
			</g>
		</SvgIcon>
	);
};
