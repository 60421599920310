import CheckmarkImage from '../../../assets/green-rounded-checkmark.png';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	availableLeadsLoading: boolean;
	availableLeadsNumber: number;
}

export const AvailableLeads: React.FC<IProps> = ({ availableLeadsLoading, availableLeadsNumber }) => {
	if (availableLeadsNumber === undefined) {
		return null;
	}

	const number = availableLeadsNumber <= 2000 ? availableLeadsNumber : '2000+';

	return (
		<>
			{(availableLeadsLoading || availableLeadsNumber !== null) && (
				<div className={css(styleSheet.availableLeads)}>
					<img src={CheckmarkImage} className={css(styleSheet.availableLeadsCheckmarkIcon)} />
					{availableLeadsLoading ? (
						<strong>Counting leads...</strong>
					) : (
						<>
							<strong>Estimated available leads:</strong>&nbsp;{number}
						</>
					)}
				</div>
			)}
		</>
	);
};
