import { css } from 'aphrodite';
import * as React from 'react';
import { DeprecatedXIcon } from '../../../../web/components/svgs/icons/DeprecatedXIcon';
import { styleSheet } from '../styles';

interface IProps {
	isMobile: boolean;
	onClickToClose: () => void;
}

export const CallDetailsHeader: React.FC<IProps> = ({ isMobile, onClickToClose }) => {
	const headerMobile = isMobile ? styleSheet.headerMobile : null;
	const headerTitleMobile = isMobile ? styleSheet.headerTitleMobile : null;
	return (
		<div className={css(styleSheet.header, headerMobile)}>
			<div className={css(styleSheet.headerMain)}>
				<span className={css(styleSheet.headerTitle, headerTitleMobile)}>Call Details</span>
			</div>
			<button className={css(styleSheet.headerClose)} onClick={onClickToClose}>
				<DeprecatedXIcon fillColor='#fff' />
			</button>
		</div>
	);
};
