import { FetchStatus } from '../../entities/utils/useStatesOnFetch';
import { useFuzzySearch } from '../../hooks/useFuzzySearch';
import { IIntegrationPropertyItem } from '../../viewModels/leads/interfaces';
import { ContentBox } from '../generics/ContentBox';
import { DraggableList } from '../generics/DraggableList';
import { useIntegrationProperties } from './IntegrationPropertiesContext';
import { DealGeneralSettings } from './components/DealGeneralSettings';
import { DealPropertyCard } from './components/DealPropertyCard';
import { EmptyState, EmptyStateVariations } from './components/EmptyState';
import { PropertiesHeader } from './components/PropertiesHeader';
import { PropertyItemRow } from './components/PropertyItemRow';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { useMemo, useState } from 'react';
import * as React from 'react';

interface IProps {
	isAdding?: boolean;
}

export const DealProperties: React.FC<IProps> = () => {
	const [search, setSearch] = useState('');

	// @ts-ignore
	const [settingsFetchStatus, setSettingsFetchStatus] = useState<FetchStatus>(null);

	const { config, status, update } = useIntegrationProperties();

	const handleSearch = (keyword: string) => {
		setSearch(keyword);
	};

	const filteredData = config?.properties?.filter(i => i.addToForm);
	const restData = config?.properties?.filter(i => !i.addToForm);

	const handleRemoveItem = (item: IIntegrationPropertyItem) => () => {
		// @ts-ignore
		const properties = [...filteredData].map(i => {
			if (i.name === item.name) {
				i.addToForm = false;
			}
			return i;
		});
		// @ts-ignore
		update({ ...config, properties: [...properties, ...restData] });
	};

	// @ts-ignore
	const fuzzySearch = useFuzzySearch<IIntegrationPropertyItem>(filteredData, ['label']);

	const items = useMemo(() => {
		return search ? fuzzySearch.search(search).map(item => item) : filteredData;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, filteredData]) as IIntegrationPropertyItem[];

	const isLoading = status !== FetchStatus.Done;

	const isEmpty = filteredData && filteredData?.length > 0 ? false : true;

	const renderEmpty = (
		<div className={css(styleSheet.emptyState)}>
			<EmptyState
				description='Click on "Add Additional Properties" above to start'
				variation={EmptyStateVariations.Flag}
			/>
		</div>
	);

	const handleReordering = (properties: IIntegrationPropertyItem[]) => {
		// @ts-ignore
		update({ ...config, properties: [...properties, ...restData] });
	};

	const getKey = (i: IIntegrationPropertyItem) => i.name;

	const labelPlaceholder = '▋▋ ▋▋▋▋ ▋▋▋';

	const isNotDone = isLoading || settingsFetchStatus !== FetchStatus.Done;

	return (
		<>
			<DealGeneralSettings onFetchStatusChange={setSettingsFetchStatus} />
			<ContentBox
				className={css(styleSheet.contentBox)}
				isBlocked={isNotDone}
				maxWidth={1024}
				title='Existing deal properties in Coffee (drag and drop to rearrange):'
			>
				<PropertiesHeader onSearchChange={handleSearch} pathname='deal' />
				{isEmpty ? (
					renderEmpty
				) : (
					<DraggableList<IIntegrationPropertyItem>
						className={css(styleSheet.draggableItemRow)}
						getKey={getKey}
						items={items}
						onChange={handleReordering}
					>
						{({ item, isDragging }) => {
							const draggableItemIsDragging = isDragging ? styleSheet.draggableItemIsDragging : null;
							return (
								<div className={css(styleSheet.draggableItem, draggableItemIsDragging)}>
									<PropertyItemRow
										isDraggable={true}
										canRemove={!item.isRequired}
										onClickToRemove={handleRemoveItem(item)}
										renderLeft={<DealPropertyCard label={isNotDone ? labelPlaceholder : item.label} />}
									/>
								</div>
							);
						}}
					</DraggableList>
				)}
			</ContentBox>
		</>
	);
};
