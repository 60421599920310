import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const TranscriptionIcon: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`transcription-icon ${className}`} height={17} width={20}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g transform='translate(-830.000000, -634.000000)' fill='#F3CE0D'>
					<g id='Group-2-Copy-2' transform='translate(830.000000, 634.000000)'>
						<polygon points='14.7826089 0 0 0 0 1.73913046 14.7826089 1.73913046' />
						<polygon points='14.7826089 3.47826093 0 3.47826093 0 5.21739139 14.7826089 5.21739139' />
						<polygon points='7.82608708 6.95652185 0 6.95652185 0 8.69565231 7.82608708 8.69565231' />
						<polygon points='7.82608708 10.4347828 0 10.4347828 0 12.1739132 7.82608708 12.1739132' />
						<g transform='translate(9.565218, 6.956522)'>
							<path d='M2.99274991,5.92061895 L1.08413328,5.92061895 L1.08413328,3.76700627 L2.99274991,3.76700627 L4.87859974,1.0895273 L4.87914181,8.59971313 L2.99274991,5.92061895 Z M5.29273865,0.0800213532 C4.89215141,-0.0841916139 4.41730103,0.00949053783 4.11211751,0.313688329 L2.42791647,2.69019993 L1.08413328,2.69019993 C0.486233774,2.69019993 0,3.17314757 0,3.76700627 L0,5.92061895 C0,6.51447765 0.486233774,6.99742529 1.08413328,6.99742529 L2.42791647,6.99742529 L4.05140605,9.30179087 C4.27690577,9.56883884 4.57124796,9.68944115 4.87859974,9.68944115 C5.02116327,9.68944115 5.16101646,9.66198259 5.29273865,9.60814227 C5.6998307,9.44123729 5.96273301,9.04981818 5.96273301,8.61263481 L5.96273301,1.07499041 C5.96273301,0.637268635 5.6998307,0.246926336 5.29273865,0.0800213532 L5.29273865,0.0800213532 Z' />
							<path d='M6.70807464,0.745341627 L6.70807464,1.81011538 C8.17586526,1.81011538 9.37000902,3.00425914 9.37000902,4.47204976 C9.37000902,5.93984038 8.17586526,7.13398414 6.70807464,7.13398414 L6.70807464,8.1987579 C8.76308798,8.1987579 10.4347828,6.5270631 10.4347828,4.47204976 C10.4347828,2.41756881 8.76308798,0.745341627 6.70807464,0.745341627' />
							<path d='M8.1987579,4.47204976 C8.1987579,3.65018639 7.52993801,2.98136651 6.70807464,2.98136651 L6.70807464,3.97515534 C6.98186347,3.97515534 7.20496906,4.19826094 7.20496906,4.47204976 C7.20496906,4.74633548 6.98186347,4.96894418 6.70807464,4.96894418 L6.70807464,5.96273301 C7.52993801,5.96273301 8.1987579,5.29391313 8.1987579,4.47204976' />
						</g>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
