import { useLambda } from '../../../models/hooks/useLambda';
import { AddAction } from '../AddAction';
import { PhoneNumberEditor } from '../PhoneNumberEditor';
import { IPhoneNumber } from '@ViewModels';
import { useEffect } from 'react';
import * as React from 'react';

interface IProps {
	addActionClassName?: string;
	editorClassName?: string;
	onAddingStatusChange?: (isAdding: boolean) => void;
	onPhoneNumberAdded?: (phoneNumber: IPhoneNumber) => void;
	onPhoneNumberDeleted?: (phoneNumber: IPhoneNumber) => void;
	onPhoneNumberChanged?: (phoneNumber: IPhoneNumber) => void;
	phoneNumber: IPhoneNumber;
}

export const AddPhoneAction: React.FC<IProps> = ({
	addActionClassName = '',
	editorClassName = '',
	onAddingStatusChange,
	onPhoneNumberAdded,
	onPhoneNumberDeleted,
	onPhoneNumberChanged,
	phoneNumber,
}) => {
	const [isAdding, setIsAdding, setIsAddingLambda] = useLambda(false);

	useEffect(() => {
		onAddingStatusChange?.(isAdding);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAdding]);

	const onCancelClick = () => {
		setIsAdding(false);
	};

	const onSaveClick = (phoneNumberToSave: IPhoneNumber) => {
		setIsAdding(false);
		// @ts-ignore
		onPhoneNumberAdded(phoneNumberToSave);
	};

	const onDeleteClick = (phoneNumberToDelete: IPhoneNumber) => {
		setIsAdding(false);
		// @ts-ignore
		onPhoneNumberDeleted(phoneNumberToDelete);
	};

	return isAdding ? (
		<PhoneNumberEditor
			className={editorClassName}
			onCancel={onCancelClick}
			onSave={onSaveClick}
			onChange={onPhoneNumberChanged}
			onDelete={onDeleteClick}
			phoneNumber={phoneNumber}
		/>
	) : (
		<AddAction className={addActionClassName} onClick={setIsAddingLambda(true)} text='Add phone number' />
	);
};
