import { IntegrationOptions } from '../';
import { green, orange } from '../../../styles/colors';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface ISettingsCard {
	color: string;
	option: IntegrationOptions;
	text: string;
}

export const IntegrationCards: React.FC = () => {
	const cards: ISettingsCard[] = [
		{
			color: orange,
			option: IntegrationOptions?.DealProperties,
			text: 'Deal Properties',
		},
		{
			color: green,
			option: IntegrationOptions?.LeadProperties,
			text: 'Lead Properties',
		},
	];
	return (
		<div className={css(styleSheet.cardsWrapper)}>
			{cards.map(card => (
				<Link key={card.option} to={`/integration/${card.option}`} className={css(styleSheet.cardContainer)}>
					<div className={css(styleSheet.card)} style={{ background: card.color }}>
						{card.text}
					</div>
				</Link>
			))}
		</div>
	);
};
