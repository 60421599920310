import { IPhoneNumber } from '../../../extViewmodels';
import { PhoneIcon } from '../../../web/components/svgs/icons/PhoneIcon';
import { useTelephony } from '../../contexts/telephony';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	companyId?: string;
	contactId?: string;
	isDisabled?: boolean;
	phoneNumber: IPhoneNumber;
	renderFallback?: (href: string) => JSX.Element;
	renderWithProvider?: (onClick: () => void) => JSX.Element;
}

const callHref = (phoneNumber: string) => {
	return `tel:${phoneNumber}`;
};

const CallButtonWrapper: React.FC<IProps> = ({
	companyId,
	contactId,
	isDisabled,
	phoneNumber,
	renderFallback,
	renderWithProvider,
}) => {
	const { initiateCall, phoneCall, telephonyConfiguration } = useTelephony();

	const hasProvider = telephonyConfiguration?.provider;

	const phoneNumberE164 = phoneNumber?.metadata?.e164;

	const call = () => {
		initiateCall(phoneNumber, companyId, contactId);
	};

	const onClick = !isDisabled && !phoneCall ? call : null;

	if (hasProvider) {
		// @ts-ignore
		// @ts-ignore
		return renderWithProvider(onClick);
	}

	// @ts-ignore
	// @ts-ignore
	return renderFallback(callHref(phoneNumberE164));
};

export const CallButton: React.FC<IProps> = props => {
	const renderFallback = (href: string) => (
		<a className={css(styleSheet.callCta)} href={href}>
			<span>
				<PhoneIcon fill='#fff' />
				<span>Call</span>
			</span>
		</a>
	);

	const renderWithProvider = (onClick: () => void) => (
		<button className={css(styleSheet.callCta)} onClick={onClick}>
			<span>
				<PhoneIcon fill='#fff' />
				<span>Call</span>
			</span>
		</button>
	);

	return <CallButtonWrapper {...props} renderFallback={renderFallback} renderWithProvider={renderWithProvider} />;
};

export const SmallCallButton: React.FC<IProps> = props => {
	const renderFallback = (href: string) => (
		<a className={css(styleSheet.smallCallButton)} href={href}>
			<span>Call</span>
		</a>
	);

	const renderWithProvider = (onClick: () => void) => (
		<button className={css(styleSheet.smallCallButton)} onClick={onClick}>
			<span>Call</span>
		</button>
	);

	return <CallButtonWrapper {...props} renderFallback={renderFallback} renderWithProvider={renderWithProvider} />;
};
