import { CssSelectors } from '../../../web/styles/styles';
import { lightOrange, orange } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { CSSProperties, StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	callActionContainer: {
		':not(:last-child)': {
			marginBottom: 10,
		},
		...aidaBaseStyles.flexHorizontalCenter,
		background: lightOrange,
		borderRadius: 6,
		padding: 20,
	},
	callActionsContainer: {
		...aidaBaseStyles.flexCol,
	},
	callCta: {
		':active': {
			boxShadow: 'inset 0 0 5px 1px rgba(0, 0, 0, .18)',
			cursor: 'pointer',
			transform: 'translate3d(0,0,0)',
		},
		':disabled': {
			opacity: 0.5,
		},
		':hover': {
			boxShadow: `0 1px 4px 1px rgba(0, 0, 0, 0.2)`,
			cursor: 'pointer',
			transform: 'translate3d(0,-1px,0)',
		},
		...aidaBaseStyles.flexCenter,
		background: orange,
		border: 'none',
		borderRadius: 3,
		color: '#fff',
		fontSize: '14px',
		minWidth: '100%',
		padding: 10,
		transition: 'box-shadow 0.1s, transform 0.1s',
		...CssSelectors.allChildren(
			{
				...(aidaBaseStyles.flexHorizontalCenter as CSSProperties),
				...CssSelectors.allChildren(
					{
						marginRight: 10,
					},
					'svg'
				),
			},
			'span'
		),
	},
	disabled: {
		opacity: 0.5,
	},
	label: {
		color: '#858585',
		fontSize: '12px',
		textTransform: 'capitalize',
	},
	left: {
		minWidth: '50%',
	},
	noPhones: {
		color: orange,
		minWidth: '100%',
		textAlign: 'center',
	},
	right: {
		minWidth: '50%',
	},
	triggerContainer: {
		...aidaBaseStyles.flexCol,
		background: 'none',
		color: '#000',
		...CssSelectors.allChildren(
			{
				fontSize: '16px',
				paddingTop: 4,
			},
			':last-child'
		),
	},
});
