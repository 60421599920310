import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const SailboatGraphic: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`sailboat-graphic ${className}`} height={83} width={78}>
			<path fillRule='evenodd' clipRule='evenodd' d='M2 56.9932L41 0V57L2 56.9932Z' fill='#0D0F11' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M33.3993 41.4207C32.2933 41.4207 31.3993 40.5267 31.3993 39.4207C31.3993 38.3147 32.2933 37.4207 33.3993 37.4207C34.5053 37.4207 35.3993 38.3147 35.3993 39.4207C35.3993 40.5267 34.5053 41.4207 33.3993 41.4207M25.3993 41.4207C24.2933 41.4207 23.3993 40.5267 23.3993 39.4207C23.3993 38.3147 24.2933 37.4207 25.3993 37.4207C26.5053 37.4207 27.3993 38.3147 27.3993 39.4207C27.3993 40.5267 26.5053 41.4207 25.3993 41.4207M29.3993 31.4207C24.9813 31.4207 21.3993 35.0027 21.3993 39.4207C21.3993 42.3747 23.0193 44.9247 25.3993 46.3107V49.4207L33.3993 49.4207L33.3993 46.3107C35.7793 44.9247 37.3993 42.3747 37.3993 39.4207C37.3993 35.0027 33.8173 31.4207 29.3993 31.4207'
				fill='white'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M73.5048 57.1372L44.5048 10.1372V57.1372H73.5048Z'
				fill='#0D0F11'
			/>
			<path d='M7.47913 62.1665H68.4791' stroke='#00AAE8' strokeWidth='2' strokeLinecap='square' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.55627 65.9146H75.5563L71.4855 72.4678C67.8365 78.3421 61.4118 81.9146 54.4964 81.9146H28.7295C23.7077 81.9146 18.8697 80.0254 15.1767 76.6224L3.55627 65.9146Z'
				fill='#00528C'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.2097 74.53H65.6174L64.9726 75.0477C61.4218 77.899 57.0043 79.4531 52.4504 79.4531H30.4097C25.9908 79.4531 21.6962 77.9896 18.1969 75.2912L17.2097 74.53Z'
				fill='#046CB6'
			/>
			<ellipse cx='15.3479' cy='70.2221' rx='1.86183' ry='1.84615' fill='#00AAE8' />
			<ellipse cx='15.3479' cy='70.2221' rx='1.86183' ry='1.84615' fill='white' />
			<ellipse cx='21.554' cy='70.2221' rx='1.86183' ry='1.84615' fill='white' />
			<ellipse cx='27.7601' cy='70.2221' rx='1.86183' ry='1.84615' fill='white' />
		</SvgIcon>
	);
};
