import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const EyeIcon: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`eye-icon ${className}`} height={14} width={22}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11 12c-4.165 0-7.549-3.576-8.731-5.001C3.448 5.573 6.82 2 11 2c4.165 0 7.549 3.576 8.731 5.001C18.552 8.427 15.18 12 11 12zm10.817-5.576C21.633 6.162 17.222 0 11 0S.367 6.162.183 6.424a.998.998 0 000 1.152C.367 7.838 4.778 14 11 14s10.633-6.162 10.817-6.424a.998.998 0 000-1.152z'
				fill='#00528C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11 9c-1.103 0-2-.897-2-2 0-1.102.897-2 2-2s2 .898 2 2c0 1.103-.897 2-2 2zm0-6C8.794 3 7 4.794 7 7s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4z'
				fill='#00528C'
			/>
		</SvgIcon>
	);
};
