import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const CopyIcon: React.FC<IProps> = ({ className = '', fillColor }) => {
	return (
		<SvgIcon className={`copy-icon ${className}`} height={14} width={14}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<path
					d='M4.2,9.8 L12.6,9.8 L12.6,1.4 L4.2,1.4 L4.2,9.8 Z M13.3,11.2 L3.5,11.2 C3.1136,11.2 2.8,10.8864 2.8,10.5 L2.8,0.7 C2.8,0.3136 3.1136,0 3.5,0 L13.3,0 C13.6864,0 14,0.3136 14,0.7 L14,10.5 C14,10.8864 13.6864,11.2 13.3,11.2 L13.3,11.2 Z'
					fill={fillColor || '#00AAE8'}
				/>
				<path
					d='M11.2,14 L0.7,14 C0.3136,14 0,13.6864 0,13.3 L0,2.8 L1.4,2.8 L1.4,12.6 L11.2,12.6 L11.2,14 Z'
					fill={fillColor || '#00AAE8'}
				/>
			</g>
		</SvgIcon>
	);
};
