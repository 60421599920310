import flagImage from '../../../assets/flag.svg';
import tagsImage from '../../../assets/tags.svg';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

export enum EmptyStateVariations {
	Flag = 'flag',
	Tags = 'tags',
}

interface IProps {
	description?: string;
	variation: EmptyStateVariations;
}

export const EmptyState: React.FC<IProps> = ({ description = 'Select properties from the left to add', variation }) => {
	const image = variation === EmptyStateVariations.Tags ? tagsImage : flagImage;
	return (
		<div className={css(styleSheet.addPropertiesEmptyState)}>
			<img alt='Tag icon to indicate empty state' src={image} className={css(styleSheet.addPropertiesEmptyStateImg)} />
			<div>{description}</div>
		</div>
	);
};
