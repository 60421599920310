import { KingOfClubsGraphic } from '../KingOfClubsGraphic';
import { QueenOfHearsGraphic } from '../QueenOfHeartsGraphic';
import { styleSheet } from './styles';
import { animated, config, useSpring } from '@react-spring/web';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	animate?: boolean;
	className?: string;
}

const queenStart = {
	transform: 'translate3d(-70%, -50%, 0) rotateZ(-30deg)',
	zIndex: 0,
};
const queenOut = {
	transform: 'translate3d(-110%, -50%, 0) rotateZ(-10deg)',
	zIndex: 2,
};
const queenEnd = {
	transform: 'translate3d(-30%, -50%, 0) rotateZ(10deg)',
	zIndex: 2,
};
const kingStart = {
	transform: 'translate3d(-30%, -50%, 0) rotateZ(0deg)',
	zIndex: 2,
};
const kingOut = {
	transform: 'translate3d(10%, -50%, 0) rotateZ(10deg)',
	zIndex: 0,
};
const kingEnd = {
	transform: 'translate3d(-70%, -50%, 0) rotateZ(-30deg)',
	zIndex: 0,
};

export const QualifiedPitchGraphic: React.FC<IProps> = ({ animate, className = '' }) => {
	const queenSpring = useSpring({
		config: config.default,
		from: queenStart,
		to: animate ? [queenOut, queenEnd] : queenStart,
	});

	const kingSpring = useSpring({
		config: config.default,
		from: kingStart,
		to: animate ? [kingOut, kingEnd] : kingStart,
	});

	return (
		<div className={`${css(styleSheet.qualifiedPitchContainer)} ${className}`}>
			<animated.div className={css(styleSheet.queenOfHearts)} style={queenSpring}>
				<QueenOfHearsGraphic />
			</animated.div>
			<animated.div className={css(styleSheet.kingOfClubs)} style={kingSpring}>
				<KingOfClubsGraphic />
			</animated.div>
		</div>
	);
};
