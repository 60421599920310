import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const KingOfClubsGraphic: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`king-of-clubs-graphic ${className}`} height={62} width={48}>
			<rect x='3.00024' y='3' width='42' height='56' rx='9' fill='white' stroke='#00528C' strokeWidth='5' />
			<path fillRule='evenodd' clipRule='evenodd' d='M17.0002 14L23.0002 19H17.0002L17.0002 14Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M17.0002 19L20.0002 22H17.0002L17.0002 19Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M20.0002 19L23.0002 22H20.0002L20.0002 19Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M28.0002 19L25.0002 22H28.0002L28.0002 19Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M31.0002 19L28.0002 22H31.0002L31.0002 19Z' fill='#E7C309' />
			<path fillRule='evenodd' clipRule='evenodd' d='M31.0002 14L25.0002 19H31.0002L31.0002 14Z' fill='#E7C309' />
			<circle cx='24.0002' cy='12' r='1' fill='#F3CE0D' />
			<circle cx='31.0002' cy='12' r='1' fill='#F3CE0D' />
			<circle cx='17.0002' cy='12' r='1' fill='#F3CE0D' />
			<path fillRule='evenodd' clipRule='evenodd' d='M24.0002 14L27.0002 19H21.0002L24.0002 14Z' fill='#F9D519' />
			<path fillRule='evenodd' clipRule='evenodd' d='M24.0002 19L25.0002 22H23.0002L24.0002 19Z' fill='#F9D519' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M25.2733 41.4175C25.5436 42.8357 25.9087 44.197 26.4729 45.5068C27.0443 46.8333 28.0214 47.8758 28.9655 48.9441C28.9528 48.9628 28.9402 48.9814 28.9277 49H19.3115C19.8449 48.246 20.4067 47.5195 20.8921 46.7467C21.3858 45.9606 21.6769 45.0785 21.9026 44.1849C22.1265 43.2982 22.3087 42.4014 22.5092 41.5091C22.4797 41.497 22.4503 41.4848 22.4209 41.4726C22.3586 41.5395 22.2963 41.6064 22.234 41.6732C21.1125 42.877 19.7182 43.5644 18.0693 43.7101C15.7028 43.9191 13.8837 42.6455 13.2455 40.3861C12.9182 39.2277 12.8704 38.0799 13.4006 36.9665C14.0021 35.7033 15.0565 34.9358 16.3648 34.4818C17.8555 33.9645 19.3658 33.9526 20.8833 34.3749C20.9595 34.3961 21.0366 34.4147 21.1468 34.3934C21.0964 34.3417 21.0466 34.2893 20.9954 34.2383C19.2787 32.529 18.5096 30.4756 18.761 28.0912C18.9825 25.9887 20.4581 24.4989 22.5836 24.1353C23.8058 23.9262 25.018 23.9415 26.2098 24.3081C27.9439 24.8415 29.089 26.2207 29.2418 27.9938C29.4498 30.4062 28.6394 32.4685 26.9589 34.2192C26.9142 34.2657 26.8686 34.3113 26.8244 34.3582C26.8205 34.3624 26.8239 34.3732 26.8239 34.4189C26.9436 34.3831 27.0538 34.349 27.1648 34.3173C29.3028 33.7077 31.3125 33.9746 33.106 35.2839C36.2787 37.6004 34.9584 41.7402 32.8393 42.967C31.6203 43.6727 30.3194 43.783 28.9673 43.4949C27.576 43.1981 26.376 42.5601 25.4256 41.5023C25.4042 41.4786 25.3804 41.4563 25.3547 41.4371C25.3433 41.4286 25.3247 41.4293 25.2733 41.4175'
				fill='#00528C'
			/>
		</SvgIcon>
	);
};
