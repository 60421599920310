import { AnimatedLionGraphic } from '../AnimatedLionGraphic';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	animate?: boolean;
	className?: string;
}

export const BookedGraphic: React.FC<IProps> = ({ animate, className = '' }) => {
	return (
		<div className={`booked-graphic-container ${css(styleSheet.bookedGraphicContainer)} ${className}`}>
			<AnimatedLionGraphic animate={animate} />
		</div>
	);
};
