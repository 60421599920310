import { borderColor, mention, pink } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	highlight: {
		background: mention,
	},
	invalid: {
		bottom: '100%',
		color: pink,
		fontSize: '12px',
		position: 'absolute',
	},
	invalidTextInput: {
		background: pink,
	},
	option: {
		':hover': {
			background: mention,
			cursor: 'pointer',
		},
		padding: '4px 10px',
	},
	optionsContainer: {
		background: '#fff',
		border: `1px solid ${borderColor}`,
		borderRadius: 4,
		maxHeight: 200,
		minWidth: 200,
		overflow: 'auto',
		padding: '10px 0',
	},
	optionsContainerInner: {
		maxHeight: '100%',
		width: '100%',
	},
	textInput: {},
	textInputContainer: {
		position: 'relative',
	},
	timePickerContainer: {},
});
