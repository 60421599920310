import { CssSelectors, FontFamilies } from '../../../web/styles/styles';
import { brandPrimaryHover } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	close: {
		position: 'absolute',
		right: 10,
		top: 10,
	},
	ctaContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		padding: '20px 30px 55px',
		...CssSelectors.allChildren({
			marginRight: 10,
		}),
	},
	dealField: {
		':first-child': {
			paddingTop: 0,
		},
		':last-child': {
			paddingBottom: 0,
		},
		padding: '7px 0',
	},
	dealForm: {
		flexGrow: 1,
		overflow: 'auto',
		padding: '30px 30px 0',
		position: 'relative',
	},
	dealFormInner: {
		...aidaBaseStyles.flexCol,
		overflow: 'auto',
	},
	dealModal: {
		...aidaBaseStyles.cardShadow,
		background: '#fff',
		boxShadow: '0 0 6px 0 rgba(139,139,139,0.10)',
		display: 'flex',
		flexDirection: 'column',
		height: '100vh',
		position: 'fixed',
		right: 0,
		top: 0,
		width: 500,
	},
	disabled: {
		cursor: 'not-allowed',
		height: 41,
		opacity: 0.5,
		pointerEvents: 'none',
	},
	header: {
		...aidaBaseStyles.flexHorizontalCenter,
		background: brandPrimaryHover,
		justifyContent: 'space-between',
		minHeight: 90,
		padding: '0px 80px 0 30px',
		position: 'relative',
	},
	headerMain: {
		...aidaBaseStyles.flexHorizontalCenter,
	},
	headerTitle: {
		color: '#fff',
		fontFamily: FontFamilies.conqueror,
		fontSize: 40,
		marginLeft: 20,
	},
	hex: {
		...aidaBaseStyles.absoluteCenter,
		height: '100%',
		width: '100%',
	},
	hexContainer: {
		height: 75,
		position: 'relative',
		width: 75,
	},
	icon: {
		height: 17,
		width: 17,
	},
	lion: {
		...aidaBaseStyles.absoluteCenter,
		height: '70%',
		width: '70%',
	},
	title: {
		marginTop: 6,
	},
});
