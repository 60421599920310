import { borderColor, error, lightOrange, titles } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleDeclaration, StyleSheet } from 'aphrodite';

const col: StyleDeclaration = {
	...aidaBaseStyles.flexCol,
	alignSelf: 'stretch',
};

export const styleSheet = StyleSheet.create({
	button: {
		':disabled': {
			opacity: 0.5,
			position: 'relative',
		},
	},
	companyName: {},
	ctaContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'space-between',
		padding: '10px 20px 15px',
	},
	error: {
		color: error,
		fontSize: 12,
		padding: '10px 20px 0',
		textAlign: 'right',
	},
	leftCol: {
		...col,
		borderRight: `1px solid ${borderColor}`,
		overflow: 'hidden',
		position: 'relative',
	},
	leftHeader: {
		padding: '25px 15px 10px',
	},
	manualButton: {
		bottom: 0,
		left: 0,
		margin: '0px 0px 10px 20px',
		position: 'absolute',
	},
	noteEditorContainer: {
		display: 'flex',
		flexGrow: 1,
		minHeight: 530,
	},
	noteEditorContainerShort: {
		minHeight: 496,
	},
	phoneCallInfo: {
		...aidaBaseStyles.flexHorizontalCenter,
		background: lightOrange,
		borderBottom: `1px solid ${borderColor}`,
		color: titles,
		fontSize: 14,
		justifyContent: 'space-between',
		padding: '7px 14px',
	},
	rightCol: {
		...col,
		flexGrow: 1,
		minWidth: 430,
		paddingTop: 10,
	},
	skipContainer: {
		minWidth: 290,
	},
});
