import { IContact } from '../../../extViewmodels';
import { getDisplayName } from '../../../models/UiUtils';
import { Avatar } from '../../../web/components/Avatar';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	contact?: IContact;
}

export const CardableContact: React.FC<IProps> = ({ className = '', contact }) => {
	return (
		<div className={`${css(styleSheet.section, styleSheet.contactContainer)} ${className}`}>
			{!!contact && (
				<>
					<div className={css(styleSheet.avatarContainer)}>
						<Avatar entity={contact} />
					</div>
					<div className={css(styleSheet.contactInfoContainer)}>
						<div className='contact-name'>{getDisplayName(contact)}</div>
						{!!contact.jobTitle && <div className='contact-job-title'>{contact.jobTitle}</div>}
						{contact.primaryEmail ? (
							<div className='contact-email'>{contact.primaryEmail.value}</div>
						) : contact.emailAddresses?.length ? (
							<div className='contact-email'>{contact.emailAddresses[0].value}</div>
						) : null}
					</div>
				</>
			)}
		</div>
	);
};
