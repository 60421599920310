import { useLambda } from '../../../models/hooks/useLambda';
import { PopoverType, TinyPopover } from '../../../web/components/TinyPopover';
import { InfoIcon } from '../../../web/components/svgs/icons/InfoIcon';
import { useQueue } from '../../hooks/queue';
import { white } from '../../styles/colors';
import { ILeadStatusFlag } from '../../viewModels/leads/interfaces';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	className?: string;
}

const Status: React.FC<{ status: ILeadStatusFlag }> = ({ status }) => {
	const [isOpen, , setIsOpen] = useLambda(false);

	return (
		<div className={css(styleSheet.status)} style={{ backgroundColor: status.hexColor }}>
			{status.name}
			{!!status.description && (
				<div onMouseEnter={setIsOpen(true)} onMouseLeave={setIsOpen(false)}>
					<TinyPopover
						anchor={
							<div>
								<InfoIcon className={css(styleSheet.icon)} fillColor={white} />
							</div>
						}
						dismissOnOutsideAction={true}
						isOpen={isOpen}
						key={status.name}
						onRequestClose={setIsOpen(false)}
						type={PopoverType.background}
					>
						<span className={css(styleSheet.description)}>{status.description}</span>
					</TinyPopover>
				</div>
			)}
		</div>
	);
};

const LeadStatusesBase: React.FC<IProps> = ({ className = '' }) => {
	const queue = useQueue();

	const renderStatuses = () => {
		return (queue.lead?.statusFlags || []).map(status => <Status key={status.name} status={status} />);
	};

	return <div className={`${css(styleSheet.leadStatusesContainer)} ${className}`}>{renderStatuses()}</div>;
};

export const LeadStatuses = observer(LeadStatusesBase);
