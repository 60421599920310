import { CssSelectors, FontFamilies } from '../../../web/styles/styles';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	chevron: {},
	link: {
		':hover': {
			...CssSelectors.allChildren(
				{
					marginRight: 15,
				},
				'svg'
			),
		},
		...aidaBaseStyles.flexHorizontalCenter,
		paddingTop: 30,
		textDecoration: 'none',
		...CssSelectors.allChildren(
			{
				marginRight: 5,
				transform: 'rotateZ(90deg)',
				transition: 'margin .3s ease-in-out',
			},
			'svg'
		),
		...CssSelectors.allChildren(
			{
				color: '#fff',
			},
			'span'
		),
	},
	pageNotFoundContainer: {
		...aidaBaseStyles.flexCenter,
		height: '50vh',
	},
	subText: {
		color: '#fff',
		fontSize: '36px',
		textAlign: 'center',
	},
	title: {
		...aidaBaseStyles.flexCenter,
		height: 210,
		...CssSelectors.allChildren(
			{
				color: '#fff',
				fontFamily: FontFamilies.conqueror,
				fontSize: '260px',
			},
			'span'
		),
	},
	wrapper: {
		...aidaBaseStyles.flexCol,
		alignItems: 'center',
	},
});
