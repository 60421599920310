import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { getDefaultDateStringValue } from '../../../../models/UiUtils';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { SlotMachineSymbolGroup } from '../../../components/svgs/slotMachines/SlotMachineSymbolGroup';
import { SlotMachineViewModel } from '../../../viewModels/slotMachines';
import { styleSheet } from './styles';

export interface ISpinRow
	extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	machineType: Api.SlotMachineType;
	slotMachine?: SlotMachineViewModel;
}

export const SpinRow: React.FC<ISpinRow> = observer(props => {
	const { machineType, slotMachine, className, ...restProps } = props;
	const machineTypeToSpinRowProps = () => {
		switch (machineType) {
			case Api.SlotMachineType.BoozeCruise: {
				return ["Daisy's Booze Cruise", styleSheet.boozeCruiseRow] as const;
			}
			case Api.SlotMachineType.GoldRush: {
				return ['Gold Rush', styleSheet.goldRushRow] as const;
			}
			default: {
				return ['High Score', styleSheet.highScoreRow] as const;
			}
		}
	};
	const rowProps = machineTypeToSpinRowProps();
	const spinCount = slotMachine?.spinsRemaining?.length || 0;
	const disabled = spinCount < 1;
	return (
		<button
			className={`${className || ''} ${css(
				disabled ? baseStyleSheet.disabled : null,
				styleSheet.row,
				styleSheet.spinRow,
				!disabled ? styleSheet.spinRowHover : null,
				rowProps[1]
			)}`}
			disabled={disabled}
			{...restProps}
		>
			<span>
				You have &nbsp;
				<span className={css(baseStyleSheet.fontBold)}>{spinCount}</span>
				&nbsp;
				{`extra ${rowProps[0]} spin${spinCount > 1 || spinCount === 0 ? 's' : ''}.${
					spinCount > 0 ? ' Test your luck!' : ''
				}`}
			</span>
		</button>
	);
});

export interface IHistoryRow {
	className?: string;
	prizeLog: Api.IPrizeLog<Api.IGameToken<Api.ISlotMachineSpin>>;
	styles?: StyleDeclarationValue[];
}

export const HistoryRow: React.FC<IHistoryRow> = props => {
	const { prizeLog, className = '', styles = [] } = props;
	return (
		<li className={`${className} ${css(styleSheet.row, styleSheet.historyRow, ...styles)}`}>
			<span className={css(styleSheet.historyRowPrize)}>{prizeLog.gameToken.gameOutcome.prize}</span>
			<span className={css(styleSheet.historyRowSymbols)}>
				<SlotMachineSymbolGroup
					styles={[styleSheet.historyRowSymbols]}
					symbols={Array.from<Api.SlotMachineSymbol>({
						// @ts-ignore
						length: prizeLog.gameToken.gameOutcome.winningPayline.symbolCount,
						// @ts-ignore
					}).fill(prizeLog.gameToken.gameOutcome.winningPayline.symbol)}
					symbolStyles={[styleSheet.historyRowSymbol]}
				/>
			</span>
			<span className={css(styleSheet.historyRowFieldWithLabel)}>
				<span className={css(styleSheet.historyRowLabel)}>When:</span>
				<span>{getDefaultDateStringValue(prizeLog.gameToken.redemptionDate)}</span>
			</span>
			<span className={css(styleSheet.historyRowFieldWithLabel)}>
				<span className={css(styleSheet.historyRowLabel)}>For:</span>
				<span>{prizeLog.achievementDescription}</span>
			</span>
		</li>
	);
};
