import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import { Engine, ISourceOptions } from 'tsparticles-engine';
import { loadEmittersPlugin } from 'tsparticles-plugin-emitters';
import { loadConfettiPreset } from 'tsparticles-preset-confetti';
import { SlotMachineSymbol } from '../SlotMachineSymbol';
import CoinImageUrl from './goldRushCoin.png';
import { styleSheet } from './styles';

interface IGoldRushSymbolProps {
	symbolType: Api.SlotMachineSymbol;
	width: number;
}

export const GoldRushSymbol: React.FC<IGoldRushSymbolProps> = props => {
	const { symbolType, width } = props;
	const style: React.CSSProperties = { height: 'auto', width };
	switch (symbolType) {
		case Api.SlotMachineSymbol.Cherry: {
			style.width = (style.width as number) + 10;
			break;
		}
		case Api.SlotMachineSymbol.Tiger: {
			style.width = (style.width as number) + 5;
			break;
		}
		default: {
			break;
		}
	}
	const styleProvider = (s: React.CSSProperties) => {
		return {
			...s,
			...style,
		};
	};
	return <SlotMachineSymbol style={styleProvider} styles={[styleSheet.symbol]} type={symbolType} />;
};

export const CoinMachine: React.FC<{ minSize?: number }> = ({ minSize = 30 }) => {
	const particlesInit = React.useCallback(async (engine: Engine) => {
		// you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
		// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
		// starting from v2 you can add only the features you need reducing the bundle size
		await loadFull(engine);
		await loadConfettiPreset(engine as any);
		await loadEmittersPlugin(engine);
	}, []);

	const particleOptions = React.useRef<ISourceOptions>({
		autoPlay: true,
		background: {
			color: 'transparent',
		},
		detectRetina: true,
		emitters: [
			{
				direction: 'bottom',
				life: {
					count: 50,
					delay: 5,
					duration: 3,
				},
				position: {
					x: '75%',
					y: '100%',
				},
			},
			{
				direction: 'bottom',
				life: {
					count: 50,
					delay: 5,
					duration: 3,
				},
				position: {
					x: '25%',
					y: '100%',
				},
			},
		],
		fpsLimit: 30,
		particles: {
			collisions: {
				enable: true,
			},
			number: {
				density: {
					area: 2,
				},
				max: 40,
			},
			shape: {
				images: [
					{
						height: 68,
						replaceColor: false,
						src: CoinImageUrl,
						width: 154,
					},
				],
				type: 'images',
			},
			size: {
				random: { enable: true, minimumValue: minSize },
			},
		},
		preset: 'confetti',
	}).current;
	return (
		<div className={css(styleSheet.coinMachine)}>
			<Particles init={particlesInit} options={particleOptions} />
		</div>
	);
};
