import { CssSelectors } from '../../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addButton: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
	},
	addText: {
		color: 'white',
		marginLeft: 8,
	},
	fileInput: {
		display: 'none',
	},
	gamification: {
		display: 'flex',
		...CssSelectors.allChildren({
			maxWidth: '50%',
			minWidth: '50%',
		}),
		margin: '0 auto',
		maxWidth: 1200,
		width: '80%',
	},
	gamificationContainer: {},
	sayinsContainer: {
		margin: 10,
	},
});
