import { CssSelectors } from '../../../web/styles/styles';
import { brandPrimary, brandPrimaryHover } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addActionContainer: {},
	btn: {
		':hover': {
			color: brandPrimary,
		},
		...aidaBaseStyles.flexHorizontalCenter,
		color: brandPrimaryHover,
		fontSize: '14px',
		...CssSelectors.allChildren(
			{
				marginRight: 10,
			},
			'svg'
		),
	},
});
