import { CssSelectors } from '../../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	loadingScreenContainer: {
		height: '100%',
		width: '100%',
	},
	loadingScreenContent: {},
	loadingScreenTitle: {
		height: '33vh',
		width: 'auto',
	},
	modal: {
		...CssSelectors.allChildren(
			{
				background: '#000',
				height: '100vh',
				position: 'fixed',
				width: '100vw',
				...CssSelectors.allChildren(
					{
						background: '#000',
						border: 'none',
						height: '100%',
						maxWidth: '100%',
						minWidth: 0,
						opacity: 0,
						padding: 0,
						position: 'relative',
						transform: 'none',
						width: '100%',
					},
					'.modal-overlay-content'
				),
				...CssSelectors.allChildren(
					{
						opacity: 1,
						transform: 'none',
					},
					'.modal-overlay-content-after-open'
				),
			},
			'.modal-overlay'
		),
	},
	modalCloseButton: {
		position: 'fixed',
		right: 40,
		top: 40,
		zIndex: 1,
	},
	prizeLegend: {
		position: 'absolute',
		right: 60,
		tansition: 'opacity 150ms ease',
		top: 20,
	},
	prizeLegendLoading: {
		opacity: 0,
	},
});
