import { SimpleCheckbox } from '../../generics/SimpleCheckbox';
import { SimpleTag } from '../../generics/SimpleTag';
import { Group } from '../../leadView/components/Group';
import * as React from 'react';

interface IProps {
	checkboxLabel?: string;
	checked?: boolean;
	onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	tagLabel: string;
}

export const LeadPropertyCard: React.FC<IProps> = ({
	checkboxLabel = 'Display this tag on prospect profile',
	checked,
	onCheckboxChange,
	tagLabel,
}) => {
	return (
		<Group vertical={true}>
			<SimpleTag>{tagLabel}</SimpleTag>
			<Group>
				<div />
				<SimpleCheckbox checked={checked} isSmall={true} label={checkboxLabel} onChange={onCheckboxChange} />
			</Group>
		</Group>
	);
};
