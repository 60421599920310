import { StyleSheet } from 'aphrodite';
import { Layouts } from '../../../../web/styles/styles';
import { brandPrimary } from '../../../styles/colors';
import { aidaBaseStyles } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		...Layouts.horizontalStack(10),
	},
	pepper: {
		':hover': {
			boxShadow: '0 0 3px 3px rgba(0,0,0,0.1)',
		},
		height: 40,
		width: 40,
		...aidaBaseStyles.flexCenter,
		borderRadius: 5,
	},
	pepperSelected: {
		border: `2px solid ${brandPrimary}`,
	},
});
