import { FieldKey } from '../../viewModels/form';
import { IUser } from '@ViewModels';
import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export enum LeadViewType {
	Card = 'card',
	Row = 'row',
}

export interface ILeadViewContext {
	viewType: LeadViewType;
	setViewType: Dispatch<SetStateAction<LeadViewType>>;
	selectedUser: Partial<IUser>;
	setSelectedUser: Dispatch<SetStateAction<Partial<IUser>>>;
	selectedOutcome: FieldKey;
	setSelectedOutcome: Dispatch<SetStateAction<FieldKey>>;
	isManager: boolean;
	setIsManager: Dispatch<SetStateAction<boolean>>;
}

export const LeadViewContext = createContext(null);

export const useLeadView = (): ILeadViewContext => {
	const context = useContext(LeadViewContext);
	if (!context) {
		throw new Error('This component must be used within a <LeadView /> component.');
	}
	return context;
};
