import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import { ReactNode } from 'react';
import * as React from 'react';

interface IProps {
	color?: string;
	label: string | ReactNode;
	value: number | string | ReactNode;
	width?: number;
}

export const ListColumn: React.FC<IProps> = ({ color, label, value, width }) => {
	return (
		<div className={css(styleSheet.leaderColumn)} style={{ color, width }}>
			<span>{value}</span>
			<span>{label}</span>
		</div>
	);
};
