import { CssSelectors } from '../../../web/styles/styles';
import { darkBlue, lightBlue, white } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	exportReportButton: {
		alignItems: 'center',
		color: white,
		display: 'flex',
		margin: '-10px -30px 10px auto',
		...CssSelectors.allChildren(
			{
				marginRight: 5,
			},
			':first-child'
		),
	},
	filter: {
		marginBottom: 20,
		...CssSelectors.allDescendants(
			{
				minWidth: 240,
				width: 240,
			},
			'.dropdown .autocomplete-search-field-input, button.datepicker-select, .dropdown.outcome-select'
		),
		...CssSelectors.allDescendants(
			{
				minWidth: 160,
				width: 160,
			},
			'.company-tags-select'
		),
		...CssSelectors.allDescendants(
			{
				minWidth: 180,
				width: 180,
			},
			'.category-select'
		),
	},
	filterButton: {
		color: lightBlue,
		fontSize: 13,
		height: 41,
		padding: 11,
		paddingLeft: 5,
		paddingRight: 0,
		width: '100%',
	},
	filterGroup: {
		...CssSelectors.allDescendants(
			{
				minWidth: 240,
				width: 240,
			},
			'.dropdown .autocomplete-search-field-input, button.datepicker-select, .dropdown.outcome-select'
		),
	},
	filterMobile: {
		backgroundColor: darkBlue,
		marginLeft: -20,
		padding: 20,
		paddingBottom: 0,
		paddingTop: 10,
		width: '100vw !important',
		...CssSelectors.allDescendants(
			{
				minWidth: 220,
				width: 220,
			},
			'.dropdown .autocomplete-search-field-input, button.datepicker-select, .dropdown.outcome-select'
		),
	},
	playIcon: {
		height: 15,
	},
	viewContainer: {
		marginTop: 40,
	},
});
