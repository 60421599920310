import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { LeaderboardScore } from '../../../components/LeaderboardComponents/LeaderboardScore';
import { SortOptions } from '../../../components/LeaderboardComponents/components/ListHeader';
import { ILeaderboardFilter, useLeaderboard } from '../../../entities/Leaderboard/useLeaderboard';
import { FetchStatus } from '../../../entities/utils/useStatesOnFetch';
import { useQueryParameter } from '../../../hooks/query';
import { useInterval } from '../../../hooks/useInterval';
import { styleSheet } from '../styles';
import { Filters } from './Filters';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import * as React from 'react';

let isFirstLoading = true;

const LeaderboardBase: React.FC = () => {
	const [, sortBy, updateSortBy] = useQueryParameter<SortOptions>('sortBy', SortOptions.Demos);
	const [, ascending, updateAscending] = useQueryParameter<boolean>('asc', false);

	// @ts-ignore
	const [filter, setFilter] = useState<ILeaderboardFilter>(null);

	const parsedAscending = JSON.parse(ascending);

	const { get, data, status } = useLeaderboard();

	useEffect(() => {
		get({ asc: parsedAscending === true, filter, sortBy });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter, sortBy, ascending]);

	useEffect(() => {
		if (status === FetchStatus.Done) {
			isFirstLoading = false;
		}
	}, [status]);

	useInterval(() => {
		get({ asc: parsedAscending === true, filter, sortBy });
	}, 30000);

	const userData = data?.scores;

	const renderEmptyState = (
		<div className={css(styleSheet.noLeaders)}>
			<div className='no-leaders-header'>No leaders yet.</div>
			<div>Make that dial and be the first on the board!</div>
		</div>
	);

	const handleFilterChange = (output: ILeaderboardFilter) => {
		setFilter(output);
	};

	const handleSorting = (callback: (param: SortOptions | boolean) => void) => (value: SortOptions | boolean) => {
		callback?.(value);
	};

	const renderList = () => {
		const emptyState = (!userData || !userData?.length) && renderEmptyState;
		return (
			<LeaderboardScore
				asc={parsedAscending}
				// @ts-ignore
				renderEmptyState={isFirstLoading ? emptyState : null}
				renderFilter={<Filters filter={filter} onChange={handleFilterChange} />}
				// @ts-ignore
				setAsc={handleSorting(updateAscending)}
				// @ts-ignore
				setSortBy={handleSorting(updateSortBy)}
				sortBy={sortBy}
				// @ts-ignore
				top10={userData}
				// @ts-ignore
				totalScores={data}
			/>
		);
	};

	const isLoading = status !== FetchStatus.Done && !userData;

	return (
		<div className={css(styleSheet.leaderboardContainer)}>
			<div className={css(styleSheet.boardsContainer)}>
				{isLoading && isFirstLoading ? <LoadingSpinner /> : renderList()}
			</div>
		</div>
	);
};

export const Leaderboard = observer(LeaderboardBase);
