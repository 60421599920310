import { ActionItemViewModel } from '../../../extViewmodels';
import { Checkbox } from '../../../web/components/Checkbox';
import { actionItemTintColor, kitTintColor } from '../../styles/colors';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	actionItem: ActionItemViewModel;
}

export const AidaActionItem: React.FC<IProps> = ({ actionItem, className = '' }) => {
	return (
		<div className={`${css(styleSheet.aidaActionItemContainer)} ${className}`}>
			<div
				className={css(styleSheet.indicator)}
				style={{
					background: actionItem.isKeepInTouchActionItem ? kitTintColor : actionItemTintColor,
				}}
			/>
			<div className={css(styleSheet.checkboxContainer)}>
				{/* @ts-ignore */}
				<Checkbox checked={!!actionItem.isCompleted} id={actionItem.id} />
			</div>
			<div>action item</div>
		</div>
	);
};
