import { aidaBaseStyles } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	hookRejectedGraphicContainer: {
		height: '100%',
		position: 'relative',
		width: '100%',
	},
	sailboat: {
		...aidaBaseStyles.absoluteCenter,
	},
	sailboatWater: {
		bottom: 6,
		left: 0,
		position: 'absolute',
		width: '100%',
	},
});
