import { FontFamilies } from '../../../web/styles/styles';
import { black, destructive } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	item: {
		cursor: 'pointer',
	},
	statusCompleted: {
		color: black,
		fontFamily: FontFamilies.bold,
	},
	statusFailed: {
		color: destructive,
		fontFamily: FontFamilies.bold,
	},
	title: {
		marginBottom: 5,
		marginRight: 25,
	},
});
