import { IPrizeLog } from '../../../../../extViewmodels';
import { LoadingSpinner } from '../../../../../web/components/LoadingSpinner';
import { styleSheet } from '../styles';
import { ListItem } from './ListItem';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	isLoading?: boolean;
	items: IPrizeLog[];
	setGameTokenAsPaid?: (gameTokenId: string) => void;
}

export const ListItemsContainer: React.FC<IProps> = ({ isLoading, items, setGameTokenAsPaid }) => {
	return (
		<>
			{isLoading ? (
				<LoadingSpinner type='large' className={css(styleSheet.loadingSpinner)} />
			) : (
				items?.map(item => {
					return <ListItem key={item?.gameToken?.id} item={item} setGameTokenAsPaid={setGameTokenAsPaid} />;
				})
			)}
		</>
	);
};
