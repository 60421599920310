import { brandPrimaryHover } from '../../../styles/colors';
import { aidaBaseStyles } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		display: 'flex',
		marginBottom: 6,
	},
	date: {
		color: '#858585',
	},
	pepperIcon: {
		height: 20,
		width: 20,
	},
	text: {
		...aidaBaseStyles.truncateText,
		':hover': {
			color: brandPrimaryHover,
			textDecoration: 'underline',
		},
		cursor: 'pointer',
		fontSize: 14,
		marginLeft: 10,
	},
});
