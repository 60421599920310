import { CssSelectors } from '../../../web/styles/styles';
import { white } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

const edgeThreshold = 80;

export const styleSheet = StyleSheet.create({
	closeButton: {
		position: 'absolute',
		top: 0,
	},
	closeLeft: {
		left: 0,
	},
	closeRight: {
		right: 0,
	},
	closeRightInternal: {
		right: 10,
		top: 10,
		...CssSelectors.allDescendants(
			{
				fill: white,
			},
			'svg.svg-icon polygon'
		),
	},
	fromBottom: {
		bottom: edgeThreshold,
		left: '50%',
	},
	fromLeft: {
		left: edgeThreshold * 2,
		top: '50%',
	},
	fromLeftBottom: {
		bottom: edgeThreshold,
		left: edgeThreshold * 2,
	},
	fromLeftTop: {
		left: edgeThreshold * 2,
		top: edgeThreshold,
	},
	fromRight: {
		right: edgeThreshold * 2,
		top: '50%',
	},
	fromRightBottom: {
		bottom: edgeThreshold,
		right: edgeThreshold * 2,
	},
	fromRightTop: {
		right: edgeThreshold * 2,
		top: edgeThreshold,
	},
	fromTop: {
		left: '50%',
		top: edgeThreshold,
	},
	mrry: {},
	mrryContainer: {
		position: 'absolute',
		top: '40%',
		transform: 'translate(0, -50%)',
	},
	mrryContainerLeft: {
		justifyContent: 'flex-start',
		right: '100%',
		...CssSelectors.allChildren(
			{
				transform: 'rotateY(180deg)',
			},
			'svg'
		),
	},
	mrryContainerRight: {
		justifyContent: 'flex-end',
		left: '100%',
	},
	mrryModalContainer: {
		padding: 20,
		position: 'absolute',
		zIndex: 20,
	},
	speechBubble: {
		zIndex: 5,
	},
});
