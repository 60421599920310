import { brandSecondary } from '../../styles/colors';
import { TransparentButton } from '../TransparentButton';
import { LocationIcon } from '../svgs/icons/LocationIcon';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	onClick(): void;
	name?: string;
}

export const ExternalLink: React.FC<IProps> = ({ className = '', onClick, name }) => {
	return (
		<div className={`${css(styleSheet.container)} ${className}`}>
			<TransparentButton className={css(styleSheet.google)} onClick={onClick}>
				{name ?? 'Google'}
				<LocationIcon className={css(styleSheet.locationIcon)} fillColor={brandSecondary} />
			</TransparentButton>
		</div>
	);
};
