import { CssSelectors, FontFamilies } from '../../../../web/styles/styles';
import { actionItemTintColor, brandPrimary, brandSecondary, white } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	card: {
		backgroundColor: brandPrimary,
		borderRadius: 10,
		justifyContent: 'space-between',
		padding: 30,
	},

	cardAmount: {
		color: actionItemTintColor,
		fontFamily: FontFamilies.bold,
		fontSize: 16,
		width: '100%',
	},
	cardLastColumn: {
		textAlign: 'right',
	},
	cardRegularText: {
		color: white,
		fontSize: 14,
		whiteSpace: 'nowrap',
		width: '100%',
	},
	cardRegularTextTitle: {
		color: brandSecondary,
		width: '100%',
	},
	cardTokens: {
		width: '100%',
	},

	checkbox: {
		color: 'white',
	},
	checkboxPaid: {
		color: actionItemTintColor,
	},

	container: {
		padding: 20,
		paddingBottom: 40,
	},
	filter: {
		justifyContent: 'space-between',
		margin: 'auto',
		maxWidth: 1100,
		paddingTop: 30,
	},

	itemAchievement: {
		flexShrink: 0,
		width: 200,
	},
	itemDate: {
		flexShrink: 0,
		width: 170,
	},
	itemName: {
		flexShrink: 0,
		width: 200,
	},
	itemPrize: {
		flexShrink: 0,
		width: 80,
	},
	itemSpinResult: {
		flexShrink: 0,
		width: 250,
	},

	listItemsContainer: {
		margin: 'auto',
		maxWidth: 1100,
		paddingTop: 20,
	},

	loadingSpinner: {
		marginTop: 30,
	},

	regularText: {
		color: white,
	},

	toggleLabel: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allDescendants(
			{
				marginLeft: 3,
			},
			'span'
		),
	},
});
