import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { IPhoneCall } from '../../../../../extViewmodels';
import { copyToClipboard, getDisplayName } from '../../../../../models/UiUtils';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';
import { useAudio } from '../../../../contexts/audioPlayer';
import { useToaster } from '../../../../hooks';
import { CopyIcon } from '../../../svgs/icons/CopyIcon';
import { styleSheet } from './../styles';

export const Recording: React.FC<IPhoneCall> = ({ recording, creationDate, creator, phoneNumber, id }) => {
	const audio = useAudio();
	const toaster = useToaster();
	const userSession = useUserSession();

	const file = recording;
	const onAudioPlay = (e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		audio.setIsOpen(true)();
		// @ts-ignore
		// @ts-ignore
		audio.setAudioSrc(file.publicUrl);
		audio.setTitle(`Call Recording (${moment(creationDate).format('M/DD/YYYY [at] h:mma')})`);
		// @ts-ignore
		audio.setSubtitle(`${getDisplayName(creator)} called ${phoneNumber?.standard}`);
		// @ts-ignore
		audio.setPhoneCallId(id);
	};
	const copyAudioLink = (evt: React.MouseEvent<HTMLSpanElement>) => {
		evt.stopPropagation();
		const url = `${userSession.webServiceHelper.baseUrl}/PhoneCall/${id}/Download`;
		if (copyToClipboard(url)) {
			// @ts-ignore
			toaster.push({
				message: `Link copied to clipboard`,
				type: 'successMessage',
			});
		} else {
			// @ts-ignore
			toaster.push({
				message: `Email failed to copy to clipboard`,
				type: 'errorMessage',
			});
		}
	};

	if (file?.publicUrl) {
		return (
			<div className={css(styleSheet.audioColumn)}>
				<div className={css(styleSheet.reportLink, styleSheet.fontSizeSmall)} onClick={onAudioPlay}>
					Play
				</div>
				{userSession.account?.features?.aida?.allowAnonymousAccessToCallRecording && (
					<div className={css(styleSheet.reportLink, styleSheet.copyAudioLink)} onClick={copyAudioLink}>
						<CopyIcon fillColor='#D3DBDB' />
					</div>
				)}
			</div>
		);
	}

	return <div className={css(styleSheet.empty, styleSheet.fontSizeSmall)}>None</div>;
};
