import { useGamification } from '../../hooks';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';

interface IProps {
	className?: string;
}

const PointsProgressBarBase: React.FC<IProps> = ({ className = '' }) => {
	const gamification = useGamification();

	if (!gamification.goals) {
		return null;
	}

	const barColor =
		gamification.percentToGoal.points >= 100
			? styleSheet.progressGreen
			: gamification.percentToGoal.points >= 90
				? styleSheet.progressYellow
				: styleSheet.progressRed;

	return (
		<div className={`${css(styleSheet.pointsProgressBarContainer)} ${className}`}>
			<div className={css(styleSheet.progressBar)}>
				<div
					className={css(styleSheet.progress, barColor)}
					style={{
						width: `${Math.min(gamification.userPercentage.points, 100)}%`,
					}}
				/>
			</div>
			<div
				className={css(styleSheet.marker)}
				style={{
					left: `${Math.min(gamification.goalPercentage.points / 100, 1) * 400}px`,
				}}
			>
				<div className={css(styleSheet.markerText)}>You should be here by {moment().format('h:mma')}</div>
			</div>
			<div className={css(styleSheet.pointsContainer)}>
				<span>
					{gamification.points || '0'} / {gamification.goals?.points || '000'}
				</span>
				<span>PTS</span>
			</div>
		</div>
	);
};

export const PointsProgressBar = observer(PointsProgressBarBase);
