import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const EightOfClubsGraphic: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`eight-of-clubs-graphic ${className}`} height={70} width={54}>
			<rect x='3' y='3' width='48' height='64' rx='9' fill='white' stroke='#00528C' strokeWidth='5' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.1372 19.6247C17.2413 20.2121 17.3818 20.7758 17.5991 21.3182C17.8191 21.8675 18.1953 22.2992 18.5588 22.7417C18.5539 22.7494 18.5491 22.7571 18.5442 22.7648H14.8417C15.0471 22.4526 15.2634 22.1517 15.4503 21.8317C15.6404 21.5062 15.7525 21.1409 15.8394 20.7708C15.9256 20.4036 15.9957 20.0322 16.0729 19.6627C16.0616 19.6577 16.0503 19.6526 16.0389 19.6476C16.0149 19.6753 15.991 19.703 15.967 19.7307C15.5352 20.2292 14.9984 20.5138 14.3635 20.5741C13.4523 20.6607 12.7519 20.1333 12.5062 19.1976C12.3802 18.7179 12.3618 18.2426 12.5659 17.7815C12.7975 17.2584 13.2035 16.9406 13.7072 16.7526C14.2811 16.5383 14.8627 16.5334 15.4469 16.7083C15.4763 16.7171 15.5059 16.7248 15.5484 16.7159C15.529 16.6945 15.5098 16.6728 15.4901 16.6517C14.8291 15.9439 14.533 15.0935 14.6298 14.1061C14.7151 13.2354 15.2832 12.6185 16.1016 12.4679C16.5722 12.3813 17.0389 12.3876 17.4978 12.5395C18.1655 12.7604 18.6063 13.3315 18.6652 14.0658C18.7453 15.0648 18.4332 15.9188 17.7862 16.6438C17.769 16.6631 17.7514 16.6819 17.7344 16.7014C17.7329 16.7031 17.7342 16.7076 17.7342 16.7265C17.7803 16.7117 17.8227 16.6976 17.8655 16.6844C18.6887 16.432 19.4625 16.5425 20.153 17.0847C21.3746 18.044 20.8662 19.7584 20.0503 20.2664C19.5809 20.5587 19.0801 20.6044 18.5595 20.485C18.0238 20.3622 17.5618 20.0979 17.1958 19.6599C17.1876 19.65 17.1784 19.6408 17.1686 19.6329C17.1641 19.6294 17.157 19.6296 17.1372 19.6247'
				fill='#00528C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.1372 36.5659C17.2413 37.1532 17.3818 37.717 17.5991 38.2594C17.8191 38.8087 18.1953 39.2404 18.5588 39.6828C18.5539 39.6906 18.5491 39.6983 18.5442 39.706H14.8417C15.0471 39.3937 15.2634 39.0929 15.4503 38.7729C15.6404 38.4473 15.7525 38.082 15.8394 37.7119C15.9256 37.3447 15.9957 36.9734 16.0729 36.6039C16.0616 36.5988 16.0503 36.5938 16.0389 36.5887C16.0149 36.6165 15.991 36.6441 15.967 36.6718C15.5352 37.1703 14.9984 37.455 14.3635 37.5153C13.4523 37.6019 12.7519 37.0744 12.5062 36.1388C12.3802 35.6591 12.3618 35.1837 12.5659 34.7227C12.7975 34.1996 13.2035 33.8817 13.7072 33.6937C14.2811 33.4795 14.8627 33.4746 15.4469 33.6495C15.4763 33.6583 15.5059 33.666 15.5484 33.6571C15.529 33.6357 15.5098 33.614 15.4901 33.5929C14.8291 32.885 14.533 32.0347 14.6298 31.0473C14.7151 30.1766 15.2832 29.5596 16.1016 29.4091C16.5722 29.3225 17.0389 29.3288 17.4978 29.4806C18.1655 29.7015 18.6063 30.2726 18.6652 31.007C18.7453 32.006 18.4332 32.86 17.7862 33.585C17.769 33.6042 17.7514 33.6231 17.7344 33.6425C17.7329 33.6443 17.7342 33.6488 17.7342 33.6677C17.7803 33.6529 17.8227 33.6387 17.8655 33.6256C18.6887 33.3731 19.4625 33.4837 20.153 34.0259C21.3746 34.9852 20.8662 36.6995 20.0503 37.2076C19.5809 37.4999 19.0801 37.5455 18.5595 37.4262C18.0238 37.3033 17.5618 37.0391 17.1958 36.6011C17.1876 36.5912 17.1784 36.582 17.1686 36.574C17.1641 36.5705 17.157 36.5708 17.1372 36.5659'
				fill='#00528C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M37.8431 19.6247C37.9472 20.2121 38.0878 20.7758 38.305 21.3182C38.525 21.8675 38.9012 22.2992 39.2647 22.7417C39.2599 22.7494 39.255 22.7571 39.2502 22.7648H35.5477C35.753 22.4526 35.9694 22.1517 36.1563 21.8317C36.3464 21.5062 36.4584 21.1409 36.5453 20.7708C36.6315 20.4036 36.7017 20.0322 36.7789 19.6627C36.7675 19.6577 36.7562 19.6526 36.7449 19.6476C36.7209 19.6753 36.6969 19.703 36.6729 19.7307C36.2411 20.2292 35.7043 20.5138 35.0694 20.5741C34.1582 20.6607 33.4578 20.1333 33.2121 19.1976C33.0861 18.7179 33.0677 18.2426 33.2718 17.7815C33.5034 17.2584 33.9094 16.9406 34.4131 16.7526C34.9871 16.5383 35.5686 16.5334 36.1529 16.7083C36.1822 16.7171 36.2119 16.7248 36.2543 16.7159C36.2349 16.6945 36.2157 16.6728 36.196 16.6517C35.5351 15.9439 35.2389 15.0935 35.3357 14.1061C35.421 13.2354 35.9891 12.6185 36.8075 12.4679C37.2781 12.3813 37.7448 12.3876 38.2037 12.5395C38.8714 12.7604 39.3123 13.3315 39.3711 14.0658C39.4512 15.0648 39.1392 15.9188 38.4921 16.6438C38.4749 16.6631 38.4574 16.6819 38.4403 16.7014C38.4388 16.7031 38.4402 16.7076 38.4402 16.7265C38.4862 16.7117 38.5287 16.6976 38.5714 16.6844C39.3946 16.432 40.1684 16.5425 40.8589 17.0847C42.0805 18.044 41.5721 19.7584 40.7562 20.2664C40.2869 20.5587 39.786 20.6044 39.2654 20.485C38.7297 20.3622 38.2677 20.0979 37.9018 19.6599C37.8935 19.65 37.8843 19.6408 37.8745 19.6329C37.8701 19.6294 37.8629 19.6296 37.8431 19.6247'
				fill='#00528C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M37.8431 36.5659C37.9472 37.1532 38.0878 37.717 38.305 38.2594C38.525 38.8087 38.9012 39.2404 39.2647 39.6828C39.2599 39.6906 39.255 39.6983 39.2502 39.706H35.5477C35.753 39.3937 35.9694 39.0929 36.1563 38.7729C36.3464 38.4473 36.4584 38.082 36.5453 37.7119C36.6315 37.3447 36.7017 36.9734 36.7789 36.6039C36.7675 36.5988 36.7562 36.5938 36.7449 36.5887C36.7209 36.6165 36.6969 36.6441 36.6729 36.6718C36.2411 37.1703 35.7043 37.455 35.0694 37.5153C34.1582 37.6019 33.4578 37.0744 33.2121 36.1388C33.0861 35.6591 33.0677 35.1837 33.2718 34.7227C33.5034 34.1996 33.9094 33.8817 34.4131 33.6937C34.9871 33.4795 35.5686 33.4746 36.1529 33.6495C36.1822 33.6583 36.2119 33.666 36.2543 33.6571C36.2349 33.6357 36.2157 33.614 36.196 33.5929C35.5351 32.885 35.2389 32.0347 35.3357 31.0473C35.421 30.1766 35.9891 29.5596 36.8075 29.4091C37.2781 29.3225 37.7448 29.3288 38.2037 29.4806C38.8714 29.7015 39.3123 30.2726 39.3711 31.007C39.4512 32.006 39.1392 32.86 38.4921 33.585C38.4749 33.6042 38.4574 33.6231 38.4403 33.6425C38.4388 33.6443 38.4402 33.6488 38.4402 33.6677C38.4862 33.6529 38.5287 33.6387 38.5714 33.6256C39.3946 33.3731 40.1684 33.4837 40.8589 34.0259C42.0805 34.9852 41.5721 36.6995 40.7562 37.2076C40.2869 37.4999 39.786 37.5455 39.2654 37.4262C38.7297 37.3033 38.2677 37.0391 37.9018 36.6011C37.8935 36.5912 37.8843 36.582 37.8745 36.574C37.8701 36.5705 37.8629 36.5708 37.8431 36.5659'
				fill='#00528C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M27.4901 27.154C27.5942 27.7414 27.7347 28.3051 27.952 28.8475C28.172 29.3968 28.5482 29.8285 28.9117 30.271C28.9068 30.2787 28.902 30.2864 28.8972 30.2941H25.1946C25.4 29.9819 25.6163 29.681 25.8032 29.361C25.9933 29.0354 26.1054 28.6702 26.1923 28.3001C26.2785 27.9329 26.3487 27.5615 26.4258 27.192C26.4145 27.187 26.4032 27.1819 26.3918 27.1769C26.3679 27.2046 26.3439 27.2323 26.3199 27.26C25.8881 27.7584 25.3513 28.0431 24.7164 28.1034C23.8052 28.19 23.1048 27.6626 22.8591 26.7269C22.7331 26.2472 22.7147 25.7719 22.9188 25.3108C23.1504 24.7877 23.5564 24.4699 24.0601 24.2819C24.634 24.0676 25.2156 24.0627 25.7998 24.2376C25.8292 24.2464 25.8588 24.2541 25.9013 24.2452C25.8819 24.2238 25.8627 24.2021 25.843 24.181C25.182 23.4732 24.8859 22.6228 24.9827 21.6354C25.068 20.7647 25.6361 20.1478 26.4545 19.9972C26.9251 19.9106 27.3918 19.9169 27.8507 20.0688C28.5184 20.2896 28.9592 20.8608 29.0181 21.5951C29.0982 22.5941 28.7861 23.4481 28.1391 24.1731C28.1219 24.1924 28.1043 24.2112 28.0873 24.2307C28.0858 24.2324 28.0871 24.2369 28.0871 24.2558C28.1332 24.241 28.1756 24.2268 28.2184 24.2137C29.0416 23.9613 29.8154 24.0718 30.5059 24.614C31.7275 25.5733 31.2191 27.2877 30.4032 27.7957C29.9339 28.088 29.433 28.1337 28.9124 28.0143C28.3767 27.8915 27.9147 27.6272 27.5488 27.1892C27.5405 27.1793 27.5313 27.1701 27.5215 27.1622C27.5171 27.1586 27.5099 27.1589 27.4901 27.154'
				fill='#00528C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.1372 49.4341C17.2413 48.8468 17.3818 48.283 17.5991 47.7406C17.8191 47.1913 18.1953 46.7596 18.5588 46.3172C18.5539 46.3094 18.5491 46.3017 18.5442 46.294H14.8417C15.0471 46.6063 15.2634 46.9071 15.4503 47.2271C15.6404 47.5527 15.7525 47.918 15.8394 48.2881C15.9256 48.6553 15.9957 49.0266 16.0729 49.3961C16.0616 49.4012 16.0503 49.4062 16.0389 49.4113C16.0149 49.3835 15.991 49.3559 15.967 49.3282C15.5352 48.8297 14.9984 48.545 14.3635 48.4847C13.4523 48.3981 12.7519 48.9256 12.5062 49.8612C12.3802 50.3409 12.3618 50.8163 12.5659 51.2773C12.7975 51.8004 13.2035 52.1183 13.7072 52.3063C14.2811 52.5205 14.8627 52.5254 15.4469 52.3505C15.4763 52.3417 15.5059 52.334 15.5484 52.3429C15.529 52.3643 15.5098 52.386 15.4901 52.4071C14.8291 53.115 14.533 53.9653 14.6298 54.9527C14.7151 55.8234 15.2832 56.4404 16.1016 56.5909C16.5722 56.6775 17.0389 56.6712 17.4978 56.5194C18.1655 56.2985 18.6063 55.7274 18.6652 54.993C18.7453 53.994 18.4332 53.14 17.7862 52.415C17.769 52.3958 17.7514 52.3769 17.7344 52.3575C17.7329 52.3557 17.7342 52.3512 17.7342 52.3323C17.7803 52.3471 17.8227 52.3613 17.8655 52.3744C18.6887 52.6269 19.4625 52.5163 20.153 51.9741C21.3746 51.0148 20.8662 49.3005 20.0503 48.7924C19.5809 48.5001 19.0801 48.4545 18.5595 48.5738C18.0238 48.6967 17.5618 48.9609 17.1958 49.3989C17.1876 49.4088 17.1784 49.418 17.1686 49.426C17.1641 49.4295 17.157 49.4292 17.1372 49.4341'
				fill='#00528C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M37.8431 49.4341C37.9472 48.8468 38.0878 48.283 38.305 47.7406C38.525 47.1913 38.9012 46.7596 39.2647 46.3172C39.2599 46.3094 39.255 46.3017 39.2502 46.294H35.5477C35.753 46.6063 35.9694 46.9071 36.1563 47.2271C36.3464 47.5527 36.4584 47.918 36.5453 48.2881C36.6315 48.6553 36.7017 49.0266 36.7789 49.3961C36.7675 49.4012 36.7562 49.4062 36.7449 49.4113C36.7209 49.3835 36.6969 49.3559 36.6729 49.3282C36.2411 48.8297 35.7043 48.545 35.0694 48.4847C34.1582 48.3981 33.4578 48.9256 33.2121 49.8612C33.0861 50.3409 33.0677 50.8163 33.2718 51.2773C33.5034 51.8004 33.9094 52.1183 34.4131 52.3063C34.9871 52.5205 35.5686 52.5254 36.1529 52.3505C36.1822 52.3417 36.2119 52.334 36.2543 52.3429C36.2349 52.3643 36.2157 52.386 36.196 52.4071C35.5351 53.115 35.2389 53.9653 35.3357 54.9527C35.421 55.8234 35.9891 56.4404 36.8075 56.5909C37.2781 56.6775 37.7448 56.6712 38.2037 56.5194C38.8714 56.2985 39.3123 55.7274 39.3711 54.993C39.4512 53.994 39.1392 53.14 38.4921 52.415C38.4749 52.3958 38.4574 52.3769 38.4403 52.3575C38.4388 52.3557 38.4402 52.3512 38.4402 52.3323C38.4862 52.3471 38.5287 52.3613 38.5714 52.3744C39.3946 52.6269 40.1684 52.5163 40.8589 51.9741C42.0805 51.0148 41.5721 49.3005 40.7562 48.7924C40.2869 48.5001 39.786 48.4545 39.2654 48.5738C38.7297 48.6967 38.2677 48.9609 37.9018 49.3989C37.8935 49.4088 37.8843 49.418 37.8745 49.426C37.8701 49.4295 37.8629 49.4292 37.8431 49.4341'
				fill='#00528C'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M27.4901 40.0225C27.5942 39.4351 27.7347 38.8714 27.952 38.329C28.172 37.7797 28.5482 37.348 28.9117 36.9056C28.9068 36.8978 28.902 36.8901 28.8972 36.8824H25.1946C25.4 37.1946 25.6163 37.4955 25.8032 37.8155C25.9933 38.1411 26.1054 38.5064 26.1923 38.8764C26.2785 39.2436 26.3487 39.615 26.4258 39.9845C26.4145 39.9895 26.4032 39.9946 26.3918 39.9996C26.3679 39.9719 26.3439 39.9442 26.3199 39.9166C25.8881 39.4181 25.3513 39.1334 24.7164 39.0731C23.8052 38.9865 23.1048 39.5139 22.8591 40.4496C22.7331 40.9293 22.7147 41.4046 22.9188 41.8657C23.1504 42.3888 23.5564 42.7067 24.0601 42.8947C24.634 43.1089 25.2156 43.1138 25.7998 42.9389C25.8292 42.9301 25.8588 42.9224 25.9013 42.9313C25.8819 42.9527 25.8627 42.9744 25.843 42.9955C25.182 43.7034 24.8859 44.5537 24.9827 45.5411C25.068 46.4118 25.6361 47.0288 26.4545 47.1793C26.9251 47.2659 27.3918 47.2596 27.8507 47.1078C28.5184 46.8869 28.9592 46.3157 29.0181 45.5814C29.0982 44.5824 28.7861 43.7284 28.1391 43.0034C28.1219 42.9841 28.1043 42.9653 28.0873 42.9459C28.0858 42.9441 28.0871 42.9396 28.0871 42.9207C28.1332 42.9355 28.1756 42.9497 28.2184 42.9628C29.0416 43.2152 29.8154 43.1047 30.5059 42.5625C31.7275 41.6032 31.2191 39.8888 30.4032 39.3808C29.9339 39.0885 29.433 39.0428 28.9124 39.1622C28.3767 39.2851 27.9147 39.5493 27.5488 39.9873C27.5405 39.9972 27.5313 40.0064 27.5215 40.0143C27.5171 40.0179 27.5099 40.0176 27.4901 40.0225'
				fill='#00528C'
			/>
		</SvgIcon>
	);
};
