import { IContact } from '../../../viewmodels/AppViewModels';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { useErrorMessages } from '../../hooks';
import { useQueue } from '../../hooks/queue';
import { AddAction } from '../AddAction';
import { CompanyContacts } from '../CompanyContacts';
import { ContactInfo } from '../ContactInfo';
import { useSaveContact } from '../ContactInfo/useSaveContact';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import * as React from 'react';

interface IProps {
	className?: string;
}

export interface IContactModalProps {
	contact: IContact;
	show: boolean;
}

const ContactCardsBase: React.FC<IProps> = () => {
	const queue = useQueue();
	const errorMessages = useErrorMessages();
	const [isOpenNewContact, setOpenNewContact] = useState(null);

	const saveContact = useSaveContact();

	useEffect(() => {
		queue?.lead
			?.getContacts()
			.then(() => {
				// @ts-ignore
				setOpenNewContact(false);
			})
			.catch(err => {
				// @ts-ignore
				errorMessages.pushApiError(err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue.companyId]);

	const onClickToSave = (contact: IContact, original?: IContact) => {
		saveContact(contact, original).then(response => {
			if (response) {
				// @ts-ignore
				setOpenNewContact(false);
			}
		});
	};

	const renderLeadInfo = () => {
		let siteLink = queue.lead?.company?.webSite;
		if (!!siteLink && !siteLink.startsWith('http://') && !siteLink.startsWith('https://')) {
			siteLink = 'http://' + siteLink;
		}

		// @ts-ignore
		const toggleNewContactForm = (state: boolean) => () => setOpenNewContact(state);

		// @ts-ignore
		const handleClickToEdit = () => setOpenNewContact(false);

		return (
			<>
				{queue.lead?.fetchingContacts ? (
					<LoadingSpinner />
				) : (
					<CompanyContacts
						contacts={queue.lead?.contacts}
						onSave={onClickToSave}
						onClickToEdit={handleClickToEdit}
						// @ts-ignore
						newContactIsOpen={isOpenNewContact}
					/>
				)}
				<div className={css(styleSheet.addActionsContainer)}>
					{isOpenNewContact && <ContactInfo onCancel={toggleNewContactForm(false)} onSave={onClickToSave} />}
					{!isOpenNewContact && (
						<AddAction className={css(styleSheet.addAction)} onClick={toggleNewContactForm(true)} text='Add contact' />
					)}
				</div>
			</>
		);
	};

	return <>{renderLeadInfo()}</>;
};

export const ContactCards = observer(ContactCardsBase);
