import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../../../web/styles/styles';
import { brandPrimary, brandSecondary } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	customButton: {
		border: `1px solid ${brandSecondary}`,
		borderRadius: 3,
		color: 'rgb(167 171 173)',
		fontSize: 14,
		padding: '10px',
		width: 300,
		...CssSelectors.allDescendants(
			{
				display: 'block',
				textAlign: 'left',
				width: '100%',
			},
			'span'
		),
	},
	wrapper: {
		display: 'flex',
		position: 'relative',
		...CssSelectors.allDescendants(
			{
				background: `${brandPrimary}`,
			},
			'button:first-of-type'
		),
	},
});
