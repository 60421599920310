import { useAchievementSubscription } from './hooks';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';
import { ToastContainer } from 'react-toastify';

/**
 * Note: audio files are from: https://mixkit.co/free-sound-effects/slot-machine/ License states free to use in
 * comercial projects: https://mixkit.co/license/#sfxFree
 */
export const WsNotificationProvider: React.FC = ({ children }) => {
	useAchievementSubscription();
	return (
		<>
			{children}
			<ToastContainer
				position='bottom-center'
				autoClose={false}
				hideProgressBar={true}
				className={css(styleSheet.toastContainer)}
				newestOnTop={true}
				draggable={false}
				closeButton={false}
			/>
		</>
	);
};
