import { CssSelectors } from '../../../../../web/styles/styles';
import { grayIconFill, green } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	col: {
		':last-child': {
			flexGrow: 0,
			fontSize: 12,
			width: 90,
		},
		flexGrow: 1,
		textAlign: 'left',
	},
	colDisabled: {
		color: grayIconFill,
		...CssSelectors.allDescendants(
			{
				opacity: 0.6,
			},
			'svg'
		),
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		color: grayIconFill,
		display: 'flex',
		flexDirection: 'row',
		fontSize: 12,
		marginBottom: 5,
		textTransform: 'uppercase',
		...CssSelectors.allChildren(
			{
				paddingLeft: 20,
			},
			':first-child'
		),
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		...CssSelectors.allChildren(
			{
				color: green,
			},
			':last-child'
		),
	},
	scrollablePropertyList: {
		maxHeight: 370,
		overflowY: 'scroll',
	},
});
