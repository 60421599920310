import { brandPrimaryLight } from '../../../../styles/colors';
import { aidaBaseStyleSheet } from '../../../../styles/styles';
import { styleSheet } from '../../LeadViewRowElement/styles';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';

interface IProps {
	color?: string;
	date: string;
	name: string;
}

export const OutcomeInfo: React.FC<IProps> = ({ color, date, name }) => {
	const hasInfo = name && date;
	return (
		<div>
			{hasInfo ? (
				<>
					<div className={`outcome-info-title ${css(styleSheet.lastInteraction)}`}>
						<span>{moment(date).format('ddd, MM/DD/YY, h:mmA')}</span>
					</div>
					<div className={css(aidaBaseStyleSheet.flexHorizontalCenter)}>
						<div className={css(styleSheet.lastInteraction)}>
							<div className={css(styleSheet.dot)} style={{ background: color || brandPrimaryLight }} />
							<span>{name}</span>
						</div>
					</div>
				</>
			) : (
				<span className={css(styleSheet.label)}>No interactions yet</span>
			)}
		</div>
	);
};
