import { CssSelectors, FontFamilies } from '../../../web/styles/styles';
import { darkBlue, white, yellow } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	boardsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-evenly',
		paddingBottom: 10,
		width: '100%',
	},
	// DaySlider
	daySliderButton: {
		alignItems: 'center',
		display: 'flex',
		height: 26,
		justifyContent: 'center',
		width: 26,
	},
	daySliderDate: {
		color: white,
		fontFamily: FontFamilies.conqueror,
		fontSize: 18,
		height: 14,
		lineHeight: 1,
		textAlign: 'center',
		width: 310,
	},
	daySliderIcon: {
		height: 16,
		width: 16,
	},
	daySliderIconNext: {},
	daySliderIconPrev: {
		transform: 'rotate(-180deg)',
	},
	daySliderWrapper: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	filterContainer: {
		margin: 'auto',
		marginBottom: 40,
		marginTop: 20,
		...CssSelectors.allDescendants(
			{
				minWidth: 220,
			},
			'.dropdown-anchor .autocomplete-search-field-input'
		),
	},
	filterWrapper: {
		backgroundColor: darkBlue,
		padding: '20px 60px',
		width: '90vw',
	},
	filterWrapperItem: {
		margin: 5,
	},
	filterWrapperItemMobile: {
		margin: 0,
	},
	filterWrapperMobile: {
		backgroundColor: darkBlue,
		padding: 20,
		width: '90vw',
		...CssSelectors.allDescendants(
			{
				width: 'calc(90vw - 40px)',
			},
			'.autocomplete-search-field-input'
		),
	},
	individualLeaderBoard: {
		maxWidth: 680,
	},
	leaderboardContainer: {
		width: '100%',
	},
	leaderboardTabs: {
		marginTop: 60,
	},
	lion: {
		height: 26,
		marginTop: -4,
		width: 26,
	},
	noLeaders: {
		color: white,
		marginTop: 30,
		textAlign: 'center',
		...aidaBaseStyles.flexCol,
		...CssSelectors.allChildren(
			{
				fontFamily: FontFamilies.conqueror,
				fontSize: '24px',
				padding: '60px 0 10px',
				textAlign: 'center',
			},
			'.no-leaders-header'
		),
	},
	red: {
		border: '1px solid #FF7892',
	},
	teamLeaderBoard: {
		border: `1px solid ${yellow}`,
		borderRadius: 35,
		margin: '0 30px',
		padding: 30,
		width: 720,
		...CssSelectors.allDescendants(
			{
				maxHeight: 80,
				maxWidth: 80,
				minHeight: 80,
				minWidth: 80,
			},
			'.first'
		),
		...CssSelectors.allDescendants(
			{
				maxHeight: 65,
				maxWidth: 65,
				minHeight: 65,
				minWidth: 65,
			},
			'.not-first'
		),
		...CssSelectors.allDescendants(
			{
				top: 'calc(100% + 10px)',
			},
			'.num'
		),
		...CssSelectors.allDescendants(
			{
				color: yellow,
				paddingRight: 10,
			},
			'.score'
		),
		...CssSelectors.allDescendants(
			{
				fontSize: '26px',
			},
			'.team-header'
		),
		...CssSelectors.allDescendants(
			{
				...CssSelectors.allDescendants({
					fontSize: '18px',
				}),
			},
			'.team-leader'
		),
	},
	toggleContainer: {
		...aidaBaseStyles.flexCenter,
		marginTop: 95,
		padding: '25px 0 0',
	},
	toggleContainerMobile: {
		marginTop: 0,
	},
	toggleText: {
		color: white,
		fontFamily: FontFamilies.conqueror,
		fontSize: '18px',
		lineHeight: 0,
		padding: '3px 0 0 10px',
	},
});
