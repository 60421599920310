import { aidaBaseStyles } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	kingOfClubs: {
		...aidaBaseStyles.absoluteCenter,
	},
	qualifiedPitchContainer: {
		position: 'relative',
	},
	queenOfHearts: {
		...aidaBaseStyles.absoluteCenter,
	},
});
