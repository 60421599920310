import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { LeadView } from '../../../components/leadView/LeadView';
import { LeadViewType } from '../../../components/leadView/context';
import { IColumn } from '../LeadViewTableHeader';
import { ColumnWrapper } from '../components/ColumnWrapper';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { ReactNode, useState } from 'react';
import * as React from 'react';
import Waypoint from 'react-waypoint';

interface IColumns<T> extends IColumn {
	Component: (args: T) => boolean | React.ReactChild | React.ReactFragment;
}

interface IProps<T> {
	cardViewComponent?: (row: T) => ReactNode;
	columns: IColumns<T>[];
	data: T[];
	filterComponent?: ReactNode;
	getRowToClick?: (row: T) => () => void;
	getSource?: (row: T) => string;
	hideHeader?: boolean;
	isEmpty: boolean;
	isLoading?: boolean;
	onLoadMore?: (shouldNotTriggerLoading: boolean) => void;
	title: string;
}

export function LeadViewWrapper<T>({
	cardViewComponent,
	columns,
	data,
	filterComponent,
	getRowToClick,
	getSource,
	hideHeader,
	isEmpty,
	isLoading,
	onLoadMore,
	title,
}: IProps<T>) {
	// @ts-ignore
	const [viewType, setViewType] = useState<LeadViewType>(null);

	const onViewSelected = (newViewType: LeadViewType) => setViewType(newViewType);

	const isCard = viewType === LeadViewType.Card;

	const renderResults = () => {
		if (isLoading) {
			return <LoadingSpinner className={css(styleSheet.loading)} type='login' />;
		}

		if (isEmpty) {
			return <div className={css(styleSheet.noResults)}>No data to show</div>;
		}

		if (isCard) {
			return data.map((item, index) => (
				<div className={css(styleSheet.leadCard)} key={index}>
					{/* @ts-ignore */}
					{cardViewComponent(item)}
				</div>
			));
		}

		if (!isCard) {
			return data.map((item, dataIndex) => {
				return (
					<div key={dataIndex} className={css(styleSheet.leadRow)}>
						<LeadView.RowView onClick={getRowToClick?.(item)} source={getSource?.(item)}>
							{columns.map((column, colIndex) => {
								if (column.hide) {
									return null;
								}
								return (
									<ColumnWrapper key={colIndex} width={column.width}>
										{column.Component(item)}
									</ColumnWrapper>
								);
							})}
						</LeadView.RowView>
					</div>
				);
			});
		}

		return null;
	};

	const handleLoadMore = () => onLoadMore?.(true);
	return (
		<LeadView onViewSelected={onViewSelected}>
			{!hideHeader && <LeadView.Title title={title} />}

			{filterComponent && filterComponent}

			{!isCard && <LeadView.TableHeader columns={columns} />}

			<LeadView.TableContainer>
				{renderResults()}
				{handleLoadMore && <Waypoint bottomOffset='-200px' onEnter={handleLoadMore} />}
			</LeadView.TableContainer>
		</LeadView>
	);
}
