import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Noop } from '../../../extViewmodels/Utils';
import { IContact } from '../../../viewmodels/AppViewModels';
import { DeprecatedXIcon } from '../../../web/components/svgs/icons/DeprecatedXIcon';
import { BlueModal } from '../BlueModal';
import { ContactInfo } from '../ContactInfo';
import { TransparentButton } from '../TransparentButton';
import { styleSheet } from './styles';

interface IProps {
	contact?: IContact;
	isOpen: boolean;
	onCancel: () => void;
	onSave: (contact: IContact, updatedContactInfo?: IContact) => IContact | void;
}

export const ContactInfoModalBase: React.FC<IProps> = ({ contact, isOpen, onCancel, onSave }) => {
	return (
		<BlueModal
			className={`${css(styleSheet.contactInfoModal)}`}
			headerAccessory={
				<div className={css(styleSheet.header)}>
					<div className={css(styleSheet.title)}>{contact ? 'Edit Contact' : 'New Contact'}</div>
					<TransparentButton onClick={onCancel}>
						<DeprecatedXIcon />
					</TransparentButton>
				</div>
			}
			isOpen={isOpen}
			onRequestClose={Noop}
		>
			<ContactInfo bare={true} onSave={onSave} />
		</BlueModal>
	);
};

export const ContactInfoModal = observer(ContactInfoModalBase);
