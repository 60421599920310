import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IUserReference, ResourceAutoCompleteViewModelType, UserViewModel } from '../../../../../extViewmodels';
import { getDisplayName } from '../../../../../extViewmodels/Utils';
import { RadioButton } from '../../../../../web/components/RadioButton';
import {
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../../../../../web/components/autocomplete/SimpleAutoCompleteSearchField';
import { DeprecatedXIcon } from '../../../../../web/components/svgs/icons/DeprecatedXIcon';
import { baseStyleSheet } from '../../../../../web/styles/styles';
import { LeadRuleViewModel, TurnItOnForOptions } from '../../../../viewModels/leadrules';
import { styleSheet } from '../../styles';

interface IProps {
	enabled: boolean;
	leadRule: LeadRuleViewModel;
	onChangeToEnable: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Individual: React.FC<IProps> = observer(({ enabled, leadRule, onChangeToEnable }) => {
	const onSelected = (event: ISimpleAutoCompleteSearchFieldItemSelectionEvent<IUserReference>) => {
		leadRule?.setUsers(leadRule.users.concat(event.selection));
	};
	const onRemoved = (user: IUserReference) => () => {
		leadRule?.setUsers(leadRule.users.filter((item: IUserReference) => item.id !== user.id));
	};
	return (
		<div>
			<RadioButton checked={enabled} id={TurnItOnForOptions.Individual} onChange={onChangeToEnable}>
				<span className={css(styleSheet.radioLabel)}>Individual(s)</span>
			</RadioButton>
			<div
				className={css(
					styleSheet.radioContent,
					!enabled && baseStyleSheet.disabled,
					!enabled && baseStyleSheet.disableInteraction
				)}
			>
				{enabled && (
					<>
						<div className={css(styleSheet.userSearchField)}>
							<SimpleAutoCompleteSearchField
								initialSearchQuery=''
								// @ts-ignore
								onItemSelected={onSelected}
								pageSize={5}
								placeholder='Search Employees'
								resultsLimit={5}
								type={ResourceAutoCompleteViewModelType.User}
							/>
						</div>
						{/* @ts-ignore */}
						{leadRule?.users?.map((user: UserViewModel) => {
							return (
								<div key={user.id} className={css(styleSheet.selectedUser)}>
									{/* @ts-ignore */}
									<button onClick={onRemoved(user)}>
										<span>
											<DeprecatedXIcon />
										</span>
									</button>
									<span className={css(styleSheet.userName)}>{getDisplayName(user)}</span>
								</div>
							);
						})}
					</>
				)}
			</div>
		</div>
	);
});
