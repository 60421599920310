import { QuestionCardGraphic } from '../QuestionCardGraphic';
import { styleSheet } from './styles';
import { animated, config, useSpring } from '@react-spring/web';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	animate?: boolean;
	className?: string;
}

export const StumpedGraphic: React.FC<IProps> = ({ animate, className = '' }) => {
	const from = {
		transform: 'rotateZ(0deg) scale(1)',
	};
	const to = {
		transform: 'rotateZ(-15deg) scale(1.25)',
	};
	const spring = useSpring({
		config: config.gentle,
		from,
		to: animate ? to : from,
	});

	return (
		<div className={`${css(styleSheet.stumpedContainer)} ${className}`}>
			<animated.div style={spring}>
				<QuestionCardGraphic />
			</animated.div>
		</div>
	);
};
