import { CssSelectors } from '../../../../web/styles/styles';
import { background, borderColor, grayIconFill, titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addEntityButtons: {
		borderTop: '1px #F2F2F2 solid',
	},
	dropdownContent: {
		background: '#FFFFFF',
		border: '1px solid #F3F3F3',
		borderRadius: '3px',
		boxShadow: '0 0 6px 0 rgba(139,139,139,0.10)',
		boxSizing: 'border-box',
		minWidth: '100%',
	},
	highlighted: {
		backgroundColor: '#e8f3f9',
	},
	info: {
		cursor: 'text',
		fontSize: 14,
		padding: '10px 20px',
	},
	infoButton: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		fontSize: '14px',
		padding: '10px 20px',
		textAlign: 'left',
	},
	item: {
		':focus': {
			outline: 'none',
		},
		':hover': {
			backgroundColor: '#E3E7E9',
		},
		cursor: 'pointer',
	},
	tag: {
		fontSize: '14px',
		padding: '4px 20px',
	},

	textInput: {
		alignItems: 'center',
		background,
		border: `1px solid ${borderColor}`,
		borderRadius: 3,
		boxSizing: 'border-box',
		color: titles,
		display: 'flex',
		fontSize: 14,
		outline: 'none',
		padding: 10,
		...CssSelectors.allDescendants(
			{
				'::placeholder': {
					color: grayIconFill,
				},
				':focus': {
					outline: 'none',
				},
				background: 'transparent',
				border: 'none',
				color: titles,
				flexGrow: 1,
				fontSize: '14px',
				padding: 0,
			},
			'input'
		),
	},
});
