import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

export interface ILeadViewDescriptionProps {
	description: string;
}

export const LeadViewDescription: React.FC<ILeadViewDescriptionProps> = props => {
	const { description: title } = props;
	return (
		<div className={`${css(styleSheet.container)} lead-view-description`}>
			<div className={css(styleSheet.header)}>
				<div className={css(styleSheet.description)}>{title}</div>
			</div>
		</div>
	);
};
