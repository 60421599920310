import * as Api from '@ViewModels';
import { animated } from '@react-spring/web';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { useAudioPlayer } from '../../../hooks/useSound';
import { ISlotMachineComponent } from '../../../models/slotMachines';
import { SlotMachineViewModel } from '../../../viewModels/slotMachines';
import { SlotMachineEndGameModal } from '../SlotMachineEndGameModal';
import HandleAudioFileUrl from '../coins.mp3';
import { useSlotMachineEndGameModal, useSlots } from '../hooks';
import { Background, BoozeCruiseSymbol, BubbleMachine, Daisy, Fish, Ocean, TitleText } from './assets';
import TitleImageUrl from './boozeCruiseTitle.png';
import WinAudioFileUrl from './boozeCruiseWin.mp3';
import HandleImageUrl from './daisyHandle.svg';
import LifeSaverImageUrl from './daisyLifeSaver.svg';
import PlayNowImageUrl from './daisyPlayNow.svg';
import ReelsImageUrl from './daisyReels.svg';
import SharkImageUrl from './daisyShark.png';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	scale?: number;
	slotMachine: SlotMachineViewModel;
	styles?: StyleDeclarationValue[];
}

export const MachineDefaultSize = 3500;

/**
 * Note: audio files are from: https://mixkit.co/free-sound-effects/slot-machine/ License states free to use in
 * comercial projects: https://mixkit.co/license/#sfxFree
 */
export const DaisyBoozeCruiseSlotMachine: React.FC<IProps> & Partial<ISlotMachineComponent> = observer(props => {
	const { className, slotMachine, styles = [], scale = 0.33 } = props;
	const { handleProps, loadingSpin, onHandleClick, reelItemProps } = useSlots(slotMachine, 'DaisyBoozeCruise');
	const { showEndGameModalWithTimeout, ...endGameModalProps } = useSlotMachineEndGameModal(slotMachine);
	const winAudio = useAudioPlayer(WinAudioFileUrl);
	const handleAudio = useAudioPlayer(HandleAudioFileUrl);
	const getScaledValue = (value: number) => {
		return scale * value;
	};

	const spin = React.useCallback(async () => {
		handleAudio.play();
		await onHandleClick();
		handleAudio.stop();
		if (slotMachine.spinOutcome?.gameOutcomeResultType === Api.GameOutcomeResultType.Win) {
			winAudio.play();
		}
		showEndGameModalWithTimeout(3000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onHandleClick, showEndGameModalWithTimeout, winAudio, handleAudio]);

	return (
		<div className={`${css(styleSheet.container, ...styles)} daisy-booze-cruise-slot-machine ${className || ''}`}>
			{loadingSpin ? <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} /> : null}
			<SlotMachineEndGameModal {...endGameModalProps} />
			<animated.div
				className={css(styleSheet.machineContainer)}
				style={{
					height: getScaledValue(MachineDefaultSize),
					width: getScaledValue(MachineDefaultSize),
				}}
			>
				<Background
					className={css(styleSheet.machinePart)}
					style={{
						height: getScaledValue(3481),
						left: getScaledValue(114),
						top: getScaledValue(169),
						width: getScaledValue(3481),
					}}
				/>
				<animated.img
					className={css(styleSheet.machinePart, styleSheet.handle)}
					height={getScaledValue(641)}
					onClick={!loadingSpin && !slotMachine.spinOutcome ? spin : undefined}
					src={HandleImageUrl}
					style={{
						left: getScaledValue(2834.5),
						perspective: 1100,
						top: getScaledValue(1245.53),
						transform: handleProps.rotation.to(x => {
							return `rotateX(${x}deg)`;
						}),
						transformOrigin: `4% 90% ${getScaledValue(-15)}px`,
						transformStyle: 'preserve-3d',
					}}
					width={getScaledValue(551.73)}
				/>
				<Ocean
					className={css(styleSheet.machinePart)}
					style={{
						height: getScaledValue(1483),
						left: getScaledValue(986.5),
						top: getScaledValue(1055),
						width: getScaledValue(1461),
					}}
				/>
				<BubbleMachine minSize={getScaledValue(60)} />
				<Fish
					className={css(styleSheet.machinePart)}
					style={{
						height: getScaledValue(917),
						left: getScaledValue(1246.5),
						top: getScaledValue(1510),
						width: getScaledValue(1048),
					}}
				/>
				<animated.img
					className={css(styleSheet.machinePart)}
					height={getScaledValue(214.45)}
					src={SharkImageUrl}
					style={{
						left: getScaledValue(1817.12),
						top: getScaledValue(2040.78),
					}}
					width={getScaledValue(271.66)}
				/>
				<animated.img
					className={css(styleSheet.machinePart)}
					height={getScaledValue(2033)}
					src={LifeSaverImageUrl}
					style={{
						left: getScaledValue(701.5),
						top: getScaledValue(778),
					}}
					width={getScaledValue(2165.18)}
				/>
				<Daisy
					className={css(styleSheet.machinePart)}
					style={{
						height: getScaledValue(1043),
						left: getScaledValue(1319.5),
						top: getScaledValue(122),
						width: getScaledValue(668),
					}}
				/>
				<TitleText
					className={css(styleSheet.machinePart)}
					style={{
						height: getScaledValue(709),
						left: getScaledValue(1108.5),
						top: getScaledValue(835),
						width: getScaledValue(1186),
					}}
				/>
				<animated.img
					className={css(styleSheet.machinePart)}
					height={getScaledValue(380)}
					src={ReelsImageUrl}
					style={{
						left: getScaledValue(1113.5),
						top: getScaledValue(1661),
					}}
					width={getScaledValue(1210)}
				/>
				<div
					className={css(styleSheet.machinePart, styleSheet.reels)}
					style={{
						height: getScaledValue(380),
						left: getScaledValue(1113.5),
						top: getScaledValue(1661),
						width: getScaledValue(1210),
					}}
				>
					{slotMachine.reels.map((reel, i) => {
						const side = getScaledValue(380);
						const spacing = getScaledValue(35);
						return (
							<animated.div
								className={css(styleSheet.machinePart, styleSheet.reel)}
								key={reel.id}
								style={{
									height: side,
									marginLeft: (side + spacing) * i,
									perspective: `${getScaledValue(154)}px`,
									width: side,
								}}
							>
								{reelItemProps[i]?.map((animatedStyles, index) => {
									const { rotation, blur, ...restProps } = animatedStyles;
									return (
										<animated.div
											key={index}
											className={css(styleSheet.machinePart, styleSheet.reelItem)}
											style={{
												...restProps,
												filter: blur
													.to({
														output: [0, 1, 0.5, 0],
														range: [0, 0.5, 0.75, 1],
													})
													.to(x => {
														return `blur(${x}px)`;
													}),
												height: side,
												transform: rotation.to(x => {
													return `rotateX(${x}deg)`;
												}),
												transformOrigin: `50% 50% -${side * 1.2}px`,
												width: side,
											}}
										>
											<BoozeCruiseSymbol
												height={getScaledValue(230)}
												symbolType={slotMachine.reels[i].symbols[index].value}
											/>
										</animated.div>
									);
								})}
							</animated.div>
						);
					})}
				</div>
				<animated.img
					className={css(styleSheet.machinePart)}
					height={getScaledValue(288.5)}
					onClick={!loadingSpin && !slotMachine.spinOutcome ? spin : undefined}
					src={PlayNowImageUrl}
					style={{
						left: getScaledValue(1492.32),
						top: getScaledValue(2116.3),
					}}
					width={getScaledValue(434.15)}
				/>
			</animated.div>
		</div>
	);
});

DaisyBoozeCruiseSlotMachine.PreloadImageUrls = [
	HandleImageUrl,
	LifeSaverImageUrl,
	PlayNowImageUrl,
	ReelsImageUrl,
	SharkImageUrl,
	TitleImageUrl,
];
