import { white } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	location: {
		':hover': {
			textDecoration: 'underline',
		},
		color: white,
		fontSize: '12px',
		margin: 6,
		textDecoration: 'none',
	},
	locationIcon: {
		marginLeft: 6,
	},
});
