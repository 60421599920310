import * as Api from '@ViewModels';
import * as React from 'react';
import { DialerPhoneCallStatus, useTelephony } from '../../contexts/telephony';
import { CallActions } from './components/CallActions';
import { CallInfo } from './components/CallInfo';
import { CallTimer } from './components/CallTimer';
import { DialerContainer } from './components/DialerContainer';
import { Dialpad } from './components/Dialpad';
import { SplashScreen } from './components/SplashScreen';

export const Dialer: React.FC = () => {
	const {
		acceptCall,
		endCall,
		initiateDialer,
		isMuted,
		isWebDialerEnabled,
		phoneCallStatus,
		rejectCall,
		sendDigits,
		setIsMuted,
		telephonyConfiguration,
	} = useTelephony();

	if (!telephonyConfiguration || telephonyConfiguration.provider !== Api.TelephonyServiceProvider.Twilio) {
		return null;
	}

	const isConnected = phoneCallStatus === DialerPhoneCallStatus.Connected;
	const isRinging = phoneCallStatus === DialerPhoneCallStatus.Ringing;

	const handleMuteToggle = () => {
		setIsMuted(!isMuted);
	};

	const handleDialpadChange = (keys: string) => {
		sendDigits(keys);
	};

	if (!isWebDialerEnabled) {
		return process.env.NODE_ENV === 'development' ? null : <SplashScreen onClick={initiateDialer} />;
	}

	if (!isConnected && !isRinging) {
		return null;
	}

	return (
		<DialerContainer>
			<CallInfo />
			<CallTimer isConnected={isConnected} />
			<CallActions
				isConnected={isConnected}
				isMuted={isMuted}
				isRinging={isRinging}
				onClickToAccept={acceptCall}
				onClickToReject={rejectCall}
				onClickToDisconnect={endCall}
				toggleMuted={handleMuteToggle}
			/>
			{isConnected && <Dialpad onKeyPress={handleDialpadChange} />}
		</DialerContainer>
	);
};
