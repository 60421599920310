import { DisclosureIcon } from '../../../web/components/svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	className?: string;
}

export const PageNotFound: React.FC<IProps> = ({ className = '' }) => {
	return (
		<div className={`${css(styleSheet.pageNotFoundContainer)} ${className}`}>
			<div className={css(styleSheet.wrapper)}>
				<div className={css(styleSheet.title)}>
					<span>404</span>
				</div>
				<div className={css(styleSheet.subText)}>Page Not Found</div>
				<div>
					<Link className={css(styleSheet.link)} to='/'>
						<DisclosureIcon className={css(styleSheet.chevron)} fillColor='#fff' type='chevron' />
						<span>Back to Queue</span>
					</Link>
				</div>
			</div>
		</div>
	);
};
