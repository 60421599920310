import { FontFamilies } from '../../../web/styles/styles';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	basicPageContainer: {
		...aidaBaseStyles.flexCol,
		height: 'auto',
		margin: '0 auto',
		maxWidth: 1400,
		minHeight: '95%',
		paddingTop: 20,
	},
	body: {
		minWidth: '100%',
	},
	header: {
		...aidaBaseStyles.flexCenter,
		marginTop: 10,
		minWidth: '100%',
	},
	title: {
		color: '#fff',
		fontFamily: FontFamilies.conqueror,
		fontSize: '54px',
		textTransform: 'uppercase',
	},
});
