import { StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../web/styles/styles';
import { brandPrimaryDark, green, success } from '../../styles/colors';

export const styleSheet = StyleSheet.create({
	marker: {
		background: success,
		height: 24,
		left: 6,
		position: 'absolute',
		top: -1,
		transition: 'left 0.3s',
		width: 1,
	},
	markerText: {
		color: success,
		fontSize: 10,
		left: -76,
		position: 'absolute',
		top: -18,
		width: 200,
	},
	pointsContainer: {
		color: green,
		fontSize: 14,
		whiteSpace: 'nowrap',
		...CssSelectors.allChildren(
			{
				display: 'inline-block',
				paddingRight: 5,
			},
			'span:first-child'
		),
	},
	pointsProgressBarContainer: {
		alignItems: 'center',
		display: 'flex',
		position: 'relative',
	},
	progress: {
		borderRadius: 6,
		height: 12,
		minWidth: 12,
		transition: 'width 0.3s',
		width: 0,
	},

	progressBar: {
		background: brandPrimaryDark,
		borderRadius: 6,
		height: 12,
		marginRight: 20,
		width: 400,
	},
	progressGreen: {
		background: 'linear-gradient(to right, #4A8A96, #89C947)',
	},
	progressRed: {
		background: 'linear-gradient(to right, #C30DAF, #FF7892)',
	},
	progressYellow: {
		background: 'linear-gradient(to right, #BC743D, #FFE94F)',
	},
});
