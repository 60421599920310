import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const FollowUpsIcon: React.FC<IProps> = ({ className = '', fill }) => {
	return (
		<SvgIcon className={`follow-ups-icon ${className || ''}`} height={24} width={24}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.555 22c.693 1.19 1.969 2 3.445 2 1.477 0 2.752-.81 3.446-2H5.555Zm9.592-6.193a8.9 8.9 0 0 1-2.134-.748A5.373 5.373 0 0 0 14.965 19H3.035A5.38 5.38 0 0 0 5 14.838V12a3.998 3.998 0 0 1 3.077-3.88A8.999 8.999 0 0 1 8 7c0-.31.016-.615.046-.917C5.19 6.543 3 9.017 3 12v2.838c0 1.46-.931 2.751-2.316 3.214L0 18.28V21h18v-2.72l-.684-.228a3.383 3.383 0 0 1-2.169-2.245'
				fill={fill}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17 2c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5m0 12c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7'
				fill={fill}
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M20 8h-4V3h2v3h2v2Z' fill={fill} />
		</SvgIcon>
	);
};
