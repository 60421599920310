import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const QuestionCardGraphic: React.FC<IProps> = ({ className = '' }) => {
	return (
		<SvgIcon className={`question-card-graphic ${className}`} height={70} width={54}>
			<rect x='3' y='3' width='48' height='64' rx='9' fill='#043D65' stroke='#00528C' strokeWidth='5' />
			<path
				d='M23.3389 40.96C23.3389 38.8636 23.5934 37.194 24.1025 35.9512C24.6117 34.7083 25.54 33.488 26.8877 32.29C28.2503 31.0771 29.1562 30.0964 29.6055 29.3477C30.0547 28.584 30.2793 27.7829 30.2793 26.9443C30.2793 24.4137 29.1113 23.1484 26.7754 23.1484C25.6673 23.1484 24.7764 23.4928 24.1025 24.1816C23.4437 24.8555 23.0993 25.7913 23.0693 26.9893H16.5557C16.5856 24.1292 17.5065 21.8906 19.3184 20.2734C21.1452 18.6562 23.6309 17.8477 26.7754 17.8477C29.9499 17.8477 32.4131 18.6188 34.165 20.1611C35.917 21.6885 36.793 23.8522 36.793 26.6523C36.793 27.9251 36.5085 29.1305 35.9395 30.2686C35.3704 31.3916 34.3747 32.6419 32.9521 34.0195L31.1328 35.749C29.9948 36.8421 29.3434 38.1224 29.1787 39.5898L29.0889 40.96H23.3389ZM22.6875 47.8555C22.6875 46.8522 23.0244 46.0286 23.6982 45.3848C24.387 44.7259 25.263 44.3965 26.3262 44.3965C27.3893 44.3965 28.2578 44.7259 28.9316 45.3848C29.6204 46.0286 29.9648 46.8522 29.9648 47.8555C29.9648 48.8438 29.6279 49.6598 28.9541 50.3037C28.2952 50.9476 27.4193 51.2695 26.3262 51.2695C25.2331 51.2695 24.3496 50.9476 23.6758 50.3037C23.0169 49.6598 22.6875 48.8438 22.6875 47.8555Z'
				fill='#046CB6'
			/>
		</SvgIcon>
	);
};
