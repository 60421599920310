import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	viewBox?: string;
}

export const ChatUsersIcon: React.FC<IProps> = props => {
	const { className, fillColor, viewBox } = props;
	return (
		<SvgIcon className={`chat-users-icon ${className || ''}`} height={24} viewBox={viewBox} width={24}>
			<g>
				<path
					d='M4.4 17.7a2.1 2.1 0 1 0-2.1-2.1 2.1 2.1 0 0 0 2.1 2.1zm0-3.2a1.1 1.1 0 1 1-1.1 1.1 1.1 1.1 0 0 1 1.1-1.1zM5.71 18.3H3.091A1.592 1.592 0 0 0 1.5 19.89V22a.5.5 0 0 0 1 0v-2.11a.591.591 0 0 1 .591-.59H5.71a.59.59 0 0 1 .59.59V22a.5.5 0 0 0 1 0v-2.11a1.591 1.591 0 0 0-1.59-1.59zM21.667 15.333A2.167 2.167 0 1 0 19.5 17.5a2.169 2.169 0 0 0 2.167-2.167zm-3.334 0A1.167 1.167 0 1 1 19.5 16.5a1.167 1.167 0 0 1-1.167-1.167zM20.864 18.167h-2.728A1.636 1.636 0 0 0 16.5 19.8V22a.5.5 0 0 0 1 0v-2.2a.636.636 0 0 1 .636-.635h2.728a.636.636 0 0 1 .636.635V22a.5.5 0 0 0 1 0v-2.2a1.636 1.636 0 0 0-1.636-1.633zM20.58 1.5h-7.16a1.922 1.922 0 0 0-1.92 1.92V5.5H8.42A1.922 1.922 0 0 0 6.5 7.42v4.66a1.923 1.923 0 0 0 1.67 1.9V16a.5.5 0 0 0 .288.453.507.507 0 0 0 .212.047.5.5 0 0 0 .32-.116L11.851 14h3.729a1.922 1.922 0 0 0 1.92-1.92v-1.788l2.51 2.092a.5.5 0 0 0 .32.116.507.507 0 0 0 .212-.047.5.5 0 0 0 .288-.453V9.984a1.923 1.923 0 0 0 1.67-1.9V3.42a1.922 1.922 0 0 0-1.92-1.92zm-5 11.5h-3.91a.5.5 0 0 0-.32.116l-2.18 1.817V13.5a.5.5 0 0 0-.5-.5h-.25a.91.91 0 0 1-.92-.92V7.42a.91.91 0 0 1 .92-.92h3.08v1.58A1.922 1.922 0 0 0 13.42 10h3.08v2.08a.91.91 0 0 1-.92.92zm5.92-4.92a.91.91 0 0 1-.92.92h-.25a.5.5 0 0 0-.5.5v1.433l-2.18-1.817A.5.5 0 0 0 17.33 9h-3.91a.91.91 0 0 1-.92-.92V3.42a.91.91 0 0 1 .92-.92h7.16a.91.91 0 0 1 .92.92z'
					fill={fillColor}
				/>
			</g>
		</SvgIcon>
	);
};
