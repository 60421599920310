import { StyleSheet } from 'aphrodite';
import { CssSelectors, Layouts } from '../../../../web/styles/styles';
import { brandPrimary, brandPrimaryDark, brandSecondary, white } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		justifyContent: 'center',
		...Layouts.horizontalStack(30),
	},
	outcomeSelect: {
		background: brandPrimary,
		border: `1px solid ${brandSecondary}`,
		...CssSelectors.allDescendants(
			{
				...CssSelectors.allDescendants(
					{
						fill: brandSecondary,
					},
					'polygon'
				),
			},
			'.disclosure-icon'
		),
		...CssSelectors.allDescendants(
			{
				color: white,
			},
			'.select-option-text'
		),
	},
	outcomeSelectDropdown: {
		border: `1px solid ${brandSecondary}`,
		...CssSelectors.allDescendants(
			{
				background: brandPrimary,
			},
			'.options-container'
		),
		...CssSelectors.allDescendants(
			{
				background: brandPrimary,
				...CssSelectors.allDescendants({
					':hover': {
						background: brandPrimaryDark,
					},
					color: white,
				}),
			},
			'.option-container'
		),
		...CssSelectors.allDescendants(
			{
				background: brandPrimaryDark,
			},
			'.selected'
		),
	},
	searchIcon: {
		marginRight: 6,
	},
	selectedUser: {
		marginRight: 10,
	},
	userAutocomplete: {
		...CssSelectors.allDescendants(
			{
				minWidth: '100%',
			},
			'.user-auto-complete-anchor'
		),
		...CssSelectors.allDescendants(
			{
				...CssSelectors.allDescendants(
					{
						background: brandPrimary,
						border: `1px solid ${brandSecondary}`,
						...CssSelectors.allDescendants(
							{
								borderRadius: 3,
							},
							'.item'
						),
						...CssSelectors.allDescendants(
							{
								color: white,
							},
							'.name'
						),
						...CssSelectors.allDescendants(
							{
								color: brandSecondary,
							},
							'.email'
						),
						...CssSelectors.allDescendants(
							{
								backgroundColor: brandPrimaryDark,
							},
							'.highlighted'
						),
					},
					'.dropdown-content'
				),
			},
			'.user-auto-complete-dropdown'
		),
		...CssSelectors.allDescendants(
			{
				background: brandPrimary,
				border: `1px solid ${brandSecondary}`,
				borderRadius: 3,
				minWidth: 300,
				...CssSelectors.allDescendants(
					{
						color: white,
					},
					'input'
				),
			},
			'.autocomplete-search-field-input'
		),
	},
});
