import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import { white } from '../../../../styles/colors';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const CardViewIcon: React.FC<IProps> = ({ className = '', fillColor = white }) => {
	return (
		<SvgIcon className={`card-view-icon ${className}`} height={20} width={18}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2 4h14V2H2v2Zm14 2H2C.897 6 0 5.103 0 4V2C0 .898.897 0 2 0h14c1.103 0 2 .898 2 2v2c0 1.103-.897 2-2 2ZM2 11h14V9H2v2Zm14 2H2c-1.103 0-2-.897-2-2V9c0-1.102.897-2 2-2h14c1.103 0 2 .898 2 2v2c0 1.103-.897 2-2 2ZM2 18h14v-2H2v2Zm14 2H2c-1.103 0-2-.897-2-2v-2c0-1.103.897-2 2-2h14c1.103 0 2 .897 2 2v2c0 1.103-.897 2-2 2Z'
				fill={fillColor}
			/>
		</SvgIcon>
	);
};
