import { CssSelectors } from '../../../../../web/styles/styles';
import { white } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	clearButton: {
		cursor: 'pointer',
		marginTop: -12,
		position: 'absolute',
		right: 8,
		top: '50%',
		...CssSelectors.allDescendants(
			{
				height: 24,
				width: 24,
			},
			'svg'
		),
		...CssSelectors.allDescendants(
			{
				fill: white,
				opacity: 0,
			},
			'circle'
		),
	},
});
