import { Checkbox } from '../../../../web/components/Checkbox';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';
import { v4 as uuid } from 'uuid';

interface IProps {
	checked?: boolean;
	className?: string;
	defaultChecked?: boolean;
	disabled?: boolean;
	id?: string;
	isSmall?: boolean;
	label?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SimpleCheckbox: React.FC<IProps> = ({
	checked,
	className = '',
	defaultChecked = false,
	disabled,
	id = uuid(),
	isSmall,
	label,
	onChange,
}) => {
	return (
		<div className={css(styleSheet.checkboxWrapper)}>
			<Checkbox
				backgroundFillColor='white'
				checked={checked}
				defaultChecked={defaultChecked}
				checkedBackgroundFillColor='white'
				className={className}
				disabled={disabled}
				id={id}
				onChange={onChange}
			>
				<strong className={css(styleSheet.checkboxLabel, isSmall && styleSheet.checkboxLabelSmall)}>{label}</strong>
			</Checkbox>
		</div>
	);
};
