import { RoundAddIcon } from '../../../web/components/svgs/icons/RoundAddIcon';
import { TransparentButton } from '../TransparentButton';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	onClick: () => void;
	text: string;
}

export const AddAction: React.FC<IProps> = ({ className = '', onClick, text }) => {
	return (
		<div className={`${css(styleSheet.addActionContainer)} ${className}`}>
			<TransparentButton className={css(styleSheet.btn)} onClick={onClick}>
				<RoundAddIcon />
				<span>{text}</span>
			</TransparentButton>
		</div>
	);
};
