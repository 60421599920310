import { CssSelectors } from '../../../web/styles/styles';
import { brandPrimary, brandPrimaryDark, brandSecondary, white } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	select: {
		background: brandPrimary,
		border: `1px solid ${brandSecondary}`,
		...CssSelectors.allDescendants(
			{
				...CssSelectors.allDescendants(
					{
						fill: brandSecondary,
					},
					'polygon'
				),
			},
			'.disclosure-icon'
		),
		...CssSelectors.allDescendants(
			{
				color: white,
			},
			'.select-option-text'
		),
	},
	selectDropdown: {
		border: `1px solid ${brandSecondary}`,
		...CssSelectors.allDescendants(
			{
				background: brandPrimary,
			},
			'.options-container'
		),
		...CssSelectors.allDescendants(
			{
				background: brandPrimary,
				...CssSelectors.allDescendants({
					':hover': {
						background: brandPrimaryDark,
					},
				}),
			},
			'.option-container'
		),
		...CssSelectors.allDescendants(
			{
				background: brandPrimaryDark,
			},
			'.selected'
		),
	},
	selectRow: {
		alignItems: 'flex-end',
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: 20,
	},
	settings: {
		paddingTop: 15,
	},
	settingsContainer: {},
});
