import { PointsBubble } from '../PointsBubble';
import { TransparentButton } from '../TransparentButton';
import { HexGraphic } from '../svgs/HexGraphic';
import { styleSheet } from './styles';
import { animated, config, useSpring } from '@react-spring/web';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	disabled?: boolean;
	graphic: JSX.Element;
	graphicClassName?: string;
	onClick: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	points?: number;
	selected?: boolean;
	text: string | JSX.Element;
	textClassName?: string;
}

const buttonFrom = {
	transform: 'rotateZ(0deg)',
};
const buttonTo = {
	transform: 'rotateZ(360deg)',
};

export const HexButton: React.FC<IProps> = ({
	className = '',
	disabled,
	graphic,
	graphicClassName = '',
	onClick,
	onMouseEnter,
	onMouseLeave,
	points,
	selected,
	text,
	textClassName = '',
}) => {
	const buttonSpring = useSpring({
		config: config.gentle,
		from: buttonFrom,
		to: selected ? buttonTo : buttonFrom,
	});

	return (
		<div className={`${css(styleSheet.hexButtonContainer)} ${className}`}>
			<animated.div style={buttonSpring}>
				<TransparentButton
					className={css(styleSheet.button, disabled && styleSheet.disabled)}
					disabled={disabled}
					onClick={onClick}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
				>
					{!!points && <PointsBubble className={css(styleSheet.pointsBubble)} points={points} selected={selected} />}
					{/* @ts-ignore */}
					<HexGraphic animate={true} className={css(styleSheet.hex)} selected={selected} />
					<div className={`${css(styleSheet.graphic)} ${graphicClassName}`}>{graphic}</div>
				</TransparentButton>
			</animated.div>
			<div className={`${css(styleSheet.text, disabled && styleSheet.disabled)} ${textClassName}`}>{text}</div>
		</div>
	);
};
