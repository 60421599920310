import { CssSelectors, Layouts } from '../../../../web/styles/styles';
import { brandSecondary, white } from '../../../styles/colors';
import { CSSProperties, StyleSheet } from 'aphrodite';

const flexCenter: CSSProperties = {
	alignItems: 'center',
	display: 'flex',
};

export const styleSheet = StyleSheet.create({
	container: {
		background: brandSecondary,
		borderRadius: 10,
		color: white,
		fontSize: 11,
		height: 120,
		letterSpacing: 1.1,
		marginBottom: 20,
		padding: 20,
		position: 'relative',
		textTransform: 'uppercase',
	},
	file: {
		...flexCenter,
		width: '50%',
		...CssSelectors.allChildren({
			':first-child': {
				marginRight: 10,
			},
		}),
	},
	fileContainer: {
		...flexCenter,
		marginTop: 10,
	},
	fileInput: {
		display: 'none',
	},
	input: {
		width: 300,
	},
	loader: {
		height: 80,
	},
	phraseLabel: {},
	sound: {
		...flexCenter,
		width: '50%',
		...CssSelectors.allChildren({
			':first-child': {
				marginRight: 10,
			},
		}),
	},
	textContainer: {
		...flexCenter,
		marginTop: 10,
		...Layouts.horizontalStack(12),
	},
	uploadedFile: {
		...flexCenter,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			'span'
		),
	},
	xIconContainer: {
		position: 'absolute',
		right: 12,
		top: 12,
	},
});
