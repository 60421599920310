import { ILeaderboardUser } from '../../../viewModels/gamification';
import { Top3Avatar, Top3AvatarGhost, Top3AvatarWrapper } from './Top3Avatar';
import * as React from 'react';

interface IProps {
	top10: ILeaderboardUser[];
	asc?: boolean;
}

export const Top3: React.FC<IProps> = ({ top10, asc }) => {
	const first =
		top10.length >= 1 ? (
			<Top3Avatar userReference={top10[0].userReference} isAsc={asc === true} position='first' />
		) : (
			<Top3AvatarGhost />
		);

	const second =
		top10.length >= 2 ? <Top3Avatar userReference={top10[1].userReference} position='second' /> : <Top3AvatarGhost />;

	const third =
		top10.length >= 3 ? <Top3Avatar userReference={top10[2].userReference} position='third' /> : <Top3AvatarGhost />;

	const hasntPositions = !first && !second && !third;

	if (hasntPositions) {
		return null;
	}

	return (
		<Top3AvatarWrapper>
			{second}
			{first}
			{third}
		</Top3AvatarWrapper>
	);
};
