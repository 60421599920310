import { CssSelectors } from '../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	logoContainer: {
		height: 42,
		width: 101,
		...CssSelectors.allDescendants({
			height: 42,
			width: 101,
		}),
	},
	logoContainerMobile: {
		height: 40,
		width: 40,
		...CssSelectors.allDescendants({
			height: 40,
			width: 40,
		}),
	},
});
