import { ISlotMachineSymbolProps, SlotMachineSymbol } from '../SlotMachineSymbol';
import * as React from 'react';

export const LevCoinSymbol = React.forwardRef<SVGSVGElement, ISlotMachineSymbolProps>(
	function LevCoinSymbol(props, ref) {
		const { className = '', index, ...restProps } = props;
		return (
			<SlotMachineSymbol
				height={105}
				width={93}
				{...restProps}
				className={`${className} lev-coin-symbol`}
				fill='none'
				ref={ref}
			>
				<defs>
					<linearGradient
						id='levCoinGradient_a'
						x1='-72.671'
						y1='80.827'
						x2='44.684'
						y2='210.841'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#FFE677' />
						<stop offset='.03' stopColor='#FFC200' />
						<stop offset='.064' stopColor='#FFD600' />
						<stop offset='.151' stopColor='#F07A00' />
						<stop offset='.257' stopColor='#9E2F00' />
						<stop offset='.404' stopColor='#FF7F00' />
						<stop offset='.495' stopColor='#FFBC00' />
						<stop offset='.537' stopColor='#FFA200' />
						<stop offset='.689' stopColor='#FFC500' />
						<stop offset='.835' stopColor='#FFA200' />
						<stop offset='.956' stopColor='#FFD45A' />
						<stop offset='1' stopColor='#FCF881' />
						<stop offset='1' stopColor='#FCF881' />
					</linearGradient>
					<linearGradient
						id='levCoinGradient_b'
						x1='-17.07'
						y1='31.348'
						x2='33.195'
						y2='167.898'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#FCF881' />
						<stop stopColor='#FCF881' />
						<stop offset='.044' stopColor='#FFD45A' />
						<stop offset='.165' stopColor='#FFA200' />
						<stop offset='.311' stopColor='#FFC500' />
						<stop offset='.463' stopColor='#FFA200' />
						<stop offset='.505' stopColor='#FFBC00' />
						<stop offset='.596' stopColor='#FF7F00' />
						<stop offset='.743' stopColor='#9E2F00' />
						<stop offset='.849' stopColor='#F07A00' />
						<stop offset='.936' stopColor='#FFD600' />
						<stop offset='.97' stopColor='#FFC200' />
						<stop offset='1' stopColor='#FFE677' />
					</linearGradient>
					<linearGradient
						id='levCoinGradient_c'
						x1='-36.6'
						y1='-20.622'
						x2='73.025'
						y2='82.711'
						gradientUnits='userSpaceOnUse'
					>
						<stop stopColor='#FCF881' />
						<stop offset='.044' stopColor='#FFD45A' />
						<stop offset='.165' stopColor='#FFA200' />
						<stop offset='.311' stopColor='#FFC500' />
						<stop offset='.463' stopColor='#FFA200' />
						<stop offset='.505' stopColor='#FFBC00' />
						<stop offset='.596' stopColor='#FF7F00' />
						<stop offset='.743' stopColor='#9E2F00' />
						<stop offset='.849' stopColor='#F07A00' />
						<stop offset='.936' stopColor='#FFD600' />
						<stop offset='.97' stopColor='#FFC200' />
						<stop offset='1' stopColor='#FFE677' />
						<stop offset='1' stopColor='#FFE677' />
					</linearGradient>
				</defs>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M14.958 90.008c9.372 10.573 20.4 15.494 33.364 14.952 13.377-.558 24.228-6.376 32.68-17.688 5.675-7.583 9.364-16.202 10.947-25.92 2.447-9.843-.147-20.801-.147-20.801-1.61-9.168-5.47-17.38-11.39-24.574C71.316 4.848 59.414-.544 44.968.043 30.216.647 18.339 7.025 9.896 18.888 4.312 26.84 1.016 35.493.188 44.9c0 0-.926 9.689 1.335 19.388 2.261 9.701 6.835 18.243 13.435 25.72'
					fill='url(#levCoinGradient_a)'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M20.065 83.936c7.855 8.86 17.097 12.984 27.962 12.53 11.211-.467 20.306-5.343 27.39-14.824 4.755-6.355 7.847-13.579 9.174-21.723 2.05-8.25-.124-17.433-.124-17.433-1.35-7.684-4.584-14.567-9.546-20.596-7.623-9.327-17.598-13.846-29.705-13.354-12.363.506-22.318 5.852-29.394 15.794-4.68 6.665-7.441 13.917-8.136 21.8 0 0-.776 8.12 1.119 16.25s5.728 15.289 11.26 21.556'
					fill='url(#levCoinGradient_b)'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M38.168 20.42v54.545h22.366v2.812H35.372V20.504h-2.709c.118-.112 0 59.916 0 59.916H63.33v-8.182H40.964V23.147h2.796V69.51h19.57v-2.727H46.555V20.42h-8.387Z'
					fill='url(#levCoinGradient_c)'
				/>
			</SlotMachineSymbol>
		);
	}
);
