import { animated } from '@react-spring/web';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { DraggableData, DraggableEvent } from 'react-draggable';
import { IRecipient, IRichContentEditorState } from '../../../extViewmodels';
import { convertRawRichTextContentStateToRichContentEditorState } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { calculateDraggableWindowZIndex, useDraggableWindowContext } from '../../contexts/draggableWindowContext';
import { useQueue } from '../../hooks/queue';
import { white } from '../../styles/colors';
import { aidaBaseStyleSheet } from '../../styles/styles';
import { AidaNoteViewModel } from '../../viewModels/note';
import { AidaDraggable } from '../AidaDraggable';
import { QueueNoteEditor } from '../QueueNoteEditor';
import { TransparentButton } from '../TransparentButton';
import { ExpandIcon } from '../svgs/icons/ExpandIcon';
import { MinimizeIcon } from '../svgs/icons/MinimizeIcon';
import { DraggableIcon } from '../svgs/icons/audio/DraggableIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	defaultNote?: AidaNoteViewModel;
	isExpanded: boolean;
	toggleExpand: React.Dispatch<React.SetStateAction<boolean>>;
}

const queueNoteWindowId = 'queueNote';

export const QueueLeadNoteSlider: React.FC<IProps> = observer(
	({ className = '', defaultNote, isExpanded, toggleExpand }) => {
		const userSession = useUserSession();
		const queue = useQueue();
		const windowContext = useDraggableWindowContext();
		const [note, setNote] = useState(defaultNote || new AidaNoteViewModel(userSession));
		const [editorState, setEditorState] = useState<IRichContentEditorState>(
			convertRawRichTextContentStateToRichContentEditorState(note.rawContentState)
		);
		const [ccUsers, setCcUsers] = useState<IRecipient[]>([]);
		const [doNotMarket, setDoNotMarket] = useState(false);
		const [isMinimized, setIsMinimized] = useState(!isExpanded);
		const [dragging, setDragging] = useState<{
			active?: boolean;
			x?: number;
			y?: number;
		}>({ active: false, x: -15, y: 520 });

		const [processing, setProcessing] = useState(false);
		const outerTimeout = useRef<number>(null);
		const innerTimeout = useRef<number>(null);

		useEffect(() => {
			windowContext.registerWindow(queueNoteWindowId);
			return () => windowContext.unregisterWindow(queueNoteWindowId);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		useEffect(() => {
			// reset the note whenever changing leads
			// processing trigger allows tinyMCE editor to reset
			setProcessing(true);
			// @ts-ignore
			outerTimeout.current = window.setTimeout(() => {
				const newNote = new AidaNoteViewModel(userSession);
				setNoteAndEditor(newNote);
				// @ts-ignore
				innerTimeout.current = window.setTimeout(() => {
					setProcessing(false);
				}, 50);
			}, 50);

			setDoNotMarket(false);
			setCcUsers([]);
			return () => {
				// @ts-ignore
				window.clearTimeout(outerTimeout.current);
				// @ts-ignore
				window.clearTimeout(innerTimeout.current);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [queue.companyId]);

		const setNoteAndEditor = (newNote: AidaNoteViewModel) => {
			setNote(newNote);
			setEditorState(convertRawRichTextContentStateToRichContentEditorState(newNote.rawContentState));
		};

		const onToggleClick = (event?: SyntheticEvent) => {
			event?.stopPropagation();
			setIsMinimized(!isMinimized);
		};

		useEffect(() => {
			toggleExpand(!isMinimized);
			setDragging({
				active: false,
				x: -15,
				y: isMinimized ? 520 : -15,
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isMinimized]);

		useEffect(() => {
			// expand if requested by the parent
			setIsMinimized(!isExpanded);
		}, [isExpanded]);

		const containerElement = document.querySelector('.note-drag-container');

		const onDrag = (event: DraggableEvent, { x, y }: DraggableData) => {
			event.stopPropagation();
			setIsMinimized(false);
			setDragging({ active: true, x, y });
		};

		const onDragStop = (_: DraggableEvent, { x, y }: DraggableData) => {
			const { active } = dragging;
			if (active) {
				// we do get this event on a click too
				// only if started dragging
				setIsMinimized(false);
				const offset = 15;
				const containerPosition = containerElement?.getBoundingClientRect();
				// @ts-ignore
				const leftPosDiff = window.innerWidth - containerPosition.width - offset;
				// @ts-ignore
				const topPosDiff = window.innerHeight - containerPosition.height - offset;
				let cX = x;
				let cY = y;
				if (x <= -leftPosDiff) {
					cX = -leftPosDiff;
				}
				if (y <= -topPosDiff) {
					cY = -topPosDiff;
				}
				if (x >= -offset) {
					cX = -offset;
				}
				// @ts-ignore
				// @ts-ignore
				if (y + containerPosition.height >= containerPosition.height - offset) {
					cY = -offset;
				}
				setDragging({ active: false, x: cX, y: cY });
			}
		};

		const onRestore = () => {
			if (isMinimized) {
				setIsMinimized(false);
			}
		};

		const bringToFront = () => windowContext.bringToFront(queueNoteWindowId);

		const handleSave = () => onToggleClick();

		const content = (
			<>
				<TransparentButton className={css(styleSheet.headerButton)}>
					<div className={`${css(styleSheet.header)} drag-handle`}>
						<div>{!isMinimized && <DraggableIcon className={css(styleSheet.draggableIcon)} />}</div>
						<div className={css(aidaBaseStyleSheet.flexHorizontalCenter)}>
							{/* @ts-ignore */}
							<div onClick={dragging.active ? null : onToggleClick} className={css(styleSheet.iconContainer)}>
								{isMinimized ? <ExpandIcon fillColor={white} /> : <MinimizeIcon fillColor={white} />}
							</div>
						</div>
					</div>
				</TransparentButton>
				<div>
					<QueueNoteEditor
						ccUsers={ccUsers}
						defaultNote={defaultNote}
						doNotMarket={doNotMarket}
						editorState={editorState}
						note={note}
						onSave={handleSave}
						processing={processing}
						setCcUsers={setCcUsers}
						setDoNotMarket={setDoNotMarket}
						setEditorState={setEditorState}
						setNoteAndEditor={setNoteAndEditor}
						setProcessing={setProcessing}
					/>
				</div>
			</>
		);
		const editor = (
			<animated.div
				style={{
					zIndex: calculateDraggableWindowZIndex(windowContext.windowIds, queueNoteWindowId),
				}}
				className={`note-drag-container ${css(styleSheet.queueLeadNoteSliderContainer)} ${className}`}
				onClick={bringToFront}
			>
				{content}
			</animated.div>
		);

		return (
			<AidaDraggable
				onDrag={onDrag}
				onStop={onDragStop}
				onMouseDown={onRestore}
				defaultPosition={{ x: -15, y: -15 }}
				// @ts-ignore
				// @ts-ignore
				position={{ x: dragging.x, y: dragging.y }}
				bounds={false}
				isDraggable={true}
				disabled={isMinimized}
				// @ts-ignore
				defaultClassNameDragging={null}
			>
				{editor}
			</AidaDraggable>
		);
	}
);
