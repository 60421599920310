import { CssSelectors, FontFamilies } from '../../../../web/styles/styles';
import { backgroundGreen, brandPrimary, brandSecondary, gold, gray, white } from '../../../styles/colors';
import { aidaBaseStyles } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	audioColumn: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
	},
	copyAudioLink: {
		display: 'flex',
		padding: 12,
		paddingLeft: 0,
		...CssSelectors.allChildren(
			{
				height: 14,
				width: 14,
			},
			'svg'
		),
		...CssSelectors.allChildren(
			{
				marginRight: 5,
				marginTop: -4,
			},
			':first-child:not(svg)'
		),
	},
	dot: {
		borderRadius: '50%',
		height: 10,
		marginLeft: 5,
		width: 10,
	},
	empty: {
		color: gray,
		padding: 12,
		paddingLeft: 0,
	},
	followUpClockIcon: {
		marginLeft: 10,
		...CssSelectors.allChildren(
			{
				height: 14,
				width: 14,
			},
			'svg'
		),
	},
	followUpContainer: {
		background: brandSecondary,
		borderRadius: 14,
		display: 'inline-block',
		height: '50',
		padding: '10px 14px',
		width: '100',
		...CssSelectors.allDescendants({
			fontFamily: FontFamilies.semiBold,
		}),
	},
	followUpName: {
		':nth-child(1n)': {
			fontFamily: FontFamilies.regular,
		},
		color: white,
		marginLeft: 10,
	},
	followUpOverdue: {
		color: `${gold} !important`,
	},
	fontSizeSmall: {
		fontSize: 14,
	},
	iconPosition: {
		marginBottom: -1,
		marginLeft: 6,
	},
	label: {
		display: 'inline-block',
		fontSize: '11px',
		minWidth: 90,
		textAlign: 'right',
		textTransform: 'uppercase',
	},
	lastDealContainer: {
		background: backgroundGreen,
		borderRadius: 14,
		color: brandPrimary,
		display: 'inline-block',
		height: '50',
		padding: '10px 14px',
		width: '100',
		...CssSelectors.allDescendants({
			color: brandPrimary,
			fontFamily: FontFamilies.semiBold,
		}),
	},
	lastInteraction: {
		...aidaBaseStyles.flexHorizontalCenter,
		':first-child': {
			marginBottom: 4,
		},
		...CssSelectors.allChildren(
			{
				fontSize: '12px',
				paddingLeft: 5,
			},
			'span:last-child'
		),
	},
	link: {
		color: brandSecondary,
	},
	pepper: {
		height: 16,
		marginLeft: 10,
		width: 16,
	},
	reportLink: {
		color: 'white',
	},
	transcriptionComponents: {
		...CssSelectors.allDescendants(
			{
				marginTop: 4,
			},
			'.transcription-icon'
		),
	},
});
