import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { ChangeEvent, useEffect } from 'react';
import { IRecipient, IRichContentEditorState } from '../../../extViewmodels';
import { ToolbarAIDA } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useLambda } from '../../../models/hooks/useLambda';
import { Checkbox } from '../../../web/components/Checkbox';
import { LoadingSpinner } from '../../../web/components/LoadingSpinner';
import { CCField } from '../../../web/components/notes/CCField';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '../../../web/components/richContent/RichContentDocumentEditor';
import { FontFamilies } from '../../../web/styles/styles';
import { useTelephony } from '../../contexts/telephony';
import { useQueue } from '../../hooks/queue';
import { titles } from '../../styles/colors';
import { aidaBaseStyleSheet } from '../../styles/styles';
import { LeadAction } from '../../viewModels/leads/interfaces';
import { DecisionMakerSelect, SelectedDecisionMaker } from '../DecisionMakerSelect';
import { TransparentButton } from '../TransparentButton';
import { styleSheet } from './styles';

interface IProps {
	ccUsers?: IRecipient[];
	className?: string;
	decisionMaker?: SelectedDecisionMaker;
	doNotMarket?: boolean;
	editorClassName?: string;
	editorConfig?: Partial<IRichContentDocumentEditorConfig>;
	editorState: IRichContentEditorState;
	loading?: boolean;
	onCCUsersChange?: (ccUsers: IRecipient[]) => void;
	onContentChange?: (editorState: IRichContentEditorState) => void;
	onDecisionMakerChange?: (value: SelectedDecisionMaker) => void;
	onDoNotMarketChange?: (doNotMarket: boolean) => void;
	onEditorStateChange: (editorState: IRichContentEditorState) => void;
	readOnly?: boolean;
}

export const DefaultAIDANoteContentCSS = `
	body {
		background: transparent;
		color: ${titles};
		font-family: ${FontFamilies.regular};
		font-size: 14px;
		margin: 0;
		padding: 10px 20px;
	}
`;

const DefaultEditorConfig: IRichContentDocumentEditorConfig = {
	autoresizeToFitContent: true,
	contentHorizontalPadding: 20,
	contentRawCss: DefaultAIDANoteContentCSS,
	minHeight: 100,
	plugins: 'emoticons',
	toolbar: ToolbarAIDA,
};

const AidaNoteEditorBase: React.FC<IProps> = ({
	ccUsers,
	className = '',
	decisionMaker,
	doNotMarket,
	editorConfig = {},
	editorState,
	loading,
	onCCUsersChange,
	onContentChange,
	onDecisionMakerChange,
	onDoNotMarketChange,
	onEditorStateChange,
	readOnly = false,
}) => {
	const userSession = useUserSession();
	const queue = useQueue();
	const { phoneCalls } = useTelephony();
	const [isAddingCC, , setIsAddingCC] = useLambda(false);

	useEffect(() => {
		setIsAddingCC(false)();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue.companyId]);

	const onDoNotMarketInputChange = (e: ChangeEvent<HTMLInputElement>) => onDoNotMarketChange?.(e.target.checked);

	const handleContentStateChange = (content: IRichContentEditorState) => {
		onContentChange?.(content);
		onEditorStateChange?.(content);
	};

	return (
		<div className={`${css(styleSheet.noteEditorContainer)} ${className}`}>
			{!!onCCUsersChange && (
				<div className={css(styleSheet.ccContainer)}>
					{!userSession.account.isLevitateSalesCoffeeAccount && (
						<label className={`${css(aidaBaseStyleSheet.flexHorizontalCenter, styleSheet.decisionMakerLabel)}`}>
							Connected With:
							{/* @ts-ignore */}
							{/* @ts-ignore */}
							<DecisionMakerSelect onSelect={onDecisionMakerChange} selected={decisionMaker} />
						</label>
					)}
					{isAddingCC || ccUsers?.length ? (
						<CCField
							ccUsers={ccUsers}
							className={css(styleSheet.ccField)}
							onCCUsersChanged={onCCUsersChange}
							userSession={userSession}
						/>
					) : (
						<TransparentButton className={css(styleSheet.addCcButton)} onClick={setIsAddingCC(true)}>
							Add CC
						</TransparentButton>
					)}
					{/** Removed the do not market check box. BE does nothing with it. Leaving it here. */}
					{queue.action !== LeadAction.Skip && false && (
						<div className={css(styleSheet.doNotMarketContainer)}>
							<Checkbox checked={doNotMarket} id='do-not-market-checkbox' onChange={onDoNotMarketInputChange} />
							<label className={css(styleSheet.doNotMarketLabel)} htmlFor='do-not-market-checkbox'>
								Do Not Market
							</label>
						</div>
					)}
				</div>
			)}
			<div className={css(styleSheet.editorContainer)}>
				{loading || phoneCalls.currentCall?.isSaving ? (
					<LoadingSpinner className={css(aidaBaseStyleSheet.absoluteCenter)} type='small' />
				) : (
					<RichContentDocumentEditor
						autoFocus={true}
						className='note-editor-body-editor'
						config={{ ...DefaultEditorConfig, ...editorConfig }}
						contentPlaceholderText=''
						contentState={editorState}
						onContentStateChanged={handleContentStateChange}
						readOnly={readOnly}
					/>
				)}
			</div>
		</div>
	);
};

export const AidaNoteEditor = observer(AidaNoteEditorBase);
