import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { AchievementConfigurationTable } from '../../../components/slotMachines/settings/AchievementConfigurationTable';
import { useSlotMachineAchievements } from '../../../components/slotMachines/settings/AchievementConfigurationTable/hooks';
import { PrizeConfigurationTable } from '../../../components/slotMachines/settings/PrizeConfigurationTable';
import { BasicPage } from '../../BasicPage';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const SlotMachines: React.FC<IProps> = observer(props => {
	const { className, styles = [] } = props;
	const { achievements } = useSlotMachineAchievements();
	return (
		<BasicPage
			className={`${css(styleSheet.container, ...styles)} slot-machines-settings ${className || ''}`}
			title='slot machine'
		>
			<div className={css(styleSheet.pageHeader)} />
			<div className={css(styleSheet.body)}>
				{achievements.isLoading ? (
					<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} />
				) : (
					<>
						<AchievementConfigurationTable achievements={achievements} styles={[styleSheet.activityTable]} />
						{achievements.selectedAchievement ? (
							<div>
								<div className={css(styleSheet.prizeTableTitle)}>Prizes will be randomized</div>
								<PrizeConfigurationTable
									achievement={achievements.selectedAchievement}
									key={achievements.selectedAchievement?.gameId}
								/>
							</div>
						) : null}
					</>
				)}
			</div>
		</BasicPage>
	);
});
