/* eslint-disable react-hooks/exhaustive-deps */
import { animated, config, useSpring } from '@react-spring/web';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	IRawRichTextContentState,
	IRecipient,
	IRichContentEditorState,
	NoteViewModel,
	RichContentReferenceMethod,
} from '../../../extViewmodels';
import { getDisplayName } from '../../../extViewmodels/Utils';
import { IToasterComponentProps } from '../../../models/AppState';
import { createRichContentEditorStateWithText, debounce } from '../../../models/UiUtils';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useLambda } from '../../../models/hooks/useLambda';
import { Button } from '../../../web/components/Button';
import { useDealModal } from '../../contexts/dealModal';
import { useQueueLeadNote } from '../../contexts/queueLeadNote';
import { useTelephony } from '../../contexts/telephony';
import { NotesStoreKeyTypes, useNotesStore } from '../../entities/LeadView/useNotesStore';
import { useErrorMessages, useGamification } from '../../hooks';
import { useQueue } from '../../hooks/queue';
import { useTimeout } from '../../hooks/useTimeout';
import { ToasterViewModelKey } from '../../models/AppState';
import { FieldKey } from '../../viewModels/form';
import { LeadAction } from '../../viewModels/leads/interfaces';
import { AidaNoteViewModel } from '../../viewModels/note';
import { IPhoneCallOutcome } from '../../viewModels/phonecall';
import { ILeadFollowUp } from '../../viewModels/queue';
import { AidaNoteEditor } from '../AidaNoteEditor';
import { SelectedDecisionMaker } from '../DecisionMakerSelect';
import { FollowUpSelect } from '../followup/FollowUpSelect';
import { ConnectedOptions } from './ConnectedOptions';
import { ShortcutOptions } from './ShortcutOptions';
import { styleSheet } from './styles';

interface IProps extends IToasterComponentProps {
	ccUsers: IRecipient[];
	className?: string;
	defaultNote?: AidaNoteViewModel;
	doNotMarket: boolean;
	editorState: IRichContentEditorState;
	note: AidaNoteViewModel;
	onSave: (note: NoteViewModel, canceled: boolean) => void;
	processing: boolean;
	setCcUsers: React.Dispatch<React.SetStateAction<IRecipient[]>>;
	setDoNotMarket: React.Dispatch<React.SetStateAction<boolean>>;
	setEditorState: React.Dispatch<React.SetStateAction<IRichContentEditorState>>;
	setNoteAndEditor(note: AidaNoteViewModel): void;
	setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
}

const leftColDefaultWidth = { width: '275px' };

const QueueNoteEditorBase: React.FC<IProps> = ({
	ccUsers,
	className = '',
	defaultNote,
	doNotMarket,
	editorState,
	note,
	onSave,
	processing,
	setCcUsers,
	setDoNotMarket,
	setEditorState,
	setNoteAndEditor,
	setProcessing,
	toaster,
}) => {
	const userSession = useUserSession();
	const queue = useQueue();
	const gamification = useGamification();
	const { phoneCalls, isPendingOutcome, setIsPendingOutcome } = useTelephony();
	const errorMessages = useErrorMessages();
	const dealModal = useDealModal();
	const queueNote = useQueueLeadNote();
	const [error, setError] = useLambda('');

	const history = useNotesStore(NotesStoreKeyTypes.Notes);

	// @ts-ignore
	const [historyCurrentState, setHistoryCurrentState] = useState<IRichContentEditorState>(null);

	const companyId = queue?.lead?.company?.id;
	// @ts-ignore
	const currentHistory = history?.state?.[companyId];
	const timeout = useTimeout();

	useEffect(() => {
		if (companyId && historyCurrentState) {
			const raw = { ...historyCurrentState };
			if (!historyCurrentState?.hasContent() || !raw?.getPlainTextPreview().trim()) {
				return;
			}
			history.update({
				[companyId]: {
					content: raw?.getRawRichTextContent(),
					plain: raw?.getPlainTextPreview(),
				},
			});
		}
	}, [companyId, historyCurrentState]);

	useEffect(() => {
		if (currentHistory?.plain) {
			timeout(() => {
				setEditorState(createRichContentEditorStateWithText(currentHistory.plain));
			}, 200);
		}
	}, []);

	const [leftColSpringProps, setLeftColSpringProps] = useState({
		from: leftColDefaultWidth,
		to: leftColDefaultWidth,
	});

	// @ts-ignore
	const [decisionMaker, setDecisionMaker] = useState<SelectedDecisionMaker>(null);

	const leftColSpring = useSpring({
		config: config.default,
		...leftColSpringProps,
	});

	let outcomesList = queue?.outcomes?.find(o => o.id === phoneCalls.currentCall?.outcome?.id);
	if (!outcomesList) {
		outcomesList = queue?.outcomes?.find(o => o.name === FieldKey.Connected);
	}
	const outcomesToRender = outcomesList?.children?.filter(x => x.name !== FieldKey.BadLead);

	useEffect(() => {
		if (defaultNote) {
			setProcessing(true);
			// @ts-ignore
			defaultNote
				.load()
				.then(() => {
					setNoteAndEditor(defaultNote);
				})
				.catch(err => {
					// @ts-ignore
					errorMessages.pushApiError(err);
				})
				.finally(() => setTimeout(() => setProcessing(false), 0));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultNote]);

	useEffect(() => {
		if (queue.action === LeadAction.Next) {
			// @ts-ignore
			const size = Math.ceil(outcomesToRender?.length / 2) * 150;
			setLeftColSpringProps({
				from: leftColSpringProps.from,
				to: { width: `${size}px` },
			});
		} else {
			setLeftColSpringProps({
				from: leftColSpringProps.from,
				to: leftColDefaultWidth,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue.action]);

	const isSaveDisabled = useMemo((): string => {
		if (phoneCalls.currentCall) {
			if (!phoneCalls.currentCall?.outcome) {
				return 'You must select an outcome for the call';
			}

			if (phoneCalls?.currentCall?.outcome?.requireNote && !editorState.hasContent()) {
				return "The outcome you've selected requires a note";
			}
			return '';
		} else {
			// no call, this is saving a normal note
			if (!editorState.hasContent()) {
				return 'You must enter content to save a note';
			}
			return '';
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneCalls.currentCall, phoneCalls.currentCall?.outcome, editorState]);

	useEffect(() => {
		setError(isSaveDisabled);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSaveDisabled]);

	const buildNoteToSave = () => {
		const hasContent = editorState?.hasContent();
		if (!hasContent) {
			return null;
		}

		const newNote = note.toNote();

		newNote.content = editorState.getRawRichTextContent() as IRawRichTextContentState;
		// @ts-ignore
		newNote.context = null;
		newNote.visibility = 'all';
		newNote.referencedEntities = {
			companies: [],
			contacts: [],
			users: [],
		};

		// @ts-ignore
		newNote.referencedEntities.companies.push({
			entity: {
				id: queue.lead.company.id,
			},
			method: RichContentReferenceMethod.Explicit,
		});

		ccUsers.forEach(x => {
			// @ts-ignore
			// @ts-ignore
			newNote.referencedEntities.users.push({
				entity: {
					id: x.id,
				},
				method: RichContentReferenceMethod.Explicit,
			});
		});

		// remove legacy keys
		delete (newNote.content as any).entityMap;
		delete (newNote.content as any).blocks;
		return newNote;
	};

	const resetDecisionMakerChange = () => {
		// @ts-ignore
		setDecisionMaker(null);
		// @ts-ignore
		queue.lead.decisionMaker = null;
	};

	const handleDecisionMakerChange = (selected: SelectedDecisionMaker) => {
		setDecisionMaker(selected);
		if (selected) {
			// @ts-ignore
			queue.lead.decisionMaker = selected.id === 'other' ? null : selected.id;
		}
	};

	const onSaveClick = async () => {
		const outcome = phoneCalls.currentCall?.outcome?.name;
		if ([FieldKey.Booked].includes(outcome)) {
			// @ts-ignore
			toaster.push({
				message: 'Please update or create a deal using the deal modal',
				type: 'errorMessage',
			});
			return;
		}

		setProcessing(true);
		const newNote = buildNoteToSave();

		try {
			if (newNote) {
				const url =
					!!phoneCalls?.currentCall?.id && phoneCalls?.currentCall?.id !== 'manual'
						? `phonecall/${phoneCalls.currentCall.id}/Note`
						: `lead/${queue.lead.company.id}/Note`;
				// @ts-ignore
				await note.saveLeadNote(newNote, url, null, !!phoneCalls.currentCall?.outcome, queue.lead.company.id);
			}

			if (phoneCalls.currentCall?.outcome) {
				const meme = await phoneCalls.currentCall?.saveOutcome(
					// @ts-ignore
					note?.id,
					queue.lead.selectedFollowUp,
					doNotMarket,
					queue.leadServedSource,
					{ decisionMaker: queue.lead?.decisionMaker }
				);

				const selectedFollowUp = queue.lead.selectedFollowUp as ILeadFollowUp;

				if (selectedFollowUp?.scheduled) {
					queue.lead.setFollowUp(selectedFollowUp);
				}

				if (meme) {
					// @ts-ignore
					gamification.setMeme(meme);
				}
			}

			// notify user of successful change
			// @ts-ignore
			toaster.push({
				message: 'Your note has been saved successfully.',
				type: 'successMessage',
			});

			// Clear last note history
			history?.clear();

			// notify parent component
			onSave(note, false);
			queue.lead.note = note;
			// @ts-ignore
			queue.setAction(undefined);

			// reset note
			// @ts-ignore
			queueNote.setDefaultNote(null);
			const raw = new AidaNoteViewModel(userSession);
			setNoteAndEditor(raw);

			// reset decision maker select
			resetDecisionMakerChange();

			phoneCalls.clearCurrentCall();
			setIsPendingOutcome(false);
		} catch (err) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(err);
		} finally {
			setProcessing(false);
		}
	};

	const onStatusOptionClicked = (option: IPhoneCallOutcome) => {
		// load note
		const newNote = note.toNote();
		newNote.content = editorState.getRawRichTextContent() as IRawRichTextContentState;

		switch (option.name) {
			case FieldKey.Booked:
				dealModal.setNote(newNote);
				setTimeout(() => {
					queue.lead?.createDealForm();
					dealModal.setShowDealModal(true);
					queueNote.setShowQueueLeadNote(false);
				}, 50);
				break;
			case FieldKey.AppointmentRescheduled:
			case FieldKey.NewDemo:
				dealModal.setInteractionNote(newNote);
				setTimeout(() => {
					const lastDeal = queue.lead?.lastDeal;
					// @ts-ignore
					// @ts-ignore
					queue.lead?.loadDealForm(lastDeal.id);
					dealModal.setShowDealModal(true);
					queueNote.setShowQueueLeadNote(false);
				}, 50);
				break;
			default:
				break;
		}
	};

	const onManuallyLogCallClicked = () => {
		// @ts-ignore
		phoneCalls.setExistingCall({ creationDate: new Date().toISOString(), id: 'manual' }, queue.companyId);
		queue.hasCalled = true;
	};

	const renderLeftCol = () => {
		const pendingOutcome = !!phoneCalls.currentCall;
		const showingShortcuts = !queue.action || queue.action === LeadAction.AddNote;

		const content = !pendingOutcome ? null : showingShortcuts ? (
			<ShortcutOptions onStatusOptionClicked={onStatusOptionClicked} />
		) : (
			// @ts-ignore
			<ConnectedOptions onStatusOptionClicked={onStatusOptionClicked} outcomesToRender={outcomesToRender} />
		);

		return (
			<animated.div className={css(styleSheet.leftCol)} style={leftColSpring}>
				{content}
				{showingShortcuts && (
					<div className={css(styleSheet.manualButton)}>
						<Button
							disabled={isPendingOutcome || !!phoneCalls.currentCall}
							label='Manually Log Call'
							onClick={onManuallyLogCallClicked}
							size='small'
						/>
					</div>
				)}
			</animated.div>
		);
	};

	const phoneCallString = phoneCalls.currentCall?.getCallMetadataDisplayString;

	const companyOrContactName =
		getDisplayName(
			// @ts-ignore
			queue.lead?.contacts?.find(x =>
				// @ts-ignore
				x.phoneNumbers.find(
					p =>
						p?.metadata?.e164 === phoneCalls.currentCall?.phoneNumber?.e164 &&
						p?.metadata?.extension === phoneCalls.currentCall?.phoneNumber?.extension
				)
			)
		) || queue.lead?.company?.companyName;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleContentChange: (editorState: IRichContentEditorState) => void = useCallback(
		debounce(
			args => {
				setHistoryCurrentState(args[0]);
			},
			1000,
			false
		),
		[]
	);

	return (
		<div>
			{phoneCallString && (
				<div className={css(styleSheet.phoneCallInfo)}>
					<span className={css(styleSheet.companyName)}>Call Notes: {companyOrContactName}</span>
					{phoneCallString}
				</div>
			)}
			<div
				className={`${css(
					styleSheet.noteEditorContainer,
					// @ts-ignore
					phoneCallString && styleSheet.noteEditorContainerShort
				)} ${className}`}
			>
				{renderLeftCol()}
				<div className={css(styleSheet.rightCol)}>
					<AidaNoteEditor
						ccUsers={ccUsers}
						decisionMaker={decisionMaker}
						doNotMarket={doNotMarket}
						editorConfig={{ maxHeight: 370, minHeight: 300 }}
						editorState={editorState}
						loading={processing || note.isBusy || note.isSaving}
						onCCUsersChange={setCcUsers}
						onContentChange={handleContentChange}
						onDecisionMakerChange={handleDecisionMakerChange}
						onDoNotMarketChange={setDoNotMarket}
						onEditorStateChange={setEditorState}
					/>
					{!!error && <div className={css(styleSheet.error)}>{error}</div>}
					<div className={css(styleSheet.ctaContainer)}>
						<FollowUpSelect label='Follow Up:' />
						<Button
							className={css(styleSheet.button)}
							disabled={!!isSaveDisabled}
							onClick={onSaveClick}
							label={`Save${queue.action === LeadAction.Skip ? ' & Skip' : ''}`}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const QueueNoteEditorAsObserver = observer(QueueNoteEditorBase);
export const QueueNoteEditor = inject(ToasterViewModelKey)(QueueNoteEditorAsObserver);
