import { IOperationResult } from '../../../extViewmodels';
import { useDealProperties } from '../../entities/Integration/useDealProperties';
import { useLeadProperties } from '../../entities/Integration/useLeadProperties';
import { FetchStatus } from '../../entities/utils/useStatesOnFetch';
import { IIntegrationPipelineItem, IIntegrationPropertyConfig } from '../../viewModels/leads/interfaces';
import { FC, createContext, useContext, useEffect, useState } from 'react';

type UpdateFunction = (
	value: IIntegrationPropertyConfig,
	callback?: (response: IOperationResult<IIntegrationPropertyConfig>) => void
) => void;

export interface IPropertiesContext {
	config: IIntegrationPropertyConfig;
	status: FetchStatus;
	update: UpdateFunction;
}

// @ts-ignore
const IntegrationPropertiesContext = createContext<IPropertiesContext>(null);

export const useIntegrationProperties = () => useContext<IPropertiesContext>(IntegrationPropertiesContext);

export enum IntegrationPropertiesTypes {
	Deals = 'deals',
	Leads = 'leads',
}

interface IProps {
	type: IntegrationPropertiesTypes;
}

export const IntegrationPropertiesContextProvider: FC<IProps> = ({ children, type }) => {
	// @ts-ignore
	const [config, setConfig] = useState<IIntegrationPropertyConfig>(null);

	const deals = useDealProperties<IIntegrationPropertyConfig>();
	const leads = useLeadProperties<IIntegrationPropertyConfig>();

	useEffect(() => {
		// @ts-ignore
		setConfig(null);
		if (type === IntegrationPropertiesTypes.Deals) {
			deals.getConfig().then(response => {
				// @ts-ignore
				deals.getPipelines().then(({ value }) => {
					// @ts-ignore
					setConfig({ ...config, ...response.value, pipelines: value as unknown as IIntegrationPipelineItem[] });
				});
			});
		}
		if (type === IntegrationPropertiesTypes.Leads) {
			// @ts-ignore
			leads.getConfig().then(response => setConfig({ ...config, ...response.value }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const update: UpdateFunction = (value, callback) => {
		const responseHandler = (response: IOperationResult<IIntegrationPropertyConfig>) => {
			callback?.(response);
			setConfig({ ...config, ...response.value });
		};
		if (type === IntegrationPropertiesTypes.Deals) {
			// @ts-ignore
			return deals.updateConfig(value).then(responseHandler);
		}
		if (type === IntegrationPropertiesTypes.Leads) {
			// @ts-ignore
			return leads.updateConfig(value).then(responseHandler);
		}
	};

	const status = type === IntegrationPropertiesTypes.Deals ? deals.status : leads.status;

	return (
		<IntegrationPropertiesContext.Provider
			value={{
				config,
				status,
				update,
			}}
		>
			{children}
		</IntegrationPropertiesContext.Provider>
	);
};
