import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { error, orange } from '../../../styles/colors';
import { FollowUpImportance } from '../../../viewModels/queue';
import { PepperIcon } from '../../svgs/icons/PepperIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	importanceLevel: FollowUpImportance;
	onImportanceLevelChange(level: FollowUpImportance): () => void;
}

export const SpicePicker: React.FC<IProps> = props => {
	const { className, importanceLevel, onImportanceLevelChange, styles = [] } = props;
	return (
		<div className={`${css(styleSheet.container, ...styles)} spice-picker ${className || ''}`}>
			<div
				onClick={onImportanceLevelChange(FollowUpImportance.Low)}
				className={css(styleSheet.pepper, importanceLevel === FollowUpImportance.Low && styleSheet.pepperSelected)}
			>
				<PepperIcon />
			</div>
			<div
				onClick={onImportanceLevelChange(FollowUpImportance.Medium)}
				className={css(styleSheet.pepper, importanceLevel === FollowUpImportance.Medium && styleSheet.pepperSelected)}
			>
				<PepperIcon fillColor={orange} />
			</div>
			<div
				onClick={onImportanceLevelChange(FollowUpImportance.High)}
				className={css(styleSheet.pepper, importanceLevel === FollowUpImportance.High && styleSheet.pepperSelected)}
			>
				<PepperIcon fillColor={error} />
			</div>
		</div>
	);
};
