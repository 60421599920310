import { brandPrimary, green } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	incomingCallsContainer: {
		padding: '0 30px',
	},
	label: {
		color: green,
	},
	sep: {
		background: green,
		height: 1,
		margin: '10px 0',
		width: '100%',
	},
	unknownNumber: {
		marginTop: 10,
	},
	whoIsThis: {
		color: brandPrimary,
		marginTop: 10,
	},
});
