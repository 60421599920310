import { StyleSheet } from 'aphrodite';
import { FontFamilies } from '../../../web/styles/styles';
import { brandPrimary, white } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		...aidaBaseStyles.cardShadow,
		background: white,
		borderRadius: 5,
		maxWidth: 500,
		width: '100%',
	},
	copyButton: {
		marginLeft: 10,
	},
	copyIcon: {
		height: 14,
		width: 14,
	},
	flexBetween: {
		...aidaBaseStyles.flexCenter,
		justifyContent: 'space-between',
		width: '100%',
	},
	header: {
		...aidaBaseStyles.flexHorizontalCenter,
		background: brandPrimary,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		color: white,
		height: 60,
		justifyContent: 'space-between',
		padding: '0 14px',
		position: 'relative',
	},
	playerWrapper: {
		padding: '14px 20px',
	},
	subtitle: {
		fontSize: 14,
	},
	title: {
		fontFamily: FontFamilies.bold,
		fontSize: 16,
	},
	titleBar: {
		alignItems: 'center',
		display: 'flex',
	},
	titleContainer: {
		textAlign: 'left',
	},
});
