import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const SmallArrowUpIcon: React.FC<IProps> = ({ className = '', fill = '' }) => {
	return (
		<SvgIcon className={`small-arrow-icon ${className}`} height={8} width={9}>
			<path
				d='m760.544 647.641v4.359h1.912v-4.359l2.192 2.179 1.352-1.345-4.5-4.475-4.5 4.475 1.352 1.345z'
				fill={fill}
				fillRule='evenodd'
				transform='translate(-757 -644)'
			/>
		</SvgIcon>
	);
};
