import { brandPrimaryHover, darkBlue, gold, goldDark } from '../../../styles/colors';
import { animated, config, useSpring } from '@react-spring/web';
import { Ref } from 'react';
import * as React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
	ref?: Ref<SVGSVGElement>;
	selected: boolean;
	animate: boolean;
}

const width = 127;
const height = 141;
const hexFrom = {
	fill: brandPrimaryHover,
	stroke: darkBlue,
};
const hexTo = {
	fill: goldDark,
	stroke: gold,
};

export const HexGraphic: React.FC<IProps> = ({ className = '', selected, animate }) => {
	const spring = useSpring({
		config: config.gentle,
		from: hexFrom,
		to: selected ? hexTo : hexFrom,
	});

	if (animate) {
		return (
			<animated.svg
				className={`hex-graphic ${className}`}
				height={height}
				style={spring}
				viewBox={`0 0 ${width} ${height}`}
				width={width}
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M55.4567 7.6188C60.4071 4.76068 66.5063 4.76068 71.4567 7.6188L113.913 32.1312C118.864 34.9893 121.913 40.2714 121.913 45.9876V95.0124C121.913 100.729 118.864 106.011 113.913 108.869L71.4567 133.381C66.5063 136.239 60.4071 136.239 55.4567 133.381L13 108.869C8.04958 106.011 5 100.729 5 95.0124V45.9876C5 40.2714 8.04958 34.9893 13 32.1312L55.4567 7.6188Z'
					strokeWidth='10'
				/>
			</animated.svg>
		);
	}

	return (
		<svg
			className={`hex-graphic ${className}`}
			height={height}
			fill={goldDark}
			stroke={gold}
			viewBox={`0 0 ${width} ${height}`}
			width={width}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M55.4567 7.6188C60.4071 4.76068 66.5063 4.76068 71.4567 7.6188L113.913 32.1312C118.864 34.9893 121.913 40.2714 121.913 45.9876V95.0124C121.913 100.729 118.864 106.011 113.913 108.869L71.4567 133.381C66.5063 136.239 60.4071 136.239 55.4567 133.381L13 108.869C8.04958 106.011 5 100.729 5 95.0124V45.9876C5 40.2714 8.04958 34.9893 13 32.1312L55.4567 7.6188Z'
				strokeWidth='10'
			/>
		</svg>
	);
};
