import { FontFamilies } from '../../../../web/styles/styles';
import { white } from '../../../styles/colors';
import { aidaBaseStyles } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	description: {
		color: white,
		fontFamily: FontFamilies.semiBold,
		fontSize: 14,
	},
	header: {
		...aidaBaseStyles.flexCenter,
		minWidth: '100%',
	},
});
