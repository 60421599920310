import { IRawRichTextContentState, IRichContentEditorState, RichContentReferenceMethod } from '../../../extViewmodels';
import { convertRawRichTextContentStateToRichContentEditorState } from '../../../models/UiUtils';
import { EditItemPenIcon } from '../../../web/components/svgs/icons/EditItemPenIcon';
import { useErrorMessages } from '../../hooks';
import { useQueue } from '../../hooks/queue';
import { AidaNoteEditor } from '../AidaNoteEditor';
import { TransparentButton } from '../TransparentButton';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { useState } from 'react';
import * as React from 'react';

interface IProps {
	className?: string;
	onChange?: (summary: string) => void;
}

export const ExecutiveSummary: React.FC<IProps> = ({ className = '' }) => {
	const queue = useQueue();
	const errorMessages = useErrorMessages();
	const [isEditing, setIsEditing] = useState(false);
	const [editorState, setEditorState] = useState<IRichContentEditorState>(
		convertRawRichTextContentStateToRichContentEditorState(queue.lead?.executiveSummary?.rawContentState)
	);

	const onCancelClick = () => {
		setIsEditing(false);
	};

	const onEditClick = () => {
		setIsEditing(true);
	};

	const onSaveClick = async () => {
		const note = queue.lead?.executiveSummary.toNote();

		note.content = editorState.getRawRichTextContent() as IRawRichTextContentState;
		// @ts-ignore
		note.context = null;
		note.visibility = 'all';
		note.referencedEntities = {
			companies: [],
			contacts: [],
			users: [],
		};

		// @ts-ignore
		note.referencedEntities.companies.push({
			entity: {
				id: queue.lead.company.id,
			},
			method: RichContentReferenceMethod.Explicit,
		});

		// remove legacy keys
		delete (note.content as any).entityMap;
		delete (note.content as any).blocks;

		try {
			await queue.lead.saveExecutiveSummary(note, `lead/${queue.lead.company.id}/Note`);
		} catch (err) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(err);
		}

		setIsEditing(false);
	};

	const renderButtons = () => {
		if (isEditing) {
			return (
				<>
					<button className={css(styleSheet.button, styleSheet.saveButton)} onClick={onSaveClick}>
						Save
					</button>
					<TransparentButton className={css(styleSheet.button)} onClick={onCancelClick}>
						<span className={css(styleSheet.buttonText)}>Cancel</span>
					</TransparentButton>
				</>
			);
		}

		return (
			<TransparentButton className={css(styleSheet.button)} onClick={onEditClick}>
				<EditItemPenIcon />
			</TransparentButton>
		);
	};

	return (
		<div className={`${css(styleSheet.executiveSummaryContainer)} ${className}`}>
			<div className={css(styleSheet.header, isEditing && styleSheet.headerEditing)}>
				<div className={css(styleSheet.title)}>Executive Summary</div>
				<div className={css(styleSheet.buttonsContainer)}>{renderButtons()}</div>
			</div>
			{!!editorState?.hasContent() || isEditing ? (
				<AidaNoteEditor
					className={css(styleSheet.noteEditorContainer)}
					editorConfig={{ maxHeight: 200 }}
					editorState={editorState}
					onEditorStateChange={setEditorState}
					readOnly={!isEditing}
				/>
			) : (
				<div className={css(styleSheet.summaryPlaceholder)}>Please add a summary...</div>
			)}
		</div>
	);
};
