import { Layouts } from '../../../../../web/styles/styles';
import { brandPrimary } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const PrizeConfigurationTableWidth = 420;
const symbolColWidth = 80;
const prizeColCheckboxContainerWidth = 76;
const prizeColCheckboxContainerMargin = 8;

export const styleSheet = StyleSheet.create({
	container: {
		background: brandPrimary,
		borderRadius: 10,
		boxSizing: 'border-box',
		padding: '18px 20px',
		width: PrizeConfigurationTableWidth,
	},
	header: {
		color: '#33C5FA',
		marginBottom: 26,
	},
	prizeCol: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		marginLeft: 55,
	},
	prizeColCheckboxContainer: {
		color: '#fff',
		fontSize: 12,
		marginLeft: prizeColCheckboxContainerMargin,
		width: prizeColCheckboxContainerWidth,
	},
	prizeColCheckboxText: {
		color: '#fff',
		fontSize: 12,
	},
	prizeColHeader: {
		':not(:first-of-type)': {
			marginTop: 36,
		},
		color: '#fff',
		display: 'flex',
		fontSize: 11,
		letterSpacing: '1.1px',
		marginBottom: 12,
	},
	prizeColInputContainer: {
		width: `calc(100% - ${prizeColCheckboxContainerWidth + prizeColCheckboxContainerMargin + 25}px)`,
	},
	prizeColInputDisabled: {
		opacity: 0.5,
	},
	row: {
		':not(:first-child)': {
			marginTop: 8,
		},
		alignItems: 'center',
		display: 'flex',
	},
	symbol: {
		height: 'auto',
		maxHeight: 22,
		width: 'auto',
	},
	symbolCol: {
		alignItems: 'center',
		display: 'flex',
		flexShrink: 0,
		justifyContent: 'space-between',
		width: symbolColWidth,
	},
	symbolCol2Matching: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		flexShrink: 0,
		justifyContent: 'space-between',
		marginRight: symbolColWidth - 50,
		width: 50,
	},
	symbols: {
		...Layouts.horizontalStack(8),
	},
});
