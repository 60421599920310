import { ViewportContext } from '../../hooks/useViewport';
import { Logo } from '../Logo';
import { MobileHeader } from '../MobileHeader';
import { MobileMenu } from '../MobileMenu';
import { Nav } from '../Nav';
import { PointsProgressBar } from '../PointsProgressBar';
import { StatusBanner } from '../StatusBanner';
import { UserOptions } from '../UserOptions';
import { Fill } from '../leadView/components/Fill';
import { Group } from '../leadView/components/Group';
import { CoffeeLogo } from '../svgs/CoffeeLogo';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

export const Header: React.FC = () => {
	const location = useLocation();
	const [showGamification, setShowGamification] = useState(false);
	// @ts-ignore
	const { isBelowSmall } = useContext(ViewportContext);

	useEffect(() => {
		setShowGamification(
			!!location?.pathname && !location?.pathname.includes('settings') && !location?.pathname.includes('reporting')
		);
	}, [location.pathname]);

	return isBelowSmall ? (
		<div className={css(styleSheet.headerContainer, styleSheet.mobileHeaderContainer)}>
			<Group vCentered={true}>
				<MobileMenu />
				{/* @ts-ignore */}
				<Logo linkTo={null} />
				<Fill />
				<MobileHeader />
			</Group>
		</div>
	) : (
		<div className={css(styleSheet.headerContainer)}>
			<Link to='/queue'>
				<CoffeeLogo className={css(styleSheet.logo)} />
			</Link>
			<Nav className={css(styleSheet.nav)} />
			{showGamification && <PointsProgressBar className={css(styleSheet.pointsProgressBar)} />}
			<UserOptions />
			{showGamification && <StatusBanner />}
		</div>
	);
};
