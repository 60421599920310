import { InputField } from '../../InputField';
import { styleSheet } from './../styles';
import { css } from 'aphrodite';
import { ChangeEvent, useEffect, useState } from 'react';
import * as React from 'react';

interface IProps {
	isRequired?: boolean;
	isHalf?: boolean;
	label: string;
	name: string;
	onChange: (param: { name: string; value: string }) => void;
	value?: string;
}

export const TextFieldType: React.FC<IProps> = ({ isHalf, isRequired, label, name, onChange, value }) => {
	// @ts-ignore
	const [innerValue, setValue] = useState<string>(null);

	useEffect(() => {
		// @ts-ignore
		setValue(value);
	}, [value]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		onChange({ name, value: e.target.value });
	};

	return (
		<InputField
			className={css([styleSheet.fieldContainer, isHalf && styleSheet.fieldContainerHalf])}
			handler={handleChange}
			id={name}
			label={
				<span>
					{label} {isRequired && <sup>*</sup>}
				</span>
			}
			value={innerValue}
		/>
	);
};
