import facebookLogo from '../../../web/assets/facebook@2x.png';
import linkedInLogo from '../../../web/assets/linkedin@2x.png';
import twitterLogo from '../../../web/assets/twitter@2x.png';
import { styleSheet } from './styles';
import { ISocialProfile } from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	socialProfiles?: ISocialProfile[];
}

interface ISocialProfileWithLogo extends ISocialProfile {
	logo: string;
}

// only these profiles will be included.
// if the company does not have one of the set,
// a disabled button with the profile logo
// will be displayed.
const supportedProfiles: ISocialProfileWithLogo[] = [
	{
		logo: facebookLogo,
		typeId: 'facebook',
	},
	{
		logo: linkedInLogo,
		typeId: 'linkedin',
	},
	{
		logo: twitterLogo,
		typeId: 'twitter',
	},
];

export const SocialProfiles: React.FC<IProps> = ({ className = '', socialProfiles }) => {
	const renderSocialLinks = () => {
		// get supported profiles
		const profiles = supportedProfiles.map(sp => {
			const existingProfile = (socialProfiles || []).find(profile => profile.typeId === sp.typeId);

			return existingProfile ? { ...sp, ...existingProfile } : sp;
		});

		return profiles.map(p => {
			const img = (
				<div key={p.typeId} className={css(styleSheet.imgContainer, !p.url && styleSheet.disabled)}>
					<img src={p.logo} alt={`${p.typeId} logo`} />
				</div>
			);

			return p.url ? (
				<a key={`${p.typeId}-link`} href={p.url} target='_blank' rel='noreferrer'>
					{img}
				</a>
			) : (
				img
			);
		});
	};

	return <div className={`${css(styleSheet.socialProfilesContainer)} ${className}`}>{renderSocialLinks()}</div>;
};
