import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	text: string;
}

export const NoneFound: React.FC<IProps> = ({ className = '', text }) => {
	return <div className={`${css(styleSheet.noneFoundContainer)} ${className}`}>{text}</div>;
};
