import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	label: string;
}

export const DealPropertyCard: React.FC<IProps> = ({ label }) => {
	return <div className={css(styleSheet.dealPropertyLabel)}>{label}</div>;
};
