import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useState } from 'react';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { getDisplayName } from '../../../../extViewmodels/Utils';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { useErrorMessages } from '../../../hooks';
import { GoalsViewModel, IGoals, UserDailyActivity } from '../../../viewModels/goals';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	goals: GoalsViewModel;
}

interface IData {
	name: string;

	values: IGoals;
}

export const GoalsTable: React.FC<IProps> = observer(props => {
	const { goals, className, styles = [] } = props;
	const errorMessages = useErrorMessages();
	// @ts-ignore
	const [extraData, setExtraData] = useState<IData>(null);

	const loadMore = () => {
		// @ts-ignore
		goals.loadNext()?.catch(err => errorMessages.pushApiError(err));
	};

	const setData = (data: IData) => () => {
		setExtraData(data);
	};

	return (
		<>
			<table className={`${css(styleSheet.container, ...styles)} goals-table ${className || ''}`}>
				<tr className={css(styleSheet.headerContainer)}>
					<th className={css(styleSheet.columnHeader)}>User</th>
					<th className={css(styleSheet.columnHeader, styleSheet.columnDay)}>Day</th>
					<th className={css(styleSheet.columnHeader, styleSheet.columnThin)}>Goals</th>
					<th className={css(styleSheet.columnHeader, styleSheet.columnThin)}>Score</th>
					<th className={css(styleSheet.columnHeader, styleSheet.columnThin)}>Projected</th>
					<th className={css(styleSheet.columnHeader, styleSheet.columnThin)}>Role</th>
					<th className={css(styleSheet.columnHeader, styleSheet.columnThin)}>Teams</th>
					<th className={css(styleSheet.columnHeader, styleSheet.columnThin)}>Lead Pool</th>
					<th className={css(styleSheet.columnHeader)}>Sub Lead Pools</th>
					<th className={css(styleSheet.columnHeader, styleSheet.columnDate)}>Last Modified</th>
				</tr>
				<tbody>
					{goals.items?.map(x => (
						<GoalsRow key={x.id} da={x} setExtraData={setData} />
					))}
					<tr>{goals.items?.length > 0 && <Waypoint bottomOffset='-200px' onEnter={loadMore} />}</tr>
					<tr>{goals.isLoading && <LoadingSpinner />}</tr>
				</tbody>
			</table>
			{extraData && (
				<div className={css(styleSheet.extraData)}>
					<div className={css(styleSheet.extraDataName)}>{extraData?.name}</div>
					<div className={css(styleSheet.extraDataRow)}>
						<div className={css(styleSheet.extraDataKey)}>Points</div>
						<div className={css(styleSheet.extraDataValue)}>{extraData?.values?.points}</div>
					</div>
					<div className={css(styleSheet.extraDataRow)}>
						<div className={css(styleSheet.extraDataKey)}>Dials</div>
						<div className={css(styleSheet.extraDataValue)}>{extraData?.values?.dials}</div>
					</div>
					<div className={css(styleSheet.extraDataRow)}>
						<div className={css(styleSheet.extraDataKey)}>Connections</div>
						<div className={css(styleSheet.extraDataValue)}>{extraData?.values?.connections}</div>
					</div>
					<div className={css(styleSheet.extraDataRow)}>
						<div className={css(styleSheet.extraDataKey)}>Demos</div>
						<div className={css(styleSheet.extraDataValue)}>{extraData?.values?.demos}</div>
					</div>
					<div className={css(styleSheet.extraDataRow)}>
						<div className={css(styleSheet.extraDataKey)}>Max Cold Demos</div>
						<div className={css(styleSheet.extraDataValue)}>{extraData?.values?.maxColdDemos}</div>
					</div>
					<div className={css(styleSheet.extraDataRow)}>
						<div className={css(styleSheet.extraDataKey)}>Max Follow Ups</div>
						<div className={css(styleSheet.extraDataValue)}>{extraData?.values?.maxFollowUps}</div>
					</div>
					<div className={css(styleSheet.extraDataRow)}>
						<div className={css(styleSheet.extraDataKey)}>Max Oldies</div>
						<div className={css(styleSheet.extraDataValue)}>{extraData?.values?.maxOldies}</div>
					</div>
					<div className={css(styleSheet.extraDataRow)}>
						<div className={css(styleSheet.extraDataKey)}>Max Research</div>
						<div className={css(styleSheet.extraDataValue)}>{extraData?.values?.maxResearch}</div>
					</div>
					<div className={css(styleSheet.extraDataRow)}>
						<div className={css(styleSheet.extraDataKey)}>Adjusted Dials</div>
						<div className={css(styleSheet.extraDataValue)}>{extraData?.values?.adjustedDials}</div>
					</div>
					<div className={css(styleSheet.extraDataRow)}>
						<div className={css(styleSheet.extraDataKey)}>Adjusted Demos</div>
						<div className={css(styleSheet.extraDataValue)}>{extraData?.values?.adjustedDemos}</div>
					</div>
				</div>
			)}
		</>
	);
});

interface IRowProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	da: UserDailyActivity;
	setExtraData(data: IData): () => void;
}

export const GoalsRow: React.FC<IRowProps> = observer(props => {
	const { da, className, setExtraData, styles = [] } = props;

	return (
		<tr className={`${css(styleSheet.rowContainer, ...styles)} goals-row ${className || ''}`}>
			{/* @ts-ignore */}
			<td className={css(styleSheet.rowData)} title={getDisplayName(da.creator)}>
				{/* @ts-ignore */}
				{getDisplayName(da.creator)}
			</td>
			<td className={css(styleSheet.rowData, styleSheet.columnDay)} title={da.day}>
				{moment(da.day).format('MM-DD-yyyy')}
			</td>
			<td
				className={css(styleSheet.rowData, styleSheet.rowDataInteractive, styleSheet.columnThin)}
				onMouseEnter={da.goals && setExtraData({ name: 'Goals', values: da.goals })}
				// @ts-ignore
				onMouseLeave={setExtraData(null)}
			>
				{da.goals && 'data'}
			</td>
			<td
				className={css(styleSheet.rowData, styleSheet.rowDataInteractive, styleSheet.columnThin)}
				onMouseEnter={da.score && setExtraData({ name: 'Score', values: da.score })}
				// @ts-ignore
				onMouseLeave={setExtraData(null)}
			>
				{da.score && 'data'}
			</td>
			<td
				className={css(styleSheet.rowData, styleSheet.rowDataInteractive, styleSheet.columnThin)}
				onMouseEnter={
					da.projectedScore &&
					setExtraData({
						name: 'Current Projected Score',
						values: da.projectedScore,
					})
				}
				// @ts-ignore
				onMouseLeave={setExtraData(null)}
			>
				{da.projectedScore && 'data'}
			</td>
			<td className={css(styleSheet.rowData, styleSheet.columnThin)} title={da.role}>
				{da.role}
			</td>
			<td className={css(styleSheet.rowData, styleSheet.columnThin)} title={da.teams?.join(', ')}>
				{da.teams?.join(', ')}
			</td>
			<td className={css(styleSheet.rowData, styleSheet.columnThin)} title={da.vertical?.industry}>
				{da.vertical?.industry}
			</td>
			<td className={css(styleSheet.rowData)} title={da.vertical?.subverticals?.join(', ')}>
				{da.vertical?.subverticals?.join(', ')}
			</td>
			<td className={css(styleSheet.rowData, styleSheet.columnDate)} title={da.lastModifiedDate}>
				{moment(da.lastModifiedDate).format('MM-DD-yyyy h:mm a')}
			</td>
		</tr>
	);
});
