import { brandPrimary, white } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	layer: {
		background: '#fff',
		borderRadius: 10,
		display: 'flex',
		minHeight: '90%',
		width: '100%',
	},
	layer1: {
		marginTop: 20,
		position: 'relative',
	},
	layer2: {
		top: 10,
		width: 'calc(100% - 20px)',
	},
	layer3: {
		top: 0,
		width: 'calc(100% - 40px)',
	},
	queueContainer: {
		display: 'flex',
		justifyContent: 'center',
		maxWidth: 1400,
		paddingBottom: 20,
		position: 'relative',
		width: '95%',
	},
	sunShade: {
		alignItems: 'center',
		background: brandPrimary,
		borderRadius: 10,
		color: white,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		justifyContent: 'center',
		left: 0,
		top: 0,
		width: '100%',
		zIndex: 9999999999,
	},
	transparentLayer: {
		left: '50%',
		opacity: 0.4,
		position: 'absolute',
		transform: 'translate(-50%, 0)',
	},
});
