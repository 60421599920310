import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	dropdown: {},
	dropdownAnchor: {
		display: 'flex',
	},
	dropdownContent: {
		background: '#FFFFFF',
		border: '1px solid #F3F3F3',
		borderRadius: '3px',
		boxShadow: '0 0 6px 0 rgba(139,139,139,0.10)',
		boxSizing: 'border-box',
		minWidth: 441,
	},
	loading: {
		padding: 10,
	},
});
