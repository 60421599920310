import { RoundAddIcon } from '../../../../../web/components/svgs/icons/RoundAddIcon';
import { RoundRemoveIcon } from '../../../../../web/components/svgs/icons/RoundRemoveIcon';
import { brandPrimaryLight, grayIconFill } from '../../../../styles/colors';
import { TransparentButton } from '../../../TransparentButton';
import { Group } from '../../../leadView/components/Group';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

export interface IRepeaterItem<T> {
	content: T;
	uuid: string;
}

interface IProps<T> {
	children: (
		item: IRepeaterItem<T>,
		onChange: (content: T) => any,
		index: number,
		items: IRepeaterItem<T>[]
	) => JSX.Element;
	contentToAdd?: T;
	disabled?: boolean;
	initialValues: T[];
	onChange: (arg: any) => void;
}

// @ts-ignore
export function Repeater<T>({ children, contentToAdd = null, disabled, initialValues, onChange }: IProps<T>) {
	// @ts-ignore
	const [items, setItems] = useState<IRepeaterItem<T>[]>(null);

	const generateEmptyItem = (): IRepeaterItem<T> => ({
		content: contentToAdd,
		uuid: uuid(),
	});

	useEffect(() => {
		const initialValuesAreEmpty = !initialValues || initialValues?.length <= 0;
		const itemsAreEmpty = !items || items?.length <= 0;
		const initialValuesAreNotEmpty = initialValues?.length > 0;
		const itemsAreNotValid = items?.length < 1 || !items || !items?.[0]?.content;

		if (initialValuesAreEmpty && itemsAreEmpty) {
			setItems([generateEmptyItem()]);
		} else {
			if (initialValuesAreNotEmpty && itemsAreNotValid) {
				setItems(
					initialValues?.map(content => ({
						content,
						uuid: uuid(),
					}))
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	const updateAll = (values: IRepeaterItem<T>[]) => {
		setItems(values);
		onChange(values);
	};

	const handleAdd = () => {
		const newValues = [...items];
		newValues.push(generateEmptyItem());
		updateAll(newValues);
	};

	const handleRemove = (item: IRepeaterItem<T>) => {
		const newValues = [...items].filter(i => i.uuid !== item.uuid);
		updateAll(newValues);
	};

	const handleChange = (contentUuid: string, content: T) => {
		const newValues = [...items].map(cItem => {
			if (cItem.uuid === contentUuid) {
				cItem.content = content;
			}
			return cItem;
		});
		updateAll(newValues);
	};

	return (
		<Group vertical={true}>
			{items?.map((item, index) => {
				const handleClickToRemove = () => handleRemove(item);
				const renderChildren = children(item, content => handleChange(item.uuid, content), index, items);
				return (
					<div key={item.uuid} className={css(styleSheet.itemContainer)}>
						<div className={css(styleSheet.item)}>{renderChildren}</div>
						<div className={css(styleSheet.actions)}>
							<Group fullWidth={false}>
								{items?.length > 1 && (
									<TransparentButton
										// @ts-ignore
										className={disabled && css(styleSheet.disabled)}
										onClick={handleClickToRemove}
										disabled={disabled}
									>
										<RoundRemoveIcon fillColor={grayIconFill} />
									</TransparentButton>
								)}
								{index === items?.length - 1 && (
									<TransparentButton
										// @ts-ignore
										className={disabled && css(styleSheet.disabled)}
										onClick={handleAdd}
										disabled={disabled}
									>
										<RoundAddIcon fillColor={brandPrimaryLight} />
									</TransparentButton>
								)}
							</Group>
						</div>
					</div>
				);
			})}
		</Group>
	);
}
