import { styleSheet } from './styles';
import { css } from 'aphrodite';

export interface IColumn {
	label: string;
	width: number;
	hide?: boolean;
}

export interface ILeadViewTableHeaderProps {
	columns: IColumn[];
}

export function LeadViewTableHeader(props: ILeadViewTableHeaderProps) {
	const { columns = [] } = props;
	return (
		<div className={`${css(styleSheet.container)} lead-view-table-header`}>
			{columns.map(column => {
				if (column.hide) {
					return null;
				}
				return (
					<div key={column.label} className={css(styleSheet.column)} style={{ width: column.width }}>
						{column.label}
					</div>
				);
			})}
		</div>
	);
}
