import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { useDealModal } from '../../contexts/dealModal';
import { useQueueLeadNote } from '../../contexts/queueLeadNote';
import { useQueue } from '../../hooks/queue';
import { SalesActivity } from '../../viewModels/leads/interfaces';
import { LeadViewModel } from '../../viewModels/leads/lead';
import { styleSheet } from './styles';

interface IProps {
	lead: LeadViewModel;
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const MeetingConfirmationDisplay: React.FC<IProps> = observer(props => {
	const { lead, className, styles = [] } = props;
	const queue = useQueue();
	const dealModal = useDealModal();
	const noteSlider = useQueueLeadNote();
	if (!lead || !lead?.nextMeeting) {
		return null;
	}

	const onClick = () => {
		const lastDeal = queue.lead?.lastDeal;
		if (lastDeal) {
			// @ts-ignore
			queue.lead?.loadDealForm(lastDeal.id);
			dealModal.setShowDealModal(true);
			noteSlider.setShowQueueLeadNote(false);
		}
	};

	const prompt =
		lead.requiredActivity === SalesActivity.MeetingConfirmation
			? 'demo scheduled, please call to confirm'
			: 'next meeting scheduled';

	const datetime = lead.nextMeetingFormattedString || moment(lead.nextMeeting.startDate).format('ddd, M/DD/YY @ h:mmA');

	return (
		<div
			className={`${css(styleSheet.container, ...styles)} meeting-confirmation-display ${className || ''}`}
			onClick={onClick}
		>
			<div className={css(styleSheet.prompt)}>{prompt}</div>
			<div className={css(styleSheet.time)}>{datetime + ' with ' + lead.nextMeetingParticipant}</div>
		</div>
	);
});
