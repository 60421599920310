import { getPartialFormattedPhoneNumber } from '../../../models/UiUtils';
import { useQueueLeadNote } from '../../contexts/queueLeadNote';
import { useTelephony } from '../../contexts/telephony';
import { useQueue } from '../../hooks/queue';
import { NoneFound } from '../NoneFound';
import { CallButton } from '../TwilioDialer/CallButton';
import { styleSheet } from './styles';
import { IPhoneNumber } from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	className?: string;
	phoneNumbers: IPhoneNumber[];
}

const CallActionsBase: React.FC<IProps> = ({ className = '', phoneNumbers = [] }) => {
	const queue = useQueue();
	const { setShowQueueLeadNote } = useQueueLeadNote();

	const { isPendingOutcome } = useTelephony();

	const onAddOutcome = () => {
		setShowQueueLeadNote(true);
	};

	if (!phoneNumbers?.length) {
		return <NoneFound text='No numbers found.' />;
	}

	return (
		<div className={`${css(styleSheet.callActionsContainer)} ${className}`}>
			{phoneNumbers.map(p => {
				if (!p?.metadata) {
					return null;
				}

				return (
					<div key={p.metadata?.e164} className={css(styleSheet.callActionContainer)}>
						<div className={css(styleSheet.left)}>
							<div className={css(styleSheet.triggerContainer)}>
								<span className={css(styleSheet.label)}>{p.label}</span>
								{/* @ts-ignore */}
								<span>{getPartialFormattedPhoneNumber(p.metadata?.standard)}</span>
							</div>
						</div>
						<div className={css(styleSheet.right)}>
							{isPendingOutcome ? (
								<button
									className={css(styleSheet.callCta, styleSheet.disabled)}
									onClick={onAddOutcome}
									key='logLastCallOutcome'
								>
									<span>Log last call outcome</span>
								</button>
							) : (
								<CallButton phoneNumber={p} companyId={queue.lead.company.id} key='callButton' />
							)}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export const CallActions = observer(CallActionsBase);
