import * as Api from '@ViewModels';
import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { LeadRuleViewModel } from '../../viewModels/leadrules';

const BLITZES_QUERY_KEY = 'blitzes';

export function useBlitzes({
	enabled = true,
	onError,
	onlyForUser = false,
}: {
	enabled?: boolean;
	onError?: (err: Api.IOperationResultNoValue) => void;
	onlyForUser?: any;
}) {
	const userSession = useUserSession();
	return useQuery({
		enabled,
		onError,
		queryFn: async () => {
			const response = await userSession.webServiceHelper.callWebServiceAsync<{ values: LeadRuleViewModel[] }>(
				Api.ImpersonationBroker.composeApiUrl({ queryParams: { onlyForUser }, urlPath: 'aida/rule' }),
				'GET'
			);
			return response.value;
		},
		queryKey: [BLITZES_QUERY_KEY, onlyForUser],
	});
}
