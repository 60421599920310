import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		background: '#002138',
		height: '100%',
		overflow: 'auto',
		position: 'relative',
		width: '100%',
	},
	handle: {},
	machineContainer: {
		marginLeft: 'auto',
		marginRight: 'auto',
		position: 'relative',
	},
	machinePart: {
		position: 'absolute',
	},
	reel: {
		left: 0,
		perspective: 1100,
		top: 0,
		transformStyle: 'preserve-3d',
	},
	reelItem: {
		alignItems: 'center',
		backfaceVisibility: 'hidden',
		display: 'inline-flex',
		justifyContent: 'center',
		left: 0,
		overflow: 'hidden',
	},
	reels: {
		display: 'flex',
		overflow: 'hidden',
		perspective: 1100,
		transform: 'rotateY(0deg)',
		transformStyle: 'preserve-3d',
	},
	symbol: {},
});
