import Icon from '../../assets/slot-machine-achievement-icon.png';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	action?: string;
	description?: string;
	indicator?: string | number;
	onClick: () => void;
	name?: string;
}

export const AchievementToast: React.FC<IProps> = ({ action, description, indicator, onClick, name }) => {
	return (
		<div role='button' onClick={onClick} className={css(styleSheet.achievementToast)}>
			<div className={css(styleSheet.achievementToastIcon)}>
				<img src={Icon} />
			</div>
			<div className={css(styleSheet.achievementToastContent)}>
				<div className={css(styleSheet.achievementToastDescription)}>{description}</div>
				<div className={css(styleSheet.achievementToastInfoGroup)}>
					{indicator && <span className={css(styleSheet.achievementToastCount)}>{indicator}</span>}
					<span className={css(styleSheet.achievementToastName)}>{name}</span>
					{action && <span>{action}</span>}
				</div>
			</div>
		</div>
	);
};
