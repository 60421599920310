import { getDisplayName } from '../../../../../extViewmodels/Utils';
import { styleSheet } from '../styles';
import { ViewModelTypeAutocomplete } from './ViewModelTypeAutocomplete';
import { IUser, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import { useEffect, useState } from 'react';
import * as React from 'react';

interface IProps {
	defaultSelected: IUser;
	onChange: (user: IUser) => void;
}

export const SearchField: React.FC<IProps> = ({ defaultSelected, onChange }) => {
	const [selected, setSelected] = useState(null);
	useEffect(() => {
		// @ts-ignore
		setSelected(defaultSelected);
	}, [defaultSelected]);
	const handleSelection = (user: IUser) => {
		onChange(user);
	};
	const handleSelected = (selectedUser: IUser) => getDisplayName(selectedUser);
	return (
		<div className={css(styleSheet.searchBar)}>
			<ViewModelTypeAutocomplete<IUser>
				placeholder='Search for an user to see associated phone numbers'
				model={ResourceAutoCompleteViewModelType.User}
				getSelected={handleSelected}
				onChange={handleSelection}
				// @ts-ignore
				defaultSelected={selected}
			/>
		</div>
	);
};
