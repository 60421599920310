import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number) => {
	const savedCallback = useRef(null);
	useEffect(() => {
		// @ts-ignore
		savedCallback.current = callback;
	});
	useEffect(() => {
		// @ts-ignore
		const tick = () => savedCallback.current();
		if (delay !== null) {
			const interval = setInterval(tick, delay);
			return () => clearInterval(interval);
		}
	}, [delay]);
};
