import { ISelectOption, Select } from '../../../../web/components/Select';
import { MoreIcon } from '../../../../web/components/svgs/icons/MoreIcon';
import { styleSheet } from '../styles';
import * as React from 'react';

interface IProps {
	onClickToClone(): void;
	onClickToDelete(): void;
	onClickToEdit(): void;
	onClickToEditSchedule(): void;
}

enum MoreOptions {
	Clone = 'clone',
	Delete = 'delete',
	Edit = 'edit',
	EditSchedule = 'editSchedule',
}

export const RuleOptions: React.FC<IProps> = ({
	onClickToClone,
	onClickToDelete,
	onClickToEdit,
	onClickToEditSchedule,
}) => {
	const options: ISelectOption<MoreOptions>[] = [
		{
			dataContext: MoreOptions.EditSchedule,
			id: 'lead-rule-more-options-edit-schedule',
			text: 'Edit Schedule',
			type: 'blue',
		},
		// @ts-ignore
		// @ts-ignore
		onClickToEdit
			? {
					dataContext: MoreOptions.Edit,
					id: 'lead-rule-more-options-edit',
					text: 'Edit',
					type: 'blue',
				}
			: null,
		// @ts-ignore
		// @ts-ignore
		onClickToClone
			? {
					dataContext: MoreOptions.Clone,
					id: 'lead-rule-more-options-clone',
					text: 'Clone',
					type: 'blue',
				}
			: null,
		// @ts-ignore
		// @ts-ignore
		onClickToDelete
			? {
					dataContext: MoreOptions.Delete,
					id: 'lead-rule-more-options-delete',
					text: 'Delete',
					type: 'blue',
				}
			: null,
	];

	const handleSelectChange = (option: ISelectOption<MoreOptions>) => {
		switch (option.dataContext) {
			case MoreOptions.Edit:
				onClickToEdit();
				break;
			case MoreOptions.EditSchedule:
				onClickToEditSchedule();
				break;
			case MoreOptions.Clone:
				onClickToClone();
				break;
			case MoreOptions.Delete:
				onClickToDelete();
				break;
			default:
				break;
		}
	};

	return (
		<Select
			onOptionClick={handleSelectChange}
			options={options}
			selectedOptionTitle={<MoreIcon fillColor='' />}
			showCaret={false}
			styles={[styleSheet.moreMenu]}
			triggerStyles={[styleSheet.moreMenuTrigger]}
		/>
	);
};
