import { white } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		color: white,
		margin: '0 auto 20px',
		maxWidth: '1100px',
		position: 'relative',
		width: '80vw',
	},
	containerMobile: {
		paddingLeft: 20,
		paddingRight: 20,
		width: '100vw',
	},
});
