import { CssSelectors, FontFamilies } from '../../../web/styles/styles';
import {
	black,
	brandPrimary,
	brandPrimaryHover,
	brandPrimaryLight,
	grayDark,
	lightOrange,
	white,
} from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { background, borderColor, lightYellow } from './../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	// Card
	callActionContainer: {
		':not(:last-child)': {
			marginBottom: 10,
		},
		...aidaBaseStyles.flexHorizontalCenter,
		background: lightOrange,
		borderRadius: 6,
		padding: 20,
	},
	// Comments
	commentItem: {},
	commentItemContent: {},
	commentItemContentName: {
		fontFamily: FontFamilies.bold,
		fontSize: 14,
	},
	commentItemContentNote: {
		color: grayDark,
		fontSize: 14,
		marginTop: 5,
	},
	commentItemMedia: {
		alignItems: 'center',
		borderRadius: '100%',
		display: 'flex',
		flexShrink: 0,
		height: 44,
		justifyContent: 'center',
		overflow: 'hidden',
		width: 44,
		...CssSelectors.allDescendants(
			{
				height: '100%',
			},
			'img'
		),
	},
	commentItemTime: {
		color: grayDark,
		fontFamily: FontFamilies.bold,
		fontSize: 14,
	},
	commentsContainer: {
		borderRadius: 8,
		marginTop: 10,
		paddingBottom: 10,
		paddingTop: 10,
	},
	commentsFooter: {
		background,
		borderRadius: 8,
		marginTop: 20,
		padding: 20,
		width: '100%',
	},
	commentsInput: {
		background: white,
		borderRadius: 8,
		height: 40,
	},
	commentsInputSendButton: {
		...CssSelectors.allDescendants(
			{
				marginTop: 4,
			},
			'svg'
		),
	},
	commentsWrapper: {
		...CssSelectors.allDescendants(
			{
				listStyleType: 'none',
				...CssSelectors.allDescendants(
					{
						borderBottom: `1px solid ${borderColor}`,
						marginBottom: 20,
						paddingBottom: 20,
					},
					'li'
				),
				...CssSelectors.allDescendants(
					{
						borderBottom: 'none',
						marginBottom: 0,
						paddingBottom: 0,
					},
					'li:last-child'
				),
			},
			'ul'
		),
	},
	container: {
		...aidaBaseStyles.cardShadow,
		background: white,
		cursor: 'auto',
		height: '100vh',
		position: 'fixed',
		top: 0,
		width: 500,
		zIndex: 10,
	},
	containerMobile: {
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		marginLeft: 5,
		marginRight: 5,
		width: 'calc(100% - 10px)',
	},
	content: {
		height: 'calc(100% - 90px)',
		overflowY: 'scroll',
		padding: 30,
		paddingBottom: 20,
		position: 'relative',
		width: '100%',
	},
	contentMobile: {
		padding: 15,
	},
	copyIcon: {
		marginBottom: -2,
		marginLeft: 5,
	},
	emptyContent: {},
	// Header
	header: {
		...aidaBaseStyles.flexHorizontalCenter,
		background: brandPrimaryHover,
		justifyContent: 'space-between',
		padding: '16px 80px 14px 30px',
		position: 'relative',
	},
	headerClose: {
		position: 'absolute',
		right: 15,
		top: 15,
		...CssSelectors.allChildren(
			{
				height: 15,
				width: 15,
			},
			'svg'
		),
	},
	headerMain: {
		...aidaBaseStyles.flexHorizontalCenter,
	},
	headerMobile: {
		padding: '5px 60px 5px 15px',
	},
	headerTitle: {
		color: white,
		fontFamily: FontFamilies.conqueror,
		fontSize: 40,
		marginTop: 6,
	},
	headerTitleMobile: {
		fontSize: 28,
	},
	label: {
		color: grayDark,
		fontSize: '12px',
		textTransform: 'capitalize',
	},
	left: {
		minWidth: '50%',
	},
	regularButton: {
		alignItems: 'center',
		color: brandPrimaryLight,
		display: 'flex',
		fontSize: 14,
		...CssSelectors.allDescendants(
			{
				marginRight: 5,
				marginTop: 5,
			},
			'svg'
		),
	},
	right: {
		minWidth: '50%',
		...CssSelectors.allDescendants(
			{
				color: grayDark,
			},
			'.outcome-info-title'
		),
	},
	// Section
	sectionTitle: {
		color: grayDark,
		fontSize: 14,
	},
	sectionWrapper: {},
	title: {
		color: brandPrimary,
		fontSize: 28,
		marginBottom: 20,
		marginTop: -10,
	},
	titleCopyButton: {
		color: brandPrimaryLight,
		position: 'absolute',
		right: 10,
		top: 8,
	},
	titleIcon: {
		height: 30,
		position: 'relative',
		top: -15,
		width: 32,
	},
	transcriptionContainer: {
		marginTop: 10,
	},
	transcriptionContent: {
		background: lightYellow,
		fontSize: 14,
		padding: '15px 20px 5px 20px',
	},
	transcriptionContentFooter: {
		paddingBottom: 10,
	},
	transcriptionContentLine: {
		color: grayDark,
		marginBottom: 10,
	},
	transcriptionContentSpeaker: {
		color: black,
	},
	triggerContainer: {
		...aidaBaseStyles.flexCol,
		background: 'none',
		color: black,
		...CssSelectors.allChildren(
			{
				fontSize: '16px',
				paddingTop: 4,
			},
			':last-child'
		),
	},
});
