import { CssSelectors, FontFamilies } from '../../../web/styles/styles';
import { brandSecondary, white } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	points: {
		font: FontFamilies.bold,
		fontSize: '24px',
		paddingRight: 5,
	},
	pointsBubbleContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		background: brandSecondary,
		borderRadius: 30,
		justifyContent: 'center',
		padding: '3px 20px',
		...CssSelectors.allChildren(
			{
				color: white,
				textTransform: 'uppercase',
			},
			'span'
		),
	},
});
