import { OpenNewWindowIcon } from '../../../../web/components/svgs/icons/OpenNewWindowIcon';
import { useCompany } from '../../../entities/Company/useCompany';
import { getBoolFromStatus } from '../../../entities/utils/getBoolFromStatus';
import { CopyButton } from '../../../hooks/useCopyToClipboard/CopyButton';
import { brandPrimaryLight } from '../../../styles/colors';
import { LeadServedSource } from '../../../viewModels/queue';
import { TransparentButton } from '../../TransparentButton';
import { Group } from '../../leadView/components/Group';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import { useEffect } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router';

interface IProps {
	companyId: string;
	isMobile: boolean;
}

export const CallDetailsTitle: React.FC<IProps> = ({ companyId, isMobile }) => {
	const history = useHistory();

	const { data, empty, get, status } = useCompany();
	const { isEmpty, isWorking } = getBoolFromStatus({ empty, status });

	useEffect(() => {
		if (companyId && companyId !== 'string') {
			get(companyId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyId]);

	const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
		event.preventDefault();
		event.stopPropagation();
		if (!(event.ctrlKey || event.metaKey)) {
			history.push(`/queue?from=${LeadServedSource.CallLog}&companyId=${companyId}`);
			return;
		}
		const { origin, pathname } = location;
		const url = `${origin + pathname}#/queue?from=${LeadServedSource.CallLog}&companyId=${companyId}`;
		window.open(url, '_blank');
	};

	const nameNotFound = 'Unknown';
	const gettingName = 'Loading...';

	return (
		<Group>
			<h1 className={css(styleSheet.title)}>{isWorking ? gettingName : isEmpty ? nameNotFound : data?.companyName}</h1>
			{!isWorking && !isEmpty && !isMobile && (
				<TransparentButton onClick={handleClick}>
					<OpenNewWindowIcon className={css(styleSheet.titleIcon)} fill={brandPrimaryLight} />
				</TransparentButton>
			)}
			{!isMobile && (
				<div className={css(styleSheet.titleCopyButton)}>
					<CopyButton label='Copy to clipboard' message={{ success: 'Link copied to clipboard' }} />
				</div>
			)}
		</Group>
	);
};
