import { StyleSheet } from 'aphrodite';
import { brandPrimary } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	mrryCloseButton: {
		...aidaBaseStyles.flexCenter,
		background: brandPrimary,
		borderRadius: '50%',
		height: 22,
		paddingBottom: 3,
		paddingRight: 3,
		width: 22,
	},
});
