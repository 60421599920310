import { brandSecondary } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	google: {
		':hover': {
			textDecoration: 'underline',
		},
		color: brandSecondary,
		cursor: 'pointer',
		fontSize: '12px',
		position: 'relative',
	},
	locationIcon: {
		left: 'calc(100% - 1px)',
		position: 'absolute',
		top: 1,
	},
});
