import { StyleSheet } from 'aphrodite';
import bg from '../../assets/bg.svg';
import { statusBannerHeight } from '../../components/StatusBanner/styles';
import { titles, warningDark } from '../../styles/colors';
import { headerHeight } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	bodyContainer: {
		overflow: 'auto',
	},
	container: {
		background: `url(${bg}) no-repeat`,
		height: '100vh',
	},
	impersonating: {
		alignItems: 'center',
		background: warningDark,
		color: titles,
		display: 'flex',
		justifyContent: 'center',
		padding: 0,
		position: 'absolute',
		top: 105,
		width: '100vw',
		zIndex: 1000,
	},
	mobileHeader: {
		height: `calc(100% - ${headerHeight}px)`,
		marginTop: 60,
	},
	noCoffee: {
		background: warningDark,
		color: titles,
		padding: 20,
		position: 'absolute',
		top: 105,
		width: '100vw',
		zIndex: 1000,
	},
	shortHeader: {
		height: `calc(100% - ${headerHeight}px)`,
		marginTop: headerHeight,
	},
	tallHeader: {
		height: `calc(100% - ${headerHeight}px - ${statusBannerHeight}px)`,
		marginTop: headerHeight + statusBannerHeight,
	},
});
