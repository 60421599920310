import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import { brandPrimaryHover } from '../../../../styles/colors';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const PlayIcon: React.FC<IProps> = ({ className = '', fillColor = brandPrimaryHover }) => {
	return (
		<SvgIcon className={`play-icon ${className}`} height={24} width={21}>
			<path
				d='M18.577 11.14a1 1 0 0 1 0 1.72L2.508 22.357A1 1 0 0 1 1 21.496V2.504a1 1 0 0 1 1.508-.861l16.069 9.495Z'
				fill={fillColor}
				stroke={fillColor}
				strokeWidth='2'
			/>
		</SvgIcon>
	);
};
