import { TrashIcon } from '../../../../web/components/svgs/icons/TrashIcon';
import { gray, grayIconFill } from '../../../styles/colors';
import { TransparentButton } from '../../TransparentButton';
import { Fill } from '../../leadView/components/Fill';
import { Group } from '../../leadView/components/Group';
import { DraggableIcon } from '../../svgs/icons/audio/DraggableIcon';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import { SyntheticEvent } from 'react';
import * as React from 'react';

interface IProps {
	canRemove?: boolean;
	isDraggable?: boolean;
	onClickToRemove?: (event: SyntheticEvent) => void;
	renderLeft: JSX.Element;
	renderRight?: JSX.Element;
}

export const PropertyItemRow: React.FC<IProps> = ({
	canRemove = true,
	isDraggable,
	onClickToRemove,
	renderLeft,
	renderRight,
}) => {
	return (
		<Group className={css(styleSheet.propertyItemRow)} spacingSize={15} vCentered={true}>
			{isDraggable && <DraggableIcon fill={gray} />}
			<Fill>{renderLeft}</Fill>
			{renderRight}
			{canRemove && (
				<TransparentButton className={css(styleSheet.removeButton)} onClick={onClickToRemove}>
					<TrashIcon fillColor={grayIconFill} />
				</TransparentButton>
			)}
		</Group>
	);
};
