import { ISelectOption, Select } from '../../../../web/components/Select';
import { IRepeaterItem, Repeater } from '../../ContactInfo/components/Repeater';
import { Group } from '../../leadView/components/Group';
import { styleSheet } from '../styles';
import { generateTimeOptions } from '../utils';
import * as React from 'react';

interface IRange {
	start: ISelectOption<number>;
	end: ISelectOption<number>;
}

interface IProps {
	disabled: boolean;
	onChange: (values: IRange[]) => void;
	values: IRange[];
}

enum TimeRangeKey {
	End = 'end',
	Start = 'start',
}

export const TimeRangeRepeater: React.FC<IProps> = React.memo(function TimeRangeRepeaterBase({
	disabled,
	onChange,
	values,
}) {
	const handleRepeaterChange = (newValues: IRepeaterItem<IRange>[]) => {
		const sorted = newValues
			?.map(i => i.content)
			?.sort((a, b) => {
				if (a.start.dataContext < b.start.dataContext) {
					return -1;
				}
				if (a.start.dataContext > b.start.dataContext) {
					return 1;
				}
				return 0;
			});
		onChange(sorted);
	};
	const options: ISelectOption<number>[] = [...generateTimeOptions()];
	return (
		<Repeater<IRange>
			disabled={disabled}
			initialValues={values}
			onChange={handleRepeaterChange}
			contentToAdd={{
				end: options[17],
				start: options[8],
			}}
		>
			{(item, handleContentChange) => {
				const handleTimeChange = (key: TimeRangeKey) => (option: ISelectOption<number>) => {
					const newTimeRange = { ...item?.content, [key]: option };
					handleContentChange(newTimeRange);
				};
				const content = item?.content;
				const endOptions = [...options]?.filter(i => i.dataContext > content?.start?.dataContext);
				const startOptions = [...options]?.filter(i => i.dataContext < content?.end?.dataContext);
				return (
					<Group vCentered={true}>
						<Select
							// @ts-ignore
							styles={[styleSheet.timeSelectCondensed, disabled ? styleSheet.disabled : null]}
							onOptionClick={handleTimeChange(TimeRangeKey.Start)}
							options={startOptions?.length <= 0 ? options : startOptions}
							disabled={disabled}
							selectedOption={content?.start}
						/>
						<small>to</small>
						<Select
							// @ts-ignore
							styles={[styleSheet.timeSelectCondensed, disabled ? styleSheet.disabled : null]}
							onOptionClick={handleTimeChange(TimeRangeKey.End)}
							options={endOptions?.length <= 0 ? options : endOptions}
							disabled={disabled}
							selectedOption={content?.end}
						/>
					</Group>
				);
			}}
		</Repeater>
	);
});
