import * as React from 'react';

export const useInput = (initialInputValue: string) => {
	const [value, setValue] = React.useState(initialInputValue);
	const inputChange = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[value, setValue]
	);
	return [value, setValue, inputChange] as const;
};
