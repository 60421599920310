import { Noop } from '../../extViewmodels/Utils';
import { useModal } from '../../models/hooks/useModal';
import { AidaAudioPlayer } from '../components/AudioPlayer';
import { observer } from 'mobx-react';
import { useContext, Dispatch, FC, SetStateAction, createContext, useState } from 'react';

export interface IAudioPlayerContext {
	audioSrc: string;
	setAudioSrc: Dispatch<SetStateAction<string>>;
	title: string;
	setTitle: Dispatch<SetStateAction<string>>;
	subtitle: string;
	setSubtitle: Dispatch<SetStateAction<string>>;
	phoneCallId: string;
	setPhoneCallId: Dispatch<SetStateAction<string>>;
	setIsOpen: (newBool: boolean) => () => void;
}

// @ts-ignore
export const AudioPlayerContext = createContext<IAudioPlayerContext>(null);

export const AudioPlayerProvider: FC = observer(({ children }) => {
	const [audioSrc, setAudioSrc] = useState('');
	const [title, setTitle] = useState('');
	const [subtitle, setSubtitle] = useState('');
	const [phoneCallId, setPhoneCallId] = useState(undefined);
	const audioModal = useModal(false, Noop, [], false, true);

	return (
		<AudioPlayerContext.Provider
			value={{
				audioSrc,
				// @ts-ignore
				phoneCallId,
				setAudioSrc,
				setIsOpen: audioModal.setIsOpen,
				// @ts-ignore
				setPhoneCallId,
				setSubtitle,
				setTitle,
				subtitle,
				title,
			}}
		>
			{children}
			<AidaAudioPlayer {...audioModal} />
		</AudioPlayerContext.Provider>
	);
});

export const useAudio = () => useContext<IAudioPlayerContext>(AudioPlayerContext);
