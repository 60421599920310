import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import {
	ContactSearchListItem,
	IContactSearchListItemProps,
} from '../../../../web/components/contacts/ContactSearchListItem';
import { AutoCompleteDropdown } from '../AutoCompleteDropdown';
import {
	AutoCompleteResultFilter,
	ResourceAutoCompleteViewModel,
	ResourceAutoCompleteViewModelType,
} from '../autocomplete';
import { styleSheet } from './styles';
import { IContact, IEntity, IHandleAutoCompleteResult, IUser } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import shallowEqual from 'shallowequal';

export enum AutoCompleteSearchFieldAccessoryViewMode {
	Always = 0,
	WhileEditing,
	Never,
}

export interface IAutoCompleteSearchFieldComponent {
	clearInput(): void;
	setSearchQuery(searchQuery?: string): void;
}

interface IProps<T = any> extends IUserSessionComponentProps, IImpersonationContextComponentProps {
	anchorClassName?: string;
	autoCompleteFilter?: AutoCompleteResultFilter<T>;
	className?: string;
	clearSearchFieldAfterSelectingItem?: boolean;
	contentPositionY?: 'top' | 'bottom';
	/** Setting this to true makes the input act like a normal text input field */
	disableAutocomplete?: boolean;
	dropdownClassName?: string;
	dropdownContentClassName?: string;
	dropdownContentItemsStyles?: StyleDeclarationValue[];
	dropdownItemClassName?: string;
	hideResultsFooter?: boolean;
	initialSearchQuery?: string;
	onCreateAutoCompleteViewModel?(
		type: ResourceAutoCompleteViewModelType,
		suggestedViewModel: ResourceAutoCompleteViewModel,
		autoCompleteFilter?: AutoCompleteResultFilter<any>
	): ResourceAutoCompleteViewModel;
	onInnerRef?(ref: IAutoCompleteSearchFieldComponent): void;
	inputId: string;
	inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

	/** Element to display to the left of the inner input field */
	leftAccessory?: React.ReactNode;

	onInputRef?: React.Ref<HTMLInputElement>;

	/**
	 * @param item Selected dropdown item (either from enter on the keyboard or an explict mouse click)
	 * @param type ResourceAutoCompleteViewModelType
	 */
	onItemSelected?(item: IEntity | string | IUser, type: ResourceAutoCompleteViewModelType): void;

	onOpenChanged?(isOpen?: boolean): void;

	onRenderAnchorContent?(
		inputElement: React.ReactElement<any>,
		inputHasFocus: boolean,
		leftAccessory?: React.ReactNode,
		rightAccessory?: React.ReactNode
	): React.ReactNode;

	/**
	 * You can pass back a node to render at the bottom of the autocomplete list, or let the control render the default
	 * element.
	 *
	 * @param overflowItems List of items that the control did not render
	 */
	onRenderLimitedResults?(renderedItems: any[], overflowItems: any[], totalCount: number): React.ReactNode;

	onRenderResultsFooter?(
		searchQuery: string,
		renderedItems: any[],
		totalCount: number,
		overflowItems?: any[]
	): React.ReactNode;

	pageSize?: number;

	/** Placeholder text */
	placeholder?: string;
	preventImpersonation?: boolean;
	renderNoResultsItem?: boolean;

	resultsLimit?: number;

	retainFocusAfterSelectingItem?: boolean;

	/** Element to display to the left of the inner input field */
	rightAccessory?: React.ReactNode;

	/** By default this is AutoCompleteSearchFieldAccessoryViewMode.Always */
	rightAccessoryViewMode?: AutoCompleteSearchFieldAccessoryViewMode;

	/** Type of autocomplete service to provide */
	type: ResourceAutoCompleteViewModelType;
	leadId: string;
	fieldId: string;
	dealType: 'CreateDeal' | 'UpdateDeal';
}

interface IState<T = any> {
	autoCompleteFilter?: AutoCompleteResultFilter<T>;
	autoCompleteViewModel?: ResourceAutoCompleteViewModel<any>;
	disableAutocomplete?: boolean;
	hideResultsFooter?: boolean;
	inputHasFocus?: boolean;
	inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
	searchQuery?: string;
	type?: ResourceAutoCompleteViewModelType;
}

class _AutoCompleteSearchField extends React.Component<IProps, IState> implements IAutoCompleteSearchFieldComponent {
	public static defaultProps: Partial<IProps> = {
		clearSearchFieldAfterSelectingItem: false,
		renderNoResultsItem: true,
		rightAccessoryViewMode: AutoCompleteSearchFieldAccessoryViewMode.Always,
	};
	public state: IState = {};
	// @ts-ignore
	private dropdownElement: typeof AutoCompleteDropdown;
	constructor(props: IProps) {
		super(props);
	}

	public UNSAFE_componentWillMount() {
		const nextState = this.getNextStateWithProps(this.props);
		this.setState({
			...nextState,
			searchQuery: this.props.initialSearchQuery,
		});

		if (this.props.onInnerRef) {
			this.props.onInnerRef(this);
		}
	}

	public componentWillUnmount() {
		if (this.props.onInnerRef) {
			// @ts-ignore
			this.props.onInnerRef(null);
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		const nextState = this.getNextStateWithProps(nextProps);
		if (nextState) {
			this.setState(nextState);
		}
	}

	public render() {
		const {
			className = '',
			onInputRef,
			onOpenChanged,
			resultsLimit,
			initialSearchQuery,
			placeholder,
			dropdownClassName,
			inputId,
			dropdownContentItemsStyles,
			contentPositionY,
		} = this.props;
		const { autoCompleteViewModel, inputProps, disableAutocomplete, searchQuery } = this.state;
		if (!autoCompleteViewModel) {
			return null;
		}

		// hook into onChange to capture search query changes
		const computedInputProps: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> = {
			...(inputProps || {}),
			autoComplete: 'off',
			name: (inputProps || {}).name || inputId,
			onBlur: this.onInputFocusChanged(false, inputProps ? inputProps.onBlur : undefined),
			// @ts-ignore
			onChange: this.onTextInputChanged(inputProps ? inputProps.onChange : undefined),
			onFocus: this.onInputFocusChanged(true, inputProps ? inputProps.onFocus : undefined),
			// @ts-ignore
			placeholder: placeholder || (inputProps ? inputProps.placeholder : null),
			type: 'search',
		};
		return (
			<div className={className || undefined}>
				<AutoCompleteDropdown
					anchor={this.anchorProvider}
					autoCompleteViewModel={autoCompleteViewModel}
					className={dropdownClassName}
					contentPositionY={contentPositionY}
					disableAutocomplete={disableAutocomplete}
					dropdownContentClassName={`${css(styleSheet.dropdownContent)} ${this.props.dropdownContentClassName || ''}`}
					dropdownContentItemsStyles={dropdownContentItemsStyles}
					initialSearchQuery={initialSearchQuery}
					inputProps={computedInputProps}
					onInputRef={onInputRef}
					onItemSelected={this.onItemSelected}
					onOpenChanged={onOpenChanged}
					onRenderEmptyResults={this.onRenderEmptyResults}
					// @ts-ignore
					onRenderItem={this.onRenderItem}
					onRenderLimitedResults={this.onRenderLimitedResults}
					onRenderResultsFooter={this.onRenderResultsFooter}
					ref={this.onDropdownElementRef}
					resultsLimit={resultsLimit}
					searchQuery={searchQuery}
				/>
			</div>
		);
	}

	public setSearchQuery = (searchQuery?: string) => {
		this.setState({
			searchQuery,
		});
	};

	public clearInput = () => {
		this.setState({
			// @ts-ignore
			searchQuery: null,
		});
	};

	private onInputFocusChanged =
		(hasFocus: boolean, onFocusChangedCallback?: React.FocusEventHandler<HTMLInputElement>) =>
		(e: React.FocusEvent<HTMLInputElement>) => {
			if (onFocusChangedCallback) {
				onFocusChangedCallback(e);
			}

			this.setState({
				inputHasFocus: hasFocus,
			});
		};

	private onRenderResultsFooter = (
		searchQuery: string,
		renderedItems: IEntity[] | string[] | IUser[],
		totalCount: number,
		overflowItems?: IEntity[] | string[] | IUser[]
	) => {
		const { onRenderResultsFooter } = this.props;
		if (onRenderResultsFooter) {
			const footer = onRenderResultsFooter(searchQuery, renderedItems, totalCount, overflowItems);
			if (footer) {
				return footer;
			}
		}

		const { type, hideResultsFooter } = this.state;
		if (!!hideResultsFooter || type !== 'contact') {
			return null;
		}
	};

	private onRenderLimitedResults = (renderedItems: any[], overflowItems: any[], totalCount: number) => {
		const { onRenderLimitedResults } = this.props;
		if (onRenderLimitedResults) {
			return onRenderLimitedResults(renderedItems, overflowItems, totalCount);
		}

		return null;
	};

	private onRenderEmptyResults = () => {
		if (this.props.renderNoResultsItem) {
			return <div className={css(styleSheet.info)}>No results</div>;
		}

		return null;
	};

	private onDropdownElementRef = (ref: any) => {
		this.dropdownElement = ref;
	};

	private onTextInputChanged =
		(onChange: (e: React.ChangeEvent<HTMLInputElement>) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
			this.setState({
				searchQuery: e.target.value,
			});
			if (onChange) {
				onChange(e);
			}
		};

	private onItemSelected = (item: any) => {
		const { type } = this.state;
		const { retainFocusAfterSelectingItem } = this.props;
		const result: IEntity | string | IUser = item;
		let searchQuery = this.state.searchQuery;
		switch (type) {
			case 'user': {
				const user: IUser = item;
				searchQuery = user.firstName + ' ' + user.lastName;
				break;
			}
			case 'contact': {
				const contact: IContact = item;
				searchQuery = contact.handle;
				break;
			}
			default: {
				break;
			}
		}

		this.setState({
			// @ts-ignore
			searchQuery: this.props.clearSearchFieldAfterSelectingItem ? null : searchQuery,
		});

		if (this.props.onItemSelected) {
			// @ts-ignore
			this.props.onItemSelected(result, type);
		}

		if (!!this.dropdownElement && !retainFocusAfterSelectingItem) {
			requestAnimationFrame(() => {
				if (this.dropdownElement) {
					(this.dropdownElement as any).blur();
				}
			});
		}
	};

	private anchorProvider = (inputElement: React.ReactElement<any>) => {
		const { rightAccessory, leftAccessory, anchorClassName, rightAccessoryViewMode, onRenderAnchorContent } =
			this.props;
		const { inputHasFocus } = this.state;
		const rightNode =
			rightAccessoryViewMode === AutoCompleteSearchFieldAccessoryViewMode.Never
				? null
				: (rightAccessoryViewMode === AutoCompleteSearchFieldAccessoryViewMode.Always ||
						(rightAccessoryViewMode === AutoCompleteSearchFieldAccessoryViewMode.WhileEditing && !!inputHasFocus)) &&
					rightAccessory;
		// @ts-ignore
		const externalContent = onRenderAnchorContent?.(inputElement, inputHasFocus, leftAccessory, rightNode);
		return (
			<div className={`autocomplete-search-field-input ${css(styleSheet.textInput)} ${anchorClassName || ''}`}>
				{externalContent || (
					<>
						{leftAccessory}
						{inputElement}
						{rightNode}
					</>
				)}
			</div>
		);
	};

	private onRenderItem = (
		item: IEntity | string | IHandleAutoCompleteResult,
		index: number,
		highlighted: boolean,
		onClick: (e?: React.MouseEvent<HTMLElement>) => void
	) => {
		const { type } = this.state;
		// @ts-ignore
		let itemContent: JSX.Element | string = null;
		switch (type) {
			case 'contact':
			case 'user': {
				const props: IContactSearchListItemProps = {};
				if (type === 'contact') {
					props.entity = item as IEntity;
				} else {
					props.user = item as IUser;
				}
				itemContent = <ContactSearchListItem {...props} showTitle={true} />;
				break;
			}
			default: {
				break;
			}
		}

		if (itemContent) {
			let key = Object.prototype.hasOwnProperty.call(item, 'id') ? (item as IEntity | IUser).id : '';
			if (!key) {
				const handleResult = item as IHandleAutoCompleteResult;
				const entity = handleResult.contact || handleResult.company;
				if (entity) {
					key = entity.id;
				}
			}
			if (!key) {
				key = `${index}`;
			}
			return (
				<div
					className={`${css(styleSheet.item, highlighted && styleSheet.highlighted)} ${
						this.props.dropdownItemClassName || ''
					}`}
					key={key}
					onMouseDown={onClick}
					tabIndex={0}
				>
					{itemContent}
				</div>
			);
		}
		return null;
	};

	private getNextStateWithProps = (nextProps: IProps) => {
		if (nextProps === this.props || !shallowEqual(nextProps, this.props)) {
			const nextState: IState = {};
			if (nextProps.type !== this.state.type || nextProps.autoCompleteFilter !== this.state.autoCompleteFilter) {
				nextState.type = nextProps.type;
				nextState.autoCompleteFilter = nextProps.autoCompleteFilter;
				nextState.autoCompleteViewModel = this.createAutoCompleteViewModel(
					nextProps.type,
					nextProps.autoCompleteFilter
				);
				if (this.dropdownElement) {
					requestAnimationFrame(() => {
						if (this.dropdownElement) {
							(this.dropdownElement as any).reset();
						}
					});
				}
			}

			if (!shallowEqual(nextProps.inputProps, this.state.inputProps)) {
				nextState.inputProps = nextProps.inputProps;
			}

			if (nextProps.disableAutocomplete !== this.state.disableAutocomplete) {
				nextState.disableAutocomplete = nextProps.disableAutocomplete;
			}

			if (nextProps.hideResultsFooter !== this.state.hideResultsFooter) {
				nextState.hideResultsFooter = nextProps.hideResultsFooter;
			}

			return Object.keys(nextState).length > 0 ? nextState : null;
		}
		return null;
	};

	private createAutoCompleteViewModel = (
		type: ResourceAutoCompleteViewModelType,
		autoCompleteFilter?: AutoCompleteResultFilter<any>
	) => {
		const {
			onCreateAutoCompleteViewModel,
			pageSize,
			impersonationContext,
			leadId,
			fieldId,
			dealType,
			preventImpersonation = false,
		} = this.props;
		let vm: ResourceAutoCompleteViewModel = new ResourceAutoCompleteViewModel(
			// @ts-ignore
			this.props.userSession,
			{
				fieldId,
				filter: autoCompleteFilter,
				inputDelay: 100,
				leadId,
				pageSize,
				type,
			},
			dealType
		);
		if (!preventImpersonation) {
			vm.impersonate(impersonationContext);
		}
		if (onCreateAutoCompleteViewModel) {
			vm = onCreateAutoCompleteViewModel(type, vm, autoCompleteFilter);
		}

		return vm;
	};
}

const AutoCompleteSearchFieldAsObserver = observer(_AutoCompleteSearchField);
export const AutoCompleteSearchField = inject(
	UserSessionViewModelKey,
	ImpersonationContextKey
)(AutoCompleteSearchFieldAsObserver);
