import { CssSelectors } from '../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	callLogContainer: {
		width: '80vw',
	},
	filter: {
		marginBottom: 20,
		...CssSelectors.allDescendants(
			{
				minWidth: 220,
				width: 220,
			},
			'.common-autocomplete-wrapper'
		),
		...CssSelectors.allDescendants(
			{
				minWidth: 220,
				width: 220,
			},
			'.datepicker-select'
		),
	},
	leadCard: {
		marginBottom: 26,
		marginTop: 26,
	},
	noResults: {
		color: '#fff',
		textAlign: 'center',
	},
});
