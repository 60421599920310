import { baseStyleSheet } from '../../../../web/styles/styles';
import { SlotMachineViewModel } from '../../../viewModels/slotMachines';
import BoozeCruiseTitleImageUrl from '../DaisyBoozeCruiseSlotMachine/boozeCruiseTitle.png';
import GoldRushTitleImageUrl from '../GoldRushSlotMachine/goldRushTitle.png';
import { Title as HighScoreTitle } from '../HighScoreSlotMachine/presentation';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';

export const LoseModalBackground: React.FC<React.SVGProps<SVGSVGElement>> = props => {
	return (
		<svg
			height='100%'
			preserveAspectRatio='none'
			viewBox='0 0 828 377'
			overflow='visible'
			width='100%'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g filter='url(#filter0_d_0_2081)'>
				<rect x='15' y='15' width='798' height='347' rx='16' stroke='url(#paint0_linear_0_2081)' strokeWidth='30' />
			</g>
			<rect opacity='0.78446' x='30' y='29.5' width='768' height='318' fill='url(#paint1_linear_0_2081)' />
			<mask
				id='mask0_0_2081'
				style={{ maskType: 'alpha' }}
				maskUnits='userSpaceOnUse'
				x='30'
				y='29'
				width='768'
				height='319'
			>
				<rect x='30' y='29.5' width='768' height='318' fill='white' />
			</mask>
			<g mask='url(#mask0_0_2081)' />
			<defs>
				<filter
					id='filter0_d_0_2081'
					x='0'
					y='0'
					width='866'
					height='420'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='24' />
					<feGaussianBlur stdDeviation='9.5' />
					<feColorMatrix type='matrix' values='0 0 0 0 0.146395 0 0 0 0 0.171759 0 0 0 0 0.153313 0 0 0 0.658934 0' />
					<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_0_2081' />
					<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_0_2081' result='shape' />
				</filter>
				<linearGradient
					id='paint0_linear_0_2081'
					x1='-594.512'
					y1='25.7514'
					x2='-571.006'
					y2='963.678'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='white' />
					<stop offset='0.295198' stopColor='#9DD7ED' />
					<stop offset='0.677818' stopColor='#51BAE5' />
					<stop offset='1' stopColor='#3FB2E3' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_0_2081'
					x1='-520.055'
					y1='51.2214'
					x2='-502.021'
					y2='842.45'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='white' />
					<stop offset='0.295198' stopColor='#9DD7ED' />
					<stop offset='0.677818' stopColor='#51BAE5' />
					<stop offset='1' stopColor='#3FB2E3' />
				</linearGradient>
			</defs>
		</svg>
	);
};

export const GoldRushWinModalBackground: React.FC<{ className?: string }> = props => {
	return (
		<svg
			height='100%'
			preserveAspectRatio='none'
			viewBox='0 0 828 377'
			overflow='visible'
			width='100%'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<rect x='15' y='15' width='798' height='347' rx='16' stroke='url(#paint0_linear_0_2442)' strokeWidth='30' />
			<rect x='30' y='26.5' width='768' height='326' fill='url(#paint1_linear_0_2442)' />
			<mask
				id='mask0_0_2442'
				style={{ maskType: 'alpha' }}
				maskUnits='userSpaceOnUse'
				x='30'
				y='26'
				width='768'
				height='327'
			>
				<rect x='30' y='26.5' width='768' height='326' fill='white' />
			</mask>
			<g mask='url(#mask0_0_2442)' />
			<defs>
				<linearGradient
					id='paint0_linear_0_2442'
					x1='-481.878'
					y1='13.0308'
					x2='-353.586'
					y2='693.329'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#FFE677' />
					<stop offset='0.0301323' stopColor='#FFC200' />
					<stop offset='0.0636746' stopColor='#FFD600' />
					<stop offset='0.151015' stopColor='#F07A00' />
					<stop offset='0.256778' stopColor='#9E2F00' />
					<stop offset='0.404401' stopColor='#FF7F00' />
					<stop offset='0.495485' stopColor='#FFBC00' />
					<stop offset='0.536747' stopColor='#FFA200' />
					<stop offset='0.689422' stopColor='#FFC500' />
					<stop offset='0.835232' stopColor='#FFA200' />
					<stop offset='0.95572' stopColor='#FFD45A' />
					<stop offset='1' stopColor='#FCF881' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_0_2442'
					x1='-533.729'
					y1='-354.872'
					x2='-532.635'
					y2='419.089'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#EEFFDF' />
					<stop offset='0.106589' stopColor='#9DF64F' />
					<stop offset='0.193201' stopColor='#6CE800' />
					<stop offset='0.338191' stopColor='#329A00' />
					<stop offset='0.542352' stopColor='#005F33' />
					<stop offset='1' stopColor='#3ACD00' />
				</linearGradient>
			</defs>
		</svg>
	);
};

export const HighScoreWinModalBackground: React.FC<{ className?: string }> = props => {
	return (
		<svg
			height='100%'
			preserveAspectRatio='none'
			viewBox='0 0 828 377'
			overflow='visible'
			width='100%'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g filter='url(#filter0_d_0_2721)'>
				<rect x='15' y='15' width='798' height='347' stroke='url(#paint0_linear_0_2721)' strokeWidth='30' />
			</g>
			<rect x='30' y='26.5' width='768' height='326' fill='url(#paint1_linear_0_2721)' />
			<mask
				id='mask0_0_2721'
				style={{ maskType: 'alpha' }}
				maskUnits='userSpaceOnUse'
				x='30'
				y='26'
				width='768'
				height='327'
			>
				<rect x='30' y='26.5' width='768' height='326' fill='white' />
			</mask>
			<g mask='url(#mask0_0_2721)' />
			<defs>
				<filter
					id='filter0_d_0_2721'
					x='0'
					y='0'
					width='866'
					height='420'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feColorMatrix
						in='SourceAlpha'
						type='matrix'
						values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
						result='hardAlpha'
					/>
					<feOffset dy='24' />
					<feGaussianBlur stdDeviation='9.5' />
					<feColorMatrix type='matrix' values='0 0 0 0 0.146395 0 0 0 0 0.171759 0 0 0 0 0.153313 0 0 0 0.658934 0' />
					<feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_0_2721' />
					<feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_0_2721' result='shape' />
				</filter>
				<linearGradient
					id='paint0_linear_0_2721'
					x1='20.1083'
					y1='0'
					x2='20.1083'
					y2='375.991'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#B683FF' />
					<stop offset='1' stopColor='#534EE4' />
				</linearGradient>
				<linearGradient id='paint1_linear_0_2721' x1='30' y1='26.5' x2='30' y2='352.5' gradientUnits='userSpaceOnUse'>
					<stop stopColor='#A750D0' />
					<stop offset='1' stopColor='#6E27A3' />
				</linearGradient>
			</defs>
		</svg>
	);
};

export const BoozeCruiseWinModalBackground: React.FC<{ className?: string }> = props => {
	return (
		<svg viewBox='0 0 541 541' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='xMidYMid meet' {...props}>
			<circle cx='270.5' cy='270.5' r='269' fill='#002138' stroke='url(#paint0_linear_0_2462)' strokeWidth='3' />
			<ellipse cx='271.134' cy='254.442' rx='263.872' ry='251' fill='url(#paint1_linear_0_2462)' />
			<defs>
				<linearGradient
					id='paint0_linear_0_2462'
					x1='-113.815'
					y1='240.179'
					x2='454.431'
					y2='596.885'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='white' />
					<stop offset='0.378771' stopColor='#14B0EA' />
					<stop offset='0.7077' stopColor='#90DAF5' stopOpacity='0.270603' />
					<stop offset='1' stopColor='white' />
				</linearGradient>
				<linearGradient
					id='paint1_linear_0_2462'
					x1='7.26175'
					y1='3.44214'
					x2='7.26175'
					y2='505.442'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#C6E8F4' />
					<stop offset='0.449031' stopColor='#F6F6F6' stopOpacity='0.01' />
					<stop offset='1' stopColor='white' stopOpacity='0.01' />
				</linearGradient>
			</defs>
		</svg>
	);
};

export const SlotMachineEndGameModalTitle: React.FC<{ slotMachine: SlotMachineViewModel }> = ({ slotMachine }) => {
	switch (slotMachine.type) {
		case Api.SlotMachineType.BoozeCruise: {
			return <img src={BoozeCruiseTitleImageUrl} className={css(styleSheet.title)} />;
		}
		case Api.SlotMachineType.GoldRush: {
			return <img src={GoldRushTitleImageUrl} className={css(styleSheet.title)} />;
		}
		case Api.SlotMachineType.Default: {
			return <HighScoreTitle className={css(styleSheet.title)} />;
		}
		default: {
			return null;
		}
	}
};

export const SlotMachineEndGameModalBackground: React.FC<{ slotMachine: SlotMachineViewModel }> = ({ slotMachine }) => {
	if (slotMachine?.spinOutcome?.gameOutcomeResultType !== Api.GameOutcomeResultType.Win) {
		return <LoseModalBackground className={css(styleSheet.background)} />;
	}

	switch (slotMachine.type) {
		case Api.SlotMachineType.BoozeCruise: {
			return <BoozeCruiseWinModalBackground className={css(styleSheet.background)} />;
		}
		case Api.SlotMachineType.GoldRush: {
			return <GoldRushWinModalBackground className={css(styleSheet.background)} />;
		}
		case Api.SlotMachineType.Default: {
			return <HighScoreWinModalBackground className={css(styleSheet.background)} />;
		}
		default: {
			return null;
		}
	}
};

export const SlotMachineEndGameModalMessage: React.FC<{ slotMachine: SlotMachineViewModel }> = ({ slotMachine }) => {
	if (slotMachine?.spinOutcome?.gameOutcomeResultType === Api.GameOutcomeResultType.Win) {
		return (
			<div>
				{"Congrats, you've won: "}
				<span className={css(baseStyleSheet.fontBold)}>{slotMachine.spinOutcome.prize}</span>
			</div>
		);
	}

	return (
		<>
			<div className={css(baseStyleSheet.fontBold)}>Better Luck Next Time!</div>
			<div>Sorry, keep earning spins and try again soon!</div>
		</>
	);
};
