import { CssSelectors } from '../../../../../web/styles/styles';
import { brandPrimary, brandPrimaryDark, brandSecondary, white } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	select: {
		background: brandPrimary,
		border: `1px solid ${brandSecondary}`,
		...CssSelectors.allDescendants(
			{
				width: 200,
			},
			'.tinyPopover-anchor'
		),
		...CssSelectors.allDescendants(
			{
				...CssSelectors.allDescendants(
					{
						fill: brandSecondary,
					},
					'polygon'
				),
			},
			'.disclosure-icon'
		),
		...CssSelectors.allDescendants(
			{
				color: white,
			},
			'.select-option-text'
		),
	},
	selectDropdown: {
		border: `1px solid ${brandSecondary}`,
		...CssSelectors.allDescendants(
			{
				background: brandPrimary,
			},
			'.options-container'
		),
		...CssSelectors.allDescendants(
			{
				background: brandPrimary,
				...CssSelectors.allDescendants({
					':hover': {
						background: brandPrimaryDark,
					},
					color: white,
				}),
			},
			'.option-container'
		),
		...CssSelectors.allDescendants(
			{
				background: brandPrimaryDark,
			},
			'.selected'
		),
	},
});
