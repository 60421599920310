import * as Api from '@ViewModels';
import equal from 'fast-deep-equal';
import * as React from 'react';

export interface IPageCollectionHookOptions<TModel extends object = any, TTransformedItem extends object = TModel> {
	apiPath: string | (() => string);
	defaultPageSize?: number;
	defaultQueryParams?: Api.IDictionary;
	/** Default: 'GET' */
	httpMethod?: 'GET' | 'POST';
	impersonationContext?: Api.IImpersonationContext;
	transformer?(model: TModel): TTransformedItem;
}

type FetchQuery = Omit<Api.IPagedResultFetchContext, 'pageToken'>;

interface IFetchContext<TRequest extends object = any> {
	hasAllPages?: boolean;
	hasFetchedFirstPage?: boolean;
	pageToken?: string;
	query?: Api.IDictionary;
	request?: TRequest;
}

const fetchIsEqualToPreviousFetch = <TFetchRequest extends object = any>(
	current?: IFetchContext<TFetchRequest>,
	next?: IFetchContext<TFetchRequest>
) => {
	const { query: currentQuery, request: currentRequest } = current || {};
	const { query, request } = next || {};
	return (
		((!request && !currentRequest) || equal(request, currentRequest)) &&
		((!query && !currentQuery) || equal(query || {}, currentQuery || {}))
	);
};

export const usePagedCollection = <
	TModel extends object = any,
	TTransformedItem extends object = TModel,
	TRequest extends object = any,
>(
	userSession: Api.UserSessionContext,
	options: IPageCollectionHookOptions<TModel, TTransformedItem>
) => {
	const { apiPath, httpMethod = 'POST', defaultPageSize = 25, defaultQueryParams, impersonationContext } = options;

	const [fetching, setFetching] = React.useState<boolean>(false);
	const [items, setItems] = React.useState<TTransformedItem[]>(null);
	const [totalCount, setTotalCount] = React.useState<number>(-1);

	const fetchContextRef = React.useRef<IFetchContext>({
		hasAllPages: false,
		hasFetchedFirstPage: false,
		query: {
			pageSize: defaultPageSize,
		},
	});
	const setFetchContext = React.useCallback((context?: Partial<IFetchContext>) => {
		fetchContextRef.current = {
			...(fetchContextRef.current || {}),
			...(context || {}),
		};
	}, []);

	const acitveFetchRef = React.useRef<{
		abortController: AbortController;
		promise: Promise<Api.IOperationResult<Api.IPagedCollection<TModel>>>;
	}>(null);
	const cancelFetch = React.useCallback(() => {
		acitveFetchRef.current?.abortController?.abort();
		acitveFetchRef.current = null;
		setFetching(false);
	}, []);

	const composeQueryParams = React.useCallback((query?: FetchQuery, additionalParams?: Api.IDictionary) => {
		// remove the typeOf filter
		const queryWithoutTypeOfFilter = Api.excludeKeysOf(query || {}, ['typeOf']);
		// convert typeOf filter array to "string,string,string..." param
		const types = !!query && !!query.typeOf ? { typeOf: query.typeOf.join(',') } : {};
		const computedParams = {
			...(additionalParams || {}),
			...queryWithoutTypeOfFilter,
			...types,
			...(queryWithoutTypeOfFilter || {}),
		};
		Api.VmUtils.removeEmptyKvpFromObject(computedParams);
		return computedParams;
	}, []);

	const executeFetch = React.useCallback(
		(context?: IFetchContext) => {
			const abortController = new AbortController();
			const promise = new Promise<Api.IOperationResult<Api.IPagedCollection<TModel>>>(resolve => {
				let path: string = null;
				if (typeof apiPath === 'string') {
					path = apiPath as string;
				} else if (typeof apiPath === 'function') {
					path = (apiPath as () => string)();
				}

				const queryParams: Api.IDictionary = { ...(context?.query || {}) };
				if (fetchContextRef.current?.pageToken) {
					queryParams.pageToken = fetchContextRef.current.pageToken;
				}
				userSession.webServiceHelper
					.callWebServiceAsync<Api.IPagedCollection<TModel>>(
						Api.ImpersonationBroker.composeApiUrl({ impersonationContext, queryParams, urlPath: path }),
						httpMethod,
						httpMethod === 'POST' ? context?.request : null
					)
					.then(opResult => {
						if (!abortController.signal.aborted) {
							resolve(opResult);
						}
					});
			});
			return { abortController, promise } as const;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[httpMethod, apiPath, impersonationContext]
	);

	const getItems = React.useCallback(
		(query?: FetchQuery, request?: TRequest) => {
			const computedQuery = composeQueryParams(
				{
					...(query || {}),
					pageSize:
						(query?.pageSize !== null && query?.pageSize !== undefined && query?.pageSize) ||
						fetchContextRef.current?.query?.pageSize,
				},
				defaultQueryParams
			);

			const isFetchEqualToPreviousFetch = fetchIsEqualToPreviousFetch(fetchContextRef.current || {}, {
				query: computedQuery,
				request,
			});

			if (isFetchEqualToPreviousFetch) {
				if (acitveFetchRef.current?.promise) {
					return acitveFetchRef.current.promise;
				}

				if (fetchContextRef.current?.hasAllPages) {
					return Promise.resolve([]);
				}
			} else {
				// use/clear page token and fetch results state if needed
				setFetchContext({
					hasAllPages: false,
					pageToken: null,
				});
			}

			cancelFetch();
			setFetching(true);
			setFetchContext({
				query: computedQuery,
				request,
			});

			return new Promise<Api.IOperationResult<TTransformedItem[]>>((resolve, reject) => {
				acitveFetchRef.current = executeFetch(fetchContextRef.current);
				const { abortController, promise } = acitveFetchRef.current;
				promise.then(opResult => {
					if (acitveFetchRef.current?.abortController !== abortController || abortController.signal.aborted) {
						return;
					}

					acitveFetchRef.current = null;
					if (opResult.success) {
						setFetchContext({
							hasAllPages: !opResult.value.pageToken,
							pageToken: opResult.value.pageToken,
						});
						setTotalCount(opResult.value.totalCount);

						const additionalItems = options?.transformer
							? (opResult.value.values || []).map(options.transformer)
							: opResult.value.values || [];
						setItems(itemsInCollection => {
							if (!isFetchEqualToPreviousFetch || !itemsInCollection) {
								itemsInCollection = [];
							}

							return [...itemsInCollection, ...(additionalItems as TTransformedItem[])];
						});

						setFetching(false);
						resolve({
							success: true,
							value: additionalItems as TTransformedItem[],
						});
					} else {
						setFetching(false);
						reject(opResult);
					}
				});
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[executeFetch]
	);

	const reset = React.useCallback(() => {
		cancelFetch();
		setTotalCount(-1);
		setItems(null);
		fetchContextRef.current = {
			hasAllPages: false,
			hasFetchedFirstPage: false,
			query: {
				pageSize: defaultPageSize,
			},
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		cancelFetch,
		fetching,
		getItems,
		items,
		reset,
		setItems,
		totalCount,
	} as const;
};
