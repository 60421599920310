import { getDisplayName } from '../../../../../models/UiUtils';
import { ViewModelTypeAutocomplete } from '../../../../containers/Settings/PhoneNumbers/components/ViewModelTypeAutocomplete';
import { styleSheet } from '../styles';
import { IUser, ResourceAutoCompleteViewModelType } from '@ViewModels';
import { css } from 'aphrodite';
import { useEffect, useState } from 'react';
import * as React from 'react';

interface IProps {
	user?: IUser;
	onChange: (user: IUser) => void;
}

export const CloserOwnerSelect: React.FC<IProps> = ({ user: initialUser, onChange }) => {
	// this is to allow transition from old views where the parent doesn't control the state
	// to new views (calloog) where the parent controls the selection/state
	// keep intenral state but allow parent to control it
	// @ts-ignore
	const [selectedItem, selectItem] = useState<IUser>(initialUser);
	useEffect(() => {
		// @ts-ignore
		selectItem(initialUser);
	}, [initialUser]);
	const handleSelection = (value: IUser) => {
		onChange(value);
		selectItem(value);
	};
	return (
		<ViewModelTypeAutocomplete<IUser>
			className={css(styleSheet.autocomplete)}
			defaultSelected={selectedItem}
			model={ResourceAutoCompleteViewModelType.User}
			onChange={handleSelection}
			placeholder={selectedItem ? 'Closer Owner: ' + getDisplayName(selectedItem) : 'Filter by Closer Owner'}
		/>
	);
};
