import { white } from '../../../styles/colors';
import { CardViewIcon } from '../../svgs/icons/CardViewIcon';
import { RowViewIcon } from '../../svgs/icons/RowViewIcon';
import { LeadViewType, useLeadView } from '../context';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

export const LeadViewViewSelector: React.FC = () => {
	const leadView = useLeadView();

	const onClick = (viewType: LeadViewType) => () => {
		leadView.setViewType(viewType);
	};

	const calculateColor = (viewType: LeadViewType) => {
		const isEmpty = !leadView.viewType;
		const typeToCompare = isEmpty ? LeadViewType.Row : leadView.viewType;
		return typeToCompare !== viewType ? '#5B9BBD' : white;
	};

	return (
		<div className={`${css(styleSheet.container)} lead-view-view-selector`}>
			<span onClick={onClick(LeadViewType.Card)}>
				<CardViewIcon fillColor={calculateColor(LeadViewType.Card)} />
			</span>
			<span onClick={onClick(LeadViewType.Row)}>
				<RowViewIcon fillColor={calculateColor(LeadViewType.Row)} />
			</span>
		</div>
	);
};
