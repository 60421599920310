import { Button } from '../../../../web/components/Button';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
// eslint-disable-next-line import/no-internal-modules
import CoffeeAnimationImageUrl from '../../../assets/coffee-animation.gif';
import { CoffeeLogo } from '../../svgs/CoffeeLogo';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import { useState } from 'react';
import * as React from 'react';
import ReactDOM from 'react-dom';

interface IProps {
	onClick: () => void;
}

export const SplashScreen: React.FC<IProps> = ({ onClick }) => {
	const [loading, setLoading] = useState(false);
	const letsGoClick = (e: React.MouseEvent<HTMLElement>) => {
		setLoading(true);
		e.stopPropagation();
		onClick();
	};

	const component = (
		<div>
			<div className={`${css(styleSheet.headerContainer)}`}>
				<CoffeeLogo className={css(styleSheet.splashScreenLogo)} />
			</div>
			<div className={css(styleSheet.splashScreen)} onClick={letsGoClick}>
				<img className={css(styleSheet.splashScreenImage)} src={CoffeeAnimationImageUrl} />
				{loading ? <LoadingSpinner type='small' /> : <Button onClick={letsGoClick} label="Let's go!" />}
			</div>
		</div>
	);
	const nodeToAppend = document.querySelector('body');
	// @ts-ignore
	return ReactDOM.createPortal(component, nodeToAppend);
};
