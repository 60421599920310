import { ISlotMachineSymbolProps, SlotMachineSymbol } from '../SlotMachineSymbol';
import * as React from 'react';

export const TigerKingSymbol = React.forwardRef<SVGSVGElement, ISlotMachineSymbolProps>(
	function TigerKingSymbol(props, ref) {
		const { className = '', index, ...restProps } = props;
		return (
			<SlotMachineSymbol height={131} width={111} {...restProps} className={`${className} tiger-king-symbol`} ref={ref}>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M10.228 84.41 0 93.144h10.228l-7.671 7.488h10.228s4.58 12.318 10.227 16.223c5.648 3.905 20.456 4.992 20.456 4.992l8.949-9.984L16.62 80.665l-6.392 3.744Zm90.544 0L111 93.144h-10.228l7.671 7.488H98.215s-4.58 12.318-10.227 16.223c-5.648 3.905-20.456 4.992-20.456 4.992l-8.949-9.984L94.38 80.665l6.392 3.744Z'
					fill='#fff'
				/>
				<ellipse cx='57.813' cy='111.934' rx='17.729' ry='17.541' fill='#063E66' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M44.707 124.896C46.86 128.545 50.887 131 55.5 131c4.612 0 8.639-2.455 10.793-6.104H44.707Z'
					fill='#F89143'
				/>
				<rect x='13.875' y='48.634' width='86.333' height='56.436' rx='19' fill='#E78236' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M24.81 101.025c-2.345-7.012 2.873-14.259 10.266-14.259H45.58c6.33 0 11.462 5.132 11.462 11.462 0 6.88-6.019 12.21-12.848 11.379l-10.426-1.27a10.826 10.826 0 0 1-8.958-7.312Zm64.463 0c2.345-7.012-2.873-14.259-10.266-14.259H68.504c-6.33 0-11.462 5.132-11.462 11.462 0 6.88 6.018 12.21 12.847 11.379l10.427-1.27a10.826 10.826 0 0 0 8.957-7.312Z'
					fill='#FFA25C'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M46.25 89.817h20.042l-5.275 7.844-4.219 1.308-4.22-1.308-6.328-7.844Z'
					fill='#063E66'
				/>
				<rect x='41.625' y='54.735' width='29.292' height='6.355' rx='2' fill='#063E66' />
				<rect x='45.286' y='63.633' width='23.189' height='6.355' rx='2' fill='#063E66' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M88.488 86.398a1.876 1.876 0 0 1 .69-2.575l6.568-3.752a1.876 1.876 0 0 1 1.861 3.258l-6.569 3.752a1.876 1.876 0 0 1-2.55-.683Zm0 6.237a1.876 1.876 0 0 1 .69-2.575l6.568-3.752a1.876 1.876 0 0 1 1.861 3.258l-6.569 3.752a1.876 1.876 0 0 1-2.55-.683ZM24.095 85.15c.525-.9.216-2.057-.69-2.574l-6.569-3.752a1.876 1.876 0 1 0-1.861 3.258l6.569 3.752c.894.51 2.032.206 2.55-.684Zm0 6.238a1.877 1.877 0 0 0-.69-2.575l-6.569-3.752a1.876 1.876 0 1 0-1.861 3.258l6.569 3.752c.894.51 2.032.206 2.55-.683Z'
					fill='#063E66'
				/>
				<rect x='72.468' y='97.443' width='35.448' height='2.586' rx='1.293' fill='#063E66' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M72.697 102.515a1.292 1.292 0 0 1 1.502-1.051l27.38 4.93a1.292 1.292 0 0 1-.458 2.543l-27.38-4.93a1.293 1.293 0 0 1-1.044-1.492Zm.563 4.765a1.29 1.29 0 0 1 1.678-.747l20.056 7.861a1.29 1.29 0 0 1-.941 2.401l-20.056-7.861a1.29 1.29 0 0 1-.736-1.654Z'
					fill='#063E66'
				/>
				<rect width='35.448' height='2.586' rx='1.293' transform='matrix(-1 0 0 1 41.615 97.443)' fill='#063E66' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M41.386 102.515a1.292 1.292 0 0 0-1.502-1.051l-27.38 4.93a1.29 1.29 0 1 0 .458 2.543l27.38-4.93a1.292 1.292 0 0 0 1.044-1.492Zm-.564 4.765a1.29 1.29 0 0 0-1.677-.747l-20.055 7.861a1.29 1.29 0 0 0 .94 2.401l20.056-7.861a1.29 1.29 0 0 0 .736-1.654Z'
					fill='#063E66'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='m44.708 123.374 3.084-7.627 3.083 7.627h-6.167Zm15.417 0 3.083-7.627 3.084 7.627h-6.167Zm7.44-36.608L95.583 67s-16.556-3.706-24.197 1.236c-7.641 4.941-3.82 18.53-3.82 18.53Z'
					fill='#fff'
				/>
				<ellipse rx='10.021' ry='10.677' transform='matrix(-1 0 0 1 76.313 80.665)' fill='#063E66' />
				<ellipse rx='4.941' ry='4.92' transform='matrix(-1 0 0 1 74.963 81.516)' fill='#F84343' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M43.435 86.766 15.417 67s16.556-3.706 24.197 1.236c7.642 4.941 3.82 18.53 3.82 18.53Z'
					fill='#fff'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M79.233 48.077s7.596-.382 11.37-1.647c3.774-1.264 9.574-5.369 9.574-5.369s4.835 16.558-6.357 19.507c-11.193 2.948-14.587-12.49-14.587-12.49Z'
					fill='#063E66'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M79.324 47.271s6.561-.338 9.823-1.422c3.26-1.084 8.276-4.594 8.276-4.594s4.151 14.11-5.52 16.643c-9.671 2.532-12.579-10.627-12.579-10.627Z'
					fill='#F89143'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M30.225 48.077s-7.596-.382-11.37-1.647c-3.774-1.264-9.574-5.369-9.574-5.369S4.446 57.62 15.639 60.568c11.192 2.948 14.586-12.49 14.586-12.49Z'
					fill='#063E66'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M30.134 47.271s-6.561-.338-9.822-1.422c-3.262-1.084-8.277-4.594-8.277-4.594s-4.15 14.11 5.52 16.643c9.671 2.532 12.579-10.627 12.579-10.627Z'
					fill='#F89143'
				/>
				<ellipse cx='50.104' cy='101.257' rx='.771' ry='.763' fill='#063E66' />
				<ellipse cx='45.479' cy='105.833' rx='.771' ry='.763' fill='#063E66' />
				<ellipse rx='1.285' ry='1.525' transform='matrix(-1 0 0 1 62.951 102.019)' fill='#063E66' />
				<ellipse rx='1.285' ry='1.525' transform='matrix(-1 0 0 1 68.09 105.07)' fill='#063E66' />
				<ellipse cx='34.688' cy='80.665' rx='10.021' ry='10.677' fill='#063E66' />
				<ellipse cx='36.037' cy='81.516' rx='4.941' ry='4.92' fill='#F84343' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='m28.128 10.63 25.026 19.74H28.128V10.63Zm0 19.74L39.077 41H28.128V30.37Zm12.513 0L51.59 41H40.64V30.37Zm29.718 0L59.41 41h10.95V30.37Zm12.513 0L71.923 41h10.949V30.37Zm1.564-19.74L59.41 30.37h25.026V10.63Z'
					fill='#E7C309'
				/>
				<ellipse cx='55.5' cy='3.796' rx='3.91' ry='3.796' fill='#F3CE0D' />
				<ellipse cx='82.09' cy='3.796' rx='3.91' ry='3.796' fill='#F3CE0D' />
				<ellipse cx='28.91' cy='3.796' rx='3.91' ry='3.796' fill='#F3CE0D' />
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='m56.282 10.63 12.513 19.74H43.769l12.513-19.74ZM55.5 30.37 59.41 41h-7.82l3.91-10.63Z'
					fill='#F9D519'
				/>
			</SlotMachineSymbol>
		);
	}
);
