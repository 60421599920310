import { CssSelectors } from '../../../../web/styles/styles';
import { borderColor, lightOrange, tagBackgroundColor, titles } from '../../../styles/colors';
import { aidaBaseStyles } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	contentContainer: {
		...aidaBaseStyles.flexCol,
		alignItems: 'flex-start',
		maxHeight: 200,
		minHeight: 50,
		overflow: 'auto',
		position: 'relative',
	},
	leadAttributeContainer: {},
	noOptions: {
		...aidaBaseStyles.absoluteCenter,
		color: '#858585',
		fontSize: '14px',
		minWidth: '100%',
		textAlign: 'center',
	},
	option: {
		background: tagBackgroundColor,
		borderRadius: 30,
		color: titles,
		fontSize: '12px',
		maxWidth: '100%',
		minHeight: 26,
		padding: '5px 10px',
	},
	optionButton: {
		':not(:last-child)': {
			marginBottom: 10,
		},
		...aidaBaseStyles.flexHorizontalCenter,
		maxWidth: '99%',
		...CssSelectors.allChildren(
			{
				marginRight: 5,
			},
			'.checkbox'
		),
	},
	optionNone: {
		background: lightOrange,
	},
	popoverContainer: {
		background: '#fff',
		border: `1px solid ${borderColor}`,
		borderRadius: 4,
		boxShadow: `0 1px 4px 1px rgba(0, 0, 0, 0.2)`,
		marginTop: 5,
		padding: 10,
		width: 215,
	},
	searchContainer: {
		paddingBottom: 10,
	},
	searchIcon: {
		marginRight: 5,
	},
	selectedAttribute: {
		...aidaBaseStyles.flexHorizontalCenter,
		background: tagBackgroundColor,
		border: 'none',
		borderRadius: 30,
		color: titles,
		fontSize: '12px',
		marginTop: 10,
		padding: '4px 10px',
		whiteSpace: 'nowrap',
		...CssSelectors.allChildren(
			{
				marginRight: 15,
			},
			':first-child'
		),
		...CssSelectors.allDescendants(
			{
				paddingRight: 5,
			},
			'span:first-child'
		),
		...CssSelectors.allDescendants(
			{
				transform: 'rotateZ(90deg)',
			},
			'svg'
		),
	},
	selectedAttributeButton: {
		position: 'relative',
	},
});
