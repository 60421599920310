import { EightOfClubsGraphic } from '../EightOfClubsGraphic';
import { styleSheet } from './styles';
import { animated, config, useSpring } from '@react-spring/web';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	animate?: boolean;
	className?: string;
}

const from = {
	transform: 'rotateY(0deg)',
};
const to = {
	transform: 'rotateY(360deg)',
};

export const QuickPitchGraphic: React.FC<IProps> = ({ animate, className = '' }) => {
	const spring = useSpring({
		config: config.gentle,
		from,
		to: animate ? to : from,
	});

	return (
		<div className={`${css(styleSheet.quickPitchContainer)} ${className}`}>
			<animated.div className={css(styleSheet.eightOfClubsContainer)} style={spring}>
				<EightOfClubsGraphic className={css(styleSheet.eightOfClubs)} />
				<div className={css(styleSheet.eightOfClubsBack)}>
					<span>A</span>
				</div>
			</animated.div>
		</div>
	);
};
