import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import { SlotMachineSymbol } from '../SlotMachineSymbol';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { animated } from '@react-spring/web';
import * as React from 'react';

interface IHighScoreSymbolProps {
	height: number;
	symbolType: Api.SlotMachineSymbol;
}

export const HighScoreSymbol: React.FC<IHighScoreSymbolProps> = props => {
	const { height, symbolType } = props;
	const style: React.CSSProperties = { height, width: 'auto' };
	switch (symbolType) {
		case Api.SlotMachineSymbol.Cherry: {
			style.width = (style.width as number) + 10;
			break;
		}
		case Api.SlotMachineSymbol.Tiger: {
			style.width = (style.width as number) + 5;
			break;
		}
		default: {
			break;
		}
	}

	const styleProvider = (s: React.CSSProperties) => {
		return {
			...s,
			...style,
		};
	};
	return <SlotMachineSymbol style={styleProvider} styles={[styleSheet.symbol]} type={symbolType} />;
};

export const Title: React.FC<React.SVGProps<SVGSVGElement>> = props => {
	return (
		<SvgIcon fill='none' height={1295} width={1768} overflow='visible' {...props}>
			<animated.g>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M308.319 749.221C319.222 726.771 330.126 704.322 341.03 681.872H404.852C401.046 690.752 397.24 699.633 393.434 708.513L325.075 868.028H473.991C495.736 798.838 517.481 729.648 539.225 660.457L545.579 635.747L597.539 433.664H511.221C484.629 495.717 458.036 557.768 431.444 619.82H371.168C401.306 557.768 431.444 495.716 461.581 433.664H362.304C277.596 574.851 192.889 716.038 108.181 857.225C106.02 860.826 103.86 864.427 101.699 868.028H250.616C269.85 828.426 289.084 788.823 308.319 749.221Z'
					fill='white'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M771.826 868.028C778.095 794.881 784.365 721.733 790.634 648.585C791.269 641.185 791.903 633.784 792.537 626.383L793.115 606.146L796.32 493.984L798.003 435.088H808.933C808.974 434.613 809.014 434.139 809.055 433.664H674.048H660.138C642.54 502.104 624.943 570.543 607.345 638.983C604.077 651.691 600.809 664.399 597.542 677.107C581.178 740.748 564.814 804.388 548.45 868.028H771.826Z'
					fill='white'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M989.883 805.977C986.337 764.608 982.792 723.24 979.246 681.872H1043.07C1048.98 723.24 1054.89 764.608 1060.8 805.977H989.883ZM920.743 868.028H1218.58C1202.21 804.388 1185.85 740.748 1169.49 677.108L1166.71 668.288L1157.79 639.9L1151.48 619.82H973.927C970.382 578.452 966.836 537.084 963.29 495.716C972.155 495.716 981.018 495.716 989.883 495.716C998.747 495.716 1007.61 495.716 1016.48 495.716C1019.43 516.4 1022.38 537.084 1025.34 557.768H1131.98L1112.48 495.716C1107.07 495.716 1101.66 495.716 1096.25 495.716C1087.39 495.716 1078.52 495.716 1069.66 495.716C1065.52 475.032 1061.39 454.348 1057.25 433.664H908.332C908.923 454.348 909.516 475.032 910.106 495.716C901.242 495.716 892.378 495.716 883.514 495.716C877.204 495.716 870.894 495.716 864.586 495.716H856.921L853.09 629.839C852.88 637.167 852.671 644.496 852.462 651.824C850.993 703.208 849.524 754.592 848.057 805.977H918.97C919.561 826.661 920.152 847.344 920.743 868.028Z'
					fill='white'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M1227.8 660.458C1228.98 664.196 1230.15 667.935 1231.33 671.673C1251.9 737.125 1272.47 802.577 1293.04 868.028H1441.95C1415.36 805.976 1388.77 743.924 1362.18 681.872H1426C1456.14 743.924 1486.27 805.976 1516.41 868.028H1665.33C1659.86 858.915 1654.39 849.802 1648.92 840.688C1567.53 705.014 1486.12 569.339 1404.72 433.664H1305.45C1335.58 495.716 1365.72 557.768 1395.86 619.821H1335.58C1308.99 557.768 1282.4 495.716 1255.81 433.664H1156.53C1156.68 434.139 1156.83 434.613 1156.98 435.088C1180.59 510.211 1204.19 585.335 1227.8 660.458Z'
					fill='white'
				/>
			</animated.g>
			<animated.g>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M398.445 1075.56C417.358 1049.08 436.27 1022.6 455.182 996.122H523.954L536.82 969.634L543.245 956.404H451.778C441.566 969.644 431.353 982.883 421.14 996.122H387.098C365.16 1022.6 343.221 1049.08 321.282 1075.56H359.864C349.651 1088.8 339.439 1102.04 329.226 1115.28C342.843 1115.28 356.46 1115.28 370.077 1115.28C383.694 1115.28 397.311 1115.28 410.927 1115.28C393.528 1141.76 376.128 1168.23 358.729 1194.71H222.559C211.59 1207.95 200.621 1221.19 189.651 1234.43H380.291C388.233 1221.19 396.177 1207.95 404.12 1194.71H427.501L440.366 1168.22L466.082 1115.28C461.314 1115.28 456.547 1115.28 451.779 1115.28C459.722 1102.04 467.666 1088.8 475.608 1075.56H398.445Z'
					fill='white'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M647.853 956.404H642.416C636.741 969.644 631.068 982.883 625.394 996.123H591.801H591.353C590.083 998.737 588.813 1001.35 587.544 1003.97C556.662 1067.55 525.781 1131.13 494.9 1194.71H518.492H540.289C534.615 1207.95 528.941 1221.19 523.267 1234.43H666.246C669.65 1221.19 673.054 1207.95 676.458 1194.71H705.996L707.221 1186.14L711.671 1155H643.551C639.389 1168.23 635.229 1181.47 631.068 1194.71H585.679C610.265 1128.52 634.851 1062.32 659.437 996.123H693.479C689.319 1009.36 685.158 1022.6 680.996 1035.84H728.692L734.365 996.123H727.522C730.926 982.883 734.33 969.644 737.734 956.404H647.853Z'
					fill='white'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M858.018 1194.71C859.908 1128.52 861.8 1062.32 863.691 996.122H897.734C899.625 1062.32 901.517 1128.52 903.408 1194.71H858.018ZM965.063 1131.28L938.034 996.122H931.777C930.642 982.883 929.507 969.644 928.372 956.405H833.054C831.919 969.644 830.784 982.883 829.649 996.122H823.392H795.607C786.151 1062.32 776.694 1128.52 767.238 1194.71H783.676H812.628C811.494 1207.95 810.359 1221.19 809.224 1234.43H952.203C951.068 1221.19 949.934 1207.95 948.799 1194.71H977.75L965.063 1131.28Z'
					fill='white'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M1092.91 1075.56C1084.59 1049.08 1076.27 1022.6 1067.95 996.122H1101.99C1111.82 1022.6 1121.66 1049.08 1131.49 1075.56H1092.91ZM1204.12 1155C1192.77 1128.52 1181.42 1102.04 1170.07 1075.56H1178.25L1129.27 985.33L1113.57 956.404H1021.39H991.917C1010.45 1049.08 1028.99 1141.76 1047.52 1234.43H1061.1H1142.84C1134.52 1207.95 1126.19 1181.47 1117.87 1155H1160.99C1170.83 1181.47 1180.66 1207.95 1190.5 1234.43H1264.49L1221.37 1155H1204.12Z'
					fill='white'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M1402.7 1194.71C1385.3 1168.24 1367.9 1141.76 1350.5 1115.28C1364.11 1115.28 1377.73 1115.28 1391.35 1115.28C1404.97 1115.28 1418.58 1115.28 1432.2 1115.28C1421.99 1102.04 1411.77 1088.8 1401.56 1075.56H1324.4C1307 1049.08 1289.6 1022.6 1272.2 996.122H1374.33C1363.36 982.883 1352.39 969.644 1341.42 956.405H1218.18H1182.55C1184.26 959.539 1185.96 962.673 1187.66 965.808C1192.06 973.909 1196.45 982.011 1200.85 990.112C1245.06 1071.55 1289.27 1152.99 1333.48 1234.43H1353.22H1571.77C1560.8 1221.19 1549.84 1207.95 1538.87 1194.71H1402.7Z'
					fill='white'
				/>
			</animated.g>
			<path fillRule='evenodd' clipRule='evenodd' d='M120.162 1000.87H137.445V983.61H120.162V1000.87Z' fill='#BA00AA' />
			<path fillRule='evenodd' clipRule='evenodd' d='M241.385 1000.87H258.668V983.61H241.385V1000.87Z' fill='#BA00AA' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M224.102 1000.87V1018.14H210.013H184.028H158.043H154.729V1000.87H137.445V1018.14H132.059H120.163V1035.41H102.88V1052.67H85.597V1069.94V1087.2V1104.47H102.88V1087.2V1069.94H120.163V1087.2V1104.47H137.445V1087.2H154.729H158.043H184.028H210.013H224.102H235.998H241.384V1104.47H258.668V1087.2V1069.94H275.95V1087.2V1104.47H293.233V1052.67H275.95V1035.41H258.668V1018.14H241.384V1000.87H224.102Z'
				fill='#BA00AA'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M206.698 1121.73H241.385V1104.47H206.698V1121.73Z'
				fill='#BA00AA'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M137.384 1121.73H172.071V1104.47H137.384V1121.73Z'
				fill='#BA00AA'
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M137.324 1061.43H154.607V1044.17H137.324V1061.43Z' fill='white' />
			<path fillRule='evenodd' clipRule='evenodd' d='M223.98 1061.43H241.263V1044.17H223.98V1061.43Z' fill='white' />
			<path fillRule='evenodd' clipRule='evenodd' d='M1503.27 1000.87H1520.56V983.61H1503.27V1000.87Z' fill='#BA00AA' />
			<path fillRule='evenodd' clipRule='evenodd' d='M1624.5 1000.87H1641.78V983.61H1624.5V1000.87Z' fill='#BA00AA' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1607.21 1000.87V1018.14H1593.13H1567.14H1541.16H1537.84V1000.87H1520.56V1018.14H1515.17H1503.27V1035.41H1485.99V1052.67H1468.71V1069.94V1087.2V1104.47H1485.99V1087.2V1069.94H1503.27V1087.2V1104.47H1520.56V1087.2H1537.84H1541.16H1567.14H1593.13H1607.21H1619.11H1624.5V1104.47H1641.78V1087.2V1069.94H1659.06V1087.2V1104.47H1676.35V1052.67H1659.06V1035.41H1641.78V1018.14H1624.5V1000.87H1607.21Z'
				fill='#BA00AA'
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M1589.81 1121.73H1624.5V1104.47H1589.81V1121.73Z' fill='#BA00AA' />
			<path fillRule='evenodd' clipRule='evenodd' d='M1520.5 1121.73H1555.18V1104.47H1520.5V1121.73Z' fill='#BA00AA' />
			<path fillRule='evenodd' clipRule='evenodd' d='M1520.44 1061.43H1537.72V1044.17H1520.44V1061.43Z' fill='white' />
			<path fillRule='evenodd' clipRule='evenodd' d='M1607.09 1061.43H1624.37V1044.17H1607.09V1061.43Z' fill='white' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M674.992 47.2088H721.632V0.595703H674.992V47.2088Z'
				fill='#BA00AA'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1001.47 47.2088H1048.11V0.595703H1001.47V47.2088Z'
				fill='#BA00AA'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M954.481 47.2088V93.8213H916.563H846.627H776.693H767.773V47.2088H721.256V93.8213H706.76H674.742V140.436H628.227V187.048H581.712V233.661V280.273V326.887H628.227V280.273V233.661H674.742V280.273V326.887H721.256V280.273H767.773H776.693H846.627H916.563H954.481H986.499H1001V326.887H1047.51V280.273V233.661H1094.02V280.273V326.887H1140.54V187.048H1094.02V140.436H1047.51V93.8213H1001V47.2088H954.481Z'
				fill='#BA00AA'
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M908.19 373.5H1001.47V326.887H908.19V373.5Z' fill='#BA00AA' />
			<path fillRule='evenodd' clipRule='evenodd' d='M720.783 373.5H814.063V326.887H720.783V373.5Z' fill='#BA00AA' />
			<path fillRule='evenodd' clipRule='evenodd' d='M720.783 209.931H767.423V163.318H720.783V209.931Z' fill='white' />
			<path fillRule='evenodd' clipRule='evenodd' d='M954.83 209.931H1001.47V163.318H954.83V209.931Z' fill='white' />
		</SvgIcon>
	);
};

const DefaultBackgroundInvaderWidth = 251;
const DefaultBackgroundInvaderHeight = 167;
export const BackgroundInvader: React.FC<React.SVGProps<SVGSVGElement> & { toggle?: boolean }> = props => {
	const { toggle, ...restProps } = props;
	return (
		<SvgIcon height={DefaultBackgroundInvaderHeight} width={DefaultBackgroundInvaderWidth} {...restProps}>
			<g style={{ opacity: toggle ? 1 : 0 }}>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M166.814 20.818v20.819H83.262V20.818H62.446v20.819H41.631v20.82H20.815v20.818H0v62.457h20.815v-41.638h20.816v41.638h20.815v-20.82h125.183v20.82h20.817v-41.638h20.814v41.638h20.816V83.275H229.26V62.456h-20.814v-20.82h-20.817V20.819h-20.815Z'
					fill='#BA00AA'
				/>
				<path fillRule='evenodd' clipRule='evenodd' d='M145.853 166.55h41.777v-20.818h-41.777v20.818Z' fill='#BA00AA' />
				<path fillRule='evenodd' clipRule='evenodd' d='M62.373 166.55h41.776v-20.818H62.373v20.818Z' fill='#BA00AA' />
			</g>
			<g style={{ opacity: toggle ? 0 : 1 }}>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M166.814 20.818v20.819H83.262V20.818H62.446v20.819H41.631v20.82H20.815v-41.64H0v83.276h20.815v20.818h20.816v20.82h20.815v-20.82h125.183v20.82h20.817v-20.82h20.814v-20.818h20.816V20.818H229.26v41.638h-20.814v-20.82h-20.817V20.819h-20.815Z'
					fill='#BA00AA'
				/>
				<path fillRule='evenodd' clipRule='evenodd' d='M208.45 166.73h21v-21h-21v21Z' fill='#BA00AA' />
				<path fillRule='evenodd' clipRule='evenodd' d='M20.82 166.73h21v-21h-21v21Z' fill='#BA00AA' />
			</g>
			<path fillRule='evenodd' clipRule='evenodd' d='M41.63 20.818h20.816V0H41.63v20.818Z' fill='#BA00AA' />
			<path fillRule='evenodd' clipRule='evenodd' d='M187.63 20.818h20.816V0H187.63v20.818Z' fill='#BA00AA' />
			<path fillRule='evenodd' clipRule='evenodd' d='M62.3 93.839h20.816V73.02H62.299v20.82Z' fill='#fff' />
			<path fillRule='evenodd' clipRule='evenodd' d='M166.668 93.839h20.815V73.02h-20.815v20.82Z' fill='#fff' />
		</SvgIcon>
	);
};

const BackgroundInvaderGroup: React.FC = () => {
	const [toggle, setToggle] = React.useState<boolean>(false);
	React.useEffect(() => {
		const handle = setInterval(() => setToggle(v => !v), 1000);
		return () => clearInterval(handle);
	}, []);
	const invaderLayout = React.useRef([
		Array.from<null>({ length: 9 }),
		Array.from<null>({ length: 8 }),
		Array.from<null>({ length: 7 }),
		Array.from<null>({ length: 6 }),
		Array.from<null>({ length: 5 }),
		Array.from<null>({ length: 4 }),
		Array.from<null>({ length: 3 }),
		Array.from<null>({ length: 2 }),
		Array.from<null>({ length: 1 }),
	]).current;
	return (
		<g opacity='.556' fillRule='evenodd' clipRule='evenodd'>
			{invaderLayout.map((row, i) =>
				row.map((_, j) => (
					<BackgroundInvader
						key={`invader-${i}${j}`}
						toggle={toggle}
						x={430 + (DefaultBackgroundInvaderWidth + 16) * j + (DefaultBackgroundInvaderWidth / 2) * i}
						y={1054 + (DefaultBackgroundInvaderHeight + 23) * i}
					/>
				))
			)}
		</g>
	);
};

const DefaultBackgroundSize = 3238;
export const Background: React.FC<React.SVGProps<SVGSVGElement>> = props => {
	const { height = DefaultBackgroundSize, width = DefaultBackgroundSize, ...restProps } = props;
	return (
		<SvgIcon fill='none' height={height} width={width} {...restProps}>
			<g opacity='.414' filter='url(#background__a)'>
				<circle cx='1619' cy='1619' r='906.688' fill='url(#background__b)' />
			</g>
			<g opacity='.414' filter='url(#background__c)'>
				<circle cx='1619' cy='1619' r='1161.85' fill='url(#background__d)' />
			</g>
			<g opacity='.414' filter='url(#background__e)'>
				<circle cx='1619' cy='1619' r='1483' fill='url(#background__f)' />
			</g>
			<BackgroundInvaderGroup />
			<rect x='26' y='768' width='3186' height='1897' rx='8' fill='url(#background__g)' />
			<defs>
				<linearGradient
					id='background__b'
					x1='292.373'
					y1='606.077'
					x2='266.704'
					y2='3140.3'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#E4D1FF' />
					<stop offset='.107' stopColor='#C451FE' />
					<stop offset='.193' stopColor='#861FB9' />
					<stop offset='.404' stopColor='#4A0F70' />
					<stop offset='.704' stopColor='#005F33' stopOpacity='.01' />
					<stop offset='1' stopColor='#3ACD00' stopOpacity='.01' />
				</linearGradient>
				<linearGradient
					id='background__d'
					x1='-80.973'
					y1='321.015'
					x2='-113.866'
					y2='3568.43'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#E4D1FF' />
					<stop offset='.107' stopColor='#C451FE' />
					<stop offset='.193' stopColor='#861FB9' />
					<stop offset='.404' stopColor='#4A0F70' />
					<stop offset='.704' stopColor='#005F33' stopOpacity='.01' />
					<stop offset='1' stopColor='#3ACD00' stopOpacity='.01' />
				</linearGradient>
				<linearGradient
					id='background__f'
					x1='-550.862'
					y1='-37.761'
					x2='-592.847'
					y2='4107.27'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#E4D1FF' />
					<stop offset='.107' stopColor='#C451FE' />
					<stop offset='.193' stopColor='#861FB9' />
					<stop offset='.404' stopColor='#4A0F70' />
					<stop offset='.704' stopColor='#005F33' stopOpacity='.01' />
					<stop offset='1' stopColor='#3ACD00' stopOpacity='.01' />
				</linearGradient>
				<linearGradient id='background__g' x1='26' y1='768' x2='26' y2='2665' gradientUnits='userSpaceOnUse'>
					<stop stopColor='#002138' stopOpacity='.01' />
					<stop offset='1' stopColor='#002138' />
				</linearGradient>
				<filter
					id='background__a'
					x='576.398'
					y='576.398'
					width='2085.2'
					height='2085.2'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
					<feGaussianBlur stdDeviation='67.957' result='effect1_foregroundBlur_5_3' />
				</filter>
				<filter
					id='background__c'
					x='321.233'
					y='321.233'
					width='2595.53'
					height='2595.53'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
					<feGaussianBlur stdDeviation='67.957' result='effect1_foregroundBlur_5_3' />
				</filter>
				<filter
					id='background__e'
					x='.086'
					y='.086'
					width='3237.83'
					height='3237.83'
					filterUnits='userSpaceOnUse'
					colorInterpolationFilters='sRGB'
				>
					<feFlood floodOpacity='0' result='BackgroundImageFix' />
					<feBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
					<feGaussianBlur stdDeviation='67.957' result='effect1_foregroundBlur_5_3' />
				</filter>
			</defs>
		</SvgIcon>
	);
};
