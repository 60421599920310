import { CSSProperties, StyleSheet } from 'aphrodite';
import { CssSelectors, baseStyles } from '../../../../web/styles/styles';
import { brandPrimary } from '../../../styles/colors';

const flexCenter: CSSProperties = {
	alignItems: 'center',
	display: 'flex',
};

export const styleSheet = StyleSheet.create({
	container: {
		width: '50%',
	},
	file: {
		...flexCenter,
		width: '50%',
		...CssSelectors.allChildren({
			':first-child': {
				marginRight: 10,
			},
		}),
	},
	fileInput: {
		display: 'none',
	},
	fileName: {
		...baseStyles.truncateText,
	},
	removeIcon: {
		alignItems: 'center',
		background: brandPrimary,
		borderRadius: 20,
		display: 'flex',
		height: 18,
		justifyContent: 'center',
		paddingTop: 2,
		width: 18,
	},
	uploadedFile: {
		...flexCenter,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			'span'
		),
	},
});
