import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
	viewBox?: string;
}

export const LocationIcon: React.FC<IProps> = props => {
	const { className, fillColor, viewBox } = props;
	return (
		<SvgIcon className={`location-icon ${className || ''}`} height={7} viewBox={viewBox} width={7}>
			<path fillRule='evenodd' clipRule='evenodd' d='M7 0L4.2 7L3.5 3.5L0 2.8L7 0Z' fill={fillColor} />
		</SvgIcon>
	);
};
