import { ViewportContext } from '../../../hooks/useViewport';
import { ProgressBar } from '../../ProgressBar';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import { useContext } from 'react';
import * as React from 'react';

export interface IDemosListRowItem {
	name?: string;
	label?: string;
	goal: number;
	demos: number;
}

interface IProps {
	label: string;
	total: IDemosListRowItem;
	rows: IDemosListRowItem[];
}

export const DemosList: React.FC<IProps> = ({ label, total, rows }) => {
	// @ts-ignore
	const { isBelowSmall } = useContext(ViewportContext);

	const renderHeader = (
		<div className={css(styleSheet.demosRow, styleSheet.demosHeaderWrapper)}>
			<div className={css(styleSheet.demosHeader, styleSheet.demosColLabel)} title={label}>
				{label}
			</div>
			{isBelowSmall ? (
				<div className={css(styleSheet.demosHeader, styleSheet.demosColMobile)} title='Demos'>
					Demos
				</div>
			) : (
				<>
					<div className={css(styleSheet.demosHeader, styleSheet.demosColGoal)} title='Goal'>
						Goal
					</div>
					<div className={css(styleSheet.demosHeader, styleSheet.demosColActual)} title='Actual'>
						Actual
					</div>
				</>
			)}
			<div className={css(styleSheet.demosHeader, styleSheet.demosColProgress)} title='Progress'>
				Progress
			</div>
		</div>
	);

	const renderRows = rows.map(item => {
		return (
			<div
				className={css(styleSheet.demosRow, styleSheet.demosRowHighlight, styleSheet.demosColWrapper)}
				key={item?.name}
			>
				<div className={css(styleSheet.col, styleSheet.demosColLabel)} title={item.label}>
					{item.label}
				</div>
				{isBelowSmall ? (
					<div className={css(styleSheet.col, styleSheet.demosColMobile)} title=''>
						{item.demos}/<span className={css(styleSheet.demosColGoal)}>{item.goal}</span>
					</div>
				) : (
					<>
						<div className={css(styleSheet.col, styleSheet.demosColGoal)} title={item.goal.toString()}>
							{item.goal}
						</div>
						<div className={css(styleSheet.col, styleSheet.demosColActual)} title={item.demos.toString()}>
							{item.demos}
						</div>
					</>
				)}
				<div className={css(styleSheet.col, styleSheet.demosColProgress)} title='Progress'>
					<ProgressBar hideValues={true} goal={item.goal} amount={item.demos} isSmall={true} />
				</div>
			</div>
		);
	});

	const renderTotal = total && (
		<div className={css(styleSheet.demosRow, styleSheet.demosFooterWrapper)}>
			<div className={css(styleSheet.demosFooter, styleSheet.demosColLabel)} title='Total'>
				Total
			</div>
			{isBelowSmall ? (
				<div className={css(styleSheet.demosFooter, styleSheet.demosColMobile)} title=''>
					{total.demos} / <span className={css(styleSheet.demosColGoal)}>{total.goal}</span>
				</div>
			) : (
				<>
					<div className={css(styleSheet.demosFooter, styleSheet.demosColGoal)} title={total.goal.toString()}>
						{total.goal}
					</div>
					<div className={css(styleSheet.demosFooter, styleSheet.demosColActual)} title={total.demos.toString()}>
						{total.demos}
					</div>
				</>
			)}
			<div className={css(styleSheet.demosFooter, styleSheet.demosColProgress)} title='?'>
				<ProgressBar hideValues={true} goal={total.goal} amount={total.demos} isSmall={true} />
			</div>
		</div>
	);

	return (
		<div className={css(styleSheet.demosContainer)}>
			{renderHeader}
			{renderRows}
			{renderTotal}
		</div>
	);
};
