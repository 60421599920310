import { CssSelectors, FontFamilies, Layouts } from '../../../web/styles/styles';
import {
	backgroundGreen,
	borderColor,
	brandPrimary,
	brandPrimaryHover,
	brandPrimaryLight,
	brandSecondary,
	mention,
	mentionDark,
	nameCircles,
	titles,
	white,
} from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

const leftColSize = 420;

const pulseKeyframes = {
	'0%': {
		background: brandPrimaryLight,
	},
	'100%': {
		background: brandPrimaryLight,
	},
	'50%': {
		background: brandPrimaryHover,
	},
};

const pulseBorderKeyframes = {
	'0%': {
		borderLeft: `12px solid ${brandPrimaryLight}`,
	},
	'100%': {
		borderLeft: `12px solid ${brandPrimaryLight}`,
	},
	'50%': {
		borderLeft: `12px solid ${brandPrimaryHover}`,
	},
};

export const styleSheet = StyleSheet.create({
	actionButton: {
		'::after': {
			borderBottom: `13px solid transparent`,
			borderTop: `13px solid transparent`,
			content: '" "',
			left: '100%',
			position: 'absolute',
			top: 0,
			transition: 'border .2s ease-in-out',
		},
		':hover': {
			cursor: 'pointer',
		},
		border: 'none',
		boxShadow: `0 1px 4px 1px rgba(0, 0, 0, 0.2)`,
		color: '#fff',
		outline: 'none',
		padding: '4px 20px',
		position: 'relative',
		transition: 'background .2s ease-in-out',
	},
	addAction: {},
	addActionChild: {
		paddingBottom: 10,
	},
	addActionsContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		alignItems: 'flex-start',
		flexFlow: 'column',
		justifyContent: 'space-between',
		marginTop: 20,
	},
	addActionsContainerVertical: {
		...aidaBaseStyles.flexCol,
	},
	addPhoneAction: {
		minWidth: '100%',
	},
	backToList: {
		alignItems: 'center',
		color: white,
		display: 'flex',
		left: 0,
		position: 'absolute',
		top: -56,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			'span'
		),
		...CssSelectors.allChildren(
			{
				...CssSelectors.allChildren(
					{
						fill: white,
					},
					'polygon'
				),
			},
			'svg'
		),
	},
	body: {
		padding: '0 30px 20px',
	},
	callActionContainer: {},
	callingCardContainer: {
		borderRadius: 10,
		display: 'flex',
		height: '100%',
		minHeight: '100%',
		width: '100%',
	},
	companyInfoWrapper: {
		...CssSelectors.allChildren(
			{
				fontFamily: FontFamilies.regular,
				fontSize: '24px',
				fontWeight: 400,
			},
			'h1'
		),
		...CssSelectors.allChildren(
			{
				':hover': {
					color: brandPrimary,
					cursor: 'pointer',
				},
				color: brandPrimaryHover,
				display: 'block',
				fontSize: '14px',
				marginTop: 10,
				overflow: 'hidden',
				textDecoration: 'none',
				textOverflow: 'ellipsis',
			},
			'a'
		),
	},
	companyLink: {
		':hover': {
			textDecoration: 'underline',
		},
		color: brandSecondary,
		cursor: 'pointer',
		fontSize: 12,
	},
	companyLinksContainer: {
		...Layouts.horizontalStack(20),
		alignItems: 'flex-end',
	},
	editAddAction: {
		padding: '10px 0 30px',
	},
	editing: {
		paddingTop: 35,
		...CssSelectors.allDescendants(
			{
				marginRight: 10,
			},
			'.button'
		),
	},
	fieldContainer: {
		padding: '7px 0',
		...CssSelectors.allChildren(
			{
				color: '#858585',
				display: 'block',
				fontSize: '14px',
				paddingBottom: 7,
				textTransform: 'capitalize',
			},
			'label'
		),
	},
	headerButtonsContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'space-between',
		padding: '20px 0 20px 30px',
		width: '100%',
	},
	headerButtonsRightContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'flex-end',
		width: '100%',
	},
	leftCol: {
		borderRight: `1px solid ${borderColor}`,
		maxWidth: leftColSize,
		minWidth: leftColSize,
	},
	leftColHeader: {
		alignItems: 'flex-start',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '20px 20px 10px 0',
		...CssSelectors.allChildren(
			{
				flexGrow: 1,
			},
			':first-child'
		),
		...CssSelectors.allChildren(
			{
				maxWidth: 40,
				minWidth: 40,
				textAlign: 'right',
			},
			':last-child'
		),
	},
	link: {
		color: brandPrimary,
	},
	location: {
		fontFamily: FontFamilies.bold,
		...aidaBaseStyles.flexHorizontalCenter,
		color: titles,
		fontSize: 14,
		justifyContent: 'flex-end',
		marginTop: 10,
	},
	meetingActionContainer: {
		background: backgroundGreen,
		borderRadius: 6,
		marginBottom: 10,
		padding: 14,
	},
	meetingPrompt: {
		color: '#4F7726',
		fontSize: 11,
		letterSpacing: 1.1,
		textTransform: 'uppercase',
	},
	meetingTime: {
		color: brandPrimary,
	},
	nextButton: {
		'::after': {
			borderLeft: `12px solid ${brandPrimaryLight}`,
		},
		':hover': {
			'::after': {
				borderLeft: `12px solid ${brandPrimary}`,
			},
			background: brandPrimary,
		},
		background: brandPrimaryLight,
	},
	pulse: {
		':not(:hover)': {
			'::after': {
				animationDuration: '2s',
				animationIterationCount: 'infinite',
				animationName: [pulseBorderKeyframes],
			},
			animationDuration: '2s',
			animationIterationCount: 'infinite',
			animationName: [pulseKeyframes],
		},
	},
	rightCol: {
		maxWidth: `calc(100% - ${leftColSize}px)`,
		minWidth: `calc(100% - ${leftColSize}px)`,
	},
	rightColHeader: {
		...aidaBaseStyles.flexCol,
		height: 60,
	},
	skipButton: {
		'::after': {
			borderLeft: `12px solid ${nameCircles}`,
		},
		':hover': {
			'::after': {
				borderLeft: `12px solid ${mentionDark}`,
			},
			background: mentionDark,
		},
		background: nameCircles,
	},
	socialContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'space-between',
		padding: '15px 0 20px',
	},
	tagsContainer: {
		paddingBottom: 10,
	},
	timelineContainer: {
		position: 'relative',
	},
	timelineHeader: {
		background: mention,
		padding: '15px 30px',
	},
});
