import { lightOrange, orange } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	noneFoundContainer: {
		background: lightOrange,
		borderRadius: 6,
		color: orange,
		fontSize: '12px',
		padding: 20,
		textAlign: 'center',
	},
});
