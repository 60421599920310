import { FontFamilies } from '../../../web/styles/styles';
import { titles } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	button: {
		':disabled:hover': {
			cursor: 'not-allowed',
		},
		position: 'relative',
	},
	disabled: {
		opacity: 0.5,
	},
	graphic: {
		...aidaBaseStyles.flexCenter,
		height: '100%',
		left: 0,
		padding: 10,
		position: 'absolute',
		top: 0,
		width: '100%',
	},
	hex: {},
	hexButtonContainer: {
		...aidaBaseStyles.flexCol,
		alignItems: 'center',
	},
	pointsBubble: {
		bottom: 'calc(100% - 50px)',
		position: 'absolute',
		right: 'calc(100% - 65px)',
		zIndex: 5,
	},
	text: {
		color: titles,
		fontFamily: FontFamilies.bold,
		fontSize: '11px',
		letterSpacing: '1.1px',
		maxWidth: 90,
		minHeight: 50,
		textAlign: 'center',
		textTransform: 'uppercase',
	},
});
