import { useLambda } from '../../models/hooks/useLambda';
import { QueueLeadNoteSlider } from '../components/QueueLeadNoteSlider';
import { useQueue } from '../hooks/queue';
import { AidaNoteViewModel } from '../viewModels/note';
import { DealModalContext } from './dealModal';
import { DialerPhoneCallStatus, useTelephony } from './telephony';
import { FC, createContext, useContext, useEffect, useState } from 'react';
import * as React from 'react';

export interface IQueueLeadNoteContext {
	defaultNote: AidaNoteViewModel;
	setDefaultNote: React.Dispatch<React.SetStateAction<AidaNoteViewModel>>;
	showQueueLeadNote: boolean;
	setShowQueueLeadNote: React.Dispatch<React.SetStateAction<boolean>>;
	setShowQueueLeadNoteLambda: (show: boolean) => () => void;
}

// @ts-ignore
export const QueueLeadNoteContext = createContext<IQueueLeadNoteContext>(null);

export const QueueLeadNoteProvider: FC = ({ children }) => {
	const queue = useQueue();
	const [showQueueLeadNote, setShowQueueLeadNote, setShowQueueLeadNoteLambda] = useLambda(false);
	// @ts-ignore
	const [defaultNote, setDefaultNote] = useState<AidaNoteViewModel>(null);

	const { showDealModal } = useContext(DealModalContext);

	useEffect(() => {
		if (showDealModal) {
			setShowQueueLeadNote(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showDealModal]);

	useEffect(() => {
		setShowQueueLeadNote(!!defaultNote);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultNote]);

	const { phoneCalls, phoneCall, phoneCallStatus, isPendingOutcome } = useTelephony();
	useEffect(() => {
		if (!!phoneCalls.currentCall || isPendingOutcome) {
			setShowQueueLeadNote(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [phoneCalls?.currentCall, isPendingOutcome]);

	// only show editor if there is a company loaded and you are not in a call with a different company
	const isConnected = !!phoneCall && phoneCallStatus !== DialerPhoneCallStatus.Ringing;
	const isDifferentCompany = isConnected && (phoneCall.companyId !== queue.companyId || !phoneCall.companyId);
	const showNoteEditor = !!queue.companyId && !isDifferentCompany;

	return (
		<QueueLeadNoteContext.Provider
			value={{
				defaultNote,
				setDefaultNote,
				setShowQueueLeadNote,
				setShowQueueLeadNoteLambda,
				showQueueLeadNote,
			}}
		>
			{children}
			{showNoteEditor && (
				<QueueLeadNoteSlider
					defaultNote={defaultNote}
					isExpanded={showQueueLeadNote}
					toggleExpand={setShowQueueLeadNote}
				/>
			)}
		</QueueLeadNoteContext.Provider>
	);
};

export const useQueueLeadNote = () => {
	return React.useContext<IQueueLeadNoteContext>(QueueLeadNoteContext);
};
