import { mention, titles } from '../../../styles/colors';
import { aidaBaseStyles } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	dot: {
		borderRadius: '50%',
		height: 16,
		marginRight: 10,
		width: 16,
	},
	optionContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		':disabled': {
			opacity: 0.4,
		},
		':hover': {
			background: mention,
			cursor: 'pointer',
		},
		color: titles,
		padding: '7px 20px',
		width: '100%',
	},
	outcomesContainer: {
		padding: '20px 0 10px',
		position: 'relative',
	},
	selected: {
		background: mention,
	},
	shortcutsOptionsContainer: {},
	smallArrow: {
		fill: titles,
		marginLeft: 5,
		transform: 'rotateZ(90deg)',
	},
	title: {
		color: '#aaa',
		fontSize: '11px',
		letterSpacing: '1.2px',
		marginBottom: 5,
		textAlign: 'center',
		textTransform: 'uppercase',
	},
});
