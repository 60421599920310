import { CSSProperties, StyleSheet } from 'aphrodite';
import { CssSelectors } from '../../../web/styles/styles';
import { brandPrimary, brandSecondary, error, titles, white } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	automationIcon: {
		fill: brandSecondary,
		height: 23,
	},
	automationPreviewContainer: {
		marginBottom: -10,
		paddingTop: 10,
	},
	close: {
		marginTop: 4,
	},
	closeIcon: {
		height: 14,
		width: 14,
	},
	container: {
		...aidaBaseStyles.cardShadow,
		background: white,
		borderRadius: 5,
		left: 0,
		position: 'fixed',
		top: 0,
		transform: 'translate3d(0,0,0)',
		willChange: 'top',
	},
	ctaContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		paddingTop: 20,
		...CssSelectors.allChildren(
			{
				marginRight: 10,
			},
			':not(:last-child)'
		),
	},
	draggableButton: {
		marginRight: 10,
	},
	draggableIcon: {
		height: 15,
		marginRight: 3,
		marginTop: 3,
	},
	editor: {
		minHeight: 400,
	},
	editorContainer: {
		...CssSelectors.allDescendants(
			{
				color: error,
			},
			'.required'
		),
		...CssSelectors.allDescendants(aidaBaseStyles.flexCol as CSSProperties, '.email-composer-body'),
		...CssSelectors.allDescendants(
			{
				flexGrow: 1,
			},
			'.body-field-content'
		),
		...CssSelectors.allDescendants(
			{
				flexGrow: 0,
			},
			'.email-field-error'
		),
	},
	emailEditorContainer: {
		maxHeight: '80vh',
		minWidth: 794,
		overflowY: 'auto',
		padding: 20,
	},
	emailTemplatesSelect: {
		alignItems: 'center',
		background: '#f9f9f9',
		display: 'flex',
		maxWidth: '100%',
		width: '100%',
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'.tinyPopover-anchor'
		),
	},
	emailTemplatesSelectContainer: {
		marginBottom: 10,
		minHeight: 40,
	},
	fieldContainer: {
		marginBottom: 10,
	},
	header: {
		...aidaBaseStyles.flexHorizontalCenter,
		background: brandPrimary,
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		color: white,
		height: 30,
		justifyContent: 'space-between',
		padding: '0 14px',
		position: 'relative',
	},
	icon: {
		height: 17,
		width: 17,
	},
	recipientList: {
		maxWidth: 560,
		minWidth: 560,
	},
	title: {
		fontSize: 16,
	},
	trigger: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 14,
		...CssSelectors.allDescendants(
			{
				marginLeft: 10,
			},
			'span'
		),
	},
});
