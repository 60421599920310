import * as React from 'react';
import { EmailAddress } from '../../../../extViewmodels';
import { IRepeaterItem, Repeater } from './Repeater';
import { TextFieldType } from './TextFieldType';

interface IProps {
	onChange: (values: EmailAddress[]) => void;
	values: EmailAddress[];
}

export const EmailsRepeater: React.FC<IProps> = ({ values, onChange }) => {
	const handleRepeaterChange = (newValues: IRepeaterItem<EmailAddress>[]) => {
		onChange(newValues.map(i => i.content));
	};
	return (
		<Repeater<EmailAddress> initialValues={values} onChange={handleRepeaterChange}>
			{(item, handleContentChange, itemIndex, items) => {
				const handleChange = ({ value }: EmailAddress) => handleContentChange({ value });
				return (
					<TextFieldType
						value={item.content?.value}
						label={`Email ${items?.length > 1 ? itemIndex + 1 : ''}`}
						name={`email-${item.uuid}`}
						onChange={handleChange}
					/>
				);
			}}
		</Repeater>
	);
};
