import { IUser } from '../../../extViewmodels';
import { SortOptions } from '../../components/LeaderboardComponents/components/ListHeader';
import { useErrorMessages, useGamification } from '../../hooks';
import { formatOptional, formatUser } from '../utils/criteriaBuilder';
import { useStatesOnFetch } from '../utils/useStatesOnFetch';
import {
	ILeaderboard,
	ILeaderboardFilterCriteria,
	LeaderboardFilterCriteriaProperty,
} from './../../viewModels/gamification';

export interface ILeaderboardFilter {
	Manager?: IUser;
	Team?: string;
	User?: IUser;
	UserRole?: string;
	Vertical?: string;
}

function filterToCriteria(filter: ILeaderboardFilter): ILeaderboardFilterCriteria[] {
	const criteria: ILeaderboardFilterCriteria[] = [];

	criteria.push(
		// @ts-ignore
		formatUser<LeaderboardFilterCriteriaProperty>(LeaderboardFilterCriteriaProperty.Manager, filter?.Manager)
	);
	criteria.push(
		// @ts-ignore
		formatOptional<LeaderboardFilterCriteriaProperty>(LeaderboardFilterCriteriaProperty.Team, filter?.Team)
	);
	// @ts-ignore
	criteria.push(formatUser<LeaderboardFilterCriteriaProperty>(LeaderboardFilterCriteriaProperty.User, filter?.User));
	criteria.push(
		// @ts-ignore
		formatOptional<LeaderboardFilterCriteriaProperty>(LeaderboardFilterCriteriaProperty.UserRole, filter?.UserRole)
	);
	criteria.push(
		// @ts-ignore
		formatOptional<LeaderboardFilterCriteriaProperty>(LeaderboardFilterCriteriaProperty.Vertical, filter?.Vertical)
	);

	return criteria.filter(item => !!item);
}

interface IParams {
	asc?: boolean;
	filter: ILeaderboardFilter;
	sortBy: SortOptions;
}

export const useLeaderboard = () => {
	const { state, handleWorking, handleResponse, reset } = useStatesOnFetch<ILeaderboard>();

	const errorMessages = useErrorMessages();

	const gamification = useGamification();

	const get = async ({ asc, filter, sortBy }: IParams) => {
		handleWorking();
		const criteria = filterToCriteria(filter);
		try {
			// @ts-ignore
			await gamification.getUserLeaderboardFiltered(criteria, sortBy, asc);
			// @ts-ignore
			const response = { value: gamification.userLeaderboard };
			handleResponse(response);
			return response;
		} catch (error) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
	};

	return {
		...state,
		empty: !state?.data,
		get,
		reset,
		status: state.status,
	};
};
