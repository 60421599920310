import { CheckmarkIcon } from '../../../../web/components/svgs/icons/CheckmarkIcon';
import { PlusIcon } from '../../../../web/components/svgs/icons/PlusIcon';
import { SmileyIcon } from '../../../../web/components/svgs/icons/SmileyIcon';
import { brandPrimaryHover, white } from '../../../styles/colors';
import { TransparentButton } from '../../TransparentButton';
import { Group } from '../../generics/Group';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	disabled: boolean;
	isAdding: boolean;
	onClickToAdd: () => void;
	onClickToCancel: () => void;
}

export const AddPropertiesButton: React.FC<IProps> = ({ disabled, isAdding, onClickToAdd, onClickToCancel }) => {
	return (
		<Group fullWidth={false} vCentered={true} vertical={true}>
			<div
				className={css(styleSheet.addPlusButton, (isAdding || disabled) && styleSheet.addPlusButtonAdding)}
				// @ts-ignore
				onClick={isAdding || disabled ? null : onClickToAdd}
			>
				{isAdding ? (
					<CheckmarkIcon fillColor={white} />
				) : disabled ? (
					<SmileyIcon fillColor={disabled ? brandPrimaryHover : white} />
				) : (
					<PlusIcon fillColor={white} />
				)}
			</div>
			<TransparentButton className={css(styleSheet.cancelButton)} onClick={onClickToCancel}>
				Go back and cancel
			</TransparentButton>
		</Group>
	);
};
