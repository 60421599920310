import { SvgIcon } from '../../../../web/components/svgs/icons/SvgIcon';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	isConnected: boolean;
	isMuted: boolean;
	isRinging: boolean;
	onClickToAccept: () => void;
	onClickToDisconnect: () => void;
	toggleMuted: () => void;
	onClickToReject: () => void;
}

export const CallActions: React.FC<IProps> = ({
	isConnected,
	isMuted,
	isRinging,
	onClickToAccept,
	onClickToDisconnect,
	toggleMuted,
	onClickToReject,
}) => {
	const acceptIcon = (
		<svg viewBox='0 0 48 48'>
			<path d='M0 0h48v48H0z' fill='none' />
			<path d='M13.25 21.59c2.88 5.66 7.51 10.29 13.18 13.17l4.4-4.41c.55-.55 1.34-.71 2.03-.49C35.1 30.6 37.51 31 40 31c1.11 0 2 .89 2 2v7c0 1.11-.89 2-2 2C21.22 42 6 26.78 6 8c0-1.11.9-2 2-2h7c1.11 0 2 .89 2 2 0 2.49.4 4.9 1.14 7.14.22.69.06 1.48-.49 2.03l-4.4 4.42z' />
		</svg>
	);

	const rejectIcon = (
		<svg viewBox='0 0 48 48'>
			<path d='M0 0h48v48H0z' fill='none' />
			<path d='M24 18c-3.21 0-6.3.5-9.2 1.44v6.21c0 .79-.46 1.47-1.12 1.8-1.95.98-3.74 2.23-5.33 3.7-.36.35-.85.57-1.4.57-.55 0-1.05-.22-1.41-.59L.59 26.18c-.37-.37-.59-.87-.59-1.42 0-.55.22-1.05.59-1.42C6.68 17.55 14.93 14 24 14s17.32 3.55 23.41 9.34c.37.36.59.87.59 1.42 0 .55-.22 1.05-.59 1.41l-4.95 4.95c-.36.36-.86.59-1.41.59-.54 0-1.04-.22-1.4-.57-1.59-1.47-3.38-2.72-5.33-3.7-.66-.33-1.12-1.01-1.12-1.8v-6.21C30.3 18.5 27.21 18 24 18z' />
		</svg>
	);

	const mutedIcon = (
		<SvgIcon height={48} width={48}>
			<path d='M24 28c3.31 0 5.98-2.69 5.98-6L30 10c0-3.32-2.68-6-6-6-3.31 0-6 2.68-6 6v12c0 3.31 2.69 6 6 6zm10.6-6c0 6-5.07 10.2-10.6 10.2-5.52 0-10.6-4.2-10.6-10.2H10c0 6.83 5.44 12.47 12 13.44V42h4v-6.56c6.56-.97 12-6.61 12-13.44h-3.4z' />
			<path d='M0 0h48v48H0z' fill='none' />
		</SvgIcon>
	);

	const unmutedIcon = (
		<SvgIcon height={48} width={48}>
			<path d='M0 0h48v48H0zm0 0h48v48H0z' fill='none' />
			<path d='M38 22h-3.4c0 1.49-.31 2.87-.87 4.1l2.46 2.46C37.33 26.61 38 24.38 38 22zm-8.03.33c0-.11.03-.22.03-.33V10c0-3.32-2.69-6-6-6s-6 2.68-6 6v.37l11.97 11.96zM8.55 6L6 8.55l12.02 12.02v1.44c0 3.31 2.67 6 5.98 6 .45 0 .88-.06 1.3-.15l3.32 3.32c-1.43.66-3 1.03-4.62 1.03-5.52 0-10.6-4.2-10.6-10.2H10c0 6.83 5.44 12.47 12 13.44V42h4v-6.56c1.81-.27 3.53-.9 5.08-1.81L39.45 42 42 39.46 8.55 6z' />
		</SvgIcon>
	);

	const receiving = (
		<>
			<button
				className={css([styleSheet.dialerCallAction, styleSheet.dialerCallActionConnected])}
				type='button'
				onClick={onClickToAccept}
			>
				{acceptIcon}
			</button>
			<button
				className={css([styleSheet.dialerCallAction, styleSheet.dialerCallActionDisconnected])}
				type='button'
				onClick={onClickToReject}
			>
				{rejectIcon}
			</button>
		</>
	);

	const inCall = (
		<>
			<button
				className={css([styleSheet.dialerCallAction, styleSheet.dialerCallActionDisconnected])}
				type='button'
				onClick={onClickToDisconnect}
			>
				{rejectIcon}
			</button>
			<button
				className={css([
					styleSheet.dialerCallAction,
					isMuted ? styleSheet.dialerCallActionUnmute : styleSheet.dialerCallActionMute,
				])}
				type='button'
				onClick={toggleMuted}
			>
				{isMuted ? unmutedIcon : mutedIcon}
			</button>
		</>
	);

	if (isRinging) {
		return receiving;
	}

	if (!isConnected) {
		return null;
	}

	return inCall;
};
