import { useState } from 'react';
import { ISelectOption } from '../../../web/components/Select';
import {
	IInterval,
	IWeeklySchedule,
	OwnershipEnforcement,
	RecurrenceType,
	TurnThisOnOptions,
} from '../../viewModels/leadrules';
import { IRangeValues, IScheduleInterval } from './components/ScheduleModal';

interface IWeekDays {
	enabled: boolean;
	index: number;
	label: string;
	range: IRangeValues[];
}

// @ts-ignore
export const wordify = (str: string) => str.match(/[A-Z][a-z]+/g).join(' ');

export const arrayOfNumbers = (value: number) => Array.from(Array(value).keys());

export const useTagList: (
	initialState: string[],
	allowDuplicates?: boolean
) => [
	tags: string[],
	onTagAdded: (tag: string) => void,
	onTagRemoved: (tag: string) => void,
	setTags: (tags: string[]) => void,
] = (initialState, allowDuplicates = true) => {
	const [tags, setTags] = useState(initialState);
	const onTagRemoved = (tag: string) => {
		setTags(tags.filter(x => x !== tag));
	};

	const onTagAdded = (tag: string) => {
		const shouldAdd = allowDuplicates ? true : !tags.find(t => t === tag);
		if (shouldAdd) {
			setTags(tags.concat(tag));
		}
	};

	return [tags, onTagAdded, onTagRemoved, setTags];
};

export const turnThisOnOptions: ISelectOption<number>[] = [
	{
		dataContext: 0,
		id: TurnThisOnOptions.RightAway,
		text: 'Right away',
	},
	{
		dataContext: 1,
		id: TurnThisOnOptions.Scheduled,
		text: 'Based on a schedule',
	},
];

export const hourOptions: ISelectOption<number>[] = arrayOfNumbers(11).map(x => {
	const isFirstIndex = x === 0;
	return {
		dataContext: x,
		id: isFirstIndex ? RecurrenceType.AlwaysOn : `${x}-hr`,
		text: isFirstIndex ? 'Forever' : `${x} hr${x > 1 ? 's' : ''}`,
	};
});

export enum TimeOptionsAMPM {
	Am = 'AM',
	Pm = 'PM',
}

export const formatMinutesToAMPM = (minutes: number) => {
	let hours = minutes / 60;
	const ampm = hours >= 12 ? TimeOptionsAMPM.Pm : TimeOptionsAMPM.Am;
	hours %= 12;
	hours = hours || 12;
	hours = ampm === TimeOptionsAMPM.Am && hours === 12 ? 0 : hours;
	return {
		dataContext: minutes,
		id: `${hours < 10 ? '0' + hours : hours}-${ampm}`,
		text: `${hours < 10 ? '0' + hours : hours}:00 ${ampm}`,
	};
};

export const generateTimeOptions = (): ISelectOption<number>[] =>
	arrayOfNumbers(24).map(value => {
		const minutes = value * 60;
		return formatMinutesToAMPM(minutes);
	});

export const formatFromTimeOptions = (rangeItem: IRangeValues) => {
	return rangeItem?.end?.id.includes(TimeOptionsAMPM.Am)
		? rangeItem?.end.dataContext * 60
		: (rangeItem?.end.dataContext + 12) * 60;
};

export const prepareFromWeeklySchedule = (item: IWeeklySchedule, index: number) => {
	if (item?.intervals) {
		return {
			enabled: true,
			index,
			label: weekDays[index].label,
			range: [...item.intervals].map(interval => ({
				// @ts-ignore
				end: formatMinutesToAMPM(interval?.endMinutes),
				// @ts-ignore
				start: formatMinutesToAMPM(interval?.startMinutes),
			})),
		};
	}
	return {
		enabled: false,
		index,
		label: weekDays[index].label,
		range: weekDays[index].range,
	};
};

export const prepareToWeeklySchedule = (item: IScheduleInterval) => {
	if (item?.range && item?.enabled) {
		const minutes = [...(item?.range ?? [])]?.map(rangeItem => {
			const interval: IInterval = {};
			if (rangeItem?.start) {
				const start = rangeItem?.start.dataContext;
				interval.startMinutes = start;
			}
			if (rangeItem?.end) {
				const end = rangeItem?.end.dataContext;
				interval.endMinutes = end;
			}
			return interval;
		});
		return { intervals: minutes };
	}
	return null;
};

export const friendlyWeeklySchedule = (item: IWeeklySchedule, index: number, all: IWeeklySchedule[]) => {
	if (!item?.intervals) {
		return null;
	}
	if (item?.intervals) {
		const intervals = [...item.intervals];
		const timeRanges = ` (${intervals
			.map(
				interval =>
					// @ts-ignore
					// @ts-ignore
					`${formatMinutesToAMPM(interval?.startMinutes)?.text}-${formatMinutesToAMPM(interval?.endMinutes)?.text}`
			)
			.join(', ')})`;
		return `${weekDays[index].label}${all?.filter(i => !!i).length <= 1 ? timeRanges : ''}`;
	}
	return `${weekDays[index].label}`;
};

export const ownershipLabels: any = {
	LeadOwner: 'Lead Owner',
	LeadVertical: 'Lead Pool',
};

export const ownershipEnforcementOptions: ISelectOption<OwnershipEnforcement>[] = Object.keys(OwnershipEnforcement).map(
	x => {
		return {
			dataContext: x as OwnershipEnforcement,
			id: x,
			text: ownershipLabels[x],
		};
	}
);

const weekDaysDefaultRange = [
	{
		end: generateTimeOptions()[17],
		start: generateTimeOptions()[8],
	},
];

export const weekDays: IWeekDays[] = [
	{
		enabled: false,
		index: 0,
		label: 'Sun',
		range: weekDaysDefaultRange,
	},
	{
		enabled: false,
		index: 1,
		label: 'Mon',
		range: weekDaysDefaultRange,
	},
	{
		enabled: false,
		index: 2,
		label: 'Tues',
		range: weekDaysDefaultRange,
	},
	{
		enabled: false,
		index: 3,
		label: 'Wed',
		range: weekDaysDefaultRange,
	},
	{
		enabled: false,
		index: 4,
		label: 'Thur',
		range: weekDaysDefaultRange,
	},
	{
		enabled: false,
		index: 5,
		label: 'Fri',
		range: weekDaysDefaultRange,
	},
	{
		enabled: false,
		index: 6,
		label: 'Sat',
		range: weekDaysDefaultRange,
	},
];

export const minDaysOptions: ISelectOption<number>[] = [
	{
		// @ts-ignore
		dataContext: null,
		id: 'default',
		text: 'Default',
	},
	{
		dataContext: 1,
		id: '1-days',
		text: '1 day',
	},
	{
		dataContext: 2,
		id: '2-days',
		text: '2 days',
	},
	{
		dataContext: 7,
		id: '7-days',
		text: '1 week',
	},
	{
		dataContext: 14,
		id: '14-days',
		text: '2 weeks',
	},
	{
		dataContext: 30,
		id: '1-month',
		text: '1 month',
	},
	{
		dataContext: 90,
		id: '3-month',
		text: '3 months',
	},
	{
		// @ts-ignore
		dataContext: null,
		id: 'custom',
		text: 'Custom',
	},
];

export const numberRange: ISelectOption<number>[] = [
	{
		dataContext: 1,
		id: '1',
		text: '1',
	},
	{
		dataContext: 2,
		id: '2',
		text: '2',
	},
	{
		dataContext: 3,
		id: '3',
		text: '3',
	},
	{
		dataContext: 4,
		id: '4',
		text: '4',
	},
	{
		dataContext: 5,
		id: '5',
		text: '5',
	},
	{
		dataContext: 6,
		id: '6',
		text: '6',
	},
	{
		dataContext: 7,
		id: '7',
		text: '7',
	},
	{
		dataContext: 8,
		id: '8',
		text: '8',
	},
	{
		dataContext: 9,
		id: '9',
		text: '9',
	},
	{
		dataContext: 10,
		id: '10',
		text: '10',
	},
	{
		dataContext: 20,
		id: '20',
		text: '20',
	},
	{
		dataContext: 30,
		id: '30',
		text: '30',
	},
	{
		dataContext: 40,
		id: '40',
		text: '40',
	},
	{
		dataContext: 50,
		id: '50',
		text: '50',
	},
	{
		dataContext: 60,
		id: '60',
		text: '60',
	},
	{
		dataContext: 70,
		id: '70',
		text: '70',
	},
	{
		dataContext: 80,
		id: '80',
		text: '80',
	},
	{
		dataContext: 90,
		id: '90',
		text: '90',
	},
	{
		dataContext: 100,
		id: '100',
		text: '100',
	},
	{
		// @ts-ignore
		dataContext: null,
		id: 'default',
		text: 'No Limit',
	},
	{
		// @ts-ignore
		dataContext: null,
		id: 'custom',
		text: 'Custom',
	},
];
