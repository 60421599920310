import { Button } from '../../../../web/components/Button';
import { Group } from '../../leadView/components/Group';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	onClickToClear: () => void;
	onClickToSave: () => void;
}

export const SelectedTagsContainer: React.FC<IProps> = ({ children, onClickToClear, onClickToSave }) => {
	return (
		<>
			<div className={css(styleSheet.addPropertiesRightHeader)}>Newly added properties - please save to continue</div>
			<div className={css(styleSheet.addPropertiesRightBody)}>
				<Group vertical={true} spacingSize={20}>
					{children}
				</Group>
			</div>
			<Group className={css(styleSheet.addPropertiesRightFooter)}>
				<Button onClick={onClickToSave} label='Save' />
				<Button onClick={onClickToClear} label='Clear All' kind='reverse' />
			</Group>
		</>
	);
};
