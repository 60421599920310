import * as Api from '@ViewModels';
import { SlotMachineType } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { SlotMachineViewModel } from '../../../viewModels/slotMachines';
import { LegendPager, PrizeRow } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	machineType: SlotMachineType;
	slotMachine?: SlotMachineViewModel;
	styles?: StyleDeclarationValue[];
}

const getBackgroundClass = (type: SlotMachineType) => {
	switch (type) {
		case SlotMachineType.GoldRush: {
			return styleSheet.goldRushContainer;
		}
		case SlotMachineType.BoozeCruise: {
			return styleSheet.daisyContainer;
		}
		default: {
			return styleSheet.highSoreContainer;
		}
	}
};

export const SlotMachinePrizeLegend: React.FC<IProps> = observer(props => {
	const { className, styles = [], machineType, slotMachine } = props;
	// four per page
	// useMemo prevents LegendPager from invalidating children and resetting some animations.
	const pages = React.useMemo(() => {
		const payLines = slotMachine?.game?.config.payTable.filter(x => x.enabled);
		const result: Api.ISlotMachinePayLine[][] = Array.from({ length: Math.ceil((payLines?.length || 0) / 4) });
		if (result.length > 0) {
			result.forEach((_, i) => {
				// @ts-ignore
				result[i] = payLines.slice(i * 4, i * 4 + 4);
			});
		}

		return result?.map((payLinesPerPage, i) => {
			return (
				<ul className={css(styleSheet.prizeList)} key={`page-${i}`}>
					{payLinesPerPage.map((payLine, j) => {
						return (
							<li key={`pay-line-${j}`}>
								<PrizeRow
									symbols={Array.from<Api.SlotMachineSymbol>({ length: payLine.symbolCount }).fill(payLine.symbol)}
								>
									{`= ${payLine.prize}`}
								</PrizeRow>
							</li>
						);
					})}
				</ul>
			);
		});
	}, [slotMachine?.game?.config.payTable]);

	const spinCount = slotMachine?.spinsRemaining?.length || 0;

	return (
		<div
			className={`${css(styleSheet.container, getBackgroundClass(machineType), ...styles)} slot-machine-prize-legend ${
				className || ''
			}`}
		>
			<LegendPager
				styles={[
					styleSheet.legendContent,
					// @ts-ignore
					machineType === SlotMachineType.BoozeCruise ? styleSheet.legendContentDaisy : null,
				]}
			>
				{pages}
			</LegendPager>
			{spinCount > 0 ? (
				<span className={css(styleSheet.legendPagerSpinsLeft)}>{`${spinCount} spin${
					spinCount > 1 ? 's' : ''
				} remaining`}</span>
			) : null}
		</div>
	);
});
