import { XMarkIcon } from '../../../../../web/components/svgs/icons/XMarkIcon';
import { TransparentButton } from '../../../TransparentButton';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	onClick: () => void;
}

export const ClearButton: React.FC<IProps> = ({ onClick }) => {
	return (
		<TransparentButton className={`${css(styleSheet.clearButton)} common-autocomplete-clear-button`} onClick={onClick}>
			<XMarkIcon />
		</TransparentButton>
	);
};
