import * as React from 'react';
import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import { green, success } from '../../../../styles/colors';

interface IProps {
	className?: string;
	fillColor?: string;
	viewBox?: string;
}

export const PepperIcon: React.FC<IProps> = props => {
	const { className, fillColor = green } = props;
	return (
		<SvgIcon className={`pepper-icon ${className || ''}`} height={24} viewBox='0 0 261.16 268.16' width={24}>
			<path
				d='M208.44,66.72a5.89,5.89,0,0,1,9.17,4.88V89.5l16.53-10.91a5.89,5.89,0,0,1,9.11,4.48L245,106.35l10.15-8.81c0-.13,0-.27-.05-.41h0L257,90.88l2.72-6.81a19.84,19.84,0,0,0-11.06-25.8.82.82,0,0,1-.4-1.17l7-14.8a48.22,48.22,0,0,0,.86-39.21,5,5,0,0,0-9-.65L220.81,46.38a.81.81,0,0,1-1,.33l-8.4-3.35A18.29,18.29,0,0,0,189,50.87L191.67,78Z'
				fill={fillColor || success}
			/>
			<path
				d='M240.63,122.79a6,6,0,0,1-2.26-.44,5.86,5.86,0,0,1-3.62-5l-1.83-24.76-17.19,11.34A5.88,5.88,0,0,1,206.61,99V81.22l-15.9,10.71a5.88,5.88,0,0,1-9.14-4.29l-3-30.24c-14.34,6.81-26,18.47-31.66,29.79-14.8,29.61-20.7,63.27-40.88,83.45s-72.93,24.79-91.52,24.82A14.54,14.54,0,0,0,2.44,218.07c1.37,2,34.31,50.09,113.88,50.09,53.73,0,100.89-36.36,126.13-97.28,7.83-18.91,13.05-40.32,13.39-59.4l-11.37,9.87A5.84,5.84,0,0,1,240.63,122.79Z'
				fill={fillColor || success}
			/>
		</SvgIcon>
	);
};
