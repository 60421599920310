import { StyleSheet } from 'aphrodite';
import { CssSelectors, Layouts } from '../../../web/styles/styles';
import {
	brandPrimary,
	brandPrimaryHover,
	brandPrimaryLight,
	grayIconFill,
	mention,
	mentionDark,
	titles,
} from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	automationBanner: {
		padding: '5px 0',
	},
	engagementsTypesSelect: {
		background: mentionDark,
		border: 'none',
		borderRadius: 30,
		minWidth: 250,
	},
	engagementsTypesSelectContainer: {
		marginRight: 65,
	},
	engagementTypesSelectTrigger: {
		color: titles,
	},
	fab: {
		position: 'absolute',
		right: 20,
		top: 20,
		zIndex: 1,
	},
	hexButton: {
		...CssSelectors.allDescendants(
			{
				fontSize: '16px',
				lineHeight: '1.1em',
			},
			'.text'
		),
		...CssSelectors.allDescendants(
			{
				minHeight: 50,
			},
			'.no-wrap'
		),
		height: 36,
		transform: 'scale(0.3) translate(0px, -55px);',
		width: 36,
	},
	lastContact: {
		textAlign: 'right',
	},
	lastInteractionContainer: {
		alignItems: 'center',
		display: 'flex',
		fontSize: '12px',
		...Layouts.horizontalStack(10),
		...CssSelectors.allChildren(
			{
				color: grayIconFill,
				letterSpacing: '1.2px',
				marginRight: 5,
				textTransform: 'uppercase',
			},
			':first-child'
		),
		...CssSelectors.allChildren(
			{
				color: brandPrimaryLight,
			},
			':last-child'
		),
	},
	lastInteractionTime: {
		color: titles,
	},
	loadingSpinner: {
		marginTop: 40,
	},
	moreMenu: {},
	playIcon: {
		height: 10,
		marginRight: 4,
		width: 10,
	},
	recordingLink: {
		':hover': {
			color: brandPrimaryHover,
		},
		color: brandPrimary,
		cursor: 'pointer',
		fontSize: 12,
	},
	timeline: {
		...CssSelectors.allDescendants(
			{
				whiteSpace: 'normal',
				width: 'unset',
			},
			'.entity-title'
		),
		...CssSelectors.allDescendants(
			{
				margin: '1em 0',
			},
			'p'
		),
	},
	timelineContainer: {},
	timelineHeader: {
		...aidaBaseStyles.flexHorizontalCenter,
		background: mention,
		borderBottom: '10px solid white',
		borderTop: '10px solid white',
		justifyContent: 'space-between',
		padding: '10px 30px',
		position: 'sticky',
		top: 0,
		zIndex: 9,
	},
});
