import { CssSelectors } from '../../../../web/styles/styles';
import { brandSecondary, titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	card: {
		alignItems: 'center',
		background: 'white',
		borderRadius: 10,
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .1)',
		color: titles,
		display: 'flex',
		flexGrow: 1,
		height: 120,
		justifyContent: 'space-between',
		margin: 10,
		padding: 20,
	},
	container: {
		alignItems: 'center',
		display: 'flex',
	},
	label: {
		color: '#AAAAAA',
		fontSize: 11,
		letterSpacing: '1.1px',
		marginBottom: 10,
		textTransform: 'uppercase',
	},
	memeCard: {
		cursor: 'pointer',
		flexGrow: 0,
		textAlign: 'center',
		width: 120,
	},
	memeCardSelected: {
		background: brandSecondary,
		color: 'white',
		...CssSelectors.allDescendants({
			color: 'white',
		}),
	},
	memeCenter: {
		width: '100%',
	},
	memeCount: {
		fontSize: 24,
	},
	memeLabel: {
		marginBottom: 0,
	},
});
