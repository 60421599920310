import { FontFamilies, Layouts } from '../../../../web/styles/styles';
import { brandPrimaryHover, error, grayDark, mention, white } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	buttonContainer: {
		...Layouts.horizontalStack(),
	},
	container: {
		...Layouts.verticalStack(20),
	},
	datePickerContainer: {},
	dateTimePickerContainer: {
		background: white,
	},
	followUpNameInput: {
		alignItems: 'center',
		backgroundColor: '#f9f9f9',
		border: '1px solid #f2f2f2',
		borderRadius: 3,
		color: '#4a4a4a',
		display: 'flex',
		fontSize: 14,
		height: 40,
		outline: 'none',
		padding: '0 12px',
		verticalAlign: 'middle',
		width: 400,
	},
	importanceLevels: {
		marginLeft: 20,
	},
	inputContainer: {},
	label: {
		color: grayDark,
		fontSize: 12,
		marginBottom: 4,
	},
	time: {
		color: brandPrimaryHover,
		fontFamily: FontFamilies.semiBold,
	},
	timeOld: {
		color: white,
		fontFamily: FontFamilies.semiBold,
	},
	timePicker: {
		alignItems: 'center',
		background: mention,
		borderRadius: 16,
		display: 'flex',
		height: 40,
		justifyContent: 'space-between',
		padding: 12,
		width: 200,
	},
	timePickerRed: {
		background: error,
	},
});
