import { IUserSessionComponentProps } from '../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../models/Logging';
import { WarningIcon } from '../../../web/components/svgs/icons/WarningIcon';
import { MainContainer } from '../../containers/MainContainer';
import { UserSessionViewModelKey } from '../../models/AppState';
import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

interface IProps extends RouteComponentProps<any>, IUserSessionComponentProps, IEventLoggingComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IState {
	hasError: boolean;
	error: Error;
}

class _MainContainerErrorBoundary extends React.Component<IProps, IState> {
	// @ts-ignore
	public readonly state: IState = { error: null, hasError: false };

	public static getDerivedStateFromError() {
		return { hasError: true };
	}

	public componentDidCatch(error: Error) {
		const { logError } = this.props;
		this.setState({ error });
		// @ts-ignore
		logError('AIDAErrorBoundary-Error', error);
	}

	public render() {
		const { styles, className, userSession } = this.props;
		const { hasError, error } = this.state;
		const islocalhost = location.href.includes('localhost');
		const userIsAida = userSession?.user?.primaryEmail?.value?.toLowerCase().includes('aida');

		if (hasError) {
			return (
				<MainContainer>
					<div className={css(styleSheet.center)}>
						<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
							<WarningIcon />
							{islocalhost && !userIsAida && (
								<div className={css(styleSheet.wrong)}>You&apos;re logged in as the wrong user</div>
							)}
							<div>COFFEE encountered an error. Please notify the engineering team if this problem persists.</div>
							<div className={css(styleSheet.message)}>{error?.message}</div>
							<div className={css(styleSheet.stack)}>{error?.stack}</div>
						</div>
					</div>
				</MainContainer>
			);
		}

		return this.props.children;
	}
}

const MainContainerErrorBoundaryAsObserver = observer(_MainContainerErrorBoundary);
const MainContainerErrorBoundaryWithRouter = withRouter(MainContainerErrorBoundaryAsObserver);
const MainContainerErrorBoundaryWithEventLogging = withEventLogging(MainContainerErrorBoundaryWithRouter);
export const MainContainerErrorBoundary = inject(UserSessionViewModelKey)(MainContainerErrorBoundaryWithEventLogging);
