import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { styleSheet } from './styles';

interface IProps {
	bodyStyles?: StyleDeclarationValue[];
	className?: string;
	headerStyles?: StyleDeclarationValue[];
	title?: React.ReactNode;
}

export const BasicPage: React.FC<IProps> = ({
	bodyStyles = [],
	children,
	className = '',
	headerStyles = [],
	title,
}) => {
	return (
		<div className={`${css(styleSheet.basicPageContainer)} ${className}`}>
			<div className={css(styleSheet.header, ...headerStyles)}>
				<div className={css(styleSheet.title)}>{title}</div>
			</div>
			<div className={css(styleSheet.body, ...bodyStyles)}>{children}</div>
		</div>
	);
};
