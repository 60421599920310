import { ViewportContext } from '../../../hooks/useViewport';
import { ILeaderboard } from '../../../viewModels/gamification';
import { ProgressBar } from '../../ProgressBar';
import { styleSheet } from '../styles';
import { SortOptions } from './ListHeader';
import { css } from 'aphrodite';
import { useContext } from 'react';
import * as React from 'react';

export interface IProps {
	selected?: SortOptions;
	totals: ILeaderboard;
}

export const TotalScores: React.FC<IProps> = ({ selected, totals }) => {
	// @ts-ignore
	const { isBelowSmall } = useContext(ViewportContext);

	// @ts-ignore
	const goal = isBelowSmall ? totals?.totalGoals?.[selected] : totals?.totalGoals?.demos;
	// @ts-ignore
	const amount = isBelowSmall ? totals?.totalScore?.[selected] : totals?.totalScore?.demos;
	// @ts-ignore
	const projected = isBelowSmall ? totals?.projectedScore?.[selected] : totals?.projectedScore?.demos;
	const title = isBelowSmall ? 'Totals' : 'Demo totals';

	return (
		<div className={css(styleSheet.leaderBoardTotalScores)}>
			<div className={css(styleSheet.leaderBoardTotalScoresTitle)}>{title}</div>
			<ProgressBar goal={goal} amount={amount} projected={projected} />
		</div>
	);
};
