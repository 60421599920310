import { useState } from 'react';
import { Connection, Device } from 'twilio-client';
import { useUserSession } from '../../models/hooks/appStateHooks';
import { ApiClient } from './api';
// eslint-disable-next-line import/no-internal-modules
import { TwilioError } from 'twilio-client/es5/twilio/errors';

function createDevice(token: string): Device {
	const device = new Device();

	device.on('registering', d => {
		console.log('device registering', d);
	});

	device.on('registered', d => {
		console.log('device registered', d);
	});

	device.on('unregistered', call => {
		console.log('device unregistered', call);
	});

	device.setup(token, {
		allowIncomingWhileBusy: false,
		closeProtection: 'You are on a call. Closing this tab will end it.',
	});

	return device;
}

interface IProps {
	onError: (twilioError: TwilioError) => void;
	onIncomingCall: (phoneCallId: string) => void;
}

export const useTwilioDialer = ({ onError, onIncomingCall }: IProps) => {
	// @ts-ignore
	const [device, setDevice] = useState<Device>(undefined);
	// @ts-ignore
	const [call, setCall] = useState<Connection>(undefined);
	const [isConnected, setIsConnected] = useState<boolean>(false);
	const [isRinging, setIsRinging] = useState<boolean>(false);
	const [isMuted, updateIsMuted] = useState<boolean>(false);

	const session = useUserSession();

	const addCallListeners = (newCall: Connection) => {
		setCall(newCall);
		updateIsMuted(newCall.isMuted());

		newCall.on('accept', callArg => {
			console.log(
				'The incoming call was accepted or the outgoing call media session has finished setting up.',
				callArg
			);
			setIsConnected(true);
			setIsRinging(false);
		});

		newCall.on('reject', () => {
			console.log('The call was rejected.');
			setIsConnected(false);
			setIsRinging(false);
		});

		newCall.on('disconnect', callArg => {
			console.log('The call has been disconnected.', callArg);
			// @ts-ignore
			setCall(undefined);
			setIsConnected(false);
			setIsRinging(false);
		});

		newCall.on('cancel', () => {
			console.log('The call has been canceled.');
			// @ts-ignore
			setCall(undefined);
			setIsConnected(false);
			setIsRinging(false);
		});

		newCall.on('error', onError);

		newCall.on('mute', value => {
			updateIsMuted(value);
		});

		newCall.on('reconnected', () => {
			console.log('The call has regained connectivity.');
		});

		newCall.on('reconnecting', (twilioError: TwilioError) => {
			// update the UI to alert user that connectivity was lost
			// and that the SDK is trying to reconnect
			// showReconnectingMessageInBrowser();

			// You may also want to view the TwilioError:
			console.log('Connectivity error: ', twilioError);
		});

		// newCall.on('volume', (inputVolume, outputVolume) => {
		// 	// Do something
		// 	console.log('change volume', inputVolume, outputVolume);
		// });
	};

	const init = async () => {
		// @ts-ignore
		const client = new ApiClient(session);
		const result = await client.getTwilioAccessToken();
		if (!result.success) {
			// @ts-ignore
			setDevice(undefined);
			return undefined;
		}

		const accessToken = result.value;
		// @ts-ignore
		const newDevice = createDevice(accessToken);

		newDevice.on('incoming', (incomingCall: Connection) => {
			console.log('incoming call', incomingCall);
			addCallListeners(incomingCall);

			if (incomingCall.customParameters.get('autoAnswer') === 'True') {
				incomingCall.accept();
			} else {
				setIsRinging(true);
				const phoneCallId = incomingCall.customParameters.get('phoneCallId');
				if (onIncomingCall) {
					// @ts-ignore
					onIncomingCall(phoneCallId);
				}
			}
		});

		newDevice.on('error', (twilioError: TwilioError, callArg) => {
			console.log('An error has occurred: ', twilioError, callArg);
			switch (twilioError.code) {
				case 31002:
					// call disconnected
					console.log('call got disconnected');
					hangUp();
					return;

				case 31202:
					// token has expired, auto reconnect
					init();
					return;

				default:
					onError(twilioError);
					break;
			}
		});

		newDevice.on('connect', (connection: Connection) => {
			console.log('Call is connected', connection);
			if (call !== connection) {
				setCall(connection);
			}
		});

		newDevice.on('disconnect', (connection: Connection) => {
			console.log('Call was disconnected', connection);
			if (call === connection) {
				// @ts-ignore
				setCall(undefined);
			}
		});

		newDevice.on('offline', () => {
			console.warn('Device is offline');
			deInit();
		});

		newDevice.on('ready', () => {
			console.log('Device is ready');
		});

		setDevice(newDevice);

		return newDevice;
	};

	const deInit = () => {
		if (device) {
			try {
				device.disconnectAll();
				device.destroy();
			} catch (ex) {
				console.error('failed to destroy device', ex);
			} finally {
				// @ts-ignore
				setDevice(undefined);
			}
		}
	};

	const dial = async (phoneNumber: string, parameters?: Record<string, string>) => {
		let currentDevice = device;
		if (!currentDevice) {
			// will try to initiate implicitly but ...
			// from testing it seems that we would still need to add some delay before trying to make a call or it will fail
			// @ts-ignore
			currentDevice = await init();
		}

		if (!currentDevice) {
			console.error('failed to initiate twilio device');
			return false;
		}

		const newCall = currentDevice.connect({
			...parameters,
			To: phoneNumber,
		});

		addCallListeners(newCall);

		return true;
	};

	const hangUp = () => {
		setIsConnected(false);

		if (device) {
			device.disconnectAll();
		}
	};

	const acceptCall = () => {
		console.log('accept call', call, call?.status());
		if (call && call.status() === Connection.State.Pending) {
			call.accept();
			return true;
		}

		return false;
	};

	const rejectCall = () => {
		console.log('reject call', call, call?.status());
		if (call && call.status() === Connection.State.Pending) {
			call.ignore();
			return true;
		}

		return false;
	};

	const setIsMuted = (value: boolean) => {
		if (call) {
			call.mute(value);
		}
	};

	const sendDigits = (digit: string) => {
		if (!!call && call.status() === Connection.State.Open) {
			call.sendDigits(digit);
		}
	};

	return {
		acceptCall,
		call,
		deInit,
		device,
		dial,
		hangUp,
		init,
		isConnected,
		isMuted,
		isRinging,
		rejectCall,
		sendDigits,
		setIsMuted,
	};
};
