import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const MenuIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`merge-icon ${className || ''}`} height={18} width={18}>
			<g fill={fillColor || '#F2B639'} fillRule='evenodd'>
				<path
					d='M2,9 L2,7 L14,7 L14,9 L2,9 Z M2,13 L2,11 L14,11 L14,13 L2,13 Z M2,5 L2,3 L14,3 L14,5 L2,5 Z'
					fillRule='evenodd'
				/>
			</g>
		</SvgIcon>
	);
};
