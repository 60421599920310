import { ActivityViewModel } from '../../../viewModels/memes';
import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	activity: ActivityViewModel;
	className?: string;
	onClick?(): void;
	selected: boolean;
	styles?: StyleDeclarationValue[];
}

export const Activity: React.FC<IProps> = props => {
	const { className, activity, onClick, selected, styles = [] } = props;
	return (
		<div className={`${css(styleSheet.container, ...styles)} activity ${className || ''}`} onClick={onClick}>
			<div className={css(styleSheet.card)}>
				<div>
					<div className={css(styleSheet.label)}>Activity</div>
					<div>{activity.label}</div>
				</div>
				<div>
					<div className={css(styleSheet.label)}>Points</div>
					<div>{activity.points}</div>
				</div>
			</div>
			<div className={css(styleSheet.card, styleSheet.memeCard, selected && styleSheet.memeCardSelected)}>
				<div className={css(styleSheet.memeCenter)}>
					<div className={css(styleSheet.label, styleSheet.memeLabel)}>Memes</div>
					<div className={css(styleSheet.memeCount)}>{activity.memes}</div>
				</div>
			</div>
		</div>
	);
};
