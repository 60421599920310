import { StyleSheet } from 'aphrodite';
import { CssSelectors, FontFamilies, Layouts } from '../../../web/styles/styles';
import {
	actionItemTintColor,
	background,
	backgroundGreenDark,
	black,
	borderColor,
	brandPrimary,
	gray,
	mention,
	titles,
} from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	addExtraMarginTop: {
		marginTop: 20,
	},
	addHourLabel: {
		display: 'inline-block',
		marginLeft: 5,
		position: 'relative',
		top: -3,
	},
	availableLeads: {
		alignItems: 'center',
		background: backgroundGreenDark,
		border: `1px solid ${actionItemTintColor}`,
		borderRadius: 4,
		display: 'flex',
		fontSize: 12,
		height: 39,
		justifyContent: 'center',
		padding: 12,
		paddingLeft: 10,
		...CssSelectors.allDescendants(
			{
				fontFamily: FontFamilies.bold,
			},
			'strong'
		),
	},
	availableLeadsCheckmarkIcon: {
		height: 16,
		marginRight: 5,
		width: 'auto',
	},
	bold: {
		fontFamily: FontFamilies.bold,
	},
	checkbox: {
		marginLeft: 20,
	},
	checkboxText: {
		color: titles,
	},
	container: {},
	ctaContainer: {
		...aidaBaseStyles.flexCol,
		padding: 20,
		...CssSelectors.allChildren(
			{
				':not(:last-child)': {
					marginBottom: 10,
				},
			},
			'div'
		),
	},
	customMultiselect: {
		paddingLeft: 15,
		...CssSelectors.allDescendants(
			{
				background,
				borderColor,
			},
			'.dropdown.outcome-select'
		),
		...CssSelectors.allDescendants(
			{
				background,
				borderColor,
				marginTop: 0,
				minWidth: 408,
			},
			'.multiselect-dropdown'
		),
		...CssSelectors.allDescendants(
			{
				color: black,
				fontSize: 12,
				letterSpacing: 'initial',
			},
			'.checkbox-content div'
		),
	},
	customValueInput: {
		height: 42,
		marginTop: 5,
		width: 70,
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'input'
		),
	},
	customValueModal: {
		...CssSelectors.allDescendants(
			{
				minWidth: 'auto',
				width: 250,
			},
			'.modal-overlay-content'
		),
	},
	disabled: {
		opacity: 0.5,
	},
	divider: {
		background: gray,
		height: 1,
	},
	dividerVertical: {
		background: gray,
		width: 1,
	},
	editFilterContainer: {
		flexGrow: 1,
		fontSize: 14,
		maxHeight: 400,
		overflowY: 'scroll',
		padding: 20,
		...Layouts.verticalStack(20),
	},
	extendedConstraintsText: {
		marginLeft: 20,
		opacity: 0.5,
	},
	fieldWrapper: {
		display: 'flex',
	},
	filterNameInput: {
		height: 34,
		width: 340,
	},
	filterNameTitle: {
		fontFamily: FontFamilies.bold,
		fontSize: 14,
		width: 110,
	},
	flex: {
		alignItems: 'center',
		display: 'flex',
	},
	header: {
		alignItems: 'center',
		background: brandPrimary,
		borderTopLeftRadius: 3,
		borderTopRightRadius: 3,
		display: 'flex',
		justifyContent: 'space-between',
		padding: '6px 14px',
	},
	leadRuleModal: {
		...CssSelectors.allDescendants(
			{
				border: 'none',
				maxWidth: 1030,
				padding: 0,
				width: 1030,
			},
			'.modal-overlay-content'
		),
	},
	leadRuleScheduleModal: {
		...CssSelectors.allDescendants(
			{
				maxHeight: 610,
				overflowY: 'scroll',
				width: 468,
			},
			'.modal-overlay-content'
		),
	},
	mediumSelect: {
		width: 120,
	},
	minDaysSelect: {
		width: 115,
	},
	priority: {
		marginLeft: 20,
	},
	priorityInput: {
		width: 80,
	},
	priorityTextInput: {
		height: 42,
		width: 70,
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'input'
		),
	},
	radioContainer: {
		minHeight: 380,
		minWidth: 320,
		padding: 20,
		...Layouts.verticalStack(20),
	},
	radioContent: {
		margin: '10px 0',
	},
	radioLabel: {},
	regularSelect: {
		width: 170,
	},
	regularText: {
		fontSize: 14,
		marginRight: 5,
		whiteSpace: 'nowrap',
	},
	ruleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	ruleContainerHeader: {
		backgroundColor: mention,
		padding: 15,
	},
	schedule: {
		color: titles,
		fontSize: 12,
		marginLeft: 20,
	},
	sectionTitle: {
		fontFamily: FontFamilies.bold,
		fontSize: 14,
		whiteSpace: 'nowrap',
	},
	selectedUser: {
		fontSize: 14,
	},
	subtitle: {
		fontSize: 16,
	},
	tag: {
		display: 'inline-block',
	},
	tagCheckbox: {
		margin: '10px 0',
	},
	tagCheckboxContainer: {
		width: 70,
	},
	tagListBuilderContainer: {
		minWidth: 408,
	},
	tagSection: {
		alignItems: 'flex-start',
		display: 'flex',
		...CssSelectors.allChildren({
			marginRight: 10,
		}),
	},
	tagSectionTitle: {
		marginBottom: 10,
	},
	timeSelect: {
		width: 110,
	},
	timeSelectCondensed: {
		width: 130,
	},
	title: {
		fontSize: 16,
	},
	toggleText: {
		fontSize: 14,
		margin: '0 15px',
		whiteSpace: 'nowrap',
	},
	userName: {
		marginLeft: 10,
	},
	userSearchField: {
		margin: '10px 0',
	},
	verticals: {
		display: 'grid',
		gridTemplateColumns: '50% 50%',
	},
	weekdaysWrapper: {
		padding: '20px 10px',
	},
});
