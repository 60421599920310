import * as Api from '@ViewModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useErrorMessages } from '../../hooks';
import { ApiClient } from '../../services/api';
import { ILeadViewFilter, filterToCriteria } from '../LeadView/useLeadView';
import { useStatesOnFetch } from '../utils/useStatesOnFetch';

export const useExportTranscripts = <T>() => {
	const { state, handleWorking, handleResponse, reset } = useStatesOnFetch<T>();

	const userSession = useUserSession();
	const errorMessages = useErrorMessages();

	// @ts-ignore
	const client = new ApiClient(userSession);

	const bulkExport = async (filter: ILeadViewFilter) => {
		handleWorking();
		try {
			const response = await client.exportCallTranscripts(filterToCriteria(filter));
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response);
			return response;
		} catch (error) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
	};

	return {
		...state,
		bulkExport,
		empty: !state?.data,
		reset,
		status: state.status,
	};
};
