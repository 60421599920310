import * as Api from '@ViewModels';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useErrorMessages } from '../../hooks';
import { ApiClient } from '../../services/api';
import { useStatesOnFetch } from '../utils/useStatesOnFetch';

export const usePhoneCallCategory = <T>() => {
	const { state, handleWorking, handleResponse, reset } = useStatesOnFetch<T>();

	const userSession = useUserSession();
	const errorMessages = useErrorMessages();

	// @ts-ignore
	const client = new ApiClient(userSession);

	const get = async () => {
		handleWorking();
		try {
			const response = await client.getPhoneCallCategory();
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response, (data: any) => data?.reverse());
			return response;
		} catch (error) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
	};

	const update = async (categoryId: string, body: Api.IPhoneCallCategory) => {
		handleWorking();
		try {
			const response = await client.updatePhoneCallCategory(categoryId, body);
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response);
			return response;
		} catch (error) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
	};

	const create = async (body: Api.IPhoneCallCategory) => {
		handleWorking();
		try {
			const response = await client.createPhoneCallCategory(body);
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response);
			return response;
		} catch (error) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
	};

	const addToPhoneCall = async (phoneCallId: string, categoryName: string) => {
		handleWorking();
		try {
			const response = await client.addPhoneCallCategory(phoneCallId, categoryName);
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response);
			return response;
		} catch (error) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
	};

	const removeFromPhoneCall = async (phoneCallId: string, categoryName: string) => {
		handleWorking();
		try {
			const response = await client.removePhoneCallCategory(phoneCallId, categoryName);
			if (!response.success) {
				throw Api.asApiError(response);
			}
			handleResponse(response);
			return response;
		} catch (error) {
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
	};

	return {
		...state,
		addToPhoneCall,
		create,
		empty: !state?.data,
		get,
		removeFromPhoneCall,
		reset,
		status: state.status,
		update,
	};
};
