import { render as DOMRender } from 'react-dom';
import { Router } from 'react-router-dom';
import { AppHashHistory } from '../models';
import { BugsnagEventLogger, EventLogger } from '../models/Logging';
import { polyfillAsync } from '../models/UiUtils';
import { AidaApp } from './containers/AidaApp';
import { AidaRouter } from './containers/AidaRouter';
import { DraggableWindowContextProvider } from './contexts/draggableWindowContext';
import { SearchProvider } from './contexts/search';
import { ViewportProvider } from './hooks/useViewport';
import './styles/global-styles.less';

const envName = process.env.BUGSNAG_ENV as BUGSNAG_ENV;
const bugsnagEventLogger = new BugsnagEventLogger(envName);
const ErrorBoundary = bugsnagEventLogger.getErrorBoundary();
EventLogger.addLogger(bugsnagEventLogger);

document.addEventListener('DOMContentLoaded', async () => {
	await polyfillAsync();
	const rootElement = document.getElementById('react-root');

	DOMRender(
		<ErrorBoundary>
			<Router history={AppHashHistory}>
				<AidaApp>
					<SearchProvider>
						<ViewportProvider>
							<DraggableWindowContextProvider>
								<AidaRouter history={AppHashHistory} />
							</DraggableWindowContextProvider>
						</ViewportProvider>
					</SearchProvider>
				</AidaApp>
			</Router>
		</ErrorBoundary>,
		rootElement
	);
});
