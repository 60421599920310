import { CssSelectors } from '../../../web/styles/styles';
import { borderColorLight, category, white } from '../../styles/colors';
import { black } from './../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addButton: {
		alignItems: 'center',
		color: category,
		cursor: 'pointer',
		display: 'inline-flex',
		fontSize: 14,
		marginLeft: 1,
		...CssSelectors.allChildren(
			{
				marginLeft: 6,
			},
			'span'
		),
		...CssSelectors.allChildren(
			{
				height: 20,
				marginTop: 3,
				width: 20,
			},
			'svg'
		),
	},
	addContainer: {
		width: '100%',
	},
	addContainerExtraMargin: {
		marginTop: 8,
	},
	cat: {
		...CssSelectors.allDescendants(
			{
				fill: `${black}`,
			},
			'.tag-remove-button path:first-child'
		),
	},
	catSvg: {},
	catWrapper: {
		display: 'flex',
		...CssSelectors.allDescendants(
			{
				background: `${category}`,
				borderRadius: `0px 6px 6px 0px`,
				borderTopLeftRadius: 0,
				color: `${white}`,
				display: 'inline-block',
				height: 26,
				marginLeft: -1,
			},
			'.tag'
		),
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		margin: '10px 0 0',
		...CssSelectors.allChildren({
			marginRight: 4,
		}),
	},
	searchBox: {
		border: `1px solid ${borderColorLight}`,
		height: 30,
	},
});
