import { LeadRuleViewModel, TurnItOnForOptions } from '../../../../viewModels/leadrules';
import { styleSheet } from './../../styles';
import { Everyone } from './Everyone';
import { Individual } from './Individual';
import { Vertical } from './Vertical';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import * as React from 'react';

interface IProps {
	leadRule: LeadRuleViewModel;
	onSelect: (option: TurnItOnForOptions) => void;
}

export const TurnItOnFor: React.FC<IProps> = observer(({ leadRule, onSelect }) => {
	const [radio, setRadio] = useState<TurnItOnForOptions>(leadRule?.currentType);

	const handleRadio = (state: TurnItOnForOptions) => () => {
		onSelect(state);
		setRadio(state);
	};

	useEffect(() => {
		if (leadRule) {
			handleRadio(leadRule?.currentType)();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leadRule]);

	return (
		<div className={css(styleSheet.radioContainer)}>
			<div className={css(styleSheet.subtitle)}>Turn it on for:</div>

			<Everyone
				enabled={radio === TurnItOnForOptions.Everyone}
				onChangeToEnable={handleRadio(TurnItOnForOptions.Everyone)}
			/>

			<Vertical
				enabled={radio === TurnItOnForOptions.Vertical}
				leadRule={leadRule}
				onChangeToEnable={handleRadio(TurnItOnForOptions.Vertical)}
			/>

			<Individual
				enabled={radio === TurnItOnForOptions.Individual}
				leadRule={leadRule}
				onChangeToEnable={handleRadio(TurnItOnForOptions.Individual)}
			/>
		</div>
	);
});
