import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { FormFieldType } from '../../../../extViewmodels';
import { Noop } from '../../../../extViewmodels/Utils';
import { useLambda } from '../../../../models/hooks/useLambda';
import { Checkbox } from '../../../../web/components/Checkbox';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { TextInput } from '../../../../web/components/TextInput';
import { TinyPopover } from '../../../../web/components/TinyPopover';
import { DisclosureIcon } from '../../../../web/components/svgs/icons/DisclosureIcon';
import { useErrorMessages, useToaster } from '../../../hooks';
import { useQueue } from '../../../hooks/queue';
import { grayDark, lightOrange, tagBackgroundColor, titles } from '../../../styles/colors';
import { aidaBaseStyleSheet } from '../../../styles/styles';
import { FieldKey, FormFieldViewModel, IFormFieldOption } from '../../../viewModels/form';
import { TransparentButton } from '../../TransparentButton';
import { SearchIcon } from '../../svgs/icons/SearchIcon';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	attribute: FormFieldViewModel<any>;
}

const LeadAttributeBase: React.FC<IProps> = ({ attribute }) => {
	const queue = useQueue();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const searchRef = useRef<HTMLInputElement>(null);
	const [isOpen, setIsOpen, setIsOpenLambda] = useLambda(false);
	const [searchValue, setSearchValue] = useState('');
	// @ts-ignore
	const [searchTimeout, setSearchTimeout] = useState<number>(null);

	useEffect(() => {
		window.clearTimeout(searchTimeout);
		setSearchTimeout(
			window.setTimeout(() => {
				attribute.loadOptions(searchValue).catch(err => {
					// @ts-ignore
					errorMessages.pushApiError(err);
				});
			}, 400)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	useEffect(() => {
		if (isOpen) {
			window.setTimeout(() => {
				// @ts-ignore
				searchRef.current.focus();
			}, 100);
		}
	}, [isOpen]);

	const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const onOptionClick = (selectedOption: IFormFieldOption) => async () => {
		let value = selectedOption.id;

		if (attribute.fieldType === FormFieldType.MultipleOptions) {
			if (((attribute.value || []) as IFormFieldOption[]).find(v => v.id === selectedOption.id)) {
				// is being deselected
				value = ((attribute.value || []) as IFormFieldOption[])
					.filter(v => v.id !== selectedOption.id)
					.map(v => v.id)
					.join(',');
			} else {
				// is being selected
				const arr = ((attribute.value || []) as IFormFieldOption[]).map(v => v.id);
				arr.push(selectedOption.id);
				value = arr.join(',');
			}
		}

		await queue.lead
			// @ts-ignore
			.setAttribute(attribute, value)
			.then(() => {
				// @ts-ignore
				toaster.push({
					message: `${attribute.label} updated successfully.`,
					type: 'successMessage',
				});
				if (attribute.fieldType !== FormFieldType.MultipleOptions) {
					setIsOpen(false);
				}
				if (attribute.name === FieldKey.Vertical) {
					// if vertical changes, need to do full reload of lead
					queue.reloadLead();
				}
			})
			.catch(err => {
				// @ts-ignore
				errorMessages.pushApiError(err);
			});
	};

	const onRef = (ref: HTMLInputElement) => {
		// @ts-ignore
		searchRef.current = ref;
	};

	const renderOptionsContent = () => {
		const content =
			attribute.isBusy || queue.lead.settingAttribute ? (
				<LoadingSpinner className={css(aidaBaseStyleSheet.absoluteCenter)} type='small' />
			) : attribute.options.length === 0 ? (
				<div className={css(styleSheet.noOptions)}>no options found</div>
			) : (
				attribute.options.map(option => (
					<TransparentButton
						className={css(styleSheet.optionButton)}
						key={`option-${option.id}`}
						onClick={onOptionClick(option)}
					>
						{attribute.fieldType === FormFieldType.MultipleOptions && (
							<Checkbox
								checked={!!((attribute.value || []) as IFormFieldOption[])?.find(o => o.id === option.id)}
								className='checkbox'
								// @ts-ignore
								id={option.id}
								onChange={Noop}
							/>
						)}
						<div
							className={css(
								aidaBaseStyleSheet.truncateText,
								styleSheet.option,
								option.name === FieldKey.Unknown && styleSheet.optionNone
							)}
							style={{ background: option.hexColor }}
						>
							{option.label}
						</div>
					</TransparentButton>
				))
			);

		return (
			<div className={css(styleSheet.popoverContainer)}>
				<div className={css(styleSheet.searchContainer)}>
					<TextInput
						inputId={`search-${attribute.id}`}
						inputRef={onRef}
						leftAccessory={<SearchIcon className={css(styleSheet.searchIcon)} />}
						onChange={onSearchChange}
						placeholder='Search'
						type='text'
						value={searchValue}
					/>
				</div>
				<div className={css(styleSheet.contentContainer)}>{content}</div>
			</div>
		);
	};

	const renderSelectedAttribute = () => {
		const value =
			attribute.fieldType === FormFieldType.MultipleOptions
				? attribute.value?.map((v: IFormFieldOption) => v.label).join(', ')
				: attribute.value?.label || attribute.value?.value; // fallback to value is label is not present

		return (
			<TransparentButton
				key={attribute.id}
				className={css(styleSheet.selectedAttributeButton)}
				disabled={attribute.isBusy}
				onClick={setIsOpenLambda(true)}
			>
				<div
					className={css(styleSheet.selectedAttribute)}
					style={{
						background: !value || value === FieldKey.Unknown ? lightOrange : tagBackgroundColor,
						color: !value || value === FieldKey.Unknown ? grayDark : titles,
					}}
				>
					<div>
						<span>{attribute.label}:</span>
						<span>{value || 'Unknown'}</span>
					</div>
					<div>
						<DisclosureIcon />
					</div>
				</div>
			</TransparentButton>
		);
	};

	return (
		<TinyPopover
			anchor={renderSelectedAttribute()}
			dismissOnOutsideAction={true}
			isOpen={isOpen}
			key={attribute.id}
			onRequestClose={setIsOpenLambda(false)}
		>
			{renderOptionsContent()}
		</TinyPopover>
	);
};

export const LeadAttribute = observer(LeadAttributeBase);
