import { styleSheet } from './styles';
import { animated, config, useSpring } from '@react-spring/web';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	color?: string;
	points: number;
	selected?: boolean;
}

const from = {
	opacity: 0,
	transform: 'scale(0)',
};
const to = {
	opacity: 1,
	transform: 'scale(1)',
};

export const PointsBubble: React.FC<IProps> = ({ className = '', points, selected }) => {
	const spring = useSpring({
		config: config.gentle,
		delay: 300,
		from,
		to: selected ? to : from,
	});

	return (
		<animated.div className={`${css(styleSheet.pointsBubbleContainer)} ${className}`} style={spring}>
			<span className={css(styleSheet.points)}>{points}</span>
			<span>pts</span>
		</animated.div>
	);
};
