import { StyleSheet } from 'aphrodite';
import { CssSelectors, baseStyles } from '../../../../../../web/styles/styles';
import { borderColor, brandPrimary, grayDark, white } from '../../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	autocomplete: {
		width: '100%',
		...CssSelectors.allDescendants(
			{
				width: '100%',
			},
			'.dropdown, .dropdown-anchor, .autocomplete-search-field-input'
		),
		// No results
		...CssSelectors.allDescendants({ color: grayDark }, '.autocomplete-no-results'),
		// Input background color and border
		...CssSelectors.allDescendants(
			{
				background: white,
				border: `1px solid ${white}`,
				borderRadius: 8,
				// Input font color
				...CssSelectors.allDescendants({ color: grayDark }, 'input'),
			},
			'.autocomplete-search-field-input'
		),
	},
	autoCompleteMenu: {
		minWidth: 240,
	},
	autoCompleteMenuItemHighlighted: {
		...baseStyles.dropdownMenuItemHighlighted,
	},
	autocompleteWrapper: {
		display: 'flex',
		position: 'relative',
	},
	autoCompleteMenuItem: {
		...baseStyles.dropdownMenuItem,
		display: 'block',
		padding: 0,
		margin: '5px 10px',
		...CssSelectors.allChildren({
			display: 'inline-flex',
		}),
	},
	createTag: {
		display: 'block',
		padding: 10,
		fontSize: 14,
	},
	clearButton: {
		cursor: 'pointer',
		marginTop: -12,
		position: 'absolute',
		right: 8,
		top: '50%',
		...CssSelectors.allDescendants(
			{
				height: 24,
				width: 24,
			},
			'svg'
		),
		...CssSelectors.allDescendants(
			{
				fill: grayDark,
				opacity: 0.6,
				stroke: grayDark,
			},
			'circle'
		),
	},
	dropdownItem: {
		color: grayDark,
	},
	searchIcon: {
		fill: grayDark,
		flexShrink: 0,
		marginRight: 6,
	},
	sectionWrap: {
		borderBox: 'box-sizing',
		alignItems: 'center',
		display: 'flex',
		padding: 10,
		borderTop: `1px solid ${borderColor}`,
		borderBottom: `1px solid ${borderColor}`,
	},
	sectionTitle: {
		color: brandPrimary,
		fontSize: 14,
		margin: 0,
	},
	sectionIcon: {
		marginRight: 8,
		height: 16,
		width: 16,
	},
	noMargin: {
		margin: 0,
	},
});
