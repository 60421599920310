import { CssSelectors } from '../../../../web/styles/styles';
import { brandPrimaryHover } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		background: brandPrimaryHover,
		color: 'inherit',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '22px 0',
		textDecoration: 'none',
		width: '100%',
		...CssSelectors.allDescendants(
			{
				color: 'inherit',
				textDecoration: 'none',
			},
			' *'
		),
	},
});
