import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Noop, timezoneMap } from '../../../../extViewmodels/Utils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useLambda } from '../../../../models/hooks/useLambda';
import { Button } from '../../../../web/components/Button';
import { TextInput } from '../../../../web/components/TextInput';
import { CalendarDateIcon } from '../../../../web/components/svgs/icons/CalendarDateIcon';
import { useErrorMessages } from '../../../hooks';
import { useInput } from '../../../hooks/input';
import { useQueue } from '../../../hooks/queue';
import { brandSecondary } from '../../../styles/colors';
import { aidaBaseStyleSheet } from '../../../styles/styles';
import { FollowUpImportance } from '../../../viewModels/queue';
import { AidaDayPicker } from '../../AidaDayPicker';
import { BlueModal, IBlueModalProps } from '../../BlueModal';
import { TransparentButton } from '../../TransparentButton';
import { SpicePicker as ImportancePicker } from '../SpicePicker';
import { styleSheet } from './styles';

export interface IFollowUpCallbackArgs {
	importance: FollowUpImportance;
	name: string;
	selectedDate: moment.Moment;
}

interface IProps extends IBlueModalProps {
	className?: string;
	confirmButtonLabel?: string;
	onClickToConfirm: (args: IFollowUpCallbackArgs) => void;
	styles?: StyleDeclarationValue[];
}

export const FollowUpModal: React.FC<IProps> = observer(props => {
	const queue = useQueue();
	const errorMessages = useErrorMessages();
	const userSession = useUserSession();
	const { className, confirmButtonLabel = 'Set', onClickToConfirm, styles = [], ...restProps } = props;
	const [showDayPicker, setShowDayPicker, setShowDayPickerLambda] = useLambda(false);
	const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment(queue.lead?.followUp?.followUpDate));
	const [name, setName, onNameChange] = useInput(queue.lead?.followUp?.name ?? '');
	const [importance, setImportance, onImportanceChange] = useLambda(
		queue.lead?.followUp?.importance ?? FollowUpImportance.Low
	);

	const dateIsBeforeNow = selectedDate.isBefore(moment());

	useEffect(() => {
		// @ts-ignore
		setName(queue.lead?.followUp?.name);
		setImportance(queue.lead?.followUp?.importance ?? FollowUpImportance.Low);
		setSelectedDate(moment(queue.lead?.followUp?.followUpDate));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queue.lead?.followUp]);

	const onSaveClick = () => {
		if (!selectedDate) {
			// @ts-ignore
			errorMessages.push({
				messages: ['You must select a time for the follow up'],
			});
			return;
		}

		if (dateIsBeforeNow) {
			// @ts-ignore
			errorMessages.push({
				messages: ['You must select a time in the future'],
			});
			return;
		}
		onClickToConfirm({
			importance,
			name,
			selectedDate,
		});
	};

	const onCancelClick = () => restProps.onRequestClose({}, true);
	const onDayPickerCancel = () => setShowDayPicker(false);
	const onDayPickerChange = (day: moment.Moment) => setSelectedDate(day);

	const onDayPickerSave = (day: moment.Moment) => {
		setSelectedDate(day);
		setShowDayPicker(false);
	};

	return (
		<BlueModal {...restProps}>
			<div className={`${css(styleSheet.container, ...styles)} follow-up-modal ${className || ''}`}>
				<div>Follow up</div>
				<div className={css(styleSheet.inputContainer)}>
					<div className={css(styleSheet.label)}>Name</div>
					<TextInput
						className={css(styleSheet.followUpNameInput)}
						inputId='follow-up-name'
						onChange={onNameChange}
						placeholder='Describe your follow up'
						type='text'
						value={name}
					/>
				</div>
				<div className={css(aidaBaseStyleSheet.flexHorizontalCenter)}>
					<div className={css(styleSheet.inputContainer)}>
						<div className={css(styleSheet.label)}>Date &amp; Time</div>
						<TransparentButton onClick={setShowDayPickerLambda(true)}>
							<div className={css(styleSheet.timePicker, dateIsBeforeNow && styleSheet.timePickerRed)}>
								<div className={css(styleSheet.time, dateIsBeforeNow && styleSheet.timeOld)}>
									{selectedDate
										? moment(selectedDate).format('M/D/YYYY hh:mmA') +
											' ' +
											// @ts-ignore
											timezoneMap[userSession.user.userPreferences?.timeZone || momentTz.tz.guess()]
										: 'Select time'}
								</div>
								<CalendarDateIcon fillColor={brandSecondary} />
							</div>
						</TransparentButton>
					</div>
					<div className={css(styleSheet.inputContainer, styleSheet.importanceLevels)}>
						<div className={css(styleSheet.label)}>Spice Level</div>
						<ImportancePicker importanceLevel={importance} onImportanceLevelChange={onImportanceChange} />
					</div>
				</div>
				<BlueModal
					isOpen={showDayPicker}
					onRequestClose={Noop}
					shouldCloseOnOverlayClick={false}
					useDefaultHeader={false}
				>
					<div className={css(styleSheet.dateTimePickerContainer)}>
						<AidaDayPicker
							className={css(styleSheet.datePickerContainer)}
							day={selectedDate}
							hideTime={false}
							onCancel={onDayPickerCancel}
							onSave={onDayPickerSave}
							onChange={onDayPickerChange}
						/>
					</div>
				</BlueModal>
				<div className={css(styleSheet.buttonContainer)}>
					<Button onClick={onSaveClick} label={confirmButtonLabel} />
					<Button onClick={onCancelClick} label='Cancel' kind='reverse' />
				</div>
			</div>
		</BlueModal>
	);
});
