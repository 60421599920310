import { CssSelectors, FontFamilies } from '../../../web/styles/styles';
import { white } from '../../styles/colors';
import { MemeModalZIndex } from '../MemeModal/styles';
import { StyleSheet } from 'aphrodite';

const achievementToastIconSize = 64;

export const styleSheet = StyleSheet.create({
	achievementToast: {
		alignItems: 'center',
		background: `linear-gradient(#534EDD, #9A75F2)`,
		borderRadius: achievementToastIconSize / 2.0,
		boxSizing: 'border-box',
		cursor: 'pointer',
		display: 'flex',
		height: achievementToastIconSize,
	},
	achievementToastContent: {
		boxSizing: 'border-box',
		color: white,
		flexShrink: 0,
		fontSize: 16,
		margin: 10,
		marginRight: 25,
	},
	achievementToastCount: {
		alignItems: 'center',
		background: white,
		borderRadius: '50%',
		color: '#534EDD',
		display: 'flex',
		fontFamily: FontFamilies.bold,
		height: 20,
		justifyContent: 'center',
		width: 20,
	},
	achievementToastDescription: {
		flexShrink: 0,
		marginBottom: 5,
	},
	achievementToastIcon: {
		alignItems: 'center',
		background: white,
		border: `1px solid #9A75F2`,
		borderRadius: achievementToastIconSize / 2.0,
		display: 'flex',
		flexShrink: 0,
		height: achievementToastIconSize,
		justifyContent: 'center',
		marginRight: 15,
		width: achievementToastIconSize,
		...CssSelectors.allDescendants(
			{
				height: achievementToastIconSize * 0.43,
				marginLeft: 2,
			},
			'img'
		),
	},
	achievementToastInfoGroup: {
		alignItems: 'center',
		display: 'flex',
		fontSize: 13,
		marginRight: -4,
		...CssSelectors.allChildren(
			{
				marginRight: 4,
			},
			'span'
		),
	},
	achievementToastName: {
		fontFamily: FontFamilies.bold,
	},
	position: {
		bottom: -100,
		left: '50%',
		position: 'fixed',
		transform: 'translateX(-50%)',
		zIndex: MemeModalZIndex + 1,
	},
	toastContainer: {
		bottom: 4,
		left: '50%',
		position: 'fixed',
		transform: 'translate(-50%, 0)',
		zIndex: MemeModalZIndex + 1,
	},
});
