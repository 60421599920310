import { CssSelectors } from '../../../web/styles/styles';
import { borderColor, brandPrimary, error, orange } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addItemContainer: {
		alignItems: 'flex-end',
		alignSelf: 'flex-end',
		display: 'flex',
		minWidth: 30,
		padding: '0 0 9px 10px',
	},
	contactInfoCard: {
		'::before': {
			background: orange,
			bottom: 0,
			content: '" "',
			left: 0,
			position: 'absolute',
			top: 0,
			width: 4,
		},
		':not(:last-child)': {
			marginBottom: 10,
		},
		marginBottom: 10,
		...aidaBaseStyles.flexHorizontalCenter,
		...{
			border: `1px solid ${borderColor}`,
			borderLeft: '1px solid transparent',
			borderRadius: 4,
			maxWidth: '100%',
			minHeight: 120,
			padding: '20px 15px 15px',
			position: 'relative',
		},
	},
	contactInfoContainer: {
		...CssSelectors.allDescendants(
			{
				display: 'block',
				fontSize: '14px',
				paddingBottom: 5,
				...CssSelectors.allDescendants(
					{
						color: error,
						padding: '3px 0 0 2px',
					},
					'sup'
				),
			},
			'label'
		),
	},
	contactInfoModal: {
		...CssSelectors.allChildren(
			{
				maxHeight: '80vh',
				overflow: 'auto',
			},
			'.modal-overlay-content'
		),
		...{
			maxWidth: '100%',
			padding: 0,
		},
		':before': {
			background: orange,
			bottom: '0px',
			content: '',
			left: '0px',
			position: 'absolute',
			top: '0px',
			width: '4px',
		},
	},
	emailFieldContainer: {
		flexGrow: 1,
	},
	fieldContainer: {
		padding: 0,
		width: '100%',
	},
	fieldContainerHalf: {
		width: 160,
	},
	footer: {
		marginTop: 10,
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'space-between',
		...CssSelectors.allDescendants({
			':hover:disabled': {
				background: brandPrimary,
			},
		}),
		width: '100%',
	},
	header: {
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'space-between',
		paddingBottom: 30,
	},
	labelWithPhoneRepeaterWrapper: {
		...aidaBaseStyles.flexHorizontalCenter,
		...CssSelectors.allChildren(
			{
				marginLeft: 3,
				minWidth: 90,
			},
			':last-child'
		),
		...CssSelectors.allChildren(
			{
				flexGrow: 1,
			},
			':nth-child(2n)'
		),
	},
	markAsDMContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		...CssSelectors.allChildren(
			{
				paddingBottom: 0,
			},
			'label'
		),
		...CssSelectors.allChildren(
			{
				color: '#858585',
				fontSize: '14px',
				paddingLeft: 10,
			},
			'span'
		),
	},
	phoneNumberEditorContainer: {
		flexGrow: 1,
		width: '100%',
		...CssSelectors.allDescendants(
			{
				paddingBottom: 0,
				width: '100%',
			},
			'.phone-number-inputs-container'
		),
		...CssSelectors.allDescendants(
			{
				marginTop: 27,
			},
			'button'
		),
		...CssSelectors.allDescendants(
			{
				maxWidth: 110,
				minWidth: 100,
			},
			'.phone-number-inputs-container:nth-child(n) > :first-child'
		),
		...CssSelectors.allDescendants(
			{
				marginLeft: 12,
				marginTop: 26,
			},
			'button'
		),
	},
	saveButton: {
		':disabled': {
			opacity: 0.5,
		},
	},
	select: {
		background: '#F9F9F9',
		border: '1px solid #ccc',
		height: 41,
	},
	splitFieldContainer: {
		justifyContent: 'space-between',
		width: 'calc(100% - 30px)',
	},
	title: {
		fontSize: '18px',
	},
});
