import { brandPrimary } from '../../../../styles/colors';
import { styleSheet } from '../styles';
import { CalendarDateIcon } from '@WebComponents/svgs/icons/CalendarDateIcon';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';

interface IRowElementProps {
	dateValue: string;
}

export const FullDateIndicator: React.FC<IRowElementProps> = ({ dateValue }) => {
	if (!dateValue) {
		return <div>no date to show</div>;
	}

	const momentDate = moment(dateValue);
	const isMidnight = momentDate.hours() === 0 && momentDate.minutes() === 0 && momentDate.seconds() === 0;
	const formattedDate = momentDate.format(`M/DD/YY${isMidnight ? '' : ' h:mm A'}`);
	return (
		<div className={css(styleSheet.lastDealContainer)}>
			<div>
				<span>{formattedDate}</span>
				<CalendarDateIcon fillColor={brandPrimary} className={css(styleSheet.iconPosition)} />
			</div>
		</div>
	);
};
