import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const CallLogIcon: React.FC<IProps> = ({ className = '', fill }) => {
	return (
		<SvgIcon className={`phone-icon ${className || ''}`} height={20} width={21}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12 1C12 1.553 11.552 2 11 2C10.448 2 10 1.553 10 1C10 0.448 10.448 0 11 0C11.552 0 12 0.448 12 1'
				fill={fill}
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M21 2H14V0H21V2Z' fill={fill} />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12 5C12 5.553 11.552 6 11 6C10.448 6 10 5.553 10 5C10 4.448 10.448 4 11 4C11.552 4 12 4.448 12 5'
				fill={fill}
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M21 6H14V4H21V6Z' fill={fill} />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12 9C12 9.553 11.552 10 11 10C10.448 10 10 9.553 10 9C10 8.448 10.448 8 11 8C11.552 8 12 8.448 12 9'
				fill={fill}
			/>
			<path fillRule='evenodd' clipRule='evenodd' d='M21 10H14V8H21V10Z' fill={fill} />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2 3V8C2 13.514 6.486 18 12 18H17.002L17 14H13V15C13 15.552 12.552 16 12 16C7.512 16 4.072 12.567 4 8.016C3.996 7.748 4.099 7.49 4.287 7.298C4.475 7.107 4.732 7 5 7H6V3H2ZM17 20H12C5.383 20 0 14.617 0 8V3C0 1.897 0.897 1 2 1H6C7.103 1 8 1.897 8 3V7C8 8.072 7.152 8.95 6.092 8.998C6.529 11.582 8.459 13.518 11.002 13.922C11.042 12.855 11.923 12 13 12H17C18.103 12 19 12.897 19 14V18C19 19.103 18.103 20 17 20V20Z'
				fill={fill}
			/>
		</SvgIcon>
	);
};
