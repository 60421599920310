import { CssSelectors } from '../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	blueModal: {
		...CssSelectors.allChildren(
			{
				borderRadius: 4,
			},
			'.modal-overlay-content'
		),
		...CssSelectors.allDescendants(
			{
				':hover': {
					cursor: 'pointer',
				},
				background: 'none',
				border: 'none',
				outline: 'none',
			},
			'.modal-overlay-content-header button'
		),
	},
	blueModalAfterOpen: {
		background: 'rgba(4, 108, 182, 0.95)',
		transition: 'background 0.3s',
	},
	blueModalBeforeClose: {
		background: 'transparent',
		transition: 'background 0.3s',
	},
});
