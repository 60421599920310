import { css } from 'aphrodite';
import { FC } from 'react';
import * as React from 'react';
import { LightningBoltIcon } from '../../../web/components/svgs/icons/LightningBoltIcon';
import { brandPrimary } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { BasicPage } from '../BasicPage';
import { styleSheet } from './styles';

export const HelpContainer: FC = () => {
	return (
		<BasicPage className={`${css(styleSheet.container)}`} title='Help & FAQs'>
			<Question question='My Dialpad app is not ringing? Dialpad is not working...'>
				<Answer>
					First, try to log out and log back in to Dialpad. Signing back in seems to fix most issues. If that does not
					fix your dialing, then try restarting the dialpad app. If restarting the dialpad app doesn&apos;t work, then
					try restarting your computer. Please make sure you have a stable internet connection and are using an ethernet
					cable, not WiFi if possible. If you do not have any luck after these troubleshooting steps please speak to
					your manager since there may be other issues with your Dialpad account or computer.
				</Answer>
				<Tip>
					To avoid recurring issues with the Dialpad app, and other general slowness on your computer - you should
					restart or shut down your computer at least every other day. A good practice would be to shut your computer
					down when you finish work for the day. When you start work in the morning everything comes up fresh and allows
					Dialpad to reset the memory it has used. Most apps (like Google Chrome) will re-open the tabs you had or you
					can set up a profile with your preset tabs.
				</Tip>
			</Question>
			<Question question='What happens if someone calls me back? Will I get a voicemail?'>
				<Answer>
					The dialpad app will show you a popup with inbound calls. Within COFFEE, you will also see a Mrry (wizard)
					popup that by clicking on will execute a search of the phone number. If you aren&apos;t able to pick up the
					phone, then the caller will be able to leave a voicemail with Dialpad. You can find voicemails by going to{' '}
					<b>Inbox</b> &gt; <b>Voicemails</b>.
				</Answer>
			</Question>
			<Question question="I've set follow ups. When will they show up?">
				<Answer>
					If you set a follow up for a specific time then the lead will be served to you at exactly that time. If you
					are on another call at the exact time it was meant to be served then it will be served directly after. If you
					set a follow up for a generic time like &quot;Tomorrow&quot; or &quot;This afternoon&quot; then COFFEE has
					more discretion on when to serve the lead.
				</Answer>
			</Question>
			<Question question="I'm setting a follow up for 'Tomorrow', but tomorrow is Saturday. What happens?">
				<Answer>
					COFFEE will automatically account for business days and adjust your follow up to be on the next business day.
					The same logic applies if you pick a different follow up option like &apos;3 Days&apos;, or &apos;In a
					week&apos;, etc.
				</Answer>
			</Question>
			<Question question='If I send an email through COFFEE, will my signature show up?'>
				<Answer>
					Please work with your manager to make sure your signature is set up correctly for COFFEE. The COFFEE account
					is totally separate from your Levitate account, so you&apos;ll need to set up a new signature for your COFFEE
					account. To set it up, you can go to{' '}
					<a
						className={css(aidaBaseStyles.brandSecondaryLink)}
						href='https://secure.levitate.ai/#/logout'
						target='_blank'
						rel='noreferrer'
					>
						Levitate
					</a>{' '}
					and login with your <b>+aida</b> email address (first.last+aida@levitateapp.com). Navigate to <b>Settings</b>{' '}
					&gt; <b>Email Signature</b>.
				</Answer>
			</Question>
			<Question question="I can't find the lead I'm looking for with COFFEE search. How can I find it?">
				<Answer>
					The COFFEE search uses weighted criteria when searching the database. That can be problematic when many of the
					company names have &apos;Insurance&apos;, or &apos;CPA&apos;, in the name of the lead. To look for exact
					matches, put the search in quotes like &apos;&quot;Acme Insurance Co.&quot;&apos; If you enter in a phone
					number (in any format) then COFFEE looks for a lead with an exact match of that phone number. Domain searches
					are another good way to find a lead you are looking for, since HubSpot requires unique domains among each
					lead. Don&apos;t forget, if you have talked to the lead recently, they should be easy to find in your call
					log.
				</Answer>
				<Tip>
					COFFEE search also allows you to exclude search terms with a - (minus sign). For example, to search for all
					leads that include the word Acme, but exclude the word insurance, you would search for &apos;Acme
					-Insurance&apos;.
				</Tip>
			</Question>
			<Question question="If I don't set a follow up for a lead, will it automatically set a follow up for me?">
				<Answer>
					COFFEE will automatically choose the recommended follow up interval for you at the bottom of the notes panel.
					If you want to change the selection or choose a specific time, you may also do so within the follow up
					dropdown on the notes panel. Be aware that <b>No Answer</b> or <b>GK Blocked</b> outcomes will not
					automatically recommend a follow up thanks to feedback from our SDR beta testers!
				</Answer>
			</Question>
			<Question question="How can I learn more about a lead? What is the 'Google' button?">
				<Answer>
					There is a &apos;Google&apos; link above each lead and contact that will open a new tab with a google search
					including all the information we have for that lead or individual. The google search includes the name, email,
					phone number and address automatically. All you need to do is click on the link to view the search results.
				</Answer>
			</Question>
			<Question question='The lead is missing some information from HubSpot? How can I double check?'>
				<Answer>
					There is a &apos;HubSpot&apos; link above the lead name that will take you directly to the company in HubSpot.
					If you need to reference something there this link is the fastest way to get to it. As COFFEE becomes more
					stable, you should not need to reference HubSpot much, if at all. If there is some information you feel is not
					being carried over from HubSpot please bring it to the attention of your manager.
				</Answer>
			</Question>
			<Question question="How can I view a previous lead that I've called?">
				<Answer>
					The fastest way to find a recently called lead is your call log. It will show all the calls you made, in
					order. The call log also displays the outcome of the call and the primary contact or DM if there is one set on
					the lead. Click on the lead in the call log to view it. If the lead was someone you called a while ago, it
					would be easiest to perform a search. When searching, you can use quotes to find exact matches.
				</Answer>
			</Question>
			<Question question='How can I view my follow ups/tasks?'>
				<Answer>
					The follow ups tab will display your tasks in the order that they are due. So, you will see overdue tasks
					first, then follow ups for today, and then follow ups for tomorrow, etc. If there is a clock icon next to the
					time it is due then that means it is &quot;scheduled&quot; for that exact time to show up. If there is no
					clock icon it is not scheduled and will be served a little more loosely. Though it should still be generally
					around or right after the time it shows on the follow up screen. The non-scheduled follow ups are created by
					selecting generic options like &quot;Tomorrow&quot;, or &quot;In a Week&quot;, where it is not important that
					you call at a specific time of day.
				</Answer>
				<Tip>
					Follow ups will show up automatically in your call list. You can use the follow ups page as a reference, or if
					you want to knock them all out in one shot. But they will also be served to you at the correct time without
					you needing to think about it.
				</Tip>
			</Question>
			<Question question='How do I set a follow up? Can I set it for a specific time?'>
				<Answer>
					You can set a follow up in the notes panel after a call. COFFEE will recommend a follow up time frame for
					specific call outcomes/dispositions. If you do not want to use the recommended window to follow up, you can
					always set a specific time. If you want, you can always
				</Answer>
				<Answer>
					When the time picker opens, it defaults to the lead&apos;s timezone. This way, if the person says &quot;Call
					me back at 3 my time,&quot; you can pick the time without having to think about the time difference.
					Optionally, you can change the timezone to EST if you know when to call back based on your time. Either way,
					when you click &quot;Save,&quot; the timezone is converted and displayed in EST so you can confirm that it is
					the correct time.
				</Answer>
			</Question>
			<Question question='I think there is a duplicate lead. What should I do?'>
				<Answer>
					Duplicate leads need to be reconciled in HubSpot. Please reach out to the Rev Ops team using the COFFEE chat
					channel to let them know about the two leads that you think are duplicates. Then, the Rev Ops team can merge
					the leads and that merge will be reflected in COFFEE.
				</Answer>
			</Question>
			<Question question='I ran out of leads. Who you gonna call? Rev Ops'>
				<Answer>
					Please reach out to the Rev Ops team using the COFFEE chat channel if you have run out of leads. Let someone
					know right away since you should never be without leads! The COFFEE team may need to check what filters are
					currently applied and make sure you have enough leads for the current blitz.
				</Answer>
			</Question>
			<Question question='My dial count is not correct. Why is it off?'>
				<Answer>
					Your dials and score rely on a persistent connection to our server to keep up to date. While COFFEE is in
					beta, this connection may occasionally break. Refresh the page to re-establish the connection. If this happens
					to you frequently please let the engineering team know. If you&apos;re not sure about your dial count, you can
					always check where you fall on the leaderboard.
				</Answer>
			</Question>
			<Question question='Why do I have to log in using a +aida after my email?'>
				<Answer>
					Your COFFEE account is completely separate from your Levitate account. It is a whole new user! Keeping the
					COFFEE account separate helps keep the leads organized appropriately. If you log in with the wrong account,
					COFFEE will show you a large banner across the screen to let you know. Simply log out and log in with the
					correct email <b>first.last+aida@levitateapp.com</b>.
				</Answer>
			</Question>
			<Question question="There is a problem with COFFEE, I can't move forward. What do I do?">
				<Answer>
					Please select &quot;Skip/Remove Lead&quot; and then select &quot;Problem with COFFEE&quot; as the reason. By
					selecting problem with COFFEE as the reason, the lead will not actually be removed from the lead pool. You may
					also use this same flow if you were served a lead you should not have been served. For example, if you talked
					to them this morning, or you have a follow up set for a later date. The COFFEE team will also get a
					notification to investigate the problem, so please leave a note about what went wrong in COFFEE.
				</Answer>
			</Question>
			<Question question='What is the Golden Hour?'>
				<Answer>
					The &apos;Golden Hour&apos; label indicates that it is a historically good time to call into this area code.
				</Answer>
				<Tip>
					Contrary to popular belief, historical data from all the calls ever made from Levitate indicate that the lunch
					hour is actually a good time to call. The DPD rate is high!
				</Tip>
			</Question>
			<Question question='Can I edit a note? Why not?'>
				<Answer>
					Right now, you cannot edit a note on a lead. This is a feature that we plan to build into COFFEE soon. If you
					need to write something else down, or make a correction - use the blue button with a pen on it to create a
					note without making a call.
				</Answer>
			</Question>

			<Question question="Have a question you don't see answered here?">
				<Answer>
					Email nick.beaulieu@levitateapp.com or chat message Nick Beaulieu to have a new question answered here. If you
					have a question, your other teammates may have it too. Don&apos;t be afraid to ask for help! If you have a
					one-off question or a question about a specific lead, you can ask it in the appropriate COFFEE chat channel.
				</Answer>
			</Question>
		</BasicPage>
	);
};

interface IProps {
	question: string;
}

export const Question: React.FC<IProps> = props => {
	const { question, children } = props;
	return (
		<div className={css(styleSheet.question)}>
			<h3 className={css(styleSheet.questionTitle)}>{question}</h3>
			{children}
		</div>
	);
};

const Answer: FC = ({ children }) => {
	return <p className={css(styleSheet.answer)}>{children}</p>;
};

interface ITipProps {
	label?: string;
}

const Tip: FC<ITipProps> = props => {
	const { label = '', children } = props;
	return (
		<div className={css(styleSheet.tipContainer)}>
			<div className={css(styleSheet.tipTitle)}>
				<LightningBoltIcon fillColor={brandPrimary} className={css(styleSheet.lightning)} />
				{` Tip! ${label}`}
			</div>
			<p className={css(styleSheet.tipContent)}>{children}</p>
		</div>
	);
};
