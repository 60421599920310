import { CssSelectors } from '../../../web/styles/styles';
import { brandPrimary, error } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	addItemContainer: {
		alignItems: 'flex-end',
		alignSelf: 'flex-end',
		display: 'flex',
		minWidth: 30,
		padding: '0 0 9px 10px',
	},
	contactInfoContainer: {
		...CssSelectors.allDescendants(
			{
				display: 'block',
				fontSize: '14px',
				paddingBottom: 5,
				...CssSelectors.allDescendants(
					{
						color: error,
						padding: '3px 0 0 2px',
					},
					'sup'
				),
			},
			'label'
		),
	},
	contactInfoModal: {
		...CssSelectors.allChildren(
			{
				maxHeight: '80vh',
				overflow: 'auto',
			},
			'.modal-overlay-content'
		),
	},
	emailFieldContainer: {
		flexGrow: 1,
	},
	fieldContainer: {
		padding: '0 4px 15px',
	},
	footer: {
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'space-between',
		...CssSelectors.allDescendants({
			':hover:disabled': {
				background: brandPrimary,
			},
		}),
	},
	header: {
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'space-between',
		paddingBottom: 30,
	},
	markAsDMContainer: {
		...aidaBaseStyles.flexHorizontalCenter,
		...CssSelectors.allChildren(
			{
				paddingBottom: 0,
			},
			'label'
		),
		...CssSelectors.allChildren(
			{
				color: '#858585',
				fontSize: '14px',
				paddingLeft: 10,
			},
			'span'
		),
	},
	phoneNumberEditorContainer: {
		flexGrow: 1,
		...CssSelectors.allDescendants(
			{
				paddingBottom: 0,
			},
			'.phone-number-inputs-container'
		),
		...CssSelectors.allDescendants(
			{
				marginTop: 27,
			},
			'button'
		),
	},
	saveButton: {
		':disabled': {
			opacity: 0.5,
		},
	},
	splitFieldContainer: {
		justifyContent: 'space-between',
	},
	title: {
		fontSize: '18px',
	},
});
