import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { baseStyleSheet } from '../../../../web/styles/styles';
import { useSlotMachineModalContext } from '../../../components/slotMachines/SlotMachineModalProvider/context';
import { SlotMachineViewModel, UserSlotMachinePrizesViewModel } from '../../../viewModels/slotMachines';
import { BasicPage } from '../../BasicPage';
import { HistoryRow, SpinRow } from './presentation';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

export const SlotMachinePrizes: React.FC<IProps> = observer(props => {
	const { className, styles = [] } = props;
	const { playSlotMachine } = useSlotMachineModalContext();
	const userSession = useUserSession();

	const show = (machine: SlotMachineViewModel) => () => {
		playSlotMachine(machine);
	};
	const slotMachinePrizes = React.useRef<UserSlotMachinePrizesViewModel>(
		new UserSlotMachinePrizesViewModel(userSession)
	).current;

	React.useEffect(() => {
		slotMachinePrizes.load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderAvailableSpins = React.useCallback(() => {
		return (
			<section className={css(styleSheet.contentColumn)}>
				{[Api.SlotMachineType.Default, Api.SlotMachineType.GoldRush, Api.SlotMachineType.BoozeCruise].map(x => {
					const machine = slotMachinePrizes?.slotMachines?.[x];
					return <SpinRow key={x} machineType={x} onClick={show(machine)} slotMachine={machine} />;
				})}
			</section>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slotMachinePrizes.slotMachines]);

	const renderHistory = React.useCallback(() => {
		return (
			<ul className={css(styleSheet.contentColumn)}>
				{slotMachinePrizes.history?.map(prizeLog => (
					<HistoryRow prizeLog={prizeLog} key={prizeLog.gameToken.id} />
				))}
			</ul>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<BasicPage
			className={`${css(styleSheet.container, ...styles)} slot-machine-prizes ${className || ''}`}
			title='Slot Machine Prizes'
		>
			{slotMachinePrizes.isLoading ? (
				<LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} />
			) : (
				<>
					{renderAvailableSpins()}
					{renderHistory()}
				</>
			)}
		</BasicPage>
	);
});
