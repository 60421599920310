import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	isBlocked?: boolean;
	maxWidth?: number;
	showLoading?: boolean;
	smallBottomMargin?: boolean;
	title?: string;
	titleAlignment?: 'left' | 'center' | 'right';
}

export const ContentBox: React.FC<IProps> = ({
	children,
	className,
	isBlocked,
	maxWidth,
	showLoading,
	smallBottomMargin,
	title,
	titleAlignment = 'left',
}) => {
	const renderHeader = title && (
		<h3 className={css(styleSheet.header)} style={{ textAlign: titleAlignment }}>
			{title}
		</h3>
	);
	const containerSmallDistance = smallBottomMargin ? styleSheet.containerSmallDistance : null;
	return (
		<div className={`${css(styleSheet.container, containerSmallDistance)} ${className}`} style={{ maxWidth }}>
			{renderHeader}
			{children && (
				<div className={css(styleSheet.content)}>
					{isBlocked && (
						<div className={css(styleSheet.splashScreen)}>{showLoading && <LoadingSpinner type='large' />}</div>
					)}
					{children}
				</div>
			)}
		</div>
	);
};
