import { CssSelectors, FontFamilies } from '../../../web/styles/styles';
import { white } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const MemeModalZIndex = 99;

const lightning1Keyframes = {
	'0%': {
		top: -12,
		transform: 'rotateZ(10deg)',
	},
	'100%': {
		top: -40,
		transform: 'rotateZ(-10deg)',
	},
	'50%': {
		top: -12,
		transform: 'rotateZ(10deg)',
	},
	'51%': {
		top: -40,
		transform: 'rotateZ(-10deg)',
	},
};

const lightning2Keyframes = {
	'0%': {
		transform: 'rotateZ(10deg)',
	},
	'100%': {
		transform: 'rotateZ(-10deg)',
	},
	'50%': {
		transform: 'rotateZ(10deg)',
	},
	'51%': {
		transform: 'rotateZ(-10deg)',
	},
};

export const styleSheet = StyleSheet.create({
	closeMeme: {
		height: 20,
		position: 'fixed',
		right: 30,
		top: 20,
		width: 20,
		...CssSelectors.allChildren(
			{
				height: 30,
				width: 30,
			},
			'svg'
		),
	},
	lightningGraphic1: {
		animationDuration: '1s',
		animationIterationCount: 'infinite',
		animationName: [lightning1Keyframes],
		left: 100,
		position: 'fixed',
		top: -12,
	},
	lightningGraphic2: {
		animationDuration: '1s',
		animationIterationCount: 'infinite',
		animationName: [lightning2Keyframes],
		position: 'fixed',
		right: '-60px',
		top: '45vh',
	},
	memeImgContainer: {
		borderRadius: '50%',
		height: 250,
		position: 'relative',
		width: 250,
	},
	memeImgWrapper: {
		overflow: 'hidden',
		...CssSelectors.allChildren(
			{
				display: 'block',
				height: '100%',
				width: '100%',
			},
			'img'
		),
	},
	memeModal: {
		...aidaBaseStyles.flexCol,
		alignItems: 'center',
		maxWidth: 800,
		minWidth: 800,
		position: 'relative',
	},
	memeModalOverlay: {
		...aidaBaseStyles.flexCenter,
		background: 'rgba(4, 108, 182, 0.95)',
		height: '100vh',
		left: 0,
		position: 'fixed',
		top: 0,
		width: '100vw',
		zIndex: MemeModalZIndex,
	},
	memePhrase: {
		color: white,
		fontFamily: FontFamilies.conqueror,
		fontSize: '64px',
		marginTop: 30,
		textAlign: 'center',
	},
	memePoints: {
		borderRadius: 30,
		padding: '10px 30px',
		position: 'absolute',
		...CssSelectors.allChildren(
			{
				':first-child': {
					fontSize: '36px',
					lineHeight: '1em',
					marginRight: 4,
				},
			},
			'span'
		),
	},
	memePointsBottom: {
		left: '50%',
		top: '100%',
	},
	memePointsBottomLeft: {
		right: '90%',
		top: '100%',
	},
	memePointsBottomRight: {
		left: '90%',
		top: '100%',
	},
	memePointsCenter: {
		left: '50%',
		top: '50%',
	},
	memePointsLeft: {
		right: '100%',
		top: '50%',
	},
	memePointsRight: {
		left: '100%',
		top: '50%',
	},
	memePointsTop: {
		left: '50%',
		top: 0,
	},
	memePointsTopLeft: {
		right: '90%',
		top: 0,
	},
	memePointsTopRight: {
		left: '90%',
		top: 0,
	},
	memeVideoContainer: {
		borderRadius: '50%',
		height: 500,
		position: 'relative',
		width: 500,
	},
	memeVideoWrapper: {
		overflow: 'hidden',
		...CssSelectors.allChildren(
			{
				display: 'block',
				height: '100%',
				width: '100%',
			},
			'video'
		),
	},
});
