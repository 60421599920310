import { Layouts } from '../../../web/styles/styles';
import { brandPrimaryHover, brandSecondary, grayDark, white } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		alignItems: 'center',
		display: 'flex',
	},
	dealDate: {
		color: '#858585',
	},
	dealText: {
		':hover': {
			color: brandPrimaryHover,
			textDecoration: 'underline',
		},
		cursor: 'pointer',
		fontSize: 14,
		marginLeft: 10,
	},
	dollarIcon: {
		width: 20,
	},
	prevDeal: {
		...Layouts.verticalStack(10),
	},
	prevDealDate: {
		color: grayDark,
	},
	prevDealDescription: {
		color: grayDark,
	},
	prevDealLostReason: {
		fontSize: 14,
	},
	prevDealName: {
		fontSize: 14,
	},
	prevDeals: {
		background: white,
		borderRadius: 10,
		boxShadow: `0 1px 4px 1px rgba(0, 0, 0, 0.2)`,
		fontSize: 12,
		maxHeight: 400,
		maxWidth: 500,
		overflowY: 'auto',
		padding: 20,
	},
	prevDealsAnchor: {
		color: brandSecondary,
		fontSize: 14,
		height: '100%',
		marginLeft: 10,
		textDecoration: 'underline',
	},
	prevDealsTitle: {
		fontSize: 16,
	},
});
