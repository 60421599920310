import { SailboatGraphic } from '../SailboatGraphic';
import { SailboatWaterGraphic } from '../SailboatWaterGraphic';
import { styleSheet } from './styles';
import { animated, config, useSpring } from '@react-spring/web';
import { css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	animate?: boolean;
	className?: string;
}

const from = {
	transform: 'rotateZ(-20deg) translate(-31%, -74%)',
};
const to = {
	transform: 'rotateZ(12deg) translate(-55%, -55%)',
};

export const HookRejectedGraphic: React.FC<IProps> = ({ animate, className = '' }) => {
	const spring = useSpring({
		config: config.gentle,
		from,
		to: animate ? to : from,
	});

	return (
		<div className={`${css(styleSheet.hookRejectedGraphicContainer)} ${className}`}>
			<SailboatWaterGraphic className={css(styleSheet.sailboatWater)} />
			<animated.div className={css(styleSheet.sailboat)} style={spring}>
				<SailboatGraphic />
			</animated.div>
		</div>
	);
};
