import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { ClockAlertIcon } from '../../../../../web/components/svgs/icons/ClockAlertIcon';
import { FollowUpsIcon } from '../../../../../web/components/svgs/icons/FollowUpsIcon';
import { brandPrimary, error, orange, success, white } from '../../../../styles/colors';
import { FollowUpImportance, ILeadFollowUp } from '../../../../viewModels/queue';
import { PepperIcon } from '../../../svgs/icons/PepperIcon';
import { styleSheet } from '../styles';

export const FollowUp: React.FC<ILeadFollowUp> = ({ followUpDate, scheduled, importance }) => {
	if (!followUpDate) {
		return <div>no follow up</div>;
	}

	const fu = moment(followUpDate);
	const isMidnight = fu.hours() === 0 && fu.minutes() === 0 && fu.seconds() === 0;
	let formattedDate = fu.format(`M/DD/YY${isMidnight ? '' : ' h:mm A'}`);
	const isScheduled = scheduled;
	if (!isScheduled) {
		if (fu.isSame(moment(), 'day')) {
			formattedDate = 'Today';
		} else if (fu.isSame(moment().add(1, 'day'), 'day')) {
			formattedDate = 'Tomorrow';
		}
	}

	const renderPepper = () => {
		if (!scheduled) {
			return null;
		}

		const pepperFill =
			importance === FollowUpImportance.High ? error : importance === FollowUpImportance.Medium ? orange : success;

		return <PepperIcon className={css(styleSheet.pepper)} fillColor={pepperFill} />;
	};

	return (
		<div className={css(styleSheet.followUpContainer)}>
			<div>
				<span>{formattedDate}</span>
				<span className={css(styleSheet.followUpClockIcon)}>
					<FollowUpsIcon fill={white} />
				</span>
				<div>
					{fu.isBefore(moment()) && <span className={css(styleSheet.followUpOverdue)}>Overdue</span>}
					{scheduled && (
						<span className={css(styleSheet.followUpClockIcon)}>
							<ClockAlertIcon fillColor={brandPrimary} />
						</span>
					)}
					{renderPepper()}
				</div>
			</div>
		</div>
	);
};
