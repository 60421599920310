import { usePagedCollection } from '../../../entities/utils/collections';
import * as Api from '@ViewModels';
import * as React from 'react';

export const usePrizeLog = (userSession: Api.UserSessionContext) => {
	const [exporting, setExporting] = React.useState<boolean>(false);

	const { fetching, items, getItems, setItems } = usePagedCollection<
		Api.IPrizeLog,
		Api.IPrizeLog,
		Api.IGameTokenFilter
	>(userSession, {
		apiPath: 'game/prize-log/filter',
	});

	const updatePaidStatus = React.useCallback(
		async (gameTokenId: string) => {
			const opResult = await userSession.webServiceHelper.callWebServiceAsync<any>(
				`game/token/${gameTokenId}/prizePaid`,
				'POST'
			);

			const nextItems = [...(items || [])];
			const index = nextItems.findIndex(x => x.gameToken?.id === gameTokenId);
			if (index >= 0) {
				nextItems.splice(index, 1, opResult.value);
				setItems(nextItems);
			}

			if (!opResult.success) {
				throw opResult;
			}
			return opResult;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[items]
	);

	const exportToCsv = React.useCallback(async (filter: Api.IGameTokenFilter) => {
		setExporting(true);
		const opResult = await userSession.webServiceHelper.callWebServiceAsync<Api.ISystemJob>(
			'game/prize-log/export',
			'POST',
			filter
		);
		setExporting(false);
		if (!opResult.success) {
			throw opResult;
		}
		return opResult;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { exporting, exportToCsv, fetching, getItems, items, updatePaidStatus } as const;
};
