import { CssSelectors, Layouts } from '../../../web/styles/styles';
import { borderColor, destructive, titles } from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	buttonContainer: {
		...Layouts.horizontalStack(),
		marginTop: 20,
	},
	close: {
		marginLeft: 30,
		marginTop: 3,
	},
	container: {
		...aidaBaseStyles.flexHorizontalCenter,
	},
	editorContainer: {
		border: `1px solid ${borderColor}`,
		minWidth: 400,
		...CssSelectors.allDescendants(
			{
				borderBottom: `1px solid ${borderColor}`,
			},
			'.tox-editor-header'
		),
	},
	header: {
		...aidaBaseStyles.flexHorizontalCenter,
		justifyContent: 'space-between',
		marginBottom: 10,
		position: 'relative',
	},
	mainContainer: {
		display: 'flex',
		minHeight: 300,
	},
	noteRequired: {
		color: destructive,
		fontSize: 14,
		paddingLeft: 55,
	},
	removeLeadModal: {},
	text: {
		width: 100,
	},
	title: {
		color: titles,
		fontSize: '18px',
	},
});
