import { CssSelectors } from '../../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	background: {
		height: '100%',
		position: 'absolute',
		width: '100%',
	},
	boozeCruiseContainer: {
		height: 541,
		width: 541,
	},
	container: {
		color: '#FFF',
		fontSize: 40,
		height: 377,
		position: 'relative',
		width: 828,
	},
	content: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		fontSize: 28,
		height: '100%',
		justifyContent: 'center',
		margin: '0 auto',
		padding: '0 12%',
		position: 'absolute',
		textAlign: 'center',
		width: '100%',
		...CssSelectors.allChildren({
			margin: '0 auto',
		}),
	},
	continueButton: {
		bottom: '12%',
		color: '#FFF',
		fontSize: 32,
		left: '50%',
		position: 'absolute',
		transform: 'translate(-50%, 0)',
	},
	modal: {
		...CssSelectors.allDescendants(
			{
				background: 'transparent',
				border: 'none',
				boxShadow: 'none',
			},
			'.modal-overlay-content'
		),
		...CssSelectors.allDescendants(
			{
				background: 'rgba(0,0,0,0.50)',
			},
			'.modal-overlay'
		),
	},
	symbol: {
		':not(:first-child)': {
			marginLeft: 20,
		},
		height: 60,
		width: 'auto',
	},
	title: {
		height: 'auto',
		left: '50%',
		position: 'absolute',
		top: 0,
		transform: 'translate(-50%, -60%)',
		width: '50%',
		zIndex: 1,
	},
	titleContent: {
		position: 'relative',
	},
});
