import { CssSelectors } from '../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	disabled: {
		opacity: 0.5,
	},
	imgContainer: {
		borderRadius: '50%',
		height: 40,
		overflow: 'hidden',
		width: 40,
		...CssSelectors.allChildren(
			{
				display: 'block',
				height: '100%',
				width: '100%',
			},
			'img'
		),
	},
	socialProfilesContainer: {
		display: 'flex',
	},
});
