import { CssSelectors } from '../../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

const titleHeight = 20;
const titleMargin = 10;

export const styleSheet = StyleSheet.create({
	activityTable: {
		marginTop: titleHeight + titleMargin,
	},
	body: {
		boxSizing: 'border-box',
		display: 'flex',
		...CssSelectors.allChildren({
			':not(:first-child)': {
				marginLeft: 20,
			},
			maxWidth: '50%',
			minWidth: '50%',
		}),
		margin: '0 auto',
		maxWidth: 1200,
		paddingBottom: 30,
		width: '80%',
	},
	container: {},
	pageHeader: {
		boxShadow: '0px 5px 5px -4px rgba(0, 0, 0, 0.5)',
		height: 14,
		marginBottom: 28,
		width: '100%',
	},
	prizeTableTitle: {
		color: '#fff',
		fontSize: 14,
		height: titleHeight,
		marginBottom: titleMargin,
	},
});
