/* eslint-disable react-hooks/exhaustive-deps */
import { css } from 'aphrodite';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import {
	IPhoneCall,
	ITranscription,
	ITranscriptionSpeakerLines,
	PhoneCallRecordingTranscriptionStatus,
	TranscriptionStatus,
} from '../../../../extViewmodels';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { TranscriptionEventsContext } from '../../../entities/PhoneCall/TranscriptionEventsProvider';
import { useTranscription } from '../../../entities/PhoneCall/useTranscription';
import { FetchStatus } from '../../../entities/utils/useStatesOnFetch';
import { TransparentButton } from '../../TransparentButton';
import { TranscriptionIcon } from '../../svgs/icons/TranscriptionIcon';
import { styleSheet } from '../styles';
import { SpeakerLinesComponent } from './SpeakerLinesComponent';

interface IProps {
	phoneCall: IPhoneCall;
	transcriptionId: string;
}

export const CallDetailsTranscription: React.FC<IProps> = ({ phoneCall, transcriptionId }) => {
	const phoneCallId = phoneCall.id;

	const [transcriptionStatus, setStatus] = useState<TranscriptionStatus>(TranscriptionStatus.Unknown);

	const [currentTranscriptionId, setTranscriptionId] = useState<string>(transcriptionId);

	const userSession = useUserSession();

	const transcription = useTranscription<ITranscription>();
	const speakerLines = useTranscription<ITranscriptionSpeakerLines>();

	const { eventPayload } = useContext(TranscriptionEventsContext);

	const handleTranscribeRequest = (id: string) => {
		if (!currentTranscriptionId) {
			setTranscriptionId(id);
		}
		// @ts-ignore
		transcription.get(id).then(({ value }) => {
			if (value.status === TranscriptionStatus.Completed) {
				setStatus(value.status);
				speakerLines.get(id, true);
				return;
			}
			setStatus(value.status);
		});
	};

	useEffect(() => {
		if (transcriptionId) {
			setTranscriptionId(transcriptionId);
			handleTranscribeRequest(transcriptionId);
		}
	}, [transcriptionId]);

	useEffect(() => {
		if (eventPayload) {
			handleTranscribeRequest(currentTranscriptionId);
		}
	}, [eventPayload]);

	// @ts-ignore
	// @ts-ignore
	const handleCreate = () => transcription.create(phoneCallId).then(({ value }) => handleTranscribeRequest(value.id));

	const isLoadingTranscription = transcription.status === FetchStatus.Working;
	const isLoadingSpeakerLines = speakerLines.status === FetchStatus.Working;

	const isFailed = transcriptionStatus === TranscriptionStatus.Failed;
	const isInProgress = transcriptionStatus === TranscriptionStatus.InProgress;

	const showTranscribe = isFailed || (!currentTranscriptionId && !isInProgress);

	const recordingTranscriptionStatus = phoneCall.recordingTranscriptionStatus;
	const isNotAvailable =
		recordingTranscriptionStatus === PhoneCallRecordingTranscriptionStatus.RecordingValidationFailure ||
		recordingTranscriptionStatus === PhoneCallRecordingTranscriptionStatus.TranscriptionFailure;

	// @ts-ignore
	// @ts-ignore
	const canTranscribe = userSession.account.features.aida.transcriptionOnDemandEnabled && !isNotAvailable;

	const renderTranscribeButton = canTranscribe ? (
		<TransparentButton className={css(styleSheet.regularButton)} onClick={handleCreate}>
			<TranscriptionIcon /> Transcribe
		</TransparentButton>
	) : (
		<div className={css(styleSheet.regularButton)}>
			{isNotAvailable ? 'Unable to transcribe this recording' : 'Transcription not available'}
		</div>
	);

	return (
		<div className={css(styleSheet.sectionWrapper)}>
			<div className={css(styleSheet.sectionTitle)}>Call Transcription</div>
			<div className={css(styleSheet.transcriptionContainer)}>
				{showTranscribe && !isLoadingTranscription && renderTranscribeButton}

				<div>{isLoadingTranscription && <LoadingSpinner type='small' />}</div>

				{!showTranscribe && !isLoadingTranscription && (
					<SpeakerLinesComponent
						isLoading={isLoadingSpeakerLines}
						// @ts-ignore
						isRead={transcription?.data?.read}
						isSpeakerLinesReady={speakerLines.status === FetchStatus.Done}
						isTranscriptionCompleted={transcriptionStatus === TranscriptionStatus.Completed}
						// @ts-ignore
						speakerLines={speakerLines?.data?.speakerLines}
						transcriptionId={transcriptionId}
					/>
				)}
			</div>
		</div>
	);
};
