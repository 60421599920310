import { Button } from '../../../../web/components/Button';
import { TextInput } from '../../../../web/components/TextInput';
import { grayIconFill } from '../../../styles/colors';
import { Fill } from '../../leadView/components/Fill';
import { Group } from '../../leadView/components/Group';
import { SearchIcon } from '../../svgs/icons/SearchIcon';
import { styleSheet } from '../styles';
import { css } from 'aphrodite';
import { ChangeEvent, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router';

interface IProps {
	onSearchChange?: (value: string) => void;
	pathname: string;
}

export const PropertiesHeader: React.FC<IProps> = ({ onSearchChange, pathname }) => {
	const [searchValue, setSearchValue] = useState<string>('');

	const history = useHistory();

	const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event?.target?.value;
		setSearchValue(value);
		onSearchChange?.(value);
	};

	const handleClickToAdd = () => {
		history.push(`/integration/${pathname}-properties/adding`);
	};

	return (
		<Group className={css(styleSheet.propertiesHeader)} spacingSize={15} vertical={true}>
			<Group>
				<TextInput
					className={css(styleSheet.searchInput)}
					inputId='global-search-input'
					leftAccessory={<SearchIcon fillColor={grayIconFill} />}
					onChange={onSearch}
					placeholder='Search property name'
					type='text'
					value={searchValue}
				/>
				<Fill />
				<Button onClick={handleClickToAdd} label='Add Additional Properties' />
			</Group>
			<div className={css(styleSheet.propertiesHeaderTitle)}>Existing properties in Coffee</div>
		</Group>
	);
};
