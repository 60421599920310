import { CssSelectors } from '../../../web/styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	draggable: {
		cursor: 'pointer',
		opacity: 1,
		transition: 'opacity 0.1s',
	},
	draggableHandle: {
		...CssSelectors.allDescendants(
			{
				':active': {
					cursor: 'grabbing',
				},
				cursor: 'grab',
			},
			'.drag-handle'
		),
	},
	dragging: {
		opacity: 0.6,
	},
});
