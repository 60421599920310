import { CssSelectors, FontFamilies } from '../../../web/styles/styles';
import {
	brandPrimary,
	brandPrimaryLight,
	brandSecondary,
	gold,
	green,
	lightBlue,
	mentionDark,
	white,
	yellow,
	yellowDark,
} from '../../styles/colors';
import { aidaBaseStyles } from '../../styles/styles';
import { CSSProperties, StyleSheet } from 'aphrodite';

const avatarStyles: CSSProperties = {
	border: 'none',
	display: 'flex',
	minHeight: '100%',
	minWidth: '100%',
};

export const styleSheet = StyleSheet.create({
	arrowAsc: {
		transform: 'rotate(-90deg)',
	},
	arrowIcon: {
		height: 14,
		transform: 'rotate(90deg)',
		width: 14,
	},
	avatarContainer: {
		borderRadius: '50%',
		display: 'flex',
		minHeight: '100%',
		minWidth: '100%',
		overflow: 'hidden',
		...CssSelectors.allDescendants(
			{
				...avatarStyles,
			},
			'img'
		),
		...CssSelectors.allDescendants(
			{
				...avatarStyles,
			},
			'.avatar-content'
		),
		...CssSelectors.allDescendants(
			{
				...avatarStyles,
			},
			'.avatar'
		),
	},
	col: {
		color: white,
		fontFamily: FontFamilies.conqueror,
		fontSize: 22,
		paddingTop: 5,
	},
	colDate: {
		color: brandSecondary,
		flexGrow: 1,
		width: 170,
	},
	colMobile: {
		fontSize: 16,
		paddingTop: 3,
		width: 'auto',
	},
	colName: {
		flexGrow: 1,
		textAlign: 'left',
		whiteSpace: 'nowrap',
		width: 200,
	},
	colPrize: {
		color: gold,
		flexGrow: 1,
		width: 50,
	},
	colSymbol: {
		flexGrow: 0,
		flexShrink: 1,
		paddingTop: 4,
		width: 40,
	},

	columnNavItem: {
		borderRadius: 20,
		color: mentionDark,
		fontSize: 13,
		margin: 2,
		padding: '5px 15px',
		textTransform: 'uppercase',
	},
	columnNavItemSelected: {
		backgroundColor: brandPrimary,
		color: brandSecondary,
	},
	crown: {},
	crownUpsideDown: {
		transform: 'rotate(180deg) translate(50%, 50%) !important',
	},

	// Specific
	demosColActual: {
		color: white,
		textAlign: 'right',
	},
	demosColGoal: {
		color: lightBlue,
		textAlign: 'right',
	},
	demosColLabel: {
		color: white,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		width: 130,
	},
	demosColMobile: {
		color: white,
		textAlign: 'right',
		width: 70,
	},
	demosColProgress: {
		color: green,
		padding: 0,
	},
	demosColWrapper: {
		marginTop: 10,
	},
	demosContainer: {
		margin: 'auto',
		maxWidth: 748,
		width: '100%',
	},
	demosFooter: {
		fontFamily: FontFamilies.conqueror,
		fontSize: 20,
		width: 120,
	},
	demosFooterWrapper: {
		alignItems: 'center',
		borderTop: `solid 1px ${brandPrimaryLight}`,
		marginTop: 10,
		paddingTop: 10,
	},
	demosHeader: {
		fontFamily: FontFamilies.conqueror,
		fontSize: 22,
		width: 120,
	},
	demosHeaderWrapper: {
		alignItems: 'center',
		borderBottom: `solid 1px ${brandPrimaryLight}`,
		marginBottom: 10,
		paddingBottom: 10,
	},
	demosRow: {
		justifyContent: 'space-between',
		...aidaBaseStyles.flexHorizontalCenter,
	},
	demosRowHighlight: {
		':hover': {
			backgroundColor: 'rgba(255, 255, 255, .1)',
		},
		borderRadius: 5,
		height: 22,
		marginLeft: -3,
		marginRight: -3,
		padding: 3,
	},
	// Demos Total Dials Display
	demosTotalDialsDisplay: {
		color: yellow,
		fontFamily: FontFamilies.conqueror,
		fontSize: 20,
		textAlign: 'center',
	},
	demosTotalDialsDisplayContainer: {
		marginBottom: 40,
		marginTop: 10,
	},
	dropdownIcon: {
		height: 28,
		marginLeft: 10,
		transform: 'rotateZ(90deg)',
		width: 20,
	},
	first: {
		background: yellow,
		...CssSelectors.allChildren(
			{
				background: yellowDark,
			},
			'.num'
		),
	},
	header: {
		...aidaBaseStyles.flexCenter,
		background: brandPrimary,
		borderRadius: 40,
		color: white,
		display: 'inline-block',
		fontFamily: FontFamilies.conqueror,
		fontSize: 54,
		margin: 'auto',
		padding: '10px 25px 0',
		...CssSelectors.allDescendants({
			fontFamily: FontFamilies.conqueror,
		}),
	},
	headerContainer: {
		...aidaBaseStyles.flexCenter,
		display: 'block',
		marginTop: 50,
		padding: '50px 0 30px',
		textAlign: 'center',
		width: '100%',
	},
	headerMobile: {
		fontSize: 34,
		marginTop: -10,
		padding: '8px 20px 0',
	},
	invisible: {
		visibility: 'hidden',
	},
	leaderBoardContainer: {
		marginBottom: 20,
		padding: 15,
		width: '100%',
	},
	leaderBoardContainerTitle: {
		color: white,
		fontFamily: FontFamilies.conqueror,
		fontSize: 34,
		marginTop: 10,
		textAlign: 'center',
		textTransform: 'uppercase',
	},
	leaderBoardContainerTitleMobile: {
		fontSize: 28,
	},
	leaderBoardTotalScores: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 40,
	},
	leaderBoardTotalScoresTitle: {
		color: white,
		fontFamily: FontFamilies.conqueror,
		fontSize: 20,
		marginRight: 15,
		marginTop: 5,
		textTransform: 'uppercase',
	},
	leaderColumn: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-end',
		...CssSelectors.allChildren({
			':first-child': {
				marginRight: 6,
			},
			color: 'inherit',
		}),
		width: 130,
	},
	leaderName: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		width: 240,
	},
	leaderRow: {
		height: 33,
		justifyContent: 'space-between',
		...aidaBaseStyles.flexHorizontalCenter,
		...CssSelectors.allDescendants({
			color: white,
			fontFamily: FontFamilies.conqueror,
			fontSize: 24,
		}),
		':not(:last-child)': {
			marginBottom: 5,
		},
	},
	leaderRowHeader: {
		height: 20,
	},
	leadersContainer: {
		...aidaBaseStyles.flexCol,
		margin: 'auto',
		maxWidth: 748,
	},
	// Column
	listColumnNav: {
		display: 'flex',
		margin: 'auto',
		marginBottom: 30,
		marginTop: 30,
		width: 'fit-content',
	},
	notFirst: {
		maxHeight: 100,
		maxWidth: 100,
		minHeight: 100,
		minWidth: 100,
	},

	prizeRow: {
		borderBottom: `1px solid ${brandSecondary}`,
		justifyContent: 'space-between',
		paddingBottom: 5,
		paddingLeft: 10,
		paddingRight: 10,
		...aidaBaseStyles.flexHorizontalCenter,
	},
	prizesContainer: {
		margin: 'auto',
		maxWidth: 640,
		width: '100%',
	},

	second: {
		background: brandSecondary,
		...CssSelectors.allChildren(
			{
				background: brandSecondary,
			},
			'.num'
		),
	},
	small: {
		maxHeight: 65,
		maxWidth: 65,
		minHeight: 65,
		minWidth: 65,
	},
	smallFirst: {
		maxHeight: 80,
		maxWidth: 80,
		minHeight: 80,
		minWidth: 80,
	},
	smallWrapper: {
		maxWidth: 260,
	},
	third: {
		background: green,
		...CssSelectors.allChildren(
			{
				background: green,
			},
			'.num'
		),
	},
	tiger: {
		height: 25,
		marginLeft: 6,
		marginTop: 3,
		width: 26,
	},
	// Top3Avatar Styles
	top3: {
		...aidaBaseStyles.flexHorizontalCenter,
		alignItems: 'flex-end',
		justifyContent: 'space-between',
		margin: '0 auto 40px auto',
		maxWidth: 460,
		padding: '30px 0 0',
	},
	top3Ind: {
		borderRadius: '50%',
		display: 'flex',
		maxHeight: 140,
		maxWidth: 140,
		minHeight: 140,
		minWidth: 140,
		padding: 2,
		position: 'relative',
		...CssSelectors.allChildren(
			{
				...(aidaBaseStyles.absoluteCenter as CSSProperties),
				bottom: 'calc(100% - 5px)',
				top: 'unset',
			},
			'.crown'
		),

		...CssSelectors.allChildren(
			{
				...(aidaBaseStyles.absoluteCenter as CSSProperties),
				...(aidaBaseStyles.flexCenter as CSSProperties),
				borderRadius: '50%',
				color: white,
				fontFamily: FontFamilies.conqueror,
				fontSize: '24px',
				height: 35,
				position: 'absolute',
				top: '100%',
				width: 35,
			},
			'.num'
		),
		...CssSelectors.allDescendants(
			{
				lineHeight: 0,
			},
			'.num span'
		),
	},
});
